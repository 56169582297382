import React, { Fragment, useEffect, useState } from 'react';
import EmployeeInfo from './EmployeeInfo';
import EmployeeDetailTeam from './EmployeeDetailTeam';

const EmployeeInfoView = ({ showDetailTeam, setShowDetailTeam, employee, refetch }) => {
    useEffect(() => {
        setShowDetailTeam(false);
    }, [])
    return (
        <Fragment>
           {showDetailTeam ? (
               <EmployeeDetailTeam />
           ) : (
               <EmployeeInfo setShowDetailTeam={setShowDetailTeam} employee={employee}  />
           )}
        </Fragment>
    )
}

export default EmployeeInfoView;
