import { gql } from '@apollo/client';

export const GET_POSITIONS = gql`
    query getPositions {
    results: position(where: {deleted: {_eq: false}}) {
        id
        code
        name
    }
}
`
