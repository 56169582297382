import { gql } from '@apollo/client';
export const GET_PROFILE = gql`
query GetProfile($id: bigint!) {
    results: account_by_pk(id: $id) {
      id
      code
      full_name
      email
      phone
      avatar: medium {
          id
          name
          url
          type
      }
    }
  }
`

export const GET_ACCOUNT = gql`
query getAccounts($id: bigint!) @cached(ttl: 120) {
  results: account_by_pk(id: $id) {
    id
    code
    full_name
    email
    phone
    roles: account_roles {
        role: roleByRole{
            id
            code
            name
        }
    }
    avatar: medium {
        id
        name
        url
        type
    }
  }
}

`