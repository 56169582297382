import { gql } from "@apollo/client";

export const GET_DASHBOARD_PRODUCT_TYPE = gql`
  query GetDashboardProductType(
    $where: view_statistics_product_type_bool_exp!
  ) {
    result: view_statistics_product_type(where: $where) {
      count
      fk_property_type {
        name
        code
      }
    }
  }
`;

export const GET_DASHBOARD_PRODUCT_TYPE_AREA = gql`
  query GetDashboardProductTypeArea(
    $where: view_statistics_product_type_bool_exp!
  ) {
    result: view_statistics_product_type(where: $where) {
      count
      fk_property_type {
        name
        code
      }
      fk_district {
        name
      }
    }
  }
`;

export const GET_DASHBOARD_PRODUCT_TYPE_STATUS = gql`
  query GetDashboardProductStatus(
    $where: view_statistics_product_type_bool_exp!
  ) {
    result: view_statistics_product_type(where: $where) {
      count
      product_status
    }
  }
`;

export const GET_DASHBOARD_PRODUCT_SECONDARY = gql`
  query GetDashboardProductSecondary(
    $where: view_statistics_product_type_bool_exp!
    $where_aggregate: view_statistics_product_type_bool_exp!
  ) {
    result: view_statistics_product_type(where: $where) {
      count
      date
    }
    view_statistics_product_type_aggregate(where: $where_aggregate) {
      nodes {
        date
        count
      }
    }
  }
`;
