import React from "react";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import {
  MenuItem,
  FormControl,
  Select,
  Typography,
  CircularProgress,
} from "@material-ui/core";

const SelectComponent = ({
  title,
  handleChange,
  value,
  defaultTitle,
  defaultValue,
  listData = [],
  loadingList = false,
  fieldValue,
  fieldTitle,
  width = 120,
  multiple = false,
  widthTitle = undefined,
  direction = "row",
  alignItems = undefined,
  justifyContent = undefined,
  disabledDefault = false,
  margin = undefined,
  required = false,
  renderValue = false,
  onClose = undefined,
}) => {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      width: width,
      backgroundColor: "#fff",
    },
  }));
  
  const classes = useStyles();

  if(loadingList) {
    return <CircularProgress />
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: direction,
        alignItems,
        justifyContent,
        margin: margin,
      }}
    >
      <Typography style={{ width: widthTitle, fontWeight: "bold" }}>
        {title} {required && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <FormControl
        size="small"
        variant="outlined"
        className={classes.formControl}
      >
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={value}
          displayEmpty
          onChange={handleChange}
          multiple={multiple}
          onClose={onClose}
          defaultValue={defaultValue}
          renderValue={
            renderValue
              ? (selected) => {
                  if (_.isEmpty(selected)) {
                    return defaultTitle;
                  }
                  return _.join(selected, ", ");
                }
              : undefined
          }
        >
          <MenuItem disabled={disabledDefault} value={defaultValue}>
            {defaultTitle}
          </MenuItem>
          {_.map(listData, (item, key) => {
            return (
              <MenuItem key={_.get(item, "id")} value={_.get(item, fieldValue)}>
                {_.get(item, fieldTitle)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectComponent;
