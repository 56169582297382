import _ from 'lodash';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import * as formatMoment from 'moment'
import swal from 'sweetalert';
import { USER_ROLE_SUPPER_ADMIN } from '../constants/defined';

export function removeVietnameseTones(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, " ");
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
    str = str.replace(/\s+/g, '');
    return str;
}

export const timeAgo = (time) => {
    var date = new Date((time || "").replace(/-/g, "/").replace(/[TZ]/g, " ")),
        diff = (((new Date()).getTime() - date.getTime()) / 1000),
        day_diff = Math.floor(diff / 86400);

    if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31)
        return;

    return day_diff == 0 && (
        diff < 60 && "vừa xong" ||
        diff < 120 && "1 phút trước" ||
        diff < 3600 && Math.floor(diff / 60) + " phút trước" ||
        diff < 7200 && "1 giờ trước" ||
        diff < 86400 && Math.floor(diff / 3600) + " giờ trước") ||
        day_diff == 1 && "Hôm qua" ||
        day_diff < 7 && day_diff + " ngày trước " ||
        day_diff < 31 && Math.ceil(day_diff / 7) + " tuần trước";
}

export const jsUcfirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const handleStatusList = (data, listStatus) => {
    let status = _.get(data, 'status.code');
    switch (status) {
        case "INITIAL":
            return _.filter(listStatus, (item, key) => {
                return _.get(item, 'code') === "CANCEL" || _.get(item, 'code') === "BOOKING_DONE" || _.get(item, 'code') === "DEPOSIT";
            });
        case "CANCEL":
            return _.filter(listStatus, (item, key) => {
                return _.get(item, 'code') === "CANCEL" || _.get(item, 'code') === "BOOKING_DONE";
            });
        case "BOOKING":
            return _.filter(listStatus, (item, key) => {
                return _.get(item, 'code') === "CANCEL" || _.get(item, 'code') === "BOOKING_DONE" || _.get(item, 'code') === "DEPOSIT";
            });
        case "DEPOSIT":
            return _.filter(listStatus, (item, key) => {
                return _.get(item, 'code') === "CANCEL" || _.get(item, 'code') === "DONE" || _.get(item, 'code') === "COMPLETED";
            });
        case "BOOKING_DONE":
            return _.filter(listStatus, (item, key) => {
                return _.get(item, 'code') === "CANCEL" || _.get(item, 'code') === "DONE" || _.get(item, 'code') === "COMPLETED";
            });
        case "SKIP":
            return _.filter(listStatus, (item, key) => {
                return _.get(item, 'code') === "CANCEL" || _.get(item, 'code') === "DONE" || _.get(item, 'code') === "COMPLETED";
            });
        case "SELECTED":
            return _.filter(listStatus, (item, key) => {
                return _.get(item, 'code') === "CANCEL" || _.get(item, 'code') === "DONE" || _.get(item, 'code') === "COMPLETED";
            });
        case "CHECKING_DEPOSIT":
            return _.filter(listStatus, (item, key) => {
                return _.get(item, 'code') === "CANCEL" || _.get(item, 'code') === "DONE" || _.get(item, 'code') === "COMPLETED"
            });
        case "DONE":
            return _.filter(listStatus, (item, key) => {
                return _.get(item, 'code') === "CANCEL" || _.get(item, 'code') === "DONE" || _.get(item, 'code') === "COMPLETED";
            });
        case "COMPLETED":
            return _.filter(listStatus, (item, key) => {
                return _.get(item, 'code') === "CANCEL" || _.get(item, 'code') === "DONE" || _.get(item, 'code') === "COMPLETED";
            });
        default:
            return [];
    }
}


export const handleExportFile = (dataNotChecking, dataHouseView, dataDeposit, dataContract) => {
    const ExcelJSWorkbook = new ExcelJS.Workbook();
    const inprocessSheet = ExcelJSWorkbook.addWorksheet('Tư vấn');
    const houseViewSheet = ExcelJSWorkbook.addWorksheet('Xem nhà');
    const depositSheet = ExcelJSWorkbook.addWorksheet('Đặt cọc');
    const contractSheet = ExcelJSWorkbook.addWorksheet('Hợp đồng mua bán');

    const header = [
        'STT',
        'Hoạt động'
    ];

    const dataProcessExport = [];
    const dataHouseViewExport = [];
    const dataDepositExport = [];
    const dataContractExport = [];

    if (dataNotChecking !== undefined && dataNotChecking.length > 0) {
        let i = 1;
        dataNotChecking.forEach((item) => {
            dataProcessExport.push([
                i,
                `${formatMoment(_.get(item, 'created')).format('YYYY/MM/DD')} Mã SP ${_.get(item, 'requestByRequest.productByProduct.productByProduct.code', '')}: khách hàng ${_.get(item, 'account.full_name')} (Mã khách hàng: ${_.get(item, 'account.code')})`
            ]);
            i++;
        });
    } else if (dataHouseView !== undefined && dataHouseView.length > 0) {
        let i = 1;
        dataHouseView.forEach((item) => {
            dataHouseViewExport.push([
                i,
                `${formatMoment(_.get(item, 'created')).format('YYYY/MM/DD')} Mã SP ${_.get(item, 'requestByRequest.productByProduct.productByProduct.code', '')}: khách hàng ${_.get(item, 'account.full_name')} (Mã khách hàng: ${_.get(item, 'account.code')})`
            ]);
            i++;
        });
    } else if (depositSheet !== undefined && depositSheet.length > 0) {
        let i = 1;
        dataDeposit.forEach((item) => {
            dataDepositExport.push([
                i,
                `${formatMoment(_.get(item, 'created')).format('YYYY/MM/DD')} Mã SP ${_.get(item, 'requestByRequest.productByProduct.productByProduct.code', '')}: khách hàng ${_.get(item, 'account.full_name')} (Mã khách hàng: ${_.get(item, 'account.code')})`
            ]);
            i++;
        });
    } else if (dataContract !== undefined && dataContract.length > 0) {
        let i = 1;
        contractSheet.forEach((item) => {
            dataContractExport.push([
                i,
                `${formatMoment(_.get(item, 'created')).format('YYYY/MM/DD')} Mã SP ${_.get(item, 'requestByRequest.productByProduct.productByProduct.code', '')}: khách hàng ${_.get(item, 'account.full_name')} (Mã khách hàng: ${_.get(item, 'account.code')})`
            ]);
            i++;
        });
    } else {
        swal({
            title: "Thông báo",
            text: "Không có dữ liệu!",
            icon: "warning",
        });
        return;
    }

    const customCellInprocess = inprocessSheet.getCell('A1');
    const customCellHouseView = houseViewSheet.getCell('A1');
    const customCellDeposit = depositSheet.getCell('A1');
    const customCellContract = contractSheet.getCell('A1');

    customCellInprocess.alignment = { vertical: 'middle', horizontal: 'center' };
    customCellHouseView.alignment = { vertical: 'middle', horizontal: 'center' };
    customCellDeposit.alignment = { vertical: 'middle', horizontal: 'center' };
    customCellContract.alignment = { vertical: 'middle', horizontal: 'center' };


    customCellInprocess.font = {
        name: 'Century',
        family: 4,
        bold: true,
    };

    customCellHouseView.font = {
        name: 'Century',
        family: 4,
        bold: true,
    };

    customCellDeposit.font = {
        name: 'Century',
        family: 4,
        bold: true,
    };

    customCellContract.font = {
        name: 'Century',
        family: 4,
        bold: true,
    };


    inprocessSheet.addRow();
    houseViewSheet.addRow();
    depositSheet.addRow();
    contractSheet.addRow();

    const headerRowInprocess = inprocessSheet.addRow(header);
    const headerRowHouseView = houseViewSheet.addRow(header);
    const headerRowDeposit = depositSheet.addRow(header);
    const headerRowContract = contractSheet.addRow(header);

    let columnSTTInprocess = 1;
    let columnSTTHouseView = 1;
    let columnSTTDeposit = 1;
    let columnSTTContract = 1;

    inprocessSheet.columns.forEach((column) => {
        let dataMax = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
            dataMax = cell.value ? cell.value.toString().length : 0;
        });
        if (columnSTTInprocess === 1) {
            column.width = 5;
            columnSTTInprocess++;
        } else if (dataMax <= 2) {
            column.width = 10;
        } else {
            column.width = dataMax <= 15 ? 20 : dataMax;
        }
    });

    houseViewSheet.columns.forEach((column) => {
        let dataMax = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
            dataMax = cell.value ? cell.value.toString().length : 0;
        });
        if (columnSTTHouseView === 1) {
            column.width = 5;
            columnSTTHouseView++;
        } else if (dataMax <= 2) {
            column.width = 10;
        } else {
            column.width = dataMax <= 15 ? 20 : dataMax;
        }
    });

    depositSheet.columns.forEach((column) => {
        let dataMax = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
            dataMax = cell.value ? cell.value.toString().length : 0;
        });
        if (columnSTTDeposit === 1) {
            column.width = 5;
            columnSTTDeposit++;
        } else if (dataMax <= 2) {
            column.width = 10;
        } else {
            column.width = dataMax <= 15 ? 20 : dataMax;
        }
    });

    depositSheet.columns.forEach((column) => {
        let dataMax = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
            dataMax = cell.value ? cell.value.toString().length : 0;
        });
        if (columnSTTContract === 1) {
            column.width = 5;
            columnSTTContract++;
        } else if (dataMax <= 2) {
            column.width = 10;
        } else {
            column.width = dataMax <= 15 ? 20 : dataMax;
        }
    });


    if (dataProcessExport.length > 0) {
        dataProcessExport.forEach((value) => {
            inprocessSheet.addRow(value);
        });
    }

    if (dataHouseViewExport.length > 0) {
        dataHouseViewExport.forEach((value) => {
            houseViewSheet.addRow(value);
        });
    }

    if (dataDepositExport.length > 0) {
        dataDepositExport.forEach((value) => {
            depositSheet.addRow(value);
        });
    }

    if (dataContractExport.length > 0) {
        dataContractExport.forEach((value) => {
            contractSheet.addRow(value);
        });
    }

    customCellInprocess.value = 'Lịch sử tư vấn';
    customCellHouseView.value = ' Xem nhà';
    customCellDeposit.value = 'Đặt cọc';
    customCellContract.value = 'Hợp đồng mua bán';

    inprocessSheet.mergeCells('A1:E1');
    houseViewSheet.mergeCells('A1:E1');
    depositSheet.mergeCells('A1:E1');
    contractSheet.mergeCells('A1:E1');

    ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Danh_sach_lich_su_hoat_dong.xlsx');
    });
}

export const handleFlex = (flexDirection = 'row', alignItems = undefined, justifyContent = undefined) => {
    return {
        display: 'flex',
        flexDirection,
        alignItems,
        justifyContent
    }
}

export const convertNumberComma = (string) => {
    return Number(_.replace(string, /,/g, ''));
}

export const formatPhone = ({ phone, type }) => {
    const length = phone ? phone.length : 0;

    if (length < 10) return "N/A";
    if (type === 'first') return "**** *** " + phone.substr(length - 3, 3);
    if (type === 'middle') return phone.substr(0, length - 6) + " *** " + phone.substr(length - 3, 3);
    if (type === 'last') return phone.substr(0, length - 6) + " *** ***";

    return phone.substr(0, length - 6) + ' ' + phone.substr(length - 6, 3) + ' ' + phone.substr(length - 3, 3);
}

export const formatMoney = (money) => {
    if (_.isUndefined(money)) return;
    return money.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const checkedValidate = (list) => {
    let checked = _.every(list, (item) => Boolean(item));
    return checked;
}

export const checkedPermission = (userInfo, accessObjectPermissions, permission) => {
    if (_.toUpper(_.get(userInfo, 'role')) === USER_ROLE_SUPPER_ADMIN || (_.get(accessObjectPermissions, permission))) {
        return true;
    }

    return false;
};

export const AccessPermissions = (arr) => {
    const tmp = _.map((arr), item => _.reverse(_.split(item?.code, '_')));
    let res = _.map((tmp), (item) => ({ [item[0]]: item[1] }));
    res = _.groupBy(res, (item) => _.keys(item));
    res = _.map(res, (item, key) => ({ [key]: _.map(item, o => _.get(o, key)) }));
    return res;
};

export const CheckAccessPermissions = ({ role, isRoute, arr, functionCode, permissionCode, accessAllow = false }) => {
    if (_.toUpper(role) === USER_ROLE_SUPPER_ADMIN || accessAllow) {
        return true;
    }
    let access = false;
    const func = _.toUpper(functionCode) === "PRODUCT" 
        ? _.find(arr, (o) => _.includes(["PRIMARY", "SECONDARY"], _.keys(o)[0]))
        : _.toUpper(functionCode) === "OTHER" 
        ? _.find(arr, (o) => _.includes(["BANNER", "NOTIFICATION"], _.keys(o)[0]))
        : _.find(arr, `${_.toUpper(functionCode)}`);
    if (func && isRoute) {
        return true;
    }
    const accessList = _.get(func, _.toUpper(functionCode));
    access = _.includes(accessList, _.toUpper(permissionCode));
    return access;
};
