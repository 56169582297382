import { makeStyles } from "@material-ui/styles";
import PrimaryButton from '../../components/UI/PrimaryButton'
import React from "react";
import LockIcon from '@material-ui/icons/Lock';
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import slugs from "../../resources/slugs";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 150px)'
    }
}));

const AccessDenied = () => {

    const classes = useStyles();
    const history = useHistory();

    return (
        <Grid container direction="column" spacing={3} className={classes.container}>
            <Grid item>
                <LockIcon style={{ fontSize: 120 }} />
            </Grid>
            <Grid item>
                <Typography variant='h4'>Truy cập bị từ chối</Typography>
            </Grid>
            <Grid item>
                <Typography variant='subtitle1'>Bạn không có quyền truy cập.</Typography>
            </Grid>
            <Grid item>
                <PrimaryButton
                    labelButton="Về trang chủ"
                    actionButton={() => history.push(slugs.dashboard)}
                />
            </Grid>
        </Grid>
    )
};

export default AccessDenied;