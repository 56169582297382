import {
  Collapse,
  Grid,
  ListItem,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Fragment, useState } from "react";
import { useStyles } from "../FilterStyles";
import moment from "moment";

const ListDate = ({
  headerTitle = "Từ ngày - Đến ngày",
  open,
  setOpen,
  setDate,
  date,
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemText primary={headerTitle} className={classes.subHeader} />
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Grid container>
          <Grid item className={classes.dateWrap}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.datePicker}
                variant="inline"
                format="dd/MM/yyyy"
                value={date?.dateBegin}
                onChange={(date) =>
                  setDate((prev) => {
                    return {
                      ...prev,
                      dateBegin: moment(date).format("YYYY/MM/DD").toString(),
                    };
                  })
                }
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
                InputProps={{ disableUnderline: true }}
              />
              <KeyboardDatePicker
                className={classes.datePicker}
                variant="inline"
                format="dd/MM/yyyy"
                value={date?.dateEnd}
                onChange={(date) =>
                  setDate((prev) => {
                    return {
                      ...prev,
                      dateEnd: moment(date).format("YYYY/MM/DD").toString(),
                    };
                  })
                }
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
                InputProps={{ disableUnderline: true }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Collapse>
    </Fragment>
  );
};

export default ListDate;
