import {
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { CloudUpload, Description, HighlightOff } from "@material-ui/icons";
import axios from "axios";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../../context/ConfigContext";

const useStyles = makeStyles((theme) => ({
  addBtn: {
    background: "#fff",
    borderRadius: "6px",
    color: "#00837B",
    border: "1px solid #00837B",
    fontSize: "16px",
    "& > span": {
      gap: "10px",
      display: "flex",
      alignItems: "center",
    },
    "&:hover": {
      background: "rgba(0,131,123,0.3)",
    },
  },
  fileIcon: {
    background: "#e5e5e5",
    borderRadius: "6px",
    color: "#0A2240",
    fontSize: "16px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    cursor: "pointer",

    padding: 10,

    "&:hover": {
      opacity: "0.8",
    },

    "& > span": {
      gap: "10px",
    },
  },
  removeBtn: {
    "&:hover": {
      fill: "red",
    },
  },
}));

const Upload = ({
  uploadData,
  setUploadData,
  limit,
  type,
  id,
  fileChange,
  setFileChange,
  ...others
}) => {
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState(uploadData);

  const configValue = useContext(ConfigContext);

  useEffect(() => {
    let clone = uploadData;
    setUploadedFiles(clone);
  }, []);

  const classes = useStyles();

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (file?.size > 5e6) {
      setErrMsg("Dung lượng file tối đa cho phép là 20MB");
      return false;
    }
    if (uploadData.length >= limit) {
      setErrMsg(`Chỉ cho phép tối đa ${limit} file`);
      return false;
    }
    setLoading(true);
    setErrMsg("");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("media_type_code", type);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        configValue.API_UPLOAD_FILE,
        formData,
        config
      );
      setUploadedFiles([...uploadedFiles, data[0]]);
      setUploadData([...uploadData, data[0]]);
      setFileChange([...fileChange, data[0]]);
      // console.log(data[0]);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveFile = (index) => {
    const newList = [...uploadedFiles];

    const removedItem = newList[index];

    const tmp = fileChange;
    // To check if this media already in fileChange
    if (tmp.indexOf(removedItem) === -1) {
      setFileChange([...fileChange, removedItem]);
    } else {
      // remove if alredy in fileChange.
      const newFileChange = tmp.splice(index, 1);
      setFileChange(newFileChange);
    }
    newList.splice(index, 1);
    setUploadedFiles(newList);
    setUploadData(newList);
  };

  return (
    <div {...others}>
      <input
        hidden
        // accept={fileAccepted()}
        accept={
          type === "DOCUMENT" || type === "GUIDE_GAME" || type === "RULE_GAME"
            ? "application/pdf"
            : type === "VIDEO"
              ? "video/mp4,video/x-m4v,video/*"
              : "image/*"
        }
        id={id}
        type="file"
        onChange={handleUpload}
        disabled={loading}
      />

      <Grid container direction="column" style={{ gap: "12px" }}>
        {_.map(uploadedFiles, (item, key) => (
          <div className={classes.fileIcon} key={key}>
            <Description />
            <span> {_.get(item, "name")} </span>
            <HighlightOff
              onClick={() => handleRemoveFile(key)}
              className={classes.removeBtn}
            />
          </div>
        ))}

        <label htmlFor={id}>
          {loading ? (
            <CircularProgress size="24px" />
          ) : limit > uploadedFiles.length ? (
            <IconButton component="div" className={classes.addBtn}>
              <CloudUpload />
              <span>Tải tài liêu lên</span>
            </IconButton>
          ) : null}

          {!_.isEmpty(errMsg) && (
            <p style={{ color: "red", fontStyle: "italic" }}>{errMsg}</p>
          )}
        </label>
      </Grid>
    </div>
  );
};

Upload.propTypes = {
  type: PropTypes.oneOf([
    "PHOTO",
    "DOCUMENT",
    "VIDEO",
    "GUIDE_GAME",
    "RULE_GAME",
  ]).isRequired,
  limit: PropTypes.number,
  data: PropTypes.array,
  buttonType: PropTypes.oneOf(["DEFAULT", "CARD"]),
};
Upload.defaultProps = {
  type: "PHOTO",
  limit: 10,
  data: [],
  buttonType: "CARD",
};

export default Upload;
