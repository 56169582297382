import React, { useState, Fragment, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import Scheduler, { SchedulerData, ViewTypes, DATE_FORMAT } from 'react-big-scheduler'
import 'react-big-scheduler/lib/css/style.css'
import moment from 'moment'
import withDragDropContext from './withDnDContext'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import EventIcon from '@material-ui/icons/Event';
import { useQuery } from '@apollo/client';
import { GET_ALL_SCHEDULE } from '../../graphql/schemas/employee/employeeQueries';
import * as _ from 'lodash';
import { StoreContext } from '../../resources/store';

import { checkedPermission } from '../../helper/index';
import { UPDATE_EMPLOYEE } from '../../constants/defined';

const resources = [
    {
        id: 'r7',
        name: 'Nhân viên'
    }
];


Calendar.propTypes = {
    id: PropTypes.string,
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: "15px",
    },
    btn: {
        backgroundColor: "#00837b",
    },

}));


function Calendar({ onCreate, onDetail, data }) {

    const { accessObjectPermissions, userInfo } = useContext(StoreContext);

    const events = _.map(_.get(data, 'events'), (item, index) => {
        return {
            id: item.id,
            start: moment(item.start_time).utc().format('YYYY-MM-DD HH:mm'),
            end: moment(item.end_time).utc().format('YYYY-MM-DD HH:mm'),
            title: item.title,
            resourceId: 'r7',
        }
    });


    const classes = useStyles();

    const newConfig = {
        schedulerWidth: '70%',
        resourceName: 'Quản lý lịch hẹn',
    };

    const schedulerData = new SchedulerData(new moment().format(DATE_FORMAT), ViewTypes.Week, false, false, newConfig);
    moment.locale('vi');
    schedulerData.setLocaleMoment(moment);
    schedulerData.setResources(resources);
    schedulerData.setEvents(events);

    const [viewModel, setViewModel] = useState(schedulerData);
    const [visible, setVisible] = useState(false);


    const prevClick = (schedulerData) => {
        schedulerData.prev();
        schedulerData.setEvents(events);
        setViewModel(schedulerData);
        setVisible(!visible);
    }

    const nextClick = (schedulerData) => {
        schedulerData.next();
        schedulerData.setEvents(events);
        setViewModel(schedulerData);
        setVisible(!visible);
    }

    const onViewChange = (schedulerData, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        schedulerData.setEvents(events);
        setViewModel(schedulerData);
        setVisible(!visible);
    }

    const onSelectDate = (schedulerData, date) => {
        schedulerData.setDate(date);
        schedulerData.setEvents(events);
        setViewModel(schedulerData);
    }

    const eventClicked = (schedulerData, event) => {
        //alert(`You just clicked an event: {id: ${event.id}, title: ${event.title}}`);
        onDetail(event.id);
    };

    const ops1 = (schedulerData, event) => {
        alert(`You just executed ops1 to event: {id: ${event.id}, title: ${event.title}}`);
    };

    const ops2 = (schedulerData, event) => {
        alert(`You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`);
    };

    const newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
        let newFreshId = 0;
        schedulerData.events.forEach((item) => {
            if (item.id >= newFreshId)
                newFreshId = item.id + 1;
        });

        let newEvent = {
            id: newFreshId,
            title: 'New event you just created',
            start: start,
            end: end,
            resourceId: slotId,
            bgColor: 'purple'
        }
        schedulerData.addEvent(newEvent);
        setViewModel(schedulerData);
    }

    const updateEventStart = (schedulerData, event, newStart) => {
        schedulerData.updateEventStart(event, newStart);
        setViewModel(schedulerData);
    }

    const updateEventEnd = (schedulerData, event, newEnd) => {
        schedulerData.updateEventEnd(event, newEnd);
        setViewModel(schedulerData);
    }

    const moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
        schedulerData.moveEvent(event, slotId, slotName, start, end);
        setViewModel(schedulerData);
    }

    const handleCreate = () => {
        onCreate();
    }


    return (
        <Fragment>
            <div className={classes.root}>
                <Button
                    className={classes.btn}
                    disabled={!checkedPermission(userInfo, accessObjectPermissions, UPDATE_EMPLOYEE)}
                    variant="contained"
                    color="secondary"
                    onClick={handleCreate}
                    startIcon={<EventIcon />}
                >
                    Tạo lịch hẹn
                </Button>
                <Scheduler schedulerData={viewModel}
                    prevClick={prevClick}
                    nextClick={nextClick}
                    onSelectDate={onSelectDate}
                    onViewChange={onViewChange}
                    eventItemClick={eventClicked}
                    viewEventClick={ops1}
                    viewEvent2Click={ops2}
                    updateEventStart={updateEventStart}
                    updateEventEnd={updateEventEnd}
                    moveEvent={moveEvent}
                    newEvent={newEvent}
                />
            </div>
        </Fragment>
    );
}

export default withDragDropContext(Calendar);