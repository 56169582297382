import _ from "lodash";
import { useState, createContext } from "react";
import { useGetAppConfig } from "../graphql/schemas/hooks";

const ConfigContext = createContext();

function ConfigProvier({ children }) {
  const configs = useGetAppConfig();
  const parseObjectConfig = (configs) => {
    const object = {};
    _.forEach(configs, (item) => {
      _.set(object, `${item.key}`, item.value);
    });

    return object;
  };
  return (
    <ConfigContext.Provider value={parseObjectConfig(configs)}>
      {children}
    </ConfigContext.Provider>
  );
}

export { ConfigContext, ConfigProvier };
