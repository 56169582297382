import gql from "graphql-tag";

export const INSERT_BOOKING = gql`
    mutation CreateBooking($data: InsertBookingInput!) {
        createBooking(data: $data) {
            id
            project
        }
    }
`;

export const UPDATE_BOOKING = gql`
mutation UpdateBooking($data: UpdateBookingInput!) {
  updateBooking(data: $data) {
    id
  }
}

`