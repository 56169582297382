// Table
import * as _ from 'lodash';
import React, { Fragment, useState } from "react";
import { useHistory } from 'react-router-dom';
import {
    Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel,
    Toolbar, Typography, Checkbox, IconButton, Tooltip,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';



import { lighten, makeStyles } from '@material-ui/core/styles';
import { BOOKING_STATUS } from '../../../../constants/bookingStatus';

import ModalEditBooking from '../booking/ModalEditBooking';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: 'calc(100vh - 80px)',
        padding: '0 30px',
        position: 'relative',
    },
    headerStyle: {
        userSelect: 'none',
        '& th': {
            position: 'sticky',
            top: 0,
            left: 0,
            background: '#A9D08E',
            fontWeight: 'bold',
            color: '#000',
            fontSize: 12
        },
        '& th > .MuiTableSortLabel-root.MuiTableSortLabel-active': {
            color: '#000',
            '& .MuiTableSortLabel-icon': {
                color: '#000 !important',
            }
        },
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 2100,
        borderCollapse: 'separate'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    rowStyle: {
        position: 'relative',
        zIndex: 5,
    },
    checkboxStyle: {
        background: 'green',
        position: 'relative',
        zIndex: 9,
    },
    rowContainer: {
        display: 'flex',
        justifyContent: 'center',
        '& > p': {
            margin: '0 40px'
        }
    },
    isEcoeCart: {
        '&.MuiTableRow-root.MuiTableRow-hover:hover': {
            backgroundColor: 'rgb(169 208 142 / 70%)',
        },
        '&.selected': {
            background: '#f00',
            '& > td': {
                color: '#fff',
            }
        },
        '&.booking_done': {
            background: '#fb0'
        },
        '&.booking': {
            background: '#fff'
        },
        '&.deposit': {
            background: '#fff'
        },
        '&.cancel': {
            background: '#000',
            '& > td': {
                color: '#fff',
            }
        }
    },
    isOpenSaleCart: {
        '&.above': {
            backgroundColor:'#eee',
        }
    },
}));


const TableComponent = ({ data, headMenu = [], status, setDetailBooking, setKindCartShow }) => {
    const history = useHistory();
    const classes = useStyles();

    const [statusModal, setStatusModal] = useState(false);

    const isEcoeCart = _.includes( ['ECOECART'], _.get(status, 'code') );
    const isOpenSaleCart = _.includes( ['OPENSALE'], _.get(status, 'code') );

    let headCells = [];

    if (_.isEmpty(headMenu) && !_.isEmpty(data)) {
        headCells = _.map(_.keys(_.head(data)), (value, key) => {
            return ({ id: value, numeric: false, disablePadding: false, label: value })
        });
    } else {
        headCells = headMenu
    };

    


    const handleOpenModal = (bookingId) => {
        localStorage.setItem('selectedBooking', bookingId)
        setStatusModal(true);
    };
    
    if (_.isEmpty(data)) return <div>NULL</div>;

    
    return (
        <Paper className={classes.paper}>
            <ModalEditBooking
                setStatusModal={setStatusModal}
                statusModal={statusModal}
            />


            <TableContainer style={{ maxHeight: 640 }}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size='medium'
                    aria-label="enhanced table"
                >

                    <TableHead className={classes.headerStyle}>
                        <TableRow>
                            {
                                _.map(headCells, (item, key) =>
                                    <TableCell key={key} align="center">
                                        {item.label}
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            _.map(data, (row, index) => {

                                return (
                                    <TableRow
                                        className={clsx(
                                            isEcoeCart && classes.isEcoeCart, isEcoeCart && _.toLower(row.status),
                                            isOpenSaleCart && classes.isOpenSaleCart, (_.get(row, 'index') && _.includes(["SKIP", "SELECTED", "CANCEL", "DEPOSIT"], _.get(row,'status'))) && 'above'
                                        )}
                                        hover
                                        // role="checkbox"
                                        // tabIndex={-1}
                                        key={index}
                                    >
                                        

                                        {_.map(headCells, (value, key) =>
                                            <TableCell
                                                style={{ cursor: 'pointer' }}
                                                onClick={()=>handleOpenModal(_.get(row, 'id'))} 
                                                //history.push(`/product/primary/booking/${_.get(row, 'id')}`) 
                                                align="center"
                                                key={key}
                                            >
                                                {
                                                    value.id === 'status' ? (
                                                        BOOKING_STATUS(_.get(row, value.id))
                                                    )
                                                        :
                                                        value.id === 'customer_code' ? (
                                                            <div
                                                                style={{ fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    history.push(window.open("/customer/detail/" + _.get(row, 'customer_id')))
                                                                }}
                                                            >{_.get(row, value.id)}</div>
                                                        )
                                                            :
                                                            value.id === 'employee_code' ? (
                                                                <div
                                                                    style={{ fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        history.push(window.open("/employee/detail/" + _.get(row, 'employee_id')))
                                                                    }}
                                                                >{_.get(row, value.id)}</div>
                                                            )
                                                                :
                                                                value.id === 'employeeManager' ? (
                                                                    <div
                                                                        style={{ fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            history.push(window.open("/employee/detail/" + _.get(row, 'manager_id')))
                                                                        }}
                                                                    >{_.get(row, value.id)}</div>
                                                                )
                                                                    :
                                                                    (
                                                                        _.get(row, value.id) === 0 ? null : _.get(row, value.id)
                                                                    )
                                                }
                                            </TableCell>
                                        )}
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
};
export default TableComponent;
