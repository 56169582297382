import _ from "lodash";
import React, { Fragment } from "react";
import {
  Grid,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { BINGO_FINAL_RESULT, BINGO_WEEKLY_RESULT } from "../../../../constants/defined";

const useStyles = makeStyles((theme) => ({
  wrapperPaper: {
    padding: "30px 15px",
    marginBottom: "30px",
  },
  heading: {
    fontWeight: "bold",
    fontSize: 24,
  },
}));

const BingoResultRound = (props) => {
  const classes = useStyles();
  const { data, round } = _.get(props,'location.state');

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container className={classes.heading}>
        <Grid item>{`Số trúng thưởng ${round}`}</Grid>
      </Grid>

      <Paper className={classes.wrapperPaper}>
        <Grid direction="column" container spacing={3}>
          <Grid item xs={12}>
            <p style={{color: "rgba(0, 0, 0, 0.54)", fontSize: "1.3rem", marginBottom: 0, fontWeight: 'bold'}}>
              Giải thưởng
            </p>
            <p style={{fontSize: "16px", margin: '30px 0 15px', fontWeight: 'bold'}}>
              Loại giải thưởng: <span>{_.get(_.head(_.get(data,'bingo_results')), 'bingo_result_setting.special') ? 'Giải chung cuộc' : 'Giải thường'}</span>
            </p>
          </Grid>

          <Grid item xs={12} style={{height:'45px', flexBasis: 'unset', fontWeight: 'bold', opacity: '0.6', background: '#ccc', textAlign: 'center'}}>
            <Grid direction="row" container spacing={3}>
              <Grid item xs={3}>Hạng giải thưởng</Grid>
              <Grid item xs={3}>Mã số giải thưởng</Grid>
              <Grid item xs={6}>Mô tả</Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {
              _.map((_.get(_.head(_.get(data,'bingo_results')), 'bingo_result_setting.special') ? BINGO_FINAL_RESULT : BINGO_WEEKLY_RESULT), (item) => {
                let mapResult = _.filter(_.get(data,'bingo_results'), ['bingo_result_setting.code', item]);

                return (
                  <Fragment>
                  {
                    _.map(mapResult, (resultItem, key) =>
                      <Grid
                        key={key}
                        direction="row" container spacing={3} 
                        style={{borderBottom: '1px solid #ccc', marginBottom: '15px', textAlign: 'center'}}
                      >
                        <Grid item xs={3}>{_.get(resultItem, 'bingo_result_setting.name')}</Grid>
                        <Grid item xs={3}>{_.get(resultItem,'code')}</Grid>
                        <Grid item xs={6}>{_.get(resultItem,'channel')}</Grid>
                      </Grid>
                    )
                  }
                  </Fragment>
                )                
              })
            }
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default BingoResultRound;
