import React from "react";
import { withStyles, Checkbox } from "@material-ui/core";

const CustomCheckbox = withStyles({
	root: {
		color: '#B9B9B9',
		'&$checked': {
			color: '#00837B',
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default CustomCheckbox;
