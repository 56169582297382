import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, Button, makeStyles, CircularProgress, Paper } from '@material-ui/core';
import SelectComponent from '../../../components/SelectComponent';
import TableComponent from '../../../components/TableComponent';
import clsx from 'clsx';
import { useBranchesBy } from '../hooks';
import _ from 'lodash';
import moment from 'moment';
import AutoCompleteBranch from '../../../components/AutoCompleteOffice';
import { useHistory } from 'react-router-dom';
import slugs from '../../../resources/slugs';
import ButtonExportExcel from '../../../components/ButtonExportExcel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { StoreContext } from '../../../resources/store';
import { CREATE_BRANCH, EXPORT_BRANCH, USER_ROLE_SUPPER_ADMIN } from '../../../constants/defined';
import { checkedPermission } from '../../../helper';

const headMenu = [
    { id: 'code', numeric: false, disablePadding: false, label: 'Mã văn phòng' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Tên văn phòng' },
    { id: 'emp_count', numeric: false, disablePadding: false, label: 'SL NVKD', center: true },
    { id: 'admin_count', numeric: false, disablePadding: false, label: 'SL TK Admin', center: true },
    { id: 'product_count', numeric: false, disablePadding: false, label: 'SL SP quản lý', center: true },
    { id: 'created', numeric: false, disablePadding: false, label: 'Ngày thành lập' },
    { id: 'active', numeric: false, disablePadding: false, label: 'Tình trạng' },
    { id: 'manager', numeric: false, disablePadding: false, label: 'Người quản lý' },
    { id: 'head_branch', numeric: false, disablePadding: false, label: 'Mã văn phòng quản lý' },
];
const BranchList = () => {

    const history = useHistory();
    const [skip, setSkip] = useState(0);
    const [branchId, setBranchId] = useState(undefined);
    const [active, setActive] = useState(0);
    const [branchManage, setBranchManage] = useState(undefined);
    const { data: branchList, count, loading, refetch } = useBranchesBy(active, branchManage, branchId, skip, 10);

    const { userInfo, accessObjectPermissions } = useContext(StoreContext);

    const [page, setPage] = useState(1);

    const useStyles = makeStyles((theme) => ({
        createButton: {
            // display: 'flex',
            // alignItems: 'center',
            // backgroundColor: '#2C8A81',
            // fontWeight: 'unset',
            // padding: '5px 20px',

            backgroundColor: "#00837B",
            textTransform: "unset",
            fontSize: "18px",
            fontWeight: "bold",
            color: "#ffffff",
            borderRadius: "6px",
            padding: "4px 20px",
            width: "100%",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "#00837B",
                opacity: 0.7,
            },
        },
        wapperLoading: {
            width: '100%',
            height: 'calc(100vh - 113px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        wapperContent: {
            minHeight: 'calc(100vh - 200px)',
            padding: '30px 30px',
            position: 'relative',
        },
        marginBottom: {
            marginBottom: "30px",
        },
    }));

    const classes = useStyles();

    let dataList = _.map((branchList), (element) => {
        return {
            id: _.get(element, 'id'),
            code: _.get(element, 'code'),
            name: _.get(element, 'name'),
            emp_count: _.get(element, 'emp_count.aggregate.count'),
            admin_count: _.get(element, 'admin_count.aggregate.count'),
            product_count: _.get(element, 'product_count.aggregate.count'),
            created: moment(_.get(element, 'created')).format('DD/MM/YYYY'),
            active: _.get(element, 'active') ? "Đang hoạt động" : "Đã khoá",
            manager: _.get(element, "employee_manager.info.code"),
            head_branch: _.get(element, "head_branch.code"),
        }
    });

    useEffect(() => {
        refetch();
    }, []);

    if (loading) {
        return (
            <div className={classes.wapperLoading}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <Grid container direction="column">
            <Grid
                container
                direction="row"
                spacing={3}
                alignItems="flex-end"
                className={classes.marginBottom}
            >
                <Grid item xs={6}>
                    <Grid container direction="row" spacing={3}>
                        {/* <Grid item>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>Văn phòng quản lý</Typography>
                                <AutoCompleteBranch
                                    onChange={
                                        (id) => {
                                            setBranchManage(id);
                                            setPage(0)
                                        }
                                    }
                                    width={250}
                                    value={branchManage}
                                />
                            </div>
                        </Grid> */}

                        <Grid item>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>Văn phòng</Typography>
                                <AutoCompleteBranch
                                    onChange={
                                        (id) => {
                                            setBranchId(id);
                                            setPage(0)
                                        }
                                    }
                                    width={250}
                                    value={branchId}
                                />
                            </div>
                        </Grid>

                        <Grid item>
                            <SelectComponent
                                defaultValue={0}
                                value={active}
                                defaultTitle="Chọn trạng thái"
                                fieldTitle="name"
                                fieldValue="id"
                                title="Trạng thái văn phòng"
                                flex={true}
                                direction="column"
                                listData={[{ id: true, name: "Hoạt động" }, { id: false, name: "Đã khoá" }]}
                                width={250} space={20} color="#fff"
                                handleChange={(e) => { setActive(e.target.value); setPage(1) }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Grid container direction="row" spacing={3} justifyContent="flex-end">
                        <Grid item>
                            <ButtonExportExcel
                                disabled={!checkedPermission(userInfo, accessObjectPermissions, EXPORT_BRANCH)}
                                exportName='EXPORT_BRANCH'
                                active={active === 0 ? null : active}
                                branch={branchManage > 0 ? branchManage : null}
                            />
                        </Grid>

                        <Grid item>
                            <Button
                                disabled={!checkedPermission(userInfo, accessObjectPermissions, CREATE_BRANCH)}
                                variant="contained"
                                startIcon={<AddCircleIcon />}
                                onClick={() => history.push(slugs.branchCreate)} className={clsx(classes.createButton)}
                            >
                                Tạo văn phòng mới
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Paper className={classes.wapperContent}>
                <Grid
                    container
                    direction="row"
                    spacing={4}
                    style={{ marginTop: "0", marginLeft: "-30px", width: "calc(100% + 60px)" }}
                >
                    {
                        loading ? (
                            <CircularProgress disableShrink />
                        )
                            :
                            (
                                <TableComponent
                                    rowPath={slugs.branchList}
                                    headMenu={headMenu}
                                    data={dataList}
                                    setSkip={(skip) => setSkip(skip)}
                                    page={page}
                                    setPage={setPage}
                                    count={count}
                                    fieldValue="id"
                                />
                            )
                    }
                </Grid>
            </Paper>
        </Grid>
    )
}

export default BranchList;

