import gql from "graphql-tag";

export const GET_PROPERTY_TYPES = gql`
query PropertyTypeQuery {
    results: property_type(where: {deleted: {_eq: false}}) {
            id
            code
            name
        }
    }
`;
export const GET_UTILITIES = gql`
    query GetUtilities {
        results: utilities(where: {deleted: {_eq: false}}) {
          id
          name
          type
        }
      }
`;
export const GET_FURNITURES = gql`
    query getFurnitures {
        results: room_type {
            id
            name
            furnitures: furniture {
                id
                name
            }
        }
    }
`;
export const GET_ORIENTATION = gql`
    query GetOrientations {
        results: orientation(where: {deleted: {_eq: false}}) {
            id
            code
            name
        }
    }  
`;

export const GET_WORKFLOW_NEXT_STEP = gql`
    query product_request($id: smallint!) {
        type_workflow(where: {id: {_eq: $id}}) {
            id
            init_step
            finish_step
            typeWorkflowNextstepsByStep {
                type_workflow_next_step{
                id
                type_step{
                id
                code
                name
                }
            }
            }
        }
    }
`;

export const GET_PRODUCT_PLAN_STATUS = gql`
    query getProductPlanStatuses {
        result: product_plan_status {
            id
            code
            name
        }
    }
`;

export const GET_APP_CONFIG = gql`
query appConfig {
  appconfig {
    key
    value
  }
}
`
