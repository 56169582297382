import * as _ from 'lodash';
import React from "react";
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, Grid } from '@material-ui/core';
import ecoePrimary from '../../assets/icons/project.svg';
import ecoeSecondary from '../../assets/icons/property.svg';
import slugs from "../../resources/slugs";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: 300
    },
    item: {
        maxWidth: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        '&:hover': {
            color: '#00837b',
            background: 'none',
            opacity: '.7',
        }
    },
    iconStyle: {
        width: '100%',
    },
    text: {
        paddingTop: 20,
        fontSize: 24,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        textAlign: 'center',
    }
});

const Product = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container direction="row" style={{width: 600}}>
                <ListItem className={classes.item} button component={Link} to={_.get(slugs, 'productPrimary')} >
                    <img className={classes.iconStyle} src={ecoePrimary} />
                    <div className={classes.text}>Sản phẩm sơ cấp</div>
                </ListItem>
                <ListItem className={classes.item} button component={Link} to={_.get(slugs, 'productSecondary')} >
                    <img className={classes.iconStyle} src={ecoeSecondary} />
                    <div className={classes.text}>Sản phẩm thứ cấp</div>
                </ListItem>
            </Grid>
            
        </div>
    )
};

export default Product;
