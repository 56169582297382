import * as _ from 'lodash';
import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { FormControl } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%',
        padding: '10.5px 14px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        
        '&.disabled': {
            background: '#f7f7f7'
        },
    },
    inputStyle: {
        width: '100%',
        fontSize: '16px',
        border: '0',
        background: 'none',
        '&:focus-visible': {
            outline: '0',
        },
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    defaultPadding: {
        '&>div': {
            padding: '10.5px 14px',
        },
    },
    dropdownList: {
        '> *': {
            background: 'green',
        }
    }
}));

const InputCustomerSearch = (props) => {
    const { fieldname, description, object, setObject, datalist, modeEdit, setIsAvailable } = props;
    const [inputValue, setInputValue] = useState(_.get(object, fieldname));
    const classes = useStyles();

    const onBlurSetValue = (event) => {
        const value = _.trim(_.get(event, 'target.value'));
        if ((_.isEmpty(value) && inputValue === undefined) || _.get(object, fieldname) === value) return;
        
        const selectedCustomer = _.find(datalist, [ 'info.code', value ]);
        let tempObject = {};
        if (selectedCustomer) {
            if(fieldname === 'exchange_code') {
                _.set(tempObject, 'code', _.get(selectedCustomer, 'info.code'));    
            }
            _.set(tempObject, 'id', _.get(selectedCustomer, 'info.id'));
            _.set(tempObject, 'name', _.get(selectedCustomer, 'info.full_name'));
            _.set(tempObject, 'email', _.get(selectedCustomer, 'info.email'));
            _.set(tempObject, 'phone', _.get(selectedCustomer, 'info.phone'));
            _.set(tempObject, 'personalid', _.get(selectedCustomer, 'info.personalid'));
            _.set(tempObject, 'ref_employee_code', _.get(selectedCustomer, 'ref_employee_info.info.code'));
        } else {
            tempObject = {'customer':'Invalid'}
        }
        setObject(tempObject);
        if(_.isFunction(setIsAvailable) && tempObject.personalid ) {
            setIsAvailable(true);
        }
        return;
    };

    const onKeyPress = (event) => {
        if (event.key === 'Enter' || event.charCode === 13) {
            onBlurSetValue(event);
        }
        return;
    }

    const onchangeAction = (e) => {
        const value = _.get(e, 'target.value');
        setInputValue(value);
    }

    return (
        <Fragment>
            <div style={{ display: 'flex' }}>
                <FormControl variant="outlined" className={clsx(classes.formControl, modeEdit && 'disabled')}>
                    <input
                        className={classes.inputStyle}
                        placeholder={_.isEmpty(inputValue) && description}
                        value={inputValue}
                        list={`list-${fieldname}`}
                        id={`input-textfield-${fieldname}`}
                        onBlur={(e) => onBlurSetValue(e)}
                        onKeyPress={onKeyPress}
                        onChange={onchangeAction}
                        autocomplete="off"
                        disabled={modeEdit}
                    />
                    <datalist id={`list-${fieldname}`} className={classes.dropdownList}>
                        {_.map(datalist, (item, key) => {
                            return <option key={key} value={_.get(item,'info.code')} />
                        })}
                    </datalist>
                </FormControl>
            </div>
        </Fragment >
    );
};

export default InputCustomerSearch;
