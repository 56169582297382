import React, { useState } from 'react';
import { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/styles/useTheme';
import { makeStyles } from '@material-ui/core/styles';
import ProductSigned from './ProductSigned';
import ProductCare from './ProductCare';
import Accordion from '../Accordion';
import AccordionForCustomer from '../AccordionForCustomer';
import { useParams } from 'react-router-dom';

import _ from 'lodash';

import { useQuery } from '@apollo/client';
import { GET_PRODUCT_BY_CUSTOMER, GET_ACTIVITY_HISTORY_BY_CUSTOMER } from '../../graphql/schemas/customer/customerQueries';
import clsx from 'clsx';
import RewardList from './RewardList';


const useStyles = makeStyles(theme => ({
    button: {
        fontWeight: 'bold',
        fontSize: '16px',
        color: '#ffffff',
        borderRadius: '0',
        height: '32px',
    },
    accordion: {
        width: '100%',
        maxWidth: '900px',
        marginTop: '50px',
        marginLeft: '0',
        '&.matches-sm': {
            width: '100%',
        }
    },
    accordionBox: {
        marginTop: 0,
        marginBottom: 0,
    },
}))

const CustomerProductView = ({ accountId, wallets }) => {
    const { id } = useParams();
    const theme = useTheme();
    const classes = useStyles();

    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const [kindMenu, setKindMenu] = useState("signed");

    const PER_PAGE = 6;

    const { loading, data, error, fetchMore: fetchMoreConsult } = useQuery(GET_PRODUCT_BY_CUSTOMER, {
        variables: {
            skip: 0,
            take: PER_PAGE,
            order_by: {
                created: 'desc'
            },
            condition: {
                product_requests: {
                    customer: {
                        _eq: Number(accountId),
                    },
                    workflow_type: {
                        code: {
                            _in: ['CONSULT']
                        }
                    }
                },
            }
        },
        skip: !accountId
    })

    const { loading: loadingConsign, data: dataConsign, error: errorConsign, fetchMore: fetchMoreConsign } = useQuery(GET_PRODUCT_BY_CUSTOMER, {
        variables: {
            skip: 0,
            take: PER_PAGE,
            order_by: {
                created: 'desc'
            },
            condition: {
                product_requests: {
                    customer: {
                        _eq: Number(accountId),
                    },
                    workflow_type: {
                        code: {
                            _in: ["CONSIGN", "POST"]
                        }
                    }
                },

            }
        }
    })

    const { data: dataActivyHistory, loading: loadingActivyHistory } = useQuery(GET_ACTIVITY_HISTORY_BY_CUSTOMER, {
        variables: {
            type: 'CONSULT',
            customer: Number(accountId)
        },
        skip: kindMenu !== "historyActivitiy",
    });

    const countConSign = _.get(dataConsign, 'product_aggregate.aggregate.count');
    const countConsult = _.get(data, 'product_aggregate.aggregate.count');

    return (
        <Fragment>
            <Grid item>
                <ButtonGroup size="small" aria-label="small outlined button group">
                    <Button
                        onClick={() => setKindMenu("signed")}
                        className={classes.button} style={{
                            backgroundColor: kindMenu === "signed" ? '#e27f35' : "#2c8a81",
                            width: '210px'
                        }}>
                        SP KÝ GỬI ({countConSign})
                    </Button>
                    <Button
                        onClick={() => setKindMenu("productcare")}
                        className={classes.button}
                        style={{
                            backgroundColor: kindMenu === "productcare" ? '#e27f35' : "#2c8a81",
                            width: '280px'
                        }}>SP KHÁCH QUAN TÂM ({countConsult})
                    </Button>
                    <Button
                        onClick={() => setKindMenu("historyActivitiy")}
                        className={classes.button}
                        style={{
                            backgroundColor: kindMenu === "historyActivitiy" ? '#e27f35' : "#2c8a81",
                            width: '240px'
                        }}>LỊCH SỬ HOẠT ĐỘNG
                    </Button>
                    <Button
                        onClick={() => setKindMenu("rewardList")}
                        className={classes.button}
                        style={{
                            backgroundColor: kindMenu === "rewardList" ? '#e27f35' : "#2c8a81",
                            width: 'max-content'
                        }}
                    >
                        DANH SÁCH GIẢI THƯỞNG
                    </Button>
                </ButtonGroup>
            </Grid>
            <Grid item>
                {kindMenu === "signed" && (<ProductSigned
                    fetchMore={fetchMoreConsign}
                    PER_PAGE={PER_PAGE}
                    data={dataConsign}
                    loading={loadingConsign}
                    accountId={accountId}
                />)}
                {kindMenu === "productcare" && (<ProductCare
                    accountId={accountId}
                    fetchMore={fetchMoreConsult}
                    PER_PAGE={PER_PAGE}
                    data={data}
                    loading={loading}
                />)}
                {kindMenu === "historyActivitiy" && (
                    <Grid container direction="column" className={clsx(classes.accordion, matchesSM && 'matches-sm')}>
                        <AccordionForCustomer data={dataActivyHistory} loading={loadingActivyHistory} />
                    </Grid>
                )}

                {kindMenu === "rewardList" && (
                    <RewardList wallets={wallets} accountId={accountId} />
                )}

            </Grid>
        </Fragment>

    )
}

export default CustomerProductView
