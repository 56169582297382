import gql from "graphql-tag";
import { CORE_SCHEMA_FIELDS } from './fragments';

export const PROJECT_GET_FILTER = gql`
query GetProjectsSearchMini($skip: Int!, $take: Int!, $order_by: [product_order_by!] = {}, $where: product_bool_exp!) {
  projects: product(offset: $skip, limit: $take, where: $where, order_by: $order_by) {
    id
    code
    title
  }
}

`

export const PROJECT_GET_ALL = gql`
  ${CORE_SCHEMA_FIELDS}
  query GetProjects($skip: Int!, $take: Int!, $order_by: [product_order_by!] = {}, $where: product_bool_exp!) {
    projects: product(offset: $skip, limit: $take, where: $where, order_by: $order_by) {
      requests {
        id
        draft
        request_workflows {
          id
          status
          approved
        }
      }
      id
      code
      title
      address: addressByAddress {
        ...addressData
      }
      project_types: product_property_types {
        project_type: propertyTypeByPropertyType {
          name
          id
          code
        }
      }
      longitude
      latitude
      total_area
      density_of_building
      density_of_trees
      price_from
      price_to
      price_from_milion
      price_to_milion
      price_unit: priceUnitByPriceUnit {
        name
        id
      }
      floor_from
      floor_to
      basement
      discount
      start_date
      taking_over
      total_appartments
      total_ground
      total_block
      avatar: productAvatarByMedia {
        id
        name
        url
        type: media_type {
          id
          code
          name
        }
      }
      project_status: projectStatusByProjectStatus {
        id
        code
        name
      }
      project_investor: companyByInvestor {
        id
        name
        website
        logo: medium {
          id
          url
        }
        corporation: company {
          name
        }
      }
    }
  }
  
`;

export const PROJECT_GET_ONE = gql`
  ${CORE_SCHEMA_FIELDS}
  query GetProject($id: uuid!) {
    project: product_by_pk(id: $id) {
      requests {
        id
        draft
        current_step
        request_workflows {
          id
          status
          approved
        }
        type_workflow {
          type_status {
            id
            code
            name
          }
        }
      }
      avatar: productAvatarByMedia {
        id
        name
        url
        type: media_type {
          id
          code
          name
        }
      }
      id
      code
      title
      description
      address: addressByAddress {
        ...addressData
      }
      longitude
      latitude
      total_area
      density_of_building
      density_of_trees
      price_from
      price_to
      price_from_milion
      price_to_milion
      building_contractor
      design_company
      manager_company
      floor_from
      floor_to
      basement
      price_unit: priceUnitByPriceUnit {
        name
        id
      }
      investment
      investment_unit: priceUnitByPriceUnit {
        id
        name
      }
      discount
      start_date
      taking_over
      total_appartments
      total_officetel
      total_shophouse
      total_townhouse
      total_ground
      total_block
      total_parking
      floor_from
      floor_to
      area_from
      created
      area_to
      total_villa
      total_block
      description
      view_3d_url
      video_url
      motorbike_parking_fee
      car_parking_fee
      management_fee
      investor_document_name
      note
      develop_company: companyByDevelopCompany {
        id
        name
      }
      project_status: projectStatusByProjectStatus {
        id
        code
        name
      }
      project_investor: companyByInvestor {
        id
        name
        website
        logo: medium {
          id
          url
        }
        corporation: company {
          name
        }
      }
      project_constructor: companyByBuildingContractor {
        id
        name
      }
      project_medias: property_media {
        media: propertyMediaDetail {
          id
          name
          url
          type: media_type {
            id
            code
            name
          }
        }
      }
      project_utilities: product_utilities {
        utility {
          id
          name
          type
        }
      }
      project_types: product_property_types {
        project_type: propertyTypeByPropertyType {
          name
          id
          code
        }
      }
      project_guaranty_banks: company {
        id
        code
        name
      }
      project_loan_banks: companyBySupportBank {
        id
        code
        name
      }
      project_design: companyByDesignCompany {
        id
        name
      }
      project_management: companyByManagerCompany {
        id
        name
      }
      investment_cooperation: companyByInvestmentCooperation {
        id
        name
      }
      construction_supervision: companyByMonitorCompany {
        id
        name
      }
    }
  }
`;

export const GET_ALL_SHORT_PROJECTS = gql`
    ${CORE_SCHEMA_FIELDS}
    query GetProjects($skip: Int!, $take: Int!) {
        results: product(offset: $skip, limit: $take, where: {deleted: {_eq: false}, propertyTypeByPropertyType: {code: {_eq: "P"}}}) {
            id
            code
            name: title
            address: addressByAddress {
                ...addressData
            }
        }
    }  
`;

export const GET_PROJECTS_SUMMARY = gql`
  query GetProjectsSummary {
    count: product_aggregate(where: {deleted: {_eq: false}, propertyTypeByPropertyType: {code: {_eq: "P"}}}) {
      aggregate {
        count
      }
    }
  }  
`;

export const GET_PROJECT_TYPES = gql`
query MyQuery {
  property_type {
   id
   code
   name
  }
 }
`