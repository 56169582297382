import React, { useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { useStyles } from './useStyles';
import InputTextField from '../Secondary/components/InputTextField';
import DateFnsUtils from '@date-io/date-fns';
import * as _ from "lodash";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker
} from '@material-ui/pickers';
ProjectInfo.propTypes = {

};

function ProjectInfo({ draftObject, setDraftObject, modeEdit }) {
    const [selectedDateStart, setSelectedDateStart] = React.useState(_.get(draftObject, 'start_date'));
    const [selectedDateEnd, setSelectedDateEnd] = React.useState(_.get(draftObject, 'taking_over'));
    const classes = useStyles();
    const handleDateStartChange = (date) => {
        setSelectedDateStart(date);
    };

    const handleDateEndChange = (date) => {
        setSelectedDateEnd(date);
    };

    useEffect(() => {
        let tmp = { ...draftObject };
        _.set(tmp, 'start_date', selectedDateStart);
        setDraftObject(tmp);
    }, [selectedDateStart]);

    useEffect(() => {
        let tmp = { ...draftObject };
        _.set(tmp, 'taking_over', selectedDateEnd);
        setDraftObject(tmp);
    }, [selectedDateEnd]);


    return (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container xs={12} className={classes.groupPadding}>
                    <Grid item xs={12}>
                        <Typography component='div' className={classes.title}>Thông tin dự án</Typography>
                    </Grid>
                    <div className={classes.marginDivider} />
                    <Grid xs={2} item>
                        <div className={classes.titleInfo} style={{ paddingLeft: "0px" }}>Tổng số vốn đầu tư</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='total_investment'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "total_investment")}
                        />
                    </Grid>
                    <Grid xs={2} item>
                        <div className={classes.titleInfo}>Diện tích toàn dự án (m2)</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='total_area'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "total_area")}
                        />
                    </Grid>
                    <Grid xs={2} item>
                        <div className={classes.titleInfo}>Mật độ xây dựng (%)</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='density_of_building'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "density_of_building")}
                        />
                    </Grid>
                    <Grid xs={2} item>
                        <div className={classes.titleInfo}>Mật độ cây xanh (%)</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='density_of_tree'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "density_of_tree")}
                        />
                    </Grid>
                </Grid>
                <div className={classes.marginDivider} />
                <Grid container xs={12} className={classes.groupPadding}>

                    <Grid item xs={4}>
                        <div className={classes.titleInfo}>Khởi công</div>
                        <KeyboardDateTimePicker
                            style={{
                                backgroundColor: '#ffffff',
                                width: '240px',
                                border: '1px solid lightgray',
                                padding: '5px 0px 5px 15px',
                                borderRadius: '5px'
                            }}
                            margin="normal"
                            variant="inline"
                            id="time-picker-start"
                            format="yyyy"
                            value={selectedDateStart}
                            onChange={handleDateStartChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                            defaultValue={_.get(draftObject, 'start_date')}
                            InputProps={{ disableUnderline: true }}
                        />
                        {/* <InputTextField

                        fieldname='start_date'
                        fullwidth
                        object={draftObject}
                        setObject={setDraftObject}
                    /> */}
                    </Grid>
                    <Grid item xs={5}>
                        <div className={classes.titleInfo}>Bàn giao</div>
                        <KeyboardDateTimePicker
                            style={{
                                backgroundColor: '#ffffff',
                                width: '240px',
                                border: '1px solid lightgray',
                                padding: '5px 0px 5px 15px',
                                borderRadius: '5px'
                            }}
                            margin="normal"
                            variant="inline"
                            id="time-picker-end"
                            format="yyyy"
                            value={selectedDateEnd}
                            onChange={handleDateEndChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                            defaultValue={_.get(draftObject, 'taking_over')}
                            InputProps={{ disableUnderline: true }}
                        />
                        {/* <InputTextField
                        fieldname='taking_over'
                        fullwidth
                        object={draftObject}
                        setObject={setDraftObject}
                    /> */}
                    </Grid>
                </Grid>
                {/* <div className={classes.marginDivider} /> */}
                <Grid container xs={12} className={classes.groupPadding}>

                    <Grid item xs={2}>
                        <div className={classes.titleInfo}>Số căn hộ</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='total_appartments'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "total_appartments")}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <div className={classes.titleInfo}>Số officetel</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='total_officetel'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "total_officetel")}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <div className={classes.titleInfo}>Số Shophouse</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='total_shophouse'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "total_shophouse")}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={12} className={classes.groupPadding}>

                    <Grid item xs={2}>
                        <div className={classes.titleInfo}>Số biệt thự</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='total_villa'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "total_villa")}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <div className={classes.titleInfo}>Số nhà phố</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='total_townhouse'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "total_townhouse")}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <div className={classes.titleInfo}>Số đất nền</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='total_ground'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "total_ground")}
                        />
                    </Grid>
                </Grid>

                <Grid container xs={12} className={classes.groupPadding}>

                    <Grid item xs={2}>
                        <div className={classes.titleInfo}>Số tầng (min)</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='floor_from'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "floor_from")}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <div className={classes.titleInfo}>Số tầng (max)</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='floor_to'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "floor_to")}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <div className={classes.titleInfo}>Số tầng giữ xe</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='total_parking'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "total_parking")}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={12} className={classes.groupPadding}>

                    <Grid item xs={2}>
                        <div className={classes.titleInfo}>Số block</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='total_block'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "total_block")}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <div className={classes.titleInfo}>Diện tích SP (min)</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='area_from'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "area_from")}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <div className={classes.titleInfo}>Diện tích SP (max)</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='area_to'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "area_to")}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={12} className={classes.groupPadding}>

                    <Grid item xs={2}>
                        <div className={classes.titleInfo}>Phí quản lý (đ/m2)</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='management_fee'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "management_fee")}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <div className={classes.titleInfo}>Phí gửi xe máy (đ/xe/tháng)</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='motorbike_parking_fee'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "motorbike_parking_fee")}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <div className={classes.titleInfo}>Phí gửi ô tô (đ/xe/tháng)</div>
                        <InputTextField
                            number
                            modeEdit={modeEdit}
                            fieldname='car_parking_fee'
                            fullwidth
                            object={draftObject}
                            setObject={setDraftObject}
                            defaultValue={_.get(draftObject, "car_parking_fee")}
                        />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    );
}

export default ProjectInfo;