import { useQuery } from "@apollo/client";
import { Backdrop, CircularProgress, Grid } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import swal from "sweetalert";
import { GET_DASHBOARD_PRODUCT_TYPE_AREA } from "../../../graphql/schemas/chart/chartQueries";
import CategoryAndLandChart from "./CategoryAndLandChart";
import CategoryAndLandFilter from "./CategoryAndLandFilter";

function ChartCategoryAndLand(props) {
  const chartRef = useRef(null);

  const { districts } = props;

  const [isSelectAll, setIsSelectAll] = useState({
    areaType: true,
  });

  const [filtertType, setFilterType] = useState({
    areaType: [],
  });

  const [date, setDate] = useState({
    dateBegin: moment().startOf("month").format("YYYY/MM/DD").toString(),
    dateEnd: moment().endOf("month").format("YYYY/MM/DD").toString(),
  });
  const [variables, setVariables] = useState({
    where: {
      date: {
        _gte: moment().startOf("month").format("YYYY/MM/DD").toString(),
        _lte: moment().endOf("month").format("YYYY/MM/DD").toString(),
      },
    },
  });

  const { loading, data, error, refetch } = useQuery(
    GET_DASHBOARD_PRODUCT_TYPE_AREA,
    {
      variables: variables,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );
  const resetZoom = () => {
    chartRef.current.resetZoom();
  };
  const handleSubmit = async () => {
    resetZoom();
    const values = {
      ...filtertType,
      ...date,
    };

    if (
      _.toLower(_.get(values, "dateBegin")) !== _.toLower("Invalid date") &&
      _.toLower(_.get(values, "dateEnd")) !== _.toLower("Invalid date")
    ) {
      if (_.get(values, "dateBegin") > _.get(values, "dateEnd")) {
        swal(
          "Thông báo",
          "Ngày bắt đầu nhỏ hơn hoặc bằng ngày kết thúc",
          "error"
        );

        return;
      }

      const variables = {
        where: {
          date: {
            _gte: _.get(values, "dateBegin"),
            _lte: _.get(values, "dateEnd"),
          },
          district: {
            _in: _.get(values, "areaType"),
          },
        },
      };

      isSelectAll.areaType && delete variables.where.district;

      setVariables(variables);
    } else {
      swal(
        "Thông báo",
        "Ngày bắt đầu hoặc ngày kết thúc không hợp lệ",
        "error"
      );
    }
  };
  return (
    <Grid item container spacing={2}>
      <CategoryAndLandFilter
        districts={districts}
        onSubmit={handleSubmit}
        setFilterType={setFilterType}
        setDate={setDate}
        date={date}
        setIsSelectAll={setIsSelectAll}
      />
      <CategoryAndLandChart chartRef={chartRef} dataChart={data} />
      <Backdrop style={{ color: "#fff", zIndex: 200000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default ChartCategoryAndLand;
