import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Grid,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Radio,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SelectComponent from "../../Customer/components/SelectComponent";
import SelectComponentNew from "../../Customer/components/SelectComponentNew";
import {
  useProvinces,
  useWards,
} from "../../../graphql/schemas/masterdata/address";
import {
  useDistricts,
  useStreets,
} from "../../../graphql/schemas/masterdata/address/index";
import _, { set } from "lodash";
import clsx from "clsx";
import { checkedPermission, checkedValidate } from "../../../helper/index";
import InputTextField from "../../Product/Secondary/components/InputTextField";
import AutoCompleteBranch from "../../../components/AutoCompleteOffice";
import AutoCompleteEmployee from "../../../components/AutoCompleteEmployee";
import {
  useGetBranch,
  useUpdateBranch,
} from "../../../graphql/schemas/masterdata/branches/index";
import { useGetEmployee } from "../hooks";
import swal from "sweetalert";
import {
  AddCircle as AddCircleIcon,
  RemoveCircleOutline,
} from "@material-ui/icons";
import { StoreContext } from "../../../resources/store";
import { USER_ROLE_SUPPER_ADMIN } from "../../../constants/defined";

const useStyles = makeStyles((theme) => ({
  fieldTitle: {
    fontWeight: "bold",
  },
  branchContainer: {
    marginTop: 24,
  },
  cancel: {
    backgroundColor: "#E27F35",
    color: "#fff",
    marginRight: 20,
  },
  confirm: {
    backgroundColor: "#00837B",
    color: "#fff",
  },
  wapperLoading: {
    width: "100%",
    height: "calc(100vh - 113px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const BranchDetailEdit = () => {

  const { userInfo, accessObjectPermissions } = useContext(StoreContext);

  const { id } = useParams();

  const [address, setAddress] = useState([]);

  const [object, setObject] = useState({
    id: null,
    name: "",
    phone: "",
    address_number: "",
    street_name: "",
    ward: 0,
    district: 0,
    province: 0,
    longitude: null,
    latitude: null,
    employee_manager: null,
    branch_manager: null,
    is_head: null,
    is_physical: null,
  });

  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const { updateBranch } = useUpdateBranch(id);

  const [incharges, setIncharges] = useState([]);

  const [disabled, setDisabled] = useState(false);

  const [areaObject, setAreaObject] = useState(null);

  const { data: branch, loading: loadingDetailBranch } = useGetBranch(id);

  const classes = useStyles();

  const provinceList = useProvinces();

  const districtList = useDistricts({
    provinceId: object.province,
  });

  const wardList = useWards({
    districtId: object.district,
  });

  const districtListManage = useDistricts({
    provinceId: address.provinceIdManage,
  });

  const wardListManage = useWards({
    districtId: address.districtIdManage,
  });

  const streetList = useStreets({ districtId: object.district });
  const { data: employee, loading: loadingEmployee } = useGetEmployee(
    _.get(branch, "manager")
  );

  const onClose = (wardList, index) => {
    let arr = _.map(_.get(areaObject[index], "wards"), (value) =>
      _.get(_.find(wardList, ["name", value]), "id")
    );

    let removeIncharges = _.filter(
      incharges,
      (o) => _.get(o, "district") !== _.get(areaObject[index], "district")
    );
    let variablesWard = [
      ...removeIncharges,
      ..._.map(arr, (id) => {
        return {
          ward: id,
          province: _.get(areaObject[index], "province"),
          district: _.get(areaObject[index], "district"),
        };
      }),
    ];

    setIncharges(variablesWard);
  };

  useEffect(() => {
    let checked = checkedValidate(
      Object.values({
        name: object?.name,
        province: object?.province,
        ward: object?.ward,
        district: object?.district,
        longitude: object?.longitude,
        latitude: object?.latitude,
        street_name: object?.street_name,
        employee_manager: object?.employee_manager,
      })
    );
    setDisabled(!checked);
  }, [object, address]);

  useEffect(() => {
    let groupByDistrict = _.map(
      _.groupBy(_.get(branch, "branch_incharges"), "districtByDistrict.id"),
      (item, key) => {
        return {
          district: key,
          wards: item,
        };
      }
    );

    if (branch) {
      setObject({
        name: _.get(branch, "name"),
        phone: _.get(branch, "phone"),
        address_number: _.get(branch, "address.short"),
        street_name: _.get(branch, "address.street.name"),
        ward: _.get(branch, "address.ward.id"),
        district: _.get(branch, "address.district.id"),
        province: _.get(branch, "address.province.id"),
        longitude: _.get(branch, "longitude"),
        latitude: _.get(branch, "latitude"),
        branch_manager: _.get(branch, "branch.id"),
        employee_manager: _.get(branch, "manager"),
        is_head: _.get(branch, "head"),
        is_physical: _.get(branch, "is_physical"),
      });
      setAddress(
        _.map(groupByDistrict, (item) => {
          return {
            provinceIdManage: _.get(
              _.head(_.get(item, "wards")),
              "provinceByProvince.id"
            ),
            districtIdManage: _.get(
              _.head(_.get(item, "wards")),
              "districtByDistrict.id"
            ),
            wards: _.get(item, "wards"),
          };
        })
      );

      setIncharges(
        _.map(_.get(branch, "branch_incharges"), (item) => {
          return {
            ward: _.get(item, "wardByWard.id"),
            province: _.get(item, "provinceByProvince.id"),
            district: _.get(item, "districtByDistrict.id"),
          };
        })
      );
    }
  }, [loadingDetailBranch]);

  useEffect(() => {
    let tmp = [];
    tmp = _.map(address, (item) => {
      return {
        province: item?.provinceIdManage,
        district: item?.districtIdManage,
        wards: _.map(item?.wards, (item) => _.get(item, "wardByWard.name")),
      };
    });

    setAreaObject(tmp);
  }, [address]);

  const addAreaObject = () => {
    setAreaObject([...areaObject, { province: 2, district: 0, wards: [] }]);
  };

  const handleUpdate = () => {
    setLoadingUpdate(true);
    let temp = _.cloneDeep(object);
    temp.latitude = Number(temp?.latitude);
    temp.longitude = Number(temp?.longitude);
    temp.id = Number(id);
    let updateIncharges = _.filter(incharges, (o) =>
      _.includes(
        _.map(areaObject, (objDis) => objDis?.district),
        o?.district
      )
    );

    updateBranch({
      variables: {
        data: {
          ...temp,
          incharges: _.unionBy(updateIncharges, "ward"),
        },
      },
    }).then(
      (res) => {
        setLoadingUpdate(false);
        swal({
          title: "Chỉnh sửa văn phòng thành công",
          icon: "success",
        }).then(() => window.history.back());
      },
      (error) => {
        swal(
          "Vui lòng thử lại",
          "Có lỗi khi chỉnh sửa văn phòng",
          "error"
        ).then(() => setLoadingUpdate(false));
      }
    );
  };

  if (loadingDetailBranch || loadingEmployee) {
    return (
      <div className={classes.wapperLoading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Grid container direction="column">
        <Grid container direction="row">
          <Typography variant="h4">Chỉnh sửa văn phòng</Typography>
        </Grid>
        <Grid
          container
          direction="column"
          className={classes.branchContainer}
          spacing={2}
        >
          <Grid item container direction="row">
            <Grid item container xs={6} alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>
                  Tên văn phòng <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <InputTextField
                  fieldname="name"
                  description="Tên văn phòng"
                  object={object}
                  setObject={setObject}
                  required
                />
              </Grid>
            </Grid>
            <Grid item container xs={6} alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>
                  Số điện thoại
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <InputTextField
                  fieldname="phone"
                  description="Số điện thoại"
                  object={object}
                  setObject={setObject}
                  maxLength={10}
                  setDisabled={(value) => {
                    setDisabled(value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item container direction="row">
            <Grid item xs={6} container alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>
                  Tỉnh/ thành phố<span style={{ color: "red" }}>*</span>{" "}
                </Typography>
              </Grid>
              <div style={{ width: 250 }}>
                <SelectComponent
                  disabledDefault={true}
                  width={"100%"}
                  defaultTitle="Chọn tỉnh/thành phố"
                  fieldTitle="name"
                  fieldValue="id"
                  value={_.get(object, "province", 0)}
                  handleChange={(e) =>
                    setObject((prev) => ({
                      ...prev,
                      province: _.get(e, "target.value", 0),
                      district: 0,
                      ward: 0,
                    }))
                  }
                  listData={provinceList}
                  alignItems="center"
                />
              </div>
            </Grid>
            <Grid item xs={6} container alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>
                  Quận/ Huyện<span style={{ color: "red" }}>*</span>{" "}
                </Typography>
              </Grid>
              <Grid>
                <div style={{ width: 250 }}>
                  <SelectComponent
                    fieldTitle="name"
                    fieldValue="id"
                    handleChange={(e) =>
                      setObject((prev) => ({
                        ...prev,
                        district: _.get(e, "target.value", 0),
                        ward: 0,
                      }))
                    }
                    listData={districtList}
                    width={250}
                    value={_.get(object, "district", 0)}
                    defaultTitle="Chọn quận/huyện"
                    alignItems="center"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6} container alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>
                  Phường/xã<span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <div style={{ width: 250 }}>
                <SelectComponent
                  fieldTitle="name"
                  fieldValue="id"
                  width="100%"
                  handleChange={(e) =>
                    setObject((prev) => ({
                      ...prev,
                      ward: _.get(e, "target.value"),
                    }))
                  }
                  listData={wardList}
                  value={_.get(object, "ward", 0)}
                  defaultTitle="Phường/xã"
                  alignItems="center"
                />
              </div>
            </Grid>
          </Grid>

          <Grid item container direction="row">
            <Grid item xs={6} container alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>Số nhà</Typography>
              </Grid>
              <Grid item xs={4}>
                <InputTextField
                  fieldname="address_number"
                  description="Số nhà"
                  object={object}
                  setObject={setObject}
                  required
                />
              </Grid>
            </Grid>
            <Grid item xs={6} container alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>
                  Tên đường <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
              </Grid>
              <div style={{ width: 250 }}>
                <InputTextField
                  fieldname="street_name"
                  description="Chọn tên đường"
                  object={object}
                  setObject={setObject}
                  required
                />
              </div>
            </Grid>
          </Grid>

          <Grid item container direction="row">
            <Grid item xs={6} container>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={3}>
                  <Typography className={classes.fieldTitle}>
                    Tọa độ <span style={{ color: "red" }}>*</span>{" "}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <InputTextField
                    fullwidth
                    fieldname="latitude"
                    description="Nhập toạ độ latitude"
                    object={object}
                    setObject={setObject}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputTextField
                    fullwidth
                    fieldname="longitude"
                    description="Nhập toạ độ longitude"
                    object={object}
                    setObject={setObject}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6} container>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={3}>
                  <Typography className={classes.fieldTitle}>
                    Phân loại <span style={{ color: "red" }}>*</span>{" "}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Radio
                    style={{ color: "#00837B" }}
                    checked={object.is_head}
                    onChange={() =>
                      setObject((prev) => {
                        return {
                          ...prev,
                          is_head: !object.is_head,
                        };
                      })
                    }
                  />
                  Hội sở
                  <Radio
                    style={{ color: "#00837B" }}
                    checked={!object.is_head}
                    onChange={() =>
                      setObject((prev) => {
                        return {
                          ...prev,
                          is_head: !object.is_head,
                        };
                      })
                    }
                  />
                  Chi nhánh
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={6}>
                    <Typography className={classes.fieldTitle}>
                      Là địa chỉ vật lý
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={object.is_physical}
                          style={{ color: "#00837B" }}
                          onClick={() =>
                            setObject((prev) => ({
                              ...prev,
                              is_physical: !object.is_physical,
                            }))
                          }
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6} container alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>
                  Văn phòng quản lý
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <AutoCompleteBranch
                  value={_.get(branch, "branch.id")}
                  placeholder="Chọn văn phòng"
                  onChange={(id) =>
                    setObject((prev) => ({ ...prev, branch_manager: id }))
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={6} container spacing={2}>
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>
                  Mã nhân viên ECOE quản lý cao nhất{" "}
                  <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <AutoCompleteEmployee
                  value={_.get(employee, "info.code")}
                  placeholder="Chọn nhân viên"
                  onChange={(id) =>
                    setObject((prev) => ({ ...prev, employee_manager: id }))
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{ padding: 8, marginTop: 4, marginLeft: 0 }}
            xs={12}
            alignItems="center"
            container
            spacing={2}
          >
            <Grid container spacing={4} alignItems="top">
              <Grid item style={{ width: 170 }}>
                <Typography className={classes.fieldTitle}>
                  Khu vực quản lý
                </Typography>
              </Grid>

              <Grid item xs={10}>
                {_.map(areaObject, (item, index) => {
                  return (
                    <Grid
                      container
                      key={index}
                      spacing={4}
                      alignItems="center"
                      style={{ position: "relative" }}
                    >
                      <Grid item xs={4}>
                        <SelectComponentNew
                          width="100%"
                          disabledDefault={true}
                          defaultValue={0}
                          defaultTitle="Chọn tỉnh/thành phố"
                          fieldTitle="name"
                          fieldValue="id"
                          value={_.get(item, "province")}
                          handleChange={(e) => {
                            let tmp = [...areaObject];
                            tmp[index]["province"] = _.get(e, "target.value");
                            tmp[index]["district"] = 0;
                            tmp[index]["wards"] = [];
                            setAreaObject(tmp);
                          }}
                          listData={provinceList}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <SelectComponentNew
                          width="100%"
                          fieldTitle="name"
                          fieldValue="id"
                          handleChange={(e) => {
                            let tmp = [...areaObject];
                            tmp[index]["district"] = _.get(e, "target.value");
                            tmp[index]["wards"] = [];
                            setAreaObject(tmp);
                          }}
                          value={_.get(item, "district")}
                          province={_.get(item, "province")}
                          defaultValue={0}
                          defaultTitle="Chọn quận/huyện"
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <SelectComponentNew
                          width="100%"
                          fieldTitle="name"
                          fieldValue="name"
                          multiple={true}
                          handleChange={(e) => {
                            let tmp = [...areaObject];
                            tmp[index]["wards"] = _.get(e, "target.value");
                            setAreaObject(tmp);
                          }}
                          value={_.get(item, "wards")}
                          defaultTitle="Chọn phường xã"
                          renderValue={true}
                          onClose={onClose}
                          district={_.get(item, "district")}
                          itemIndex={index}
                        />
                      </Grid>

                      <IconButton
                        style={{
                          position: "absolute",
                          top: "12px",
                          right: "-36px",
                        }}
                        onClick={(e) => {
                          let tmp = [
                            ..._.filter(
                              areaObject,
                              (o) => o.district !== areaObject[index].district
                            ),
                          ];
                          setAreaObject(tmp);
                        }}
                      >
                        <RemoveCircleOutline style={{ fill: "red" }} />
                      </IconButton>
                    </Grid>
                  );
                })}
              </Grid>

              <Grid item xs={12}>
                <Button
                  onClick={() => addAreaObject()}
                  variant="contained"
                  className={clsx(classes.confirm)}
                >
                  <AddCircleIcon fontSize="large" style={{ paddingRight: 5 }} />
                  Thêm quận khác
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          style={{ marginTop: 24 }}
          justifyContent="flex-end"
          container
          direction="row"
        >
          <Button
            className={clsx(classes.cancel)}
            variant="contained"
            onClick={() => window.history.back()}
          >
            Hủy
          </Button>
          <Button
            disabled={disabled || loadingUpdate}
            className={clsx(classes.confirm)}
            variant="contained"
            onClick={handleUpdate}
          >
            Xác nhận
          </Button>
        </Grid>
      </Grid>
    </>

  );
};

export default BranchDetailEdit;
