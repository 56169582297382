import { useQuery, useMutation } from '@apollo/client';
import { CREATE_NOTIFICATION, GET_LIST_NOTIFICATION } from '../../../graphql/schemas/notification/index';
import swal from 'sweetalert'

export const useCreateNotification = () => {
    const [createNotification] = useMutation(CREATE_NOTIFICATION, {
        refetchQueries: [{
            query: GET_LIST_NOTIFICATION,
            variables: {
                skip: 0,
                take: 10,
                type: "ADMIN"
            }
        }],
        onError: (err) => {
            return swal({
                title: `${err.message}`,
                icon: "error",
            })
        }
    });

    return {
        createNotification
    }
}

export const useListNotification = (skip, take) => {
    const { data: notificationList, error, loading, fetchMore } = useQuery(GET_LIST_NOTIFICATION, {
        variables: {
            skip,
            take,
            type: "ADMIN"
        }
    });

    return {
        notificationList,
        error,
        loading,
        fetchMore
    }
}

// {
//     update: (cache, { data: { result } }) => {
//         const { results } = cache.readQuery({
//             query: GET_LIST_NOTIFICATION
//         });
//         // write query to cache
//         cache.writeQuery({
//             query: GET_LIST_NOTIFICATION,
//             data: {
//                 results: [result, ...results]
//             }
//         })
//     },
//     onError: (err) => console.log(err)
// }