import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import TableComponent from "../../../components/TableComponent";

const headMenu = [
  { id: "STT", numeric: false, disablePadding: false, label: "STT" },
  {
    id: "gift_code",
    numeric: false,
    disablePadding: false,
    label: "ID giải thưởng",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Loại giải thưởng",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Tên giải thưởng",
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Ngày tạo",
  },
  {
    id: "expire_day",
    numeric: true,
    disablePadding: false,
    label: "HSD",
  },
  {
    id: "start",
    numeric: false,
    disablePadding: false,
    label: "Thời gian bắt đầu",
  },
  {
    id: "end",
    numeric: false,
    disablePadding: false,
    label: "Thời gian kết thúc",
  },

  {
    id: "redeem_ecoin",
    numeric: false,
    disablePadding: false,
    label: "Số coin để đổi",
  },
  {
    id: "max_redeem",
    numeric: false,
    disablePadding: false,
    label: "Số giải thưởng",
  },
  {
    id: "count_account_changed",
    numeric: false,
    disablePadding: false,
    label: "Đã đổi",
  },
  {
    id: "remain_reward",
    numeric: false,
    disablePadding: false,
    label: "Còn lại",
  },
];

const EventGiftsTable = (props) => {
  const { data, ...others } = props;

  const [tableData, setTableData] = useState([]);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    let tmp = _.map(data.event_gifts, (item, index) => {
      return {
        STT: index + 1,
        gift_code: _.get(item, "gifts.code"),
        title: _.get(item, "gifts.title"),
        name: _.get(item, "gifts.name"),
        created: moment(_.get(item, "gifts.created")).format("DD/MM/YYYY"),
        expire_day: _.get(item, "gifts.expire_day"),
        start: moment(_.get(item, "gifts.start")).format("DD/MM/YYYY"),
        end: moment(_.get(item, "gifts.end")).format("DD/MM/YYYY"),
        redeem_ecoin: _.get(item, "gifts.redeem_ecoin"),
        max_redeem: _.get(item, "gifts.max_redeem"),
        count_account_changed: _.get(item, "gifts.count_account_changed"),
      };
    });
    setTableData(tmp);
  }, [data]);

  return (
    <TableComponent
      {...others}
      headMenu={headMenu}
      data={tableData}
      isPromotionRouter={true}
      setSkip={(skip) => setSkip(skip)}
      page={page}
      setPage={setPage}
      count={tableData.length}
    />
  );
};

export default EventGiftsTable;
