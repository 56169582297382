import { gql } from "@apollo/client";

export const CREATE_EVENT = gql`
  mutation CreateJackpotEventGame($arg: JackpotEventGameInput!) {
    createJackpotEventGame(arg: $arg) {
      id
      name
      code
      start
      end
      type
      created
      created_by
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation UpdateJackpotEventGame($arg: UpdateEventGameInput!) {
    updateJackpotEventGame(arg: $arg) {
      affected_rows
    }
  }
`;

export const UPDATE_VOUCHER_GAME = gql`
  mutation UpdateVoucherGame($arg: UpdateVoucherGameInput) {
    updateVoucherGame(arg:$arg){
        affected_rows
    }
  }
`;

export const INSERT_YOUTUBE_MEDIA = gql`
  mutation InsertYoutubeMedia($url: String!) {
    media: insert_media_one(object: {url: $url, type: 21, size: 0, ext: ".mp4", name: ""}) {
      id
      url
    }
  }
`;

export const INSERT_RESULT_BINGO_ROUND = gql`
  mutation UpdateBingoResult($arg: [BingoResultDailyInput]!){
    updateBingoResult(arg: $arg){
        message
    }
  }
`;

export const UPDATE_REDEEM_ECOIN_GAME_SETTING = gql`
  mutation updateGameConfig($ecoin: smallint!, $where: jackpot_type_bool_exp!) {
    result: update_jackpot_type(_set: {ecoin: $ecoin}, where: $where) {
      affected_rows
    }
  }
`;
