import * as _ from 'lodash';

export const BOOKING_STATUS = (name) => _.get({
    'INITIAL':	'Xét duyệt',
    'CANCEL': 'Hủy/Qua lượt',
    'SELECTED': 'Đã chọn căn',
    'BOOKING': 'Xét duyệt giữ chỗ',
    'DEPOSIT': 'Đặt cọc',
    'DONE': 'Đặt cọc thành công',
    'BOOKING_DONE': 'Giữ chỗ thành công'
}, name, 'INITIAL');
