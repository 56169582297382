
import * as _ from 'lodash';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    useProvinces, useDistricts, useWards, useStreets
} from '../../../graphql/schemas/masterdata/address';
import {
    usePropertyTypes,
    useUtilities, useFurnitures, useOrientation,
    usePrjects,
    useGetCustomerByCode,
    useGetAllShortCustomer,
    useGetProductForEdit,
    useGetProductDetail
} from '../../../graphql/schemas/hooks';

import { makeStyles, useTheme } from '@material-ui/styles';
import {
    CircularProgress,
    Paper, Divider, Button, Typography, Grid, FormControl, RadioGroup, FormControlLabel, FormGroup, Radio
} from '@material-ui/core';

import iconBedRoom from '../../../assets/icons/bed-room.svg';
import iconBathRoom from '../../../assets/icons/bath-room.svg';
import iconLivingRoom from '../../../assets/icons/living-room.svg';
import iconKitchenRoom from '../../../assets/icons/kitchen.svg';

import clsx from 'clsx';
import Currency from '../../../components/currency/Currency';
import InputTextField from './components/InputTextField';
import InputTextFieldSearch from './components/InputTextFieldSearch';
import InputDropdown from './components/InputDropdown';

import SelectUtilities from './components/SelectUtilities';
import ImagePicker from './components/ImagePicker';

import { EDIT_SECONDARY_PRODUCT } from '../../../graphql/mutation/insertProducts';
import { GET_DETAIL_PRODUCT_FOR_EDIT } from '../../../graphql/schemas/product/productQueries';
import { useMutation } from '@apollo/client';
import swal from 'sweetalert';
import { user } from '../../../constants/user';
import InputCustomerSearch from './components/InputCustomerSearch';
import InputProjectSearch from './components/InputProjectSearch';
import ButtonReturn from '../../../components/ButtonReturn';
import { Update } from '@material-ui/icons';
import slugs from '../../../resources/slugs';


import { readMillions } from '../../../helper/currency';
import { PRODUCT_FIND_ALL } from '../../../graphql/schemas/product/productQueries';
import { checkedPermission, formatPhone } from '../../../helper';
import PlanningInformationEdit from '../../../components/product/PlanningInformationEdit';
import { useGetCustomer } from '../../Customer/hooks';
import axios from 'axios';

import { ConfigContext } from '../../../context/ConfigContext';
import { URL_API_MAP } from '../../../constants/defined';

import { useStyles } from './stylesEditPage';
import { StoreContext } from '../../../resources/store';

import { USER_ROLE_SUPPER_ADMIN } from '../../../constants/defined';

const mappingObject = (productDetail) => {
    let temp = {};
    if (_.get(productDetail, 'address')) {
        _.set(temp, 'province', _.get(productDetail, 'address.province') || null);
        _.set(temp, 'district', _.get(productDetail, 'address.district') || null);
        _.set(temp, 'ward', _.get(productDetail, 'address.ward') || null);
        _.set(temp, 'street', _.get(productDetail, 'address.street') || null);
        _.set(temp, 'address_number', _.get(productDetail, 'address.short') || null);
    }
    if (_.get(productDetail, 'latitude')) {
        _.set(temp, 'latitude', _.get(productDetail, 'latitude') || null);
    }
    if (_.get(productDetail, 'longitude')) {
        _.set(temp, 'longitude', _.get(productDetail, 'longitude') || null);
    }
    if (_.get(productDetail, 'project.id')) {
        _.set(temp, 'project.id', _.get(productDetail, 'project.id'));
        _.set(temp, 'project.name', _.get(productDetail, 'project.name'));
    }
    if (!_.get(productDetail, 'project.id') && _.get(productDetail, 'project.name')) {
        _.set(temp, 'project.name', _.get(productDetail, 'project.name'));
    }
    if (_.get(productDetail, 'area')) {
        _.set(temp, 'area', _.get(productDetail, 'area') || null);
    }
    if (_.get(productDetail, 'area_construction')) {
        _.set(temp, 'area_construction', _.get(productDetail, 'area_construction') || null);
    }
    if (_.get(productDetail, 'area_foor')) {
        _.set(temp, 'area_foor', _.get(productDetail, 'area_foor') || null);
    }
    if (_.get(productDetail, 'area_height')) {
        _.set(temp, 'area_height', _.get(productDetail, 'area_height') || null);
    }
    if (_.get(productDetail, 'area_used')) {
        _.set(temp, 'area_used', _.get(productDetail, 'area_used') || null);
    }
    if (_.get(productDetail, 'area_width')) {
        _.set(temp, 'area_width', _.get(productDetail, 'area_width') || null);
    }
    if (_.get(productDetail, 'basement')) {
        _.set(temp, 'basement', _.get(productDetail, 'basement') || null);
    }
    if (_.get(productDetail, 'bath_room')) {
        _.set(temp, 'bath_room', _.get(productDetail, 'bath_room') || null);
    }
    if (_.get(productDetail, 'bed_room')) {
        _.set(temp, 'bed_room', _.get(productDetail, 'bed_room') || null);
    }
    if (_.get(productDetail, 'commission')) {
        _.set(temp, 'commission', _.get(productDetail, 'commission') || null);
    }
    if (_.get(productDetail, 'description')) {
        _.set(temp, 'description', _.get(productDetail, 'description') || null);
    }
    if (_.get(productDetail, 'floor')) {
        _.set(temp, 'floor', _.get(productDetail, 'floor') || null);
    }
    if (_.get(productDetail, 'property_type')) {
        _.set(temp, 'property_type', _.get(productDetail, 'property_type.id') || null);
    }
    if (_.get(productDetail, 'remark')) {
        _.set(temp, 'remark', _.get(productDetail, 'remark') || null);
    }
    if (_.get(productDetail, 'title')) {
        _.set(temp, 'title', _.get(productDetail, 'title') || null);
    }
    if (_.get(productDetail, 'product_detail.video_url')) {
        _.set(temp, 'video_url', _.get(productDetail, 'product_detail.video_url') || null);
    }
    if (_.get(productDetail, 'product_detail.view_3d_url')) {
        _.set(temp, 'view_3d_url', _.get(productDetail, 'product_detail.view_3d_url') || null);
    }
    if (_.get(productDetail, 'price')) {
        _.set(temp, 'price', _.get(productDetail, 'price') || null);
    }
    if (_.size(_.get(productDetail, 'requests')) > 0) {
        _.set(temp, 'draft', _.get(_.head(_.get(productDetail, 'requests')), 'draft'));
    }

    if (_.get(productDetail, 'exclusive')) {
        _.set(temp, 'exclusive', _.get(productDetail, 'exclusive') || null);
    }

    if (_.get(productDetail, 'urgent')) {
        _.set(temp, 'urgent', _.get(productDetail, 'urgent') || null);
    }

    if (_.get(productDetail, 'product_plan')) {
        _.set(temp, 'product_plan', _.get(productDetail, 'product_plan') || null);
    }

    return temp;
};

// const mappingCustomer = ({ list=[], id }) => {
//     const selectedCustomer = _.find(list, ['info.id', id]);
//     return selectedCustomer ? {
//         code: _.get(selectedCustomer, 'info.code'),
//         id: _.get(selectedCustomer, 'info.id'),
//         name: _.get(selectedCustomer, 'info.full_name'),
//         phone: formatPhone({ phone: _.get(selectedCustomer, 'info.phone'), type: 'first' }),
//         ref_employee_code: _.get(selectedCustomer, 'ref_employee_info.info.code'),
//     } : {};
// }

const mappingCustomer = (customerInfo) => {
    return customerInfo ? {
        code: _.get(customerInfo, 'info.code'),
        id: _.get(customerInfo, 'info.id'),
        name: _.get(customerInfo, 'info.full_name'),
        phone: formatPhone({ phone: _.get(customerInfo, 'info.phone'), type: 'first' }),
        ref_employee_code: _.get(customerInfo, 'ref_employee_info.info.code'),
    } : {};
}

const mappingOrientation = (productDetail) => {
    return _.get(productDetail, 'orientation.id') || null;
}

const mappingUtilities = (productDetail) => {
    if (_.size(_.get(productDetail, 'product_utilities')) > 0) {
        const arr = _.mapValues(_.get(productDetail, 'product_utilities'), 'utility');
        return _.map(arr, item => _.get(item, 'id'));
    }
    return [];
}

const mappingBedRoom = (productDetail) => {
    if (_.size(_.get(productDetail, 'furnitures')) > 0) {
        const arr = _.filter(_.mapValues(_.get(productDetail, 'furnitures'), 'furniture'), ['room_type.code', 'BEDROOM']);
        return _.map(arr, item => _.get(item, 'id'));
    }
    return [];
}

const mappingBathRoom = (productDetail) => {
    if (_.size(_.get(productDetail, 'furnitures')) > 0) {
        const arr = _.filter(_.mapValues(_.get(productDetail, 'furnitures'), 'furniture'), ['room_type.code', 'BATHROOM']);
        return _.map(arr, item => _.get(item, 'id'));
    }
    return [];
}

const mappingLivingRoom = (productDetail) => {
    if (_.size(_.get(productDetail, 'furnitures')) > 0) {
        const arr = _.filter(_.mapValues(_.get(productDetail, 'furnitures'), 'furniture'), ['room_type.code', 'LIVINGROOM']);
        return _.map(arr, item => _.get(item, 'id'));
    }
    return [];
}

const mappingKitchenRoom = (productDetail) => {
    if (_.size(_.get(productDetail, 'furnitures')) > 0) {
        const arr = _.filter(_.mapValues(_.get(productDetail, 'furnitures'), 'furniture'), ['room_type.code', 'KITCHEN']);
        return _.map(arr, item => _.get(item, 'id'));
    }
    return [];
}

const mappingUploadProductDocuments = (productDetail) => {
    const arr = _.filter(_.mapValues(_.get(productDetail, 'product_medias'), 'media'), ['media_type.code', 'DOCUMENT']);
    return _.map(arr, item => { return { 'id': _.get(item, 'id'), 'url': _.get(item, 'url') } });
}

const mappingUploadProductImages = (productDetail) => {
    const arr = _.filter(_.mapValues(_.get(productDetail, 'product_medias'), 'media'), ['media_type.code', 'PHOTO']);
    return _.map(arr, item => { return { 'id': _.get(item, 'id'), 'url': _.get(item, 'url') } });
}

const REQUIRED_FIELD = [
    'price', 'property_type', 'title',
    // 'province', 'commission'
];
const LABEL_FIELD = (field) => _.get({
    'province': "Địa điểm của sản phẩm",
    'price': 'Giá bán',
    'property_type': 'Loại hình sản phẩm',
    'title': 'Tiêu đề hiển thị',
    'commission': 'Mức hoa hồng',
}, field, '');

const ContentPageEdit = (props) => {
    const { customerInfo, projectList, productDetail, id } = props;
    const [draftObject, setDraftObject] = useState(() => mappingObject(productDetail));

    const [projectsAvailable, setProjectsAvailable] = useState(_.get(productDetail, 'project') ? true : false);
    const provinces = useProvinces();
    const districts = useDistricts({ provinceId: _.get(draftObject, 'province.id') });
    const wards = useWards({ districtId: _.get(draftObject, 'district.id') });
    const streets = useStreets({ districtId: _.get(draftObject, 'district.id') });
    const utilitiList = useUtilities();
    const furnitureList = useFurnitures();
    const orientationList = useOrientation();
    const propertyTypeList = usePropertyTypes();

    const [urgent, setUrgent] = useState(_.get(draftObject, 'urgent') === true ? 'true' : 'false');
    const [exclusive, setExclusive] = useState(_.get(draftObject, 'exclusive') === true ? 'true' : 'false');
    const [customer, setCustomer] = useState(() => mappingCustomer(customerInfo));
    const [uploadProductDocuments, setUploadProductDocuments] = useState(() => mappingUploadProductDocuments(productDetail));
    const [uploadProductImages, setUploadProductImages] = useState(() => mappingUploadProductImages(productDetail));

    const [orientation, setOrientation] = useState(() => mappingOrientation(productDetail));
    const [utilities, setUtilities] = useState(() => mappingUtilities(productDetail));
    const [bedRoom, setBedRoom] = useState(() => mappingBedRoom(productDetail));
    const [bathRoom, setBathRoom] = useState(() => mappingBathRoom(productDetail));
    const [livingRoom, setLivingRoom] = useState(() => mappingLivingRoom(productDetail));
    const [kitchenRoom, setKitchenRoom] = useState(() => mappingKitchenRoom(productDetail));

    const [moneyStr, setMoneyStr] = useState();
    const [money, setMoney] = useState(_.get(draftObject, 'price'));

    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const { accessObjectPermissions, userInfo } = useContext(StoreContext);
    const configValue = useContext(ConfigContext);

    const [editProductSecondary] = useMutation(EDIT_SECONDARY_PRODUCT, {
        refetchQueries: [{
            query: PRODUCT_FIND_ALL, variables: {
                skip: 0,
                take: 10,
                order_by: { created: "desc" },
                condition: {
                    _and: [
                        { addressByAddress: { district: { _is_null: false } } }
                    ]
                }
            }
        }],
    });

    const handleUpdateClick = (event) => {
        event.preventDefault();
        let check = true;

        _.map(REQUIRED_FIELD, (item) => {
            if (!check) return;
            if (!_.get(draftObject, item)) {
                swal({ title: `${LABEL_FIELD(item)}`, text: `Chưa được nhập thông tin!`, button: 'Xem lại', icon: "info" });
                check = false;
            }
        })
        if (!check) return;

        let pushObject = { ...draftObject };

        _.set(pushObject, 'id', id);

        if (!_.isEmpty(uploadProductImages)) {
            _.set(pushObject, 'avatar', _.get(_.head(uploadProductImages), 'id'));
        }

        if (orientation) {
            _.set(pushObject, 'orientation', orientation);
        }

        if (!_.isEmpty(utilities)) {
            _.set(pushObject, 'utilities', utilities);
        }

        if (!_.isEmpty(bedRoom) || !_.isEmpty(bathRoom) || !_.isEmpty(livingRoom) || !_.isEmpty(kitchenRoom)) {
            let arr = _.concat(bedRoom, bathRoom, livingRoom, kitchenRoom);
            _.set(pushObject, 'furnitures', arr);
        }

        if (!_.isEmpty(uploadProductDocuments) || !_.isEmpty(uploadProductImages)) {
            let arr = _.concat(uploadProductDocuments, uploadProductImages);
            _.set(pushObject, 'photos', _.map(arr, item => _.get(item, 'id')));
        }

        if (projectsAvailable && _.get(pushObject, 'province')) {
            delete pushObject.province
        } else {
            _.set(pushObject, 'province', _.get(pushObject, 'province.id'));
        };
        if (projectsAvailable && _.get(pushObject, 'district')) {
            delete pushObject.district;
        } else {
            _.set(pushObject, 'district', _.get(pushObject, 'district.id'));
        };
        if (projectsAvailable && _.get(pushObject, 'ward')) {
            delete pushObject.ward;
        } else {
            _.set(pushObject, 'ward', _.get(pushObject, 'ward.id'));
        };
        if (projectsAvailable && _.get(pushObject, 'street')) {
            delete pushObject.street;
        } else {
            _.set(pushObject, 'street', _.get(pushObject, 'street.id'));
        }
        if (projectsAvailable && _.get(pushObject, 'address_number')) {
            delete pushObject.address_number;
        } else {
            _.set(pushObject, 'address_number', _.get(pushObject, 'address_number'));
        };

        if (exclusive === 'true') {
            _.set(pushObject, 'exclusive', true);
        } else {
            _.set(pushObject, 'exclusive', false);
        }

        if (urgent === 'true') {
            _.set(pushObject, 'urgent', true);
        } else {
            _.set(pushObject, 'urgent', false);
        }

        //product planning
        if (_.get(draftObject, 'product_plan.province')) {
            _.set(pushObject, 'product_plan.province', _.get(draftObject, 'product_plan.province.id'));
        };
        if (_.get(draftObject, 'product_plan.district')) {
            _.set(pushObject, 'product_plan.district', _.get(draftObject, 'product_plan.district.id'));
        };
        if (_.get(draftObject, 'product_plan.ward')) {
            _.set(pushObject, 'product_plan.ward', _.get(draftObject, 'product_plan.ward.id'));
        };

        swal({
            title: "Cập nhât thông tin sản phẩm",
            icon: "info",
            buttons: {
                confirm: 'Chấp nhận',
                cancel: 'Bỏ qua'
            }
        }).then(async (flag) => {
            if (flag) {
                const checkPlan = _.isEmpty(_.filter(_.get(pushObject, 'product_plan.points'), (o) => { return !_.isNull(o?.x) || !_.isNull(o?.y); }));

                if (checkPlan) {
                    _.set(pushObject, 'product_plan_status_code', 'INITIAL');

                } else {
                    const strJson = {
                        points: _.map(_.get(pushObject, 'product_plan.points'), item => {
                            return {
                                x: _.get(item, 'y'),
                                y: _.get(item, 'x'),
                            }
                        })
                    };
                    const config = { headers: { "Content-Type": "application/json" } }
                    await axios.post(`${configValue.PLANMAP_ENDPOINT}${URL_API_MAP}`, strJson, config).then((res) => {
                        _.set(pushObject, 'product_plan_status_code', 'PLANMAP');
                    }).catch(err => {
                        _.set(pushObject, 'product_plan_status_code', 'NOTPLANMAP');
                    })
                }

                editProductSecondary({
                    variables: {
                        data: pushObject
                    }
                }).then(res => {
                    swal("Thông báo", "Cập nhật sản phẩm thành công", "success");
                    history.push(slugs.productSecondary);
                    return;
                }, (error) => {
                    swal("Vui lòng thử lại", "Có lỗi khi đăng sản phẩm!", "error");
                    return;
                })
            } else {
                return
            }
        });
    };

    useEffect(() => {
        if (_.get(customer, 'customer') === 'Invalid') {
            swal({
                title: "Khách hàng chưa tồn tại!",
                text: "Bạn có muốn tạo khách hàng mới.",
                icon: "warning",
                buttons: {
                    confirm: 'Chấp nhận',
                    cancel: 'Bỏ qua'
                }
            }).then((flag) => {
                if (flag) {
                    history.push('/customer/create');
                } else {
                    setCustomer({});
                }
            });
        }
    }, [customer]);

    useEffect(() => {
        if (_.get(customer, 'customer') === 'Invalid') {
            swal({
                title: "Khách hàng chưa tồn tại!",
                text: "Bạn có muốn tạo khách hàng mới.",
                icon: "warning",
                buttons: {
                    confirm: 'Chấp nhận',
                    cancel: 'Bỏ qua'
                }
            }).then((flag) => {
                if (flag) {
                    history.push(slugs.customerCreate);
                } else {
                    setCustomer({});
                }
            });
        }

    }, [draftObject]);



    useEffect(() => {
        let result = readMillions(money, false);
        setMoneyStr(result);
    }, [money])

    return (
        <>
            <Paper className={classes.root}>
                <div className={classes.headerStyle} >
                    <Typography gutterBottom variant="h4" component='h2'>Chỉnh sửa sản phẩm</Typography>
                </div>

                <FormControl className={classes.formContainer}>
                    <Grid container className={classes.groupRow}>
                        <Typography className={classes.title} variant="h5">Thông tin Khách hàng/ Chủ nhà</Typography>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label, 'required')}>Mã khách hàng</Typography>
                            </Grid>
                            <Grid container className={classes.gridRow} xs={4}>
                                <InputCustomerSearch
                                    {...props}
                                    fieldname='code'
                                    object={customer}
                                    setObject={setCustomer}
                                    // datalist={customerList}
                                    modeEdit
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={4} className={classes.vertical}>
                                <InputTextField
                                    label='Họ và tên khách hàng'
                                    disabled={!_.isEmpty(customer)}
                                    fieldname='name'
                                    object={customer}
                                    modeEdit
                                />
                            </Grid>
                            <Grid xs={4} className={classes.vertical}>
                                <InputTextField
                                    label='Số điện thoại'
                                    disabled={!_.isEmpty(customer)}
                                    fieldname='phone'
                                    object={customer}
                                    modeEdit
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <InputTextField
                                label='Mã nhân viên ECOE giới thiệu'
                                disabled={!_.isEmpty(customer)}
                                fieldname='ref_employee_code'
                                object={customer}
                                modeEdit
                            />
                        </Grid>
                    </Grid>

                    <Divider className={classes.marginDivider} />

                    <Grid container className={classes.groupRow}>
                        <Typography className={classes.title} variant="h5">Thông tin sản phẩm</Typography>
                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={6}>
                                <Typography component='div' className={clsx(classes.label, 'required')}>Vị trí bất động sản</Typography>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={6}>
                                <Typography component='div' className={classes.label}>Chọn tên dự án (nếu có)</Typography>
                                {projectList && (
                                    <InputProjectSearch
                                        {...props}
                                        description='Nhập tên dự án'
                                        fieldname='project'
                                        object={draftObject}
                                        setObject={setDraftObject}
                                        setProjectsAvailable={setProjectsAvailable}
                                        datalist={projectList}
                                        modeEdit
                                        disabled
                                    />
                                )}

                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={6}>
                                <InputTextFieldSearch
                                    {...props}
                                    description='Chọn thành phố'
                                    fieldname='province'
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    disabled={projectsAvailable}
                                    datalist={provinces}
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={6}>
                                <InputTextFieldSearch
                                    {...props}
                                    description='Chọn quận'
                                    fieldname='district'
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    disabled={projectsAvailable}
                                    datalist={districts}
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={6}>
                                <InputTextFieldSearch
                                    {...props}
                                    description='Chọn phường'
                                    fieldname='ward'
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    disabled={projectsAvailable}
                                    datalist={wards}
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={6}>
                                <InputTextFieldSearch
                                    {...props}
                                    description='Chọn tên đường'
                                    fieldname='street'
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    disabled={projectsAvailable}
                                    datalist={streets}
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={6}>
                                <InputTextField
                                    description='Số nhà'
                                    fieldname='address_number'
                                    disabled={projectsAvailable}
                                    fullwidth
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    modeEdit
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label)}>Latitude</Typography>
                            </Grid>
                            <Grid xs={4}>
                                <InputTextField
                                    fieldname='latitude'
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    modeEdit
                                    number
                                    disabled={projectsAvailable}
                                />
                            </Grid>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label)}>Longitude</Typography>
                            </Grid>
                            <Grid xs={4}>
                                <InputTextField
                                    fieldname='longitude'
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    modeEdit
                                    number
                                    disabled={projectsAvailable}
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={6}>
                                <Typography component='div' className={clsx(classes.label, 'required', 'unit')}>Giá bán bất động sản <span>đơn vị: VNĐ</span></Typography>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={6}>
                                <InputTextField
                                    description='Nhập giá tiền bán'
                                    fieldname='price'
                                    setMoney={setMoney}
                                    number
                                    fullwidth
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    maxLength={20}
                                    modeEdit
                                />
                                <Typography component='div' className={clsx(classes.label, 'description')}>bằng chữ: {moneyStr || 0} đồng</Typography>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={8}>
                                <Typography component='div' className={clsx(classes.label, 'required', 'unit')}>Hình ảnh/ Hợp đồng mua bán <span>(Tối đa 10 hình, dung lượng &#60; 5Mb/hình)</span></Typography>
                            </Grid>
                            <Grid xs={8}>
                                <Typography component='div' className={clsx(classes.label, 'description')}>Đối với sổ chụp trang 2 &#38; 3</Typography>
                                <Typography component='div' className={clsx(classes.label, 'description')}>Hợp đồng mua bán chụp đầy đủ các trang hợp đồng</Typography>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <ImagePicker
                                data={uploadProductDocuments}
                                setData={setUploadProductDocuments}
                                numberColumn={3}
                                type='DOCUMENT'
                                buttonType='DEFAULT'
                            />
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label, 'required')}>Loại hình</Typography>
                            </Grid>
                            <Grid xs={6}>
                                <InputDropdown
                                    fieldname='property_type'
                                    discription='Chọn loại hình sản phẩm'
                                    required
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    data={_.filter(propertyTypeList, (element) => element.code !== "P")}
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label, 'required')}>Tựa đề</Typography>
                            </Grid>
                            <Grid xs={6}>
                                <InputTextField
                                    required
                                    description='Nhập tựa đề'
                                    fieldname='title'
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    maxLength={100}
                                    modeEdit
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label)}>Mô tả</Typography>
                            </Grid>
                            <Grid xs={6}>
                                <InputTextField
                                    description='Nhập mô tả'
                                    fieldname='description'
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    multiline
                                    maxLength={1000}
                                    modeEdit
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label)}>Số phòng ngủ</Typography>
                            </Grid>
                            <Grid xs={4}>
                                <InputTextField
                                    fieldname='bed_room'
                                    number
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    maxLength={2}
                                    modeEdit
                                />
                            </Grid>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label)}>Số phòng tắm</Typography>
                            </Grid>
                            <Grid xs={4}>
                                <InputTextField
                                    fieldname='bath_room'
                                    number
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    maxLength={2}
                                    modeEdit
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label)}>Số tầng</Typography>
                            </Grid>
                            <Grid xs={4}>
                                <InputTextField
                                    fieldname='floor'
                                    number
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    maxLength={2}
                                    modeEdit
                                />
                            </Grid>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label)}>Số tầng hầm</Typography>
                            </Grid>
                            <Grid xs={4}>
                                <InputTextField
                                    fieldname='basement'
                                    number
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    maxLength={1}
                                    modeEdit
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label)}>Chiều dài (m)</Typography>
                            </Grid>
                            <Grid xs={4}>
                                <InputTextField
                                    fieldname='area_height'
                                    number
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    maxLength={6}
                                    modeEdit
                                />
                            </Grid>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label)}>Chiều ngang (m)</Typography>
                            </Grid>
                            <Grid xs={4}>
                                <InputTextField
                                    fieldname='area_width'
                                    number
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    maxLength={6}
                                    modeEdit
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label)}>Diện tích tim tường (m2)</Typography>
                            </Grid>
                            <Grid xs={4}>
                                <InputTextField
                                    fieldname='area'
                                    number
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    maxLength={10}
                                    modeEdit
                                />
                            </Grid>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label)}>Diện tích thông thủy (m2)</Typography>
                            </Grid>
                            <Grid xs={4}>
                                <InputTextField
                                    fieldname='area_used'
                                    number
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    maxLength={10}
                                    modeEdit
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label)}>Diện tích sàn (m2)</Typography>
                            </Grid>
                            <Grid xs={4}>
                                <InputTextField
                                    fieldname='area_foor'
                                    number
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    maxLength={10}
                                    modeEdit
                                />
                            </Grid>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label)}>Diện tích xây dựng (m2)</Typography>
                            </Grid>
                            <Grid xs={4}>
                                <InputTextField
                                    fieldname='area_construction'
                                    number
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    maxLength={10}
                                    modeEdit
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={8}>
                                <Typography component='div' className={clsx(classes.label, 'required', 'unit')}>
                                    Hình ảnh sản phẩm <span>(Tối đa 10 hình, dung lượng &#60; 5Mb/hình)</span>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <ImagePicker
                                data={uploadProductImages}
                                setData={setUploadProductImages}
                                numberColumn={4}
                                buttonType='CARD'
                            />
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label)}>Link Video</Typography>
                            </Grid>
                            <Grid xs={6}>
                                <InputTextField
                                    fieldname='video_url'
                                    fullwidth
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    modeEdit
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={2}>
                                <Typography component='span' className={clsx(classes.label)}>Link 3D</Typography>
                            </Grid>
                            <Grid xs={6}>
                                <InputTextField
                                    fieldname='view_3d_url'
                                    fullwidth
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    modeEdit
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider className={classes.marginDivider} />

                    <Grid container className={classes.groupRow}>
                        <Typography className={classes.title} variant="h5">Thông tin khác</Typography>

                        <Grid container className={classes.groupRow} xs={12}>
                            <Typography component='div' className={clsx(classes.label)}>Hướng nhà</Typography>
                            <Grid container xs={12} className={classes.groupOrientation}>
                                {_.map(orientationList, (item, key) =>
                                    <div
                                        key={key}
                                        className={clsx({ selected: _.get(item, 'id') === orientation })}
                                        onClick={() => setOrientation(_.get(item, 'id'))}
                                    >
                                        {_.get(item, 'name')}
                                    </div>
                                )}
                            </Grid>
                        </Grid>

                        <Grid container className={classes.groupRow} xs={12}>
                            <SelectUtilities
                                title='Ưu điểm nổi bật'
                                data={utilitiList}
                                object={utilities}
                                setObject={setUtilities}
                            />
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Typography component='div' className={clsx(classes.label)}>Nội thất</Typography>

                            <Grid container className={classes.groupRow} xs={12}>
                                <SelectUtilities
                                    title='Phòng ngủ'
                                    icon={iconBedRoom}
                                    data={_.get(_.find(furnitureList, { name: 'Phòng ngủ' }), 'furnitures')}
                                    object={bedRoom}
                                    setObject={setBedRoom}
                                />
                            </Grid>

                            <Grid container className={classes.groupRow} xs={12}>
                                <SelectUtilities
                                    title='Phòng tắm'
                                    icon={iconBathRoom}
                                    data={_.get(_.find(furnitureList, { name: 'Phòng tắm' }), 'furnitures')}
                                    object={bathRoom}
                                    setObject={setBathRoom}
                                />
                            </Grid>

                            <Grid container className={classes.groupRow} xs={12}>
                                <SelectUtilities
                                    title='Phòng khách'
                                    icon={iconLivingRoom}
                                    data={_.get(_.find(furnitureList, { name: 'Phòng khách' }), 'furnitures')}
                                    object={livingRoom}
                                    setObject={setLivingRoom}
                                />
                            </Grid>

                            <Grid container className={classes.groupRow} xs={12}>
                                <SelectUtilities
                                    title='Phòng bếp'
                                    icon={iconKitchenRoom}
                                    data={_.get(_.find(furnitureList, { name: 'Phòng bếp' }), 'furnitures')}
                                    object={kitchenRoom}
                                    setObject={setKitchenRoom}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" direction="row" style={{ marginBottom: '26px', marginTop: '10px' }}>
                        <Grid item xs={3}>
                            <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Sản phẩm ECOE độc quyền:</Typography>
                        </Grid>
                        <Grid item className={classes.text}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup row>
                                    <RadioGroup aria-label="roles" name="exclusive" value={exclusive} onChange={(e) => setExclusive(e.target.value)} row>
                                        {_.map([{ id: 1, name: "Có", value: 'true' }, { id: 2, name: "Không", value: 'false' }], (item, index) => (
                                            <FormControlLabel
                                                key={index}
                                                style={{ width: '160px' }}
                                                control={<Radio size="small" value={_.get(item, 'value')} required defaultChecked={exclusive} />}
                                                label={<span style={{ fontSize: '16px' }}>{_.get(item, 'name')}</span>}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" direction="row" style={{ marginBottom: '26px', marginTop: '10px' }}>
                        <Grid item xs={3}>
                            <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Sản phẩm ECOE bán gấp:</Typography>
                        </Grid>
                        <Grid item className={classes.text}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup row>
                                    <RadioGroup aria-label="roles" name="urgent" value={urgent} onChange={(e) => setUrgent(e.target.value)} row>
                                        {_.map([{ id: 1, name: "Có", value: 'true' }, { id: 2, name: "Không", value: 'false' }], (item, index) => (
                                            <FormControlLabel
                                                key={index}
                                                style={{ width: '160px' }}
                                                control={<Radio size="small" value={_.get(item, 'value')} required defaultChecked={urgent} />}
                                                label={<span style={{ fontSize: '16px' }}>{_.get(item, 'name')}</span>}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" style={{ marginBottom: '26px', marginTop: '10px' }}>
                        <Grid item xs={12}>
                            <Typography className={classes.text} style={{ fontWeight: 'bold', paddingBottom: 30 }}>Quy hoạch</Typography>
                        </Grid>
                        {/* <Grid item xs={12}>
                        <p>Hình ảnh sổ</p>
                        <div>Slide</div>
                    </Grid>
                    <Grid item xs={12}>
                        <p>Hình ảnh sổ</p>
                        <div>Slide</div>
                    </Grid> */}
                        <PlanningInformationEdit object={draftObject} setObject={setDraftObject} />
                    </Grid>

                    <Divider className={classes.marginDivider} />

                    <Grid container className={classes.groupRow}>
                        <Typography className={classes.title} variant="h5">Thông tin Kiểm duyệt</Typography>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={3}>
                                <Typography component='div' className={clsx(classes.label)}>Mức hoa hồng cho ECOE</Typography>
                            </Grid>
                            <Grid xs={3}>
                                <InputTextField
                                    description='Hoa hồng cho ECOE'
                                    fieldname='commission'
                                    number
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    maxLength={5}
                                    modeEdit
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridRow} xs={12}>
                            <Grid xs={3}>
                                <Typography component='div' className={clsx(classes.label)}>Ghi chú tin đăng</Typography>
                            </Grid>
                            <Grid xs={6}>
                                <InputTextField
                                    description='Ghi chú tin đăng'
                                    fieldname='remark'
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    multiline
                                    maxLength={1000}
                                    modeEdit
                                />
                            </Grid>
                        </Grid>


                    </Grid>

                    <Divider className={classes.marginDivider} />
                </FormControl>

                <div className={classes.footerStyle}>
                    <Button
                        variant="contained"
                        color='primary'
                        startIcon={<Update />}
                        onClick={handleUpdateClick}
                    >Cập nhật</Button>

                    <ButtonReturn link={slugs.productSecondary} />
                </div>
            </Paper >

        </>

    )
};


const ProductEdit = props => {
    const classes = useStyles();
    const { id } = useParams();
    const { data: productDetail, loading: productLoading } = useGetProductDetail({ productId: id });
    const { data: projectList, loading: projectLoading } = usePrjects();
    const { data: customerDetail, loading: loadingCustomerDetail } = useGetCustomer(_.get(productDetail, 'customer.customers[0].id'))

    if (productLoading || projectLoading || loadingCustomerDetail) {
        return (
            <div className={classes.wapperLoading}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <ContentPageEdit
            customerInfo={_.get(customerDetail, 'results')}
            projectList={projectList}
            productDetail={productDetail}
            id={id}
        />
    )
};

export default ProductEdit;
