
import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import NO_IMAGE from '../assets/images/no-image.svg';
import clsx from 'clsx';
import areaIcon from '../assets/icons/card-area.svg';
import bathRoomIcon from '../assets/icons/card-bath-room.svg';
import bedRoomIcon from '../assets/icons/card-bed-room.svg';
import { Divider } from '@material-ui/core';
import Moment from 'react-moment';
import { useProductPlanMapStatus } from '../graphql/schemas/hooks';

import { ConfigContext } from '../context/ConfigContext';
import { LABLE_STATUS_PRODUCT_TAG, USER_ROLE_ADMINISTRATOR, USER_ROLE_SALES, USER_ROLE_SUPPER_ADMIN } from '../constants/defined';


const useStyles = makeStyles({
    root: {
        width: '100%',
        borderRadius: 10,
        // maxHeight: '440px',
        marginLeft: '6px',
        marginTop: '6px'
    },
    card: {
        display: 'flex',
        direction: 'column',
        paddingRight: '6px',
        paddingTop: '6px'
    },
    media: {
        height: 230,
    },
    kindHome: {
        color: "#e27f35",
        fontSize: '14px',
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: '8px'
    },
    contentStyle: {
        width: '100%',
    },
    titleBottom: {
        fontSize: '15px',
        fontWeight: 'bold',
    },
    button: {
        backgroundColor: '#2c8a81',
        color: "#ffffff",
        borderRadius: 0,
        fontSize: '14px',
        textTransform: 'none',
    },
    boxCardBottom: {
        backgroundColor: '#2c8a81',
        width: '30px',
        height: '10px'
    },
    codeMediaTop: {
        marginLeft: 'auto',
        marginTop: '12px',
        color: '#ffffff',
        marginRight: '16px',
    },

    price: {
        color: '#00837B',
        fontWeight: 600,
        marginBottom: '2px',
        minWidth: 100
    },

    styleTitle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    styleAddress: {
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkitBoxOrient': 'vertical',
        '-webkitLineClamp': 1,
    },
    groupItemInfoStyle: {
        display: 'flex',
        alignItems: 'center',
        '& > div:not(:last-child)': {
            paddingRight: '15px',
        },
    },
    itemInfoStyle: {
        '& > img': {
            height: '13px',
            paddingRight: '5px',
        },
        '& > span': {
            fontSize: '15px',
            color: '#0A2240',
        }
    },
    wrapperTag: {
        position: 'absolute',
        top: '15px',
        left: '10px',
        display: 'inline-flex',
        fontSize: '11px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: '#fff',

        '& > div': {
            padding: '5px 10px',
            borderRadius: '6px',
        },

        '& > div:not(:first-child)': {
            marginLeft: '10px',
        },
    },

    wrapperLabelStatus: {
        '&.waiting': {
            backgroundColor: '#0e7043',
        },
        // '&.waiting:after': {
        //     borderLeft: '15px solid #0e7043',
        // },

        '&.selling': {
            backgroundColor: '#0f295c',
        },
        // '&.selling:after': {
        //     borderLeft: '15px solid #0f295c',
        // },

        '&.sold': {
            backgroundColor: '#cece06',
        },
        // '&.sold:after': {
        //     borderLeft: '15px solid #cece06',
        // },

        '&.hidden-status': {
            backgroundColor: '#f77b00',
        },
        // '&.hidden-status:after': {
        //     borderLeft: '15px solid #f77b00',
        // },

        '&.locked-status': {
            backgroundColor: '#ff0000',
        },
        // '&.locked-status:after': {
        //     borderLeft: '15px solid #ff0000',
        // },

        '&:after': {
            content: `''`,
            position: 'absolute',
            top: '0px',
            right: '-15px',
            width: 0,
            height: 0,
            borderTop: '11px solid transparent',
            borderBottom: '11px solid transparent',
        },
    },

    wrapperPlanStatus: {
        '&.initial': {
            backgroundColor: '#e07e37',
        },

        '&.notplanmap': {
            backgroundColor: '#f4c867',
        },

        '&.planmap': {
            backgroundColor: '#77ba6a',
        },

        '&:after': {
            content: `''`,
            position: 'absolute',
            top: '0px',
            right: '-15px',
            width: 0,
            height: 0,
            borderTop: '11px solid transparent',
            borderBottom: '11px solid transparent',
        },
    },
    wrpperBottomCard: {
        width: 'calc(100% - 32px)',
        margin: '10px auto',
        fontSize: '12px',
        color: '#707070',
        '& p.sales': {
            color: '#E07E37',
        },
        '& p.supper_admin': {
            color: '#00837B',
        }
    }
});

const SecondaryCard = ({ data, openMenu, isPrimary }) => {
    const classes = useStyles();
    const { data: dataPlanMapStatus } = useProductPlanMapStatus();

    const context = useContext(ConfigContext);

    const id = _.get(data, 'id');
    const typeStatus = _.get(data, 'active') ? _.get(data, 'status') : 'LOCKED';
    let addressFull = _.join(_.pull([_.get(data, 'address.short', ''), _.get(data, 'address.street.name', ''), _.get(data, 'address.ward.name', ''), _.get(data, 'address.district.name', ''), _.get(data, 'address.province.name', '')], '', null), ', ');

    if (!data) return null;

    return (
        <Fragment>
            <Grid item className={classes.card} xs={12} sm={6} md={4} lg={3}>
                <Card className={classes.root}>
                    <CardActionArea
                        disableRipple
                        style={{ maxHeight: 385 }}
                        to={`/product/${isPrimary ? 'primary/details' : 'secondary/detail'}/${id}`}
                        component={Link}
                    >
                        <CardMedia
                            className={classes.media}
                            image={(_.get(data, "avatar.url")) ? context.MEDIA_DOMAIN + _.get(data, "avatar.url") : NO_IMAGE}
                            title={_.get(data, 'title') || 'Đang cập nhật...'}
                        >
                            <div className={classes.wrapperTag}>
                                <div
                                    className={clsx(classes.wrapperLabelStatus,
                                        (typeStatus === 'WAITING') && 'waiting',
                                        (typeStatus === 'SELLING') && 'selling',
                                        (_.includes(['SOLD', 'SOLD_OTHER', 'DEPOSIT'], typeStatus)) && 'sold',
                                        (typeStatus === 'HIDDEN') && 'hidden-status',
                                        (typeStatus === 'LOCKED') && 'locked-status',
                                    )}
                                >
                                    {LABLE_STATUS_PRODUCT_TAG(typeStatus)}
                                </div>
                                <div className={clsx(classes.wrapperPlanStatus, _.toLower(_.get(data, 'plan_status.code', 'INITIAL')))} >
                                    {_.get(data, 'plan_status.name', _.get(_.find(dataPlanMapStatus, ['code', 'INITIAL']), 'name'))}
                                </div>
                            </div>
                        </CardMedia>
                        <CardContent style={{ padding: '8px 16px' }}>

                            <Grid container direction="column">
                                <Grid container direction="column">
                                    <Grid item className={classes.kindHome}>
                                        <HomeWorkIcon style={{ marginRight: '8px', fontSize: '18px' }} /> {_.get(data, 'property_type.name') || "Không có loại hình"}
                                    </Grid>
                                    <Grid item className={classes.contentStyle}>
                                        <Typography className={classes.styleTitle} gutterBottom>
                                            {_.get(data, 'title') || 'Đang cập nhật...'}
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.contentStyle}>
                                        <Typography className={classes.styleAddress} gutterBottom style={{ fontSize: '14px', color: 'lightgray' }}>
                                            {addressFull || 'Đang cập nhật...'}
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.groupItemInfoStyle}>
                                        <Grid item className={classes.itemInfoStyle}>
                                            <img src={areaIcon} alt='icon' />
                                            <span style={{ alignSelf: 'center', marginTop: '3px' }}>{_.get(data, 'area') || '0'} m <sup>2</sup></span>
                                        </Grid>
                                        <Grid item className={classes.itemInfoStyle}>
                                            <img src={bathRoomIcon} alt='icon' />
                                            <span style={{ alignSelf: 'center', marginTop: '3px' }}>{_.get(data, 'bath_room') || '0'}</span>
                                        </Grid>
                                        <Grid item className={classes.itemInfoStyle}>
                                            <img src={bedRoomIcon} alt='icon' />
                                            <span style={{ alignSelf: 'center', marginTop: '3px' }}>{_.get(data, 'bed_room') || '0'}</span>
                                        </Grid>
                                    </Grid>

                                </Grid>

                            </Grid>

                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Grid style={{ marginLeft: '8px' }} container direction="row" alignItems="flex-end">
                            <Grid item sm={6}>
                                <Typography className={classes.price}>
                                    {_.get(data, "price_to_display") ? _.get(data, "price_to_display") : _.get(data, "price")} {_.get(data, "price_unit.name") || "Tỷ"}
                                </Typography>
                            </Grid>
                            {/* <Grid item style={{ marginLeft: 'auto' }} sm={6}>
                                <CardActions>
                                    <Button variant="contained" className={classes.button}>Lịch hẹn</Button>
                                </CardActions>
                            </Grid> */}
                        </Grid>
                    </CardActions>
                    <Divider />
                    <div className={classes.wrpperBottomCard}>
                        <Grid container direction="row">
                            <Grid item>
                                <Typography>Ngày tạo: {_.get(data, "created") ? <Moment format="DD/MM/YYYY">{_.get(data, 'created')}</Moment> : '-'}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent='space-between'>
                            {
                                _.get(data, "updated_by_role") ? (
                                    <Fragment>
                                        <Grid item>
                                            <Typography>Cập nhật: <Moment format="DD/MM/YYYY">{_.get(data, 'updated')}</Moment></Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={_.toLower(_.get(_.head(_.get(data, "updated_by_role.account_roles")), 'roleByRole.code'))}>
                                                {
                                                    _.toUpper(_.get(_.head(_.get(data, "updated_by_role.account_roles")), 'roleByRole.code')) === USER_ROLE_SALES ? "bởi Agent"
                                                        : _.includes([USER_ROLE_SUPPER_ADMIN,USER_ROLE_ADMINISTRATOR], _.toUpper(_.get(_.head(_.get(data, "updated_by_role.account_roles")), 'roleByRole.code'))) ? "bởi Admin"
                                                            : undefined
                                                }
                                            </Typography>
                                        </Grid>
                                    </Fragment>
                                )
                                    :
                                    (
                                        <Fragment>
                                            <Grid item>
                                                <Typography>Cập nhật</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>-</Typography>
                                            </Grid>
                                        </Fragment>
                                    )
                            }
                        </Grid>
                    </div>
                </Card>
            </Grid>

        </Fragment>

    );
}

export default SecondaryCard;
