import { Button, CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import { useParams } from "react-router";
import DescriptionIcon from "@material-ui/icons/Description";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import swal from "sweetalert";
import TableComponent from "../../../components/TableComponent";
import SelectComponent from "../../Customer/components/SelectComponent";
import { useGetEventCustomers, useGetGiftTypes } from "../hooks";
import { checkedPermission } from "../../../helper";
import { EXPORT_PROMOTION } from "../../../constants/defined";
import { StoreContext } from "../../../resources/store";

const useStyles = makeStyles((theme) => ({
  buttonExport: {
    borderRadius: 0,
    color: "#ffffff",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#82868B",
    textTransform: "none",
    width: 130,

    "&.matches-md ": {
      marginTop: "20px",
    },
  },
  wapperLoading: {
    width: '100%',
    height: 'calc(100vh - 113px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const headMenu = [
  { id: "rank", numeric: false, disablePadding: false, label: "Hạng" },
  {
    id: "code",
    numeric: false,
    disablePadding: false,
    label: "Mã khách hàng",
  },
  {
    id: "full_name",
    numeric: false,
    disablePadding: false,
    label: "Tên khách hàng",
  },
  {
    id: "invited",
    numeric: false,
    disablePadding: false,
    label: "Số bạn bè đã mời",
  },
  {
    id: "ecoin",
    numeric: false,
    disablePadding: false,
    label: "Số coin đã tạo",
  },
  {
    id: "ecoin_exchange",
    numeric: false,
    disablePadding: false,
    label: "Số coin đã đổi",
  },
  {
    id: "remain_ecoin",
    numeric: false,
    disablePadding: false,
    label: "Số coin còn lại",
  },
];

const PER_PAGE = 10;

const Customers = ({ gameType }) => {
  const { id } = useParams();
  const [searchText, setSearchText] = useState();
  const [tableData, setTableData] = useState([]);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const { data: listGiftType } = useGetGiftTypes();
  const [filterResult, setFilterResult] = useState(null);

  const { loading, data } = useGetEventCustomers(id);

  useEffect(() => {
    const tmp = _.map(data, (item, index) => {
      return {
        rank: index + 1,
        code: _.get(item, "account_info.code"),
        full_name: _.get(item, "account_info.full_name"),
        invited: _.get(item, "account_info.total_friend.aggregate.count"),
        ecoin: _.get(item, "ecoin") + _.get(item, "room_ecoin"),
        ecoin_exchange: _.get(item, "ecoin_change_gift"),
        remain_ecoin:
          _.get(item, "ecoin") +
          _.get(item, "room_ecoin") -
          _.get(item, "ecoin_change_gift"),
      };
    });
    setTableData(tmp);
  }, [data]);

  const classes = useStyles();

  const { userInfo, accessObjectPermissions } = useContext(StoreContext);

  const handleExport = () => {
    const ExcelJSWorkbook = new ExcelJS.Workbook();
    const worksheet = ExcelJSWorkbook.addWorksheet("ExcelJS sheet");
    const header = [
      "Hạng",
      "Mã khách hàng",
      "Tên khách hàng",
      "Số bạn bè đã mời",
      "Số coin đã tạo",
      "Số coin đã đổi",
      "Số coin còn lại",
    ];

    const dataToExport = [];

    if (tableData !== undefined && tableData.length > 0) {
      let i = 1;
      tableData.forEach((value) => {
        dataToExport.push([
          _.get(value, "rank"),
          _.get(value, "code"),
          _.get(value, "full_name"),
          _.get(value, "invited"),
          _.get(value, "ecoin"),
          _.get(value, "ecoin_exchange"),
          _.get(value, "remain_ecoin"),
        ]);
        i++;
      });
    } else {
      swal({
        title: "Thông báo",
        text: "Không có dữ liệu!",
        icon: "warning",
      });
      return;
    }

    const customCell = worksheet.getCell("A1");
    customCell.alignment = { vertical: "middle", horizontal: "center" };
    customCell.font = {
      name: "Century",
      family: 4,
      bold: true,
    };

    worksheet.addRow();
    const headerRow = worksheet.addRow(header);
    headerRow.font = {
      name: "Century",
      family: 4,
      bold: true,
    };

    let columnSTT = 1;
    worksheet.columns.forEach((column) => {
      let dataMax = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        dataMax = cell.value ? cell.value.toString().length : 0;
      });
      if (columnSTT === 1) {
        column.width = 5;
        columnSTT++;
      } else if (dataMax <= 2) {
        column.width = 10;
      } else {
        column.width = dataMax <= 15 ? 20 : dataMax;
      }
    });

    if (dataToExport.length > 0) {
      dataToExport.forEach((value) => {
        worksheet.addRow(value);
      });
    }

    customCell.value = "Danh sách khách hàng";
    worksheet.mergeCells(`A1:${String.fromCharCode(64 + _.size(header))}1`);

    ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `Danh_sach_khach_hang_game_${gameType}.xlsx`
      );
    });
  };

  if (loading) {
    return (
      <div className={classes.wapperLoading}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <Grid container direction="column" spacing={2} style={{ marginTop: 40 }}>
      <Grid item container alignItems="flex-end">
        {/* <Grid item xs={3}>
          <TextField
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Grid> */}

        {/* {gameType === "JACK" && (
          <Grid
            item
            className={classes.textLabelForm}
            xs={3}
            style={{ paddingRight: "30px" }}
          >
            <Typography>Loại giải thưởng</Typography>
            <SelectComponent
              width="100%"
              listData={
                listGiftType && [
                  { code: "ALL", id: 0, name: "Tất cả" },
                  ...listGiftType,
                ]
              }
              fieldValue="code"
              fieldTitle="name"
              handleChange={(e) => setFilterResult(e.target.value)}
              defaultValue="ALL"
            />
          </Grid>
        )} */}

        <Grid item xs={2}>
          <Button
            variant="contained"
            className={classes.buttonExport}
            onClick={() => handleExport()}
            disabled={!checkedPermission(userInfo, accessObjectPermissions, EXPORT_PROMOTION)}
          >
            <DescriptionIcon style={{ fontSize: 14 }} />
            <span style={{ fontSize: 13 }}>Xuất dữ liệu</span>
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <TableComponent
          align="center"
          headMenu={headMenu}
          data={_.slice(tableData, skip, page * PER_PAGE)}
          searchText={searchText}
          setSkip={(skip) => setSkip(skip)}
          page={page}
          setPage={setPage}
          count={data.length}
          isPromotionRouter
        />
      </Grid>
    </Grid>
  );
};

export default Customers;
