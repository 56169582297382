import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, TextField } from '@material-ui/core';
import { handleFlex } from '../../../helper/index';
import StarRatings from 'react-star-ratings';


const ReviewDetail = () => {
    const useStyles = makeStyles((theme) => ({
        bold: {
            fontWeight: 'bold'
        },
        sideRight: {
            marginLeft: 10,
            width: 200
        },
        title: {
            width: 170,
            fontWeight: 'bold'
        },
        ml2: {
            marginLeft: 20
        },
        ml6: {
            marginLeft: 60
        },
        mr1: {
            marginRight: 10
        },
        mr2: {
            marginRight: 20
        },
        mt3: {
            marginTop: 30
        },
        w10: {
            width: 100
        },
        w20: {
            width: 200
        }
    }))

    const classes = useStyles();

    return (
        <Grid container direction="column" style={{ marginTop: 40 }}>
            <Grid container direction="row" alignItems="center">
                <Grid item xs={3} style={handleFlex('row', 'center')}>
                    <Typography className={classes.title}>Tên khách hàng: </Typography>
                    <Typography className={classes.sideRight}> Huy Nguyễn</Typography>
                </Grid>
                <Grid item xs={3} style={handleFlex('row', 'center')} >
                    <Typography className={classes.title}>Mã khách hàng: </Typography>
                    <Typography className={classes.sideRight}> C110000</Typography>
                </Grid>
                <Grid item xs={3} style={handleFlex('row', 'center')}>
                    <Typography className={classes.title}>Số điện thoại: </Typography>
                    <Typography className={classes.sideRight}> 0335644587</Typography>
                </Grid>
                <Grid item xs={3} style={handleFlex('row', 'center')}>
                    <Typography className={classes.title}>CMND/CCCD: </Typography>
                    <Typography className={classes.sideRight}> 321563654</Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" style={{ marginTop: 40 }}>
                <Grid item xs={3} style={handleFlex('row', 'center')}>
                    <Typography className={classes.title}>Mã nhân viên ECOE: </Typography>
                    <Typography className={classes.sideRight}> E0069</Typography>
                </Grid>
                <Grid item xs={3} style={handleFlex('row', 'center')} >
                    <Typography className={classes.title}>Mã quản lý sales: </Typography>
                    <Typography className={classes.sideRight}> E0069</Typography>
                </Grid>
                <Grid item xs={3} style={handleFlex('row', 'center')} >
                    <Typography className={classes.title}>Mã nhóm: </Typography>
                    <Typography className={classes.sideRight}> N002</Typography>
                </Grid>
                <Grid item xs={3} style={handleFlex('row', 'center')} >
                    <Typography className={classes.title}>Mã văn phòng: </Typography>
                    <Typography className={classes.sideRight}> BRANCH001</Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" style={{ marginTop: 40 }}>
                <Grid item xs={3} style={handleFlex('row', 'center')}>
                    <Typography className={classes.title}>Thời gian giao dịch: </Typography>
                    <TextField className={classes.sideRight} disabled={true} variant="outlined" size="small" />
                </Grid>
                <Grid item xs={3} style={handleFlex('row', 'center')} >
                    <Typography className={classes.title}>Mã quản lý sales: </Typography>
                    <Typography className={classes.sideRight}> BRANCH001</Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" style={{ marginTop: 40 }}>
                <Grid item xs={3} style={handleFlex('row', 'center')}>
                    <Typography className={classes.title}>Hạng mục yêu thích: </Typography>
                    <Typography className={classes.sideRight}> E0069</Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" style={{ marginTop: 40 }}>
                <Grid item xs={12} style={handleFlex('row', 'center')}>
                    <Typography className={classes.title}>Điểm đánh giá tối đa: </Typography>
                    <StarRatings
                        style={classes.rating}
                        rating={4.5}
                        starDimension="26px"
                        // changeRating={changeRating}
                        starRatedColor="black"
                        starHoverColor="gray"
                        name="rating"
                        starSpacing={3}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" style={{ marginTop: 40 }}>
                <Grid item xs={3} style={handleFlex('row', 'center')}>
                    <Typography className={classes.title}>Ghi chú: </Typography>
                    <Typography className={classes.sideRight}> Không</Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" style={{ marginTop: 40 }}>
                <Grid item xs={3} style={handleFlex('row', 'center')}>
                    <Typography className={classes.title}>Người tạo: </Typography>
                    <Typography className={classes.sideRight} style={{ textDecoration: 'underline' }}> E0009</Typography>
                </Grid>
                <Grid item xs={3} style={handleFlex('row', 'center')} >
                    <Typography className={classes.title}>Ngày tạo: </Typography>
                    <Typography className={classes.sideRight}> 01/01/2021</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ReviewDetail
