import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Grid, Button, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TableComponent from '../components/TableComponent';
import * as _ from 'lodash';
import { Tune, PostAdd } from '@material-ui/icons';
import Moment from 'react-moment';
import { numberWithCommas } from '../../../../helper';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 'calc(100vh - 80px)',
        position: 'relative',
        width: 'calc(100%)',
        boxShadow: 'none'
    },
    buttonAdd: {
        backgroundColor: '#2C8A81',
        borderRadius: '0',
        color: '#ffffff',
        textTransform: 'none',
        padding: '10px 30px'
    },
    topTitleContainer: {
        paddingTop: '30px',
        justifyContent: "space-between"
    },
    tableContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 20,
    },
    tableHeader: {
        flexDirection: 'column',
        textAlign: 'center',
        padding: '10px 0',
    },
    wapperLoading: {
        width: '100%',
        height: 'calc(100vh - 113px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

const headMenu = [
    { id: 'code', numeric: false, disablePadding: false, label: 'Mã số ECOE đặt chỗ' },
    { id: 'index', numeric: false, disablePadding: false, label: 'STT ráp căn' },
    { id: 'assembly_turn', numeric: false, disablePadding: false, label: 'Đợt ráp căn' },
    { id: 'booking_investor_code', numeric: false, disablePadding: false, label: 'Mã số đặt chỗ' },
    { id: 'customer', numeric: false, disablePadding: false, label: 'Tên khách hàng' },
    { id: 'customer_code', numeric: false, disablePadding: false, label: 'Mã khách hàng' },
    { id: 'employee_code', numeric: false, disablePadding: false, label: 'Mã NVTV' },
    { id: 'employeeManager', numeric: false, disablePadding: false, label: 'TBP/ SLK' },
    { id: 'created', numeric: false, disablePadding: false, label: 'Ngày đặt chỗ' },
    { id: 'confirmPayment', numeric: false, disablePadding: false, label: 'Xác nhận chuyển tiền' },
    { id: 'amount', numeric: false, disablePadding: false, label: 'Số tiền đặt cọc' },
    { id: 'product', numeric: false, disablePadding: false, label: 'Chọn căn' },
    { id: 'priority', numeric: false, disablePadding: false, label: 'Loại booking/ Ưu tiên' },
    { id: 'timer', numeric: false, disablePadding: false, label: 'Thời gian còn lại' },
]

const setMappingStatusBooking = ({ priority, statusCart, booking, projectCart }) => {
    const statusBooking = _.get(booking, 'booking_status.code');

    if (statusBooking === 'DONE' || statusBooking === 'COMPLETED') return "Chọn căn thành công";

    if (_.includes(['DEPOSIT', 'CHECKING_DEPOSIT'], statusBooking)) return "Đặt cọc";

    if (statusBooking === 'BLOCKED') return "Khoá căn";

    if (statusCart === 'OPENSALE' && statusBooking === 'SKIP') {
        return "Huỷ căn";
    }

    if (priority > 0 && booking.product && (statusCart === 'ASSEMBLED' || (statusCart === 'OPENSALE' && !_.get(projectCart, 'sale_end')))) {
        return `Ưu tiên ${priority}`;
    }


    if (_.includes(['INITIAL', 'BOOKING', 'CANCEL'], statusBooking) || (statusCart === 'BOOKING')) {
        return "";
    }

    return "Chưa chọn căn";

}
const BookingList = ({ status, setKindCartShow, setDetailBooking, selectProject, data }) => {

    const classes = useStyles();
    const [dataBookings, setDataBookings] = useState([]);
    const { id } = useParams();


    const returnRemainTime = (item) => {
        let hours = 0;
        let minutes = 0;
        let seconds = 0;
        let time = _.get(status, 'code') === 'OPENSALE' ? _.get(item, 'sale_priority_end') : _.get(item, 'assembly_est_end');
        if (time <= 0) {
            return null;
        }
        hours = Math.floor(time / 3600);
        minutes = Math.floor(time % 3600 / 60);
        seconds = Math.floor(time % 3600 % 60);
        return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;

    }


    useEffect(() => {
        let tmp = _.map(data?.bookings, (item, key) => ({
            order: key,
            id: _.get(item, 'id'),
            assembly_turn: _.get(status, 'code') === 'BOOKING' ? '' : _.get(item, 'assembly_turn'),
            code: item?.code, // mã số ecoe đặt chỗ
            index: item?.index, // stt ráp căn
            booking_investor_code: item?.booking_investor_code, //mã số đặt chỗ
            customer: item?.customer_info.full_name,
            customer_code: item?.customer_info.code,
            employee_code: item?.employee_info.code, //mã số sale or slk

            employeeManager: _.get(item, 'employee_info.employees[0].manager_info.account_info.full_name'), //manager sale
            created: <Moment format="DD/MM/YYYY - h:mm A">{item?.created}</Moment>,
            confirmPayment: ((_.get(item, 'index') > 0 || _.includes(['BOOKING_DONE', 'DEPOSIT', 'CHECKING_DEPOSIT', 'DONE'], _.get(item, 'booking_status.code'))) ? ('Đã chuyển tiền giữ chỗ') : (_.includes(['BOOKING', 'INITIAL'], _.get(item, 'booking_status.code')) ? ('Chưa chuyển tiên') : ('Huỷ'))),
            product: (_.includes(['OPENSALE', 'ECOECART', 'AFTERSALE'], _.get(status, 'code')) && _.get(item, 'booking_status.code') === 'SKIP') ? '0' : _.get(item, 'product.code'), // chọn căn
            priority: setMappingStatusBooking({ priority: _.get(item, 'priority'), statusCart: _.get(status, 'code'), booking: item, projectCart: data }), //loại booking or ưu tiên
            timer: returnRemainTime(item),
            status: _.get(item, 'booking_status.code'),
            customer_id: item?.customer_info.customers[0]?.id,
            employee_id: item?.employee_info.id,
            manager_id: item?.employee_info?.employees[0]?.manager_info?.account_info?.id,
            amount: numberWithCommas(_.reduce(_.get(item, 'payments'), (acc, curr) => { return acc + curr.amount }, 0) || 0),
        }))

        setDataBookings(tmp);

    }, [data]);


    return (
        <Grid container direction="column">
            <Grid container direction="row" className={classes.topTitleContainer}>
                <Grid item>
                    <Button
                        variant="contained"
                        className={classes.buttonAdd}
                        startIcon={<Tune />}
                        component={Link} to={`/product/primary/cart/create/${id}`}
                        disabled={_.includes(['ECOECART', 'AFTERSALE'], _.get(status, 'code'))}
                    >
                        Cài đặt giỏ hàng
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        className={classes.buttonAdd}
                        startIcon={<PostAdd />}
                        component={Link} to={`/product/primary/booking/create/${id}`}
                        disabled={_.includes(['ECOECART', 'AFTERSALE'], _.get(status, 'code'))}
                    >
                        Thêm Booking
                    </Button>
                </Grid>
            </Grid>

            <Grid container className={classes.tableContainer}>
                <Grid container className={classes.tableHeader}>
                    <Typography style={{ fontWeight: 'bold' }}>
                        {`Danh sách booking ${_.get(selectProject, 'indexCard.name')}`}
                    </Typography>
                    <Typography style={{ fontWeight: 'bold' }}>
                        {_.get(selectProject, 'title') && `Dự án ${_.get(selectProject, 'title')}`}
                    </Typography>
                    {/* {!_.isEmpty(status) && (
                        <Typography style={{ color: 'red' }}>
                            Thời gian
                            <span style={{ fontWeight: 'bold', padding: '0 5px' }}>{_.get(status, 'name')}</span>
                            còn lại:
                            {moment(_.get(selectProject, 'indexCard.dayStart')).fromNow()}
                        </Typography>
                    )} */}
                </Grid>

                <Paper className={classes.root}>
                    {
                        _.size(dataBookings) > 0 ? (
                            <TableComponent
                                data={dataBookings}
                                headMenu={headMenu}
                                status={status}
                                setKindCartShow={setKindCartShow}
                                setDetailBooking={setDetailBooking}
                            />
                        )
                            :
                            (
                                <Typography>Không có dữ liệu thích hợp</Typography>
                            )
                    }

                </Paper >
            </Grid>
        </Grid>
    )
}

export default BookingList;
