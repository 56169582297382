import { gql } from '@apollo/client';

// export const UPDATE_DEVICE_BY_ACCOUNT = gql`
// mutation UpdateDeviceByAccount($data: account_device_insert_input!) {
//     insert_account_device_one(object: $data, on_conflict: {constraint: account_device_pkey, update_columns: device}) {
//       account: accountByAccount {
//         id
//       }
//     }
//   }  
// `
export const UPDATE_DEVICE_BY_ACCOUNT = gql`
  mutation UpdateAccountDevice($data: DeviceInput!) {
    result: updateAccountDevice(device: $data) {
      id
      device_id
      device_info
      device_token
      os_info
      version_info
      apns_token
      app_type
    }
  }
`;

export const DELETE_DEVICE_BY_ACCOUNT = gql`
mutation DeleteDeviceByAccount($device_token: String!) {
  delete_account_device(where: {deviceByDevice: {device_token: {_eq: $device_token}}}) {
    affected_rows
  }
  delete_device(where: {device_token: {_eq: $device_token}}) {
    affected_rows
  }
}
`

export const UPDATE_NOTIFICATION_ACCOUNT_SEEN = gql`
mutation updateNotificationAccountSeen($notification: uuid!, $account: bigint!) {
  result: update_notification_account(_set: {updated: "now()", seen: true}, where: {_and: {notification: {_eq: $notification}, account: {_eq: $account}}}) {
    returning {
        account: accountByAccount {
            id
            code
            username
            full_name
            email
            phone
            birthday
        }
        notification: notificationByNotification{
            id
            type 
            notification_type {
                id
                code
                name
            }
            deep_link 
            url_image 
            title
            body
            created_by: account {
            id
            code
            username
            full_name
            email
            phone
            birthday
            }
        
        }
    }
  }
}

`