import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import * as _ from "lodash";
import { Waypoint } from 'react-waypoint';
import SecondaryCard from '../SecondaryCard';

const useStyles = makeStyles({
    root: {
        marginTop: '30px',
    },
    nodataStyle: {
        color: "#E27F35",
        fontSize: '16px',
        fontWeight: 'bold'
      }
});

const ProductCare = ({ data, loading, accountId, fetchMore, PER_PAGE }) => {

    const classes = useStyles();

    const count = _.get(data, 'product_request_aggregate.aggregate.count');
  
    if (loading)
        return <CircularProgress />

    if (count === 0) 
        return (
            <Grid container direction="row" className={classes.root}>
            <Typography className={classes.nodataStyle}>
                Không có dữ liệu thích hợp 
            </Typography>
            </Grid>
        )

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item>
                <Typography style={{ fontSize: '20px', fontWeight: 'bold' }} variant="h6">
                    Sản phẩm KH quan tâm
                </Typography>
                <Grid container direction="row" style={{marginTop: '14px'}}>
                    {_.size(_.get(data, 'products')) > 0 ? _.map(data.products, (item, index) => (
                         <Fragment key={index}>
                            <SecondaryCard data={item} isPrimary={_.get(item,'property_type.code') === 'P'} />
                            {index === _.size(data.products) - 1 && (
                                <Waypoint
                                    onEnter={() =>
                                    fetchMore({
                                        variables: {
                                            skip: (_.size(data.products) / PER_PAGE) * PER_PAGE,
                                            take: PER_PAGE,
                                            account: Number(accountId)
                                        },
                                        updateQuery: (previousResult, { fetchMoreResult }) => {
                                            if (!_.get(fetchMoreResult, 'products')) {
                                                return previousResult
                                            };
                                            return {
                                                products: [
                                                    ...previousResult.products,
                                                    ...fetchMoreResult.products
                                                ],
                                            }
                                        }
                                    })
                                    }
                                />
                                )}
                         </Fragment>
                    )) : (
                        <Typography className={classes.nodataStyle}>
                            Không có dữ liệu thích hợp
                        </Typography>
                    )}
                    {/* {_.map(_.get(data, 'product_request'), (item, index) => {
                        return (
                            <Fragment key={index}>
                                <SecondaryCard data={_.get(item, 'productByProduct')} />
                                {index === _.size(data.product_request) - 1 && count > _.size(data.product_request) && (
                                    <Waypoint
                                        onEnter={() =>
                                            fetchMore({
                                                variables: {
                                                    skip: (_.size(data.product_request) / PER_PAGE) * PER_PAGE,
                                                    take: PER_PAGE,
                                                    account: Number(accountId)
                                                },
                                                updateQuery: (previousResult, { fetchMoreResult }) => {
                                                    if (!_.get(fetchMoreResult, 'product_request')) {
                                                        return previousResult
                                                    };
                                                    return {
                                                        product_request: [
                                                            ...previousResult.product_request,
                                                            ...fetchMoreResult.product_request
                                                        ],
                                                    }
                                                }
                                            })
                                        }
                                    />
                                )} 
                            </Fragment>
                        )
                    })} */}
                </Grid>
            </Grid>

            {/* <Grid item>
                <Typography style={{fontSize: '20px', fontWeight: 'bold', marginTop: '40px'}} variant="h6">Sản phẩm KH đăng ký đã xem thực tế (2)</Typography>
                <Grid container direction="row" style={{marginTop: '14px'}}>
                    <CardProduct />
                    <CardProduct />
                    <CardProduct />
                </Grid>
            </Grid> */}
        </Grid>
    )
}

export default ProductCare
