import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
} from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import swal from "sweetalert";
import { checkedPermission, formatMoney } from "../../../../helper";
import TableComponent from "../../../../pages/Customer/components/TableComponent";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { useParams } from "react-router-dom";
import {
  useGetBingoResultDetail,
  useUpdateBingoExchangeStatus,
} from "../../hooks";
import { Description } from "@material-ui/icons";
import { EXPORT_PROMOTION, PER_PAGE } from "../../../../constants/defined";
import { StoreContext } from "../../../../resources/store";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: "bold",
    fontSize: 24,
  },
  heading2: {
    fontWeight: "bold",
    fontSize: 18,
  },
  label: {
    fontWeight: "bold",
  },
  wrapperPaper: {
    padding: "30px 15px",
    marginBottom: "30px",
  },
}));

const headMenu = [
  {
    id: "code",
    numeric: false,
    disablePadding: false,
    label: "Mã khách hàng",
  },

  {
    id: "full_name",
    numeric: false,
    disablePadding: false,
    label: "Tên khách hàng",
  },
  {
    id: "personal_id",
    numeric: false,
    disablePadding: false,
    label: "CMND/CCCD",
  },
  {
    id: "phone_number",
    numeric: false,
    disablePadding: false,
    label: "Số điện thoại",
  },

  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "exchange_status",
    numeric: false,
    disablePadding: false,
    label: "Trạng thái",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Ngày đổi",
  },
];

const BingoResultPage = (props) => {
  const classes = useStyles();
  const { id: resultId } = useParams();
  const eventId = window.localStorage.getItem('event') || 0;
  const [tableData, setTableData] = useState([]);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);

  const { userInfo, accessObjectPermissions } = useContext(StoreContext)

  const { data, loading } = useGetBingoResultDetail(eventId * 1, resultId * 1);
  const { updateBingoExchangeStatus } = useUpdateBingoExchangeStatus(eventId * 1, resultId * 1);

  const handleExport = () => {
    const ExcelJSWorkbook = new ExcelJS.Workbook();
    const worksheet = ExcelJSWorkbook.addWorksheet("ExcelJS sheet");
    const header = [
      "Mã khách hàng",
      "Tên khách hàng",
      "CMND/CCCD",
      "SDT",
      "Email",
      "Trạng thái",
      "Ngày đổi",
    ];

    const dataToExport = [];

    if (tableData !== undefined && tableData.length > 0) {
      let i = 1;
      tableData.forEach((value) => {
        dataToExport.push([
          _.get(value, "code"),
          _.get(value, "full_name"),
          _.get(value, "personal_id"),
          _.get(value, "phone_number"),
          _.get(value, "email"),
          _.get(value, "exchange_status"),
          _.get(value, "date"),
        ]);
        i++;
      });
    } else {
      swal({
        title: "Thông báo",
        text: "Không có dữ liệu!",
        icon: "warning",
      });
      return;
    }

    const customCell = worksheet.getCell("A1");
    customCell.alignment = { vertical: "middle", horizontal: "center" };
    customCell.font = {
      name: "Century",
      family: 4,
      bold: true,
    };

    worksheet.addRow();
    const headerRow = worksheet.addRow(header);
    headerRow.font = {
      name: "Century",
      family: 4,
      bold: true,
    };

    let columnSTT = 1;
    worksheet.columns.forEach((column) => {
      let dataMax = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        dataMax = cell.value ? cell.value.toString().length : 0;
      });
      if (columnSTT === 1) {
        column.width = 5;
        columnSTT++;
      } else if (dataMax <= 2) {
        column.width = 10;
      } else {
        column.width = dataMax <= 15 ? 20 : dataMax;
      }
    });

    if (dataToExport.length > 0) {
      dataToExport.forEach((value) => {
        worksheet.addRow(value);
      });
    }

    customCell.value = "Danh sách trúng thưởng";
    worksheet.mergeCells(`A1:${String.fromCharCode(64 + _.size(header))}1`);

    ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `danh_sach_trung_thuong_game_xo_so.xlsx`
      );
    });
  };

  useEffect(() => {
    const tmp = _.map(data?.account_ticket_results, (item) => {
      return {
        id: _.get(item, "id"),
        code: _.get(item, "account_info.accountByAccount.code"),
        full_name: _.get(item, "account_info.accountByAccount.full_name"),
        personal_id: _.get(item, "account_info.accountByAccount.personalid"),
        phone_number: _.get(item, "account_info.accountByAccount.phone"),
        email: _.get(item, "account_info.accountByAccount.email"),
        exchange_status: _.get(item, "account_ticket_result_status.name"),
        exchange_code: _.get(item, "account_ticket_result_status.code"),
        date: _.get(item, "exchanged_day")
          ? moment(_.get(item, "exchanged_day")).format("DD/MM/YYYY")
          : "-",
      };
    });
    setTableData(tmp);
  }, [data]);

  const handleChangeStatus = (rowValue) => {
    swal(
      "Bạn muốn thay đổi trạng thái",
      `Bạn muốn thay đổi trạng thái giải thưởng của khách hàng 
      ${rowValue?.full_name}
      Vui lòng xác nhận
      `,
      "warning",
      {
        buttons: {
          cancel: "Hủy",
          confirm: {
            text: "Chấp nhận",
            value: "CONFIRM",
          },
        },
      }
    ).then((options) => {
      switch (options) {
        case "CONFIRM":
          _.get(rowValue, "exchange_code") === "INITIAL"
            ? updateBingoExchangeStatus({
              variables: {
                where: {
                  id: {
                    _eq: _.get(rowValue, "id"),
                  },
                },
                _set: {
                  status: 2,
                  exchanged_day: "now",
                },
              },
            })
            : swal({
              title: "Không thể cập nhật trạng thái đổi thưởng này",
              icon: "error",
            });
          break;
        default:
          return;
      }
    });
  };

  if (loading) return <CircularProgress />;

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container className={classes.heading}>
        <Grid item>Thông tin giải thưởng</Grid>
      </Grid>
      <Paper className={classes.wrapperPaper}>
        <Grid container direction="column" spacing={6}>
          <Grid item container className={classes.heading2}>
            Thông tin giải thưởng
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={2} className={classes.label}>
              Tên chương trình
            </Grid>
            <Grid item xs={2}>
              {_.get(data, "event_info.name")}
            </Grid>
            <Grid item xs={2} className={classes.label}>
              Mã chương trình
            </Grid>
            <Grid item xs={2}>
              {_.get(data, "event_info.code")}
            </Grid>
            <Grid item xs={2} className={classes.label}>
              Loại chương trình
            </Grid>
            <Grid item xs={2}>
              {_.get(data, "event_info.event_type.name")}
            </Grid>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={2} className={classes.label}>
              Loại giải thưởng
            </Grid>
            <Grid item xs={2}>
              {_.get(data, "bingo_result_setting.special")
                ? "Giải chung cuộc"
                : "Giải thường"}
            </Grid>
            <Grid item xs={2} className={classes.label}>
              Giá trị (VND)
            </Grid>
            <Grid item xs={2}>
              {formatMoney(_.get(data, "reward"))}
            </Grid>
            <Grid item xs={2} className={classes.label}>
              Hạng giải thưởng
            </Grid>
            <Grid item xs={2}>
              {_.get(data, "bingo_result_setting.name")}
            </Grid>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={2} className={classes.label}>
              Mã số trúng
            </Grid>
            <Grid item xs={2}>
              {_.get(data, "code")}
            </Grid>
            <Grid item xs={2} className={classes.label}>
              Số người trúng
            </Grid>
            <Grid item xs={2}>
              {_.get(data, "bingo_count")}
            </Grid>
            <Grid item xs={2} className={classes.label}>
              Ngày hết hạn
            </Grid>
            <Grid item xs={2}>
              {_.get(data, "reward_date") ? moment(_.get(data, "reward_date")).format("DD/MM/YYYY") : '-'}
            </Grid>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={2} className={classes.label}>
              Ngày tạo
            </Grid>
            <Grid item xs={2}>
              {_.get(data, "result_date") ? moment(_.get(data, "result_date")).format("DD/MM/YYYY") : '-'}
            </Grid>
            <Grid item xs={2} className={classes.label}>
              Người tạo
            </Grid>
            <Grid item xs={2}>
              {_.get(data, "created_by")}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.wrapperPaper}>
        <Grid container direction="column" spacing={6}>
          <Grid
            item
            container
            className={classes.heading2}
            spacing={4}
            alignItems="center"
          >
            <Grid item> Thông tin khách hàng trúng thưởng</Grid>
            <Grid item>
              <Button
                variant="contained"
                className={classes.buttonExport}
                onClick={() => handleExport()}
                disabled={!checkedPermission(userInfo, accessObjectPermissions, EXPORT_PROMOTION)}
              >
                <Description style={{ fontSize: 14 }} />
                <span style={{ fontSize: 13 }}>Xuất dữ liệu</span>
              </Button>
            </Grid>
          </Grid>
          <Grid item container>
            {_.isEmpty(_.get(data, "account_ticket_results")) ? (
              "Chưa  có khách hàng trúng thưởng"
            ) : (
              <TableComponent
                isRowClick
                headMenu={headMenu}
                data={_.slice(tableData, skip, page * PER_PAGE)}
                count={_.size(tableData)}
                onRowClick={handleChangeStatus}
                numRowsPerPage={PER_PAGE}
                setSkip={(skip) => setSkip(skip)}
                page={page}
                setPage={setPage}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default BingoResultPage;
