import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import * as _ from "lodash";

import { makeStyles } from '@material-ui/core/styles';
import { stringify } from 'uuid';
import { useGetProductsByCart } from '../graphql/schemas/hooks';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    input: {
        maxWidth: 250,
        backgroundColor: '#fff',
        marginLeft: '0',
        '&.disabled': {
            backgroundColor: '#f7f7f7',
        },
        '& > .MuiFormHelperText-marginDense': {
            backgroundColor: '#FAFAFA',
            margin: 0
        }
    }
}));

const AutoCompleteCodeProduct = ({ selectCart, onChange, value=null, disabled }) => {
    const [searchText, setSearchText] = useState("");
    
    const classes = useStyles();
    var listOption = [];
    

    const { data, loading, error } = useGetProductsByCart({ cartId: selectCart !== -1 && selectCart, searchText: searchText});


    if (!_.isEmpty(_.get(data, 'result'))) {
        listOption = _.map(_.get(data, 'result'), (item) => (item));
    }

    const handleSelect = (e, value) => {
        try {
            if (value) {
                onChange(value);
            } else {
                onChange(null);
            }
        } catch (error) {
            console.log("auto complete employee error", error);
        }
    }

    return (
        <Autocomplete
            id='combo-box-codeProduct'
            disabled={disabled}
            options={listOption}
            getOptionLabel={(option) => _.toString(option.code)}
            onChange={handleSelect}
            renderInput={(params) =>
                <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    value={value}
                    className={clsx(classes.input, disabled && 'disabled')}
                    onChange={(e) => setSearchText(e.target.value)} />}
        />
    );
}

export default AutoCompleteCodeProduct;