import * as _ from 'lodash';
import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/styles/useTheme';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CircularProgress } from '@material-ui/core';
import Moment from 'react-moment';

const Accordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    border: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: 56,
    marginBottom: 0,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


const useStyles = makeStyles({
  card: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: '30px',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    borderTopRightRadius: '0px',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  summary: {
    height: '10px',
    marginTop: '20px'
  },
  textAccordino: {
    marginTop: '10px',
    fontSize: '14px',
    '& > .boldStyle': {
      fontWeight: 'bold',
    },
    '& > .underlineStyle': {
      textDecoration: 'underline',
      cursor: 'pointer',
      '& :hover': {
        opacity: '70%',
      },
    },
    '& > span:not(:last-child)': {
      paddingRight: '5px',
    }
  }
});


export default function CustomizedAccordions({ data, loading }) {

  const theme = useTheme();
  const history = useHistory();

  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

  const classes = useStyles();

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (loading) return <CircularProgress />

  const dataNotChecking = _.filter(_.get(data, 'request_workflow'), ['type_workflow.type_step.code', 'INPROCESS']);
  const dataHouseView = _.filter(_.get(data, 'request_workflow'), ['type_workflow.type_step.code', 'HOUSEVIEW']);
  const dataDeposit = _.filter(_.get(data, 'request_workflow'), ['type_workflow.type_step.code', 'DEPOSIT']);
  const dataContract = _.filter(_.get(data, 'request_workflow'), ['type_workflow.type_step.code', 'CONTRACT']);

  return (
    <div>
      <Accordion style={{ border: 'none', marginLeft: matchesSM ? 0 : undefined, marginRight: matchesMD ? '20px' : undefined }} square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary className={classes.summary} aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={<Checkbox icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} />}
            label="Tư vấn"
            checked={!_.isEmpty(dataNotChecking)}
          />
        </AccordionSummary>

        <AccordionDetails>
          <Card className={classes.card}>
            <CardContent>
              {
                _.size(dataNotChecking) > 0 ? (
                  _.map(dataNotChecking, (item, key) => {
                    if (_.isEmpty(item.requestByRequest.account)) return <></>

                    return (
                      <Typography key={key} className={classes.textAccordino}>
                        <span className='boldStyle'>
                          <Moment format="DD/MM/YYYY">{_.get(item, 'created')}</Moment>
                        </span>

                        <span>Mã SP</span>

                        <span
                          className='boldStyle underlineStyle'
                          onClick={() => history.push('/product/secondary/detail/' + _.get(item, 'requestByRequest.productByProduct.productByProduct.id'))}
                        >
                          {`${_.get(item, 'requestByRequest.productByProduct.productByProduct.code', '')}`}
                        </span>

                        <span>
                          {`: khách hàng ${_.get(item, 'requestByRequest.account.full_name')} (Mã khách hàng:`}
                        </span>

                        <span
                          className='boldStyle underlineStyle'
                          onClick={() => history.push('/customer/detail/' + _.get(item, 'requestByRequest.account.customers[0].id'))}
                        >
                          {_.get(item, 'requestByRequest.account.code')})
                        </span>

                        <span>{`) đang được Tư Vấn`}</span>

                      </Typography>
                    )
                  })
                )
                  :
                  (
                    <Typography className={classes.textAccordino}>Chưa có dữ liệu</Typography>
                  )
              }

            </CardContent>
          </Card>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: 'none', marginLeft: matchesSM ? 0 : undefined, marginRight: matchesMD ? '20px' : undefined }} square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={<Checkbox icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} />}
            label="Xem nhà"
            checked={!_.isEmpty(dataHouseView)}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Card className={classes.card}>
            <CardContent>
              {
                _.size(dataHouseView) > 0 ? (
                  _.map(dataHouseView, (item, key) => {
                    if (_.isEmpty(item.requestByRequest.account)) return <></>
                    return (
                      <Typography key={key} className={classes.textAccordino}>
                        <span className='boldStyle'>
                          <Moment format="DD/MM/YYYY">{_.get(item, 'created')}</Moment>
                        </span>
                        <span>Mã SP</span>
                        <span
                          className='boldStyle underlineStyle'
                          onClick={() => history.push('/product/secondary/detail/' + _.get(item, 'requestByRequest.productByProduct.productByProduct.id'))}
                        >
                          {`${_.get(item, 'requestByRequest.productByProduct.productByProduct.code', '')}`}
                        </span>

                        <span>
                          {`: khách hàng ${_.get(item, 'requestByRequest.account.full_name')} (Mã khách hàng:`}
                        </span>

                        <span
                          className='boldStyle underlineStyle'
                          onClick={() => history.push('/customer/detail/' + _.get(item, 'requestByRequest.account.customers[0].id'))}
                        >
                          {_.get(item, 'requestByRequest.account.code')})
                        </span>

                        <span>Đi xem nhà</span>
                      </Typography>
                    )
                  })
                )
                  :
                  (
                    <Typography className={classes.textAccordino}>Chưa có dữ liệu</Typography>
                  )
              }

            </CardContent>
          </Card>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: 'none', marginLeft: matchesSM ? 0 : undefined, marginRight: matchesMD ? '20px' : undefined }} square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={<Checkbox icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} />}
            label="Đặt cọc"
            checked={!_.isEmpty(dataDeposit)}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Card className={classes.card}>
            <CardContent>
              {
                _.size(dataDeposit) > 0 ? (
                  _.map(dataDeposit, (item, key) => {
                    if (_.isEmpty(item.requestByRequest.account)) return <></>
                    return (
                      <Typography key={key} className={classes.textAccordino}>
                        <span className='boldStyle'>
                          <Moment format="DD/MM/YYYY">{_.get(item, 'created')}</Moment>
                        </span>
                        <span>Mã SP</span>
                        <span className='boldStyle underlineStyle' onClick={() => history.push('/product/secondary/detail/' + _.get(item, 'requestByRequest.productByProduct.productByProduct.id'))}>
                          {_.get(item, 'requestByRequest.productByProduct.productByProduct.code', '')}
                        </span>

                        <span>
                          {`: khách hàng ${_.get(item, 'requestByRequest.account.full_name')} (Mã khách hàng:`}
                        </span>

                        <span
                          className='boldStyle underlineStyle'
                          onClick={() => history.push('/customer/detail/' + _.get(item, 'requestByRequest.account.customers[0].id'))}
                        >
                          {_.get(item, 'requestByRequest.account.code')})
                        </span>

                        <span>Đặt cọc</span>
                      </Typography>
                    )
                  })
                )
                  :
                  (
                    <Typography className={classes.textAccordino}>Chưa có dữ liệu</Typography>
                  )
              }

            </CardContent>
          </Card>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: 'none', marginLeft: matchesSM ? 0 : undefined, marginRight: matchesMD ? '20px' : undefined }} square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={<Checkbox icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} />}
            label="Hợp đồng mua bán"
            checked={!_.isEmpty(dataContract)}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Card className={classes.card}>
            <CardContent>
              {
                _.size(dataContract) > 0 ? (
                  _.map(dataContract, (item, key) => {
                    if (_.isEmpty(item.requestByRequest.account)) return <></>
                    return (
                      <Typography key={key} className={classes.textAccordino}>
                        <span className='boldStyle'>
                          <Moment format="DD/MM/YYYY">{_.get(item, 'created')}</Moment>
                        </span>
                        <span>Mã SP</span>
                        <span className='boldStyle underlineStyle' onClick={() => history.push('/product/secondary/detail/' + _.get(item, 'requestByRequest.productByProduct.productByProduct.id'))}>
                          {_.get(item, 'requestByRequest.productByProduct.productByProduct.code', '')}
                        </span>

                        <span>
                          {`: khách hàng ${_.get(item, 'requestByRequest.account.full_name')} (Mã khách hàng:`}
                        </span>

                        <span
                          className='boldStyle underlineStyle'
                          onClick={() => history.push('/customer/detail/' + _.get(item, 'requestByRequest.account.customers[0].id'))}
                        >
                          {_.get(item, 'requestByRequest.account.code')})
                        </span>

                        <span>ký HĐMB</span>
                      </Typography>
                    )
                  })
                )
                  :
                  (
                    <Typography className={classes.textAccordino}>Chưa có dữ liệu</Typography>
                  )
              }

            </CardContent>
          </Card>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}