import * as _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { FormControl } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%',
        padding: '10.5px 14px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        '&.disabled': {
            background: '#f7f7f7',
        }
    },
    inputStyle: {
        width: '100%',
        fontSize: 'unset',
        border: '0',
        background: 'none',
        '&:focus-visible': {
            outline: '0',
        },
        '&:disabled': {

        },
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    defaultPadding: {
        '&>div': {
            padding: '10.5px 14px',
        },
    },
    dropdownList: {
        '> *': {
            background: 'green',
        }
    }
}));

const InputProjectSearch = (props) => {
    const { fieldname, description, object, setObject, setProjectsAvailable, disabled, datalist, modeEdit } = props;
    const [inputValue, setInputValue] = useState(_.get(object, fieldname));
    const classes = useStyles();

    const onBlurSetValue = (event) => {
        const value = _.trim(_.get(event, 'target.value'));
        const selectedProject = !_.isEmpty(value) && _.find(datalist, { name: value });
        let tempObject = { ...object };

        if (_.isEmpty(value) && _.isEmpty(_.get(object, fieldname))) return;
        if (!_.isEmpty(_.get(object, 'project.id')) && (_.get(object, 'project.id') === _.get(selectedProject, 'id'))) return;

        if (selectedProject) {
            _.set(tempObject, 'project.id', _.get(selectedProject, 'id'));
            _.set(tempObject, 'province', { 'id': _.get(selectedProject, 'address.province.id', null), 'name': _.get(selectedProject, 'address.province.name', null) });
            _.set(tempObject, 'district', { 'id': _.get(selectedProject, 'address.district.id', null), 'name': _.get(selectedProject, 'address.district.name', null) });
            _.set(tempObject, 'ward', { 'id': _.get(selectedProject, 'address.ward.id', null), 'name': _.get(selectedProject, 'address.ward.name', null) });
            _.set(tempObject, 'street', { 'id': _.get(selectedProject, 'address.street.id', null), 'name': _.get(selectedProject, 'address.street.name', null) });
            _.set(tempObject, 'address_number', _.get(selectedProject, 'address.short', null));
            _.set(tempObject, 'project.name', "");
            if (_.isFunction(setProjectsAvailable)) setProjectsAvailable(true);
        }
        else {
            if (_.get(tempObject, 'project.id')) delete tempObject.project.id;
            if (_.get(tempObject, 'province')) delete tempObject.province;
            if (_.get(tempObject, 'district')) delete tempObject.district;
            if (_.get(tempObject, 'ward')) delete tempObject.ward;
            if (_.get(tempObject, 'street')) delete tempObject.street;
            if (_.get(tempObject, 'address_number')) delete tempObject.address_number;
            if (_.isEmpty(value)) {
                if (_.get(tempObject, 'project')) delete tempObject.project;
            }
            else {
                _.set(tempObject, 'project.name', value);
            }
            if (_.isFunction(setProjectsAvailable)) setProjectsAvailable(false);
        }
        setObject(tempObject);
        return;
    };

    const onKeyPress = (event) => {
        if (event.key === 'Enter' || event.charCode === 13) {
            onBlurSetValue(event);
        }
        return;
    }

    const onchangeAction = (e) => {
        const value = _.get(e, 'target.value');
        setInputValue(value);
    }

    useEffect(() => {
        if (_.includes(['province', 'district', 'ward', 'street'], fieldname)) {
            setInputValue(_.get(object, `${fieldname}.name`));
        }
    }, [object]);

    useEffect(() => {
        if (_.isFunction(setProjectsAvailable)) setProjectsAvailable(false);
        if (_.get(object, 'project.id')) {
            setInputValue(_.get(object, `${fieldname}.name`));
            // const project = _.find(datalist, { id: _.get(object, 'project.id') }); 
            // if (project) {
            //     if (_.isFunction(setProjectsAvailable)) setProjectsAvailable(true);
            //     setInputValue(_.get(project, 'name'));
            // }
        }
    }, []);

    return (
        <Fragment>
            <div style={{ display: 'flex' }}>
                <FormControl variant="outlined" className={clsx(classes.formControl, { 'disabled': disabled })}>
                    <input
                        disabled={disabled}
                        className={clsx(classes.inputStyle)}
                        placeholder={_.isEmpty(inputValue) && description}
                        value={inputValue}
                        list={`list-${fieldname}`}
                        id={`input-textfield-${fieldname}`}
                        onBlur={(e) => onBlurSetValue(e)}
                        onKeyPress={onKeyPress}
                        onChange={onchangeAction}
                        autocomplete="off"
                    />
                    <datalist id={`list-${fieldname}`} className={clsx(classes.dropdownList)}>
                        {_.map(datalist, (item, key) => {
                            return <option key={key} value={item.name} />
                        })}
                    </datalist>

                </FormControl>
            </div >

        </Fragment >
    );
};

InputProjectSearch.propTypes = {

};

export default InputProjectSearch;
