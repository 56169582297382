import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import {
  AddCircleOutline,
  RemoveCircleOutline,
  ExpandMore,
} from "@material-ui/icons";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { BINGO_RESULT_SETTING_ITEM, BUY_TICKET } from "../../../constants/defined";
import { useGetBingoSettingCodes, useGetJackpotType } from "../hooks";
import swal from 'sweetalert';
import { GET_BINGO_RESULT_SETTING_NOT_USE } from "../../../graphql/schemas/event/eventQueries";
import { useLazyQuery } from "@apollo/client";

const BingoResultSettings = ({
  classes,
  bingoSettings,
  setBingoSettings,
  object,
  setObject,
  isEdit = false,
  setRedeemEcoinEventSetting,
}) => {
  let indexItem = 0;

  const { data: listBingoCode, loading: loadingGetBingoCode } = useGetBingoSettingCodes();
  const { data: dataBuyTicket, loading: loadingBuyTicket } = useGetJackpotType({code: BUY_TICKET});
  const [ checkingNotUse ] =  useLazyQuery(GET_BINGO_RESULT_SETTING_NOT_USE);

  const [inputValues, setInputValues] = useState([]);
  const [listFinalPrize, setListFinalPrize] = useState([]);
  const [listWeeklyPrize, setListWeeklyPrize] = useState([]);
  const [isExpanded, setIsExpanded] = useState(!isEdit);

  const handleChangeExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const addField = () => {
    let tmp = [...inputValues, {...BINGO_RESULT_SETTING_ITEM}];
    setInputValues(tmp);
  };

  const removeField = async (index) => {
    let tmp = _.cloneDeep(inputValues);
    if(_.get(tmp, `[${index}].id`)) {
      const { data: dataChecking } = await checkingNotUse({
        variables: { 
          id: _.get(tmp, `[${index}].id`) 
        },
      });
      if(_.size(_.get(dataChecking,'result')) === 0) { 
        swal({
          title: "Giải thưởng không thể xóa.",
          text: "Cài đặt giải thưởng đã được sử dụng!",
          icon: "warning",
        })
      } else {
        tmp[index]["deleted"] = true;
      }
    } else {
      tmp.splice(index, 1);
    }
    setInputValues(tmp);
    setBingoSettings(tmp);
  };

  const handleChangeInput = async (index, event) => {
    let values = [...inputValues];
    
    if (event.target.name === "code") {
      const checkinExistence = _.find(values,['code', _.get(event,'target.value')]);

      if (checkinExistence) {
        return swal({
          title: "Giải thưởng đã được cài đặt",
          icon: "warning",
        }).then( () => {
          values[index]["name"] = "";
          values[index]["code"] = "";
          setBingoSettings(values);
        })
      } else {
        values[index]["name"] = _.get(_.find(listBingoCode,['code', _.get(event,'target.value')]), 'name');
        values[index]["code"] = _.get(_.find(listBingoCode,['code', _.get(event,'target.value')]), 'code');
      }

    } else if (
      event.target.name === "last_numbers" ||
      event.target.name === "reward"
    ) {
      values[index][event.target.name] = parseInt(event.target.value);
    } else if (event.target.name === "special") {
      let special = event.target.value === "true";
      values[index][event.target.name] = special;
    } else values[index][event.target.name] = event.target.value;

    setInputValues(values);
  };

  const onBlurInput = () => {
    setBingoSettings(inputValues);
  };

  useEffect(() => {
    if (listBingoCode) {
      const weeklyPrize = listBingoCode.filter((item) =>
        item.code.includes("WEEKLY")
      );
      const finalPrize = listBingoCode.filter((item) =>
        item.code.includes("FINAL")
      );
      setListFinalPrize(finalPrize);
      setListWeeklyPrize(weeklyPrize);
    }
  }, [loadingGetBingoCode]);

  useEffect(()=>{
    if(bingoSettings) {
      setInputValues(bingoSettings);
    }
  },[bingoSettings]);

  useEffect(()=>{
    if(_.size(bingoSettings) === 0) {
      addField();
    }
  },[]);

  if(loadingGetBingoCode || loadingBuyTicket) {
    return <CircularProgress />
  }

  return (
    <>
      <Grid
        item
        container
        className={`${classes.textLabelForm} ${classes.rowItem}`}
      >
        <Grid item xs={2}>
          <p>
            Số Ecoin/vé<span style={{ color: "red" }}>*</span>
          </p>
        </Grid>
        <Grid item>
          <TextField
            type="number"
            size="small"
            variant="outlined"
            className={classes.input}
            name="coin"
            defaultValue={Math.abs(_.get(_.head(dataBuyTicket),'ecoin'))}
            onChange={(e) => {
              if(_.isFunction(setRedeemEcoinEventSetting)) setRedeemEcoinEventSetting(Math.abs(parseInt(e.target.value)))
              // setObject({
              //   ...object,
              //   coin_per_ticket: parseInt(e.target.value),
              // })
            }}
            // disabled
          />
        </Grid>
      </Grid>

      <Grid container alignItems="center">
        <Grid item xs={12}>
          <p
            style={{
              color: "rgba(0, 0, 0, 0.54)",
              fontSize: "1.3rem",
              marginBottom: 0,
            }}
            className={classes.textLabelForm}
            onClick={isEdit && handleChangeExpanded}
          >
            Cài đặt giải thưởng
            {isEdit && (
              <ExpandMore
                style={{
                  transform: `${
                    isExpanded ? "rotate(-180deg)" : "rotate(0deg)"
                  }`,
                  marginLeft: "30px",
                  position: "relative",
                  top: "5px",
                  transition: "0.5s all ease",
                  cursor: "pointer",
                }}
              />
            )}
          </p>
        </Grid>

        {isExpanded &&
          _.map(inputValues, (item, key) => {
            if(_.get(item,'deleted')) {
              return undefined;
            } else {
              indexItem++;
            }

            return (
              <Grid item container key={key} className={classes.wapperItem}>
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <p className={classes.textLabelForm}>
                      {`Giải thưởng ${indexItem}`}
                    </p>
                    <IconButton onClick={() => removeField(key)}>
                      <RemoveCircleOutline style={{ fill: "red" }} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item className={classes.textLabelForm} xs={2}>
                    <p>Loại giải thưởng</p>
                  </Grid>
                  <Grid item>
                    <RadioGroup
                      row
                      name="special"
                      defaultValue={_.toString(item.special)}
                      onChange={(e) => handleChangeInput(key, e)}
                      onBlur={onBlurInput}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Giải chung cuộc"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Giải thường"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
  
                <Grid item container spacing={4}>
                  <Grid item className={classes.textLabelForm} xs={2}>
                    <p>Hạng giải thưởng</p>
                  </Grid>
  
                  <Grid item>
                    <FormControl size="small">
                      <Select
                        name="code"
                        id={`item-result-setting-${key}`}
                        onChange={(e) => handleChangeInput(key, e)}
                        value={_.get(item, "code")}
                        variant="outlined"
                        size="small"
                        style={{ minWidth: 120 }}
                        onBlur={onBlurInput}
                      >
                        <MenuItem value={""} disabled>
                          Chọn giải thưởng
                        </MenuItem>
                        {listBingoCode && item.special
                          ? _.map(listFinalPrize, (item, index) => (
                              <MenuItem key={index} value={_.get(item, "code")}>
                                {_.get(item, "name")}
                              </MenuItem>
                            ))
                          : _.map(listWeeklyPrize, (item, index) => (
                              <MenuItem key={index} value={_.get(item, "code")}>
                                {_.get(item, "name")}
                              </MenuItem>
                            ))}
                      </Select>{" "}
                    </FormControl>
                  </Grid>
                </Grid>
  
                <Grid
                  item
                  container
                  spacing={4}
                  style={{ flexWrap: "nowrap" }}
                  alignItems="center"
                >
                  <Grid item className={classes.textLabelForm} xs={2}>
                    <p>Số cuối</p>
                  </Grid>
                  <Grid item>
                    <TextField
                      type="number"
                      size="small"
                      variant="outlined"
                      className={classes.input}
                      name="last_numbers"
                      value={item.last_numbers}
                      onChange={(e) => handleChangeInput(key, e)}
                      onBlur={onBlurInput}
                    />
                  </Grid>
                  <Grid item className={classes.textLabelForm}>
                    <p>Mô tả</p>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      size="small"
                      variant="outlined"
                      className={classes.input}
                      value={item.remark}
                      name="remark"
                      onChange={(e) => handleChangeInput(key, e)}
                      onBlur={onBlurInput}
                    />
                  </Grid>
                  <Grid item className={classes.textLabelForm}>
                    <p>Trị giá (VNĐ)</p>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      type="number"
                      size="small"
                      variant="outlined"
                      className={classes.input}
                      value={item.reward}
                      name="reward"
                      onChange={(e) => handleChangeInput(key, e)}
                      onBlur={onBlurInput}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
      </Grid>

      {isExpanded && (
        <Grid item container className={classes.textLabelForm}>
          <IconButton onClick={() => addField()}>
            <AddCircleOutline style={{ fill: "blue" }} />{" "}
            <span style={{ paddingLeft: "10px" }}>Thêm giải thưởng mới</span>
          </IconButton>
        </Grid>
      )}
    </>
  );
};

export default BingoResultSettings;
