import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import * as _ from "lodash";
import BookingEdit from './BookingEdit';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '90vw',
        maxWidth: '1200px',
        height: '100vh',
        padding: '30px',
        overflow: 'scroll',
        backgroundColor: '#fff',
        boxShadow: theme.shadows[5],
        '&:focus-visible': {
            outline: 'none',
            boxShadow: 'none'
        }
    },
    
    buttonConfirm: {
        backgroundColor: '#2C8A81',
        color: '#ffffff',
        borderRadius: 0,
        margin: '0 8px'
    },
    buttonModalBack: {
        backgroundColor: '#0B213F',
        color: '#ffffff',
        borderRadius: 0,
        margin: '0 8px'
    },
    reasonBlock: {
        borderTopLeftRadius: 20,
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 0,
        marginTop: '20px',
        padding: '10px'
    }
}));

const ModalEditBooking = ({ setStatusModal, statusModal }) => {

    const classes = useStyles();
    
    if(!statusModal) return null;

    const handleCloseModal = () => {
        localStorage.removeItem('selectedBooking');
        setStatusModal(false);
    };
    
    return (
        <Modal className={classes.root} open={statusModal}>
            <div className={classes.paper}>
                <BookingEdit handleCloseModal = {handleCloseModal} />
            </div>
        </Modal>
    )
}

export default ModalEditBooking;
