import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Grid, ButtonGroup, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import BranchDetailComponent from "../../../components/employee/Branches/BranchDetailComponent";
import clsx from "clsx";
import { useBranchNew } from "../hooks";
import BranchesByArea from "./BranchesByArea";
import BranchActivityHistory from "../../../components/employee/Branches/BranchActivityHistory";

import { StoreContext } from '../../../resources/store';
import { USER_ROLE_SUPPER_ADMIN } from "../../../constants/defined";

import _ from 'lodash'
import { checkedPermission } from "../../../helper";

const menuList = [
  {
    title: "Thông tin văn phòng",
  },
  {
    title: "Lịch sử hoạt động",
  },
  {
    title: "Danh sách chi nhánh theo khu vực",
  },
];

const BranchDetail = () => {

  const [activeMenu, setActiveMenu] = useState(
    Number(window.localStorage.getItem("headMenuBranchKey")) || 0
  );

  const useStyles = makeStyles((theme) => ({
    fieldTitle: {
      fontWeight: "bold",
      width: 240,
    },
    buttonGroupHeader: {
      marginBottom: 20,
      color: "#fff",
      borderRadius: 0,
    },
  }));

  const { id } = useParams();

  const { userInfo, accessObjectPermissions } = useContext(StoreContext);

  const classes = useStyles();

  const { data, loading, error, refetch } = useBranchNew(id);

  if (loading) {
    return <CircularProgress />
  }

  return (
    <>
      <Grid container direction="column">
        <Grid container direction="row">
          <ButtonGroup>
            {menuList.map((element, index) => {
              return (
                <Button
                  onClick={() => {
                    setActiveMenu(index);
                    window.localStorage.setItem("headMenuBranchKey", index);
                  }}
                  style={{
                    backgroundColor: activeMenu === index ? "#E27F35" : "#2C8A81",
                  }}
                  className={clsx(classes.buttonGroupHeader)}
                  variant="contained"
                >
                  {element.title}
                </Button>
              );
            })}
          </ButtonGroup>
        </Grid>
        {activeMenu === 0 && <BranchDetailComponent data={data} branchId={id} refetchData={refetch} />}
        {activeMenu === 1 && <BranchActivityHistory data={data} />}
        {activeMenu === 2 && <BranchesByArea data={data?.branches} loading={loading} />}
      </Grid>
    </>

  );
};

export default BranchDetail;
