import {
  Grid,
  IconButton,
  makeStyles,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import { Add, AddCircleRounded, RemoveCircleOutline } from "@material-ui/icons";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../../context/ConfigContext";

const useStyles = makeStyles((theme) => ({
  textLabelForm: {
    fontWeight: "bold",
    paddingRight: "20px",
  },

  imgPicker: {
    display: "flex !important",
    background: "#F5F5F5",
    width: "100px",
    height: "100px",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    fontWeight: "bold",
    cursor: "pointer",
    border: "1px solid #B9B9B9",
    borderRadius: "6px",
  },

  bodyContent: {
    gap: "16px",
    "& > div": {
      "&:last-child": {
        paddingBottom: "16px",
        borderBottom: "1px dashed #E2E1E1",
      },
    },
  },

  addBtn: {
    background: "#00837B",
    borderRadius: "6px",
    color: "#fff",
    fontSize: "16px",

    "& > span": {
      gap: "10px",
      display: "flex",
      alignItems: "center",
    },

    "&:hover": {
      background: "rgba(0,131,123,0.8)",
    },
  },
}));

const EventGifts = ({ eventGifts, setEventGifts, children }) => {
  const [inputValues, setInputValues] = useState(eventGifts);
  const classes = useStyles();

  const configValue = useContext(ConfigContext);

  const addEventGiftsField = () => {
    setInputValues([
      ...inputValues,
      {
        name: "",
        title: "",
        description: "",
        expire_day: null,
        redeem_ecoin: null,
        start: null,
        end: null,
        max_redeem: null,
        image: "",
      },
    ]);
  };

  useEffect(() => {
    let defaultValue = _.map([...eventGifts], (item) => {
      return {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        title: _.get(item, "title"),
        description: _.get(item, "description"),
        expire_day: _.get(item, "expire_day"),
        redeem_ecoin: _.get(item, "redeem_ecoin"),
        start: moment(_.get(item, "start")).format("YYYY-MM-DD"),
        end: moment(_.get(item, "end")).format("YYYY-MM-DD"),
        max_redeem: _.get(item, "max_redeem"),
        image: _.get(item, "image"),
      };
    });
    setInputValues(defaultValue);
  }, []);

  const handleRemoveEventGiftsField = (index) => {
    if (inputValues.length <= 1) return;
    const fields = [...inputValues];
    fields.splice(index, 1);
    setInputValues(fields);
  };

  const handleChangeEventGiftsInput = async (index, event) => {
    const values = [...inputValues];

    if (
      event.target.name === "max_redeem" ||
      event.target.name === "redeem_ecoin" ||
      event.target.name === "expire_day"
    ) {
      values[index][event.target.name] = parseInt(event.target.value);
    } else if (event.target.name === "image") {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("media_type_code", "PHOTO");
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const { data } = await axios.post(
          configValue.API_UPLOAD_FILE,
          formData,
          config
        );
        values[index][event.target.name] = data[0];
      } catch (error) {
        console.log(error);
      }
    } else values[index][event.target.name] = event.target.value;
    setInputValues(values);
  };

  const onBlurInput = () => {
    setEventGifts(inputValues);
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid container item alignItems="center">
        {children}
      </Grid>
      <Grid container item alignItems="center">
        <Grid item className={classes.textLabelForm} xs={2}>
          <p style={{ fontSize: "24px", margin: 0 }}>Giải thưởng</p>
        </Grid>
      </Grid>
      {_.map(inputValues, (item, key) => (
        <Grid
          item
          container
          key={key}
          alignItems="center"
          className={classes.bodyContent}
        >
          <Grid container>
            <Grid item container xs={4} alignItems="center">
              <Grid item className={classes.textLabelForm} md={4}>
                <p>
                  Loại giải thưởng<span style={{ color: "red" }}>*</span>
                </p>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  variant="outlined"
                  className={classes.input}
                  required
                  value={item.title}
                  name="title"
                  onChange={(e) => handleChangeEventGiftsInput(key, e)}
                  onBlur={onBlurInput}
                />
              </Grid>
            </Grid>

            <Grid item container md={4} alignItems="center">
              <Grid item className={classes.textLabelForm} xs={4}>
                <p>
                  Số ngày hết hạn<span style={{ color: "red" }}>*</span>
                </p>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  type="number"
                  size="small"
                  variant="outlined"
                  className={classes.input}
                  value={item.expire_day}
                  name="expire_day"
                  onChange={(e) => handleChangeEventGiftsInput(key, e)}
                  onBlur={onBlurInput}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item container md={4} alignItems="center">
              <Grid item xs={4}>
                <p className={classes.textLabelForm}>
                  Tên giải thưởng
                  <span style={{ color: "red" }}>*</span>
                </p>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  variant="outlined"
                  className={classes.input}
                  required
                  id="223"
                  value={item.name}
                  name="name"
                  onChange={(e) => handleChangeEventGiftsInput(key, e)}
                  onBlur={onBlurInput}
                />
              </Grid>
            </Grid>

            <Grid item container xs={4} alignItems="center">
              <Grid item xs={4} className={classes.textLabelForm}>
                <p>
                  Thời gian bắt đầu<span style={{ color: "red" }}>*</span>
                </p>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  variant="outlined"
                  className={classes.input}
                  value={item.start}
                  // value={moment(item.start).format("YYYY-MM-DD[T]HH-mm")}
                  // value="2021-10-01T00:00"
                  name="start"
                  type="date"
                  onChange={(e) => handleChangeEventGiftsInput(key, e)}
                  onBlur={onBlurInput}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center" xs={4}>
              <Grid item xs={4} className={classes.textLabelForm}>
                <p>
                  Thời gian kết thúc<span style={{ color: "red" }}>*</span>
                </p>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  variant="outlined"
                  className={classes.input}
                  value={item.end}
                  name="end"
                  type="date"
                  onChange={(e) => handleChangeEventGiftsInput(key, e)}
                  onBlur={onBlurInput}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid container md={4} alignItems="center">
              <Grid item className={classes.textLabelForm} xs={4}>
                <p>
                  Số Ecoin quy đổi<span style={{ color: "red" }}>*</span>
                </p>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  style={{ width: "100" }}
                  variant="outlined"
                  className={classes.input}
                  value={item.redeem_ecoin}
                  onChange={(e) => handleChangeEventGiftsInput(key, e)}
                  type="number"
                  name="redeem_ecoin"
                  onBlur={onBlurInput}
                />
              </Grid>
            </Grid>

            <Grid item container md={4} alignItems="center">
              <Grid item className={classes.textLabelForm} xs={4}>
                <p>
                  Số lượng giải thưởng<span style={{ color: "red" }}>*</span>
                </p>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  type="number"
                  size="small"
                  variant="outlined"
                  className={classes.input}
                  value={item.max_redeem}
                  name="max_redeem"
                  onChange={(e) => handleChangeEventGiftsInput(key, e)}
                  onBlur={onBlurInput}
                  required
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item container xs={4}>
              <Grid item xs={4} className={classes.textLabelForm}>
                <p>
                  Hình ảnh<span style={{ color: "red" }}>*</span>
                </p>
              </Grid>
              <Grid item xs={8}>
                {_.isEmpty(_.get(item, "image.url")) ? (
                  <>
                    <input
                      id={`image-${key}`}
                      hidden
                      type="file"
                      name="image"
                      accept="image/*"
                      multiple
                      className={classes.inputStyle}
                      onChange={(e) => handleChangeEventGiftsInput(key, e)}
                    />
                    <label
                      htmlFor={`image-${key}`}
                      className={classes.imgPicker}
                    >
                      Thêm ảnh <Add />
                    </label>{" "}
                  </>
                ) : (
                  <>
                    <input
                      id={`image-${key}`}
                      hidden
                      type="file"
                      name="image"
                      accept="image/*"
                      multiple
                      className={classes.inputStyle}
                      onChange={(e) => handleChangeEventGiftsInput(key, e)}
                    />
                    <label
                      htmlFor={`image-${key}`}
                      className={classes.imgPicker}
                    >
                      <img
                        style={{ height: 100, width: 100 }}
                        alt="icon"
                        src={configValue.MEDIA_DOMAIN + item?.image.url}
                      />
                    </label>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item container md={4} alignItems="center">
              <Grid xs={4} item className={classes.textLabelForm}>
                <p>
                  Mô tả<span style={{ color: "red" }}>*</span>
                </p>
              </Grid>
              <Grid item xs={8}>
                <TextareaAutosize
                  maxRows={5}
                  minRows={5}
                  style={{ width: "100%" }}
                  name="description"
                  value={item.description}
                  onChange={(e) => handleChangeEventGiftsInput(key, e)}
                />
              </Grid>
            </Grid>
            <Grid item>
              {inputValues.length > 1 && (
                <IconButton onClick={() => handleRemoveEventGiftsField(key)}>
                  <RemoveCircleOutline style={{ fill: "red" }} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item>
        <IconButton
          onClick={() => addEventGiftsField()}
          className={classes.addBtn}
        >
          <AddCircleRounded style={{ fill: "white" }} />{" "}
          <span>Thêm giải thưởng mới </span>
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default EventGifts;
