import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles, FormControlLabel, TableCell, TableRow } from '@material-ui/core';
import { CustomCheckbox } from '../../../components/UI';

const useStyles = makeStyles((theme) => ({
	rowItem: {
		'& > td': {
			padding: '0 16px',
		},
		'& > td:first-child': {
			borderRight: '1px solid lightgray',
		}
	}
}));

const PermissionThird = ({ element, headerList, disabledUpdate, setDisabledUpdate, permissionUpdate, setPermissionUpdate, selectedDepartment }) => {
	const classes = useStyles();
	const [values, setValues] = useState([]);

	const handleClickCheckbox = (e, index) => {
		if (disabledUpdate) setDisabledUpdate(false);
		let tmp = _.cloneDeep(permissionUpdate);
		const position = _.get(headerList, `[${index}].id`);
		const permission = _.get(element, 'id');

		if (_.get(e, 'target.checked')) {
			tmp = ([...tmp, {position, permission, departments: _.map(selectedDepartment, item => _.get(item, 'id'))} ]);
		} else {
			tmp = _.filter(tmp, (o) => {
				const check = _.get(o,"position") === position && _.get(o,"permission") === permission;
				return !check;
			})
		}
		setValues(tmp)
	};

	const handleChecked = (index) => {
		const position = _.get(headerList, `[${index}].id`);
		const permission = _.get(element, 'id');
		const res = _.find(permissionUpdate, {"position": position, "permission": permission});
		return !_.isUndefined(res);
	}

	useEffect(() => {
		setPermissionUpdate(values);
	}, [values]);

	return (
		<Fragment>
			<TableRow className={classes.rowItem}>
				<TableCell>{_.get(element, 'name')}</TableCell>
				{
					_.map(headerList, (item, key) => {
						
						return (
							<TableCell align="center" key={key}>
								<FormControlLabel
									id={`permission-${key}`}
									onChange={(e) => handleClickCheckbox(e, key)}
									control={
										<CustomCheckbox
											size="small"
											checked={handleChecked(key)}
										/>
									}
								/>
							</TableCell>
						)
					})
				}
			</TableRow>
		</Fragment>
	)
}

export default PermissionThird
