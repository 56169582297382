import React, { Fragment, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/styles';
import * as _ from 'lodash';
import ListCartProduct from './ListCartProduct'
import InfoCartProduct from './InfoCartProduct'
import BookingEdit from '../booking/BookingEdit';
const CartContent = () => {
    {/* <BookingList /> */ }
    const theme = useTheme();
    const [kindCartShow, setKindCartShow] = useState(localStorage.getItem('kindCartShow') || "listCartProduct");
    const [infoCart, setInfoCart] = useState();
    const [detailBooking, setDetailBooking] = useState({
        status: null,
        item: null
    });  

    return (
        <Fragment>
            {kindCartShow === "listCartProduct" && (
                <ListCartProduct
                    setKindCartShow={setKindCartShow}
                    setInfoCart={setInfoCart}
                />
            )}
            {kindCartShow === "infoCartProduct" && (
                <InfoCartProduct infoCart={infoCart} setKindCartShow={setKindCartShow} setDetailBooking={setDetailBooking} />
            )}

            {kindCartShow === "detailBooking" && (
                <BookingEdit detailBooking={detailBooking} setKindCartShow={setKindCartShow} />
            )}

        </Fragment>
    )

}

export default CartContent
