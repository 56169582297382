import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import useTheme from '@material-ui/styles/useTheme';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import _ from 'lodash';
import swal from 'sweetalert';

import { useHistory } from 'react-router-dom';

import { useGetBookingDetail, useGetStatusBooking, useUpdateBooking, useGetBookingHistoryPopup } from '../hooks';
import { readMillions } from '../../../../helper/currency';
import { numberWithCommas, handleStatusList } from '../../../../helper/index';

import Moment from 'react-moment';
import * as FormatMoment from 'moment';
import AutoCompleteProjectItem from '../components/AutoCompleteProjectItem';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from 'axios';
import InputDateAndTimePicker from '../../../../components/InputDateAndTimePicker';
import ButtonReturn from '../../../../components/ButtonReturn';
import { Fragment } from 'react';
import clsx from 'clsx';
import { Edit, Lock, Save } from '@material-ui/icons';
import ecoeIcon from '../../../../assets/icons/logo-ecoe-no-char.svg';
import moment from 'moment';
import { ConfigContext } from '../../../../context/ConfigContext';

const useStyles = makeStyles(theme => ({

    headerStyle: {
        fontSize: '40px',
        color: "#00837b",
        textTransform: "uppercase",
        fontWeight: "bold",
        paddingBottom: '25px',
    },
    rowWrapper: {

        '&:not(.no-bottom)': {
            '& > div': {
                paddingBottom: '25px',
            },
        }
    },
    label: {
        fontWeight: 'bold',
        paddingRight: '10px',
        position: 'relative',
    },
    text: {
        fontSize: '14px'
    },
    buttonBack: {
        backgroundColor: "black",
        color: '#ffffff',
        padding: '6px 36px',
        marginTop: '60px',
        marginRight: '30px',
        borderRadius: '0',
        marginBottom: '30px'
    },
    circle: {
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        marginRight: '32px'
    },
    content: {
        fontSize: '18px',
        marginLeft: '10px',
        marginRight: '160px',
        wordWrap: 'break-word'
    },
    message: {
        border: 'none'
    },
    note: {
        borderBottomLeftRadius: '10px',
        borderTopLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        borderTopRightRadius: '0',
        boxShadow: '1px 5px 7px lightgrey',
        padding: '8px',
        '&.matches-md': {
            width: '400px',
        }
    },

    buttonActivity: {
        borderRadius: 0,
    },

    image: {
        width: "100%",
        minHeight: "300px",
    },
    footer: {
        '&.matches-tablets': {
            justifyContent: 'flex-end',
        },
        '& > button': {
            padding: "6px 30px",
            borderRadius: 0,
        },
        '& > button:not(:last-child)': {
            marginRight: '30px',
        }
    },
    marginDivider: {
        margin: '30px 0',
    },
}));


const BookingEdit = (props) => {
    const id = localStorage.getItem('selectedBooking');

    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const history = useHistory();
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesTablets = useMediaQuery('(min-width:640px)');
    const classes = useStyles();

    const { data: listStatus } = useGetStatusBooking();

    const { data, loading, refetch } = useGetBookingDetail(id);

    const [histories, setHistories] = useState([]);

    const configValue = useContext(ConfigContext);

    const { data: historyData, loading: historyLoading } = useGetBookingHistoryPopup(id);
    const [status, setStatus] = useState();
    const [bookingInvestorCode, setBookingInvestorCode] = useState();
    const [product, setProduct] = useState(null);
    const [productCode, setProductCode] = useState(null);
    const [receiptMedia, setReceiptMedia] = useState(null);
    const [payment, setPayment] = useState({
        amount: undefined,
        description: undefined,
        receipt_media: undefined,
        receipt_date: FormatMoment(new Date()).format('YYYY/MM/DD hh:mm:ss')
    });
    const [ecoeCode, setEcoeCode] = useState();

    const [description, setDescription] = useState();

    const updateBooking = useUpdateBooking(setOpenEdit, refetch, setOpenAdd);

    const handleParamPayment = () => {
        if (_.isUndefined(payment.amount) && _.isUndefined(payment.description) && _.isUndefined(payment.receipt_date) && _.isUndefined(payment.receipt_media)) {
            return undefined;
        }
        return [payment];
    }

    const handleUpdate = () => {
        swal("Bạn có muốn cập nhật lại booking này?", {
            icon: 'warning',
            buttons: ["Hủy", "Xác nhận"]
        }).then((confirm) => {
            if (confirm) {
                updateBooking({
                    variables: {
                        data: {
                            id,
                            product,
                            booking_investor_code: bookingInvestorCode,
                            payment: handleParamPayment(),
                            status,
                            description
                        }
                    }
                }).then(() => {
                    setPayment({
                        amount: undefined,
                        description: undefined,
                        receipt_media: undefined,
                        receipt_date: FormatMoment(new Date()).format('YYYY/MM/DD hh:mm:ss')
                    });
                    setReceiptMedia(null);
                    props.handleCloseModal()
                }).catch((err) => {
                    swal({
                        title: err,
                        icon: "error",
                    })
                    // console.error("Update error: ", err);
                })
            }
        })

    }

    const handleBlock = () => {
        swal("Bạn có muốn Khoá căn này không?", {
            icon: 'warning',
            buttons: ["Hủy", "Xác nhận"]
        }).then((confirm) => {
            if (confirm) {
                updateBooking({
                    variables: {
                        data: {
                            id,
                            product,
                            booking_investor_code: bookingInvestorCode,
                            payment: undefined,
                            status: 15,
                            description
                        }
                    }
                }).then(() => {
                    setPayment({
                        amount: undefined,
                        description: undefined,
                        receipt_media: undefined,
                        receipt_date: FormatMoment(new Date()).format('YYYY/MM/DD hh:mm:ss')
                    });
                    setReceiptMedia(null);
                })
            }
        })
    }


    const uploadHandler = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("media_type_code", "PHOTO")
        try {
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
            await axios.post(configValue.API_UPLOAD_FILE, formData, config).then((data) => {
                setReceiptMedia(_.get(data, 'data[0].url'))
                setPayment((prev) => ({ ...prev, receipt_media: _.get(data, 'data[0].id') }))
            }).catch(err => {
                return swal({
                    title: "Thêm ảnh ủy nhiệm chi thất bại",
                    icon: "error",
                })
            })

        } catch (error) {
            console.log(error);
        }
    }

    const [filteredListStatus, setFilteredListStatus] = useState([]);

    useEffect(() => {
        if (data) {
            setFilteredListStatus(handleStatusList(data, listStatus))
        }
    }, [data]);

    useEffect(() => {
        if (historyData) {
            let result = _.concat(_.get(historyData, 'booking_status_record'), _.get(historyData, 'payment'));
            result = _.sortBy(result, [function (o) { return o.created; }], ['desc']);
            setHistories(result);
        }
    }, [historyData]);

    const showHistory = (item) => {
        let result = null;
        if (item.amount !== undefined) {
            if (item.amount > 0) {
                result = <Fragment>
                    <Grid item style={{ fontSize: '18px' }}>  {moment(item.created).format('DD/MM/YYYY hh:mm A')}:</Grid>
                    <Grid item className={classes.content}>Booking cập nhật số tiền booking/đặt cọc (VND): {_.get(item, 'amount')} VND bởi <a
                        style={{
                            textDecoration: 'underline', cursor: 'pointer', fontSize: 18, fontWeight: 'bold'
                        }}
                        onClick={() => history.push(window.open('/employee/detail/' + _.get(item, 'account.id')))}
                    >
                        {_.get(item, 'account.code')}
                    </a></Grid>
                </Fragment>
            } else {
                result = <Fragment>
                    <Grid item style={{ fontSize: '18px' }}>  {moment(item.created).format('DD/MM/YYYY hh:mm A')}:</Grid>
                    <Grid item className={classes.content} >Booking cập nhật hình ảnh Ủy nhiệm chi bởi <a
                        style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: 18, fontWeight: 'bold' }}
                        onClick={() => history.push(window.open('/employee/detail/' + _.get(item, 'account.id')))}
                    >
                        {" "} {_.get(item, 'accountByCreatedBy.code')}
                    </a></Grid>
                </Fragment>
            }
        } else {
            if (item.booking_status.code === 'SELECTED') {
                result = <Fragment>
                    <Grid item style={{ fontSize: '18px' }}>  {moment(item.created).format('DD/MM/YYYY hh:mm A')}:</Grid>
                    <Grid item className={classes.content} >Booking cập nhật Đã chọn căn: {_.get(item, 'project_item.code')} bởi <a
                        style={{
                            textDecoration: 'underline', cursor: 'pointer', fontSize: 18, fontWeight: 'bold'
                        }}
                        onClick={() => history.push(window.open('/employee/detail/' + _.get(item, 'account.id')))}
                    >
                        {_.get(item, 'account.code')}
                    </a>
                    </Grid>
                </Fragment>
            } else {
                result = <Fragment>
                    <Grid item style={{ fontSize: '18px' }}>  {moment(item.created).format('DD/MM/YYYY hh:mm A')}:</Grid>
                    <Grid item className={classes.content}>Booking cập nhật {_.get(item, 'booking_status.name')} bởi <a
                        style={{
                            textDecoration: 'underline', cursor: 'pointer', fontSize: 18, fontWeight: 'bold'
                        }}
                        onClick={() => history.push(window.open('/employee/detail/' + _.get(item, 'account.id')))}
                    >
                        {_.get(item, 'account.code')}
                    </a></Grid>
                </Fragment>
            }

        }

        return result;
    }

    return (
        <Fragment>
            <Grid container direction="row" className={clsx(classes.rowWrapper)}>
                <Typography className={classes.headerStyle}>Thông tin chi tiết booking</Typography>
            </Grid>

            <Grid container direction="row" className={clsx(classes.rowWrapper)}>
                <Grid item container alignItems="center" direction="row" xs={6} md={3}>
                    <Grid item>
                        <Link className={classes.label}>{_.get(data, 'cart.projectByProject.title')}</Link>
                    </Grid>
                </Grid>
                <Grid item container alignItems="center" direction="row" xs={6} md={3}>
                    <Grid item style={{ width: '150px' }}>
                        <Typography className={classes.label}>Mã sản phẩm</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        <Link onClick={() => localStorage.removeItem('activeMenuProduct')} to={`/ product / primary / details / ${_.get(data, 'cart.projectByProject.id')}`}>{_.get(data, 'cart.projectByProject.code')}</Link>
                    </Grid>
                </Grid>
                <Grid item container alignItems="center" direction="row" xs={12} md={6}>
                    <Grid item>
                        <Typography className={classes.label}>{_.get(data, 'cart.name') || '-'} - {_.get(data, 'cart.status.name') || '-'}</Typography>
                    </Grid>
                </Grid>

            </Grid>

            <Grid container direction="row" className={clsx(classes.rowWrapper)}>
                <Grid item container alignItems="center" direction="row" xs={6} md={3}>
                    <Grid item>
                        <Typography className={classes.label}>Tên khách hàng:</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        <Typography>{_.get(data, 'customer.full_name') || "-"}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems="center" direction="row" xs={6} md={3}>
                    <Grid item>
                        <Typography className={classes.label}>Mã khách hàng:</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        <Link to={`/customer/detail/${_.get(data, 'customer.id')}`}>{_.get(data, 'customer.code')}</Link>
                    </Grid>
                </Grid>
                <Grid item container alignItems="center" direction="row" xs={6} md={3}>
                    <Grid item>
                        <Typography className={classes.label}>Số điện thoại:</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        <Typography>{_.get(data, 'customer.phone') || "-"}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems="center" direction="row" xs={6} md={3}>
                    <Grid item>
                        <Typography className={classes.label}>CMND/CCCD:</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        <Typography>{_.get(data, 'customer.personalid') || "-"}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container direction="row" className={clsx(classes.rowWrapper)}>
                <Grid item container alignItems="center" direction="row" xs={12} md={6}>
                    <Grid item>
                        <Typography className={classes.label}>Mã nhân viên ECOE tư vấn:</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        <Link to={`/employee/detail/${_.get(data, 'employee.id')}`}>{_.get(data, 'employee.code') || "-"}</Link>
                    </Grid>
                </Grid>
                <Grid item container alignItems="center" direction="row" xs={6} md={3}>
                    <Grid item style={{ width: '150px' }}>
                        <Typography className={classes.label}>Mã quản lý sales:</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        {
                            _.map(_.get(data, 'employee.employees'), (item, index) => {
                                if (index === _.size(_.get(data, 'employee.employees')) - 1) {
                                    return <Link to={`/employee/detail/${_.get(item, 'manager.accountByAccount.id')}`}>{_.get(item, 'manager.accountByAccount.code')}</Link>
                                }
                                return <Link to={`/employee/detail/${_.get(item, 'manager.accountByAccount.id')}`}>{_.get(item, "manager.accountByAccount.code")}</Link> + ', '
                            })
                        }
                    </Grid>
                </Grid>
                <Grid item container alignItems="center" direction="row" xs={6} md={3}>
                    <Grid item>
                        <Typography className={classes.label}>Mã văn phòng:</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        <Typography>{_.get(data, 'employee.employees[0].branch.code') || "-"}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container direction="row" className={clsx(classes.rowWrapper)} >
                <Grid item container alignItems="center" direction="row" xs={6} md={3}>
                    <Grid item>
                        <Typography className={classes.label}>Mã đặt chỗ của ECOE:</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        <Typography>{_.get(data, 'code') || "-"}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems="center" direction="row" xs={6} md={3}>
                    <Grid item>
                        <Typography className={classes.label}>Mã đặt chỗ:</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        {
                            openEdit ? (
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    style={{ backgroundColor: '#ffffff', width: 170 }}
                                    onChange={(e) => setBookingInvestorCode(e.target.value)}
                                    value={bookingInvestorCode}
                                />
                            )
                                :
                                (
                                    <Typography>{_.get(data, 'booking_investor_code') || '-'}</Typography>
                                )
                        }

                    </Grid>
                </Grid>
                <Grid item container alignItems="center" direction="row" xs={6} md={3}>
                    <Grid item>
                        <Typography className={classes.label}>STT ráp căn:</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        <Typography>{_.get(data, 'index', '-')}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems="center" direction="row" xs={6} md={3}>
                    <Grid item>
                        <Typography className={classes.label}>Loại booking/ưu tiên:</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        <Typography>
                            {
                                _.get(data, 'priority') ? `Ưu tiên ${_.get(data, 'priority')}` : _.get(data, 'status.name')
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container direction="row" className={clsx(classes.rowWrapper)} >
                {data && (
                    <Grid item container alignItems="center" direction="row" xs={6} md={6}>
                        <Grid item>
                            <Typography className={classes.label}>Chọn căn:</Typography>
                        </Grid>
                        <Grid item className={classes.text}>
                            {
                                openEdit ? (
                                    <AutoCompleteProjectItem
                                        // disabled={_.get(data, 'cart.status.code') !== "ASSEMBLED"}
                                        value={productCode}
                                        defaultValue={_.get(data, 'product.code')}
                                        setEcoeCode={setEcoeCode}
                                        onChange={(id) => setProduct(id)}
                                        cartId={_.get(data, 'cart.id')}
                                        width={150}
                                    />
                                )
                                    :
                                    (
                                        <Typography>{_.get(data, 'product.code') || '-'}</Typography>
                                    )
                            }

                        </Grid>
                    </Grid>
                )}

                <Grid item container alignItems="center" direction="row" xs={6} md={6}>
                    <Grid item>
                        <Typography className={classes.label}>Mã SP ECOE:</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        {
                            openEdit && _.get(data, 'cart.status.id') === 1 ? (
                                <TextField
                                    disabled={true}
                                    variant="outlined"
                                    size="small"
                                    style={{ backgroundColor: '#ffffff', width: 170 }}
                                    onChange={(e) => setEcoeCode(e.target.value)}
                                    value={_.isNull(ecoeCode) ? '' : ecoeCode}
                                />
                            )
                                :
                                (
                                    <Typography>{_.get(data, 'product.ecoe_code') || '-'}</Typography>
                                )
                        }
                    </Grid>
                </Grid>
                {/* <Grid item container alignItems="center" direction="row" xs={6} md={3}>
                    <Grid item>
                        <Typography className={classes.label}>Xác nhận chuyển tiền</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        <Typography>P1200001-001-07</Typography>
                    </Grid>
                </Grid> */}
                {
                    (openEdit && filteredListStatus) && (
                        <Grid item container alignItems="center" direction="row" xs={6} md={6}>
                            <Grid item>
                                <Typography className={classes.label}>Xác nhận đặt cọc:</Typography>
                            </Grid>
                            <Grid item className={classes.text}>
                                <FormControl variant="outlined" size="small" style={{ backgroundColor: '#ffffff', width: 210 }}>
                                    <Select
                                        labelId="status"
                                        id="status"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                        {_.map(filteredListStatus, (item, key) => (
                                            <MenuItem key={_.get(item, 'id')} value={_.get(item, 'id')}>{_.get(item, 'name2')}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    )
                }

            </Grid>

            <Grid container direction="row" className={clsx(classes.rowWrapper)} >
                <Grid item container direction="row" xs={12} md={12}>
                    <Grid item>
                        <Typography className={classes.label}>Số tiền booking/đặt cọc (VNĐ):</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        <Typography>{numberWithCommas(_.reduce(_.get(data, 'payments'), (acc, curr) => { return acc + curr.amount }, 0) || 0)}</Typography>
                        {
                            _.reduce(_.get(data, 'payments'), (acc, curr) => { return acc + curr.amount }, 0) > 0 && (
                                <Typography>
                                    {`(Số tiền bằng chữ: ${readMillions(_.reduce(_.get(data, 'payments'), (acc, curr) => { return acc + curr.amount }, 0) || "-", false)} đồng)`}
                                </Typography>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>

            <Grid container direction="row" className={clsx(classes.rowWrapper)}>
                <Grid item container alignItems="center" direction="row" xs={12} md={12}>
                    <Grid item>
                        <Typography className={classes.label}>Ngày tạo thông tin chuyển tiền:</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        <Typography>{_.get(data, `payments[${_.size(_.get(data, 'payments')) - 1}].receipt_date`) ? FormatMoment(_.get(data, `payments[${_.size(_.get(data, 'payments')) - 1}].receipt_date`)).utc().format('DD/MM/YYYY') : '--'}</Typography>
                    </Grid>
                </Grid>

                {/* <Grid item container alignItems="center" direction="row" style={{ marginBottom: '26px' }} xs={8}>
                    <Grid item style={{ width: '150px' }} xs={2}>
                        <Typography className={classes.label}>Thông tin phiếu UNC</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        <Typography></Typography>
                    </Grid>
                </Grid> */}

            </Grid>

            {
                openEdit && (
                    <Grid container direction="row" className={clsx(classes.rowWrapper)}>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setOpenAdd(!openAdd);
                                    setStatus(_.get(data, 'status.id'));
                                    setDescription(_.get(data, 'description'));
                                    setBookingInvestorCode(_.get(data, 'booking_investor_code'));
                                    setEcoeCode(_.get(data, 'product.ecoe_code'));
                                    setProduct(_.get(data, 'product.id'));
                                    setProductCode(_.get(data, 'product.code'))
                                }}
                            >
                                {openAdd ? "Đóng" : "Thêm"}
                            </Button>
                        </div>
                    </Grid>
                )
            }

            {
                openAdd && (
                    <Grid container direction="row">
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '26px' }}>
                            <Grid container direction="column" className={classes.text}>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item style={{ width: '160px' }}>
                                        <Typography className={classes.label}>Số tiền đặt chổ/ đặt cọc</Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            onChange={(e) => setPayment({ ...payment, amount: e.target.value })}
                                            value={payment.amount}
                                        />
                                    </Grid>
                                    {" "}
                                    <Grid item style={{ marginLeft: 10 } || 0}>Đơn vị: VNĐ</Grid>
                                </Grid>
                                <Grid item style={{ marginLeft: 160 }}>
                                    <Typography>Bằng chữ: {payment.amount > 0 ? `${readMillions(payment.amount)} đồng` : '--'} </Typography>
                                </Grid>
                                <Grid container direction="row" alignItems="center" style={{ marginTop: 20 }}>
                                    <Grid item style={{ width: '200px' }} xs={2}>
                                        <Typography className={classes.label}>Upload ủy nhiệm chi <span style={{ color: 'red' }}>*</span></Typography>
                                    </Grid>
                                    <Grid item style={{ width: '200px' }} xs={4}>
                                        <input
                                            accept="image/*"
                                            id="face-id-customer"
                                            multiple
                                            type="file"
                                            hidden
                                            onChange={uploadHandler}
                                        />
                                        <label htmlFor="face-id-customer">
                                            {receiptMedia === null ? (
                                                <CloudUploadIcon style={{ fontSize: 150, cursor: 'pointer' }} />
                                            ) : (
                                                <img src={`${configValue.MEDIA_DOMAIN}${receiptMedia}`} width="120" alt="image receipt" />
                                            )}

                                        </label>
                                    </Grid>
                                    <Grid item style={{ width: '200px' }} xs={2}>
                                        <Typography className={classes.label}>Thông tin phiếu ủy nhiệm chi</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            onChange={(e) => setPayment({ ...payment, description: e.target.value })}
                                            value={payment.description}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography className={classes.label}>Ngày chuyển tiền</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputDateAndTimePicker setDateTime={(value) => setPayment((prev) => ({ ...prev, receipt_date: FormatMoment(value).format('YYYY/MM/DD hh:mm:ss') }))} />
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid>
                )
            }

            <Grid container direction="row" className={clsx(classes.rowWrapper)}>
                <Grid item container alignItems="center" direction="row" xs={12}>
                    <Typography className={classes.label}>Hình ảnh phiếu UNC:</Typography>
                </Grid>
                <Grid item container direction='row' xs={12}>
                    {_.map(_.get(data, 'payments'), (item, key) => {
                        return (
                            <Fragment>
                                {_.get(item, 'media.url') !== undefined && (
                                    <Grid key={_.get(item, 'id')} item xs={matchesTablets ? 6 : 12} md={4}>
                                        <img alt="Hình ảnh uỷ nhiệm chi" className={classes.image} src={`${configValue.MEDIA_DOMAIN}${_.get(item, 'media.url')}`} />
                                    </Grid>
                                )}
                            </Fragment>
                        )
                    })}
                </Grid>
            </Grid>

            <Grid container direction="row" className={clsx(classes.rowWrapper)} >
                <Grid item container alignItems="center" direction="row" xs={12}>
                    <Grid item>
                        <Typography className={classes.label}>Ghi chú:</Typography>
                    </Grid>

                    <Grid item className={classes.text}>
                        {openEdit ? (
                            <Paper className={clsx(classes.note, matchesMD && 'matches-md')}>
                                <TextField
                                    className={classes.message}
                                    InputProps={{ disableUnderline: true }}
                                    multiline
                                    onChange={(e) => setDescription(e.target.value)}
                                    value={description || ''}
                                    style={{ width: '30em', height: '100%' }}
                                    id="message"
                                    rows={5}
                                />
                            </Paper>
                        ) : (
                            <Typography>{_.get(data, 'description') || "-"}</Typography>
                        )}

                    </Grid>
                </Grid>
            </Grid>

            <Grid container direction="row" className={clsx(classes.rowWrapper, 'no-bottom')} >
                <Grid item container alignItems="center" direction="row" xs={matchesTablets ? 6 : 12} md={4}>
                    <Grid item>
                        <Typography className={classes.label}>Người tạo booking:</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        <Link to={`/employee/detail/${_.get(data, 'accountByCreatedBy.id')}`}>
                            <Typography>{_.get(data, 'accountByCreatedBy.code')}</Typography>
                        </Link>
                    </Grid>
                </Grid>

                <Grid item container alignItems="center" direction="row" xs={matchesTablets ? 6 : 12} md={4}>
                    <Grid item>
                        <Typography className={classes.label}>Ngày tạo booking:</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        <Typography>{_.get(data, 'created') ? <Moment format="DD/MM/YYYY">{_.get(data, 'created')}</Moment> : "-"}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Divider className={classes.marginDivider} />

            <Grid container spacing={1} alignItems="center" className={clsx(classes.footer, matchesTablets && 'matches-tablets')} >

                <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<Lock />}
                    className={classes.buttonActivity}
                    onClick={handleBlock}
                    disabled={
                        !_.get(data, 'product.code') ||
                        _.get(data, 'status.id') === 15 ||
                        _.get(data, 'cart.status.code') === "ASSEMBLED" ||
                        _.get(data, 'cart.status.code') === "BOOKING"
                    }
                >
                    Khoá căn
                </Button>

                {
                    openEdit ? (
                        <Button
                            color="primary"
                            variant="contained"
                            startIcon={<Save />}
                            className={classes.buttonActivity}
                            onClick={handleUpdate}
                        >
                            Cập nhật
                        </Button>
                    )
                        :
                        (
                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<Edit />}
                                className={classes.buttonActivity}
                                onClick={() => {
                                    setOpenEdit(true);
                                    setStatus(_.get(data, 'status.id'));
                                    setDescription(_.get(data, 'description'));
                                    setBookingInvestorCode(_.get(data, 'booking_investor_code'));
                                    setEcoeCode(_.get(data, 'product.ecoe_code'));
                                    setProduct(_.get(data, 'product.id'));
                                    setProductCode(_.get(data, 'product.code'));

                                }}
                            >
                                Chỉnh sửa
                            </Button>
                        )
                }

                <ButtonReturn actionClick={props.handleCloseModal} />
            </Grid>
            <Grid>
                <h2 >Lịch sử cập nhật</h2>
                {
                    _.map(histories, (item, index) => <Grid container direction="row" style={{ marginTop: '60px', flexWrap: 'wrap' }} alignItems="center" key={index}>

                        <Grid item className={classes.circle} style={{ backgroundColor: '#dfe235' }} ></Grid>
                        <Grid item style={{ marginRight: '5px' }}><img src={ecoeIcon} alt="logo" width={40} /></Grid>

                        {showHistory(item)}


                    </Grid>)
                }
            </Grid>
        </Fragment >
    );
}

export default BookingEdit;