import React from 'react'
import { Link } from 'react-router-dom';
import { Grid, Typography, TextField } from '@material-ui/core';
import { handleFlex } from '../../../helper/index';
import InputComponent from './InputComponent';
import AutoCompleteCustomer from '../../../components/AutoCompleteCustomer';
import { makeStyles } from '@material-ui/styles';
import AutoCompleteEmployee from '../../../components/AutoCompleteEmployee';
import SelectComponent from './SelectComponent';

const CustomerReviewCreate = ({ 
    setCustomerId, 
    fullName, 
    phone, 
    email, 
    personalId, 
    setPersonalId, 
    setEmployeeEcoeId, 
    fullNameEmployee, 
    setPhone, 
    setEmail,
    setFavorites,
    favorites,
    setContentTransaction,
    contentTransaction,
    setTimeTrans,
    timeTrans,
    rate,
    setRate,
    note,
    setNote
}) => {

    const useStyles = makeStyles((theme) => ({
        linkCreateCus: {
            color: '#388A81',
            fontSize: 16,
            marginLeft: 70
        }
    }));

    const classes = useStyles();

    return (
        <Grid container direction="column" style={{marginTop: 20}}>
            <Grid container direction="row">
                <Grid item style={handleFlex('row', 'center')}>
                    <Typography style={{width: 330}}>Mã khách hàng <span style={{color: 'red'}}>*</span></Typography>
                    <AutoCompleteCustomer width={330} onChange={(id) => setCustomerId(id)} />
                </Grid>
                <Grid item style={handleFlex('row', 'center')}>
                    <Link to={`/customer/create`} className={classes.linkCreateCus}>Tạo khách hàng mới</Link>
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 10 }}>
                    <InputComponent 
                        title={<p>Tên<span style={{color: 'red'}}>*</span></p>} 
                        widthTitle={330} 
                        style={{width: 330}}
                        value={fullName || ''} 
                        disabled={true} 
                    />
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 10 }}>
                    <InputComponent 
                        title={<p>Số điện thoại<span style={{color: 'red'}}>*</span></p>} 
                        widthTitle={330} 
                        style={{width: 330}}
                        value={personalId || ''} 
                        disabled={true} 
                        value={phone || ''}  
                        handleChange={(e) => setPhone(e.target.value)} 
                    />
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 10 }}>
                    <InputComponent title="Email" style={{width: 330}} widthTitle={330} disabled={true} value={email || ''}  handleChange={(e) => setEmail(e.target.value)} />
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 10 }}>
                    <InputComponent style={{width: 330}} title="CMND/CCCD" widthTitle={330} value={personalId || ''} disabled={true}  handleChange={(e) => setPersonalId(e.target.value)} />
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 10 }}>
                    <Typography style={{width: 330}}>Mã nhân viên ECOE được đánh giá <span style={{color: 'red'}}>*</span> </Typography>
                    <AutoCompleteEmployee width={330} onChange={(id) => setEmployeeEcoeId(id)} />
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 10 }}>
                    <InputComponent style={{width: 330}}  title="Tên nhân viên ECOE được đánh giá" widthTitle={330} disabled={true} value={fullNameEmployee || ''} />
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 10 }}>
                    <InputComponent 
                        style={{width: 330}} 
                        title="Nội dung giao dịch" 
                        widthTitle={330} 
                        value={contentTransaction || ''} 
                        handleChange={(e) => setContentTransaction(e.target.value)} 
                    />
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 10 }}>
                    <SelectComponent 
                        handleChange={(e) => setFavorites(e.target.value)} 
                        value={favorites}  
                        widthTitle={330} 
                        title="Hạng mục yêu thích" 
                        width={330} 
                    />
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 10 }}>
                    <InputComponent 
                        style={{width: 330}} 
                        title={<p>Thời gian giao dịch <span style={{color: 'red'}}>*</span></p>}
                        widthTitle={330} 
                        value={timeTrans || ''} 
                        handleChange={(e) => setTimeTrans(e.target.value)} 
                    />
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 10 }}>
                    <InputComponent 
                        style={{width: 330}} 
                        title={<p>Điểm đánh giá (Tối đa 5) <span style={{color: 'red'}}>*</span></p>}
                        widthTitle={330} 
                        value={rate || ''} 
                        handleChange={(e) => setRate(e.target.value)} 
                    />
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 10 }}>
                   <InputComponent 
                        style={{width: 330}} 
                        title="Ghi chú"
                        widthTitle={330} 
                        multiline={true}
                        value={note || ''} 
                        handleChange={(e) => setNote(e.target.value)} 
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CustomerReviewCreate
