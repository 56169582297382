import {
  PROJECT_GET_ONE,
} from "../../../graphql/schemas/product/projectQueries";
import * as _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import { GET_DETAIL_CART } from "../../../graphql/schemas/product/mutations";
import {
  GET_BOOKING_DETAIL,
  GET_PROJECT_ITEM,
  GET_BOOKING_STATUS,
  GET_BOOKING_HISTORY,
  GET_PROJECT_CART_STATUS_HISTORY,
  GET_BOOKING_HISTORY_POPUP,
} from "../../../graphql/schemas/booking/bookingQueries";
import { UPDATE_BOOKING } from "../../../graphql/schemas/booking/mutations";
import {
  CREATE_CART_PRODUCT,
  UPDATE_CART_PRODUCT,
} from "../../../graphql/schemas/cart/mutations";
import swal from "sweetalert";

export const useProject = ({ id }) => {
  const { data } = useQuery(PROJECT_GET_ONE, {
    variables: {
      id,
    },
    skip: !id,
  });
  return _.get(data, "project");
};

export const useCreateCart = (id, data, history) => {
  const [create] = useMutation(CREATE_CART_PRODUCT, {
    variables: {
      data: {
        project: id,
        project_items: data,
      },
    },
    onCompleted: () => {
      return swal({
        title: "Tạo giỏ hàng thành công",
        icon: "success",
      }).then(() => history.push(`/product/primary/details/${id}`));
    },
    onError: () => {
      return swal({
        title: "Tạo giỏ hàng thất bại",
        icon: "error",
      });
    },
  });

  return {
    create,
  };
};

export const useUpdateCart = (idCart, data, history, id, setData, setColumns) => {
  const [update] = useMutation(UPDATE_CART_PRODUCT, {
    variables: {
      data: {
        id: idCart,
        project_items: data,
      },
    },
    onCompleted: () => {
      setData([]);
      setColumns([]);
      return swal({
        title: "Cập nhật giỏ hàng thành công",
        icon: "success",
      }).then(() => history.push(`/product/primary/details/${id}`));
    },
    onError: (error) => {
      setData([]);
      setColumns([]);
      return swal({
        title: 'Lỗi thất bại',
        text: `${error.message}`,
        icon: "error",
      });
    },
  });

  return {
    update,
  };
};

export const useGetDetailCart = (id) => {
  const { data } = useQuery(GET_DETAIL_CART, {
    variables: {
      condition: {
        deleted: {
          _eq: false,
        },
        id: {
          _eq: id,
        },
      },
    },
    skip: !id,
    fetchPolicy: "cache-and-network",
  });

  return _.get(data, "result");
};

export const useGetBookingDetail = (id) => {
  const { data, loading, refetch } = useQuery(GET_BOOKING_DETAIL, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: "no-cache",
  });

  return {
    data: _.get(data, "result"),
    loading,
    refetch,
  };
};

export const useGetStatusBooking = () => {
  const { data, loading } = useQuery(GET_BOOKING_STATUS);
  return {
    loading,
    data: _.get(data, "result"),
  };
};

export const useGetBookingHistory = ({ id }) => {
  const { data, loading } = useQuery(GET_BOOKING_HISTORY, {
    variables: {
      projectId: id,
    },
    skip: !id,
  });
  return {
    loading,
    data: _.get(data, "booking_status_record"),
  };
};

export const useProjectItemCart = ({ id, searchText }) => {
  const { data, loading } = useQuery(GET_PROJECT_ITEM, {
    variables: {
      cartId: id,
      search: searchText,
    },
    skip: !id
  });
  return {
    data: _.get(data, "result"),
    loading,
  };
};

export const useUpdateBooking = (setOpenEdit, refetch, setOpenAdd) => {
  const [update] = useMutation(UPDATE_BOOKING, {
    onCompleted: () => {
      refetch();
      return swal({
        title: "Cập nhật booking thành công",
        icon: "success",
      }).then(() => {
        setOpenEdit(false);
        setOpenAdd(false);
      });
    },
    onError: () => {
      return swal({
        title: "Cập nhật booking thất bại",
        icon: "error",
      });
    },
  });

  return update;
};

export const useGetProjectCartStatusHistory = ({ id }) => {
  const { data, loading } = useQuery(GET_PROJECT_CART_STATUS_HISTORY, {
    variables: {
      project: id,
    },
    skip: !id
  });
  return {
    loading,
    data: _.get(data, "project_cart_status_record"),
  };
};

export const useGetBookingHistoryPopup = (id) => {
  const { data, loading } = useQuery(GET_BOOKING_HISTORY_POPUP, {
    variables: {
      bookingId: id,
    },
    skip: !id
  });
  return {
    loading,
    data: data,
  };
};