import React, { useEffect, useState } from 'react';
import { Grid, Paper, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as _ from 'lodash';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 'calc(100vh - 80px)',
        position: 'relative',
        width: 'calc(100%)',
        boxShadow: 'none',
        overflow: 'scroll'
    },
    gridRowStyle: {
        marginTop: '30px',
    },
    description: {
        marginLeft: '100px',

        '&:not(first-child)': {
            marginTop: '10px',
        },
        '&:before': {
            content: '""',
            width: '100px',
            height: '30px',
            background: '#a4c2f4',
            paddingRight: '100px',
        },
        '&.deposit:before': {
            background: '#f00',
        },
        '&.booking:before': {
            background: '#f90',
        },
        '& > span': {
            paddingLeft: '10px',
        }
    },
    buttonAdd: {
        backgroundColor: '#2C8A81',
        borderRadius: '0',
        color: '#ffffff',
        textTransform: 'none',
        padding: '10px 30px'
    },
    topTitleContainer: {
        paddingTop: '30px'
    },
    tableContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 20,
    },
    rowStyle: {
        background: 'white',
        display: 'inline-flex',
        width: '100%',
    },
    gridTable: {
        display: 'grid',
        width: 'calc(100% - 100px)',
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        textAlign: 'center',
        border: '1px solid rgba(0, 0, 0, 0.3)',
        width: '100%',
        height: '30px',
        background: '#fff',
        '&.headerStyle': {
            background: '#0f0',
        },
        '&.indexFloor': {
            background: '#ff0',
        },

        '&.status-1': {
            background: '#a4c2f4',
        },
        '&.status-2': {
            background: '#f00',
        },
        '&.status-3': {
            background: '#f90',
        },
        '& > span': {
            fontSize: '12px',
            fontWeight: 'bold',
        }
    },
}))

const GetPriorityText = ({ productId, bookings }) => {
    let priorities = _.sortBy(_.map(_.filter(bookings, ['product.id', productId]), item => _.get(item, 'priority') && `UT${_.get(item, 'priority')}`));

    if (_.size(priorities) > 0) {
        return _.join(priorities, '/');
    }

    return;
}

const ProductList = ({ status, data, handleFileUpload, bookings }) => {
    const classes = useStyles();
    const [bodyData, setBodyData] = useState([]);
    const maxFloor = _.get(_.last(_.sortBy(data, (item) => (item.floor) * 1)), 'floor') * 1;
    const maxNo = _.get(_.last(_.sortBy(data, (item) => (item.apartment_no) * 1)), 'apartment_no') * 1;
    const header = Array.from(new Array(maxNo || 0), (x, i) => i);
    const floor = Array.from(new Array(maxFloor || 0), (x, i) => i);
    const handleLoadBody = () => {
        let body = [];
        for (let i = 1; i <= maxFloor; i++) {
            for (let j = 1; j <= maxNo; j++) {
                let result = _.filter(data, item => {
                    return item.apartment_no * 1 === j && item.floor * 1 === i;
                })
                if (result.length > 0) {
                    body.push(
                        <Tooltip
                            // key={key}
                            disableFocusListener
                            title={
                                <div>
                                    <div>{_.get(result[0], 'code')}</div>
                                    <div>{_.get(result[0], 'status.name')}</div>
                                </div>
                            }
                        >
                            <div
                                className={clsx(classes.gridItem, _.get(result[0], 'status.code') === 'INITIAL' && 'status-1', _.get(result[0], 'status.code') === 'SOLD' && 'status-2', _.includes(['BOOKING', 'DEPOSIT', 'CHECKING_DEPOSIT'], _.get(result[0], 'status.code')) && 'status-3')}
                            >
                                {
                                    _.get(result[0], 'status.code') !== 'SOLD' &&
                                    <span>{GetPriorityText({ bookings: bookings, productId: _.get(result[0], 'id') })}</span>
                                }
                            </div>
                        </Tooltip>
                    )
                } else {
                    body.push(
                        <Tooltip
                            // key={key}
                            //disableFocusListener
                            title={
                                <div>
                                    <div>SP không thuộc ECOE</div>
                                </div>
                            }

                        >
                            <div
                                className={clsx(classes.gridItem)}
                            />
                        </Tooltip>
                    )
                }
            }
        }
        setBodyData(body);
    }
    useEffect(() => {
        handleLoadBody();
    }, []);


    return (
        <Paper className={classes.root}>
            <Grid container className={classes.gridRowStyle}>

                <div className={classes.rowStyle} >
                    <div style={{ width: '100px' }}>Mã căn</div>
                    <div className={classes.gridTable} style={{ gridTemplateColumns: `repeat(${maxNo}, ${100 / maxFloor}%)` }}>
                        {
                            _.map(header, (index, key) => <div key={key} className={clsx(classes.gridItem, 'headerStyle')}>{index + 1}</div>)
                        }
                    </div>
                </div>

                <div className={classes.rowStyle} >
                    <div style={{ width: '100px' }}>
                        {
                            _.map(floor, (index, key) => {
                                if (index == 0) return <div key={key} className={clsx(classes.gridItem, 'indexFloor')}>Tầng G</div>
                                return <div key={key} className={clsx(classes.gridItem, 'indexFloor')}>{`Tầng ${index + 1}`}</div>
                            })
                        }
                    </div>
                    <div className={classes.gridTable} style={{ gridTemplateColumns: `repeat(${maxNo}, ${100 / maxFloor}%)` }}>
                        {bodyData}
                    </div>

                </div>
            </Grid>

            <div className={clsx(classes.gridRowStyle)} >
                <div className={clsx(classes.description, 'nomal')}><span>Giỏ hàng trống</span></div>
                <div className={clsx(classes.description, 'deposit')}><span>Đã cọc</span></div>
                <div className={clsx(classes.description, 'booking')}><span>Đang book</span></div>
            </div>

        </Paper >
    )
}

export default ProductList;

