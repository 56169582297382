import { gql } from '@apollo/client';

export const CREATE_NOTIFICATION = gql`
mutation createNotification($typeCode: String!, $notification: NotificationInput!, $accounts: [Int], $employeeCodes: [String], $customerCodes: [String]) {
  result: createNotification(typeCode: $typeCode, notification: $notification, accounts: $accounts, employeeCodes: $employeeCodes, customerCodes: $customerCodes) {
    id
    type: notification_type {
      id
      code
      name
    }
    deep_link
    url_image
    title
    body
    notification_accounts {
      seen
      account
      accountByAccount {
        id
        code
        username
        full_name
        email
        phone
        birthday
      }
    }
    created_by: accountByCreatedBy {
      id
      code
      username
      full_name
      email
      phone
      birthday
    }
  }
}

`;

export const GET_LIST_NOTIFICATION = gql`
query getNotifications($skip: Int!, $take: Int!, $type: String) {
  results: notification(offset: $skip, limit: $take, order_by: {created: desc}, where: {notification_type: {code: {_eq: $type}}}) {
    id
    type
    notification_type {
      id
      code
      name
    }
    created
    deep_link
    url_image
    title
    body
    notification_accounts {
      seen
      account
      accountByAccount {
        id
        code
        username
        full_name
        email
        phone
        birthday
      }
    }
    created_by: account {
      id
      code
      username
      full_name
      email
      phone
      birthday
    }
  }
  t: notification_aggregate {
    aggregate {
      count
    }
  }
}
`

export const GET_COUNT_HOME_PAGE = gql`
  query GetCountHomePage($account: bigint!) {
    all_count: notification_account_aggregate(where: {account: {_eq: $account}, seen: {_eq: false}}) {
    aggregate {
      count
    }
  }
}
`
export const GET_NEW_NOTIFICATIONS = gql`
query GetNewNotifications($account: bigint!) {
  results: notification_account(where: {account: {_eq: $account}, seen: {_eq: false}}, order_by: {notificationByNotification: {created: desc}}) {
    seen
    notification: notificationByNotification {
      id
      type
      notification_type {
        id
        code
        name
      }
      deep_link
      url_image
      title
      body
      created
    }
  }
}
`
export const GET_OLD_NOTIFICATIONS = gql`
query GetOldNotifications($skip: Int!, $take: Int!, $account: bigint!) {
  results: notification_account(offset: $skip, limit: $take, where: {account: {_eq: $account}, seen: {_eq: true}}, order_by: {notificationByNotification: {created: desc}}) {
    seen
    notification: notificationByNotification {
      id
      type
      notification_type {
        id
        code
        name
      }
      deep_link
      url_image
      title
      body
      created
    }
  }
}
`
