import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { GET_CUSTOMER_BY_CODE_FILTER } from '../graphql/schemas/customer/customerQueries';
import { useQuery } from '@apollo/client';
import * as _ from "lodash";

AutoCompleteCustomer.propTypes = {

};

function AutoCompleteCustomer({ type, onChange, value, width = undefined }) {
    const [optList, setOptList] = useState([]);
    const [searchText, setSearchText] = useState("");
    var listOption = [];
    const variables = {
        skip: 0,
        take: 10,
        where: {
            accountByAccount: {
                code: {
                    _ilike: `%${searchText}%`
                }

            }
        }
    }

    const { loading, data } = useQuery(GET_CUSTOMER_BY_CODE_FILTER, { variables: variables });

    if (!_.isEmpty(_.get(data, 'results'))) {
        listOption = _.map(_.get(data, 'results'), (item) => {
            return {
                id: _.get(item, "info.id"),
                code: _.get(item, "info.code")
            }
        });
    }



    const handleSelect = (e, value) => {
        try {
            if (value) {
                onChange(value.id);
            } else {
                onChange(null);
            }
        } catch (error) {
            console.log("auto complete customer error", error);
        }
    }

    return (
        <Autocomplete
            id={`combo-box-${type}`}
            options={listOption}
            getOptionLabel={(option) => _.toString(option.code)}
            onChange={handleSelect}
            defaultValue={{ code: value }}
            // style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} variant="outlined" size="small" style={{ backgroundColor: '#ffffff', width }} value={value} onChange={(e) => setSearchText(e.target.value)} />}
        />
    );
}

export default AutoCompleteCustomer;