import React, { Fragment } from 'react';
import { Link, useHistory, useParams, Redirect } from 'react-router-dom';
import { Grid, Typography, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, useMediaQuery, Button, Divider, CircularProgress } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { AddShoppingCart, Telegram } from '@material-ui/icons';
import * as _ from 'lodash';
import slugs from '../../../../resources/slugs';
import ButtonReturn from '../../../../components/ButtonReturn';
import Moment from 'react-moment';
import { useGetProjectCarts } from '../../../../graphql/schemas/hooks';

const useStyles = makeStyles(theme => ({
    wapperLoading: {
        width: '100%',
        height: 'calc(100vh - 113px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    titleContainer: {
        width: 600,
        marginTop: 40,
        '& > .MuiGrid-item': {
            display: 'flex',
            alignItems: 'center',
            '& > p:nth-child(1)': {
                fontWeight: 'bold',
                marginRight: 20
            },
            '& > p:nth-child(2)': {
                fontSize: 13
            }
        }
    },
    table: {
        minWidth: 500,
    },
    tableHead: {
        backgroundColor: '#F2F2F2',
        '& > .MuiTableCell-head': {
            color: '#FA7D61',
            fontWeight: 'bold'
        }
    },
    itemCart: { 
        cursor: 'pointer',
    },
    addCartButton: {
        marginBottom: "10px",
        fontSize: "38px",
        color: "#00837b",
        cursor: "pointer"
    },
    marginDivider: {
        margin: '30px 0',
        height: 0,
    },
    groupButtonFooter: {
        '& > button': {
            padding: "6px 30px",
            borderRadius: 0,
        },
        '& > button:not(:last-child)': {
            marginRight: '30px',
        }
    },
}));

const ListCartProduct = ({ setKindCartShow, setInfoCart }) => {    
    // /product/primary/cart/create
    {/* <BookingList /> */ }
    const { id } = useParams();

    const classes = useStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    const { data, loading, error } = useGetProjectCarts({ project: id });
    
    const selectProject = JSON.parse(localStorage.getItem('selectProject'));

    const handleItemCart = (cart) => {
        setKindCartShow("infoCartProduct"); 
        setInfoCart(cart);
        localStorage.setItem('kindCartShow', 'infoCartProduct')
        if(localStorage.getItem('selectProject')) {
            let tmp = JSON.parse(localStorage.getItem('selectProject'))
            _.set(tmp, 'indexCard', cart)
            localStorage.setItem('selectProject', JSON.stringify(tmp))
        }
    };

    if (loading) return (
        <div className={classes.wapperLoading}>
            <CircularProgress />
        </div>
    )
    
    if (error) return <Redirect to="/" />

    return (
        <Fragment>
            <Grid container direction="column">
                <Grid container direction="row" className={classes.titleContainer} justifyContent="space-between">
                    <Grid item>
                        <Typography>{_.get(selectProject, 'title')}</Typography>
                    </Grid>
                    <Grid item style={{ display: 'flex' }}>
                        <Typography>Mã sản phẩm</Typography>
                        <Typography>{_.get(selectProject, 'code')}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container direction="row" justifyContent={matchesSM ? "flex-start" : "flex-end"}>
                <Link to={`/product/primary/cart/create/${id}`}>
                    <AddShoppingCart className={classes.addCartButton} />
                </Link>

                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.tableHead}>
                                <TableCell align="center">Tên</TableCell>
                                <TableCell align="center">Ngày Ráp Căn</TableCell>
                                <TableCell align="center">Ngày Mở bán</TableCell>
                                <TableCell align="center">Tình Trạng</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(_.get(data, 'result'), (item, index) => (
                                <TableRow
                                    key={index}
                                    className={classes.itemCart}
                                    onClick={()=>handleItemCart(item)}
                                >
                                    <TableCell align="center" >{_.get(item,'name')}</TableCell>
                                    <TableCell align="center" >
                                        { _.get(item,'assembly_date') ? <Moment format="DD/MM/YYYY">{_.get(item, 'assembly_date')}</Moment> : "Chưa cập nhật" }
                                    </TableCell>
                                    <TableCell align="center" >
                                        { _.get(item,'sale_date') ? <Moment format="DD/MM/YYYY">{_.get(item, 'sale_date')}</Moment> : "Chưa cập nhật" }
                                    </TableCell>
                                    <TableCell align="center" >
                                        { _.get(item,'project_cart_status') ? _.get(item,'project_cart_status.name') : "Chưa cập nhật" }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Divider className={classes.marginDivider} />

            <Grid container direction="row" className={classes.groupButtonFooter} justifyContent='flex-end'>
                {/* <Button
                    color="primary"
                    variant="contained"
                    startIcon={<Telegram />}
                    // onClick={}
                >Xét Duyệt</Button> */}

                <ButtonReturn link={slugs.productPrimary} />
            </Grid>
        </Fragment>
    )
}

export default ListCartProduct;
