import * as _ from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Typography, TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    inputStyle: {
        width: 100,
        backgroundColor: 'white',
        marginLeft: '0',
    },
    unit: {
        fontWeight: 'normal',
        color: '#999',
        paddingLeft: '10px',
    },
}));


const InputMinutes = ({ fieldname, object, setObject, labelUnit, defaultValue }) => {
    const classes = useStyles();
    const [inputValue, setInputValue] = useState(_.get(object, fieldname));

    const onBlurSetValue = (event) => {
        const value = _.trim(inputValue);

        if (_.get(object, fieldname) === value) return;

        if (_.isEmpty(value) && inputValue === undefined) return;

        let tmp = { ...object };

        if (_.isEmpty(value) && inputValue !== undefined) {
            tmp = { ..._.omit(object, fieldname) };
        }
        if (!_.isEmpty(value)) {
            _.set(tmp, fieldname, value * 1);
        }

        

        if (_.isFunction(setObject)) setObject(tmp);
    };

    const onchangeAction = (e) => {
        let value = _.get(e, 'target.value');

        if (value < 0) {
            return setInputValue(null);
        }
    
        setInputValue(_.get(e, 'target.value'));
    };

    useEffect(() => {
        setInputValue(_.get(object, fieldname) || '');
    }, [object])

    return (
        <div className={classes.root}>
            <TextField
                size="small" variant="outlined"
                value={inputValue}
                className={ clsx(classes.inputStyle) } 
                id={`input-textfield-${fieldname}`}
                onBlur={onBlurSetValue}
                onChange={onchangeAction}
                autoComplete='off'
                type="number"
            />
            {labelUnit && <Typography className={classes.unit}>{labelUnit}</Typography>}
            
        </div >
    );
}

InputMinutes.propTypes = {
    fieldname: PropTypes.string,
    object: PropTypes.object,
    setObject: PropTypes.func,
};

export default InputMinutes;
