import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme, withStyles } from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import * as _ from "lodash";
import axios from "axios";
import { useDebounce } from "use-debounce";

// import Radio
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import Checkbox from "@material-ui/core/Checkbox";

// import regex
import { validEcoeId, validEmail, validOffice } from "./regex";

import { user } from "../../constants/user";

// import helper
import { jsUcfirst } from "../../helper/index";

// import hooks
import {
  useDepartments,
  usePositions,
  useBranch,
  useRoles,
  usePhoneExist,
  useEmailExist,
  useCodeExist,
  useGetEmployeeType,
} from "../../pages/Employee/hooks";

import ButtonReturn from "../ButtonReturn";
import slugs from "../../resources/slugs";
import AutoCompleteBranch from "../AutoCompleteOffice";

import { validNumber, validPhone } from "./regex";
import InputDateAndTimePicker from "../InputDateAndTimePicker";
import AutoCompleteEmployeeScrollLoad from "../AutoCompleteEmployeeScrollLoad";
import { CircularProgress, Divider } from "@material-ui/core";
import { CustomRadio, CustomCheckbox, PrimaryButton } from "../UI";
import { ArrowBackOutlined, PersonAddOutlined } from '@material-ui/icons';
import TableComponentProjects from "../TableComponentProjects";
import { ConfigContext } from "../../context/ConfigContext";
import { StoreContext } from "../../resources/store";

const style = {
  borderRadius: 0,
};

const styles = (theme) => ({
  button: {
    ":disabled": {
      backgroundColor: "red",
    },
  },
});

const EmployeeCreateNew = ({ handleOpenCreateEmployee }) => {
  const history = useHistory();
  const { userInfo, accessPermissions } = useContext(StoreContext);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const { data: departments, loading: loadingDepartments } = useDepartments();
  const { data: positions, loading: loadingPositions } = usePositions();

  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: "20px 0",
    },
    formContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    },
    textLabelForm: {
      wordWrap: "break-word",
      width: 200,
      fontWeight: "bold",
      marginRight: 30,
    },
    input: {
      width: 250,
      backgroundColor: "white",
      marginLeft: "0",
      "& > .MuiFormHelperText-marginDense": {
        backgroundColor: "#FAFAFA",
        margin: 0,
      },
    },
    button: {
      color: "#ffffff",
      borderRadius: 0,
      textTransform: "uppercase",
      // ':disabled': {
      //     backgroundColor: 'gray'
      // }
    },
    buttonTitle: {
      color: "white",
      borderRadius: 0,
      fontSize: "16px",
      width: "26em",
      fontWeight: "bold",
    },
    buttonCreateConfirm: {
      padding: "6px 20px",
      marginRight: "30px",
    },
    err: {
      color: "#db2828",
      paddingTop: "5px",
    },
    wapperLoading: {
      width: '100%',
      height: 'calc(100vh - 200px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }));

  const theme = useTheme();

  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [position, setPosition] = useState(0);
  const [department, setDepartment] = useState([]);
  const [roles, setRoles] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState("");
  const [codeEmployee, setCodeEmployee] = useState("");
  const [personalid, setPersonalid] = useState("");
  const [birthday, setBirthday] = useState(null);
  const [idEmployeeManage, setIdEmployeeManage] = useState("");
  const [faceid, setFaceId] = useState([]);
  const [branch, setBranch] = useState(0);
  const [idTeam, setIdTeam] = useState("");
  const [idEcoeEmployee, setIdEcoeEmployee] = useState("");
  const [avaialbleRegex, setAvaialbleRegex] = useState({
    emailAvailable: { value: false, message: "" },
    idEmployeeManageAvailable: true,
    idOfficeAvailable: true,
    idEcoeIdAvailable: { value: false, message: "" },
    phoneAvailable: { value: false, message: "" },
    personalIdAvailable: false,
  });
  const [idTeamError, setIdTeamError] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [distributed, setDistributed] = useState("ALL");
  const [distributedList, setDistributedList] = useState([]);

  const [phoneValue] = useDebounce(phone, 300);
  const [emailValue] = useDebounce(email, 300);
  const [codeEmployeeValue] = useDebounce(idEcoeEmployee, 300);
  const { data: dataEmployeeTypes, loading: loadingEmployeeTypes } = useGetEmployeeType();

  useEffect(() => {
    const checkValidate = Object.values({
      name,
      email,
      personalid,
      idEmployeeManage,
      branch,
      idEcoeEmployee,
      phone,
    }).every((item) => Boolean(item));
    checkValidate &&
      _.get(avaialbleRegex, "emailAvailable.value") &&
      _.get(avaialbleRegex, "idEcoeIdAvailable.value") &&
      _.get(avaialbleRegex, "phoneAvailable.value") &&
      roles.length > 0 &&
      faceid.length > 0 &&
      department.length > 0
      ? setSubmitDisabled(false)
      : setSubmitDisabled(true);
  }, [
    name,
    email,
    phone,
    personalid,
    idEmployeeManage,
    branch,
    avaialbleRegex,
    faceid,
    roles,
    department,
    idEcoeEmployee,
  ]);
  const { data: dataBranch } = useBranch(branch);
  const { data: phoneExist, loading: phoneExistLoading } = usePhoneExist(
    phoneValue !== null && `%${phoneValue.slice(-9).trim()}`
  );

  const { data: emailExist, loading: emailExistLoading } =
    useEmailExist(emailValue);
  const { data: codeExist, loading: codeExistLoading } =
    useCodeExist(codeEmployeeValue);
  let { data: dataRoles } = useRoles();

  const configValue = useContext(ConfigContext);

  const validate = () => {
    if (!validOffice.test(idTeam) && _.isEmpty(idTeam) === false) {
      setIdTeamError(true);
    }

    // email
    if (!validEmail.test(email) && _.isEmpty(email) === false) {
      setAvaialbleRegex((prev) => ({
        ...prev,
        emailAvailable: { value: false, message: "Email không hợp lệ" },
      }));
    } else if (!_.isEmpty(emailExist) && !_.isEmpty(email)) {
      setAvaialbleRegex((prev) => ({
        ...prev,
        emailAvailable: { value: false, message: "Email  đã tồn tại" },
      }));
    } else {
      setAvaialbleRegex((prev) => ({
        ...prev,
        emailAvailable: { value: true, message: "" },
      }));
    }
    if (
      !validEcoeId.test(idEcoeEmployee) &&
      _.isEmpty(idEcoeEmployee) === false
    ) {
      setAvaialbleRegex((prev) => ({
        ...prev,
        idEcoeIdAvailable: {
          value: false,
          message: "Mã nhân viên không hợp lệ",
        },
      }));
    } else if (!_.isEmpty(codeExist) && !_.isEmpty(idEcoeEmployee)) {
      setAvaialbleRegex((prev) => ({
        ...prev,
        idEcoeIdAvailable: { value: false, message: "Mã nhân viên đã tồn tại" },
      }));
    } else {
      setAvaialbleRegex((prev) => ({
        ...prev,
        idEcoeIdAvailable: { value: true, message: "" },
      }));
    }

    // Phone

    if (!validPhone.test(phone) && _.isEmpty(phone) === false) {
      setAvaialbleRegex((prev) => {
        return {
          ...prev,
          phoneAvailable: {
            ...prev.phoneAvailable,
            value: false,
            message: "Số điện thoại không hợp lệ",
          },
        };
      });
    } else if (!_.isEmpty(phoneExist) && !_.isEmpty(phone)) {
      setAvaialbleRegex((prev) => {
        return {
          ...prev,
          phoneAvailable: {
            ...prev.phoneAvailable,
            value: false,
            message: "Số điện thoại đã tồn tại",
          },
        };
      });
    } else {
      setAvaialbleRegex((prev) => {
        return {
          ...prev,
          phoneAvailable: {
            ...prev.phoneAvailable,
            value: true,
            message: "",
          },
        };
      });
    }

    if (!validNumber.test(personalid) && _.isEmpty(personalid) === false) {
      setAvaialbleRegex((prev) => ({
        ...prev,
        personalIdAvailable: false,
      }));
    } else {
      setAvaialbleRegex((prev) => ({
        ...prev,
        personalIdAvailable: true,
      }));
    }

    if (dataBranch?.length !== 0) {
      setAvaialbleRegex((prev) => ({
        ...prev,
        idOfficeAvailable: true,
      }));
    } else {
      setAvaialbleRegex((prev) => ({
        ...prev,
        idOfficeAvailable: false,
      }));
    }
  };
  //   const handleValidate = () => {
  //     if (!phoneExistLoading) {
  //       validate();
  //     }
  //   };

  useEffect(() => {
    let checking = phoneExistLoading || emailExistLoading || codeExistLoading;
    if (!checking) {
      validate();
    }
  }, [
    idTeam,
    email,
    idEcoeEmployee,
    personalid,
    phoneExist,
    emailExist,
    codeExist,
  ]);

  const handleChangeDepartments = (e) => {
    if (e.target.checked) {
      setDepartment([...department, Number(e.target.value)]);
    } else {
      setDepartment(
        department.filter((item) => item !== Number(e.target.value))
      );
    }
  };

  const handleChangePosition = (e) => {
    setPosition(Number(e.target.value));
  };

  const handleChangeRole = (e) => {
    setRoles(e.target.value);
  };

  const handleDistributed = (e) => {
    setDistributed(_.get(e, 'target.value'))
  };

  const uploadHandler = async (e) => {
    setLoadingUpload(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("media_type_code", "AVATAR");
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        configValue.API_UPLOAD_FILE,
        formData,
        config
      );
      setLoadingUpload(false);
      setFaceId(data);
    } catch (error) {
      setLoadingUpload(false);
      console.log(error);
    }
  };

  useEffect(()=>{
    setDistributedList([]);
  },[distributed]);

  if(loadingEmployeeTypes || loadingDepartments || loadingPositions) {
    return (
        <div className={classes.wapperLoading}>
            <CircularProgress />
        </div>
    )
  }

  return (
    <Fragment>
      <Grid container spacing={1} alignItems="center" style={{ marginLeft: 0 }}>
        <Grid item className={classes.textLabelForm}>
          <p>
            Tên <span style={{ color: "red" }}>*</span>
          </p>
        </Grid>
        <Grid item>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={style}
            size="small"
            id="input-with-icon-employee"
            variant="outlined"
            placeholder="Họ tên"
            inputProps={{
              minLength: 2,
              maxLength: 100,
            }}
            className={classes.input}
            required
          />
        </Grid>
        <Grid
          item
          alignItems="center"
          style={{
            margin: matchesMD ? "40px 0px" : "0 30px 0 20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              wordWrap: "break-word",
              width: matchesMD ? 192 : matchesSM ? 80 : 110,
              fontWeight: "bold",
              marginRight: matchesMD ? 38 : 30,
            }}
          >
            Điện thoại <span style={{ color: "red" }}>*</span>
          </span>
          <TextField
            style={style}
            size="small"
            placeholder="Điện thoại liên hệ"
            id="input-with-icon-employee"
            variant="outlined"
            className={classes.input}
            helperText={
              !avaialbleRegex.phoneAvailable.value &&
              phone !== "" && (
                <span style={{ color: "red" }}>
                  {_.get(avaialbleRegex, "phoneAvailable.message")}
                </span>
              )
            }
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            inputProps={{
              maxLength: 12,
            }}
            required
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" style={{ marginLeft: 0 }}>
        <Grid item className={classes.textLabelForm}>
          <p variant="h3">
            Email <span style={{ color: "red" }}>*</span>{" "}
          </p>
        </Grid>
        <Grid item>
          <TextField
            value={email}
            helperText={
              !avaialbleRegex.emailAvailable.value &&
              email !== "" && (
                <span style={{ color: "red" }}>
                  {_.get(avaialbleRegex, "emailAvailable.message")}
                </span>
              )
            }
            onChange={(e) => {
              setEmail(e.target.value);
              if (validEmail.test(email)) {
                setAvaialbleRegex((prev) => ({
                  ...prev,
                  emailAvailable: true,
                }));
              }
            }}
            style={style}
            size="small"
            placeholder="Email"
            id="input-with-icon-employee"
            variant="outlined"
            className={classes.input}
            required
          />
        </Grid>
        <Grid
          item
          alignItems="center"
          style={{
            margin: matchesMD ? "40px 0px" : "0 30px 0 20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              wordWrap: "break-word",
              width: matchesMD ? 192 : matchesSM ? 80 : 110,
              fontWeight: "bold",
              marginRight: matchesMD ? 38 : 30,
            }}
          >
            CMND/CCCD <span style={{ color: "red" }}>*</span>
          </span>
          <TextField
            style={style}
            size="small"
            placeholder="CMND/CCCD"
            id="input-with-icon-employee"
            helperText={
              !avaialbleRegex.personalIdAvailable &&
              personalid !== "" && (
                <span style={{ color: "red" }}>
                  Số chứng minh nhân dân không đúng cú pháp
                </span>
              )
            }
            variant="outlined"
            className={classes.input}
            value={personalid}
            onChange={(e) => setPersonalid(e.target.value)}
            inputProps={{
              maxLength: 12,
            }}
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center" style={{ marginLeft: 0 }}>
        <Grid item className={classes.textLabelForm}>
          <p variant="h3">Sinh nhật</p>
        </Grid>
        <Grid item xs={3}>
          <InputDateAndTimePicker
            defaultValue={birthday}
            setDateTime={setBirthday}
            format="dd/MM/yyyy"
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        alignItems="center"
        className={classes.margin}
      >
        <Grid item className={classes.textLabelForm} style={{ width: "210px" }}>
          Upload hình ảnh Face ID <span style={{ color: "red" }}>*</span>
        </Grid>
        <Grid item>
          <input
            accept="image/*"
            id="face-id-customer"
            multiple
            type="file"
            hidden
            onChange={uploadHandler}
            style={{ cursor: "pointer" }}
          />
          <label htmlFor="face-id-customer">
            {faceid.length === 0 ? (
              <CloudUploadIcon style={{ fontSize: 110, cursor: "pointer" }} />
            ) : (
              <img
                style={{
                  cursor: "pointer",
                  borderRadius: "50%",
                  width: 110,
                  cursor: "pointer",
                }}
                src={configValue.MEDIA_DOMAIN + faceid[0].url}
                alt="Image Avatar"
              />
            )}
          </label>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        alignItems="center"
        className={classes.margin}
      >
        <Grid item className={classes.textLabelForm}>
          Mã nhân viên ECOE <span style={{ color: "red" }}>*</span>
        </Grid>
        <Grid item>
          <TextField
            value={idEcoeEmployee}
            helperText={
              !avaialbleRegex.idEcoeIdAvailable.value &&
              idEcoeEmployee !== "" && (
                <span style={{ color: "red" }}>
                  {_.get(avaialbleRegex, "idEcoeIdAvailable.message")}
                </span>
              )
            }
            onChange={(e) => {
              setIdEcoeEmployee(e.target.value);
              if (validEcoeId.test(idEcoeEmployee)) {
                setAvaialbleRegex((prev) => ({
                  ...prev,
                  idEcoeIdAvailable: true,
                }));
              }
            }}
            style={style}
            size="small"
            placeholder="Mã nhân viên ECOE"
            id="input-with-icon-employee"
            variant="outlined"
            className={classes.input}
            required
          />
        </Grid>
        <Grid
          item
          style={{
            margin: matchesMD ? "40px 0px" : "0 30px 0 20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              wordWrap: "break-word",
              width: matchesMD ? 192 : matchesSM ? 80 : 250,
              fontWeight: "bold",
              marginRight: matchesMD ? 38 : 30,
            }}
          >
            Mã NV ECOE quản lý trực tiếp <span style={{ color: "red" }}>*</span>
          </div>
          <div>
            <AutoCompleteEmployeeScrollLoad setObject={setIdEmployeeManage} />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        alignItems="center"
        className={classes.margin}
      >
        <Grid item className={classes.textLabelForm}>
          <p>
            Mã văn phòng làm việc <span style={{ color: "red" }}>*</span>
          </p>
        </Grid>
        <Grid item>
          <AutoCompleteBranch onChange={(id) => setBranch(id)} />
          {/* <TextField 
                        style={style} 
                        type="number"
                        size="small"
                        id="input-with-icon-employee"
                        variant="outlined"
                        helperText={!avaialbleRegex.idOfficeAvailable && branch !== "" && branch !== 0 && (<span style={{ color: 'red' }}>Văn phòng không tồn tại trong hệ thống</span>)}
                        value={branch}
                        onChange={(e) => {
                            setBranch(e.target.value);
                            // if(validOffice.test(branch)) {
                            //     setAvaialbleRegex((prev) => ({
                            //         ...prev,
                            //         idOfficeAvailable: true
                            //     }))
                            // } else {
                            //     setAvaialbleRegex((prev) => ({
                            //         ...prev,
                            //         idOfficeAvailable: false
                            //     }))
                            // }
                        }}

                        className={classes.input} 
                        required 
                    /> */}
        </Grid>
      </Grid>
      {/* <Grid container spacing={1} alignItems="center" className={classes.margin}>
                <Grid item className={classes.textLabelForm}>
                    <p>Mã nhóm: </p>
                </Grid>
                <Grid item>
                    <TextField 
                        style={style} 
                        size="small" 
                        id="input-with-icon-employee" 
                        variant="outlined" 
                        helperText={idTeamError && (<div className={classes.err}>Mã nhóm không hợp lệ</div>)}
                        className={classes.input} 
                        value={idTeam}
                        onChange={(e) => {
                            setIdTeam(e.target.value)
                            if(validOffice.test(idTeam)) {
                                setIdTeamError(false)
                            } else {
                                setIdTeamError(true)
                            }
                        }}
                        required 
                    />
                </Grid>
            </Grid> */}
      <Grid container direction="column" spacing={3}>
        <Grid
          item
          style={{
            display: "flex",
            alignItems: matchesMD ? undefined : "center",
            flexDirection: matchesMD ? "column" : "row",
          }}
        >
          <span className={classes.textLabelForm}>
            Loại tài khoản <span style={{ color: "red" }}>*</span>
          </span>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup row>
              <RadioGroup
                aria-label="roles"
                name="roles"
                value={roles}
                onChange={handleChangeRole}
                row
              >
                {_.map(dataRoles, (item, key) => (
                  <FormControlLabel
                    key={key}
                    style={{ width: "160px" }}
                    control={
                      <CustomRadio
                        size="small"
                        value={_.get(item, "code")}
                        required
                      />
                    }
                    label={
                      <span style={{ fontSize: "16px" }}>
                        {jsUcfirst(_.get(item, "name"))}
                      </span>
                    }
                  />
                ))}
              </RadioGroup>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            alignItems: matchesMD ? undefined : "center",
            flexDirection: matchesMD ? "column" : "row",
          }}
        >
          <span className={classes.textLabelForm}>
            Chức vụ <span style={{ color: "red" }}>*</span>
          </span>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup row>
              <RadioGroup
                aria-label="position"
                name="position"
                value={position}
                onChange={handleChangePosition}
                row
              >
                {_.map(positions, (item, key) => (
                  <FormControlLabel
                    key={_.get(item, "id")}
                    style={{ width: "160px" }}
                    control={
                      <CustomRadio size="small" value={_.get(item, "id")} required />
                    }
                    label={
                      <span style={{ fontSize: "16px" }}>
                        {_.get(item, "name")}
                      </span>
                    }
                  />
                ))}
              </RadioGroup>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            alignItems: matchesMD ? undefined : "center",
            flexDirection: matchesMD ? "column" : "row",
          }}
        >
          <span className={classes.textLabelForm}>
            Phòng ban <span style={{ color: "red" }}>*</span>
          </span>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup row>
              {_.map(departments, (item, key) => (
                <FormControlLabel
                  key={_.get(item, "id")}
                  style={{ width: "160px" }}
                  onChange={handleChangeDepartments}
                  control={
                    <CustomCheckbox size="small" value={_.get(item, "id")} required />
                  }
                  label={
                    <span style={{ fontSize: "16px" }}>
                      {_.get(item, "name")}
                    </span>
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid
          item
          style={{
            display: "flex",
            alignItems: matchesMD ? undefined : "center",
            flexDirection: matchesMD ? "column" : "row",
          }}
        >
          <span className={classes.textLabelForm}>
            Phân bố sản phẩm <span style={{ color: "red" }}>*</span>
          </span>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup row>
              <RadioGroup
                aria-label="distributed"
                name="distributed"
                value={distributed}
                onChange={handleDistributed}
                row
              >
                {_.map([{ id: 100, code: "ALL", name: "Tất cả" }, ...dataEmployeeTypes], (item, key) => (
                  <FormControlLabel
                    key={_.get(item, "id")}
                    style={{ width: "160px" }}
                    control={
                      <CustomRadio
                        size="small"
                        value={_.get(item, "code")}
                        required
                      />
                    }
                    label={
                      <span style={{ fontSize: "16px" }}>
                        {jsUcfirst(_.get(item, "name"))}
                      </span>
                    }
                  />
                ))}
              </RadioGroup>
            </FormGroup>
          </FormControl>
        </Grid>

        {
          distributed === "PRIMARY" && (
            <Grid
              item
              style={{
                display: "flex",
                alignItems: matchesMD ? undefined : "center",
                flexDirection: matchesMD ? "column" : "row",
              }}
            >
              <TableComponentProjects
                distributedList={distributedList}
                setDistributedList={setDistributedList}
              />
            </Grid>
          )
        }
      </Grid>

      <Divider style={{ margin: "30px auto" }} />

      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent={matchesMD ? undefined : "flex-end"}
      >
        <Grid item>
          <PrimaryButton
            labelButton="Quay lại"
            iconButton={<ArrowBackOutlined />}
            style='back'
            actionButton={() => history.push(slugs.employee)}
          />
        </Grid>

        <Grid item>
          <PrimaryButton
            labelButton="Tạo tài khoản"
            disabled={
              branch === 0 ||
              !avaialbleRegex.emailAvailable ||
              submitDisabled ||
              position <= 0 ||
              !avaialbleRegex.idEcoeIdAvailable ||
              loadingUpload ||
              !avaialbleRegex.phoneAvailable
            }
            actionButton={
              () => handleOpenCreateEmployee(
                birthday,
                position,
                department,
                email,
                roles,
                faceid,
                name,
                phone,
                personalid,
                idEmployeeManage,
                branch,
                idTeam,
                idEcoeEmployee,
                Number(_.get(userInfo, 'id')),
                distributed,
                distributedList
              )
            }
            iconButton={<PersonAddOutlined />}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default EmployeeCreateNew;
