import React, { Fragment, useState, useEffect } from 'react';
import { Grid, Typography, Card, CardContent, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import { Warning, Clear } from '@material-ui/icons';
import moment from "moment";
import { useDistricts, useProvinces, useStreets, useWards } from '../../graphql/schemas/masterdata/address';
import InputTextFieldSearch from '../../pages/Product/Secondary/components/InputTextFieldSearch';
import InputTextField from '../../pages/Product/Secondary/components/InputTextField';

const useStyles = makeStyles((theme) => ({
    titleContent: {
        fontWeight: 'bold',
        fontSize: '20px',
    },
    noteContent: {
        color: '#DA6744'
    },
    labelContent: {
        fontWeight: 'bold'
    },
    wapperInfo: {
        color: '#fff',
        borderRadius: '6px',

        position: 'relative',
        padding: '30px 8px !important',

        '&:after': {
            content: `'1'`,
            position: 'absolute',
            top: '0px',
        }
    },
    usingInfo: {
        background: '#7A43B7'
    },
    trafficInfo: {
        background: '#B79843'
    },

}))

const PlanningInformationEdit = ({ object, setObject }) => {
    const classes = useStyles();
    const [draftObject, setDraftObject] = useState({});
    const provinces = useProvinces();
    const districts = useDistricts({ provinceId: _.get(draftObject, 'province.id') });
    const wards = useWards({ districtId: _.get(draftObject, 'district.id') });

    const handleDateChange = (e) => {
        let tmp = { ...draftObject };
        tmp[e.target.name] = moment(e.target.value).utc().format("YYYY-MM-DD");
        setDraftObject(tmp);
    };

    const handleAdd = () => {
        let tmp = { ...draftObject };
        _.set(tmp, `points[${_.size(_.get(draftObject, 'points'))}]`, { x: null, y: null });
        setDraftObject(tmp);
    };

    const handleRemovePoint = (key) => {
        let tmp = { ...draftObject };
        _.remove(_.get(tmp, 'points'), (value, i) => i === key);
        setDraftObject(tmp);
    };

    useEffect(() => {
        let tmp = { ...draftObject };
        if (!_.get(draftObject, 'points')) {
            _.set(tmp, 'points[0]', { x: null, y: null });
            setDraftObject(tmp);
        }
    });

    useEffect(() => {
        let tmp = { ...object };
        _.set(tmp, 'product_plan', draftObject);
        setObject(tmp);
    }, [draftObject])

    useEffect(() => {
        if (_.get(object, 'product_plan')) {
            let tmp = { ..._.get(object, 'product_plan') };
            delete tmp.__typename;
            delete tmp.address;
            delete tmp.points;

            if (_.get(object, 'product_plan.address.province')) {
                _.set(tmp, 'province.id', _.get(object, 'product_plan.address.province.id'))
                _.set(tmp, 'province.name', _.get(object, 'product_plan.address.province.name'))
            }
            if (_.get(object, 'product_plan.address.district')) {
                _.set(tmp, 'district.id', _.get(object, 'product_plan.address.district.id'))
                _.set(tmp, 'district.name', _.get(object, 'product_plan.address.district.name'))
            }
            if (_.get(object, 'product_plan.address.ward')) {
                _.set(tmp, 'ward.id', _.get(object, 'product_plan.address.ward.id'))
                _.set(tmp, 'ward.name', _.get(object, 'product_plan.address.ward.name'))
            }

            if (_.get(object, 'product_plan.points')) {
                _.set(tmp, 'points', _.map(_.get(object, 'product_plan.points'), (item, key) => ({
                    x: item.x,
                    y: item.y
                }))
                )
            }

            setDraftObject(tmp);
        }

    }, [])

    return (
        <Grid container spacing={2} alignItems='stretch'>
            {/* <Grid item xs={8}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.titleContent}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '550px', background: '#ccc', borderRadius: '6px' }}>Bản đồ quy hoạch</div>
                    </Grid>
                </Grid>
            </Grid> */}

            <Grid item xs={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.titleContent}>Thông tin quy hoạch</Grid>

                    <Grid item xs={12} className={classes.noteContent}><Warning fontSize='small' />Thông tin chỉ mang tính chất tham khảo</Grid>

                    <Grid item xs={5}>Tỉnh/ Thành phố</Grid>
                    <Grid item xs={7} className={classes.labelContent}>
                        <InputTextFieldSearch
                            description='Chọn thành phố'
                            fieldname='province'
                            object={draftObject}
                            setObject={setDraftObject}
                            datalist={provinces}
                        />
                    </Grid>

                    <Grid item xs={5}>Quận/Huyện</Grid>
                    <Grid item xs={7} className={classes.labelContent}>
                        <InputTextFieldSearch
                            description='Chọn quận'
                            fieldname='district'
                            object={draftObject}
                            setObject={setDraftObject}
                            datalist={districts}
                        />
                    </Grid>

                    <Grid item xs={5}>Phường/Xã</Grid>
                    <Grid item xs={7} className={classes.labelContent}>
                        <InputTextFieldSearch
                            description='Chọn phường'
                            fieldname='ward'
                            object={draftObject}
                            setObject={setDraftObject}
                            datalist={wards}
                        />
                    </Grid>

                    <Grid item xs={5}>Số tờ</Grid>
                    <Grid item xs={7} className={classes.labelContent}>
                        <InputTextField
                            description='Số tờ trên sổ'
                            fieldname='map_sheet'
                            object={draftObject}
                            setObject={setDraftObject}
                            maxLength={50}
                            fullwidth
                        />
                    </Grid>

                    <Grid item xs={5}>Số thửa</Grid>
                    <Grid item xs={7} className={classes.labelContent}>
                        <InputTextField
                            description='Số thửa trên sổ'
                            fieldname='land_plot'
                            object={draftObject}
                            setObject={setDraftObject}
                            maxLength={50}
                            fullwidth
                        />
                    </Grid>

                    <Grid item xs={5}>Tổng diện tích</Grid>
                    <Grid item xs={7} className={classes.labelContent}>
                        <InputTextField
                            fieldname='area'
                            number
                            object={draftObject}
                            setObject={setDraftObject}
                            maxLength={50}
                            fullwidth
                        />
                    </Grid>

                    <Grid item xs={5}>Ngày cấp sổ</Grid>
                    <Grid item xs={7} className={classes.labelContent}>
                        <TextField
                            size="small"
                            variant="outlined"
                            name="issue_date"
                            value={_.get(draftObject, 'issue_date')}
                            type="date"
                            onChange={handleDateChange}
                        />
                    </Grid>

                    <Grid item xs={12}>Bảng tọa độ góc ranh</Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={2} className={classes.labelContent}>TT</Grid>
                            <Grid item xs={5} className={classes.labelContent}>X(m)</Grid>
                            <Grid item xs={5} className={classes.labelContent}>Y(m)</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            _.map(_.get(draftObject, 'points'), (point, key) => (
                                <Grid container spacing={2} key={key} justifyContent='center' alignItems='center'>
                                    <Grid item xs={1}>{key + 1}</Grid>
                                    <Grid item xs={5}>
                                        <InputTextField
                                            description='Tọa độ X'
                                            fieldname={`points[${key}].x`}
                                            object={draftObject}
                                            setObject={setDraftObject}
                                            modeEdit
                                            number
                                            fullwidth
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <InputTextField
                                            description='Tọa độ Y'
                                            fieldname={`points[${key}].y`}
                                            object={draftObject}
                                            setObject={setDraftObject}
                                            modeEdit
                                            number
                                            fullwidth
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Clear
                                            style={{ display: 'block', background: '#C7431B', color: '#fff', borderRadius: '50%', padding: '3px' }}
                                            onClick={() => handleRemovePoint(key)}
                                        />
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <div
                            style={{ display: 'inline-block', color: '#fff', fontWeight: 'bold', background: '#00837B', padding: '5px 30px', borderRadius: '6px', cursor: 'pointer' }}
                            onClick={handleAdd}
                        >Thêm</div>
                    </Grid>

                    <Grid item xs={5}>Đồ án</Grid>
                    <Grid item xs={7} className={classes.labelContent}>
                        <InputTextField
                            description='Nhập ghi chú'
                            fieldname='remark'
                            object={draftObject}
                            setObject={setDraftObject}
                            maxLength={500}
                            multiline
                            fullwidth
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PlanningInformationEdit;
