//content tab - ApprovalHistory
import {
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import * as _ from "lodash";
import React, { useState, Fragment } from "react";
import Moment from "react-moment";
import { useHistory, useParams } from "react-router-dom";
import ecoeIcon from "../../../assets/icons/logo-ecoe-no-char.svg";
import ButtonReturn from "../../../components/ButtonReturn";
import slugs from "../../../resources/slugs";
import { useGetBookingHistory } from "./hooks";

const TransactionHistory = (props) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles((theme) => ({
    circle: {
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      marginRight: "32px",
    },
    message: {
      fontSize: "18px",
      wordWrap: "break-word",
      marginLeft: 20,
    },
    buttonBack: {
      backgroundColor: "black",
      color: "#ffffff",
      borderRadius: 0,
      marginTop: "40px",
      padding: "5px 30px",
    },
    groupButtonFooter: {
      justifyContent: "flex-end",
      "& > button:not(:last-child)": {
        marginRight: "30px",
      },
    },
    marginDivider: {
      margin: "30px 0",
    },
  }));
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();
  let { id } = useParams();

  const { data, loading } = useGetBookingHistory({ id: id });

  return (
    <Grid container direction="column" className={classes.root}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {_.size(data) > 0 ? (
            _.map(data, (item, key) => {
              return (
                <Grid
                  container
                  direction="row"
                  style={{ marginTop: "60px", flexWrap: "wrap" }}
                  alignItems="center"
                  key={key}
                >
                  <Grid
                    item
                    className={classes.circle}
                    style={{ backgroundColor: "#dfe235" }}
                  ></Grid>
                  <Grid item>
                    <img src={ecoeIcon} alt="logo" width={40} />
                  </Grid>
                  <Grid
                    item
                    className={classes.message}
                    style={{ width: matchesSM ? "500px" : "700px" }}
                  >
                    {_.isNull(item.booking_info.employee_info) ? "Hệ thống tự động cập nhật" : (
                      <Fragment>
                        Nhân viên {item.booking_info.employee_info.full_name}
                        (mã NV: {item.booking_info.employee_info?.code}){" "}
                        {item.booking_status.name} {item.product_selected?.code} cho{" "}
                        {item.booking_info.customer_info.full_name} (mã KH:{" "}
                        {item.booking_info.customer_info.code})
                      </Fragment>
                    )}

                  </Grid>
                  <Grid item style={{ fontSize: "18px" }}>
                    <span>
                      <Moment format="DD/MM/YYYY hh:mm">
                        {_.get(item, "created")}
                      </Moment>
                    </span>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <Typography>Chưa có dữ liệu</Typography>
          )}

          <Divider className={classes.marginDivider} />

          <Grid container direction="row" className={classes.groupButtonFooter}>
            <ButtonReturn link={slugs.productPrimary} />
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default TransactionHistory;
