import * as _ from "lodash";
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button, CircularProgress, Backdrop, Modal, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, Typography, RadioGroup, FormControlLabel } from "@material-ui/core";
import { Description as DescriptionIcon } from "@material-ui/icons";
import moment from "moment";
import axios from "axios";
import swal from "sweetalert";
import { ConfigContext } from "../context/ConfigContext";
import InputDateAndTimePicker from "./InputDateAndTimePicker";
import { CustomRadio } from "./UI";

const setFilename = (code) => {
  let obj = _.find(
    [
      {
        code: "EXPORT_EMPLOYEE",
        name: "Danh sách nhân viên",
        params: "employee.xlsx",
      },
      {
        code: "EXPORT_CUSTOMER",
        name: "Danh sách khách hàng",
        params: "customer.xlsx",
      },
      {
        code: "EXPORT_BRANCH",
        name: "Danh sách văn phòng - chi nhánh",
        params: "branch.xlsx",
      },
      {
        code: "EXPORT_PRODUCT",
        name: "Danh sách sản phẩm",
        params: "product.xlsx",
      },
      {
        code: "EXPORT_GAME_JACK",
        name: "Danh sách Lịch sử giao dịch game quay",
        params: "game_jack.xlsx",
      },
      {
        code: "EXPORT_GAME",
        name: "Danh sách báo cáo game quay và xổ số",
        params: "game.xlsx",
      },
      {
        code: "EXPORT_PRODUCT_CONSULT",
        name: "Danh sách báo cáo tình trạng tư vấn",
        params: "product_consult.xlsx",
      },
      {
        code: "EXPORT_PRODUCT_CONSIGN",
        name: "Báo cáo tình trạng kiểm duyệt sản phẩm",
        params: "product_consign.xlsx",
      }
    ],
    ["code", code]
  );
  return {
    fileName: _.get(obj, "name", "Xuất báo cáo"),
    params: _.get(obj, "params"),
  };
};

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#00837B",
    textTransform: "unset",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#ffffff",
    borderRadius: "6px",
    padding: "4px 20px",
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#00837B",
      opacity: 0.7,
    },
    "&:disabled": {
      backgroundColor: "lightgray",
    },
  },
  styleLabel: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const ButtonExportExcel = ({
  buttonLabel = "Xuất dữ liệu",
  exportName = null,
  role = null,
  status = null,
  account_type = null,
  id_status = null,
  position = null,
  branch = null,
  active = null,
  workflow_type = null,
  price = null,
  province = null,
  updated_by_role = null,
  plan_status = null,
  gift_type = null,
  event = null,
  fromDate = null,
  toDate = null,
  code = null,
  eventBingo = null,
  incharge = null,
  disabled = false,
  withRange = false,
  multiReport = false
}) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [from, setFrom] = useState(moment().subtract(1, 'week'));
  const [to, setTo] = useState(new Date());
  const [typeReport, setTypeReport] = useState();
  const [loading, setLoading] = useState(false);

  const configValue = useContext(ConfigContext);

  useEffect(()=>{
    if(multiReport) setTypeReport(1);
  },[multiReport])

  
  const handleClick = async ({
    exportName: exportName,
    role,
    status,
    account_type,
    id_status,
    position,
    branch,
    active,
    workflow_type,
    price,
    province,
    updated_by_role,
    plan_status,
    gift_type,
    event,
    fromDate,
    toDate,
    code,
    eventBingo,
    incharge,
  }) => {

    setOpenModal(false);
    const strJson = {
      // exportName,
      role,
      status,
      account_type,
      id_status,
      position,
      branch,
      active,
      workflow_type,
      price,
      province,
      updated_by_role,
      plan_status,
      gift_type,
      event,
      fromDate,
      toDate,
      code,
      eventBingo,
      incharge,
    };
    const token = localStorage.getItem("access_token");

    const config = {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);

    // Exchange body object to params
    let queryParams = "";
    _.mapKeys(strJson, (value, key) => {
      if (value !== null) {
        // type Date
        if (key === "fromDate" || key === "toDate") {
          value = moment(value).format("YYYY-MM-DD HH:mm:ss%2B07");
        }
        // type primitive
        if (_.includes(["string", "number", "boolean"], typeof value)) {
          // branch = 0 (fetch all) => params.branch = ""
          if (value === 0) {
            queryParams += `&${key}=`;
          } else {
            queryParams += `&${key}=${_.toString(value)}`;
          }
        } else {
          // type reference
          let valueString = "";
          _.map(value, (item, index) => {
            if (index !== 0) {
              valueString += `,${item}`;
            } else {
              valueString += `${item}`;
            }
          });
          queryParams += `&${key}=${valueString}`;
        }
      }
    });

    // Using queryParams to call api export Data

    await axios
      .get(
        `${configValue.API_DOMAIN}/report/v1/export/${
          setFilename(exportName).params
        }${queryParams.substring(1) ? `?${queryParams.substring(1)}` : ""}`,
        config
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${setFilename(exportName).fileName}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        swal({
          title: "Có lỗi khi xuất dữ liệu",
          icon: "error",
        });
      });
  };

  if(withRange) {
    return (
      <div>
        {loading && (
          <Backdrop style={{ color: "#fff", zIndex: 200000 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Button
          className={classes.root}
          startIcon={<DescriptionIcon />}
          title="Xuất báo cáo"
          onClick={() => setOpenModal(true)}
          disabled={disabled || loading}
        >
          <span className={classes.styleLabel}>{buttonLabel}</span>
        </Button>
        <Dialog
          open={openModal}
          onClose={()=>setOpenModal(false)}
        >
          <DialogTitle style={{color: "#00837B"}}>Chọn khoảng thời gian</DialogTitle>
          <DialogContent>
            {multiReport && (
              <RadioGroup name="type report" value={typeReport} onChange={(e)=> setTypeReport((e?.target?.value)*1)} >
                <Grid container direction="row" spacing={2} style={{marginBottom: '24px'}}>
                  <Grid item>
                    <FormControlLabel value={1} control={<CustomRadio />} label="Tình trạng tư vấn khách hàng" />
                  </Grid>
                  <Grid item>
                    <FormControlLabel value={2} control={<CustomRadio />} label="Kiểm duyệt sản phẩm" />
                  </Grid>
                </Grid>
              </RadioGroup>
            )}

            <Grid container direction="row" spacing={2} style={{marginBottom: '24px'}}>
              <Grid item xs={6}>
                <Typography style={{color: "#777"}}>Thời gian bắt đầu</Typography>
                <InputDateAndTimePicker
                  defaultValue={from}
                  setDateTime={setFrom}
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid item xs={6}>
                <Typography style={{color: "#777"}}>Thời gian kết thúc</Typography>
                <InputDateAndTimePicker
                  defaultValue={to}
                  setDateTime={setTo}
                  format="dd/MM/yyyy"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{paddingRight: "24px"}}>
            <Button 
              onClick={()=>setOpenModal(false)} 
              style={{color: "#00837B", fontWeight: "bold", textTransform:"none"}}
            >
              Thoát
            </Button>
            <Button 
              onClick={
                ()=>handleClick({
                  exportName: typeReport === 2 ? "EXPORT_PRODUCT_CONSIGN" : exportName,
                  role,
                  status,
                  account_type,
                  id_status,
                  position,
                  branch,
                  active,
                  workflow_type,
                  price,
                  province,
                  updated_by_role,
                  plan_status,
                  gift_type,
                  event,
                  fromDate: moment(from).format("YYYY/MM/DD"),
                  toDate: moment(to).format("YYYY/MM/DD"),
                  code,
                  eventBingo,
                  incharge,
                })
              }
              style={{color: "#00837B", fontWeight: "bold", textTransform:"none"}}
              autoFocus
            >
              Tải file xuống
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return (
    <>
      {loading && (
        <Backdrop style={{ color: "#fff", zIndex: 200000 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Button
        className={classes.root}
        startIcon={<DescriptionIcon />}
        title="Xuất báo cáo"
        onClick={() =>
          handleClick({
            exportName,
            role,
            status,
            account_type,
            id_status,
            position,
            branch,
            active,
            workflow_type,
            price,
            province,
            updated_by_role,
            plan_status,
            gift_type,
            event,
            fromDate,
            toDate,
            code,
            eventBingo,
            incharge,
          })
        }
        disabled={disabled || loading}
      >
        <span className={classes.styleLabel}>{buttonLabel}</span>
      </Button>
    </>
  );
};

ButtonExportExcel.propTypes = {
  exportName: PropTypes.string,
  role: PropTypes.string,
  status: PropTypes.array,
  // account_type,
  // id_status,
  // position,
  // branch,
  // active,
  // workflow_type,
  // price,
  // province,
  // updated_by_role,
  // plan_status,
  // gift_type,
  // event,
  // fromDate,
  // toDate,
  // code,
  // eventBingo
};

export default ButtonExportExcel;
