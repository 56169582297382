import * as _ from 'lodash';
import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { FormControl } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%',
        padding: '10.5px 14px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        
        '&.disabled': {
            background: '#f7f7f7'
        },
    },
    inputStyle: {
        width: '100%',
        fontSize: '16px',
        border: '0',
        background: 'none',
        '&:focus-visible': {
            outline: '0',
        },
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    defaultPadding: {
        '&>div': {
            padding: '10.5px 14px',
        },
    },
    dropdownList: {
        '> *': {
            background: 'green',
        }
    }
}));

const InputCartSearch = (props) => {
    const { object, setObject, datalist, disabled } = props;
    const [inputValue, setInputValue] = useState(_.get(object, 'name'));
    const classes = useStyles();

    const onBlurSetValue = (event) => {
        const value = _.trim(_.get(event, 'target.value'));

        if ((_.isEmpty(value) && inputValue === undefined) || _.get(object, 'name') === value) return;
        
        const selectedCustomer = _.find(datalist, [ 'name', value ]);
        setObject(selectedCustomer);

        return;
    };

    const onKeyPress = (event) => {
        if (event.key === 'Enter' || event.charCode === 13) {
            onBlurSetValue(event);
        }
        return;
    }

    const onchangeAction = (e) => {
        const value = _.get(e, 'target.value');
        setInputValue(value);
    }

    return (
        <div style={{ display: 'flex' }}>
            <FormControl variant="outlined" className={clsx(classes.formControl, disabled && 'disabled')}>
                <input
                    className={classes.inputStyle}
                    placeholder={_.isEmpty(inputValue) }
                    value={inputValue}
                    list="list-carts-dropdown"
                    id={`input-textfield-carts`}
                    onBlur={(e) => onBlurSetValue(e)}
                    onKeyPress={onKeyPress}
                    onChange={onchangeAction}
                    autocomplete="off"
                    disabled={disabled}
                />
                <datalist id="list-carts-dropdown" className={classes.dropdownList}>
                    {_.map(datalist, (item, key) => {
                        return <option key={key} value={_.get(item,'name')} />
                    })}
                </datalist>
            </FormControl>
        </div>
    );
};

export default InputCartSearch;
