import { gql } from '@apollo/client';

export const ACTIVE_ACCOUNT = gql`
mutation ActiveAccountToggle($account: bigint!, $active: Boolean!) {
    result: activeAccountToggle(account: $account, active: $active) {
      id
      code
      full_name
      email
      active
    }
  }
  
`