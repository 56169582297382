import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import _ from 'lodash';
import { PROJECT_GET_FILTER } from '../../../../graphql/schemas/product/projectQueries';
import { useQuery } from '@apollo/client';



const AutoCompleteProject = ({ type, onChange, value }) => {
    let options = [];


      const [searchText, setSearchText] = useState('');

      const PER_PAGE = 10

      const variables = {
        skip:0,
        take:PER_PAGE,
        order_by: { created: "desc" },
        where: {
            deleted: {_eq: false},
            propertyTypeByPropertyType: {
                code: {
                _eq: "P"
                }
            },
            _or: [
            {title: {
                _ilike: `%${searchText}%`
            }},
            {code: {
                _ilike: "%%"
            }}
            ]
        }

      }

      const { loading, data } = useQuery(PROJECT_GET_FILTER, {
        variables
    });

    if(!_.isEmpty(_.get(data, 'projects'))) {
        options = _.map(_.get(data, 'projects'), (item, key) => {
            return {
                id: _.get(item, 'id'),
                title: _.get(item, 'title')
            }
        })
    }

    const defaultProps = {
        options,
        getOptionLabel: (option) => _.toString(option.title),
      };

    const handleSelect = (e, value) => {
        try {
            if (value) {
                onChange(value.id);
            } else {
                onChange(null);
            }
        } catch (error) {
            console.log("auto complete customer error", error);
        }
    }
    return (
        <Autocomplete 
            {...defaultProps}
            id="complete-select-project" 
            onChange={handleSelect}
            renderInput={(params) => (
                <TextField 
                    {...params} 
                    variant="outlined" 
                    size="small" 
                    style={{ backgroundColor: '#ffffff' }} 
                    value={value} 
                    onChange={(e) => setSearchText(e.target.value)} 
                />)
            }
        />
    )
}

export default AutoCompleteProject;
