import './App.css';

import _ from "lodash"
import { ApolloProvider } from "@apollo/client";
import { AppBar, Typography } from "@material-ui/core";
// import Drawer
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
// import Drawer from "./Drawer";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
  useTheme,
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React, { Fragment, useEffect, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import DrawerUI from "./components/drawer/DrawerV2";
import BranchDetail from "./pages/Employee/branch/BranchDetail";
import MenuNotify from "./components/Notify/MenuNotify";
import { client } from "./graphql/config";
import CreateCustomerPage from "./pages/Customer/CreateCustomerPage";
import CustomerReview from "./pages/Customer/CustomerReview";
import CustomerReviewEdit from "./pages/Customer/CustomerReviewEdit";
import DetailCustomer from "./pages/Customer/DetailCustomerPage";
import CustomerListPage from "./pages/Customer/ListCustomerPage";
import Dashboard from "./pages/dashboard";
import EmployeeCreate from "./pages/Employee/EmployeeCreate";
import EmployeeDetail from "./pages/Employee/EmployeeDetail";
import EmployeeList from "./pages/Employee/EmployeeList";
import NotifyPage from "./pages/notify/NotifyPage";
import OfficeCreate from "./pages/Office/OfficeCreate";
import Settings from "./pages/Other";
import SettingBanner from "./pages/Other/Banner";
import NotFoundPage from "./pages/Other/NotFoundPage";
import SettingNotification from "./pages/Other/Notification";
import PermissionIndex from "./pages/Permission/PermissionIndex";
import Product from "./pages/Product";
import BookingEdit from "./pages/Product/primary/booking/BookingEdit";
import CreateCartPrimary from "./pages/Product/primary/Cart/CreateCartPrimary";
import CreateBookingPage from "./pages/Product/primary/CreateBookingPage";
import PrimaryCartList from "./pages/Product/primary/PrimaryCartList";
import PrimaryProduct from "./pages/Product/primary/PrimaryList";
import PrimaryProductCreate from "./pages/Product/primary/PrimaryProductCreate";
import PrimaryProductEdit from "./pages/Product/primary/PrimaryProductEdit";
import ProductDetailPrimary from "./pages/Product/primary/ProductDetailPrimary";
import Secondary from "./pages/Product/Secondary";
import ProductDetail from "./pages/Product/Secondary/ProductDetail";
import ProductEdit from "./pages/Product/Secondary/ProductEdit";
import GameCreate from "./pages/Promotion/create/GameCreate";
import GameDetail from "./pages/Promotion/detail";
import PromotionIndex from "./pages/Promotion/index";
import LoginPage from "./pages/SignIn";
import SLUGS from "./resources/slugs";
import { StoreContext, StoreContextProvider } from "./resources/store";
import { requestFirebaseNotificationPermission } from "./firebase/config/firebase";
import slugs from "./resources/slugs";
import BranchList from "./pages/Employee/branch/BranchList";
import PromotionCreateVoucher from "./pages/Promotion/create/PromotionCreateVoucher";
import ItemVoucherDetail from "./pages/Promotion/detail/Voucher/ItemVoucherDetail";
import RewardDetail from "./pages/Promotion/detail/Voucher/RewardDetail";
import BingoResultPage from "./pages/Promotion/detail/BingoGame/BingoResultPage";
import JackVoucherDetail from "./pages/Promotion/detail/JackGame/VoucherDetail";
import BranchCreate from "./pages/Employee/branch/BranchCreate";
import BranchDetailEdit from "./pages/Employee/branch/BranchDetailEdit";
import BingoResultRound from "./pages/Promotion/detail/BingoGame/BingoResultRound";
import SetBingoResultRound from "./pages/Promotion/detail/BingoGame/SetBingoResultRound";
import AccountCreate from "./pages/Permission/account/AccountCreate";
import { ConfigProvier } from "./context/ConfigContext";
import { AccessPermissions, CheckAccessPermissions } from "./helper";
import AccessDenied from './pages/Permission/AccessDenied';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "30px",
    overflowY: "scroll",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#32373d",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#32373d",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    marginTop: "64px",
    width: "calc(100vw - 279px)",
    "&.fullwidth": {
      width: "100vw",
    },
    overflow: "hidden",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const App = () => {
  const [auth, setAuth] = useState(false);
  const [open, setOpen] = useState(true);

  const theme = useTheme();
  const baseTheme = createTheme();
  const classes = useStyles();

  const PrivateRoute = ({ component, path, exact, render = undefined, isRoute, functionCode, permissionCode, accessAllow = false }) => {
    const { userInfo, accessPermissions } = useContext(StoreContext);
    const condition = localStorage.getItem("access_token");
    const accessList = AccessPermissions(accessPermissions);

    const access = CheckAccessPermissions({
      role: _.get(userInfo, 'role'),
      isRoute: isRoute,
      arr: accessList,
      functionCode: functionCode,
      permissionCode: permissionCode,
      accessAllow
    });

    if (!condition) {
      return <Redirect to="/login" />
    }
    if (access === false && _.toUpper(functionCode) === "DASHBOARD") {
      return <Typography variant="h4" align='center'>Dashboard</Typography>
    }
    if (access === false) {
      return <AccessDenied />
    }
    return (
      <Route path={path} exact={exact} component={component} render={render} />
    );
  };

  useEffect(() => {
    if (window.localStorage.getItem("refresh_token")) {
      setAuth(true);
    }
    requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
        // eslint-disable-next-line no-console
        localStorage.setItem("notify_token", firebaseToken);
      })
      .catch((err) => {
        return err;
      });
  }, []);

  const handleDrawerOpen = () => { setOpen(true); };

  const handleDrawerClose = () => { setOpen(false); };

  return (
    <ApolloProvider client={client}>
      <ConfigProvier>
        <StoreContextProvider>
          <ThemeProvider theme={baseTheme}>
            <Router>
              <div style={{ display: "flex" }}>
                {auth && (
                  <Fragment>
                    <CssBaseline />
                    <AppBar
                      position="fixed"
                      className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                      })}
                    >
                      <Toolbar>
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          onClick={handleDrawerOpen}
                          edge="start"
                          className={clsx(
                            classes.menuButton,
                            open && classes.hide
                          )}
                        >
                          <MenuIcon />
                        </IconButton>
                        <MenuNotify />
                      </Toolbar>
                    </AppBar>
                    <Drawer
                      className={classes.drawer}
                      variant="persistent"
                      anchor="left"
                      open={open}
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                    >
                      <div className={classes.drawerHeader}>
                        <IconButton onClick={handleDrawerClose}>
                          {theme.direction === "ltr" ? (
                            <ChevronLeftIcon style={{ color: "#ffffff" }} />
                          ) : (
                            <ChevronRightIcon style={{ color: "#ffffff" }} />
                          )}
                        </IconButton>
                      </div>
                      <Divider />
                      <List>
                        <DrawerUI setAuth={setAuth} />
                      </List>
                    </Drawer>
                  </Fragment>
                )}

                <div
                  className={clsx(classes.content, !open && "fullwidth", {
                    [classes.contentShift]: open,
                  })}
                >
                  <Switch>
                    <Route
                      exact
                      path={SLUGS.signIn}
                      render={() => <LoginPage setAuth={setAuth} />}
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.dashboard}
                      component={Dashboard}
                      isRoute
                      functionCode='Dashboard'
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.product}
                      component={Product}
                      isRoute
                      functionCode='Product'
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.productPrimary}
                      component={PrimaryProduct}
                      isRoute
                      functionCode='Primary'
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.productDetailsPrimary}
                      component={ProductDetailPrimary}
                      functionCode='Primary'
                      permissionCode="View"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.productCartPrimary}
                      component={PrimaryCartList}
                      functionCode='Primary'
                      permissionCode="View"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.productCartPrimaryCreate}
                      component={CreateCartPrimary}
                      functionCode='Primary'
                      permissionCode="Create"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.productCartPrimaryUpdate}
                      component={CreateCartPrimary}
                      functionCode='Primary'
                      permissionCode="Update"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.productEditPrimary}
                      component={PrimaryProductEdit}
                      functionCode='Primary'
                      permissionCode="Update"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.productCreatePrimary}
                      component={PrimaryProductCreate}
                      functionCode='Primary'
                      permissionCode="Create"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.productPrimaryCreateBooking}
                      component={CreateBookingPage}
                      functionCode='Primary'
                      permissionCode="Create"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.productPrimaryDetailBooking}
                      component={BookingEdit}
                      functionCode='Primary'
                      permissionCode="Update"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.productSecondary}
                      render={() => <Secondary openMenu={open} />}
                      isRoute
                      functionCode='Secondary'
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.productDetailSecondary}
                      component={ProductDetail}
                      functionCode='Secondary'
                      permissionCode="View"
                    />
                    {/* <PrivateRoute exact path={SLUGS.productCreateSecondary} component={ProductCreate} /> */}
                    <PrivateRoute
                      exact
                      path={SLUGS.productEditSecondary}
                      component={ProductEdit}
                      functionCode='Secondary'
                      permissionCode="Update"
                    />

                    <PrivateRoute
                      exact
                      path={SLUGS.customer}
                      component={CustomerListPage}
                      isRoute
                      functionCode="Customer"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.customerCreate}
                      component={CreateCustomerPage}
                      functionCode="Customer"
                      permissionCode="Create"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.customerDetail}
                      component={DetailCustomer}
                      functionCode="Customer"
                      permissionCode="View"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.customerReview}
                      component={CustomerReview}
                      functionCode="Customer"
                      permissionCode="View"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.customerReviewEdit}
                      component={CustomerReviewEdit}
                      functionCode="Customer"
                      permissionCode="Update"
                    />

                    <PrivateRoute
                      exact
                      path={slugs.branchList}
                      component={BranchList}
                      isRoute
                      functionCode="Branch"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.branchDetail}
                      component={BranchDetail}
                      functionCode="Branch"
                      permissionCode="View"
                    />
                    <PrivateRoute
                      exact
                      path={slugs.branchCreate}
                      component={BranchCreate}
                      functionCode="Branch"
                      permissionCode="Create"
                    />

                    <PrivateRoute exact path={slugs.branchDetailEdit} component={BranchDetailEdit} functionCode="Branch" permissionCode="Update" />

                    <PrivateRoute
                      exact
                      path={SLUGS.employee}
                      component={EmployeeList}
                      isRoute
                      functionCode="Employee"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.employeeCreate}
                      component={EmployeeCreate}
                      functionCode="Employee"
                      permissionCode="Create"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.employeePriority}
                      render={() => (
                        <h1 style={{ textAlign: "center" }}>
                          Content Page<p>quản lý lượt ưu tiên!!!!!</p>
                        </h1>
                      )}
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.employeeLocate}
                      render={() => (
                        <h1 style={{ textAlign: "center" }}>
                          Content Page<p>quản lý vị trí nhân viên!!!!!</p>
                        </h1>
                      )}
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.employeeDetail}
                      component={EmployeeDetail}
                      functionCode="Employee"
                      permissionCode="View"
                    />

                    <PrivateRoute
                      exact
                      path={SLUGS.officeCreate}
                      component={OfficeCreate}
                    />

                    <PrivateRoute
                      exact
                      path={SLUGS.setting}
                      component={Settings}
                      isRoute
                      functionCode="Other"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.settingNotification}
                      component={SettingNotification}
                      functionCode="Notification"
                      permissionCode="Create"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.settingBanner}
                      component={SettingBanner}
                      functionCode="Banner"
                      permissionCode="View"
                    />

                    <PrivateRoute
                      exact
                      path={SLUGS.notify}
                      component={NotifyPage}
                      functionCode="Notification"
                      permissionCode="View"
                    />

                    <PrivateRoute
                      exact
                      path={SLUGS.promotion}
                      component={PromotionIndex}
                      isRoute
                      functionCode="Promotion"
                    />

                    <PrivateRoute
                      exact
                      path={SLUGS.promotionCreate}
                      component={GameCreate}
                      functionCode="Promotion"
                      permissionCode="Create"
                    />

                    <PrivateRoute
                      exact
                      path={SLUGS.promotionCreateVoucher}
                      component={PromotionCreateVoucher}
                      functionCode="Promotion"
                      permissionCode="Create"
                    />

                    {/* <PrivateRoute
                        exact
                        path={SLUGS.promotionDetailVoucher}
                        component={PromotionVoucherDetail}
                      /> */}
                    <PrivateRoute
                      exact
                      path={SLUGS.promotionDetailVoucher}
                      component={ItemVoucherDetail}
                      functionCode="Promotion"
                      permissionCode="View"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.promotionJackGameVoucherDetail}
                      component={JackVoucherDetail}
                      functionCode="Promotion"
                      permissionCode="View"
                    />

                    <PrivateRoute
                      exact
                      path={SLUGS.promotionDetailRewardVoucher}
                      component={RewardDetail}
                      functionCode="Promotion"
                      permissionCode="View"
                    />

                    <PrivateRoute
                      exact
                      path={SLUGS.promotionDetail}
                      component={GameDetail}
                      functionCode="Promotion"
                      permissionCode="View"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.bingoResultDetail}
                      component={BingoResultPage}
                      functionCode="Promotion"
                      permissionCode="View"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.bingoRoundResultDetail}
                      component={BingoResultRound}
                      functionCode="Promotion"
                      permissionCode="View"
                    />
                    <PrivateRoute
                      exact
                      path={SLUGS.setBingoRoundResultCode}
                      component={SetBingoResultRound}
                      functionCode="Promotion"
                      permissionCode="Update"
                    />


                    {/* <PrivateRoute exact path={SLUGS.branchDetailEdit} component={} /> */}

                    <PrivateRoute
                      exact
                      path={SLUGS.permission}
                      component={PermissionIndex}
                    />

                    <PrivateRoute
                      exact
                      path={SLUGS.permissionsAccountCreate}
                      component={AccountCreate}
                    />

                    <Route
                      exact
                      path={SLUGS.pageNotFound}
                      component={NotFoundPage}
                    />

                    <Redirect from="*" to="/not-found-404" />
                  </Switch>
                </div>
              </div>
            </Router>
          </ThemeProvider>

        </StoreContextProvider>
      </ConfigProvier>
    </ApolloProvider>
  );
};
export default App;
