import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_PROJECT_ITEM } from '../../../../graphql/schemas/booking/bookingQueries';

const AutoCompleteProjectItem = ({ type, onChange, value, cartId, setEcoeCode, disabled, defaultValue }) => {

    let options = []

    const [searchText, setSearchText] = useState('');

    const { data } = useQuery(GET_PROJECT_ITEM, {
        variables: {
            skip: 0,
            take: 1000,
            cartId,
            search: `%${searchText}%`
        },
        skip: !cartId,
        fetchPolicy: 'cache-and-network'
    })



    if (data && !_.isEmpty(data, 'result')) {
        options = _.map(_.get(data, 'result', (item, key) => {
            return {
                id: _.get(item, 'id'),
                code: _.get(item, 'code'),
                ecoe_code: _.get(item, 'ecoe_code')
            }
        }))
    }

    const handleSelect = (e, value) => {
        try {
            if (_.isNull(value)) return setEcoeCode(null)
            if (value) {
                onChange(value.id);
                setEcoeCode(value.ecoe_code)
            } else {
                onChange(null);
            }

        } catch (error) {
            console.log("auto complete customer error", error);
        }
    }

    return (
        <Autocomplete
            id="debug"
            options={options}
            onChange={handleSelect}
            disabled={disabled}
            getOptionLabel={(option) => _.toString(option.code)}
            defaultValue={{ code: defaultValue }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{ backgroundColor: '#ffffff' }}
                    style={{ width: 200 }}
                />
            )
            }
        />
    )
}

export default AutoCompleteProjectItem;
