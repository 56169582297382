import * as _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Button } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
	root: {
		backgroundColor: "#00837b",
		textTransform: "unset",
		color: "#ffffff",
		borderRadius: "6px",
		padding: "6px 24px",
		width: props => props.fullwidth ? '100%' : 'unset',
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "#00837b",
			opacity: 0.7,
		},
		'&.cancel-style': {
			backgroundColor: "#e2e1e1",
			color: '#0a2240',
		},
		'&.back-style': {
			backgroundColor: "#e27f35",
		},
		'& > span': {
			fontSize: '18px',
			fontWeight: 'bold',
		},
		'&:disabled': {
			backgroundColor: "lightgray",
		},
	},
	styleLabel: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	}
}));


const PrimaryButton = ({
	labelButton = 'Xác nhận',
	titleButton = null,
	iconButton = null,
	fullwidth = false,
	disabled = false,
	textInline = false,
	style = 'normal',
	actionButton,
}) => {
	const classes = useStyles({ fullwidth });

	const handleClick = () => {
		if (_.isFunction(actionButton)) {
			actionButton();
			return;
		};
		return;
	}

	return (
		<Button
			className={clsx(classes.root, `${style}-style`, textInline && classes.styleLabel)}
			startIcon={iconButton ? iconButton : undefined}
			title={titleButton}
			onClick={handleClick}
			disabled={disabled}
		>
			{labelButton}
		</Button>
	)
};

PrimaryButton.propTypes = {
	style: PropTypes.oneOf(['normal', 'back', 'cancel']),
};

export default PrimaryButton;
