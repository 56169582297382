import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash'
import { MenuItem, FormControl, Select, InputLabel, Typography } from '@material-ui/core';
import clsx from 'clsx';

const SelectComponent = ({ 
    title, 
    handleChange, 
    value, 
    defaultTitle, 
    defaultValue, 
    listData = [], 
    fieldValue, 
    fieldTitle, 
    width = 120, 
    multiple = false, 
    widthTitle = undefined,
    flex = undefined,
    direction = undefined,
    color = undefined,
    space = undefined }) => {
    const useStyles = makeStyles(theme => ({
        formControl: {
            minWidth: width,
           
        },
        selectForm: {
            backgroundColor: color
        }
    }));

    const classes = useStyles();
    return (
        <div style={{ display: flex, flexDirection: direction,  margin: `0px ${space}px` }}>
            <Typography style={{ width: widthTitle }}>{title}</Typography>
            <FormControl size="small" variant="outlined" className={classes.formControl}>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={value}
                    onChange={handleChange}
                    multiple={multiple}
                    className={clsx(classes.selectForm)}
                    defaultValue={value}
                >
                    <MenuItem value={defaultValue}>{defaultTitle}</MenuItem>
                    {_.map(listData, (item, key) => {
                        return (
                            <MenuItem key={_.get(item, 'id') || key} value={_.get(item, fieldValue)}>{_.get(item, fieldTitle)}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </div>

    )
}

export default SelectComponent
