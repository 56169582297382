import _ from "lodash";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Add, AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import moment from "moment";
import axios from "axios";
import { useGetGiftTypes } from "../hooks";
import ColorPicker from "../components/ColorPicker";
import { ConfigContext } from "../../../context/ConfigContext";

const useStyles = makeStyles((theme) => ({
  styleOneDivider: {
    margin: "30px 0",
  },

  textLabelForm: {
    fontWeight: "bold",
    paddingRight: "20px",
  },

  itemRow: {
    marginBottom: "15px",
    paddingBottom: "15px",
  },

  imgPicker: {
    display: "flex !important",
    background: "#F5F5F5",
    width: "100px",
    height: "100px",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    fontWeight: "bold",
    cursor: "pointer",
    border: "1px solid #B9B9B9",
    borderRadius: "6px",
  },
}));

const EventGiftsVouchers = ({ eventGiftsVouchers, setEventGiftsVouchers, children }) => {
  const [inputValues, setInputValues] = useState(null);

  // const [inputVoucherValues, setInputVoucherValues] = useState(
  //   eventGiftsVouchers.vouchers
  // );

  const configValue = useContext(ConfigContext);

  const [checkedGiftVoucher, setCheckedGiftVoucher] = useState({
    checkedVoucher: true,
    checkedGift: true,
  });
  const { data: listGiftType } = useGetGiftTypes();
  const classes = useStyles();

  const handleChangeCheckbox = (event) => {
    setCheckedGiftVoucher({
      ...checkedGiftVoucher,
      [event.target.name]: event.target.checked,
    });
  };

  //TODO: Add / remove gift field
  const addEventGiftsField = () => {
    setInputValues({
      vouchers: [...inputValues.vouchers],
      eventGifts: [
        ...inputValues.eventGifts,
        {
          name: "",
          title: "",
          description: "",
          image: "",
          start: "",
          end: "",
          expire_day: null,
          max_redeem: null,
          redeem_ecoin: null,
          prefix: null,
          postfix: null,
          type: 1,
        },
      ],
    });
  };

  const handleRemoveEventGiftsField = (index) => {
    if (inputValues.eventGifts.length <= 1) return;
    const fields = [...inputValues.eventGifts];
    fields.splice(index, 1);
    setInputValues({ vouchers: [...inputValues.vouchers], eventGifts: fields });
  };

  //TODO: Add / remove voucher fields
  const addEventVoucherField = () => {
    setInputValues({
      vouchers: [
        ...inputValues?.vouchers,
        {
          name: "",
          title: "",
          start: "",
          end: "",
          prefix: null,
          postfix: null,
          max_redeem: null,
          reward: null,
          image: null,
          expire_day: null,
          description: "",
          type: 2,
        },
      ],
      eventGifts: [...inputValues?.eventGifts],
    });
  };

  const handleRemoveEventVoucherGiftsField = (index) => {
    if (inputValues?.vouchers.length <= 1) return;
    const fields = [...inputValues?.vouchers];
    fields.splice(index, 1);
    setInputValues({
      vouchers: fields,
      eventGifts: [...inputValues?.eventGifts],
    });
  };
  //TODO: Set default value
  useEffect(() => {
    let defaultValue = _.cloneDeep(eventGiftsVouchers);
    setInputValues(defaultValue);
  }, []);

  //TODO: handle gift input
  const handleChangeEventGiftsInput = async (index, event) => {
    const values = [...inputValues?.eventGifts];

    values[index]["isUpdate"] = true;

    if (
      event.target.name === "max_redeem" ||
      event.target.name === "redeem_ecoin" ||
      event.target.name === "expire_day"
    ) {
      values[index][event.target.name] = parseInt(event.target.value);
    } else if (event.target.name === "image") {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("media_type_code", "ICON");
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const { data } = await axios.post(
          configValue.API_UPLOAD_FILE,
          formData,
          config
        );
        values[index][event.target.name] = data[0];
      } catch (error) {
        console.log(error);
      }
    } else values[index][event.target.name] = event.target.value;

    values[index]["title"] = `Quà ${index + 1}`;

    setInputValues({ eventGifts: values, vouchers: [...inputValues?.vouchers] });
  };

  //TODO: Handle voucher input
  const handleChangeVoucherInput = async (index, event) => {
    const values = [...inputValues.vouchers];
    values[index]["isUpdate"] = true;

    if (
      event.target.name === "max_redeem" ||
      event.target.name === "reward" ||
      event.target.name === "expire_day"
    ) {
      values[index][event.target.name] = parseInt(event.target.value);
    } else if (event.target.name === "image") {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("media_type_code", "ICON");
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const { data } = await axios.post(
          configValue.API_UPLOAD_FILE,
          formData,
          config
        );
        values[index][event.target.name] = data[0];
      } catch (error) {
        console.log(error);
      }
    } else values[index][event.target.name] = event.target.value;

    values[index]["title"] = `Voucher ${index + 1}`;

    setInputValues({
      eventGifts: [...inputValues?.eventGifts],
      vouchers: values,
    });
  };

  const onBlurInput = () => {
    if (checkedGiftVoucher?.checkedGift && checkedGiftVoucher?.checkedVoucher) {
      setEventGiftsVouchers(inputValues);
    } else if (checkedGiftVoucher?.checkedVoucher) {
      setEventGiftsVouchers({ vouchers: inputValues?.vouchers, eventGifts: [] });
    } else if (checkedGiftVoucher?.checkedGift) {
      setEventGiftsVouchers({
        eventGifts: inputValues?.eventGifts,
        vouchers: [],
      });
    } else setEventGiftsVouchers({});
  };

  return (
    <div>
      <Fragment>
        {children}
      </Fragment>

      <Grid container item alignItems="center">
        <Grid item className={classes.textLabelForm} xs={12}>
          <p style={{ fontSize: "24px", marginTop: 0 }}>Giải thưởng</p>
        </Grid>
        <Grid container item alignItems="center">
          <Grid item className={classes.textLabelForm} xs={2}>
            <p>Loại giải thưởng</p>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={checkedGiftVoucher.checkedVoucher}
                    onChange={handleChangeCheckbox}
                    name="checkedVoucher"
                  />
                }
                label="Voucher"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={checkedGiftVoucher.checkedGift}
                    onChange={handleChangeCheckbox}
                    name="checkedGift"
                  />
                }
                label="Quà"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
      {/* TODO: Voucher */}
      {_.get(checkedGiftVoucher, "checkedVoucher") && (
        <Fragment>
          <Divider className={classes.styleOneDivider} />
          {_.map(_.get(inputValues, 'vouchers'), (item, key) => (
            <Grid
              item
              container
              key={key}
              alignItems="center"
              spacing={2}
              className={classes.itemRow}
            >
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <p className={classes.textLabelForm}>{`Voucher ${key + 1
                    }`}</p>
                  {inputValues.vouchers.length > 1 && (
                    <IconButton
                      onClick={() => handleRemoveEventVoucherGiftsField(key)}
                    >
                      <RemoveCircleOutline style={{ fill: "red" }} />
                    </IconButton>
                  )}
                </Grid>
              </Grid>

              <Grid item container xs={4} alignItems="center">
                <Grid item className={classes.textLabelForm} md={4}>
                  <p>
                    Tên voucher<span style={{ color: "red" }}>*</span>
                  </p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={classes.input}
                    required
                    value={item?.name}
                    name="name"
                    onChange={(e) => handleChangeVoucherInput(key, e)}
                    onBlur={onBlurInput}
                  />
                </Grid>
              </Grid>

              <Grid item container md={4} alignItems="center">
                <Grid item className={classes.textLabelForm} xs={4}>
                  <p>
                    Số ngày hết hạn<span style={{ color: "red" }}>*</span>
                  </p>
                </Grid>
                <Grid item xs={8} style={{ position: "relative" }}>
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    variant="outlined"
                    className={classes.input}
                    value={item.expire_day}
                    name="expire_day"
                    onChange={(e) => handleChangeVoucherInput(key, e)}
                    onBlur={onBlurInput}
                  />
                  <p style={{ color: "#aaa", margin: 0, position: "absolute", right: 45, top: 10 }}>Ngày</p>
                </Grid>
              </Grid>

              <Grid item container md={4} alignItems="center">
                <Grid item xs={4}>
                  <p className={classes.textLabelForm}>
                    Giá trị (VND)<span style={{ color: "red" }}>*</span>
                  </p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    variant="outlined"
                    className={classes.input}
                    required
                    value={item.reward}
                    name="reward"
                    onChange={(e) => handleChangeVoucherInput(key, e)}
                    onBlur={onBlurInput}
                  />
                </Grid>
              </Grid>

              <Grid item container md={4} alignItems="center">
                <Grid item className={classes.textLabelForm} xs={4}>
                  <p>Tiền tố</p>
                </Grid>
                <Grid item xs={8} style={{ position: "relative" }}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={classes.input}
                    value={item.prefix}
                    name="prefix"
                    onChange={(e) => handleChangeVoucherInput(key, e)}
                    onBlur={onBlurInput}
                  />
                  <p style={{ position: "absolute", top: "30px" }}>
                    Ví dụ: EKM21001-XXXXX
                  </p>
                </Grid>
              </Grid>

              <Grid item container md={4} alignItems="center">
                <Grid item className={classes.textLabelForm} xs={4}>
                  <p>Hậu tố</p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={classes.input}
                    value={item.postfix}
                    name="postfix"
                    onChange={(e) => handleChangeVoucherInput(key, e)}
                    onBlur={onBlurInput}
                  />
                </Grid>
              </Grid>

              <Grid item container md={4} alignItems="center">
                <Grid item className={classes.textLabelForm} xs={4}>
                  <p>
                    Số lượng<span style={{ color: "red" }}>*</span>
                  </p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    variant="outlined"
                    className={classes.input}
                    value={item.max_redeem}
                    name="max_redeem"
                    onChange={(e) => handleChangeVoucherInput(key, e)}
                    onBlur={onBlurInput}
                  />
                </Grid>
              </Grid>

              {/* <Grid item container md={12} alignItems="center">
                      <Grid item className={classes.textLabelForm} xs={4}>
                        <p>SP áp dụng<span style={{ color: "red" }}>*</span></p>
                      </Grid>
                      <Grid item xs={8}>
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox color="primary" disabled checked={true} name="1" />}
                            label="Sản phẩm sơ cấp"
                          />
                          <FormControlLabel
                            control={<Checkbox color="primary" disabled checked={true} name="2" />}
                            label="Sản phẩm thứ cấp"
                          />
                          <FormControlLabel
                            control={<Checkbox color="primary" disabled checked={true} name="3" />}
                            label="Sản phẩm đặc biệt"
                          />
                        </FormGroup>
                      </Grid>                      
                    </Grid> */}

              <Grid item container xs={4} alignItems="center">
                <Grid item xs={4} className={classes.textLabelForm}>
                  <p>
                    Thời gian bắt đầu<span style={{ color: "red" }}>*</span>
                  </p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={classes.input}
                    defaultValue={moment(_.get(item, "start")).format("YYYY-MM-DDTHH:mm")}
                    name="start"
                    type="datetime-local"
                    onChange={(e) => handleChangeVoucherInput(key, e)}
                    onBlur={onBlurInput}
                  />
                </Grid>
              </Grid>

              <Grid item container alignItems="center" xs={4}>
                <Grid item xs={4} className={classes.textLabelForm}>
                  <p>
                    Thời gian kết thúc<span style={{ color: "red" }}>*</span>
                  </p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={classes.input}
                    defaultValue={moment(_.get(item, "end")).format("YYYY-MM-DDTHH:mm")}
                    name="end"
                    type="datetime-local"
                    onChange={(e) => handleChangeVoucherInput(key, e)}
                    onBlur={onBlurInput}
                  />
                </Grid>
              </Grid>

              <Grid item container xs={4} alignItems="center">
                <Grid item xs={4} className={classes.textLabelForm}>
                  <p>
                    Mô tả<span style={{ color: "red" }}>*</span>
                  </p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    multiline
                    rows={6}
                    size="small"
                    variant="outlined"
                    className={classes.input}
                    value={item.description}
                    name="description"
                    onChange={(e) => handleChangeVoucherInput(key, e)}
                    onBlur={onBlurInput}
                  />
                </Grid>
              </Grid>

              {/* <Grid item container>
                <Grid item xs={2} className={classes.textLabelForm}>
                  <p>
                    Sản phẩm áp dụng<span style={{ color: "red" }}>*</span>
                  </p>
                </Grid>
                <Grid item>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={(e) =>
                            handleChangeVoucherInput(key, e)
                          }
                          name="checkedVoucher"
                        />
                      }
                      label="Sản phẩm sơ cấp"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={(e) =>
                            handleChangeVoucherInput(key, e)
                          }
                          name="checkedGift"
                        />
                      }
                      label="Sản phẩm thứ cấp"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          value={1}
                          onChange={(e) =>
                            handleChangeVoucherInput(key, e)
                          }
                          name="checkedGift"
                        />
                      }
                      label="Sản phẩm Sản phẩm đặc biệt"
                    />
                  </FormGroup>
                </Grid>
              </Grid> */}

              <Grid item container alignItems="center">
                <Grid item container xs={4}>
                  <Grid item xs={4} className={classes.textLabelForm}>
                    <p>
                      Hình ảnh<span style={{ color: "red" }}>*</span>
                    </p>
                  </Grid>
                  <Grid item xs={8}>
                    {_.isEmpty(_.get(item, "image.url")) ? (
                      <>
                        <input
                          id={`image-voucher-${key}`}
                          hidden
                          type="file"
                          name="image"
                          accept="image/*"
                          multiple
                          className={classes.inputStyle}
                          onChange={(e) => handleChangeVoucherInput(key, e)}
                        />
                        <label
                          htmlFor={`image-voucher-${key}`}
                          className={classes.imgPicker}
                        >
                          Thêm ảnh <Add />
                        </label>{" "}
                      </>
                    ) : (
                      <>
                        <input
                          id={`image-voucher-${key}`}
                          hidden
                          type="file"
                          name="image"
                          accept="image/*"
                          multiple
                          className={classes.inputStyle}
                          onChange={(e) => handleChangeVoucherInput(key, e)}
                        />
                        <label
                          htmlFor={`image-voucher-${key}`}
                          className={classes.imgPicker}
                        >
                          <img
                            style={{ height: 100, width: 100, background: _.get(item, "background_color") || 'none' }}
                            alt="icon"
                            src={
                              configValue.MEDIA_DOMAIN + item?.image.url
                            }
                          />
                        </label>
                      </>
                    )}
                  </Grid>
                </Grid>

                <Grid item container xs={8}>
                  <Grid item xs={12}>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={3} className={classes.textLabelForm}>
                        <p>Cài đặt màu nền:</p>
                      </Grid>
                      <Grid item xs={3}>
                        <ColorPicker
                          inputValues={inputValues}
                          setInputValues={setInputValues}
                          name="background_color"
                          index={key}
                          isBackgroundColor={true}
                          isVouchers={true}
                          onBlurInput={onBlurInput}
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.textLabelForm}>
                        <p>Cài đặt màu chữ:</p>
                      </Grid>
                      <Grid item xs={3}>
                        <ColorPicker
                          inputValues={inputValues}
                          setInputValues={setInputValues}
                          name="title_color"
                          index={key}
                          isBackgroundColor={false}
                          isVouchers={true}
                          onBlurInput={onBlurInput}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          ))}

          <Grid item>
            <IconButton onClick={() => addEventVoucherField()}>
              <AddCircleOutline style={{ fill: "blue" }} />
              <span style={{ paddingLeft: "10px" }}>Thêm mới Voucher</span>
            </IconButton>
          </Grid>
        </Fragment>
      )}

      {_.get(checkedGiftVoucher, "checkedGift") && (
        <Fragment>
          <Divider className={classes.styleOneDivider} />
          {_.map(_.get(inputValues, 'eventGifts'), (item, key) => (
            <Grid
              item
              container
              key={key}
              alignItems="center"
              spacing={2}
              className={classes.itemRow}
            >
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <p className={classes.textLabelForm}>{`Quà ${key + 1}`}</p>
                  {inputValues.eventGifts.length > 1 && (
                    <IconButton
                      onClick={() => handleRemoveEventGiftsField(key)}
                    >
                      <RemoveCircleOutline style={{ fill: "red" }} />
                    </IconButton>
                  )}
                </Grid>
              </Grid>

              <Grid item container md={4} alignItems="center">
                <Grid item xs={4}>
                  <p className={classes.textLabelForm}>
                    Tên quà
                    <span style={{ color: "red" }}>*</span>
                  </p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={classes.input}
                    required
                    value={item?.name}
                    name="name"
                    onChange={(e) => handleChangeEventGiftsInput(key, e)}
                    onBlur={onBlurInput}
                  />
                </Grid>
              </Grid>

              <Grid item container md={4} alignItems="center">
                <Grid item className={classes.textLabelForm} xs={4}>
                  <p>
                    Số ngày hết hạn<span style={{ color: "red" }}>*</span>
                  </p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    variant="outlined"
                    className={classes.input}
                    value={item?.expire_day}
                    name="expire_day"
                    onChange={(e) => handleChangeEventGiftsInput(key, e)}
                    onBlur={onBlurInput}
                  />
                </Grid>
              </Grid>

              <Grid item container xs={4} alignItems="center">
                <Grid item xs={4} className={classes.textLabelForm}>
                  <p>
                    Thời gian bắt đầu<span style={{ color: "red" }}>*</span>
                  </p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={classes.input}
                    defaultValue={moment(_.get(item, "start")).format("YYYY-MM-DDTHH:mm")}
                    name="start"
                    type="datetime-local"
                    onChange={(e) => handleChangeEventGiftsInput(key, e)}
                    onBlur={onBlurInput}
                  />
                </Grid>
              </Grid>
              <Grid item container alignItems="center" xs={4}>
                <Grid item xs={4} className={classes.textLabelForm}>
                  <p>
                    Thời gian kết thúc<span style={{ color: "red" }}>*</span>
                  </p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={classes.input}
                    defaultValue={moment(_.get(item, "end")).format("YYYY-MM-DDTHH:mm")}
                    name="end"
                    type="datetime-local"
                    onChange={(e) => handleChangeEventGiftsInput(key, e)}
                    onBlur={onBlurInput}
                  />
                </Grid>
              </Grid>

              <Grid item container md={4} alignItems="center">
                <Grid item className={classes.textLabelForm} xs={4}>
                  <p>
                    Số coin quy định<span style={{ color: "red" }}>*</span>
                  </p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    size="small"
                    style={{ width: "100" }}
                    variant="outlined"
                    className={classes.input}
                    value={item?.redeem_ecoin}
                    onChange={(e) => handleChangeEventGiftsInput(key, e)}
                    type="number"
                    name="redeem_ecoin"
                    onBlur={onBlurInput}
                  />
                </Grid>
              </Grid>

              <Grid item container md={4} alignItems="center">
                <Grid item className={classes.textLabelForm} xs={4}>
                  <p>
                    Số lượng quà<span style={{ color: "red" }}>*</span>
                  </p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    variant="outlined"
                    className={classes.input}
                    value={item?.max_redeem}
                    name="max_redeem"
                    onChange={(e) => handleChangeEventGiftsInput(key, e)}
                    onBlur={onBlurInput}
                    required
                  />
                </Grid>
              </Grid>



              <Grid item container alignItems="flex-start" spacing={2}>
                <Grid item container xs={4}>
                  <Grid item xs={4} className={classes.textLabelForm}>
                    <p>
                      Hình ảnh<span style={{ color: "red" }}>*</span>
                    </p>
                  </Grid>
                  <Grid item xs={8}>
                    {_.isEmpty(_.get(item, "image.url")) ? (
                      <>
                        <input
                          id={`image-${key}`}
                          hidden
                          type="file"
                          name="image"
                          accept="image/*"
                          multiple
                          className={classes.inputStyle}
                          onChange={(e) => handleChangeEventGiftsInput(key, e)}
                        />
                        <label
                          htmlFor={`image-${key}`}
                          className={classes.imgPicker}
                        >
                          Thêm ảnh <Add />
                        </label>{" "}
                      </>
                    ) : (
                      <>
                        <input
                          id={`image-${key}`}
                          hidden
                          type="file"
                          name="image"
                          accept="image/*"
                          multiple
                          className={classes.inputStyle}
                          onChange={(e) => handleChangeEventGiftsInput(key, e)}
                        />
                        <label
                          htmlFor={`image-${key}`}
                          className={classes.imgPicker}
                        >
                          <img
                            style={{ height: 100, width: 100, background: _.get(item, "background_color") || 'none' }}
                            alt="icon"
                            src={
                              configValue.MEDIA_DOMAIN + item?.image.url
                            }
                          />
                        </label>
                      </>
                    )}
                  </Grid>
                </Grid>

                <Grid item container xs={4}>

                  <Grid container alignItems="center">
                    <Grid item xs={6} className={classes.textLabelForm}>
                      <p>Cài đặt màu nền:</p>
                    </Grid>
                    <Grid item xs={6}>
                      <ColorPicker
                        inputValues={inputValues}
                        setInputValues={setInputValues}
                        index={key}
                        isBackgroundColor={true}
                        onBlurInput={onBlurInput}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.textLabelForm}>
                      <p>Cài đặt màu chữ:</p>
                    </Grid>
                    <Grid item xs={6}>
                      <ColorPicker
                        inputValues={inputValues}
                        setInputValues={setInputValues}
                        index={key}
                        isBackgroundColor={false}
                        onBlurInput={onBlurInput}
                      />
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item container xs={4} alignItems="flex-start">
                  <Grid xs={4} item className={classes.textLabelForm}>
                    <p>
                      Mô tả<span style={{ color: "red" }}>*</span>
                    </p>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      multiline
                      rows={6}
                      size="small"
                      variant="outlined"
                      name="description"
                      className={classes.input}
                      value={item?.description}
                      onChange={(e) => handleChangeEventGiftsInput(key, e)}
                      onBlur={onBlurInput}
                    />
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          ))}
          <Grid item>
            <IconButton onClick={() => addEventGiftsField()}>
              <AddCircleOutline style={{ fill: "blue" }} />{" "}
              <span style={{ paddingLeft: "10px" }}>Thêm Quà mới</span>
            </IconButton>
          </Grid>
          <Divider className={classes.styleOneDivider} />
        </Fragment>
      )}
    </div>
  );
};

export default EventGiftsVouchers;
