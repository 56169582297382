import React, { Fragment } from 'react'
import { TextField, Typography } from '@material-ui/core';

const InputComponent = ({ 
        title, 
        handleChange = undefined, 
        value, 
        disabled = false, 
        style = undefined, 
        variant = "outlined", 
        size = "small", 
        widthTitle = undefined,
        multiline = false }) => {
    return (
        <Fragment>
            <Typography style={{ width: widthTitle }}>{title}</Typography>
            <TextField 
                variant={variant}
                size={size}
                onChange={handleChange}
                style={style}
                value={value}  
                disabled={disabled}
                multiline={multiline}
                rows={multiline ? 4 : undefined}
            />
        </Fragment>
       
    )
}

export default InputComponent
