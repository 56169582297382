import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import TableComponentBranch from "../TableComponents/TableComponentBranch";
import _ from "lodash";
import moment from "moment";
import { useBlockBranch } from "../../../graphql/schemas/masterdata/branches";
import ModalConfirm from "../../ModalConfirm";
import { useGetEmployeeByAccount } from "../../../pages/Employee/hooks";
import swal from "sweetalert";
import { StoreContext } from "../../../resources/store";
import { UPDATE_BRANCH } from "../../../constants/defined";
import { checkedPermission } from "../../../helper";

const BranchDetailComponent = ({ data, branchId, refetchData }) => {
  const useStyles = makeStyles((theme) => ({
    fieldTitle: {
      fontWeight: "bold",
      width: 240,
    },
  }));

  const { userInfo, accessObjectPermissions } = useContext(StoreContext);

  const history = useHistory();

  const [confirmOpen, setOpenConfirm] = useState(false);

  const classes = useStyles();

  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [reason, setReason] = useState(null);

  const { data: createdByEmployee } = useGetEmployeeByAccount(
    _.get(data, "created_by")
  );

  const headMenu = [
    {
      id: "province",
      numeric: false,
      disablePadding: false,
      label: "Tỉnh/Thành Phố",
    },
    {
      id: "district",
      numeric: false,
      disablePadding: false,
      label: "Quận/Huyện",
    },
    { id: "ward", numeric: false, disablePadding: false, label: "Phường/Xã" },
  ];

  const blockedBranch = useBlockBranch();

  const returnedDesc = () => {
    if (!_.get(data, "active")) {
      return (
        <Fragment>
          <Typography style={{ marginBottom: 12 }}>
            Mở khóa văn phòng {_.get(data, "name", "--")}?
          </Typography>
          <Typography>Vui lòng nhập lý do bên dưới.</Typography>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Typography style={{ margin: "12px 0" }}>
          Khóa văn phòng {_.get(data, "name", "--")}?
        </Typography>
        <Typography>Vui lòng nhập lý do bên dưới.</Typography>
      </Fragment>
    );
  };

  const dataBranch = _.map(
    _.get(data, "branch_incharges"),
    (element, index) => {
      return {
        province: _.get(element, "provinceByProvince.name"),
        district: _.get(element, "districtByDistrict.name"),
        ward: _.get(element, "wardByWard.name"),
      };
    }
  );

  const count = _.get(data, "branch_incharges_aggregate.aggregate.count");

  let addressFull = _.join(
    _.pull(
      [
        _.get(data, "address.short", "") +
        " " +
        _.get(data, "address.street.name", ""),
        _.get(data, "address.ward.name", ""),
        _.get(data, "address.district.name", ""),
        _.get(data, "address.province.name", ""),
      ],
      "",
      null
    ),
    ", "
  );

  const submitBlocked = () => {
    const temp = _.cloneDeep(_.get(data, "active"));

    if (_.size(_.trim(reason)) > 0) {
      blockedBranch({
        variables: { id: branchId, active: !temp, reason: _.trim(reason) },
      }).then(() => {
        setOpenConfirm(false);
      });
    } else {
      swal(
        "Lý do không hợp lệ",
        "Hãy nhập lại lý do và thử lại!",
        "warning"
      ).then(() => {
        return;
      });
    }
  };

  useEffect(() => {
    setReason(null);
  }, [confirmOpen]);

  return (
    <Fragment>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={3}>
          <Typography className={classes.fieldTitle}>Tên văn phòng</Typography>
          <Typography>{_.get(data, "name") || "-"}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.fieldTitle}>Mã văn phòng: </Typography>
          <Typography>{_.get(data, "code") || "-"}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.fieldTitle}>Số điện thoại: </Typography>
          <Typography>{_.get(data, "phone") || "-"}</Typography>
        </Grid>
        <Grid item xs={3}>
          {_.get(data, "active") ? (
            <Button
              onClick={() => setOpenConfirm(true)}
              variant="contained"
              style={{
                color: "#fff",
                backgroundColor: checkedPermission(userInfo, accessObjectPermissions, UPDATE_BRANCH) && "#DA6744",
                margin: "0 10px",
              }}
              disabled={!checkedPermission(userInfo, accessObjectPermissions, UPDATE_BRANCH)}
            >
              Khóa VP
            </Button>
          ) : (
            <Button
              onClick={() => setOpenConfirm(true)}
              variant="contained"
              style={{
                color: "#fff",
                backgroundColor: checkedPermission(userInfo, accessObjectPermissions, UPDATE_BRANCH) && "#DA6744",
                margin: "0 10px",
              }}
              disabled={!checkedPermission(userInfo, accessObjectPermissions, UPDATE_BRANCH)}
            >
              Mở khóa VP
            </Button>
          )}
          <Button
            variant="contained"
            disabled={!checkedPermission(userInfo, accessObjectPermissions, UPDATE_BRANCH)}
            onClick={() => history.push(`/branches/edit/${branchId}`)}
            style={{
              backgroundColor: checkedPermission(userInfo, accessObjectPermissions, UPDATE_BRANCH) && "#00837B",
              color: "#fff",
              margin: "0 10px",
            }}
          >
            Cập nhật
          </Button>
        </Grid>
      </Grid>
      <div style={{ height: 30, width: "100%" }}></div>
      <Grid container direction="row">
        <Grid item xs={3}>
          <Typography className={classes.fieldTitle}>Địa chỉ:</Typography>
          <Typography style={{ width: 260 }}>{addressFull || "-"}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.fieldTitle}>
            Tọa độ latitude:
          </Typography>{" "}
          <Typography>{_.get(data, "latitude") || "-"}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.fieldTitle}>
            Tọa độ longitude:
          </Typography>{" "}
          <Typography>{_.get(data, "longitude") || "-"}</Typography>
        </Grid>
      </Grid>
      <div style={{ height: 30, width: "100%" }}></div>
      <Grid container direction="row">
        <Grid item xs={3}>
          <Typography className={classes.fieldTitle}>Phân loại:</Typography>
          <Typography style={{ width: 260 }}>
            {_.get(data, "head") ? "Hội sở" : "Chi nhánh"}
            {_.get(data, "is_physical") ? " - Địa chỉ vật lý" : ""}
          </Typography>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      <div style={{ height: 30, width: "100%" }}></div>
      <Grid container direction="row">
        <Grid item xs={3}>
          <Typography className={classes.fieldTitle}>
            Mã văn phòng quản lý:{" "}
          </Typography>
          {_.get(data, "branch.code") ? (
            <Link to={`/branches/detail/${_.get(data, "branch.id")}`}>
              {_.get(data, "branch.code")}
            </Link>
          ) : (
            <Typography className={classes.fieldTitle}>-</Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.fieldTitle}>
            Mã nhân viên quản lý:
          </Typography>
          {_.get(data, "manager.info.id") ? (
            <Link to={`/employee/detail/${_.get(data, "manager.info.id")}`}>
              {_.get(data, "manager.info.code")}
            </Link>
          ) : (
            <Typography className={classes.fieldTitle}>-</Typography>
          )}
        </Grid>
      </Grid>

      <div style={{ height: 30, width: "100%" }}></div>
      <Grid container direction="row">
        <Grid item xs={3}>
          <Typography className={classes.fieldTitle}>
            Số lượng sản phẩm quản lý:{" "}
          </Typography>
          <Typography>
            {_.get(data, "product_count.aggregate.count") < 10 &&
              _.get(data, "product_count.aggregate.count") > 0
              ? `0${_.get(data, "product_count.aggregate.count")}`
              : _.get(data, "product_count.aggregate.count") || 0}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.fieldTitle}>
            Số lượng tài khoản Admin:{" "}
          </Typography>
          <Typography>
            {_.get(data, "admin_count.aggregate.count") < 10 &&
              _.get(data, "admin_count.aggregate.count") > 0
              ? `0${_.get(data, "admin_count.aggregate.count")}`
              : _.get(data, "admin_count.aggregate.count") || 0}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.fieldTitle}>
            Số lượng tài khoản NVKD:{" "}
          </Typography>
          <Typography>
            {_.get(data, "emp_count.aggregate.count") < 10 &&
              _.get(data, "emp_count.aggregate.count") > 0
              ? `0${_.get(data, "emp_count.aggregate.count")}`
              : _.get(data, "emp_count.aggregate.count") || 0}
          </Typography>
        </Grid>
      </Grid>
      <div style={{ height: 30, width: "100%" }}></div>
      <Grid container direction="row">
        <Grid item xs={3}>
          <Typography className={classes.fieldTitle}>Ngày tạo: </Typography>
          <Typography>
            {moment(_.get(data, "created")).format("DD/MM/YYYY")}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.fieldTitle}>Người tạo: </Typography>
          {_.get(createdByEmployee, "info.code") ? (
            <Link
              to={`/employee/detail/${_.get(createdByEmployee, "info.id")}`}
            >
              {_.get(createdByEmployee, "info.code")}
            </Link>
          ) : (
            <Typography className={classes.fieldTitle}>-</Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.fieldTitle}>Tình trạng: </Typography>
          <Typography>
            {_.get(data, "active") ? "Đang hoạt động" : "Đã khóa" || "-"}
          </Typography>
        </Grid>
      </Grid>
      <div style={{ height: 30, width: "100%" }}></div>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Typography className={classes.fieldTitle}>
            Khu vực quản lý:{" "}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableComponentBranch
            setSkip={(skip) => setSkip(skip)}
            page={page}
            setPage={setPage}
            count={count}
            headMenu={headMenu}
            data={_.slice(dataBranch, skip, 10 * page)}
          />
        </Grid>
      </Grid>
      <ModalConfirm
        handleClose={() => setOpenConfirm(false)}
        open={confirmOpen}
        submit={submitBlocked}
        description={returnedDesc()}
        title={`Bạn có muốn ${_.get(data, "active") ? "khóa" : "mở khóa"
          }  văn phòng?`}
        isNote={true}
        onChangeNote={(e) => setReason(e.target.value)}
        note={reason}
      />
    </Fragment>
  );
};

export default BranchDetailComponent;
