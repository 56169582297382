import React, { Fragment } from 'react'
import { Grid, IconButton, Typography } from '@material-ui/core';
import SelectComponent from './SelectComponent';
import moment from 'moment';
import InputComponent from './InputComponent';
import ItemListReview from './ItemListReview';
import { handleFlex } from '../../../helper/index';
import { makeStyles } from '@material-ui/styles';

const ReviewList = ({ branch, setBranch, pointReview, setPointReview, setEmployee, setFlag }) => {
    const useStyles = makeStyles(theme => ({
        filterContainer: {
            marginTop: 20
        }
    }));

    const classes = useStyles();


    return (
        <Fragment>
            <Grid container direction="row">
                <Grid item>
                    <SelectComponent 
                        title="Mã văn phòng" 
                        value={branch} 
                        width={500}
                        defaultTitle="Tất cả"
                        handleChange={(e) => setBranch(e.target.value)} 
                    />
                </Grid>
                <Grid item>
                    <SelectComponent 
                        title="Nhân viên" 
                        value={branch} 
                        width={500}
                        defaultTitle="Tất cả"
                        handleChange={(e) => setEmployee(e.target.value)}
                    />
                </Grid>
                <Grid item style={handleFlex('column')}>
                   <InputComponent 
                        title="Thời gian"
                        value={`${moment(new Date()).format('DD/MM/YYYY')} đến ${moment(new Date()).format('DD/MM/YYYY')}`} 
                        disabled={true} 
                        style={{ margin: 8, width: 260 }}
                    />
                </Grid>
                <Grid item>
                    <SelectComponent 
                        title="Điểm đánh giá" 
                        value={pointReview} 
                        width={260}
                        handleChange={(e) => setPointReview(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container direction="column">
                <ItemListReview setFlag={setFlag} circleColor="red" gridItem={true} />
                <ItemListReview setFlag={setFlag} circleColor="red" gridItem={true} />
                <ItemListReview setFlag={setFlag} circleColor="red" gridItem={true} />
                <ItemListReview setFlag={setFlag} circleColor="red" gridItem={true} />
                <ItemListReview setFlag={setFlag} circleColor="red" gridItem={true} />
            </Grid>
        
        </Fragment>
       
    )
}

export default ReviewList
