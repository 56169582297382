import gql from "graphql-tag";

export const GET_PROJECT_CARTS = gql`
    query GetProjectCarts($skip: Int!, $take: Int!, $order_by: [project_cart_order_by!] = {}, $project: uuid!) {
        result: project_cart(offset: $skip, limit: $take, order_by: $order_by, where: {project: {_eq: $project}}) {
            id
            name
            assembly_date
            sale_date
            project_cart_status{
                id
                code
                name
            }
        }
    }
`;

export const GET_DETAIL_CART_FOR_EDIT = gql`
query GetCartSetting($id: Int!) {
  project_cart: project_cart_by_pk(id: $id) {
    id
    name
    booking_timeline
    assembly_date
    assembly_rate
    priority_max
    assembly_timeline
    sale_date
    priority_timeline
    sale_max_blocked
    sale_blocked_timeline
    general_max_blocked
    general_blocked_timeline
    deposit_timeline
    assembly_start_countdown
    sale_start_countdown
  }
}

`;

export const SUBSCRIPTION_PROJECT_CART_DETAIL = gql`
subscription GetProjectCartDetail($condition: project_cart_bool_exp!) {
    result: project_cart(where: $condition) {
      project_items(where: {deleted: {_eq: false}}) {
        id
        index
        block_code
        apartment_no
        code
        orientation
        floor
        bed_room
        bath_room
        carpet_area
        built_up_area
        commission
        price
        price_include_vat
        status: project_item_status {
          id
          code
          name
        }
      }
    }
  }
`;

export const SEARCH_PROJECT_CARTS = gql`
  query SearchProjectCarts($skip: Int!, $take: Int!, $where: project_cart_bool_exp!, $order_by: [project_cart_order_by!] = {}) {
    result: project_cart(offset: $skip, limit: $take, order_by: $order_by, where: $where) {
      id
      name
      assembly_date
      sale_date
      status
      project_cart_status {
        id
        code
        name
      }
    }
  }
`;

export const GET_NEXT_CART_STATUS = gql`
  query GetNextCartStatus($id: Int!) {
    result: getNextCartStatus(id: $id) {
      status_id: id
      code
      name
      status_priority
    }
  }
`;
