import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress,
  Paper
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import DescriptionIcon from "@material-ui/icons/Description";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import swal from "sweetalert";
import InputDateAndTimePicker from "../../../components/InputDateAndTimePicker";
import TableComponent from "../../../components/TableComponent";
import SelectComponent from "../../Customer/components/SelectComponent";
import {
  useGetGiftTypes,
  useGetListJackVoucherTransaction,
  useGetListTransaction,
  useGetListTransactionBingo,
  useGetListTransactionEventVoucher,
  useGetListTransactionJackGift,
} from "../hooks";
import { checkedPermission, formatPhone } from "../../../helper";
import SearchText from "../../Product/Secondary/components/SearchText";
import ButtonExportExcel from "../../../components/ButtonExportExcel";
import { EXPORT_PROMOTION } from "../../../constants/defined";
import { StoreContext } from "../../../resources/store";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 30px",
  },
  textLabelForm: {
    fontWeight: "bold",
  },
  textTitle: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "40px",
    "&> p": {
      fontSize: 32,
      fontWeight: "bold",
      padding: 12,
      color: "#FFF",
      background: "#26893d",
      borderRadius: "12px",
    },
  },
  wapperLoading: {
    width: '100%',
    height: 'calc(100vh - 113px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rootSearch: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 400,
    borderRadius: 20,
  },
}));

const headMenu = [
  {
    id: "voucher_code_customer",
    numeric: false,
    disablePadding: false,
    label: "Mã khách hàng",
  },
  {
    id: "voucher_name",
    numeric: false,
    disablePadding: false,
    label: "Tên voucher",
  },
  {
    id: "voucher_id",
    numeric: false,
    disablePadding: false,
    label: "ID voucher",
  },
  {
    id: "voucher_amount",
    numeric: false,
    disablePadding: false,
    label: "Giá trị (VND)",
  },
  {
    id: "voucher_created",
    numeric: false,
    disablePadding: false,
    label: "Thời gian nhận voucher",
  },
  {
    id: "voucher_deadline_day",
    numeric: false,
    disablePadding: false,
    label: "Hạn sử dụng",
  },
  {
    id: "voucher_status",
    numeric: false,
    disablePadding: false,
    label: "Trạng thái",
  },
  {
    id: "voucher_exchange_date",
    numeric: false,
    disablePadding: false,
    label: "Ngày giao dịch",
  },
  {
    id: "voucher_exchange_code",
    numeric: false,
    disablePadding: false,
    label: "Mã KH giao dịch",
  },
  {
    id: "voucher_exchange_product",
    numeric: false,
    disablePadding: false,
    label: "SP áp dụng",
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Thời gian giao dịch",
  },
  {
    id: "gift_code",
    numeric: false,
    disablePadding: false,
    label: "ID giải thưởng",
  },
  {
    id: "code",
    numeric: false,
    disablePadding: false,
    label: "Mã khách hàng",
  },
  {
    id: "full_name",
    numeric: false,
    disablePadding: false,
    label: "Tên khách hàng",
  },
  {
    id: "ticket",
    numeric: false,
    disablePadding: false,
    label: "Mã vé đã mua",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Loại giải thưởng",
  },
  {
    id: "redeem_ecoin",
    numeric: false,
    disablePadding: false,
    label: "Số Ecoin quy đổi",
  },
  {
    id: "redeem_exchange",
    numeric: false,
    disablePadding: false,
    label: "Số giải thưởng quy đổi",
  },
  {
    id: "used_coin",
    numeric: false,
    disablePadding: false,
    label: "Số Ecoin đã đổi",
  },
];

const voucherHeadMenu = [
  {
    id: "voucher_code_customer",
    numeric: false,
    disablePadding: false,
    label: "Mã khách hàng",
  },
  {
    id: "voucher_name_customer",
    numeric: false,
    disablePadding: false,
    label: "Tên khách hàng",
  },
  {
    id: "voucher_personalid_customer",
    numeric: false,
    disablePadding: false,
    label: "CMND khách hàng",
  },
  {
    id: "voucher_name",
    numeric: false,
    disablePadding: false,
    label: "Tên voucher",
  },
  {
    id: "voucher_id",
    numeric: false,
    disablePadding: false,
    label: "ID Voucher",
  },
  {
    id: "voucher_reward",
    numeric: false,
    disablePadding: false,
    label: "Giá trị (VND)",
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Thời gian nhận voucher",
  },
  {
    id: "voucher_expire_day",
    numeric: false,
    disablePadding: false,
    label: "HSD",
  },
  {
    id: "voucher_status",
    numeric: false,
    disablePadding: false,
    label: "Trạng thái",
  },
  {
    id: "voucher_exchange_date",
    numeric: false,
    disablePadding: false,
    label: "Ngày giao dịch",
  },
  {
    id: "voucher_exchange_code",
    numeric: false,
    disablePadding: false,
    label: "Mã khách hàng giao dịch",
  },
  {
    id: "voucher_exchange_name",
    numeric: false,
    disablePadding: false,
    label: "Tên khách hàng đổi thưởng",
  },
  {
    id: "voucher_exchange_email",
    numeric: false,
    disablePadding: false,
    label: "Email khách hàng đổi thưởng",
  },
  {
    id: "voucher_exchange_phone",
    numeric: false,
    disablePadding: false,
    label: "Số ĐT khách hàng đổi thưởng",
  },
  {
    id: "voucher_exchange_product",
    numeric: false,
    disablePadding: false,
    label: "Sản phẩm áp dụng",
  },
  {
    id: "voucher_exchange_created",
    numeric: false,
    disablePadding: false,
    label: "Ngày cập nhật",
  },
];

const bingoHeadMenu = [
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Thời gian giao dịch",
  },
  // {
  //   id: "prize_id",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "ID giải thưởng",
  // },
  {
    id: "account_id",
    numeric: false,
    disablePadding: false,
    label: "Mã khách hàng",
  },
  {
    id: "account_full_name",
    numeric: false,
    disablePadding: false,
    label: "Tên khách hàng",
  },
  {
    id: "ticket",
    numeric: false,
    disablePadding: false,
    label: "Mã vé đã mua",
  },
  {
    id: "redeem_ecoin",
    numeric: false,
    disablePadding: false,
    label: "Số Ecoin/lượt quy đổi",
  },
  {
    id: "prize",
    numeric: false,
    disablePadding: false,
    label: "Giải thưởng",
  },
];

const PER_PAGE = 10;

const TransactionHistory = ({ loading, gameType, eventGifts }) => {
  const { id } = useParams();
  const [searchText, setSearchText] = useState("");
  const [date, setDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [codeFilter, setCodeFilter] = useState("ALL");
  const [filterResult, setFilterResult] = useState(null);
  const [bingoPrizeType, setBingoPrizeType] = useState("ALL");
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const classes = useStyles();

  const { userInfo, accessObjectPermissions } = useContext(StoreContext);

  const [object, setObject] = useState([]);

  const { data: listGiftType } = useGetGiftTypes();

  const { loading: loadingBingoData, data: bingoData } = useGetListTransactionBingo({
    id,
    fromDate: date ? moment(date).utc().format("YYYY/MM/DD HH:mm:ss") : null,
    toDate: endDate ? moment(endDate).utc().format("YYYY/MM/DD HH:mm:ss") : null,
    bingoPrizeType,
    searchText: searchText,
    isSkip: gameType !== "BINGO" || loading
  });

  const { loading: loadingListTransactionEventVoucher, data: listTransactionEventVoucher } = useGetListTransactionEventVoucher({
    id,
    fromDate: date ? moment(date).utc().format("YYYY/MM/DD HH:mm:ss") : null,
    toDate: endDate ? moment(endDate).utc().format("YYYY/MM/DD HH:mm:ss") : null,
    searchText: searchText,
    isSkip: gameType !== "VOUCHER" || loading
  });

  const { loading: loadingJackVoucherTransaction, data: jackVoucherTransaction } = useGetListJackVoucherTransaction({
    id,
    fromDate: date ? moment(date).utc().format("YYYY/MM/DD HH:mm:ss") : null,
    toDate: endDate ? moment(endDate).utc().format("YYYY/MM/DD HH:mm:ss") : null,
    giftType: filterResult,
    codeFilter: codeFilter,
    searchText: searchText,
    isSkip: !_.includes(_.map(listGiftType, item => _.get(item, 'code')), filterResult) || loading,
  });

  const { loading: loadingListTransactionJackGift, data: listTransactionJackGift } = useGetListTransactionJackGift({
    id,
    fromDate: date ? moment(date).utc().format("YYYY/MM/DD HH:mm:ss") : null,
    toDate: endDate ? moment(endDate).utc().format("YYYY/MM/DD HH:mm:ss") : null,
    isSkip: filterResult !== "ECOIN" || loading,
    skip,
    take: PER_PAGE
  });

  const { loading: loadingData, data } = useGetListTransaction({
    id,
    fromDate: date ? moment(date).utc().format("YYYY/MM/DD HH:mm:ss") : null,
    toDate: endDate ? moment(endDate).utc().format("YYYY/MM/DD HH:mm:ss") : null,
    searchText: searchText,
    isSkip: _.includes(['BINGO', 'VOUCHER', 'JACK', undefined], gameType) || filterResult === "VOUCHER" || loading
  });

  useEffect(() => {
    if (gameType === "JACK") {
      setFilterResult("VOUCHER");
    } else {
      setFilterResult(null);
    };
  }, [gameType]);

  useEffect(() => {
    if (
      !listTransactionEventVoucher
      && !bingoData
      && !jackVoucherTransaction
      && !listTransactionJackGift
      && !data
    ) {
      return
    }

    const tmp =
      gameType === "VOUCHER"
        ? _.map(listTransactionEventVoucher, (item) => {
          return {
            voucher_detail: item,
            voucher_code_customer: _.get(item, "account_info.code"),
            voucher_name: _.get(item, "name"),
            voucher_id: _.get(item, "code"),
            voucher_amount: _.get(item, "amount"),
            voucher_created: _.get(item, 'created') ? moment(_.get(item, 'created')).format("DD/MM/YYYY HH:mm:ss") : '-',
            voucher_deadline_day: _.get(item, "voucher_status.name"),
            voucher_status: _.get(item, "voucher_status.name"),
            voucher_exchange_date: _.get(item, "exchange_date") ? moment(_.get(item, "exchange_date")).format("DD/MM/YYYY HH:mm:ss") : "-",
            voucher_exchange_code: _.get(item, "exchange_code") || "-",
            voucher_exchange_product: _.get(item, "exchange_product") || "-",
          };
        })
        : gameType === "BINGO"
          ? _.map(_.get(bingoData, "result"), (item) => {
            const redeem_ecoin = _.get(item, "transaction") ? (_.get(item, "transaction.ecoin") ? `${Math.abs(_.get(item, "transaction.ecoin"))} Ecoin` : `${Math.abs(_.get(item, "transaction.turn"))} lượt`) : "-";//_.get(bingoData,"redeem_ecoin_event_setting[0].ecoin");
            return {
              created: _.get(item, 'created') ? moment(_.get(item, 'created')).format("DD/MM/YYYY HH:mm:ss") : '-',
              prize_id: _.get(item, "code"),
              account_id: _.get(item, "account_info.code"),
              account_full_name: _.get(item, "account_info.full_name"),
              ticket: _.get(item, "code"),
              redeem_ecoin: redeem_ecoin, 
              prize: _.isEmpty(_.get(item, "account_ticket_results"))
                ? "Chưa trúng"
                : bingoPrizeType === "ALL"
                  ? _.map(
                    _.get(item, "account_ticket_results"),
                    (res, key) =>
                      `${key > 0 ? ", " : ""} ${_.get(
                        res,
                        "bingo_result.bingo_result_setting.name"
                      )} - ${moment(
                        _.get(res, "bingo_result.result_date"),
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")}`
                  )
                  : bingoPrizeType === "FINAL"
                    ? _.map(_.get(item, "account_ticket_results"), (res, key) => {
                      if (
                        _.get(res, "bingo_result.bingo_result_setting.special")
                      ) {
                        return `${key > 0 ? ", " : ""} ${_.get(
                          res,
                          "bingo_result.bingo_result_setting.name"
                        )} - ${moment(
                          _.get(res, "bingo_result.result_date"),
                          "YYYY-MM-DD"
                        ).format("DD/MM/YYYY")}`;
                      }
                    })
                    : _.map(_.get(item, "account_ticket_results"), (res, key) => {
                      if (
                        !_.get(res, "bingo_result.bingo_result_setting.special")
                      ) {
                        return `${key > 0 ? ", " : ""} ${_.get(
                          res,
                          "bingo_result.bingo_result_setting.name"
                        )} - ${moment(
                          _.get(res, "bingo_result.result_date"),
                          "YYYY-MM-DD"
                        ).format("DD/MM/YYYY")}`;
                      }
                    }),
              // special: _.map(_.get(item, "account_ticket_results"), (el) =>
              //   _.get(el, "el.bingo_result.bingo_result_setting")
              // ),
            };
          })
          : filterResult === "VOUCHER"
            ? _.map(jackVoucherTransaction, (item) => {
              return {
                code: _.get(item, 'account_info.code'),
                voucher_detail: item,
                voucher_name: _.get(item, 'gift_info.giftByGift.name'),
                voucher_id: _.get(item, 'code'),
                voucher_reward: _.get(item, "gift_info.giftByGift.reward"),
                created: _.get(item, 'created') ? moment(_.get(item, 'created')).format("DD/MM/YYYY HH:mm:ss") : '-',
                voucher_expire_day: _.get(item, 'gift_info.giftByGift.expire_day'),
                voucher_status: _.get(item, "voucher_status.name"),
                voucher_code_customer: _.get(item, "account_info.code"),
                voucher_name_customer: _.get(item, "account_info.full_name"),
                voucher_personalid_customer: _.get(item, "account_info.personalid"),
                voucher_amount: _.get(item, "amount"),
                voucher_created: _.get(item, 'created') ? moment(_.get(item, 'created')).format("DD/MM/YYYY HH:mm:ss") : '-',
                voucher_deadline_day: _.get(item, "voucher_status.name"),
                voucher_exchange_date: _.get(item, "exchange_date") ? moment(_.get(item, "exchange_date")).format("DD/MM/YYYY HH:mm:ss") : "-",
                voucher_exchange_code: _.get(item, "exchange_code") || "-",
                voucher_exchange_product: _.get(item, "exchange_product") || "-",
                voucher_exchange_name: _.get(item, "exchange_full_name") || "-",
                voucher_exchange_email: _.get(item, "exchange_email") || "-",
                voucher_exchange_phone: _.get(item, "exchange_phone") ? formatPhone({ phone: _.get(item, "exchange_phone"), type: 'first' }) : "-",
                voucher_exchange_created: (_.get(item, "updated") && _.get(item, "voucher_status.code") === 'USED') ? moment(_.get(item, "updated")).format("DD/MM/YYYY HH:mm:ss") : "-"
              }
            })
            : filterResult === "GIFT"
              ? _.map(jackVoucherTransaction, (item) => {
                return {
                  created: _.get(item, 'created') ? moment(_.get(item, 'created')).format("DD/MM/YYYY HH:mm:ss") : '-',
                  gift_code: _.get(item, 'code') || "-",
                  code: _.get(item, "account_info.code") || "-",
                  full_name: _.get(item, "account_info.full_name") || "-",
                  name: _.get(item, "gift_info.giftByGift.name") || "-",
                  redeem_ecoin: _.get(item, "gift_info.giftByGift.redeem_ecoin") || "-",
                  redeem_exchange: "-",
                  used_coin: "-",
                }
              })
              : filterResult === "ECOIN"
                ? _.map(_.get(listTransactionJackGift, 'result'), (item) => {
                  return {
                    created: _.get(item, 'created') ? moment(_.get(item, 'created')).format("DD/MM/YYYY HH:mm:ss") : '-',
                    gift_code: _.get(item, 'code') || "-",
                    code: _.get(item, "typeByType.code") || "-",
                    full_name: _.get(item, "info.full_name") || "-",
                    name: _.get(item, "typeByType.name") || "-",
                    redeem_ecoin: _.get(item, "ecoin") || "-",
                    redeem_exchange: "-",
                    used_coin: "-",
                  }
                })
                : _.map(data, (item) => {
                  return {
                    created: _.get(item, 'created') ? moment(_.get(item, 'created')).format("DD/MM/YYYY HH:mm:ss") : '-',
                    code: _.get(item, "accountByAccount.code"),
                    gift_code: _.get(item, "giftByGift.code"),
                    full_name: _.get(item, "accountByAccount.full_name"),
                    name: _.get(item, "giftByGift.name"),
                    redeem_ecoin: _.get(item, "giftByGift.redeem_ecoin"),
                    redeem_exchange: "-",
                    used_coin: "-",
                  };
                });
    setObject(tmp);

    if (filterResult !== "ECOIN") {
      setSkip(0);
      setPage(1);
    }
  }, [data, filterResult, jackVoucherTransaction, listTransactionJackGift, bingoPrizeType, bingoData, listTransactionEventVoucher]);

  const handleExport = () => {
    const ExcelJSWorkbook = new ExcelJS.Workbook();
    const worksheet = ExcelJSWorkbook.addWorksheet("ExcelJS sheet");
    const menuExport = (gameType === "JACK" && filterResult === "VOUCHER") ? voucherHeadMenu : (gameType === "BINGO") ? bingoHeadMenu : headMenu;
    const header = [
      "STT",
      ..._.without(
        _.map(
          menuExport,
          (item) =>
            _.includes(_.keys(_.head(object)), _.get(item, "id")) &&
            _.get(item, "label")
        ),
        false
      ),
    ];

    const dataToExport = [];

    if (_.size(object) > 0) {
      const getValue = _.without(
        _.map(
          menuExport,
          (item) =>
            _.includes(_.keys(_.head(object)), _.get(item, "id")) &&
            _.get(item, "id")
        ),
        false
      );
      let i = 1;
      object.forEach((value) => {
        dataToExport.push([
          i++,
          ..._.map(getValue, (item) => _.get(value, `${item}`)),
        ]);
      });
    } else {
      swal({
        title: "Thông báo",
        text: "Không có dữ liệu!",
        icon: "warning",
      });
      return;
    }

    const customCell = worksheet.getCell("A1");
    customCell.alignment = { vertical: "middle", horizontal: "center" };
    customCell.font = {
      name: "Century",
      family: 4,
      bold: true,
    };

    worksheet.addRow();
    const headerRow = worksheet.addRow(header);
    headerRow.font = {
      name: "Century",
      family: 4,
      bold: true,
    };

    let columnSTT = 1;
    worksheet.columns.forEach((column) => {
      let dataMax = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        dataMax = cell.value ? cell.value.toString().length : 0;
      });
      if (columnSTT === 1) {
        column.width = 5;
        columnSTT++;
      } else if (dataMax <= 2) {
        column.width = 10;
      } else {
        column.width = dataMax <= 15 ? 20 : dataMax;
      }
    });

    if (dataToExport.length > 0) {
      dataToExport.forEach((value) => {
        worksheet.addRow(value);
      });
    }

    customCell.value = "Lịch sử giao dịch";
    worksheet.mergeCells(`A1:${String.fromCharCode(64 + _.size(header))}1`);

    ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `lich_su_giao_dich_game_${gameType}.xlsx`
      );
    });
  };

  const handleChangePrizeType = (e) => {
    if (_.toUpper(_.get(e, 'target.value')) === "ALL") {
      setCodeFilter("ALL");
    } else {
      setCodeFilter(_.get(e, 'target.value'));
    }
  };

  const handleChangeBingoPrizeType = (e) => {
    setBingoPrizeType(e.target.value);
  };

  if (
    loading
    || loadingData
    || loadingBingoData
    || loadingListTransactionEventVoucher
    || loadingJackVoucherTransaction
    || loadingListTransactionJackGift
  ) {
    return (
      <div className={classes.wapperLoading}>
        <CircularProgress />
      </div>
    )
  };

  return (
    <Grid container direction="column" spacing={2} style={{ marginTop: 20 }}>
      <Grid item container spacing={3} alignItems='flex-end'>
        <Grid item xs={2}>
          <Paper
            className={classes.rootSearch}
          >
            <IconButton aria-label="search">
              <Search />
            </IconButton>
            <SearchText
              text={searchText}
              setText={setSearchText}
            />
          </Paper>
        </Grid>

        {gameType === "JACK" && (
          <Fragment>
            <Grid item xs={2} className={classes.textLabelForm}>
              <Typography>Loại giải thưởng</Typography>
              <SelectComponent
                width="100%"
                listData={listGiftType}
                //listData={[...listGiftType,{code:"ECOIN", name: "Trúng Ecoin"}]}
                fieldValue="code"
                fieldTitle="name"
                handleChange={(e) => setFilterResult(e.target.value)}
                value={filterResult}
                defaultTitle="Chọn giải thưởng"
                defaultValue={"ALL"}
                disabledDefault
              />
            </Grid>

            {
              filterResult !== "ECOIN" ? (
                <Grid item xs={2} className={classes.textLabelForm}>
                  <Typography>Giải thưởng</Typography>
                  <SelectComponent
                    width="100%"
                    listData={_.filter(eventGifts, ['gift_type.code', filterResult])}
                    fieldValue="code"
                    fieldTitle="name"
                    handleChange={handleChangePrizeType}
                    defaultValue={"ALL"}
                    defaultTitle="Tất cả"
                    value={codeFilter}
                  />
                </Grid>
              ) : undefined
            }
          </Fragment>
        )}

        {gameType === "BINGO" && (
          <Grid item xs={2} className={classes.textLabelForm}>
            <Typography>Giải thưởng</Typography>
            <SelectComponent
              width="100%"
              listData={[
                {
                  code: "FINAL",
                  name: "Giải chung cuộc",
                  // value: _.filter(eventGifts, (e) =>
                  //   _.get(e, "bingo_result_setting.special")
                  // ),
                },
                {
                  code: "WEEKLY",
                  name: "Giải thường",
                  // value: _.filter(
                  //   eventGifts,
                  //   (e) => !_.get(e, "bingo_result_setting.special")
                  // ),
                },
              ]}
              fieldValue="code"
              fieldTitle="name"
              handleChange={handleChangeBingoPrizeType}
              defaultValue={"ALL"}
              defaultTitle="Tất cả"
              value={bingoPrizeType}
            />
          </Grid>
        )}

        <Grid item container xs={4} spacing={2}>
          <Grid item className={classes.textLabelForm} xs={6}>
            <Typography>Thời gian bắt đầu</Typography>
            <InputDateAndTimePicker
              width='100%'
              defaultValue={date}
              setDateTime={setDate}
            />
          </Grid>

          <Grid item className={classes.textLabelForm} xs={6}>
            <Typography>Thời gian kết thúc</Typography>
            <InputDateAndTimePicker
              width='100%'
              defaultValue={endDate}
              setDateTime={setEndDate}
            />
          </Grid>
        </Grid>

        {
          gameType === 'JACK' ? (
            <Grid item>
              <ButtonExportExcel
                exportName='EXPORT_GAME_JACK'
                event={id}
                code={codeFilter === 'ALL' ? null : codeFilter}
                fromDate={date}
                toDate={endDate}
                gift_type={filterResult}
                disabled={!checkedPermission(userInfo, accessObjectPermissions, EXPORT_PROMOTION)}
              />
            </Grid>
          )
            :
            (
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.buttonExport}
                  onClick={() => handleExport()}
                  disabled={!checkedPermission(userInfo, accessObjectPermissions, EXPORT_PROMOTION)}
                >
                  <DescriptionIcon style={{ fontSize: 14 }} />
                  <span style={{ fontSize: 13 }}>Xuất dữ liệu</span>
                </Button>
              </Grid>
            )
        }
      </Grid>

      <Grid item container>
        {filterResult === "VOUCHER" ? (
          <TableComponent
            align="center"
            headMenu={voucherHeadMenu}
            data={_.slice(object, skip, page * PER_PAGE)}
            searchText={searchText}
            rowPath="jack-voucher"
            setSkip={(skip) => setSkip(skip)}
            page={page}
            setPage={setPage}
            count={object.length}
            isPromotionRouter={false}
            minWidth={2000}
          />
        ) : (
          <TableComponent
            align="center"
            headMenu={
              gameType === "BINGO"
                ? bingoHeadMenu
                : _.difference(
                  _.map(
                    headMenu,
                    (item) =>
                      _.includes(_.keys(_.head(object)), _.get(item, "id")) &&
                      item
                  ),
                  [false]
                )
            }
            data={filterResult !== "ECOIN" ? _.slice(object, skip, page * PER_PAGE) : object}
            searchText={searchText}
            rowPath="promotion"
            setSkip={(skip) => setSkip(skip)}
            page={page}
            setPage={setPage}
            count={filterResult !== "ECOIN" ? object.length : _.get(listTransactionJackGift, 'total.aggregate.totalCount')}
            isPromotionRouter={gameType === "VOUCHER" ? false : true}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TransactionHistory;
