import {
  GET_LIST_EMPLOYEE,
  GET_DETAIL_EMPLOYEE,
  GET_EMPLOYEE_BY_ROLE,
  GET_LIST_DEPARTMENTS,
  GET_LIST_ROLES,
  GET_PRODUCT_BY_EMPLOYEE,
  GET_EMPLOYEE_BY_ID,
  CHECK_EXIST_EMPLOYEE_MANAGER,
  GET_EMPLOYEE_TYPE
} from "../../graphql/schemas/employee/employeeQueries";
import {
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
} from "../../graphql/schemas/employee/employeeMutation";
import { GET_POSITIONS } from "../../graphql/schemas/masterdata/positions/index";
import {
  GET_BRANCHES,
  GET_BRANCH,
  GET_BRANCH_BY,
  GET_BRANCH_NEW,
} from "../../graphql/schemas/masterdata/branches/index";
import * as _ from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import swal from "sweetalert";
import { GET_BRANCH_LIST } from "../../graphql/schemas/office/officeQueries";
import {
  CHECK_CODE_EXIST,
  CHECK_EMAIL_EXIST,
  CHECK_PHONE_EXIST,
} from "../../graphql/schemas/employee/employeeQueries";
import { result } from "lodash";
import { USER_ROLE_ADMINISTRATOR, USER_ROLE_SALES } from "../../constants/defined";

export const useGetProductByEmployee = (variables) => {
  const { data, loading, error, fetchMore } = useQuery(
    GET_PRODUCT_BY_EMPLOYEE,
    {
      variables,
    }
  );

  return {
    data,
    loading,
    error,
    fetchMore,
  };
};

export const useEmployees = (skip = 0, take = 10) => {
  const { data } = useQuery(GET_LIST_EMPLOYEE, {
    variables: {
      skip,
      take,
    },
  });
  return _.get(data, "results");
};

export const useEmployee = (id) => {
  const { data, loading, error, refetch } = useQuery(GET_DETAIL_EMPLOYEE, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: "cache-and-network",
  });
  return {
    employee: _.get(data, "results"),
    loading,
    error,
    refetch,
  };
};

export const useEmployeeByRole = (variables) => {
  const { data, loading, error, refetch } = useQuery(GET_EMPLOYEE_BY_ROLE, {
    variables,
    fetchPolicy: 'network-only'
  });
  return {
    data: _.get(data, "results"),
    count: _.get(data, "t.aggregate.count"),
    loading,
    error,
    refetch,
  };
};

export const usePositions = (isSkip=false) => {
  const { data, loading, error, refetch } = useQuery(GET_POSITIONS, {
    variables: {},
    skip: isSkip
  });
  return {
    data: _.get(data, "results"),
    loading,
    error,
    refetch,
  };
};
export const useBranches = (skip = 0, take = 1e9) => {
  const { data, loading, error, refetch } = useQuery(GET_BRANCHES, {
    variables: {
      skip,
      take,
    },
  });
  return {
    data: _.get(data, "results"),
    loading,
    error,
    refetch,
  };
};
export const useDepartments = (isSkip=false) => {
  const { data, loading, error } = useQuery(GET_LIST_DEPARTMENTS, {
    variables: {},
    skip: isSkip
  });

  return {
    data: _.get(data, "results"),
    loading,
    error,
  };
};
export const useCreate = (setOpenModalCreateEmployee, history) => {
  const [employeeCreate, { loading }] = useMutation(CREATE_EMPLOYEE, {
    // update: (cache, { data: { result } }) => {
    //     const { results } = cache.readQuery({
    //         query: GET_LIST_EMPLOYEE
    //     });
    //     // write query to cache
    //     cache.writeQuery({
    //         query: GET_LIST_EMPLOYEE,
    //         data: {
    //             results: [result, ...results]
    //         }
    //     })
    // },
    onError: async (err) => {
      return await swal({
        title: `${err.message}`,
        icon: "error",
      }).then(() => {
        setOpenModalCreateEmployee(false);
      });
    },
    onCompleted: async () => {
      return await swal({
        title: "Tạo nhân viên thành công",
        icon: "success",
      }).then(() => {
        setOpenModalCreateEmployee(false);
        history.push("/employee");
      });
    },
  });

  return {
    employeeCreate,
    loading,
  };
};

export const useBranch = (id) => {
  const { data, loading, error, refetch } = useQuery(GET_BRANCH, {
    variables: {
      id,
    },
    skip: !id,
  });
  return {
    data: _.get(data, "results"),
    loading,
    error,
    refetch,
  };
};

export const useUpdateEmployee = (
  setOpenModalEditEmployee,
  setOpenEdit,
  accountId
) => {
  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE, {
    refetchQueries: [
      {
        query: GET_EMPLOYEE_BY_ID,
        variables: {
          skip: 0,
          take: 10,
          where: {
            accountByAccount: {
              deleted: { _eq: false },
              id: {
                _eq: accountId,
              },
            },
          },
        },
      },
    ],
    onError: async (err) => {
      return await swal({
        title: "Lỗi thất bại",
        text: `${err.message}`,
        icon: "error",
      }).then(() => {
        setOpenModalEditEmployee(false);
        setOpenEdit(false);
      });
    },
    onCompleted: async () => {
      return await swal({
        title: "Cập nhật nhân viên thành công",
        icon: "success",
      }).then(() => {
        setOpenModalEditEmployee(false);
        setOpenEdit(false);
      });
    },
  });
  return {
    updateEmployee
  };
};

export const useRoles = () => {
  const { data, loading, error, refetch } = useQuery(GET_LIST_ROLES);
  const filterData = _.filter(_.get(data, "results"), (item) => {
    return _.includes([USER_ROLE_SALES, USER_ROLE_ADMINISTRATOR], _.toUpper(_.get(item,"code")));
  });
  return {
    data: filterData,
    loading,
    error,
    refetch,
  };
};

export const useBranchesBy = (
  activeStatus = null,
  branchManage = null,
  id = null,
  skip = 0,
  take = 10
) => {
  const branch =
    branchManage !== undefined && branchManage !== null
      ? { branch: { id: { _eq: branchManage } } }
      : {};
  const active = _.isBoolean(activeStatus)
    ? { active: { _eq: activeStatus } }
    : {};

  const { data, loading, refetch } = useQuery(GET_BRANCH_BY, {
    variables: {
      skip,
      take,
      where: {
        deleted: { _eq: false },
        id: id ? { _eq: id } : undefined,
        active: _.isBoolean(activeStatus) ? { _eq: activeStatus } : undefined,
        branch: branchManage > 0 ? { id: { _eq: branchManage } } : undefined
      },
    },
  });

  return {
    data: _.get(data, "results"),
    count: _.get(data, "t.aggregate.count"),
    loading,
    refetch,
  };
};

export const useBranchNew = (id) => {
  const { loading, error, data, refetch } = useQuery(GET_BRANCH_NEW, {
    variables: {
      where: {
        id: { _eq: id },
      },
    },
    skip: !id,
  });

  return {
    loading,
    error,
    data: _.get(data, "branch[0]"),
    refetch,
  };
};

export const useGetBranchList = (where) => {
  const { data, loading, error } = useQuery(GET_BRANCH_LIST, {
    variables: {
      where,
    },
  });
  return {
    data: _.get(data, "branch"),
    loading,
    error,
  };
};
export const useGetEmployee = (id) => {
  const { data, loading, error } = useQuery(GET_EMPLOYEE_BY_ROLE, {
    variables: {
      skip: 0,
      take: 1,
      where: {
        id: { _eq: id },
      },
    },
    skip: !id,
  });

  return {
    data: _.get(data, "results[0]"),
    loading,
    error,
  };
};
export const useGetEmployeeByAccount = (id) => {
  const { data, loading, error } = useQuery(GET_EMPLOYEE_BY_ROLE, {
    variables: {
      skip: 0,
      take: 1,
      where: {
        account: {
          _eq: id,
        },
      },
    },
    skip: !id,
  });

  return {
    data: _.get(data, "results[0]"),
  };
};

export const useEmailExist = (email) => {
  const { data, loading, error } = useQuery(CHECK_EMAIL_EXIST, {
    variables: { email },
    skip: !email
  });
  return { data: _.get(data, "result"), loading, error };
};

export const usePhoneExist = (phone) => {
  const { data, loading, error } = useQuery(
    CHECK_PHONE_EXIST, {
      variables: { phone },
      skip: !phone
    },
    { keepPreviousData: true }
  );
  return { data: _.get(data, "result"), loading, error };
};

export const useCodeExist = (code) => {
  const { data, loading, error } = useQuery(CHECK_CODE_EXIST, {
    variables: { code },
    skip: !code
  });
  return { data: _.get(data, "result"), loading, error };
};

export const useGetEmployeeType = () => {
  const { data, loading, error } = useQuery(GET_EMPLOYEE_TYPE);
  return {
    data: _.get(data, 'result'),
    loading,
    error
  };
};


