import _ from 'lodash';
import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/styles';
import useTheme from '@material-ui/styles/useTheme';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import swal from 'sweetalert';
import { useDeleteBanner } from '../pages/Other/Banner/hooks';
import { StoreContext } from '../resources/store';
import { checkedPermission } from '../helper';
import { DELETE_BANNER } from '../constants/defined';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 550,
        borderRadius: 0,
        backgroundColor: '#fafafa',
        boxShadow: 'none',
        fontSize: '30px',
        fontWeight: 'bold',
        overflow: 'hidden',
        margin: 'auto'
    },
    media: {
        height: 300,
    },
    cardHeader: {
        '& > .MuiCardHeader-content span': {
            fontWeight: 'bold',
            textAlign: 'center'
        }
    },
    iconRemove: {
        fontSize: 30
    },
    iconRemoveContainer: {
        float: 'right',
        '&:hover': {
            color: 'red'
        }
    },
}));

const CardBanner = ({ image, title, bannerId, setLoading }) => {
    const classes = useStyles();
    const { userInfo, accessObjectPermissions } = useContext(StoreContext);
    const { deleteBanner } = useDeleteBanner();

    const handleDeleteBanner = () => {
        swal({
            title: "Bạn có muốn xoá BANNER này?",
            icon: "warning",
            buttons: ["HUỶ", "XOÁ"],
            dangerMode: true,
        }).then((confirm) => {
            if (confirm) {
                setLoading(true);
                deleteBanner({
                    variables: {
                        id: bannerId,
                        account: _.get(userInfo, 'id')
                    }
                }).then(() => {
                    setLoading(false);
                })
            } else {
                setLoading(false);
            }
        })
    }

    return (
        <Grid item lg={6} md={6} sm={6} xs={12} className={classes.cardBanner}>
            <Card className={classes.root}>
                <CardHeader title={title} className={classes.cardHeader} />
                <CardMedia
                    className={classes.media}
                    image={image}
                    title="Contemplative Reptile"
                >
                    {
                        checkedPermission(userInfo, accessObjectPermissions, DELETE_BANNER) && (
                            <IconButton onClick={handleDeleteBanner} className={classes.iconRemoveContainer}>
                                <HighlightOffIcon className={classes.iconRemove} />
                            </IconButton>
                        )
                    }

                </CardMedia>
            </Card>
        </Grid>
    )
}

export default CardBanner
