import React, { useEffect, useState, useContext } from 'react';
import * as _ from "lodash";
import {
    Paper, Grid, Typography, TextField, Button,
    Dialog, DialogActions, DialogContent, DialogContentText,
    useMediaQuery,
    CircularProgress,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import DeleteIcon from '@material-ui/icons/Delete';
import { useMutation, useQuery } from '@apollo/client';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker
} from '@material-ui/pickers';
import { GET_ALL_SCHEDULE, GET_DETAIL_SCHEDULE } from '../../graphql/schemas/employee/employeeQueries';
import { DELETE_SCHEDULE } from '../../graphql/schemas/employee/employeeMutation';
import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { StoreContext } from '../../resources/store';

const EmployeeDetailTime = ({ onBack, onEdit, idSchedule }) => {
    const { userInfo, accessPermissions } = useContext(StoreContext);
    const { id } = useParams();
    const [startDateInput, setStartDateInput] = useState(
        moment().utc().format("YYYY-MM-DD HH:mm")
    );
    const [endDateInput, setEndDateInput] = useState(
        moment().utc().format("YYYY-MM-DD HH:mm")
    );
    const dateFormatter = (str) => {
        return str;
    };
    const { loading, error, data } = useQuery(GET_DETAIL_SCHEDULE, {
        variables: {
            uid: idSchedule
        }
    });

    const [deleteSchedule] = useMutation(DELETE_SCHEDULE, {
        refetchQueries: [{
            query: GET_ALL_SCHEDULE, variables: {
                skip: 0,
                take: 10,
                where: {
                    deleted: { _eq: false },
                    employee: { _eq: id },
                }
            }
        }],
    });

    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const [showModal, setShowModal] = useState(false);

    const useStyles = makeStyles((theme) => ({
        contentModal: {
            textAlign: "center",
            width: "550px",
            fontSize: "30px",
            fontWeight: 700,
            color: "#000000e3",
            paddingTop: "20px",
        },
        agreeBtn: {
            backgroundColor: "#db2828",
            color: "#fff",
            textShadow: "none",
            backgroundImage: "none",
        },
        err: {
            color: "#db2828",
            paddingTop: "5px"
        },
        cancelBtn: {
            verticalAlign: "baseline",
            background: "#e0e1e2 none",
            color: "rgba(0, 0, 0, .6)",
        },
        message: {
            border: `2px solid lightgrey`,
            border: 'none'
        },
        note: {
            borderBottomLeftRadius: '10px',
            borderTopLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            borderTopRightRadius: '0',
            boxShadow: '1px 5px 7px lightgrey',
            padding: '8px',
            width: matchesMD ? '400px' : undefined,
        },
        buttonBack: {
            backgroundColor: "black",
            color: '#ffffff',
            padding: '6px 36px',
            marginTop: '60px',
            marginRight: '30px',
            borderRadius: '0',
            marginBottom: '30px'
        },
        buttonCreate: {
            backgroundColor: "#2C8A81",
            color: '#ffffff',
            padding: '6px 36px',
            marginTop: '60px',
            marginRight: '30px',
            borderRadius: '0',
            marginBottom: '30px',
            textTransform: 'none'
        },
        keyboardDate: {
            marginTop: 0,
            '& .MuiInputBase-adornedEnd': {
                '& > input': {
                    padding: '8px 14px'
                }
            }
        },
        deleteBtn: {
            backgroundColor: "#cc471f",
            marginLeft: "15px",
            width: "180px",
            marginLeft: "150px",
        }
    }));

    const classes = useStyles();

    const handleDeleteSchedule = () => {
        //Call API to delete
        try {
            deleteSchedule({
                variables: {
                    id: idSchedule,
                    updated_by: _.get(userInfo, 'id')
                }
            }).then((data) => {
                swal("Xóa lịch hẹn thành công", "", "success").then(success => {
                    setShowModal(false);
                    onBack();
                });
            });
        } catch (error) {
            console.error("Detele schedule failed: ", error);
            swal("Xóa lịch hẹn thất bại", error, "error").then(success => {
                setShowModal(false);
            });
        }
    };

    useEffect(() => {
        if (data) {
            setStartDateInput(moment(_.get(data, 'detail.start_time')).format('YYYY-MM-DD HH:mm'));
            setEndDateInput(moment(_.get(data, 'detail.end_time')).format('YYYY-MM-DD HH:mm'))
        }

    }, [data])

    if (loading) return <CircularProgress />


    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Button
                className={classes.deleteBtn}
                variant="contained"
                color="secondary"
                onClick={() => setShowModal(true)}
                startIcon={<DeleteIcon />}
            >
                Xóa lịch hẹn
            </Button>

            <Grid container direction="column" style={{ paddingLeft: matchesSM ? '20px' : '150px', paddingTop: matchesSM ? '20px' : undefined }}>
                <Grid container direction={matchesMD ? 'column' : 'row'} alignItems="flex-start">
                    <Grid item style={{ paddingTop: '20px', paddingRight: '40px' }}>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Loại lịch hẹn: <span style={{ color: 'red' }}>*</span></Typography>
                            </Grid>
                            <Grid item className={classes.text}>
                                <div>{_.get(data, 'detail.type.name')}</div>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Ngày bắt đầu: <span style={{ color: 'red' }}>*</span></Typography>
                            </Grid>
                            <Grid item className={classes.text}>
                                <KeyboardDateTimePicker
                                    disabled={true}
                                    className={classes.keyboardDate}
                                    style={{
                                        backgroundColor: '#ffffff',
                                        width: '223px',
                                        border: '1px solid lightgray',
                                        padding: '5px 0',
                                        borderRadius: '5px'
                                    }}
                                    margin="normal"
                                    variant="inline"
                                    id="time-picker"
                                    format="MM/dd/yyyy HH:mm"
                                    rifmFormatter={dateFormatter}
                                    value={startDateInput}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                />

                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Mã sản phẩm: </Typography>
                            </Grid>
                            <Grid item className={classes.text}>
                                <div>{_.get(data, 'detail.product_info.code')}</div>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Mã khách hàng: </Typography>
                            </Grid>
                            <Grid item className={classes.text}>
                                <div>{_.get(data, 'detail.customer_info.code')}</div>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Mã NV đi cùng: </Typography>
                            </Grid>
                            <Grid item className={classes.text}>
                                <div>{_.get(data, 'detail.employee_info.code')}</div>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="column" style={{ marginBottom: '66px' }}>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', fontSize: '25px', marginBottom: '20px', marginTop: matchesMD ? '20px' : undefined }}>Ghi chú:</Typography>
                                <Paper className={classes.note}>
                                    <TextField
                                        className={classes.message}
                                        InputProps={{ disableUnderline: true }}
                                        multiline
                                        style={{ width: '25em', height: '100%' }}
                                        id="message"
                                        rows={5}
                                        value={_.get(data, 'detail.remark')}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{ paddingTop: '20px' }}>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Tiêu đề: </Typography>
                            </Grid>
                            <Grid item className={classes.text}>
                                <div>{_.get(data, 'detail.title')}</div>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Ngày kết thúc: </Typography>
                            </Grid>
                            <Grid item className={classes.text}>
                                <KeyboardDateTimePicker
                                    disabled={true}
                                    className={classes.keyboardDate}
                                    style={{
                                        backgroundColor: '#ffffff',
                                        width: '223px',
                                        border: '1px solid lightgray',
                                        padding: '5px 0',
                                        borderRadius: '5px'
                                    }}
                                    margin="normal"
                                    variant="inline"
                                    id="time-picker"
                                    format="MM/dd/yyyy HH:mm"
                                    value={endDateInput}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Vị trí: </Typography>
                            </Grid>
                            <Grid item className={classes.text}>
                                <div>{_.get(data, 'detail.product_info.address.short')} {_.get(data, 'detail.product_info.address.street.name')} {_.get(data, 'detail.product_info.address.ward.name')} {_.get(data, 'detail.product_info.address.district.name')} {_.get(data, 'detail.product_info.address.province.name')}</div>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Tên khách hàng: </Typography>
                            </Grid>
                            <Grid item className={classes.text}>
                                <div>{_.get(data, 'detail.customer_info.full_name')}</div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                        <Button className={classes.buttonCreate} variant="contained" onClick={() => onEdit()}>Thay đổi</Button>
                        <Button onClick={() => { onBack() }} className={classes.buttonBack} variant="contained">Quay lại</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog

                fullScreen={matchesSM}
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <DialogContentText className={classes.contentModal}>
                        Bạn có chắc muốn xóa?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleDeleteSchedule} className={classes.agreeBtn} variant="contained">
                        Xóa
                    </Button>
                    <Button onClick={() => setShowModal(false)} className={classes.cancelBtn} autoFocus variant="contained">
                        Hủy
                    </Button>
                </DialogActions>
            </Dialog>
        </MuiPickersUtilsProvider >
    )
}

export default EmployeeDetailTime;
