import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { validEcoeId, validOffice } from './regex';
import * as _ from 'lodash';
import ButtonReturn from '../ButtonReturn';
import slugs from '../../resources/slugs';

const style = {
    borderRadius: 0
}


const EmployeeCreateTeam = ({ handleOpenCreateEmployeeTeam }) => {

    const history = useHistory();

    const useStyles = makeStyles(theme => ({
        margin: {
            margin: '20px 0',
        },
        formContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
        },
        textLabelForm: {
            wordWrap: 'break-word',
            width: 240,
            fontWeight: 'bold',
            marginRight: 30
        },
        input: {
            width: 250,
            backgroundColor: 'white',
            marginLeft: '0',
            '& > .MuiFormHelperText-marginDense': {
                backgroundColor: '#FAFAFA',
                margin: 0
            }
        },
        button: {
            color: '#fff',
            borderRadius: 0,
            textTransform: 'uppercase'
        },
        buttonTitle: {
            color: "white",
            borderRadius: 0,
            fontSize: '16px',
            width: '26em',
            fontWeight: 'bold'
        },
        buttonConfirmCreate: {
            padding: '6px 20px',
            marginRight: '30px'
        },
        err: {
            color: "#db2828",
            paddingTop: "5px"
        },
    }));

    const theme = useTheme();

    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const [name, setName] = useState("");
    const [idOffice, setIdOffice] = useState("");
    const [idEmployeeManage, setIdEmployeeManage] = useState("");
    const [idEmployeeOne, setIdEmployeeOne] = useState("");
    const [idEmployeeTwo, setIdEmployeeTwo] = useState("");
    const [idEmployeeThree, setIdEmployeeThree] = useState("");

    const [error, setError] = useState({
        errorEmployeeOne: "",
        errorEmployeeTwo: "",
        errorEmployeeThree: "",
        errorIdEmployeeManage: "",
        errorIdOffice: ""
    });
    const [idOfficeError, setIdOffceError] = useState("");
    const [submitDisabled, setSubmitDisabled] = useState(false);

    useEffect(() => {
        const validateCheck = Object.values({
            name,
            idOffice,
            idEmployeeManage,
        }).every((item) => Boolean(item));
        validate();

        validateCheck ? setSubmitDisabled(false) : setSubmitDisabled(true);
    }, [name, idEmployeeManage, idOffice, idEmployeeTwo, idEmployeeOne, idEmployeeThree])

    const validate = () => {
        if (!validEcoeId.test(idEmployeeManage) && _.isEmpty(idEmployeeManage) === false) {
            setError({
                ...error,
                errorIdEmployeeManage: "Thông tin nhập sai cú pháp"
            })
        }
        if (!validEcoeId.test(idEmployeeOne) && _.isEmpty(idEmployeeOne) === false) {
            setError((prev) => ({
                ...prev,
                errorEmployeeOne: "Thông tin nhập sai cú pháp"
            }))
        } else {
            setError((prev) => ({
                ...prev,
                errorEmployeeOne: ""
            }))
        }
        if (!validOffice.test(idOffice) && _.isEmpty(idOffice) === false) {
            setError((prev) => ({
                ...prev,
                errorIdOffice: "Thông tin nhập sai cú pháp"
            }))
        } else {
            setError((prev) => ({
                ...prev,
                errorIdOffice: ""
            }))
        }

        if (!validEcoeId.test(idEmployeeThree) && _.isEmpty(idEmployeeThree) === false) {
            setError((prev) => ({
                ...prev,
                errorEmployeeThree: "Thông tin nhập sai cú pháp"
            }))
        } else {
            setError((prev) => ({
                ...prev,
                errorEmployeeThree: ""
            }))
        }
        if (!validEcoeId.test(idEmployeeTwo) && _.isEmpty(idEmployeeTwo) === false) {
            setError((prev) => ({
                ...prev,
                errorEmployeeTwo: "Thông tin nhập sai cú pháp"
            }))
        } else {
            setError((prev) => ({
                ...prev,
                errorEmployeeTwo: ""
            }))
        }
        if (error.errorEmployeeOne === "" && error.errorEmployeeTwo === "" && error.errorEmployeeThree === "") {
            setSubmitDisabled(false);
        }
    }




    return (
        <Fragment>
            <Grid container spacing={1} alignItems="center" className={classes.margin}>
                <Grid item className={classes.textLabelForm}>
                    <p>Tên nhóm: <span style={{ color: 'red' }}>*</span></p>
                </Grid>
                <Grid item>
                    <TextField
                        inputProps={{ maxLength: 50 }}
                        style={style}
                        size="small"
                        id="input-with-icon-employee"
                        variant="outlined"
                        className={classes.input}
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center" className={classes.margin}>
                <Grid item className={classes.textLabelForm}>
                    <p>Mã văn phòng: <span style={{ color: 'red' }}>*</span></p>
                </Grid>
                <Grid item>
                    <TextField
                        style={style}
                        size="small"
                        id="input-with-icon-employee"
                        variant="outlined"
                        value={idOffice}
                        helperText={error.errorIdOffice && idOffice !== "" && <div className={classes.err}>{error.errorIdOffice}</div>}
                        onChange={(e) => {
                            setIdOffice(e.target.value)
                            if (validOffice.test(idOffice)) {
                                setError((prev) => ({
                                    ...prev,
                                    errorIdOffice: ""
                                }))
                            }
                        }}
                        className={classes.input}
                        required />
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center" className={classes.margin}>
                <Grid item className={classes.textLabelForm}>
                    <p>Mã NV ECOE quản lý (Key): <span style={{ color: 'red' }}>*</span></p>
                </Grid>
                <Grid item>
                    <TextField
                        style={style}
                        size="small"
                        id="input-with-icon-employee"
                        variant="outlined"
                        value={idEmployeeManage}
                        helperText={error.errorIdEmployeeManage && <div className={classes.err}>{error.errorIdEmployeeManage}</div>}
                        className={classes.input}
                        onChange={(e) => {
                            setIdEmployeeManage(e.target.value)
                            if (validEcoeId.test(idEmployeeManage)) {
                                setError((prev) => ({
                                    ...prev,
                                    errorIdEmployeeManage: ""
                                }))
                            }
                        }}
                        required
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center" className={classes.margin}>
                <Grid item className={classes.textLabelForm}>
                    <p>Mã nhân viên 1: </p>
                </Grid>
                <Grid item>
                    <TextField
                        style={style}
                        size="small"
                        id="input-with-icon-employee"
                        variant="outlined"
                        className={classes.input}
                        helperText={error.errorEmployeeOne && idEmployeeOne !== "" && <div className={classes.err}>{error.errorEmployeeOne}</div>}
                        required
                        value={idEmployeeOne}
                        onChange={(e) => {
                            setIdEmployeeOne(e.target.value)
                            if (validEcoeId.test(idEmployeeOne)) {
                                setError({
                                    ...error,
                                    errorEmployeeOne: ""
                                })
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center" className={classes.margin}>
                <Grid item className={classes.textLabelForm}>
                    <p>Mã nhân viên 2: </p>
                </Grid>
                <Grid item>
                    <TextField
                        style={style}
                        size="small"
                        id="input-with-icon-employee"
                        variant="outlined"
                        className={classes.input}
                        helperText={error.errorEmployeeTwo && idEmployeeTwo !== "" && <div className={classes.err}>{error.errorEmployeeTwo}</div>}
                        required
                        value={idEmployeeTwo}
                        onChange={(e) => {
                            setIdEmployeeTwo(e.target.value)
                            if (validEcoeId.test(idEmployeeTwo)) {
                                setError((prev) => ({
                                    ...prev,
                                    errorEmployeeTwo: ""
                                }))

                            }
                        }} />
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center" className={classes.margin}>
                <Grid item className={classes.textLabelForm}>
                    <p>Mã nhân viên 3: </p>
                </Grid>
                <Grid item>
                    <TextField
                        style={style}
                        size="small"
                        id="input-with-icon-employee"
                        variant="outlined"
                        className={classes.input}
                        required
                        helperText={error.errorEmployeeThree && idEmployeeThree !== "" && <div className={classes.err}>{error.errorEmployeeThree}</div>}
                        value={idEmployeeThree}
                        onChange={(e) => {
                            setIdEmployeeThree(e.target.value)
                            if (validEcoeId.test(idEmployeeThree)) {
                                setError((prev) => ({
                                    ...prev,
                                    errorEmployeeThree: ""
                                }))
                            }
                        }}
                    />
                </Grid>
            </Grid>
            {/* Button */}
            <Grid container spacing={1} alignItems="center" style={{ paddingTop: '90px' }} justifyContent={matchesMD ? undefined : 'flex-end'}>
                <Grid item>
                    <Button
                        className={classes.buttonConfirmCreate}
                        color='primary'
                        variant="contained"
                        disabled={submitDisabled ||
                            error.errorIdOffice !== "" ||
                            error.errorIdEmployeeManage !== "" ||
                            error.errorEmployeeOne !== "" ||
                            error.errorEmployeeTwo !== "" ||
                            error.errorEmployeeThree !== ""
                        }
                        onClick={() => handleOpenCreateEmployeeTeam(idOffice, idEmployeeOne, idEmployeeTwo, idEmployeeThree, idEmployeeManage, setError)}>
                        Tạo nhóm
                    </Button>

                    <ButtonReturn link={slugs.employee} />
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default EmployeeCreateTeam;
