import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/styles/useTheme';
import makeStyles from '@material-ui/styles/makeStyles';
import logoEcoe from '../../assets/images/banner/logoEcoe.png';
import { useHistory } from 'react-router-dom';
import ButtonReturn from '../ButtonReturn';
import { useListNotification } from '../../pages/Other/Notification/hooks';

import _ from 'lodash';

//import waypoint
import { Waypoint } from 'react-waypoint';
import Moment from 'react-moment';
import slugs from '../../resources/slugs';

const NotificationHistory = () => {

    const PER_PAGE = 5;

    const useStyles = makeStyles((theme) => ({
        circle: {
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            marginRight: '32px'
        },
        message: {
            fontSize: '20px',
            marginLeft: '40px',
            marginRight: '160px',
            wordWrap: 'break-word'
        },

    }))

    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

    const { notificationList, fetchMore } = useListNotification(0, PER_PAGE);

    const count = _.get(notificationList, 't.aggregate.count');

    return (
        <Grid container direction="column">

            {notificationList !== undefined && _.map(notificationList.results, (item, index) => (
                <Fragment key={item.id}>
                    <Grid container direction="row" style={{ marginTop: '60px', flexWrap: 'wrap' }} alignItems="center">
                        <Grid item className={classes.circle} style={{ backgroundColor: '#dfe235' }} ></Grid>
                        <Grid item><img src={logoEcoe} alt="logo" width={40} /></Grid>
                        <Grid item className={classes.message} style={{ width: matchesSM ? "600px" : '800px' }}>
                            <Moment format="DD/MM/YYYY hh:mm A">{_.get(item, 'created')}</Moment> Thông báo "{item.body}" đã được gửi bởi {<Link to={`/employee/detail/${_.get(item, 'created_by.id')}`}>{_.get(item, 'created_by.code')}</Link>}
                        </Grid>
                    </Grid>
                    {(index === _.size(notificationList.results) - 1 && count > _.size(notificationList.results)) && (
                        <Waypoint
                            onEnter={() => {
                                fetchMore({
                                    variables: {
                                        skip: (_.size(notificationList.results) / PER_PAGE) * PER_PAGE,
                                        take: PER_PAGE
                                    },
                                    updateQuery: (previousResult, { fetchMoreResult }) => {
                                        if (!_.get(fetchMoreResult, 'results')) {
                                            return previousResult
                                        };
                                        return {
                                            results: [
                                                ...previousResult.results,
                                                ...fetchMoreResult.results
                                            ],
                                        }
                                    }
                                })
                            }}>

                        </Waypoint>
                    )}


                </Fragment>

            ))}

            <Grid item style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '200px' }}>
                <ButtonReturn link={slugs.setting} />
            </Grid>
        </Grid>
    )
}

export default NotificationHistory;
