import _ from "lodash";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
    Grid, IconButton, makeStyles, TextField, Divider,
    FormControl, FormGroup, FormControlLabel, Checkbox
} from "@material-ui/core";
import { Add, AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import moment from "moment";
import axios from "axios";
import { useGetListApplyType } from "../hooks";
import ColorPicker from "../components/ColorPicker";
import { ConfigContext } from "../../../context/ConfigContext";


const useStyles = makeStyles((theme) => ({
    pageContainer: {
        backgroundColor: '#fff',
        height: 200,
        borderRadius: 12,
        padding: 24,
        display: 'flex',
        alignItems: 'center'
    },
    titlePageText: {
        fontWeight: 500,
        fontSize: 30,
        margin: '20px 0px'
    },
    space: {
        width: '100%',
        height: 30
    },
    pageBody: {
        backgroundColor: '#fff',
        borderRadius: 12,
        display: 'flex',
        alignItems: 'center',
        marginTop: 20
    },
    pageBodyHeader: {
        padding: 24,
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #E2E1E1'
    },
    boxUploadImage: {
        backgroundColor: '#F5F5F5',
        width: 100,
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#ddd9d9'
        }
    },
    textUpload: {
        color: '#0A2240',
        fontSize: 13,
        fontWeight: 700,
        marginTop: 6,
        cursor: 'pointer',
    },
    iconUpload: {
        color: '#B9B9B9',
        cursor: 'pointer',
    },
    buttonVoucherCreate: {
        backgroundColor: '#00837B',
        width: 250,
        height: 48,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center'
    },
    textCreateButton: {
        textTransform: 'none',
        color: '#fff',
        fontSize: 16,
        marginLeft: 10,
        fontWeight: 600
    },
    iconCreateVoucher: {
        color: '#fff'
    },
    buttonUploadFooter: {
        textTransform: 'none',
        color: '#00837B',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 200,
        height: 48,
        backgroundColor: '#fff',
        boxShadow: 'none',
        border: '1px solid #00837B'
    },
    confirm: {
        color: '#fff',
        textTransform: 'none',
        backgroundColor: '#00837B',
        width: 150,
        height: 38,
        marginTop: 20
    },
    imgPicker: {
        display: "flex !important",
        background: "#F5F5F5",
        width: "100px",
        height: "100px",
        justifyContent: "space-around",
        alignItems: "center",
        flexDirection: "column",
        fontWeight: "bold",
        cursor: "pointer",
        border: "1px solid #B9B9B9",
        borderRadius: "6px",
    },
    textLabelForm: {
        fontWeight: "bold",
        paddingRight: "20px",
    },
}));

const PromotionCreateVoucher = ({ vouchers, setVouchers }) => {
    const classes = useStyles();
    const [inputValues, setInputValues] = useState(vouchers);
    const [checked, setChecked] = React.useState([]);
    const [dateTime, setDateTime] = useState();
    const { data: listApplyType } = useGetListApplyType();

    const configValue = useContext(ConfigContext);

    const addVoucherField = () => {
        setInputValues([
            ...inputValues,
            {
                name: "",
                deadline_day: null,
                amount: null,
                start_time: null,
                end_time: null,
                prefix_code: null,
                postfix_code: null,
                quantity: null,
                apply_type: [..._.map(listApplyType, item => _.get(item, 'id'))],
                banner: "",
                description: "",
            },
        ]);
    };

    useEffect(() => {
        let defaultValue = _.map([...vouchers], (item) => {
            return {
                id: _.get(item, "id"),
                name: _.get(item, "name"),
                deadline_day: _.get(item, "deadline_day"),
                amount: _.get(item, "amount"),
                start_time: moment(_.get(item, "start_time")).format("YYYY-MM-DD[T]HH:mm"),
                end_time: moment(_.get(item, "end_time")).format("YYYY-MM-DD[T]HH:mm"),
                prefix_code: _.get(item, "prefix_code"),
                postfix_code: _.get(item, "postfix_code"),
                quantity: _.get(item, "quantity"),
                apply_type: _.get(item, "apply_type"),
                banner: _.get(item, "banner"),
                description: _.get(item, "description"),
                background_color: item?.background_color || 'none',
                title_color: item?.title_color || '#000000',
            };
        });

        setInputValues(defaultValue);
    }, []);

    const handleRemoveVoucher = (index) => {
        if (inputValues.length <= 1) return;
        const fields = [...inputValues];
        fields.splice(index, 1);
        setInputValues(fields);
    };

    const handleChangeVoucherInput = async (index, event) => {
        const values = [...inputValues];

        if (event.target.name === "max_redeem") {
            values[index][event.target.name] = parseInt(event.target.value);
        }
        else if (event.target.name === "apply_type") {
            if (_.includes(values[index][event.target.name], Number(event.target.value))) {
                _.remove(values[index][event.target.name], (val) => val === Number(event.target.value))
            } else {
                values[index][event.target.name] = [...values[index][event.target.name], Number(event.target.value)];
            }
        }
        else if (event.target.name === "banner") {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append("file", file);
            formData.append("media_type_code", "ICON");
            try {
                const config = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                };
                const { data } = await axios.post(
                    configValue.API_UPLOAD_FILE,
                    formData,
                    config
                );
                values[index][event.target.name] = data[0];
                setInputValues(values);
            } catch (error) {
                console.log(error);
            }
        }
        else {
            values[index][event.target.name] = event.target.value;
        }
        setInputValues(values);
    };

    const onBlurInput = () => {
        setVouchers(inputValues);
    };

    return (
        <div>
            <Grid container item alignItems="center">
                <Grid
                    item
                    className={classes.textLabelForm}
                    xs={12}
                >
                    <p style={{ fontSize: "24px", marginTop: 0 }}>Giải thưởng</p>
                </Grid>
                <Grid container item alignItems="center">
                    <Grid
                        item
                        className={classes.textLabelForm}
                        xs={2}
                    >
                        <p>Loại giải thưởng</p>
                    </Grid>
                </Grid>
            </Grid>
            <Divider className={classes.styleOneDivider} />
            {
                _.map(inputValues, (item, key) => (
                    <Grid
                        item
                        container
                        key={key}
                        alignItems="center"
                        spacing={2}
                        className={classes.itemRow}
                    >
                        <Grid item xs={12}>
                            <Grid container alignItems="center">
                                <p className={classes.textLabelForm}>{`Voucher ${key + 1}`}</p>
                                {inputValues.length > 1 && (
                                    <IconButton onClick={() => handleRemoveVoucher(key)}>
                                        <RemoveCircleOutline style={{ fill: "red" }} />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>

                        <Grid item container xs={4} alignItems="center">
                            <Grid item className={classes.textLabelForm} md={4}>
                                <p>Tên voucher<span style={{ color: "red" }}>*</span></p>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    className={classes.input}
                                    required
                                    value={_.get(item, 'name')}
                                    name="name"
                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                    onBlur={onBlurInput}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container md={4} alignItems="center">
                            <Grid item xs={4}>
                                <p className={classes.textLabelForm}>Giá trị (VND)<span style={{ color: "red" }}>*</span>
                                </p>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    type="number"
                                    size="small"
                                    variant="outlined"
                                    className={classes.input}
                                    required
                                    value={_.get(item, 'amount')}
                                    name="amount"
                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                    onBlur={onBlurInput}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container md={4} alignItems="center">
                            <Grid item className={classes.textLabelForm} xs={4}>
                                <p>Hạn sử dụng<span style={{ color: "red" }}>*</span></p>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="number"
                                    size="small"
                                    variant="outlined"
                                    className={classes.input}
                                    value={_.get(item, 'deadline_day')}
                                    name="deadline_day"
                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                    onBlur={onBlurInput}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <p style={{ color: "#aaa", paddingLeft: '15px' }}>Ngày</p>
                            </Grid>
                        </Grid>

                        <Grid item container md={4} alignItems="center">
                            <Grid item className={classes.textLabelForm} xs={4}>
                                <p>Tiền tố</p>
                            </Grid>
                            <Grid item xs={8} style={{ position: 'relative' }}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    className={classes.input}
                                    value={_.get(item, 'prefix_code')}
                                    name="prefix_code"
                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                    onBlur={onBlurInput}
                                />
                                <p style={{ position: 'absolute', top: '30px' }}>Ví dụ: EKM21001-XXXXX</p>
                            </Grid>
                        </Grid>

                        <Grid item container md={4} alignItems="center">
                            <Grid item className={classes.textLabelForm} xs={4}>
                                <p>Hậu tố</p>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    className={classes.input}
                                    value={_.get(item, 'postfix_code')}
                                    name="postfix_code"
                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                    onBlur={onBlurInput}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container md={4} alignItems="center">
                            <Grid item className={classes.textLabelForm} xs={4}>
                                <p>Số lượng<span style={{ color: "red" }}>*</span></p>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    type="number"
                                    size="small"
                                    variant="outlined"
                                    className={classes.input}
                                    value={_.get(item, 'quantity')}
                                    name="quantity"
                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                    onBlur={onBlurInput}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container md={12} alignItems="center">
                            <Grid item className={classes.textLabelForm} xs={2}>
                                <p>Sản phẩm áp dụng<span style={{ color: "red" }}>*</span></p>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup row >
                                        {_.map(listApplyType, (item, index) => (
                                            <FormControlLabel
                                                key={index}
                                                name="apply_type"
                                                onChange={(e) => handleChangeVoucherInput(key, e)}
                                                onBlur={onBlurInput}
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        value={_.get(item, 'id')}
                                                        checked={_.includes(_.get(inputValues[key], 'apply_type'), _.get(item, 'id'))}
                                                    />
                                                }
                                                label={<span style={{ fontSize: '16px' }}>{_.get(item, 'name')}</span>}
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item container xs={4} alignItems="center">
                            <Grid item xs={4} className={classes.textLabelForm}>
                                <p>
                                    Thời gian bắt đầu<span style={{ color: "red" }}>*</span>
                                </p>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    className={classes.input}
                                    value={_.get(item, 'start_time')}
                                    name="start_time"
                                    type="datetime-local"
                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                    onBlur={onBlurInput}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container alignItems="center" xs={4}>
                            <Grid item xs={4} className={classes.textLabelForm}>
                                <p>
                                    Thời gian kết thúc<span style={{ color: "red" }}>*</span>
                                </p>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    className={classes.input}
                                    value={_.get(item, 'end_time')}
                                    name="end_time"
                                    type="datetime-local"
                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                    onBlur={onBlurInput}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container md={4} alignItems="center">
                            <Grid xs={4} item className={classes.textLabelForm}>
                                <p>
                                    Mô tả<span style={{ color: "red" }}>*</span>
                                </p>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    name="description"
                                    className={classes.input}
                                    value={_.get(item, 'description')}
                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                    onBlur={onBlurInput}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container alignItems="center">
                            <Grid item container xs={4}>
                                <Grid item xs={4} className={classes.textLabelForm}>
                                    <p>
                                        Hình ảnh<span style={{ color: "red" }}>*</span>
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    {_.isEmpty(_.get(item, "banner.url")) ? (
                                        <>
                                            <input
                                                id={`banner-voucher-${key}`}
                                                hidden
                                                type="file"
                                                name="banner"
                                                accept="image/*"
                                                multiple
                                                className={classes.inputStyle}
                                                onChange={(e) => handleChangeVoucherInput(key, e)}
                                            />
                                            <label
                                                htmlFor={`banner-voucher-${key}`}
                                                className={classes.imgPicker}
                                            >
                                                Thêm ảnh <Add />
                                            </label>{" "}
                                        </>
                                    ) : (
                                        <>
                                            <input
                                                id={`banner-voucher-${key}`}
                                                hidden
                                                type="file"
                                                name="banner"
                                                accept="image/*"
                                                multiple
                                                className={classes.inputStyle}
                                                onChange={(e) => handleChangeVoucherInput(key, e)}
                                            />
                                            <label
                                                htmlFor={`banner-voucher-${key}`}
                                                className={classes.imgPicker}
                                            >
                                                <img
                                                    style={{ height: 100, width: 100, background: _.get(item, "background_color") || 'none' }}
                                                    alt="icon"
                                                    src={configValue.MEDIA_DOMAIN + _.get(item, 'banner.url')}
                                                />
                                            </label>
                                        </>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid item container xs={8}>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item xs={3} className={classes.textLabelForm}>
                                            <p>Cài đặt màu nền:</p>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ColorPicker inputValues={inputValues} setInputValues={setInputValues} index={key} isBackgroundColor={true} isEventVouchers={true} />
                                        </Grid>
                                        <Grid item xs={3} className={classes.textLabelForm}>
                                            <p>Cài đặt màu chữ:</p>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ColorPicker inputValues={inputValues} setInputValues={setInputValues} index={key} isBackgroundColor={false} isEventVouchers={true} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                ))
            }
            <Grid item>
                <IconButton onClick={() => addVoucherField()}>
                    <AddCircleOutline style={{ fill: "blue" }} /><span style={{ paddingLeft: "10px" }}>Thêm mới Voucher</span>
                </IconButton>
            </Grid>


        </div>
    );
}

export default PromotionCreateVoucher
