import React from 'react';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const ModalCreateEmployee = ({ openModalCreateEmployeeTeam, setOpenModalCreateEmployeeTeam }) => {

    const handleClose = () => {
        setOpenModalCreateEmployeeTeam(false);
    };

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            outline: 'none'
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            outline: 'none',
            display: 'flex',
            width: '760px',
            flexDirection: 'column',
            alignItems: 'center',
        },
        buttonConfirm: {
            color: '#fff',
            borderRadius: 0,
            padding: '6px 36px',
            margin: '0 10px'
        },
    }));
    const classes = useStyles();
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModalCreateEmployeeTeam}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 1000,
            }}
        >
            <Fade in={openModalCreateEmployeeTeam}>
                <div className={classes.paper}>
                    <p id="transition-modal-description" style={{ fontWeight: 'bolder' }}>Bạn có muốn tạo nhóm không?</p>
                    <Grid container direction="row" justifyContent="center">
                        <Button style={{ backgroundColor: '#07807D' }} className={classes.buttonConfirm}>
                            Có
                    </Button>
                        <Button style={{ backgroundColor: 'black' }} className={classes.buttonConfirm} onClick={handleClose}>
                            Không
                    </Button>
                    </Grid>

                </div>
            </Fade>
        </Modal>
    )
}

export default ModalCreateEmployee
