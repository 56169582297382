// Table
import * as _ from "lodash";
import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import PropTypes from "prop-types";
import clsx from "clsx";

import { Link, useHistory } from "react-router-dom";

import DeleteIcon from "@material-ui/icons/Delete";

import { lighten, makeStyles } from "@material-ui/core/styles";
import { formatPhone } from "../../../helper";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 80px)",
    padding: "0 30px",
    position: "relative",
  },
  headerStyle: {
    background: "#F5F5F5",
    "& .styleCenter": {
      textAlign: 'center',
    },
    "& th > span": {
      fontWeight: "bold",
      color: "#000",
    },
    "& th > .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "#000",
      "& .MuiTableSortLabel-icon": {
        color: "#000 !important",
      },
    },
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    '& .styleCenter': {
      textAlign: 'center',
      '& > span': {
        margin: 'auto',
      }
    }
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  rowStyle: {
    position: "relative",
    zIndex: 5,
  },
  checkboxStyle: {
    background: "green",
    position: "relative",
    zIndex: 9,
  },
  nodataStyle: {
    color: "#E27F35",
    fontSize: "16px",
    fontWeight: "bold",
  },
  bingoRewardStatus: {
    fontWeight: "bold",
    cursor: "pointer",
    display: "flex",
  },
}));

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.headerStyle}>
      <TableRow>
        {_.map(headCells, (item, key) => (
          <TableCell
            key={key}
            padding={item.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === item.id ? order : false}
            className={clsx(_.get(item,'center') && 'styleCenter')}
          >
            {item.label}
            {/* <TableSortLabel
                            active={orderBy === item.id}
                            direction={orderBy === item.id ? order : 'asc'}
                            onClick={createSortHandler(item.id)}
                        >
                          
                            {orderBy === item.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list"></IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const TableComponent = ({
  data,
  count,
  isRowClick,
  onRowClick,
  headMenu = [],
  numRowsPerPage = 10,
  loading,
  setSkip,
  page,
  setPage,
}) => {
  let headCells = [];
  if (_.isEmpty(headMenu) && !_.isEmpty(data)) {
    headCells = _.map(_.keys(_.head(data)), (value, key) => {
      return { id: value, numeric: false, disablePadding: false, label: value };
    });
  } else {
    headCells = headMenu;
  }

  const orderDefault = _.get(_.head(headCells), "id");
  const classes = useStyles();
  const history = useHistory();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(orderDefault);
  const [selected, setSelected] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(numRowsPerPage);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = _.map(data, (item) => _.get(item, orderDefault));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const geteKYCStatus = (status) => {
    if (_.upperCase(status) === 'NULL' || _.upperCase(status) === 'INITIAL') {
      return ''
    } else if (_.upperCase(status) === 'VERIFIED') {
      return 'Đã xác nhận'
    } else if (_.upperCase(status) === 'REJECT') {
      return 'Đã từ chối'
    } else {
      return 'Đang chờ xác nhận'
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSkip(newPage * rowsPerPage - rowsPerPage);
  };

  const isSelected = (code) => selected.indexOf(code) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  if (loading) return <CircularProgress />;

  return (
    <Fragment>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={count}
            headCells={headCells}
          />
          <TableBody>
            {data.length > 0 ? (
              <Fragment>
                {data.map((row, index) => {
                  const isItemSelected = isSelected(_.get(row, orderDefault));
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      // component={isRowClick ? undefined : Link}
                      // to={`/customer/detail/${_.get(row, "id")}`}
                      onClick={() => isRowClick ? undefined : history.push(`/customer/detail/${_.get(row, "id")}`)}
                      hover
                      style={{ textDecoration: "none", cursor: isRowClick ? undefined : "pointer" }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={_.get(row, "id")}
                      selected={isItemSelected}
                    >
                      {_.map(headCells, (value, key) => (
                        <TableCell key={key} className={clsx(_.get(value,'center') && 'styleCenter')}>
                          {value.id === "status" ? (
                            _.get(row, value.id) === true ? (
                              <span
                                style={{
                                  width: 20,
                                  height: 20,
                                  borderRadius: 3,
                                  backgroundColor: "#00CE00",
                                  display: "block",
                                }}
                              ></span>
                            ) : (
                              <span
                                style={{
                                  width: 20,
                                  height: 20,
                                  borderRadius: 3,
                                  backgroundColor: "red",
                                  display: "block",
                                }}
                              ></span>
                            )
                          ) : value.id === "phone" ? (
                            formatPhone({
                              phone: _.get(row, value.id),
                              type: "first",
                            })
                          ) : //   bingo result status
                            value.id === "exchange_status" ? (
                              <div
                                className={classes.bingoRewardStatus}
                                onClick={() => onRowClick(row)}
                              >
                                <span
                                // style={{
                                //   width: 20,
                                //   height: 20,
                                //   borderRadius: 3,

                                //   backgroundColor: "#00CE00",
                                //   display: "block",
                                // }}
                                ></span>
                                {_.get(row, value.id)}
                              </div>
                            ) : value.id === "eKYC" ? (
                              <div
                              // className={classes.bingoRewardStatus}

                              >
                                {geteKYCStatus(_.get(row, value.id))}
                              </div>
                            ) : (
                              _.get(row, value.id)
                            )}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </Fragment>
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={12}>
                  <Typography className={classes.nodataStyle}>
                    Không có dữ liệu thích hợp
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {/* {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )} */}
          </TableBody>
        </Table>
      </TableContainer>

      {/*
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      */}
      <Pagination
        count={Math.ceil(count / numRowsPerPage)}
        onChange={handleChangePage}
        page={page}
        variant="outlined"
        shape="rounded"
        style={{ margin: "16px 16px", paddingLeft: "35%" }}
        showLastButton
        showFirstButton
        defaultPage={1}
      />
    </Fragment>
  );
};
export default TableComponent;
