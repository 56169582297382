import gql from "graphql-tag";

export const GET_CODE_REQUEST = gql`
    query product_request {
        type {
            id
            code
            name
        }
    }
` 

export const GET_TYPE_STEP = gql`
    query product_request($type: String!) {
        initStep: type_workflow(where: {typeByType: {code: {_eq: $type}}, init_step: {_eq: true}} ) {
            type_step {
                id
                code
                name
            }
        }
    }
`;

export const GET_DETAIL_PRODUCT = gql`
fragment addressData on address {
  short
  street: streetByStreet {
    id
    name
  }
  ward: wardByWard {
    id
    name
  }
  district: districtByDistrict {
    id
    name
  }
  province: provinceByProvince {
    id
    name
  }
}
query GetProduct($productId:uuid!) {
  product: product_by_pk(id:$productId) {
    id
    code
    created
    title
    map
    branch: branchByBranch {
      id
      code
      name
    }
    price_to_display
    price_unit: priceUnitByPriceUnit {
      id
      name
    }
    area
    area_used
    area_height 
    area_width 
    bath_room
    bed_room
    basement
    entrance_dist
    frontways
    address: addressByAddress {
      ...addressData
    }
    orientation: orientationByOrientation {
      id
      name
    }
    exclusive
    urgent
    publish
    listing_type: listingTypeByListingType {
      id
      name
    }
    legal_type: legalTypeByLegalType {
      id
      name
    }
    property_type: propertyTypeByPropertyType {
      id
      name
    }
    product_detail: productDetailByProductDetail {
      description
      plan_info
      outstanding_advantages
      furniture_info
      street_view
      view_3d_url
      video_url
    }
    project: projectByProject {
        id
        code
        name
        investment
        investment_unit: priceUnitByPriceUnit {
        id
        name
        }
        start_date
        taking_over
        total_appartments
        total_blocks
        project_investor:investorByInvestor {
            id
            name
            website
            logo:investorLogoByMedia {
                id
                url
            }
        }
        project_constructor:constructorByConstructor {
            id
            name
        }
        project_detail: projectDetailByProjectDetail{
            project_design: projectDesignByProjectDesign{
                id
                name
            }
            project_management : projectManagementByProjectManagement{
                id
                name
            }
            investment_cooperation : investmentCooperationByInvestmentCooperation{
                id
                name
            }
        }
    }
    product_utilities {
      utility {
        id
        name
        type
        utilities_type {
          id
          name
          code
        }
      }
    }
    furnitures: product_furnitures {
      furniture: furnitureByFurniture {
        id
        name
        room_type: roomTypeByRoomType {
            id
            code
            name
        }
      }
    }
    product_tags: product_tags {
      tag: tagByTag {
        id
        value
      }
    }
    product_medias : property_media {
      media : propertyMediaDetail {
        id
        name
        url
        media_type {
          code
          name
        }
      }
    }
    avatar: productAvatarByMedia {
      id
      name
      url
    }
  }
}

`