import * as _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonGroup, Button } from "@material-ui/core";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  bottomWrapper: {
    overflowX: "auto",
    cursor: 'pointer',
    "&::-webkit-scrollbar": {
      width: "0px",
    },
  },
  tabPanelStyle: {
    background: "none",
    boxShadow: "none",
  },
  buttonNavigation: {
    borderRadius: 0,
    color: "#ffffff",
    whiteSpace: "nowrap",
    padding: "5px 50px",
  },
}));

const ScrollableTabs = (props) => {
  const { list } = props;
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [isActive, setIsActive] = useState(
    window.localStorage.getItem("activeMenuProduct")
      ? localStorage.getItem("activeMenuProduct")
      : _.get(_.head(list), "active") || "detailProduct"
  );

  if (_.isEmpty(list)) return null;

  return (
    <div className={classes.root}>
      <div className={classes.bottomWrapper}>
        <ButtonGroup>
          {_.map(
            list,
            (item, key) =>
              item && (
                <Button
                  className={classes.buttonNavigation}
                  key={key}
                  onClick={() => {
                    setIsActive(_.get(item, "active"));
                    localStorage.removeItem("kindCartShow");
                    localStorage.setItem(
                      "activeMenuProduct",
                      _.get(item, "active")
                    );
                  }}
                  style={{
                    // whiteSpace: 'nowrap',
                    // padding: '5px 50px',
                    backgroundColor:
                      isActive === _.get(item, "active")
                        ? "#E27F35"
                        : "#2C8A81",
                  }}
                  {...a11yProps(_.get(item, "value"))}
                >
                  {_.get(item, "navigationTitle")}
                </Button>
              )
          )}
        </ButtonGroup>
      </div>
      {_.map(list, (item, key) => {
        return (
          <Fragment key={_.get(item, "value") || key}>
            {_.get(item, "active") === isActive &&
              _.isFunction(_.get(item, "content")) &&
              _.get(item, "content")(props)}
          </Fragment>
        );
      })}
    </div>
  );
};
export default ScrollableTabs;
