import gql from "graphql-tag";
import { CORE_SCHEMA_FIELDS } from "../schemas/product/fragments";

export const INSERT_SECONDARY_PRODUCT = gql`
    ${CORE_SCHEMA_FIELDS}
    mutation CreateProductConsign($data: ProductConsignCreateInput!) {
        productConsignCreate(args: $data) {
            id  
            customer
            incharge
            productByProduct {
                id
                code
                title
                description
                remark
                price_to_display
                price_unit: priceUnitByPriceUnit {
                    id
                    name
                }
                area
                area_used
                area_height
                area_width
                bath_room
                bed_room
                basement
                floor
                entrance_dist
                frontways
                commission
                area_foor
                area_construction
                address: addressByAddress {
                    ...addressData
                }
                orientation: orientationByOrientation {
                    id
                    name
                }
                exclusive
                urgent
                publish
                listing_type: listingTypeByListingType {
                    id
                    name
                }
                property_type: propertyTypeByPropertyType {
                    id
                    name
                }
                avatar: productAvatarByMedia {
                    id
                    name
                    url
                }
            }
        }
    }
`;

export const EDIT_SECONDARY_PRODUCT = gql`
    mutation productUpdate($data: ProductCreateInput!) {
        result: productUpdate(args: $data) {
            affected_rows
        }
    }
`;
