import { useQuery } from "@apollo/client";
import { makeStyles, Paper } from "@material-ui/core";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import ScrollableTabs from "../../../components/ScrollableTabs";
import { GET_PROFILE } from "../../../graphql/schemas/account/accountQueries";
import { useGetEventDetail } from "../hooks";
import BingoGameDetail from "./BingoGame/BingoGame";
import Customers from "./Customers";
import JackGameDetail from "./JackGame/JackGame";
import ShakeGameDetail from "./ShakingGame/ShakingGame";
import TransactionHistory from "./Transaction";
import UpdateHistory from "../updatehistory/UpdateHistory";
import VoucherDetail from "./Voucher/VoucherDetail";
import ResultTab from "./BingoGame/ResultTab";
import { checkedPermission } from "../../../helper";
import { StoreContext } from "../../../resources/store";
import { UPDATE_PROMOTION } from "../../../constants/defined";
import AccessDenied from "../../Permission/AccessDenied";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "30px",
  },
  actionStyle: {
    display: "grid",
    gridTemplate: "auto / auto auto",
    justifyContent: "flex-end",
    textAlign: "right",
    "& button": {
      margin: "0 15px",
    },
  },
}));

const GameDetail = (props) => {
  const [gameType, setGameType] = useState("");
  const { id } = useParams();
  const { loading, data } = useGetEventDetail(id);
  const { loading: loadingGetProfile, data: createdBy } = useQuery(
    GET_PROFILE,
    {
      variables: {
        id: _.get(data, "created_by"),
      },
      skip: !_.get(data, "created_by"),
    }
  );

  const classes = useStyles();

  useEffect(() => {
    setGameType(_.get(data, "type_info.code"));
  }, [data]);

  useEffect(() => {
    if (id) {
      window.localStorage.setItem('event', id);
    }
  }, []);

  const getGameCpn = () => {
    if (gameType === "JACK") {
      return (
        <JackGameDetail
          data={data}
          loading={loading || loadingGetProfile}
          createdBy={_.get(createdBy, "results")}
        />
      );
    }

    if (gameType === "SHAKE") {
      return (
        <ShakeGameDetail
          data={data}
          loading={loading || loadingGetProfile}
          createdBy={_.get(createdBy, "results")}
        />
      );
    }

    if (gameType === "BINGO") {
      return (
        <BingoGameDetail />
      );
    }

    if (gameType === "VOUCHER") {
      return (
        <VoucherDetail
          loading={loading || loadingGetProfile}
          data={data}
        />
      );
    }
  };

  const LISTS = [
    {
      value: 0,
      navigationTitle: "Thông tin chương trình",
      content: () => getGameCpn(),
      active: "gameDetail",
    },
    {
      value: 1,
      navigationTitle: "Danh sách khách hàng",
      content: () => <Customers gameType={gameType} />,
      active: "customerList",
    },
    {
      value: 2,
      navigationTitle: "Lịch sử cập nhật",
      content: () => <UpdateHistory />,
      active: "updateHistoryList",
    },
    {
      value: 3,
      navigationTitle: "Lịch sử giao dịch",
      content: () => (
        <TransactionHistory
          loading={loading}
          gameType={gameType}
          eventGifts={
            gameType === "BINGO"
              ? _.map(_.get(data, "bingo_results"), (item) => item)
              : _.map(_.get(data, "event_gifts"), (item) => _.get(item, "gifts"))
          }

        />
      ),
      active: "transactionHistory",
    },
    gameType === "BINGO"
      ? {
        value: 4,
        navigationTitle: "Kết quả",
        content: () => (
          <ResultTab
            id={id}
            // startDate={_.get(data, "start")}
            // endDate={_.get(data, "end")}
            bingoResultSettings={
              _.map(
                JSON.parse(JSON.stringify(_.get(data, "bingo_result_settings"))), (item) => {
                  delete item.__typename;
                  return item;
                }
              )
            }
          />
        ),
        active: "result",
      }
      : null,
  ];

  return (
    <>
      <Paper className={classes.root}>
        <ScrollableTabs {...props} list={LISTS} />
      </Paper>
    </>

  );
};

export default GameDetail;
