//content tab Info Product
import * as _ from 'lodash';
import React, { Fragment, useEffect, useState, useCallback, useContext } from 'react';
import { useHistory, useParams, Redirect, Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    Card,
    CardActionArea,
    CardMedia,
    Box,
    Button,
    Paper,
    TextField,
    Divider,
    MenuItem, FormControl, Select,
    FormLabel, RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';

import HeightIcon from '@material-ui/icons/Height';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import KingBedIcon from '@material-ui/icons/KingBed';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BathtubIcon from '@material-ui/icons/Bathtub';

import Desc from '../../../components/product/Desc';

// import tab
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ButtonReturn from '../../../components/ButtonReturn';
import { Edit, TouchApp } from '@material-ui/icons';
import swal from 'sweetalert';

import { useQuery, useMutation } from '@apollo/client';
import { useGetProductDetail, useGetWorkflowNextStep } from '../../../graphql/schemas/hooks';
import { GET_PRODUCT_STATUS_BY_CODE, PRODUCT_FIND_ALL } from '../../../graphql/schemas/product/productQueries';
import slugs from '../../../resources/slugs';
import { UPDATE_WORKFLOW_PRODUCT, UPDATE_LOCK_PRODUCT, UPDATE_HIDDEN_PRODUCT, UPDATE_SOLD_STATUS_PRODUCT } from '../../../graphql/mutation/updateWorlflow';
import InputNextStepDropdown from './components/InputNextStepDropdown';
import clsx from 'clsx';
import { checkedPermission, formatPhone } from '../../../helper';
import ImageViewer from "react-simple-image-viewer";
import PlanningInformation from '../../../components/product/PlanningInformation';
import { ConfigContext } from '../../../context/ConfigContext';
import { StoreContext } from '../../../resources/store';

import { UPDATE_SECONDARY, USER_ROLE_SUPPER_ADMIN } from '../../../constants/defined';


const useStyles = makeStyles((theme) => ({
    wapperLoading: {
        width: '100%',
        height: 'calc(100vh - 113px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        backgroundColor: '#C4C4C4',
        padding: '10px 30px',
        width: '100%',
        '& p': {
            fontWeight: 'bold'
        }
    },
    infoCustomer: {
        height: '200px',
        '& .MuiGrid-item': {
            marginLeft: 120,
        },
        '& .MuiGrid-item:nth-child(2), & .MuiGrid-item:nth-child(3)': {
            marginBottom: 20
        },
        '& .MuiGrid-item:first-child': {
            marginLeft: 0,
            '& > p': {
                fontWeight: 'bold',
                '& > span': {
                    marginRight: '10px'
                }
            }
        }
    },
    customerTitle: {
        fontSize: 12,
        marginBottom: 0
    },
    root: {
        width: 1500,
        height: 500,
        boxShadow: 'none'
    },
    media: {
        objectFit: 'cover !important',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    carousel: {
        '& div': {
            height: '400px',
            position: 'relative',
            margin: 10,
            width: '100%',
        }
    },
    detailProduct: {
        marginLeft: '10px'
    },
    cardRoot: {
        width: '54%',
        height: 300,
        marginLeft: 80,
        marginTop: 50,
        borderRadius: 20
    },
    boxService: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20
    },
    iconBox: {
        padding: 8,
        marginRight: 20,
        borderRadius: 10,
        backgroundColor: '#F5F5F5',
        border: '1px solid #E2E1E1'
    },
    antTabs: {
        '& > .MuiTabs-fixed': {
            width: 800,
            '& > .MuiTabs-flexContainer': {
                display: 'flex',
                justifyContent: 'space-between'
            }
        }
    },
    flexBox: {
        display: 'flex',
        alignItems: 'center',
        margin: 10
    },
    buttonStatusCheck: {
        backgroundColor: '#2C8A81',
        color: '#ffffff',
        borderRadius: '0',
        marginLeft: 10
    },
    boxInfoCheck: {
        margin: 10,
        alignItems: 'center',
        '& .MuiGrid-item:nth-child(1)': {
            width: 250
        }
    },
    message: {
        border: 'none'
    },
    note: {
        borderBottomLeftRadius: '10px',
        borderTopLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        borderTopRightRadius: '0',
        boxShadow: '1px 5px 7px lightgrey',
        padding: '8px',
        width: '400px',
    },
    marginDivider: {
        margin: '30px 0',
    },
    groupButtonFooter: {
        justifyContent: "flex-end",
        '& > button': {
            padding: "6px 30px",
            borderRadius: 0,
        },
        '& > button:not(:last-child)': {
            marginRight: '30px',
        }
    },
    dialogChangeStatus: {
        '& .MuiDialog-container': {
            '& .MuiDialog-paper': {
                padding: '30px 260px',
                '& .MuiDialogActions-root': {
                    display: 'flex',
                    justifyContent: 'center'
                }
            }
        }
    },
    buttonDialog: {
        color: '#ffffff',
        borderRadius: 0,
        width: '120px',
        margin: '0 20px'
    },
    titleImage: {
        margin: '10px 0px',
        display: 'block',
        fontWeight: 'bold'
    },
    formControl: {
        width: 250,
    },
    defaultPadding: {
        '&>div': {
            padding: '10.5px 14px',
        },
    },
}));

const CustomRadioGroup = withStyles((theme) => ({
    root: {
        flexDirection: 'row',
    }
}))((props) => <RadioGroup {...props} />);

const CustomRadio = withStyles((theme) => ({
    root: {
        color: '#00837B',
        '&$checked': {
            color: '#00837B',
        },
    },
    checked: {},
}))((props) => <Radio color="default" {...props} />);

const InfoProduct = () => {
    const { id } = useParams();
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles();

    const { userInfo, accessObjectPermissions } = useContext(StoreContext);

    const { data: productDetail, loading: productLoading, refetch: refetchDetail } = useGetProductDetail({ productId: id });
    const isWaitingApprove = _.toUpper(_.get(productDetail, 'requests[0].type_workflow.type_step.code')) === 'WAITINGAPPROVED';
    const { data: dataNextStep } = useGetWorkflowNextStep({ id: _.get(productDetail, 'requests[0].current_step') });
    const [value, setValue] = useState(0);
    const [status, setStatus] = useState(null);
    const [statusNextStep, setStatusNextStep] = useState(null);
    const [isViewerImagePersonalId, setIsViewerImagePersonalId] = useState({ view: false, key: 0 });
    const [isViewerImageContract, setIsViewerImageContract] = useState({ view: false, key: 0 });

    const configValue = useContext(ConfigContext);

    const [updateWorkflow] = useMutation(UPDATE_WORKFLOW_PRODUCT, {
        refetchQueries: [{
            query: PRODUCT_FIND_ALL, variables: {
                skip: 0,
                take: 10,
                order_by: { created: "desc" },
                condition: {}
            }
        }],
    });

    const [lockProduct] = useMutation(UPDATE_LOCK_PRODUCT, {
        refetchQueries: [{
            query: PRODUCT_FIND_ALL, variables: {
                skip: 0,
                take: 10,
                order_by: { created: "desc" },
                condition: {}
            }
        }],
    });

    const [hiddenProduct] = useMutation(UPDATE_HIDDEN_PRODUCT, {
        refetchQueries: [{
            query: PRODUCT_FIND_ALL, variables: {
                skip: 0,
                take: 10,
                order_by: { created: "desc" },
                condition: {}
            }
        }],
    });

    const [statusSoldProduct] = useMutation(UPDATE_SOLD_STATUS_PRODUCT, {
        refetchQueries: [{
            query: PRODUCT_FIND_ALL, variables: {
                skip: 0,
                take: 10,
                order_by: { created: "desc" },
                condition: {}
            }
        }],
    });


    const { data: productStatus } = useQuery(GET_PRODUCT_STATUS_BY_CODE, { variables: { code: "SOLD" } });

    var items = _.get(productDetail, 'product_medias')

    const AntTabs = withStyles({
        indicator: {
            backgroundColor: '#DA6744',
        },
    })(Tabs);

    const AntTab = withStyles((theme) => ({
        root: {
            textTransform: 'none',
            minWidth: 72,
            fontWeight: theme.typography.fontWeightRegular,
            marginRight: theme.spacing(4),
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
                color: '#40a9ff',
                opacity: 1,
            },
            '&$selected': {
                color: '#2F435D',
                fontWeight: theme.typography.fontWeightMedium,
            },
            '&:focus': {
                color: '#2F435D',
            },
        },
        selected: {},
    }))((props) => <Tab disableRipple {...props} />);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function Item({ item }) {
        return (
            <Card className={classes.root}>
                <CardMedia
                    className={classes.media}
                    image={configValue.MEDIA_DOMAIN + _.replace(_.get(item, 'media.url'), "mqdefault.jpg", "maxresdefault.jpg")}
                    title="Paella dish"
                />
            </Card>
        )
    }

    function a11yProps(index) {
        return {
            id: `scrollable-force-tab-${index}`,
            'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-force-tabpanel-${index}`}
                aria-labelledby={`scrollable-force-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    const Approve = () => {
        const requestId = _.get(_.head(_.get(productDetail, 'requests')), 'id');
        const typeWorkflow = _.get(_.head(_.get(productDetail, 'requests')), 'type_workflow.type');
        const accountId = _.get(userInfo, 'id');
        const nextStepId = statusNextStep;

        try {
            updateWorkflow({
                variables: {
                    data: {
                        request_id: requestId,
                        type: typeWorkflow,
                        current_step: nextStepId,
                        account: accountId
                    }
                }
            }).then(res => {
                swal("Thông báo", "Xét duyệt sản phẩm thành công!", "success")
                    .then(() => {
                        history.push(slugs.productSecondary);
                        refetchDetail();
                    });
            }, (error) => {
                swal("Vui lòng thử lại", "Có lỗi khi xét duyệt sản phẩm!", "error");
            })

        } catch (error) { }
    };

    const UpdateStatusSoldProduct = (remark) => {
        const id = _.get(productDetail, 'id');
        const accountId = _.get(userInfo, 'id');

        try {
            statusSoldProduct({
                variables: {
                    id,
                    product_status: statusNextStep,
                    remark: remark || "Không có lý do",
                    updated_by: accountId
                }
            }).then(res => {
                swal("Thông báo", "Chuyển trạng thái sản phẩm thành công!", "success")
                    .then(() => {
                        history.push(slugs.productSecondary);
                        refetchDetail();
                    });
            }, (error) => {
                swal("Vui lòng thử lại", "Có lỗi khi chuyển trạng thái sản phẩm!", "error");
            })

        } catch (error) { }
    };

    const UpdateHiddenProduct = (remark) => {
        const id = _.get(productDetail, 'id');
        const accountId = _.get(userInfo, 'id');

        try {
            hiddenProduct({
                variables: {
                    id,
                    deleted: status === "HIDDEN",
                    remark: remark || "Không có lý do",
                    updated_by: accountId
                }
            }).then(res => {
                swal("Thông báo", "Ẩn/hiện sản phẩm thành công!", "success")
                    .then(() => {
                        history.push(slugs.productSecondary);
                        refetchDetail();
                    });
            }, (error) => {
                swal("Vui lòng thử lại", "Có lỗi khi ẩn/hiện sản phẩm!", "error");
            })

        } catch (error) { }
    };

    const UpdateLockProduct = (remark) => {
        const id = _.get(productDetail, 'id');
        const accountId = _.get(userInfo, 'id');

        try {
            lockProduct({
                variables: {
                    id,
                    active: status === 'LOCKED' ? false : status === 'UNLOCK' ? true : undefined,
                    deleted: false,
                    status: status === 'UNLOCK' ? _.get(productDetail, 'productStatusByProductStatus.code') : undefined,
                    remark: remark || "Không có lý do",
                    updated_by: accountId
                }
            }).then(res => {
                swal("Thông báo", "Khóa/Mở khóa sản phẩm thành công!", "success")
                    .then(() => {
                        history.push(slugs.productSecondary);
                        refetchDetail();
                    });
            }, (error) => {
                swal("Vui lòng thử lại", "Có lỗi khi khóa/mở khóa sản phẩm!", "error");
            })

        } catch (error) { }
    };

    const handleClickApprove = () => {
        const idSoldStatus = _.get(_.head(_.get(productStatus, 'result')), 'id');
        if (_.isNull(status) && _.isNull(statusNextStep)) {
            swal("Chưa chọn trạng thái.", "Hãy chọn trạng thái xét duyệt và thử lại!", "info");
            return;
        };

        swal({
            title: _.includes(['SHOW', 'HIDDEN'], status) ? "Ẩn/Hiện sản phẩm!"
                : _.includes(['LOCKED', 'UNLOCK'], status) ? "Khóa/Mở khóa sản phẩm!"
                    : statusNextStep === idSoldStatus ? "Chuyển trạng thái sang Đã bán"
                        : "Xét duyệt sản phẩm!",
            content: (_.includes(['SHOW', 'HIDDEN', 'LOCKED', 'UNLOCK'], status) || (statusNextStep === idSoldStatus)) ? "input" : undefined,
            icon: "info",
            buttons: {
                confirm: 'Chấp nhận',
                cancel: 'Bỏ qua'
            }
        }).then((value) => {
            if (value === "") {
                swal("Chưa nhập lý do!", "Hãy Vui lòng nhập lý do và thử lại!", "info")
                    .then(() => { return });
            }

            if (value) {
                if (_.includes(['SHOW', 'HIDDEN'], status)) {
                    UpdateHiddenProduct(value);
                } else if (_.includes(['LOCKED', 'UNLOCK'], status)) {
                    UpdateLockProduct(value);
                } else if (statusNextStep === idSoldStatus) {
                    UpdateStatusSoldProduct(value)
                } else {
                    Approve();
                }
            } else {
                return;
            }
        });
    };

    const listImagePersonalId = _.filter(_.get(productDetail, 'product_media'), (element) => _.get(element, 'media_detail.media_type.code') === "PERSONALID");

    const listImageContract = _.filter(_.get(productDetail, 'product_media'), (element) => _.get(element, 'media_detail.media_type.code') === "CONTRACT");

    const listImageDocument = _.filter(_.get(productDetail, 'product_media'), (element) => _.get(element, 'media_detail.media_type.code') === "DOCUMENT");

    useEffect(() => {
        if (_.get(productDetail, 'active') === false) {
            setStatus("LOCKED")
        } else {
            if (_.get(productDetail, 'deleted')) {
                setStatus("HIDDEN")
            }
        }
    }, [productLoading]);

    if (productLoading) return (
        <div className={classes.wapperLoading}>
            <CircularProgress />
        </div>
    )

    return (
        <Fragment>
            <Grid container direction="column">
                <Grid container direction="row">
                    <Grid item className={classes.title} style={{ marginTop: '50px' }}>
                        <Typography>Thông tin Khách hàng/ Chủ nhà</Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" className={classes.infoCustomer}>
                    <Grid item>
                        <Typography>
                            <span>Mã Khách Hàng:</span> <span onClick={() => history.push(window.open('/customer/detail/' + _.get(productDetail, 'customer.customers[0].id')))} style={{ textDecoration: 'underline', cursor: 'pointer' }}>{_.get(productDetail, 'customer.code', '')}</span>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.customerTitle}>Họ và tên khách hàng: </Typography>
                        <Typography variant="h5">{_.get(productDetail, 'customer.full_name', '')}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.customerTitle}>Số điện thoại: </Typography>
                        <Typography variant="h5">{formatPhone({ phone: _.get(productDetail, 'customer.phone', ''), type: 'first' })}</Typography>
                    </Grid>
                </Grid>
                <Grid container direction="column">
                    <Grid item className={classes.title}>
                        <Typography>Thông tin sản phẩm</Typography>
                    </Grid>
                    <Grid container className={classes.carousel}>
                        <Carousel
                            indicatorContainerProps={{
                                style: {
                                    position: 'absolute',
                                    top: 362,
                                    color: '#ECECEC',
                                    height: '20px'
                                }
                            }}
                            activeIndicatorIconButtonProps={{
                                style: {
                                    backgroundColor: '#FFFFFF',
                                    color: '#FFFFFF',
                                }
                            }}>
                            {
                                _.map(items, (item, i) => (<Item key={i} item={item} />))
                            }
                        </Carousel>
                    </Grid>
                    <Grid container direction="row" style={{ paddingLeft: 80 }}>
                        <Grid item xs={6}>
                            <span className={clsx(classes.titleImage)}>Hình ảnh CMND</span>
                            <Grid container direction="row" >
                                {_.map(listImagePersonalId, (element, key) => {
                                    return (
                                        <Grid item key={key}>
                                            <CardActionArea
                                                className={classes.cardActionArea}
                                                disableRipple
                                                onClick={(e) => setIsViewerImagePersonalId({ view: true, key: key })}
                                            >
                                                <img src={configValue.MEDIA_DOMAIN + _.get(element, 'media_detail.url')} alt="Image Personal Id" width="300" style={{ paddingRight: 10, width: "300" }} />
                                            </CardActionArea>
                                            {isViewerImagePersonalId?.view && (
                                                <ImageViewer
                                                    src={_.map(listImagePersonalId, (element) => configValue.MEDIA_DOMAIN + _.get(element, 'media_detail.url'))}
                                                    onClose={() => setIsViewerImagePersonalId({ view: false, key: 0 })}
                                                    backgroundStyle={{
                                                        backgroundColor: "rgba(0,0,0,0.9)",
                                                        zIndex: "9999"
                                                    }}
                                                    currentIndex={isViewerImagePersonalId?.key}
                                                />
                                            )}
                                        </Grid>
                                    )
                                }) || '-'}
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <span className={clsx(classes.titleImage)}>Hình ảnh HĐMB ký với KH</span>
                            <Grid container direction="row">
                                {_.map(listImageContract, (element, key) => {
                                    return (
                                        <Grid item key={key}>
                                            <CardActionArea
                                                className={classes.cardActionArea}
                                                disableRipple
                                                onClick={() => setIsViewerImageContract({ view: true, key: key })}
                                            >
                                                <img src={configValue.MEDIA_DOMAIN + _.get(element, 'media_detail.url')} alt="Image Personal Id" width="300" style={{ paddingRight: 10 }} />
                                            </CardActionArea>
                                            {isViewerImageContract?.view && (
                                                <ImageViewer
                                                    src={_.map(listImageContract, (element) => configValue.MEDIA_DOMAIN + _.get(element, 'media_detail.url'))}
                                                    onClose={() => setIsViewerImageContract({ view: false, key: 0 })}
                                                    backgroundStyle={{
                                                        backgroundColor: "rgba(0,0,0,0.9)",
                                                        zIndex: "9999"
                                                    }}
                                                    currentIndex={isViewerImageContract?.key}
                                                />
                                            )}
                                        </Grid>
                                    )
                                }) || '-'}
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={6}>
                            <span className={clsx(classes.titleImage)}>Tài liệu</span>
                            <Grid container direction="row">
                                {_.map(listImageDocument, (element) => {
                                    return (
                                        <Grid item>
                                            <img src={configValue.MEDIA_DOMAIN + element.media_detail.url} alt="Image Personal Id" width="300" style={{ paddingRight: 10 }}  />
                                        </Grid>
                                    )
                                }) || '-'}
                            </Grid>
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid container direction="row" className={classes.detailProductContainer}>
                    <Grid item style={{ marginLeft: 80 }}>
                        <Typography style={{ fontWeight: 'bold' }}>
                            {_.get(productDetail, 'title')}
                        </Typography>
                        <Typography style={{ color: '#B9B9B9', margin: '10px 0', fontSize: 12 }}>
                            {_.join(_.pull([_.get(productDetail, 'address.short', ''), _.get(productDetail, 'address.street.name', ''), _.get(productDetail, 'address.ward.name', ''), _.get(productDetail, 'address.district.name', ''), _.get(productDetail, 'address.province.name', '')], '', null), ', ')}
                        </Typography>
                        <Typography style={{ color: '#309A94', fontWeight: 'bold' }}>
                            {_.get(productDetail, "price_to_display") ? _.get(productDetail, "price_to_display") : _.get(productDetail, "price") || 0} {_.get(productDetail, "price_unit.name") || "Tỷ"}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container direction="row">
                    <Card variant="outlined" className={classes.cardRoot}>
                        <Grid container direction="row" style={{ padding: "20px 50px" }}>
                            <Grid item sm={6} className={classes.boxService} style={{ marginTop: '0' }}>
                                <Grid item>
                                    <div className={classes.iconBox}>
                                        <HeightIcon style={{ color: '#566F90' }} />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Typography style={{ fontSize: 12 }}>Chiều dài</Typography>
                                    <Typography>{_.get(productDetail, 'area_width') ? _.get(productDetail, 'area_width') + "m" : "0m"} </Typography>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} className={classes.boxService} style={{ marginTop: '0' }}>
                                <Grid item>
                                    <div className={classes.iconBox}>
                                        <HeightIcon style={{ color: '#566F90' }} />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Typography style={{ fontSize: 12 }}>Chiều ngang</Typography>
                                    <Typography style={{ color: '#0A2240' }}>{_.get(productDetail, 'area_height') ? _.get(productDetail, 'area_height') + "m" : "0m"}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} className={classes.boxService}>
                                <Grid item>
                                    <div className={classes.iconBox}>
                                        <BathtubIcon style={{ color: '#566F90' }} />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Typography style={{ fontSize: 12 }}>Phòng tắm</Typography>
                                    <Typography style={{ color: '#0A2240' }}>{_.get(productDetail, 'bath_room') ? _.get(productDetail, 'bath_room') + " phòng" : "0 phòng"}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} className={classes.boxService}>
                                <Grid item>
                                    <div className={classes.iconBox}>
                                        <KingBedIcon style={{ color: '#566F90' }} />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Typography style={{ fontSize: 12 }}>Phòng ngủ</Typography>
                                    <Typography style={{ color: '#0A2240' }}>{_.get(productDetail, 'bed_room') ? _.get(productDetail, 'bed_room') + " phòng" : "0 phòng"}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} className={classes.boxService}>
                                <Grid item>
                                    <div className={classes.iconBox}>
                                        <ApartmentIcon style={{ color: '#566F90' }} />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Typography style={{ fontSize: 12 }}>Số tầng</Typography>
                                    <Typography style={{ color: '#0A2240' }}>{_.get(productDetail, 'floor') ? _.get(productDetail, 'floor') + " tầng" : "0 tầng"}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} className={classes.boxService}>
                                <Grid item>
                                    <div className={classes.iconBox}>
                                        <LocalTaxiIcon style={{ color: '#566F90' }} />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Typography style={{ fontSize: 12 }}>Số tầng hầm</Typography>
                                    <Typography style={{ color: '#0A2240' }}>{_.get(productDetail, 'basement') ? _.get(productDetail, 'basement') + " tầng" : "0 tầng"}</Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Card>
                </Grid>
                <Grid container direction="row">
                    <AntTabs style={{ marginLeft: 120 }} className={classes.antTabs} value={value} onChange={handleChange} aria-label="ant example">
                        <AntTab label="Mô tả" {...a11yProps(0)} />
                        <AntTab label="Quy hoạch" {...a11yProps(1)} />
                        <AntTab label="Bản đồ" {...a11yProps(2)} />
                    </AntTabs>
                </Grid>
                <TabPanel index={0} value={value}>
                    <Grid container direction="column">
                        {value === 0 && (
                            <Grid container style={{ marginLeft: 80 }}>
                                {_.get(productDetail, 'description')}
                            </Grid>
                        )}
                        <Desc product={productDetail} />
                    </Grid>
                </TabPanel>

                <TabPanel index={1} value={value}>
                    <Typography style={{ fontWeight: 'bold', paddingBottom: '30px' }}>Quy hoạch</Typography>

                    <div>
                        <PlanningInformation data={_.get(productDetail, 'product_plan')} photos={listImageDocument} />
                    </div>
                </TabPanel>

                <Grid container direction="row" className={classes.boxInfoCheck}>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }}>Sản phẩm ECOE độc quyền: </Typography>
                    </Grid>
                    <Grid item>
                        <Typography >
                            {_.get(productDetail, 'exclusive') === true ? "Có" : "Không"}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" className={classes.boxInfoCheck}>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }}>Sản phẩm ECOE bán gấp: </Typography>
                    </Grid>
                    <Grid item>
                        <Typography >
                            {_.get(productDetail, 'urgent') === true ? "Có" : "Không"}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item className={classes.title} style={{ marginTop: '50px' }}>
                    <Typography>Thông tin kiểm duyệt</Typography>
                </Grid>
                <Grid container direction="column" style={{ marginLeft: 80, marginTop: 20 }}>
                    <Grid container direction="row" className={classes.boxInfoCheck}>
                        <Grid item>
                            <Typography style={{ fontWeight: 'bold' }}>Người gửi yêu cầu: </Typography>
                        </Grid>
                        <Grid item>
                            <Link
                                style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: 13, fontWeight: 'bold' }}
                                target="_blank"
                                title={_.get(_.head(_.get(productDetail, 'requests')), 'created_by.full_name')}
                                to={
                                    _.startsWith(_.get(_.head(_.get(productDetail, 'requests')), 'created_by.code'), 'C') ?
                                        `/customer/detail/${_.get(_.head(_.get(productDetail, 'customer.customers')), 'id')}`
                                        :
                                        `/employee/detail/${_.get(_.head(_.get(productDetail, 'requests')), 'created_by.id')}`
                                }
                            >
                                {_.get(_.head(_.get(productDetail, 'requests')), 'created_by.code')}
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" className={classes.boxInfoCheck}>
                        <Grid item>
                            <Typography style={{ fontWeight: 'bold' }}>NV phụ trách kiểm duyệt: </Typography>
                        </Grid>
                        <Grid item>
                            <Link
                                style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: 13, fontWeight: 'bold' }}
                                target="_blank"
                                title={_.get(productDetail, 'employee.full_name')}
                                to={`/employee/detail/${_.get(productDetail, 'employee.id')}`}
                            >
                                {_.get(productDetail, 'employee.code')}
                            </Link>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" className={classes.boxInfoCheck}>
                        <Grid item>
                            <Typography style={{ fontWeight: 'bold' }}>Văn phòng quản lý SP: </Typography>
                        </Grid>
                        <Grid item>
                            <Link
                                style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: 13, fontWeight: 'bold' }}
                                target="_blank"
                                title={_.get(productDetail, 'branch.name')}
                                to={`/branches/detail/${_.get(productDetail, 'branch.id')}`}
                            >
                                {_.get(productDetail, 'branch.code')}
                            </Link>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" className={classes.boxInfoCheck}>
                        <Grid item>
                            <Typography style={{ fontWeight: 'bold' }}>Mức hoa hồng cho ECOE: </Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontSize: 13, fontWeight: 'bold' }}>
                                {_.get(productDetail, 'commission') || 0}%
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" className={classes.boxInfoCheck}>
                        <Grid item>
                            <Typography style={{ fontWeight: 'bold' }}>Trạng thái sản phẩm <span style={{ color: 'red' }}>*</span>: </Typography>
                        </Grid>

                        <Grid item>
                            {
                                !_.includes(['SOLD','SOLD_OTHER'], _.get(productDetail, 'status'))
                                    ?
                                    (
                                        <FormControl component="fieldset">
                                            <CustomRadioGroup
                                                name="change-product-status"
                                                value={status || "SHOW"}
                                                onChange={(e) => {
                                                    if (!_.get(productDetail, 'deleted') && _.get(productDetail, 'active') && _.get(e, 'target.defaultValue') === "SHOW") {
                                                        setStatus(null);
                                                    } else {
                                                        setStatus(e.target.value);
                                                        setStatusNextStep(null);
                                                    }
                                                }}
                                            >
                                                <FormControlLabel
                                                    value="SHOW"
                                                    name='showOption'
                                                    disabled={!_.get(productDetail, 'active')}
                                                    control={<CustomRadio />}
                                                    label="Hiện"
                                                />

                                                <FormControlLabel
                                                    value="HIDDEN"
                                                    disabled={!_.get(productDetail, 'active') || _.get(productDetail, 'deleted')}
                                                    control={<CustomRadio />}
                                                    label="Ẩn"
                                                />

                                                <FormControlLabel
                                                    value="LOCKED"
                                                    disabled={!_.get(productDetail, 'active')}
                                                    control={<CustomRadio />}
                                                    label="Khóa"
                                                />

                                                <FormControlLabel
                                                    value="UNLOCK"
                                                    disabled={_.get(productDetail, 'active')}
                                                    control={<CustomRadio />}
                                                    label="Mở khóa"
                                                />
                                            </CustomRadioGroup>
                                        </FormControl>
                                    )
                                    :
                                    (
                                        <Typography style={{ fontWeight: 'bold' }}>
                                            {_.get(productDetail, 'productStatusByProductStatus.name')}
                                        </Typography>
                                    )
                            }
                        </Grid>
                    </Grid>

                    {
                        !_.includes(['SOLD','SOLD_OTHER'], _.get(productDetail, 'status')) && (
                            <Grid container direction="row" className={classes.boxInfoCheck}>
                                <Grid item></Grid>
                                <Grid item>
                                    <InputNextStepDropdown
                                        disabled={_.includes(["HIDDEN", 'LOCKED'], status) || !_.get(productDetail, 'active') || _.get(productDetail, 'deleted')}
                                        discription={_.get(productDetail, 'productStatusByProductStatus.name')}
                                        object={statusNextStep}
                                        setObject={setStatusNextStep}
                                        data={_.get(dataNextStep, 'type_workflow[0].typeWorkflowNextstepsByStep')}
                                        extendedSold={_.get(_.head(_.get(productStatus, 'result')), 'id')}
                                    />
                                </Grid>
                            </Grid>
                        )
                    }

                    <Grid container direction="row" style={{ marginTop: 40, display: 'flex', alignItems: 'center' }}>
                        <Grid item style={{ fontWeight: 'bold', width: 200 }}>
                            Ghi chú tin đăng:
                        </Grid>
                        <Grid item>
                            <Paper className={classes.note}>
                                <TextField
                                    disabled
                                    value={_.get(productDetail, 'remark')}
                                    className={classes.message}
                                    InputProps={{ disableUnderline: true }}
                                    multiline
                                    style={{ width: '100%', height: '100%' }}
                                    id="message"
                                    rows={5}
                                    inputProps={{ maxLength: 100 }}
                                    required
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider className={classes.marginDivider} />
            <Grid container direction="row" className={classes.groupButtonFooter} >
                <Button
                    color='primary'
                    disabled={!checkedPermission(userInfo, accessObjectPermissions, UPDATE_SECONDARY)}
                    startIcon={<Edit />}
                    variant="contained"
                    onClick={() => history.push('/product/secondary/edit/' + id)}
                >Chỉnh sửa</Button>

                {
                    (_.get(productDetail, 'productStatusByProductStatus.code') !== 'SOLD' || _.get(productDetail, 'status') !== 'SOLD') && (
                        <Button
                            disabled={!checkedPermission(userInfo, accessObjectPermissions, UPDATE_SECONDARY)}
                            variant="contained"
                            color='primary'
                            startIcon={<TouchApp />}
                            onClick={handleClickApprove}
                        >Xét duyệt</Button>
                    )
                }

                <ButtonReturn link={slugs.productSecondary} />
            </Grid>
        </Fragment>

    );
}
export default InfoProduct;



