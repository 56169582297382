import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography, Grid } from '@material-ui/core';
import { Waypoint } from 'react-waypoint';
import _ from 'lodash';
import SecondaryCard from '../SecondaryCard';

const useStyles = makeStyles({
  root: {
    marginTop: '30px',
    position: 'relative'
  },
  nodataStyle: {
    color: "#E27F35",
    fontSize: '16px',
    fontWeight: 'bold'
  }
});

function ProductSigned({ loading, data, PER_PAGE, fetchMore, accountId }) {

  const classes = useStyles();

  const count = _.get(data, 'product_request_aggregate.aggregate.count');


  if (loading)
    return <CircularProgress />

  if (count === 0) 
    return (
      <Grid container direction="row" className={classes.root}>
        <Typography className={classes.nodataStyle}>
            Không có dữ liệu thích hợp 
        </Typography>
      </Grid>
    )
    
  return (
    
    <Grid container direction="row" className={classes.root}>
      
      {
         _.size(_.get(data, 'products')) > 0 && _.map(_.get(data, 'products'), (item, index) => {
          return (
            <Fragment key={index}>
                <SecondaryCard data={item} />
                {index === _.size(data.products) - 1 && (
                  <Waypoint
                    onEnter={() =>
                      fetchMore({
                          variables: {
                            skip: (_.size(data.products) / PER_PAGE) * PER_PAGE,
                            take: PER_PAGE,
                            account: Number(accountId)
                          },
                          updateQuery: (previousResult, { fetchMoreResult }) => {
                            if (!_.get(fetchMoreResult, 'products')) {
                              return previousResult
                            };
                            return {
                              products: [
                                ...previousResult.products,
                                ...fetchMoreResult.products
                              ],
                            }
                          }
                      })
                    }
                />
                )}
            </Fragment>
          )
        })
      }
    </Grid>
  );
}

export default ProductSigned;
