import * as _ from 'lodash';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Typography, Grid, IconButton, Paper, InputBase, FormControl, Select, MenuItem, useTheme, Button, useMediaQuery, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AlternateEmail, Search, Telegram, Tune } from '@material-ui/icons';
import BookingList from './BookingList';
import ProductList from './ProductList';
import ProductListTable from './ProductListTable';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ButtonReturn from '../../../../components/ButtonReturn';
import { GET_PROJECT_CART_DETAIL, SUBSCRIPTION_PROJECT_CART_DETAIL } from '../../../../graphql/schemas/cart/cartQueries';
import { useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client';
import { useUpdateCart } from '../hooks';
import { useGetNextCartStatus } from '../../../../graphql/schemas/hooks';
import * as XLSX from 'xlsx';

import swal from 'sweetalert';
import { UPDATE_CART_STATUS } from '../../../../graphql/schemas/cart/mutations';
import { SUBSCRIPTION_BOOKING } from '../../../../graphql/schemas/booking/subscription';

import { useOrientation } from '../../../../graphql/schemas/hooks';
import PublishIcon from '@material-ui/icons/Publish';

import DataTable from 'react-data-table-component';
import clsx from 'clsx';
import { constant, result } from 'lodash';


const useStyles = makeStyles((theme) => ({
    titleContainer: {
        marginTop: 40,
        '& > .MuiGrid-item': {
            display: 'flex',
            alignItems: 'center',
            '& > p:nth-child(1)': {
                fontWeight: 'bold',
                marginRight: 20
            },
            '& > p:nth-child(2)': {
                fontSize: 13
            }
        }
    },
    titleContainerSecond: {
        marginTop: 40,
        '& > .MuiGrid-item': {
            display: 'flex',
            alignItems: 'center',
            '& > p:nth-child(1)': {
                fontWeight: 'bold',
                marginRight: 80
            },
            '& > p:nth-child(2)': {
                fontSize: 13
            }
        }
    },
    rootSearch: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 300,
        borderRadius: 20,
        '& > div': {
            width: 'calc(100% - 55px)',
        },
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    rowButton: {
        justifyContent: 'space-between',
        padding: '30px 0',
    },
    buttonAdd: {
        backgroundColor: '#2C8A81',
        borderRadius: '0',
        color: '#ffffff',
        textTransform: 'none',
        padding: '10px 30px'
    },
    groupButtonFooter: {
        '& > button': {
            padding: "6px 30px",
            borderRadius: 0,
        },
        '& > button:not(:last-child)': {
            marginRight: '30px',
        }
    },
    showList: {
        float: "right",
        fontSize: "35px",
        marginBottom: "10px",
        cursor: "pointer"
    },
    updateCart: {
        alignItems: "center",
        margin: '30px 0',
    },
    buttonUpload: {
        fontSize: 120,
        cursor: 'pointer',
    }
}));

const headMenu = [
    { id: 'index', numeric: false, disablePadding: false, label: 'STT' },
    { id: 'block', numeric: false, disablePadding: false, label: 'BLOCK' },
    { id: 'code', numeric: false, disablePadding: false, label: 'MÃ SẢN PHẨM' },
    { id: 'apartmentNo', numeric: false, disablePadding: false, label: 'SỐ CĂN HỘ' },
    { id: 'orient', numeric: false, disablePadding: false, label: 'HƯỚNG' },
    { id: 'floor', numeric: false, disablePadding: false, label: 'TẦNG' },
    { id: 'bedroom', numeric: false, disablePadding: false, label: 'PHÒNG NGỦ' },
    { id: 'bathroom', numeric: false, disablePadding: false, label: 'PHÒNG TẮM' },
    { id: 'buildUpArea', numeric: false, disablePadding: false, label: 'DT TIM TƯỜNG (m2)' },
    { id: 'carpetArea', numeric: false, disablePadding: false, label: 'DT THÔNG THỦY (m2)' },
    { id: 'price', numeric: false, disablePadding: false, label: 'GIÁ CĂN HỘ (Chưa bao gồm VAT)' },
    { id: 'priceVAT', numeric: false, disablePadding: false, label: 'GIÁ CĂN HỘ (Đã bao gồm VAT)' },
];

const InfoCartProduct = ({ infoCart, setKindCartShow, setDetailBooking }) => {
    // const [getProjectItems, { loading, data: dataProjectDetail, refetch: refetchCartDetail }] = useLazyQuery(GET_PROJECT_CART_DETAIL, {
    //     fetchPolicy: 'cache-and-network'
    // });


    const [lstData, setLstData] = useState([]);
    const [lstStatusData, setLstStatusData] = useState([]);
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

    const orientations = useOrientation();
    const { id } = useParams();

    const selectProject = localStorage.getItem('selectProject') ? JSON.parse(localStorage.getItem('selectProject')) : null;
    const cartId = _.get(selectProject, 'indexCard.id', null);

    const [value, setValue] = useState("Booking");
    const [status, setStatus] = useState(0);

    const count = 30;
    const [page, setPage] = useState(0);
    const [skip, setSkip] = useState(0);
    const [isList, setIsList] = useState(false);

    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [arrayVariables, setArrayVariables] = useState([]);
    const [orderItem, setOrderItem] = useState({ "code": "asc", "assembly_turn": "asc" });

    const [searchContent, setSearchContent] = useState('');

    const { update } = useUpdateCart(cartId, arrayVariables, history, id, setData, setColumns);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const { data: dataProjectDetail, error: errProjectDetail } = useSubscription(SUBSCRIPTION_PROJECT_CART_DETAIL, {
        variables: {
            condition: {
                deleted: {
                    _eq: false
                },
                id: {
                    _eq: cartId
                }

            }
        }
    });

    const { data: dataSub, loading: loadingSub, error: errorSub } = useSubscription(SUBSCRIPTION_BOOKING, {
        variables: {
            cartId: selectProject?.indexCard?.id,
            orderBy: orderItem,
            whereBooking: {
                _or: [
                    {
                        code: {
                            _ilike: `%${searchContent}%`
                        }
                    },
                    {
                        account: {
                            _or: [
                                {
                                    code: {
                                        _ilike: `%${searchContent}%`
                                    }
                                },
                                {
                                    full_name: {
                                        _ilike: `%${searchContent}%`
                                    }
                                }
                            ]
                        }
                    },
                    {
                        accountByEmployee: {
                            _or: [
                                {
                                    code: {
                                        _ilike: `%${searchContent}%`
                                    }
                                },
                                {
                                    full_name: {
                                        _ilike: `%${searchContent}%`
                                    }
                                }
                            ]
                        }
                    },
                    {
                        booking_status: {
                            _or: [{
                                name2: {
                                    _ilike: `%${searchContent}%`
                                }
                            }]
                        }
                    }
                ]
            }
        }
    });

    const handleOrientation = (data) => {
        const result = _.find(orientations, (item, key) => {
            return data === _.get(item, 'name')
        })
        return _.get(result, 'id');
    };


    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }

        // prepare columns list from headers
        const columns = headers.map(c => ({
            name: c,
            selector: c,
        }));

        let result = [];

        for (let i = 0; i < list.length; i++) {
            result.push({
                index: Number(_.get(list[i], 'STT')),
                block_code: _.get(list[i], 'BLOCK'),
                code: _.get(list[i], 'MÃ SẢN PHẨM'),
                orientation: handleOrientation(_.get(list[i], 'HƯỚNG')),
                floor: _.get(list[i], 'TẦNG'),
                apartment_no: _.get(list[i], 'SỐ CĂN HỘ'),
                bed_room: Number(_.get(list[i], 'PHÒNG NGỦ')),
                bath_room: Number(_.get(list[i], 'PHÒNG TẮM')),
                carpet_area: Number(_.split(_.get(list[i], 'DT TIM TƯỜNG(m2)'), ' ')[1]),
                built_up_area: Number(_.split(_.get(list[i], 'DT THÔNG THỦY(m2)'), ' ')[1]),
                price: Number(_.replace(_.split(_.get(list[i], 'GIÁ CĂN HỘ (Chưa bao gồm VAT)'), ' ')[1], /,/g, '')),
                price_include_vat: Number(_.replace(_.split(_.get(list[i], 'GIÁ CĂN HỘ( Đã bao gồm VAT)'), ' ')[1], /,/g, '')),
                commission: 0
            })
        }
        let sorted_arr = result.slice().sort();
        if (_.size(result) > 0) {
            for (let i = 0; i < _.size(result) - 1; i++) {
                if (_.get(result[i + 1], 'code') == _.get(sorted_arr[i], 'code')) {
                    swal({
                        title: "Upload giỏ hàng không thành công",
                        text: "Mã sản phẩm bị trùng, vui lòng kiểm tra lại",
                        icon: "error"
                    });
                    return;
                }
            }
        }
        setData(list);
        setColumns(columns);
        setArrayVariables(result);
    }

    // handle file upload
    const handleFileUpload = e => {
        //Generate UI
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 2 });
            processData(data);
        };
        reader.readAsBinaryString(file);
        //Handle data
        e.target.value = null;
    }


    useEffect(() => {
        let lstItem = _.map(_.get(dataProjectDetail, 'result[0].project_items', []), item => {
            return {
                index: item.index,
                block: item.block_code,
                code: item.code,
                apartmentNo: item.apartment_no,
                orient: item.orientation,
                floor: item.floor,
                bedroom: item.bed_room,
                bathroom: item.bath_room,
                buildUpArea: item.built_up_area,
                carpetArea: item.carpet_area,
                price: item.price,
                priceVAT: item.price_include_vat
            }
        });
        setLstStatusData(_.get(dataProjectDetail, 'result[0].project_items', []));
        setLstData(lstItem);
    }, [dataProjectDetail]);

    useEffect(() => {
        setDetailBooking((prev) => ({
            ...prev,
            status
        }))
    }, [status])

    useEffect(() => {
        if (_.get(dataSub, 'project_cart.project_cart_status.code') === 'ASSEMBLED') {
            setOrderItem({ "assembly_turn": "asc", "index": "asc" })
        };
        if (_.get(dataSub, 'project_cart.project_cart_status.code') === 'OPENSALE') {
            setOrderItem({ "priority": "asc_nulls_last", "created": "asc" })
        };
    }, [dataSub]);


    return (
        <Fragment>
            <Grid container direction="column">
                <Grid container direction="row" className={classes.titleContainer} justifyContent="space-between">
                    <Grid item>
                        <Typography>{_.get(selectProject, 'title')}</Typography>
                        <FormControl variant="outlined" size="small" style={{ width: 200 }}>
                            <Select onChange={handleChange} value={value}>
                                <MenuItem value="Booking">Danh sách booking</MenuItem>
                                <MenuItem value="ProductList">Danh sách sản phẩm</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item style={{ display: 'flex' }}>
                        <Typography>Mã sản phẩm</Typography>
                        <Typography>{_.get(selectProject, 'code')}</Typography>
                    </Grid>
                    <Grid item>
                        <Paper className={classes.rootSearch}>
                            <IconButton className={classes.iconButton} aria-label="search">
                                <Search />
                            </IconButton>
                            <InputBase value={searchContent} onChange={(e) => setSearchContent(e.target.value)} />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container direction="row" className={classes.titleContainerSecond}>
                    <Grid item>
                        <Typography>{_.get(infoCart, "name")}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>Trạng thái giỏ hàng</Typography>
                        <Typography>{_.get(dataSub, 'project_cart.project_cart_status.name')}</Typography>
                        {/* <FormControl variant="outlined" size="small" style={{ width: 200 }}>
                            <Select id="status-select" onChange={handleChangeStatus} value={status}>
                                {
                                    _.map(_.get(dataCartStatus,'result'), (item, key)=> 
                                        <MenuItem
                                            key={key} 
                                            value={key}
                                        >{item.name}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl> */}
                    </Grid>
                </Grid>
                {value === "Booking" && (
                    loadingSub ? <CircularProgress /> :
                        <BookingList
                            status={_.get(dataSub, 'project_cart.project_cart_status')}
                            setKindCartShow={setKindCartShow}
                            setDetailBooking={setDetailBooking}
                            selectProject={selectProject}
                            data={_.get(dataSub, 'project_cart')}
                        />
                )}

                {value === "ProductList" && (
                    _.size(lstStatusData) === 0 && data.length === 0 ? (
                        <Paper className={classes.updateCart}>
                            <input
                                accept=".csv,.xlsx,.xls"
                                id="upload-cart"
                                multiple
                                type="file"
                                hidden
                                onChange={handleFileUpload}
                                disabled={_.includes(['ECOECART', 'AFTERSALE'], _.get(dataSub, 'project_cart.project_cart_status.code'))}
                            />
                            <Grid direction="column" container className={classes.updateCart}>
                                <Typography>Chưa cập nhật giỏ hàng</Typography>
                                {
                                    _.includes(['ECOECART', 'AFTERSALE'], _.get(dataSub, 'project_cart.project_cart_status.code')) ? (
                                        <PublishIcon
                                            className={classes.buttonUpload}
                                            onClick={() => swal("Thông báo", "Không thể cập nhật giỏ hàng này!", "info")}
                                        />
                                    )
                                        :
                                        (
                                            <label htmlFor={"upload-cart"}>
                                                <PublishIcon className={classes.buttonUpload} />
                                            </label>
                                        )
                                }
                                <Typography>Vui lòng cập nhật file csv để upload</Typography>
                            </Grid>
                        </Paper>

                    )
                        :
                        (
                            isList ? (
                                <Fragment>
                                    <Grid container direction="row" className={classes.rowButton} >
                                        <Button
                                            variant="contained"
                                            className={classes.buttonAdd}
                                            startIcon={<Tune />}
                                            component={Link} to={`/product/primary/cart/create/${id}`}
                                            disabled={_.includes(['ECOECART', 'AFTERSALE'], _.get(infoCart, 'project_cart_status.code'))}
                                        >
                                            Cài đặt giỏ hàng
                                        </Button>
                                        <ViewListIcon className={classes.showList} onClick={() => setIsList(!isList)} />
                                    </Grid>

                                    <ProductListTable
                                        count={count}
                                        data={lstData}
                                        headMenu={headMenu}
                                        setSkip={(skip) => setSkip(skip)}
                                        loading={false}
                                        page={page}
                                        setPage={setPage}
                                        handleFileUpload={handleFileUpload}
                                    />
                                </Fragment>
                            )
                                :
                                (
                                    <Fragment>
                                        <Grid container direction="row" className={classes.rowButton} >
                                            <Button
                                                variant="contained"
                                                className={classes.buttonAdd}
                                                startIcon={<Tune />}
                                                component={Link} to={`/product/primary/cart/create/${id}`}
                                                disabled={_.includes(['ECOECART', 'AFTERSALE'], _.get(infoCart, 'project_cart_status.code'))}
                                            >
                                                Cài đặt giỏ hàng
                                            </Button>
                                            <ViewModuleIcon className={classes.showList} onClick={() => setIsList(!isList)} />
                                        </Grid>

                                        {lstStatusData.length > 0 && <ProductList handleFileUpload={handleFileUpload} status={_.get(infoCart, 'project_cart_status.code')} data={lstStatusData} bookings={_.get(dataSub, 'project_cart.bookings')} />}
                                    </Fragment>
                                )
                        )
                )}

            </Grid>
            {data.length > 0 && (
                <DataTable
                    pagination
                    highlightOnHover
                    columns={columns}
                    data={data}
                />
            )}


            <Grid container direction="row" className={classes.groupButtonFooter} justifyContent='flex-end'>
                <Button
                    color="primary"
                    style={{ borderRadius: '0' }}
                    variant="contained"
                    onClick={update}
                    disabled={_.size(_.get(dataProjectDetail, 'result[0].project_items')) > 0}
                    startIcon={<Telegram />}
                >Cập nhật</Button>

                <ButtonReturn actionClick={() => setKindCartShow("listCartProduct")} />
            </Grid>
        </Fragment >

    )
}

export default InfoCartProduct;
