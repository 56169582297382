import gql from 'graphql-tag'

export const SUBSCRIPTION_BOOKING = gql`
subscription SubscriptionBookings($cartId: Int!, $orderBy: [booking_order_by!] = {}, $whereBooking: booking_bool_exp!) {
  project_cart: project_cart_by_pk(id: $cartId) {
    id
    name
    index
    assembly_date
    assembly_end_date
    sale_date
    sale_end
    status_priority
    project_cart_status {
      id
      code
      name
    }
    project_detail: projectByProject {
      id
      title
    }
    bookings(order_by: $orderBy, where: $whereBooking) {
      id
      code
      index
      assembly_activated
      assembly_activated_at
      assembly_est_end
      sale_priority_activated
      sale_priority_end
      priority
      booking_status {
        id
        code
        name
        name2
      }
      personalid
      product: project_item {
        id
        code
      }
      customer_info: account {
        id
        code
        full_name
        customers {
          id
        }
      }
      employee_info: accountByEmployee {
        id
        code
        full_name
        employees {
          manager_info: employee {
            account_info: accountByAccount {
              id
              code
              full_name
            }
          }
        }
      }
    }
  }
}

`
