import React, { Fragment, useState } from 'react'
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import { IconButton, makeStyles, TableCell, TableRow, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import clsx from 'clsx';
import PermissionThird from './PermissionThird';
import _ from 'lodash';
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
	titleName: {
		display: 'flex',
		alignItems: 'center',
		width: 200
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	rowParent: {
		background: '#e2e1e1'
	}
}));

const PermissionRowSecond = ({ element, headerList }) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);	

	const returnedCell = (element) => {
		const lstCell = [];
		if (!_.get(element, 'is_last')) {
			for (let i = 0; i < _.size(headerList); i++) {
				lstCell.push(<TableCell></TableCell>)
			}
			return lstCell;
		}

		for (let i = 0; i < _.size(headerList); i++) {
			let isPer = _.find(element.account_type_permissions, (item) => _.get(item, 'account_type.id') === _.get(headerList[i], 'id'));
			if (isPer) {
				lstCell.push(
					<TableCell align="center">
						<FormControlLabel
							control={
								<Checkbox
									value={headerList[i]}
									defaultChecked={true}
									icon={<CheckBoxOutlineBlank />}
									checkedIcon={<CheckBoxOutlined style={{ color: '#00837B' }} />}
									name="checkedI"
								/>
							}
						/>
					</TableCell>
				)
			} else {
				lstCell.push(
					<TableCell align="center">
						<FormControlLabel
							control={
								<Checkbox
									value={headerList[i]}
									defaultChecked={false}
									icon={<CheckBoxOutlineBlank />}
									checkedIcon={<CheckBoxOutlined style={{ color: '#00837B' }} />}
									name="checkedI"
								/>
							}
						/>
					</TableCell>
				)
			}
		}

		return lstCell
	};

	return (
		<Fragment>
			<TableRow className={clsx(!_.isEmpty(_.get(element, 'childs')) && classes.rowParent)}>
				<TableCell>
					<div className={clsx(classes.titleContainer)}>
						<Typography className={clsx(classes.titleName)}>{_.get(element, 'name')}</Typography>
						{!_.isEmpty(_.get(element, 'childs')) && (
							<IconButton
								aria-label="expand row"
								size="small"
								onClick={() => setOpen(!open)}
							>
								{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
							</IconButton>
						)}
					</div>
				</TableCell>				
				{returnedCell(element)}
			</TableRow>
			{open && (
				<Fragment>
					{!_.isEmpty(_.get(element, 'childs')) > 0 && (
						_.map(_.get(element, 'childs'), (element, key) =>
							<PermissionThird
								key={key}
								headerList={headerList}
								element={element}
							/>
						)
					)}
				</Fragment>
			)}
		</Fragment>


	)
}

export default PermissionRowSecond
