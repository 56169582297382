export const validEcoeId = new RegExp(
    /^[E][0-9]*$/
);

export const validProductId = new RegExp(
    /^[A|L|T|P|O][0-9]*$/
)


export const validOffice = new RegExp(
    /^[A|L|T|P|O][0-9]*$/
);

export const validCustomerId = new RegExp(
    /^[E][0-9]*$/
);

export const validEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

export const validNumber = new RegExp(
    /^[0-9]*$/
)

export const validPhone = new RegExp(
    /^[0-9\-\+]{10,12}$/
)