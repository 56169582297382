import { Collapse, IconButton, makeStyles, TableCell, TableRow, Typography, Table } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import clsx from 'clsx';
import PermissionRowSecond from './PermissionRowSecond';
import _ from 'lodash'
import PermissionThird from './PermissionThird';

const useStyles = makeStyles((theme) => ({
	titleName: {
		display: 'flex',
		alignItems: 'center',
		color: '#2E9993',
		fontWeight: 700,
		width: 300
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	rowTable: {
		'& > .MuiTableCell-root': {
			width: 300
		}
	}
}));

const PermissionRow = ({ row, headerList, disabledUpdate, setDisabledUpdate, permissionUpdate, setPermissionUpdate, selectedDepartment }) => {
	const classes = useStyles();
	const [open, setOpen] = useState(true);

	const returnedCell = () => {
		const lstCell = [];
		for (let i = 0; i < _.size(headerList); i++) {
			lstCell.push(<TableCell align="center" key={i}></TableCell>)
		}
		return lstCell;
	}
	
	useEffect(() => {
		setPermissionUpdate(permissionUpdate);
	}, [open]);

	return (
		<Fragment>
			<TableRow className={clsx(classes.rowTable)} style={{ backgroundColor: '#F5FAFA' }}>
				<TableCell align="center">
					<div className={clsx(classes.titleContainer)}>
						<Typography className={clsx(classes.titleName)}>{_.get(row, 'name')}</Typography>
						{!_.isEmpty(_.get(row, 'childs')) && (
							<IconButton
								aria-label="expand row"
								size="small"
								onClick={() => setOpen(!open)}
							>
								{open ? <KeyboardArrowUp style={{ color: '#2E9993' }} /> : <KeyboardArrowDown style={{ color: '#2E9993' }} />}
							</IconButton>
						)}
					</div>
				</TableCell>
				{returnedCell()}
			</TableRow>

			{open && (
				_.map(_.get(row, 'childs'), (element, index) => {
					// let tmp = _.includes([128,129], element?.id) ? {...element, childs: [{id: 455, name: "testing child 1"},{id: 456, name: "testing child 2"}]} : element;

					if(_.isEmpty(_.get(element,'childs'))) {
						return (
							<PermissionThird
								key={index}
								headerList={headerList}
								element={element}
								disabledUpdate={disabledUpdate}
								setDisabledUpdate={setDisabledUpdate}
								permissionUpdate={permissionUpdate}
								setPermissionUpdate={setPermissionUpdate}
								selectedDepartment={selectedDepartment}
							/>
						)
					} 
					else {
						return (
							<PermissionRowSecond
								headerList={headerList}
								element={element}
								key={index}
							/>
						)
					}
				})
			)}
		</Fragment>
	)
}

export default PermissionRow
