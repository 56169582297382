import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, ButtonGroup, Button, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ArrowBack, AddBox } from '@material-ui/icons';
import ReviewList from './components/ReviewList';
import CustomerReviewCreate from './components/CustomerReviewCreate';
import { useGetCustomer } from './hooks';

import _ from 'lodash';
import { useEmployee } from '../Employee/hooks';

import AddIcon from '@material-ui/icons/Add';
import ReviewDetail from './components/ReviewDetail';

const CustomerReview = () => {
    const [isActive, setIsActive] = useState(localStorage.getItem('isActiveReviewMenu') || true);
    const [flag, setFlag] = useState({
        isCreate: false,
        isDetail: false
    });

    const [branch, setBranch] = useState(0);
    const [employee, setEmployee] = useState(0);
    const [pointReview, setPointReview] = useState(0);
    const [customerId, setCustomerId] = useState();

    // customer
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [personalId, setPersonalId] = useState('');

    // employee
    const [employeeEcoeId, setEmployeeEcoeId] = useState();
    const [fullNameEmployee, setFullNameEmployee] = useState('');

    const [contentTransaction, setContentTransaction] = useState('');
    const [favorites, setFavorites] = useState('');

    const [timeTrans, setTimeTrans] = useState('');
    const [rate, setRate] = useState(1);

    const [note, setNote] = useState('');

    const history = useHistory();

    const useStyles = makeStyles(theme => ({
        button: {
            color: '#fff',
            border: 'none',
        },
        buttonRight: {
            backgroundColor: isActive ? '#E27F35' : '#388a81',
        },
        buttonLeft: {
            backgroundColor: isActive ? '#388a81' : '#E27F35',
        },
        buttonBack: {
            backgroundColor: '#E27F35',
            color: '#fff'
        }
    }));

    const classes = useStyles();

    const { data } = useGetCustomer(customerId);

    const { employee: employeeData } = useEmployee(employeeEcoeId);

    useEffect(() => {
        if (employeeData) {
            setFullNameEmployee(_.get(employeeData, 'info.full_name'));
        }
    }, [employeeData])

    useEffect(() => {
        if (data) {
            setFullName(_.get(data, 'results.info.full_name'));
            setPhone(_.get(data, 'results.info.phone'));
            setPersonalId(_.get(data, 'results.info.personalid'));
            setEmail(_.get(data, 'results.info.email'))
        }
    }, [data])

    return (
        <Grid container direction="column">
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item>
                    <ButtonGroup className={classes.buttonGroup}>
                        <Button
                            onClick={() => {
                                setIsActive(true);
                                setFlag({ ...flag, isDetail: false, isCreate: false });
                                localStorage.setItem('isActiveReviewMenu', true)
                            }}
                            style={{ backgroundColor: isActive ? '#E27F35' : '#388a81', }}
                            variant="contained"
                            className={classes.button}
                        >
                            Danh sách đánh giá tại văn phòng
                        </Button>
                        <Button
                            onClick={() => {
                                setIsActive(false);
                                setFlag({ ...flag, isDetail: false, isCreate: false });
                                localStorage.setItem('isActiveReviewMenu', false)
                            }}
                            variant="contained"
                            className={classes.button}
                            style={{ backgroundColor: isActive ? '#388a81' : '#E27F35', }}
                        >
                            Danh sách báo cáo xấu văn phòng
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>


            {!flag.isCreate && !flag.isDetail && isActive && (
                <Fragment>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between" className={classes.filterContainer}>
                        <Grid item>
                            <Typography>Bộ lọc</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => setFlag({ ...flag, isCreate: true })}>
                                <AddBox style={{ fontSize: 40, color: '#115293' }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <ReviewList
                        setFlag={setFlag}
                        setBranch={setBranch}
                        branch={branch}
                        setEmployee={setEmployee}
                        pointReview={pointReview}
                        setPointReview={setPointReview}
                    />
                </Fragment>
            )}

            {flag.isCreate && (
                <CustomerReviewCreate
                    email={email}
                    phone={phone}
                    fullName={fullName}
                    personalId={personalId}
                    setFullName={setFullName}
                    setCustomerId={setCustomerId}
                    setPersonalId={setPersonalId}
                    setEmployeeEcoeId={setEmployeeEcoeId}
                    setPhone={setPhone}
                    setEmail={setEmail}
                    fullNameEmployee={fullNameEmployee}
                    contentTransaction={contentTransaction}
                    setContentTransaction={setContentTransaction}
                    favorites={favorites}
                    setFavorites={setFavorites}
                    rate={rate}
                    setRate={setRate}
                    timeTrans={timeTrans}
                    setTimeTrans={setTimeTrans}
                    setNote={setNote}
                    note={note}
                />
            )}

            {flag.isDetail && (
                <ReviewDetail />
            )}

            {!isActive && (
                <p>Comming Soon</p>
            )}



            <br />

            <Grid container direction="row" justifyContent="flex-end">
                {flag.isCreate && !flag.isDetail && (
                    <Button
                        className={classes.buttonBack}
                        variant="contained"
                        style={{ marginRight: 10, backgroundColor: '#388A81' }}
                    >
                        <AddIcon style={{ fontSize: 16 }} />
                        <span style={{ marginLeft: 10 }}>Xác nhận</span>
                    </Button>
                )}
                <Button
                    onClick={() => history.goBack()}
                    className={classes.buttonBack}
                    variant="contained"
                >
                    <ArrowBack style={{ fontSize: 16 }} />
                    <span style={{ marginLeft: 10 }}>Quay lại</span>
                </Button>

            </Grid>

        </Grid>
    )
}

export default CustomerReview
