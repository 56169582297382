import * as _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    buttonStyle: {
        backgroundColor: '#E27F35',
        padding: "6px 30px",
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#c56e2d',
        }
    },
}));

const ButtonReturn = ({ label = 'Quay lại', link, actionClick }) => {
    const classes = useStyles();
    const history = useHistory();
    
    const handleClick = () => {
        if (_.isFunction(actionClick)) {
            actionClick();
        } 
        else if(link) {
            history.push(link);
        }
        else {
            window.history.back();
        }
    }
    
    return (
        <Button
            color="secondary"
            className={classes.buttonStyle}
            variant="contained"
            onClick={handleClick}
            startIcon={<ArrowBack />}
        >{label}</Button>
    )
};

ButtonReturn.propTypes = {
    label: PropTypes.string,
    link: PropTypes.string,
    actionClick: PropTypes.func,
};

export default ButtonReturn;
