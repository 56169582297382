//content tab - ApprovalHistory
import * as _ from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery, Button } from '@material-ui/core';
import ecoeIcon from '../../../assets/icons/logo-ecoe-no-char.svg';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_ACTIVITY_HISTORY } from '../../../graphql/schemas/product/productQueries';
import { CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const DealHistory = props => {
    const { id } = useParams();
    const useStyles = makeStyles((theme) => ({
        circle: {
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            marginRight: '32px'
        },
        message: {
            fontSize: '20px',
            marginLeft: '40px',
            marginRight: '160px',
            wordWrap: 'break-word'
        },
        buttonBack: {
            backgroundColor: 'black',
            color: '#ffffff',
            borderRadius: 0,
            marginTop: '40px',
            padding: '10px 40px'
        }
    }));
    const { loading, data } = useQuery(GET_PRODUCT_ACTIVITY_HISTORY, {
        variables: {
            where: {
                requestByRequest: {
                    typeByType: {
                        code: {
                            _eq: "CONSULT"
                        }
                    },
                    productByProduct: {
                        product: {
                            _eq: id
                        }
                    }
                }
            }
        }
    })


    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const history = useHistory();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const showHistory = (code, item) => {
        let result = null;
        switch (code) {
            case 'CHANGE':
                result = <Grid item className={classes.message} style={{ width: matchesSM ? "600px" : '800px' }}>Khách hàng {_.get(item, 'requestByRequest.customer_info.full_name')} (Mã KH: {_.get(item, 'requestByRequest.customer_info.code')}) {_.get(item, 'type_workflow.type_step.name')} Nhân viên {_.get(item, 'requestByRequest.employee_info.full_name')} (Mã NV: {_.get(item, 'requestByRequest.employee_info.code')})</Grid>
                break;
            case 'REQUEST':
                result = <Grid item className={classes.message} style={{ width: matchesSM ? "600px" : '800px' }}>Khách hàng {_.get(item, 'requestByRequest.customer_info.full_name')} (Mã KH: {_.get(item, 'requestByRequest.customer_info.code')}) {_.get(item, 'type_workflow.type_step.name')} Nhân viên {_.get(item, 'requestByRequest.employee_info.full_name')} (Mã NV: {_.get(item, 'requestByRequest.employee_info.code')})</Grid>
                break;
            default:
                result = <Grid item className={classes.message} style={{ width: matchesSM ? "600px" : '800px' }}>Nhân viên {_.get(item, 'requestByRequest.employee_info.full_name')} (Mã NV: {_.get(item, 'requestByRequest.employee_info.code')}) {_.get(item, 'type_workflow.type_step.name')} Khách hàng {_.get(item, 'requestByRequest.customer_info.full_name')} (Mã KH: {_.get(item, 'requestByRequest.customer_info.code')})  </Grid>
                break;
        }
        return result;
    }
    if (loading || !data.request_workflow) return <CircularProgress />
    return (
        // <h1>Coming soon</h1>
        <Grid container direction="column" className={classes.root}>
            {
                _.map(data.request_workflow, (item, index) => <Grid container direction="row" style={{ marginTop: '60px', flexWrap: 'wrap' }} alignItems="center" key={index}>
                    <Grid item className={classes.circle} style={{ backgroundColor: '#dfe235' }} ></Grid>
                    <Grid item><img src={ecoeIcon} alt="logo" width={40} /></Grid>

                    {showHistory(_.get(item, 'type_workflow.type_step.code'), item)}

                    <Grid item style={{ fontSize: '18px' }}>  {moment(item.created).format('DD/MM/YYYY hh:mm:ss A')}</Grid>
                </Grid>)
            }
            <Grid item style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '200px' }}>
                <Button className={classes.buttonBack} variant="contained" onClick={() => history.goBack()}>
                    BACK
                </Button>
            </Grid>
        </Grid>
    );
}
export default DealHistory;