import React, { Fragment, useContext } from 'react'
import { makeStyles } from '@material-ui/styles';
import useTheme from '@material-ui/styles/useTheme';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from 'axios';
import swal from 'sweetalert';

import { ConfigContext } from '../context/ConfigContext';
import { StoreContext } from '../resources/store';
import { checkedPermission } from '../helper';
import { CREATE_BANNER } from '../constants/defined';

const CardUploadBanner = ({ title, setLoading, type, setBannerList }) => {

    const configValue = useContext(ConfigContext);
    const { userInfo, accessObjectPermissions } = useContext(StoreContext);

    const useStyles = makeStyles(theme => ({
        root: {
            maxWidth: 550,
            borderRadius: 0,
            backgroundColor: '#fafafa',
            boxShadow: 'none',
            fontSize: '30px',
            fontWeight: 'bold',
            margin: 'auto'
        },
        media: {
            width: 350,
            height: 300,
            margin: 'auto'
        },
        cardHeader: {
            '& > .MuiCardHeader-content span': {
                fontWeight: 'bold',
                textAlign: 'center'
            }
        },
        iconRemove: {
            fontSize: 30
        },
        iconRemoveContainer: {
            float: 'right',
            '&:hover': {
                color: 'red'
            }
        }
    }))

    const classes = useStyles();

    const uploadHandler = async (e) => {
        setLoading(true)
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("media_type_code", type);
        try {
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
            await axios.post(configValue.API_UPLOAD_FILE, formData, config).then((res) => {
                setLoading(false);
                e.target.files = null;
                return swal({
                    title: "Thêm banner thành công",
                    icon: "success",
                });


            }).catch(err => {
                setLoading(false);
                e.target.files = null;
                return swal({
                    title: "Thêm banner thất bại",
                    icon: "error",
                })
            })

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    return (
        <Grid item lg={6} md={12} sm={12} xs={12} style={{ marginTop: '50px' }}>
            <Card className={classes.root}>
                <input
                    accept="image/*"
                    id="face-id-customer"
                    multiple
                    type="file"
                    hidden
                    onChange={uploadHandler}
                />
                <Grid direction="column" container alignItems="center">
                    {
                        !checkedPermission(userInfo, accessObjectPermissions, CREATE_BANNER) ? (
                            <CloudUploadIcon style={{ fontSize: 240, cursor: "not-allowed" }} />
                        ) : (
                            <label htmlFor="face-id-customer">
                                <CloudUploadIcon style={{ fontSize: 240, cursor: "pointer" }} />
                            </label>
                        )
                    }

                </Grid>

            </Card>
        </Grid>


    )
}

export default CardUploadBanner
