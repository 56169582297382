import gql from "graphql-tag";

export const GET_EVENT_TYPES = gql`
  query GetTypeEvent {
    result: jackpot_event_type {
      id
      code
      name
    }
  }
`;

export const GET_LIST_EVENT = gql`
  query GetListEvent($skip: Int!, $take: Int!) {
    count: jackpot_event_aggregate(where: {deleted: {_eq: false}}) {
      aggregate {
        count
      }
    },
    result: jackpot_event(offset: $skip, limit: $take, where: {deleted: {_eq: false}}, order_by: {created: desc}) {
      id
      code
      name
      type_info: event_type {
        id
        code
        name
      }
      start
      end
    }
  }
`;

export const GET_EVENT_DETAIL = gql`
  query GetJackpotEventById($id: smallint!, $skip: Int, $take: Int) {
    result: jackpot_event(where: { id: { _eq: $id } }) {
      id
      name
      code
      type_info: event_type {
        code
        name
      }
      end
      start
      created_by
      created
      bingo_results {
        id
        code
        result_date
        reward
        bingo_count
        bingo_result_setting {
          id
          code
          name
        }
      }
      bingo_result_settings ( where: {deleted: { _eq: false }}, order_by: {id: asc} ) {
        id
        name
        special
        code
        last_numbers
        remark
        reward
      }
      event_tickets(order_by:{created:asc}) {
        id
        ticket
        created
        bingo_results {
          id
          code
          channel
          bingo_result_setting {
            id
            code
            name
            special
          }
        }
      }
      event_calendars(order_by: { start: asc }) {
        id
        start
        end
      }
      event_items(
        order_by: {itemByItem: {id: asc}},
        where: {
          itemByItem: { deleted: { _eq: false } }
        }
      ) {
        items: itemByItem {
          id
          code
          name
          icon: mediaByIcon {
            id
            name
            url
          }
          gift
          gift_detail: giftByGift {
            id
            code
            name
            gift_type {
              id
              code
              name
            }
          }
          calendars: item_calendars {
            start_time
            end_time
            start
            end
          }
          active
          source_type
          max_day
          spin_rate
          spin_ecoin
          spin_turn
          updated
        }
      }
      event_gifts(
        order_by: { giftByGift: { id: desc } }
        where: {
          giftByGift: {
            start: { _lte: today }
            end: { _gte: today }
            deleted: { _eq: false }
            active: { _eq: true }
          }
        }
        offset: $skip
        limit: $take
      ) {
        gifts: giftByGift {
          title_color
          background_color
          title_color
          id
          code
          name
          title
          description
          created
          count_account_changed
          image: mediaByImage {
            id
            name
            url
          }
          gift_type {
            id
            code
          }
          start
          end
          expire_day
          redeem_ecoin
          max_redeem
          reward
          hot
        }
      }
      event_medias {
        media: mediaByMedia {
          media_type {
            code
            name
          }
          id
          name
          url
        }
      }
      event_jack_schedules(order_by:{created:asc}){
        id
        start
        end
      }

      total_ecoin: transactions_aggregate(where: { ecoin: { _gt: 0 } }) {
        aggregate {
          sum {
            ecoin
            room_ecoin
          }
        }
      }
      total_room: rooms_aggregate {
        aggregate {
          count
        }
      }
      rooms_running: rooms_aggregate(
        where: { room_status: { code: { _eq: "SHAKING" } } }
      ) {
        aggregate {
          count
        }
      }
      total_change_gift: transactions_aggregate(
        where: { typeByType: { code: { _eq: "CHANGEGIFT" } } }
      ) {
        aggregate {
          count
        }
      }
      total_promotions: promotions_aggregate(where: { event: { _eq: $id } }) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_LIST_CUSTOMER_BY_EVENT = gql`
  query GetListCustomerGame($event: smallint) {
    result: jackpot_view_list_customer_game(
      where: {
        accountByAccount: {
          contacts: { contact_events: { event: { _eq: $event } } }
        }
      }
      order_by: { accountByAccount: { contacts_aggregate: { count: desc } } }
    ) {
      account_info: accountByAccount {
        id
        code
        full_name
        total_friend: contacts_aggregate {
          aggregate {
            count
          }
        }
      }
      ecoin
      room_ecoin
      ecoin_change_gift
    }
  }
`;
export const GET_CUSTOMER = gql`
  query GetCustommerByAccount($account: bigint!) {
    account_info: account_by_pk(id: $account) {
      id
      code
      full_name
    }
    total_friend: contact_aggregate(where: { friend: { _eq: $account } }) {
      aggregate {
        count
      }
    }
    total_ecoin: jackpot_transaction_aggregate(
      where: { account: { _eq: $account }, ecoin: { _gt: 0 } }
    ) {
      aggregate {
        sum {
          ecoin
          room_ecoin
        }
      }
    }
    total_change_ecoin: jackpot_transaction_aggregate(
      where: { account: { _eq: $account }, ecoin: { _lt: 0 } }
    ) {
      aggregate {
        sum {
          ecoin
        }
      }
    }
  }
`;

export const GET_LIST_TRANSACTION = gql`
  query GetListTransactionGame($where: jackpot_transaction_bool_exp = {}) {
    result: jackpot_transaction(where: $where, order_by: { created: desc }) {
      created
      accountByAccount {
        id
        code
        full_name
      }
      giftByGift {
        id
        name
        redeem_ecoin
        code
      }
    }
  }
`;

export const GET_LIST_JACK_VOUCHER_TRANSACTION = gql`
  query GetListVoucherGameJack(
    $where: voucher_bool_exp!
    $skip: Int
    $take: Int
  ) {
    list_voucher: voucher(where: $where, order_by: { created: desc }, offset: $skip, limit: $take) {
      id
      code
      account_info: accountByAccount {
        id
        code
        personalid
        email
        full_name
      }
      gift_info: accountGiftByAccountGift {
        giftByGift {
          code
          name
          reward
          image
          description
          expire_day
          redeem_ecoin
        }
        eventByEvent {
          code
          name
          start
          end
          event_type {
            name
          }
        }
      }
      promotionByPromotion {
        promotion_applies {
          promotion_apply_type {
            name
          }
        }
      }
      created
      created_by
      image: medium {
        id
        url
        name
      }
      voucher_status {
        name
        code
      }
      exchange_date
      exchange_code
      exchange_full_name
      exchange_product
      exchange_email
      exchange_phone
      exchange_personalid
      updated
      updated_by: accountByUpdatedBy {
        id
        code
      }
    }
  }
`;

export const GET_LIST_TRANSACTION_JACK_GIFT = gql`
  query GetTransactionHistories($where: jackpot_transaction_bool_exp!, $skip: Int, $take: Int) {
    total: jackpot_transaction_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    },
    result: jackpot_transaction(where: $where, offset: $skip, limit: $take) {
      id
      created
      typeByType {
        id
        code
        name
      }
      ecoin
      info: accountByAccount {
        id
        code
        full_name
      }
    }
  }
`;

export const GET_GIFT_TYPES = gql`
  query GetGiftType {
    result: jackpot_gift_type {
      id
      code
      name
    }
  }
`;

export const GET_BINGO_SETTING_CODES = gql`
  query GetBingoSettingCode {
    result: jackpot_bingo_setting_code {
      id
      code
      name
    }
  }
`;

export const GET_JACKPOT_TYPE = gql`
  query GetJackpotType($code: String) {
    results: jackpot_type(where: {code: {_eq: $code}}) {
      id
      code
      name
      ecoin
    }
  }
`;

export const GET_BINGO_RESULT_SETTING_NOT_USE = gql`
  query GetBingoResultSettingNotUse($id: smallint!) {
    result: jackpot_bingo_result_setting(where: {id: {_eq: $id}, _not: {bingo_results: {}}}) {
      id
      code
      name
      index
    }
  }
`;

export const GET_LIST_VOUCHER_GAME = gql`
  query GetListVoucherGameJack(
    $where: voucher_bool_exp!
    $skip: Int
    $take: Int
  ) {
    list_voucher: voucher(where: $where, offset: $skip, limit: $take) {
      account_info: accountByAccount {
        id
        code
        personalid
        email
      }
      gift_info: accountGiftByAccountGift {
        giftByGift {
          code
          reward
          name
          expire_day
        }
        eventByEvent {
          code
          name
          event_type {
            name
          }
        }
      }
      created
      created_by
      image
      name
      voucher_status {
        name
      }
      exchange_date
      exchange_customer
      end_time
      exchange_product
    }
    t: voucher_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_GIFT_BY_ACCOUNT = gql`
  query GetDetailGiftByAccount($account: bigint, $skip: Int!, $take: Int!) {
    list_gift: voucher(
      offset: $skip
      limit: $take
      where: {
        account: { _eq: $account }
        accountGiftByAccountGift: {
          giftByGift: { gift_type: { code: { _eq: "GIFT" } } }
        }
      }
      order_by: { created: desc }
    ) {
      accountGiftByAccountGift {
        eventByEvent {
          name
        }
        giftByGift {
          id
          code
          name
          expire_day
          redeem_ecoin
          type
        }
      }
      created
      exchange_date
    }
    account_info: account(where: { id: { _eq: $account } }) {
      id
      code
      full_name
      total_friend: contacts_aggregate {
        aggregate {
          count
        }
      }
    }
    t: voucher_aggregate(
      where: {
        account: { _eq: $account }
        accountGiftByAccountGift: {
          giftByGift: { gift_type: { code: { _eq: "GIFT" } } }
        }
      }
      order_by: { created: desc }
    ) {
      aggregate {
        count
      }
    }
    total_ecoin: jackpot_transaction_aggregate(
      where: { account: { _eq: $account }, ecoin: { _gte: 0 } }
    ) {
      aggregate {
        sum {
          ecoin
        }
      }
      aggregate {
        sum {
          room_ecoin
        }
      }
    }
    total_exchange: jackpot_transaction_aggregate(
      where: { ecoin: { _lte: 0 }, account: { _eq: $account } }
    ) {
      aggregate {
        sum {
          ecoin
        }
      }
    }
  }
`;

export const GET_VOUCHER_BY_ACCOUNT = gql`
  query GetDetailVoucherByAccount($account: bigint, $skip: Int!, $take: Int!) {
    list_voucher: voucher(
      offset: $skip
      limit: $take
      where: { account: { _eq: $account } }
      order_by: { created: desc }
    ) {
      event_info: accountGiftByAccountGift {
        eventByEvent {
          name
        }
      }
      code
      amount
      created
      end_time
      exchange_date
      exchange_personalid
      exchange_product
    }
    total_friend: account(where: { id: { _eq: $account } }) {
      contacts_aggregate {
        aggregate {
          count
        }
      }
    }
    total_voucher: voucher_aggregate(where: { account: { _eq: $account } }) {
      aggregate {
        count
      }
    }

    total_ecoin: jackpot_transaction_aggregate(
      where: { account: { _eq: $account }, ecoin: { _gte: 0 } }
    ) {
      aggregate {
        sum {
          ecoin
        }
      }
      aggregate {
        sum {
          room_ecoin
        }
      }
    }
    account_info: account(where: { id: { _eq: $account } }) {
      id
      code
      full_name
      total_friend: contacts_aggregate {
        aggregate {
          count
        }
      }
    }

    total_exchange: voucher_aggregate(
      where: { account: { _eq: $account }, exchange_date: { _is_null: false } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_LIST_TRANSACTION_BINGO = gql`
  query GetListTransactionGameBingo($where: jackpot_account_ticket_bool_exp!) {
    result: jackpot_account_ticket(where: $where, order_by: {created: desc}) {
      created
      account_info: accountByAccount {
        id
        full_name
        code
      }
      code
      transaction: transactionByTransaction {
        turn
        ecoin
      }
      account_ticket_results {
        bingo_result {
          result_date
          bingo_result_setting {
            name
            special
          }
        }
      }
    }
    redeem_ecoin_event_setting: jackpot_type (where: {code: {_eq: "BUY_TICKET"}}) {
      ecoin
    }
  }
`;

export const GET_LIST_APPLY_TYPES = gql`
  query GetListApplyType {
    result: promotion_apply_type {
      id
      code
      name
    }
  }
`;

export const GET_LIST_UPDATE_HISTORY = gql`
  query GetListUpdateEvent($entity_id: String) {
    log_event(
      where: { entity_id: { _eq: $entity_id }, operation: { _eq: "UPDATE" } }
    ) {
      id
      operation
      created
      accountByAccount {
        id
        code
      }
    }
  }
`;
export const GET_COUNT_BINGO_RESULT_BY_EVENT = gql`
  query GetCountBingoResultByEvent(
    $where: jackpot_bingo_result_bool_exp = {}
  ) {
    count: jackpot_bingo_result_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_BINGO_RESULT_BY_EVENT = gql`
  query GetBingoResultByEvent(
    $where: jackpot_bingo_result_bool_exp = {}
    $skip: Int
    $take: Int
  ) {
    result: jackpot_bingo_result(where: $where, order_by: { created: desc }, offset: $skip, limit: $take) {
      id
      code
      event_info: eventByEvent {
        id
        code
        name
        event_type {
          name
        }
      }
      account_ticket_results {
        id
        account_info: accountTicketByAccountTicket {
          accountByAccount {
            id
            code
            full_name
            personalid
            email
          }
        }
        account_ticket_result_status {
          code
          name
        }
        exchanged_day
      }
      event_code
      result_date
      bingo_count
      reward
      reward_date
      created_by
      bingo_result_setting {
        name
        special
      }
    }
  }
`;

export const GET_BINGO_RESULT_DETAIL = gql`
  query getBingoResultById($event: smallint, $id: bigint) {
    result: jackpot_bingo_result(
      where: { event: { _eq: $event }, id: { _eq: $id } }
    ) {
      code
      event_info: eventByEvent {
        id
        code
        name
        event_type {
          name
        }
      }
      account_ticket_results {
        id
        account_info: accountTicketByAccountTicket {
          accountByAccount {
            id
            code
            full_name
            personalid
            email
            phone
          }
        }
        account_ticket_result_status {
          code
          name
        }
        exchanged_day
      }
      event_code
      result_date
      bingo_count
      reward
      reward_date
      created_by
      bingo_result_setting {
        name
        special
      }
    }
  }
`;
export const GET_LIST_TRANSACTION_EVENT_VOUCHER = gql`
  query GetListTransactionEventVoucher(
    $where: voucher_bool_exp!
    $skip: Int
    $take: Int
  ) {
    list_voucher: voucher(where: $where, order_by: { created: desc }, offset: $skip, limit: $take) {
      id
      name
      code
      amount
      start_time
      end_time
      created
      created_by
      image: medium {
        id
        name
        url
      }
      account_info: accountByAccount {
        id
        code
        full_name
        personalid
        email
        phone
      }
      promotionByPromotion {
        code
        name
        event_info: event_promotion {
          code
          name
        }
        promotion_applies {
          promotion_apply_type {
            name
          }
        }
        description
      }
      voucher_status {
        code
        name
      }
      exchange_date
      exchange_code
      exchange_full_name
      exchange_product
      exchange_email
      exchange_phone
      exchange_personalid
      updated
      updated_by: accountByUpdatedBy {
        id
        code
      }
    }
  }
`;

export const UPDATE_BINGO_EXCHANGE_STATUS = gql`
  mutation UpdateStatusAccountTicketResult(
    $where: jackpot_account_ticket_result_bool_exp = {}
    $_set: jackpot_account_ticket_result_set_input = {}
  ) {
    result: update_jackpot_account_ticket_result(where: $where, _set: $_set) {
      affected_rows
    }
  }
`;

export const GET_JACK_VOUCHER_DETAIL = gql`
  query GetListVoucherGameJack($where: voucher_bool_exp!) {
    list_voucher: voucher(where: $where) {
      id
      code
      name
      amount
      account_info: accountByAccount {
        id
        code
        full_name
        personalid
        email
        phone
      }
      gift_info: accountGiftByAccountGift {
        giftByGift {
          code
          reward
          image
          description
          expire_day
        }
        eventByEvent {
          code
          name
          start
          end
          event_type {
            name
          }
        }
      }
      promotionByPromotion {
        code
        name
        description
        event_info: event_promotion {
          code
          name
        }
        promotion_applies {
          promotion_apply_type {
            name
          }
        }
      }
      created
      created_by
      image: medium {
        id
        url
        name
      }
      voucher_status {
        name
        code
      }
      start_time
      end_time
      exchange_date
      exchange_code
      exchange_full_name
      exchange_product
      exchange_email
      exchange_phone
      exchange_personalid
      updated
      updated_by: accountByUpdatedBy {
        id
        code
      }
    }
  }
`;

export const GET_SOURCE_TYPE = gql`
  query GetSourceType{
    result: jackpot_source_type {
      id
      code
      name
    }
  }
`;
