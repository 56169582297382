import * as _ from 'lodash';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { PROJECT_GET_ALL } from '../../../graphql/schemas/product/projectQueries';
import { GET_CODE_REQUEST, GET_TYPE_STEP, GET_DETAIL_PRODUCT } from './graphql/queries';
import { GET_EMPLOYEE_BY_ROLE_SECONDARY } from '../../../graphql/schemas/employee/employeeQueries';
import { CHECK_EXIST_PROJECT_NAME } from '../../../graphql/schemas/booking/bookingQueries';

export const withData = (WrappedComponent) => {
    const Wrapped = (props) => {
        const { loading, error, data } = useQuery(PROJECT_GET_ALL, {
            variables: {
                skip: 0,
                take: 10,
                where: {
                    deleted: { _eq: false },
                    propertyTypeByPropertyType: {
                        code: {
                            _eq: "P"
                        }
                    }
                }
            }
        });
        return (
            <WrappedComponent
                {...props}
                projects={_.get(data, 'projects')}
            />
        );
    }
    return Wrapped;
};

export const useGetTypes = () => {
    const { data } = useQuery(GET_CODE_REQUEST, {});
    return _.get(data, 'type');
};


export const useGetStep = (type) => {
    const { data } = useQuery(GET_TYPE_STEP, {
        variables: {
            type
        }
    });
    return data;
};

export const useGetProduct = (id) => {
    const { data, loading } = useQuery(GET_DETAIL_PRODUCT, {
        variables: {
            productId: id
        },
        skip: !id,
        fetchPolicy: 'no-cache'
    });

    return {
        data: _.get(data, 'product'),
        loading
    }
}

export const useGetEmployeeByRole = (dataEmployee) => {
    const { data, loading } = useQuery(GET_EMPLOYEE_BY_ROLE_SECONDARY, {
        variables: {
            roleCodes: dataEmployee
        }
    });
    return {
        data: _.get(data, 'results'),
        loading
    }
}

export const useExistProjectName = (value) => {
    const { data, error } = useQuery(CHECK_EXIST_PROJECT_NAME, {
        variables: {
            where: {
                title: {
                    _ilike: value
                },
                deleted: {
                    _eq: false
                },
                propertyTypeByPropertyType: {
                    code: {
                        _eq: "P"
                    }
                }
            }
        },
        skip: !value
    });

    return {
        data: _.get(data, 'product'),
        error
    }
}

