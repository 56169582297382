import { useMutation, useQuery } from '@apollo/client';
import swal from 'sweetalert';
import { CREATE_CUSTOMER, DETAIL_CUSTOMER, GET_ALL_CUSTOMER, UPDATE_CUSTOMER } from '../../graphql/schemas/customer/customerQueries';

export const useCreateCustomer = (setOpen, history) => {
    const [create] = useMutation(CREATE_CUSTOMER, {
        refetchQueries: [{
            query: GET_ALL_CUSTOMER,
            variables: {
                skip: 0,
                take: 10,
                where: {
                    accountByAccount: {
                        full_name: {
                            _like: `%%`
                        }
                    }
                }
            }
        }],
        onError: (err) => {
            return swal({
                title: `${err.message}`,
                icon: "error",
            })
        },
        onCompleted: () => {
            setOpen(true);
            history.push('/customer');
            return  swal({
                title: "Tạo khách hàng thành công",
                icon: "success",
            })
        }
    });
    return {
        create
    }
}

export const useGetCustomer = (id) => {
    
    const { data, loading, error, refetch } = useQuery(DETAIL_CUSTOMER, {
        
        variables: {
            id
        },
        skip: !id
    });

    return {
        data,
        loading,
        error,
        refetch
    }
}

export const useUpdateCustomer = (data) => {
    const [update] = useMutation(UPDATE_CUSTOMER, {
        onError: (err) => {
            return swal({
                title: `${err.message}`,
                icon: "error",
            })
        },
        onCompleted: () => {
            return swal({
                title: "Cập nhật khách hàng thành công",
                icon: "success",
            })
        }
    });

    return {
        update
    }
}