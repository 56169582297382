import { gql } from "@apollo/client";
import { CORE_SCHEMA_FIELDS } from "../product/fragments";

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($customer: CustomerInput!) {
    result: createCustomer(customer: $customer) {
      id
      info: accountByAccount {
        id
        code
        full_name
        faceid: mediumByFaceid {
          id
          name
          size
          mime
          url
        }
        email
        phone
        personalid
        roles: account_roles {
          role: roleByRole {
            id
            code
            name
          }
        }
        avatar: medium {
          id
          name
          url
          type
        }
        active
      }
      ref_employee_info: employeeByRefEmployee {
        faceid: medium {
          id
          name
          ext
          mime
          size
          provider
          hash
          url
        }
        info: accountByAccount {
          id
          code
          full_name
          email
          phone
          personalid
          roles: account_roles {
            role: roleByRole {
              id
              code
              name
            }
          }
          avatar: medium {
            id
            name
            url
            type
          }
          active
        }
      }
    }
  }
`;

export const DETAIL_CUSTOMER = gql`
  query getCustomer($id: bigint!) {
    results: customer_by_pk(id: $id) {
      id
      faceid: medium {
        id
        name
        ext
        mime
        size
        provider
        hash
        url
      }
      info: accountByAccount {
        id
        code
        full_name
        email
        phone
        personalid
        avatar: medium {
          id
          name
          url
        }
        active
      }
      ref_employee_info: employeeByRefEmployee {
        faceid: medium {
          id
          name
          ext
          mime
          size
          provider
          hash
          url
        }
        info: accountByAccount {
          id
          code
          full_name
          email
          phone
          personalid
          roles: account_roles {
            role: roleByRole {
              id
              code
              name
            }
          }
          avatar: medium {
            id
            name
            url
            type
          }
          active
        }
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($arg: CustomerUpdateInput!) {
    updateCustomer(arg: $arg) {
      id
      name
      account
    }
  }
`;

export const GET_CUSTOMER_BY_CODE = gql`
  query GetCustomers($skip: Int!, $take: Int!, $where: customer_bool_exp!) {
    results: customer(offset: $skip, limit: $take, where: $where) {
      id
      info: accountByAccount {
        id
        code
        full_name
        email
        phone
        personalid
        avatar: medium {
          id
          name
          url
        }
        active
      }
      ref_employee_info: employeeByRefEmployee {
        info: accountByAccount {
          id
          code
          full_name
          email
          phone
        }
      }
    }
    t: customer_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ALL_CUSTOMER = gql`
  query GetCustomers($skip: Int!, $take: Int!, $where: customer_bool_exp!) {
    results: customer(
      offset: $skip
      limit: $take
      where: $where
      order_by: { accountByAccount: { created: desc_nulls_last } }
    ) {
      id
      info: accountByAccount {
        id
        code
        full_name
        email
        phone
        personalid
        avatar: medium {
          id
          name
          url
        }
        active
        online
        last_activity
      }
      ref_employee_info: employeeByRefEmployee {
        info: accountByAccount {
          id
          code
          full_name
          email
          phone
        }
      }
      consult_employee_info: employee {
        info: accountByAccount {
          id
          code
          full_name
          email
          phone
        }
      }
    }
    t: customer_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CUSTOMER = gql`
  query getCustomer($id: bigint!) {
    results: customer_by_pk(id: $id) {
      id
      customer_banks {
        id
        bank_info: bankByBank {
          id
          name
        }
        bank_branch
        bank_name_other
        account_name
        account_number
      }
      info: accountByAccount {
        id
        code
        full_name
        wallets {
          id
          amount
          address
        }
        email
        phone
        gender
        birthday
        personalid
        active
        avatar: medium {
          id
          name
          url
        }
        faceid: mediumByFaceid {
          id
          name
          url
        }
        id_type
        id_name
        id_issues_date
        id_status
        front_media: mediumByIdFrontMedia {
          id
          url
        }
        back_media: mediumByIdBackMedia {
          id
          url
        }
      }
      ref_employee_info: employeeByRefEmployee {
        faceid: medium {
          id
          name
          ext
          mime
          size
          provider
          hash
          url
        }
        info: accountByAccount {
          id
          code
          full_name
          email
          phone
          personalid
          roles: account_roles {
            role: roleByRole {
              id
              code
              name
            }
          }
          avatar: medium {
            id
            name
            url
            type
          }
          active
        }
      }
      consult_employee_info: employee {
        id
        faceid: medium {
          id
          name
          ext
          mime
          size
          provider
          hash
          url
        }
        info: accountByAccount {
          id
          code
          full_name
          email
          phone
          personalid
          roles: account_roles {
            role: roleByRole {
              id
              code
              name
            }
          }
          avatar: medium {
            id
            name
            url
            type
          }
          active
        }
        departments: employee_departments {
          department: departmentByDepartment {
            id
            code
            name
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_CONSIGN = gql`
  ${CORE_SCHEMA_FIELDS}
  query ProductConsigns($account: bigint!, $skip: Int!, $take: Int!) {
    product_request_aggregate(
      where: {
        deleted: { _eq: false }
        customer: { _eq: $account }
        type_workflow: { typeByType: { code: { _eq: "CONSIGN" } } }
        productByProduct: { active: { _eq: true }, deleted: { _eq: false } }
      }
      order_by: { created: desc, updated: desc }
    ) {
      aggregate: aggregate {
        count
      }
    }
    product_request(
      offset: $skip
      limit: $take
      where: {
        deleted: { _eq: false }
        customer: { _eq: $account }
        type_workflow: {
          typeByType: { code: { _eq: "CONSIGN" } }
          type_status: { code: {} }
        }
        productByProduct: { active: { _eq: true }, deleted: { _eq: false } }
      }
      order_by: { created: desc, updated: desc }
    ) {
      type_workflow {
        typeByType {
          id
          code
          name
        }
        type_status {
          code
          name
        }
      }
      id
      productByProduct {
        id
        code
        title
        price
        price_to_display
        price_unit: priceUnitByPriceUnit {
          id
          name
        }
        status
        area
        area_used
        area_height
        area_width
        bath_room
        bed_room
        basement
        address: addressByAddress {
          ...addressData
        }
        orientation: orientationByOrientation {
          id
          name
        }
        exclusive
        urgent
        publish
        listing_type: listingTypeByListingType {
          id
          name
        }
        property_type: propertyTypeByPropertyType {
          id
          name
        }
        avatar: productAvatarByMedia {
          id
          name
          url
        }
      }
    }
  }
`;
export const GET_PRODUCT_BY_CUSTOMER = gql`
  ${CORE_SCHEMA_FIELDS}
  query GetProductsByCustomer(
    $skip: Int!
    $take: Int!
    $condition: product_bool_exp!
    $order_by: [product_order_by!] = {}
  ) {
    product_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
    products: product(
      offset: $skip
      limit: $take
      where: $condition
      order_by: $order_by
    ) {
      status
      title
      id
      active
      product_requests(
        limit: 1
        order_by: { updated_by: desc_nulls_first, created: desc }
      ) {
        workflow_type {
          code
        }
        id
        requests {
          id
          draft
          locked
          current_step
          type_workflow {
            type_step {
              id
              code
              name
              type_status {
                id
                code
                name
              }
            }
          }
        }
        type_workflow {
          typeByType {
            id
            code
            name
          }
          type_step {
            id
            code
            name
          }
        }
      }
      branchByBranch {
        id
        code
        name
      }
      id
      code
      title
      price_to_display
      price_unit: priceUnitByPriceUnit {
        id
        name
      }
      area
      area_used
      area_height
      area_width
      bath_room
      bed_room
      basement
      orientation: orientationByOrientation {
        id
        name
      }
      exclusive
      urgent
      publish
      address: addressByAddress {
        ...addressData
      }
      listing_type: listingTypeByListingType {
        id
        name
      }
      legal_type: legalTypeByLegalType {
        id
        name
      }
      property_type: propertyTypeByPropertyType {
        id
        name
        code
      }
      avatar: productAvatarByMedia {
        id
        name
        url
      }
      created
      updated
      updated_by_role: updatedByAccount {
        account_roles {
          roleByRole {
            id
            code
            name
          }
        }
      }
    }
  }
`;

export const GET_CUSTOMER_BY_CODE_FILTER = gql`
  query GetCustomers($skip: Int!, $take: Int!, $where: customer_bool_exp!) {
    results: customer(offset: $skip, limit: $take, where: $where, order_by: { accountByAccount: { created: desc_nulls_last } }) {
      id
      info: accountByAccount {
        id
        code
        full_name
        id_name
        email
        phone
        personalid
        avatar: medium {
          id
          name
          url
        }
        active
        online
        id_status
        last_activity
      }
      ref_employee_info: employeeByRefEmployee {
        info: accountByAccount {
          id
          code
          full_name
          email
          phone
        }
      }
      consult_employee_info: employee {
        branchByBranch {
          id
          code
          name
        }
        info: accountByAccount {
          id
          code
          full_name
          email
          phone
        }
      }
    }
    t: customer_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CUSTOMER_BY_STATUS_SUMMARY = gql`
  query GetCustomerByStatusSummary{
    default: customer_aggregate(
      where: {
        accountByAccount: {
          deleted: {_eq: false},
          id_status: {_is_null: true},
          type: {_eq: "CUSTOMER"}
        }
      }
    ){
      aggregate {
        count
      }
    }

    initital: customer_aggregate(
      where: {
        accountByAccount: {
          deleted: {_eq: false},
          id_status: {_in: ["INITIAL", "IDENTITY_UPLOADED"]},
          type: {_eq: "CUSTOMER"}
        }
      }
    ){
      aggregate {
        count
      }
    }

    verified: customer_aggregate(
      where: {
        accountByAccount: {
          deleted: {_eq: false},
          id_status: {_in: ["VERIFIED"]},
          type: {_eq: "CUSTOMER"}
        }
      }
    ){
      aggregate {
        count
      }
    }

    reject: customer_aggregate(
      where: {
        accountByAccount: {
          deleted: {_eq: false},
          id_status: {_in: ["REJECT"]},
          type: {_eq: "CUSTOMER"}
        }
      }
    ){
      aggregate {
        count
      }
    }
  }
`;

export const GET_ALL_SHORT_CUSTOMER = gql`
  query GetCustomers($skip: Int!, $take: Int!) {
    results: customer(offset: $skip, limit: $take) {
      id
      info: accountByAccount {
        id
        code
        full_name
        phone
        email
        personalid
      }
      ref_employee_info: employeeByRefEmployee {
        id
        info: accountByAccount {
          id
          code
        }
      }
    }
    total: customer_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ACTIVITY_HISTORY_BY_CUSTOMER = gql`
  query GetHistoryByCustomer($customer: bigint!, $type: String!) {
    request_workflow(
      where: {
        requestByRequest: { customer: { _eq: $customer } }
        type_workflow: { typeByType: { code: { _eq: $type } } }
      }
    ) {
      type_workflow {
        type_step {
          id
          code
          name
        }
      }
      requestByRequest {
        saler: account {
          id
          code
          full_name
        }
        productByProduct {
          productByProduct {
            id
            code
          }
        }
      }
      created
      account {
        id
        code
        full_name
      }
    }
    request_review(
      where: {
        requestByRequest: { customer: { _eq: $customer } }
        type_step: { typeByType: { code: { _eq: $type } } }
      }
    ) {
      status
      note
      created
      created_by
      requestByRequest {
        productByProduct {
          productByProduct {
            id
            code
          }
        }
      }
    }
  }
`;

export const GET_ACTIVITY_HISTORY_BY_USER = gql`
  query GetHistoryByUser($user: bigint!, $type: String!) {
    request_workflow(
      where: {
        created_by: { _eq: $user }
        type_workflow: { typeByType: { code: { _eq: $type } } }
      }
    ) {
      type_workflow {
        typeByType {
          id
          code
          name
        }
      }
      id
      step
      type_workflow {
        type_step {
          id
          code
          name
        }
      }
      requestByRequest {
        customer
        customerByCustomer {
          id
          code
          name
        }
        productByProduct {
          productByProduct {
            id
            code
          }
        }
      }
      created
      account {
        id
        code
        full_name
      }
    }
    request_review(
      where: {
        user: { _eq: $user }
        type_step: { typeByType: { code: { _eq: $type } } }
      }
    ) {
      type_step {
        typeByType {
          id
          code
          name
        }
      }
      status
      note
      created
      created_by
      requestByRequest {
        productByProduct {
          productByProduct {
            id
            code
          }
        }
      }
    }
  }
`;

export const UPDATE_CUSTOMER_KYC = gql`
  mutation AdminVerifyIdentity($data: UpdateAccountInput!) {
    updateAccount(args: $data) {
      success
    }
  }
`;

export const GET_CUSTOMER_BY_CODE_FILTER_FOR_AUTOCOMPLETE = gql`
  query GetCustomers($skip: Int!, $take: Int!, $where: customer_bool_exp!) {
    results: customer(offset: $skip, limit: $take, where: $where) {
      id
      info: accountByAccount {
        id
        code
        full_name
        email
        phone
        personalid
        avatar: medium {
          id
          name
          url
        }
        active
        online
        id_status
        last_activity
      }
      ref_employee_info: employeeByRefEmployee {
        info: accountByAccount {
          id
          code
          full_name
          email
          phone
        }
      }
      consult_employee_info: employee {
        branchByBranch {
          id
          code
          name
        }
        info: accountByAccount {
          id
          code
          full_name
          email
          phone
        }
      }
    }
  }
`;
export const GET_LIST_BANK = gql`
  query GetListBank {
    result: bank {
      id
      code
      name
    }
  }
`;
