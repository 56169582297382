import * as _ from 'lodash';
import React, { Fragment, useContext, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Box, IconButton, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import PrimaryCard from "../../../components/Card/index";
import Button from '@material-ui/core/Button';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { useHistory } from 'react-router-dom';
import slugs from '../../../resources/slugs';
import { PROJECT_GET_ALL } from '../../../graphql/schemas/product/projectQueries';
import { Waypoint } from 'react-waypoint';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputDropdown from '../Secondary/components/InputDropdown';
import { ArrowBack } from '@material-ui/icons';
import ButtonReturn from '../../../components/ButtonReturn';
import { StoreContext } from '../../../resources/store';

import { CREATE_PRIMARY, UPDATE_PRIMARY, USER_ROLE_SUPPER_ADMIN } from '../../../constants/defined';
import { checkedPermission } from '../../../helper';

const useStyles = makeStyles((theme) => ({
    headerBar: {
        justifyContent: 'space-between',
        marginBottom: '30px',

        '& > div:last-child': {
            textAlign: 'right',
        },
    },
    wrapperList: {
        minHeight: '350px',
        margin: '30px 0',
    },
    nodataStyle: {
        color: "#E27F35",
        fontSize: '16px',
        fontWeight: 'bold'
    },
    plus: {
        fontSize: "38px",
        color: "#00837b"
    },
    cart: {
        marginRight: "10px",
        fontSize: "38px",
        color: "#e27f35",
        cursor: "pointer"
    },
    buttonSecondaryStyle: {
        backgroundColor: '#E27F35',
        '&:hover': {
            backgroundColor: '#c56e2d',
        }
    },
}));

const PrimaryProduct = () => {
    const history = useHistory();
    const classes = useStyles();
    const [orderType, setOrderType] = useState({ type: 'desc' });

    const PER_PAGE = 10;

    const { loading, data, fetchMore } = useQuery(PROJECT_GET_ALL, {
        variables: {
            skip: 0,
            take: PER_PAGE,
            order_by: { created: _.get(orderType, 'type') },
            where: {
                deleted: { _eq: false },
                propertyTypeByPropertyType: {
                    code: {
                        _eq: "P"
                    }
                }
            }
        }
    });

    const { accessObjectPermissions, userInfo } = useContext(StoreContext);

    if (!data || loading) return <CircularProgress />

    return (
        <Fragment>
            <Grid container direction="row" className={classes.headerBar}>
                <Grid item xs={6} className={classes.left}>
                    <InputDropdown
                        discription='Sắp xếp'
                        fieldname='type'
                        object={orderType}
                        setObject={setOrderType}
                        data={[{ id: 'desc', name: 'Mới nhất' }, { id: 'asc', name: 'Cũ nhất' }]}
                    />
                </Grid>
                <Grid item xs={6} className={classes.right}>
                    <AddShoppingCartIcon
                        style={{
                            cursor: !checkedPermission(userInfo, accessObjectPermissions, UPDATE_PRIMARY) && 'not-allowed'
                        }}
                        className={classes.cart}
                        onClick={() => {
                            if (checkedPermission(userInfo, accessObjectPermissions, UPDATE_PRIMARY)) {
                                history.push(slugs.productCartPrimaryCreate)
                            }

                            return;
                        }}
                    />
                    <PostAddIcon
                        className={classes.plus}
                        style={{ cursor: checkedPermission(userInfo, accessObjectPermissions, CREATE_PRIMARY) ? 'pointer' : 'not-allowed' }}
                        onClick={() => {
                            if (checkedPermission(userInfo, accessObjectPermissions, CREATE_PRIMARY)) {
                                history.push("/product/primary/create")
                            }

                            return;

                        }}
                    />

                </Grid>

            </Grid>

            <Box display="flex" className={classes.wrapperList}>
                <Grid container direction="row" alignItems="flex-start">
                    <Grid container direction="row">
                        {_.size(_.get(data, 'projects')) > 0 ? _.map(data.projects, (item, index) => {
                            return (
                                <Fragment key={index}>
                                    <PrimaryCard data={item} />

                                    {index === _.size(data.projects) - 1 && (
                                        <Waypoint
                                            onEnter={() =>
                                                fetchMore({
                                                    variables: {
                                                        skip: (_.size(data.projects) / PER_PAGE) * PER_PAGE,
                                                        take: PER_PAGE
                                                    },
                                                    updateQuery: (previousResult, { fetchMoreResult }) => {
                                                        if (!_.get(fetchMoreResult, 'projects')) {
                                                            return previousResult
                                                        };
                                                        return {
                                                            projects: [
                                                                ...previousResult.projects,
                                                                ...fetchMoreResult.projects
                                                            ],
                                                        }
                                                    }
                                                })
                                            }
                                        />
                                    )}
                                </Fragment>
                            )
                        })
                            :
                            (
                                <Typography className={classes.nodataStyle}>
                                    Không có dữ liệu thích hợp
                                </Typography>
                            )}


                    </Grid>
                </Grid>
            </Box>

            <Grid container direction="row" justifyContent='flex-end'>
                <ButtonReturn link={slugs.product} />
            </Grid>
        </Fragment >
    );
};
export default PrimaryProduct;
