import React from 'react';
import PropTypes from 'prop-types';

PrimaryCartList.propTypes = {

};

function PrimaryCartList(props) {
    return (
        <div>
            <h1>Danh sách giỏ hàng</h1>
        </div>
    );
}

export default PrimaryCartList;