import * as _ from 'lodash';
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
    MenuItem, FormControl, Select
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        '&:not(.fullwidth)': {
            width: 250,
        },
        '&.fullwidth': {
            width: '100%',
        },
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    defaultPadding: {
        '&>div': {
            padding: '10.5px 14px',
        },
    },

}));

const InputDropdown = ({ discription = "Chọn", fieldname, object, setObject, data, required, fullwidth, defaultValue }) => {
    const [selectValue, setSelectValue] = useState(defaultValue ? defaultValue : _.get(object, fieldname));
    const classes = useStyles();
    const handleChange = (e) => {
        e.preventDefault();
        const value = _.get(e, 'target.value');
        if(discription === 'Chọn loại sản phẩm') {
            window.localStorage.setItem('product_type', value);
        }
        if (_.get(object, fieldname) === value) return;
        let tempObject = { ...object };
        _.set(tempObject, fieldname, value);
        setSelectValue(value);
        setObject(tempObject);
    };

    return (
        <Fragment>
            <FormControl variant="outlined" className={clsx(classes.formControl, { 'fullwidth': fullwidth })}>
                <Select
                    className={classes.defaultPadding}
                    id={`input-dropdown-${fieldname}`}
                    value={fieldname === "type_list" ? defaultValue : selectValue}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                >
                    <MenuItem value="" disabled>{discription}</MenuItem>
                    {
                        _.map(data, (item, key) => {
                            return <MenuItem key={key} value={_.get(item, 'id')}>{_.get(item, 'name')}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        </Fragment>
    );
};

InputDropdown.propTypes = {
    fieldname: PropTypes.string,
    object: PropTypes.object,
    setObject: PropTypes.func,
    discription: PropTypes.string,
    data: PropTypes.array,
};

export default InputDropdown;
