import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import swal from "sweetalert";
import { BUY_TICKET } from "../../constants/defined";
import {
  CREATE_EVENT,
  INSERT_RESULT_BINGO_ROUND,
  UPDATE_EVENT,
  UPDATE_VOUCHER_GAME,
  UPDATE_REDEEM_ECOIN_GAME_SETTING,
} from "../../graphql/schemas/event/eventMutation";
import {
  GET_BINGO_RESULT_BY_EVENT,
  GET_BINGO_RESULT_DETAIL,
  GET_BINGO_SETTING_CODES,
  GET_COUNT_BINGO_RESULT_BY_EVENT,
  GET_CUSTOMER,
  GET_EVENT_DETAIL,
  GET_EVENT_TYPES,
  GET_GIFT_BY_ACCOUNT,
  GET_GIFT_TYPES,
  GET_JACKPOT_TYPE,
  GET_JACK_VOUCHER_DETAIL,
  GET_LIST_APPLY_TYPES,
  GET_LIST_CUSTOMER_BY_EVENT,
  GET_LIST_EVENT,
  GET_LIST_JACK_VOUCHER_TRANSACTION,
  GET_LIST_TRANSACTION,
  GET_LIST_TRANSACTION_BINGO,
  GET_LIST_TRANSACTION_EVENT_VOUCHER,
  GET_LIST_TRANSACTION_JACK_GIFT,
  GET_LIST_UPDATE_HISTORY,
  GET_LIST_VOUCHER_GAME,
  GET_SOURCE_TYPE,
  GET_VOUCHER_BY_ACCOUNT,
  UPDATE_BINGO_EXCHANGE_STATUS,
} from "../../graphql/schemas/event/eventQueries";

export const useGetEventTypes = () => {
  const { data, loading, error, refetch } = useQuery(GET_EVENT_TYPES, {});
  return {
    data: _.get(data, "result"),
    loading,
    error,
    refetch,
  };
};

export const useGetListEvent = () => {
  const { data, loading, error, refetch } = useQuery(GET_LIST_EVENT, {
    variables: {
      skip: 0,
      take: 1000,
    },
  });
  return {
    data: _.get(data, "result"),
    loading,
    error,
    refetch,
  };
};

export const useGetEventDetail = (id) => {
  const { data, loading, error, refetch } = useQuery(GET_EVENT_DETAIL, {
    variables: {
      id,
    },
  });
  return {
    data: _.head(_.get(data, "result")),
    loading,
    error,
    refetch,
  };
};

export const useGetEventCustomers = (id) => {
  const { data, loading, error, refetch } = useQuery(
    GET_LIST_CUSTOMER_BY_EVENT,
    {
      variables: {
        event: id,
      },
    }
  );
  return {
    data: _.get(data, "result"),
    loading,
    error,
    refetch,
  };
};

export const useGetEventCustomer = (id) => {
  const { data, loading, error, refetch } = useQuery(GET_CUSTOMER, {
    variables: {
      account: id,
    },
  });
  return {
    data,
    loading,
    error,
    refetch,
  };
};

export const useGetListTransaction = ({id, fromDate, toDate, searchText="", isSkip = false}) => {
  const { data, loading, error, refetch } = useQuery(GET_LIST_TRANSACTION, {
    variables: {
      where: {
        event: {
          _eq: id,
        },
        typeByType: {
          code: {
            _eq: "CHANGEGIFT",
          },
        },
        created: fromDate && toDate ? {
          _gte: fromDate,
          _lte: toDate,
        } : undefined,
        _or: _.size(searchText) > 0 ? [
          {
            accountByAccount: {
              _or: [
                {
                  full_name: { _ilike: `%${searchText}%` }
                },
                {
                  code: { _ilike: `%${searchText}%` }
                }
              ]
            }
          },
          {
            giftByGift: {
              _or: [
                {
                  code: { _ilike: `%${searchText}%` }
                },
                {
                  name: { _ilike: `%${searchText}%` }
                }
              ]
            }
            
          }
        ] : undefined
      },
    },
    skip: !id || isSkip
  });
  return {
    data: _.get(data, "result"),
    loading,
    error,
    refetch,
  };
};

export const useGetListJackVoucherTransaction = ({id, fromDate, toDate, giftType = 'VOUCHER', codeFilter, searchText="", isSkip = false}) => {
  const { data, loading, error, refetch } = useQuery(
    GET_LIST_JACK_VOUCHER_TRANSACTION,
    {
      variables: {
        where: {
          accountGiftByAccountGift: {
            event: {
              _eq: id,
            },
            giftByGift: {
              code: codeFilter !== "ALL" ? {_eq: codeFilter} : undefined,
              gift_type: {
                code: {
                  _eq: giftType,
                },
              },
            },
          },
          created: fromDate && toDate ? {
            _gte: fromDate,
            _lte: toDate,
          } : undefined,
          _or: _.size(searchText) > 0 ? [
            {
              accountByAccount: {
                _or: [
                  {
                    full_name: { _ilike: `%${searchText}%` }
                  },
                  {
                    personalid: { _ilike: `%${searchText}%` }
                  }
                ]
              }
            },
            {
              exchange_full_name: { _ilike: `%${searchText}%` }
            },
            {
              exchange_email: { _ilike: `%${searchText}%` }
            },
            {
              exchange_phone: { _ilike: `%${searchText}%` }
            },
            {
              exchange_product: { _ilike: `%${searchText}%` }
            }
          ] : undefined
        },
      },
      skip: !id || isSkip
    }
  );

  return {
    data: _.get(data, "list_voucher"),
    loading,
    error,
    refetch,
  };
};

export const useGetListTransactionJackGift = ({id, fromDate, toDate, isSkip = false, skip = 0, take = 10}) => {
  const { data, loading, error, refetch } = useQuery(
    GET_LIST_TRANSACTION_JACK_GIFT,
    {
      variables: {
        skip,
        take,
        where: {
          event: {
            _eq: 135
            },
            ecoin: {
              _gt: 0
            },
            _and: fromDate && toDate ? [
                {
                  created: fromDate ? {
                      _gte: fromDate
                    } : undefined
                }, 
                {
                  created: toDate ? {
                      _lte: toDate
                    } : undefined
                }
            ] : undefined
        }
    },
      skip: !id || isSkip
    }
  );

  return {
    data,
    loading,
    error,
    refetch,
  };
};

export const useCreateEvent = (history, setSubmitDisabled) => {
  const [eventCreate, { loading }] = useMutation(CREATE_EVENT, {
    onError: async (err) => {
      return await swal({
        title: `${err.message}`,
        icon: "error",
      }).then(() => {
        _.isFunction(setSubmitDisabled) && setSubmitDisabled(false);
      });
    },
    onCompleted: async () => {
      return await swal({
        title: "Tạo event thành công",
        icon: "success",
      }).then(() => {
        _.isFunction(setSubmitDisabled) && setSubmitDisabled(false);
        history.push("/promotion");
      });
    },
  });
  return {
    eventCreate,
    loading,
  };
};

export const useUpdateEvent = (gameId, setIsEdit, setDisableButton) => {
  const [updateEvent, { loading, data }] = useMutation(UPDATE_EVENT, {
    refetchQueries: [
      {
        query: GET_EVENT_DETAIL,
        variables: {
          id: gameId,
        },
      },
    ],
    onError: async (err) => {
      return await swal({
        title: "Cập nhật thất bại",
        text: `${err.message}`,
        icon: "error",
      }).then(() => {
        _.isFunction(setIsEdit) && setIsEdit(false);
        _.isFunction(setDisableButton) && setDisableButton(false)
      });
    },
    onCompleted: async () => {
      return await swal({
        title: "Cập nhật sự kiện thành công",
        icon: "success",
      }).then(() => {
        _.isFunction(setIsEdit) && setIsEdit(false);
        _.isFunction(setDisableButton) && setDisableButton(false)
      });
    },
  });
  return {
    updateEvent,
    loading,
    data,
  };
};

export const useGetGiftTypes = () => {
  const { data, loading, error } = useQuery(GET_GIFT_TYPES, {});
  return {
    data: _.get(data, "result"),
    loading,
    error,
  };
};

export const useGetBingoSettingCodes = () => {
  const { data, loading, error } = useQuery(GET_BINGO_SETTING_CODES, {});
  return {
    data: _.get(data, "result"),
    loading,
    error,
  };
};

export const useGetJackpotType = ({code = null}) => {
  const { data, loading, error } = useQuery(GET_JACKPOT_TYPE, {
    variables: {
      code
    },
    skip: !code
  });
  return {
    data: _.get(data, "results"),
    loading,
    error,
  };
};

export const useGetListVoucher = (variables, id) => {
  const { data, loading, error } = useQuery(GET_LIST_VOUCHER_GAME, {
    variables,
    skip: !id,
  });

  return {
    data: _.get(data, "list_voucher"),
    count: _.get(data, "t.aggregate.count") || 0,
    loading,
    error,
  };
};

export const useGetListVoucherByAccount = (accountId, skip, take) => {
  const { data, loading, error } = useQuery(GET_VOUCHER_BY_ACCOUNT, {
    variables: {
      account: accountId,
      skip,
      take,
    },
    skip: !accountId,
  });

  return {
    data,
    loading,
    error,
  };
};

export const useGetListGiftByAccount = (accountId, skip, take) => {
  const { data, loading, error } = useQuery(GET_GIFT_BY_ACCOUNT, {
    variables: {
      account: accountId,
      skip,
      take,
    },
    skip: !accountId,
  });

  return {
    data,
    loading,
    error,
  };
};

export const useGetListTransactionBingo = ({id, fromDate, toDate, bingoPrizeType, searchText="", isSkip = false}) => {
  const { data, loading, error, refetch } = useQuery(GET_LIST_TRANSACTION_BINGO,{
    variables: {
      where: {
        event: {_eq: id},
        created: fromDate && toDate ? {
          _gte: fromDate,
          _lte: toDate,
        } : undefined,
        account_ticket_results: bingoPrizeType !=="ALL" ? {
          "bingo_result": {
              "bingo_result_setting": {
                  "special": {
                      "_eq": bingoPrizeType === "FINAL"
                  }
              }
          }
        } : undefined,
        _or: _.size(searchText) > 0 ? [
          {
            accountByAccount: {
              _or: [
                {
                  full_name: { _ilike: `%${searchText}%` }
                },
                {
                  code: { _ilike: `%${searchText}%` }
                }
              ]
            }
          },
          {
            code: { _ilike: `%${searchText}%` }
          }
        ] : undefined
      }
    },
    skip: !id || isSkip,
  }
  );
  return {
    data,
    loading,
    error,
    refetch,
  };
};

export const useGetListApplyType = () => {
  const { data, loading, error } = useQuery(GET_LIST_APPLY_TYPES, {});
  return {
    data: _.get(data, "result"),
    loading,
    error,
  };
};

export const useGetUpdateHistory = (id) => {
  const { data, loading, error, refetch } = useQuery(GET_LIST_UPDATE_HISTORY, {
    variables: {
      entity_id: id.toString(),
    },
  });
  return {
    data: _.get(data, "log_event"),
    loading,
    error,
    refetch,
  };
};

export const useGetBingoResultByEvent = ({id, fromDate, toDate, filterResult, skip = 0, take = 1e9}) => {
  const { data, loading, error, refetch } = useQuery(GET_BINGO_RESULT_BY_EVENT, {
    variables: {
      skip,
      take,
      where: {
        event: {
          _eq: parseInt(id),
        },
        result_date: fromDate && toDate ? {
          _gte: fromDate,
          _lte: toDate,
        } : undefined,
        bingo_result_setting: filterResult > 0 ? {id: {_eq: filterResult}} : undefined
      }
    },
  });

  return {
    data: _.get(data, "result"),
    loading,
    error
  };
};

export const useGetCountBingoResultByEvent = ({id, fromDate, toDate, filterResult}) => {
  const { data, loading, error } = useQuery(GET_COUNT_BINGO_RESULT_BY_EVENT, {
    variables: {
      where: {
        event: {
          _eq: parseInt(id),
        },
        result_date: fromDate && toDate ? {
          _gte: fromDate,
          _lte: toDate,
        } : undefined,
        bingo_result_setting: filterResult > 0 ? {id: {_eq: filterResult}} : undefined
      }
    },
  });

  return {
    data: _.get(data, "count.aggregate.count"),
    loading,
    error
  };
};

export const useGetListTransactionEventVoucher = ({
  id,
  skip = 0,
  take = 1e9,
  fromDate,
  toDate,
  searchText = "",
  isSkip = false,
}) => {
  const { data, loading, error, refetch } = useQuery(
    GET_LIST_TRANSACTION_EVENT_VOUCHER,
    {
      variables: {
        skip,
        take,
        where: {
          voucherTypeByVoucherType: {
            code: {
              _eq: "GRATITUDE",
            },
          },
          promotionByPromotion: {
            event: {
              _eq: id,
            },
          },
          created: fromDate && toDate ? {
            _gte: fromDate,
            _lte: toDate,
          } : undefined,
          _or: _.size(searchText) > 0 ? [
            {
              accountByAccount: {
                _or: [
                  {
                    full_name: { _ilike: `%${searchText}%` }
                  },
                  {
                    personalid: { _ilike: `%${searchText}%` }
                  }
                ]
              }
            },
            {
              exchange_full_name: { _ilike: `%${searchText}%` }
            },
            {
              exchange_email: { _ilike: `%${searchText}%` }
            },
            {
              exchange_phone: { _ilike: `%${searchText}%` }
            },
            {
              exchange_product: { _ilike: `%${searchText}%` }
            }
          ] : undefined
        },
      },
      skip: !id || isSkip,
    }
  );
  return {
    data: _.get(data, "list_voucher"),
    loading,
    error,
    refetch,
  };
};

export const useGetBingoResultDetail = (event, id) => {
  const { data, loading, error, refetch } = useQuery(GET_BINGO_RESULT_DETAIL, {
    variables: {
      event,
      id,
    },
  });
  return {
    data: _.head(_.get(data, "result")),
    loading,
    error,
  };
};
export const useUpdateVoucherGame = (setIsEdit, id) => {
  const [updateVoucherGame, { loading, data }] = useMutation(
    UPDATE_VOUCHER_GAME,
    {
      refetchQueries: [
        {
          query: GET_JACK_VOUCHER_DETAIL,
          variables: {
            where: {
              id: {
                _eq: id,
              },
            },
          },
        },
      ],
      onError: async (err) => {
        return await swal({
          title: "Cập nhật thất bại",
          text: `${err.message}`,
          icon: "error",
        }).then(() => {
          setIsEdit(false);
        });
      },
      onCompleted: async () => {
        return await swal({
          title: "Cập nhật sự kiện thành công",
          icon: "success",
        }).then(() => {
          setIsEdit(false);
        });
      },
    }
  );
  return {
    updateVoucherGame,
    loading,
    data,
  };
};

export const useUpdateBingoExchangeStatus = (event, id) => {
  const [updateBingoExchangeStatus, { loading, data }] = useMutation(
    UPDATE_BINGO_EXCHANGE_STATUS,

    {
      refetchQueries: [
        {
          query: GET_BINGO_RESULT_DETAIL,
          variables: {
            event,
            id,
          },
        },
      ],
      onError: async (err) => {
        return await swal({
          title: "Cập nhật thất bại",
          text: `${err.message}`,
          icon: "error",
        });
      },
      onCompleted: async () => {
        return await swal({
          title: "Cập nhật sự kiện thành công",
          icon: "success",
        });
      },
    }
  );
  return {
    updateBingoExchangeStatus,
    loading,
    data,
  };
};

export const useGetJackVoucherDetail = (id) => {
  const { data, loading, error, refetch } = useQuery(GET_JACK_VOUCHER_DETAIL, {
    variables: {
      where: {
        id: {
          _eq: id,
        },
      },
    },
  });
  return {
    data: _.head(_.get(data, "list_voucher")),
    loading,
    error,
    refetch,
  };
};

export const useInsertResultBingoRound = (history, setSubmitDisabled, eventId) => {
  const [insertResultBingoRound, { loading, data }] = useMutation(INSERT_RESULT_BINGO_ROUND, {
    refetchQueries: [
      {
        query: GET_EVENT_DETAIL,
        variables: {
          id: eventId,
        },
      },
    ],
    onError: async (err) => {
      return await swal({
        title: `${err.message}`,
        icon: "error",
      }).then(() => {
        _.isFunction(setSubmitDisabled) && setSubmitDisabled(false);
      });
    },
    onCompleted: async () => {
      return await swal({
        title: "Cập nhật kết quả thành công",
        icon: "success",
      }).then(() => {
        _.isFunction(setSubmitDisabled) && setSubmitDisabled(false);
        // history.push("/promotion");
        history.goBack();
      });
    },
  });
  return {
    insertResultBingoRound,
    loading,
    data,
  };
};

export const useGetSourceType = () => {
  const { data, loading, error } = useQuery(GET_SOURCE_TYPE, {});
  return {
    data: _.get(data, "result"),
    loading,
    error,
  };
};

export const useUpdateRedeemEcoinGameSetting = () => {
  const [updateRedeemEcoinGameSetting, { loading, data, error }] = useMutation(UPDATE_REDEEM_ECOIN_GAME_SETTING, {
    refetchQueries: [
      {
        query: GET_JACKPOT_TYPE,
        variables: {
          code: BUY_TICKET,
        },
      },
    ],
    onError: (err) => {
      return swal({
        title: "Lỗi cập nhật số Ecoin quy đổi",
        icon: "error",
      })
    },
    onCompleted: () => {
      return
    },
  });
  return {
    updateRedeemEcoinGameSetting,
    loading,
    data,
    error
  };
};
