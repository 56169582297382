//content tab - ApprovalHistory
import {
  CircularProgress,
  Divider,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useParams } from "react-router";
import ecoeIcon from "../../../assets/icons/logo-ecoe-no-char.svg";
import ButtonReturn from "../../../components/ButtonReturn";
import slugs from "../../../resources/slugs";
import { useGetProjectCartStatusHistory } from "./hooks";

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "none",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: 120,
  },
}))(MuiAccordionDetails);

const ApprovalHistory = (props) => {
  const useStyles = makeStyles((theme) => ({
    circle: {
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      marginRight: "32px",
    },
    message: {
      fontSize: "18px",
      marginLeft: 20,
      wordWrap: "break-word",
    },
    buttonBack: {
      backgroundColor: "black",
      color: "#ffffff",
      borderRadius: 0,
      marginTop: "40px",
      padding: "5px 30px",
    },
    groupButtonFooter: {
      justifyContent: "flex-end",
      "& > button:not(:last-child)": {
        marginRight: "30px",
      },
    },
    marginDivider: {
      margin: "30px 0",
    },
  }));
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  let { id } = useParams();

  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    if (!expanded) setExpanded("panel1");
  });

  const { loading, data } = useGetProjectCartStatusHistory({ id: id });

  const approvalData = _.chain(data)
    .groupBy("project_cart.name")
    .map((value, key) => ({ key: key, data: value }))
    .value();

  return (
    <div className={classes.root}>
      <Grid container direction="column">
        <Grid
          container
          direction="row"
          style={{ marginTop: "60px", flexWrap: "wrap" }}
          alignItems="center"
        >
          {loading ? (
            <CircularProgress />
          ) : _.size(approvalData) > 0 ? (
            _.map(approvalData, (item, key) => {
              return (
                <Accordion
                  key={key}
                  square
                  expanded={expanded === `panel${item.key}`}
                  onChange={handleChange(`panel${item.key}`)}
                >
                  <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                    key={key}
                  >
                    <Grid container direction="row" alignItems="center">
                      <Grid
                        item
                        className={classes.circle}
                        style={{ backgroundColor: "#dfe235" }}
                      ></Grid>
                      <Grid item>
                        <img src={ecoeIcon} alt="logo" width={40} />
                      </Grid>
                      <Grid
                        item
                        className={classes.message}
                        style={{ width: matchesSM ? "500px" : "700px" }}
                      >
                        {item.key}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  {_.map(item.data, (item, key) => (
                    <AccordionDetails key={key}>
                      <Grid container direction="row" alignItems="center">
                        <Grid item>
                          <img src={ecoeIcon} alt="logo" width={40} />
                        </Grid>
                        <Grid
                          item
                          className={classes.message}
                          style={{ width: matchesSM ? "500px" : "700px" }}
                        >
                          {item.account?.full_name
                            ? item.account.full_name
                            : "Hệ thống"}{" "}
                          {item.project_cart_status.name}
                        </Grid>
                        <Grid item style={{ fontSize: "18px" }}>
                          <span>
                            <Moment format="DD/MM/YYYY hh:mm">
                              {_.get(item, "created")}
                            </Moment>
                          </span>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  ))}
                </Accordion>
              );
            })
          ) : (
            <div> No data </div>
          )}
        </Grid>

        <Divider className={classes.marginDivider} />

        <Grid container direction="row" className={classes.groupButtonFooter}>
          <ButtonReturn link={slugs.productPrimary} />
        </Grid>
      </Grid>
    </div>
  );
};
export default ApprovalHistory;
