import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { GET_CUSTOMER_BY_CODE_FILTER } from '../graphql/schemas/customer/customerQueries';
import { GET_EMPLOYEE_BY_CODE } from '../graphql/schemas/employee/employeeQueries';
import { GET_PRODUCT_BY_CODE } from '../graphql/schemas/product/productQueries';
import { useQuery } from '@apollo/client';
import * as _ from "lodash";

AutoCompleteProduct.propTypes = {

};

function AutoCompleteProduct({ type, onChange, value }) {
    const [searchText, setSearchText] = useState("");
    var listOption = [];
    const variables = {
        skip: 0,
        take: 10,
        condition: {
            deleted: { _eq: false },
            code: {
                _ilike: `%${searchText}%`
            },
            status: { _eq: "SELLING" }
        }
    }

    const { loading, data } = useQuery(GET_PRODUCT_BY_CODE, { variables: variables });

    if (!_.isEmpty(_.get(data, 'products'))) {
        listOption = _.map(_.get(data, 'products'), (item) => {
            return {
                id: item.id,
                code: item.code
            }
        });
    }



    const handleSelect = (e, value) => {
        onChange(value.id);
    }

    return (
        <Autocomplete
            id={`combo-box-${type}`}
            options={listOption}
            getOptionLabel={(option) => _.toString(option.code)}
            defaultValue={{ code: value }}
            onChange={handleSelect}
            // style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} variant="outlined" size="small" style={{ backgroundColor: '#ffffff' }} value={value} onChange={(e) => setSearchText(e.target.value)} />}
        />
    );
}

export default AutoCompleteProduct;