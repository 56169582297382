import React, { useState, useEffect, useMemo, useContext } from 'react'
import Grid from '@material-ui/core/Grid';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import { useHistory } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import { useQuery, useMutation } from '@apollo/client';
import firebase from 'firebase';
import { user } from '../../constants/user';
import * as _ from "lodash";
import { UPDATE_DEVICE_BY_ACCOUNT } from "../../graphql/schemas/notification/notifyMutation"
// import { browserName, browserVersion } from "react-device-detect";
import { onMessageListener } from "../../firebase/config/firebase";
import "firebase/messaging";
import { GET_COUNT_HOME_PAGE } from '../../graphql/schemas/notification';
import { StoreContext } from '../../resources/store';

const MenuNotify = () => {
    const history = useHistory();
    const { userInfo, accessPermissions } = useContext(StoreContext);
    const noti_token = localStorage.getItem("notify_token");
    const account = _.get(userInfo,"id");
    const [notiNumber, setNotiNumber] = useState(0);
    const [updateDevice, { data, loading, error }] = useMutation(UPDATE_DEVICE_BY_ACCOUNT);
    const { data: dataCounting } = useQuery(GET_COUNT_HOME_PAGE, {
        variables: {
            account: account
        }
    })

    useEffect(() => {
        updateDevice({
            variables: {
                data: {
                    device_id: noti_token ? noti_token : "",
                    device_info: null,
                    device_token: noti_token ? noti_token : "",
                    os_info: "WEB",
                    version_info: "Chrome.0/ECOE.V.1.0",
                    apns_token: null,
                    app_type: "WEBADMIN"
                }
            }
        });
    }, []);
    useEffect(() => {
        if (dataCounting) {
            setNotiNumber(_.get(dataCounting, 'all_count.aggregate.count', 0))
        }
    }, [dataCounting])

    useEffect(() => {
        onMessageListener()
            .then((payload) => {
                // setShow(true);
                // setNotification({
                //     title: payload.notification.title,
                //     body: payload.notification.body,
                // });
                setNotiNumber(notiNumber + 1);
                // console.log("Message", payload);
            })
            .catch((err) => console.log("failed: ", err));

    })

    //TODO call API
    return (
        <Grid container direction="row" justifyContent="flex-end">
            <Grid item>
                <IconButton>
                    <Badge
                        color="secondary"
                        badgeContent={notiNumber}
                    >
                        <NotificationsNoneIcon onClick={() => history.push('/notify')} style={{ fontSize: 26, color: '#ffffff' }}
                        />
                    </Badge>
                </IconButton>
            </Grid>
        </Grid>
    )
}

export default MenuNotify
