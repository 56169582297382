import * as _ from 'lodash';
import React, { Fragment, useState, useCallback, useEffect, useContext } from 'react';
import { useHistory, useParams, Redirect, Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { PROJECT_GET_ALL, PROJECT_GET_ONE } from '../../../graphql/schemas/product/projectQueries';
import {
    Grid,
    Typography,
    Card,
    CardContent,
    CardActionArea,
    CardMedia,
    Button,
    Paper,
    TextField,
    useMediaQuery,
    CircularProgress,
    Divider
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Home, Apartment, Nature, Build, ViewList, Straighten, DataUsage, CheckCircle, Telegram, Edit } from '@material-ui/icons';
import Moment from 'react-moment';
import InputDropdown from '../Secondary/components/InputDropdown';
import { user } from '../../../constants/user';
import { useGetEmployeeByAccount } from '../../../graphql/schemas/hooks';
import ImageViewer from "react-simple-image-viewer";
import { APPROVE_PRODUCT } from '../../../graphql/schemas/product/mutations';
import swal from 'sweetalert';
import ButtonReturn from '../../../components/ButtonReturn';
import clsx from 'clsx';
import slugs from '../../../resources/slugs';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { ConfigContext } from '../../../context/ConfigContext';
import { StoreContext } from '../../../resources/store';

import { UPDATE_PRIMARY, USER_ROLE_SUPPER_ADMIN } from '../../../constants/defined'
import { checkedPermission } from '../../../helper';

const rangeFormat = (a, b) => {
    let from = 0, to = 0;
    if (a * 0 === 0 && a !== null) from = a;
    if (b * 0 === 0 && b !== null) to = b;

    if (from === to) return from;
    if (from - to < 0) return _.join([from, to], ' - ');
    return _.join([to, from], ' - ');
}

const useStyles = makeStyles(theme => ({
    infoContainer: {
        '& > .MuiGrid-container': {
            '& > .MuiGrid-item': {
                display: 'flex',
                '& > p:nth-child(1)': {
                    fontWeight: 'bold'
                },
                alignItems: 'center',
                '& > p:nth-child(2)': {
                    marginLeft: 20,
                    fontSize: 14
                },

            },
        }
    },
    boxTitleTop: {
        width: '60%',
        marginTop: 20,
        marginBottom: 20
    },
    cardRootTop: {
        width: 420,
        minHeight: 350,
        marginBottom: 30,
        boxShadow: 'none',
    },
    cardActionArea: {
        '&:hover': {
            backgroundColor: 'transparent !important'
        }
    },
    cardRoot: {
        width: 430,
        height: 300,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center'
    },
    media: {
        height: 260,
        display: 'flex',
        alignItems: 'flex-end',
    },
    buttonBuy: {
        backgroundColor: '#E2F9FA',
        color: '#00837B',
        textTransform: 'none',
        marginLeft: 16,
        marginBottom: 16,
        borderRadius: 6,
        padding: '8px 20px'
    },
    cardContent: {
        '& > p:nth-child(1)': {
            fontWeight: 'bold'
        },
        '& > p:nth-child(2)': {
            color: '#B9B9B9',
            fontSize: 14
        },
        '& > p:nth-child(3)': {
            fontWeight: 'bold',
            color: '#00837B'
        }
    },
    boxService: {
        display: 'flex',
        alignItems: 'center',
    },
    iconBox: {
        backgroundColor: '#CDD3DB',
        padding: 8,
        marginRight: 20,
        borderRadius: 10,
        backgroundColor: '#F5F5F5',
        border: '1px solid #E2E1E1',
        '& > svg': {
            color: '#566F90',
        },
    },
    labelStyle: {
        fontSize: 12,
        color: '#B9B9B9',
    },
    valueStyle: {
        fontSize: 14,
        color: '#0A2240',
    },
    cardContentContainer: {
        width: '100% important',
        marginLeft: 20,
        '& > .MuiGrid-item': {
            marginTop: 20,
        },
        '& .MuiGrid-item:nth-child(1), & .MuiGrid-item:nth-child(2)': {
            marginTop: 0
        }
    },
    wrapperButton: {
        '& > button': {
            marginBottom: '15px',
        },
        '& > button:not(:last-child)': {
            marginRight: '15px',
        },
    },
    downloadDocument: {
        backgroundColor: '#F2F2F2',
        border: '1px solid #0A2240',
        textTransform: 'none',
        borderRadius: 10,
        fontWeight: 'bold',
        padding: '10px 50px'
    },
    utilities: {
        display: 'flex',
        alignItems: 'center',
        margin: '5px 0px'
    },
    message: {
        border: `2px solid lightgrey`,
        border: 'none'
    },
    note: {
        borderBottomLeftRadius: '10px',
        borderTopLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        borderTopRightRadius: '0',
        boxShadow: '1px 5px 7px lightgrey',
        padding: '8px',
        '&.sizeMD': {
            width: '400px',
        }
    },
    buttonFooter: {
        color: '#ffffff',
        borderRadius: 0,
        margin: 10,
        padding: '5px 30px',
        '&.disabled': {
            backgroundColor: '#f7f7f7'
        }
    },
    carousel: {
        '& div': {
            height: '400px',
            position: 'relative',
            margin: 10,
            width: '100%',
        }
    },
    marginDivider: {
        margin: '30px 0',
    },
    groupButtonFooter: {
        '& > button:not(:last-child)': {
            marginRight: '30px',
        }
    },
}));


const InfoProductPrimary = ({ data, loading, error }) => {
    const { id } = useParams();
    const { userInfo, accessObjectPermissions } = useContext(StoreContext);

    const [updateApprove] = useMutation(APPROVE_PRODUCT, {
        refetchQueries: [
            {
                query: PROJECT_GET_ALL,
                variables: {
                    skip: 0,
                    take: 10,
                    where: {
                        deleted: { _eq: false },
                        propertyTypeByPropertyType: {
                            code: {
                                _eq: "P"
                            }
                        }
                    }
                }
            }
        ]
    });


    const request = _.get(data, 'project.requests[0].id', null);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [statusProject, setStatusProject] = useState("");
    const [approveNote, setApproveNote] = useState("");
    const [disabled, setDisabled] = useState(true);
    const history = useHistory();
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();

    const configValue = useContext(ConfigContext);

    useEffect(() => {
        if (!_.isEmpty(statusProject))
            setDisabled(false)
    }, [statusProject]);

    useEffect(() => {
        // localStorage.removeItem('selectProject')
        if (data) {
            const test = {
                'id': _.get(data, 'project.id'),
                'code': _.get(data, 'project.code') || '',
                'title': _.get(data, 'project.title'),
                'indexCard': 'all'
            }
            localStorage.setItem('selectProject', JSON.stringify(test))
        }
    }, [data])

    const { employee } = useGetEmployeeByAccount(_.get(userInfo, 'id'));

    const handleImageViewer = useCallback(() => {
        setIsViewerOpen(true);
    }, []);

    const handleCloseImageViewer = () => {
        setIsViewerOpen(false);
    };

    async function downloadImage(imageSrc) {
        const image = await fetch(imageSrc)
        const imageBlog = await image.blob()
        const imageURL = URL.createObjectURL(imageBlog)

        const link = document.createElement('a')
        link.href = imageURL
        link.download = 'image.jpg'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    if (loading) return <CircularProgress />
    if (error) return <Redirect to="/" />

    const addressFull = _.join(_.pull([_.get(data, 'project.address.short', ''), _.get(data, 'project.address.street.name', ''), _.get(data, 'project.address.ward.name', ''), _.get(data, 'project.address.district.name', ''), _.get(data, 'project.address.province.name', '')], '', null), ', ');
    const projectDocuments = _.filter(_.mapValues(_.get(data, 'project.project_medias'), 'media'), ['type.code', 'DOCUMENT']);

    const imageInvestor = _.filter(_.mapValues(_.get(data, 'project.project_medias'), 'media'), ['type.code', 'AVATAR']);

    const outstandingUtilities = _.concat(
        _.filter(_.mapValues(_.get(data, 'project.project_utilities'), 'utility'), ['type', 2]),
        _.filter(_.mapValues(_.get(data, 'project.project_utilities'), 'utility'), ['type', null])
    );
    const internalUtilities = _.filter(_.mapValues(_.get(data, 'project.project_utilities'), 'utility'), ['type', 1]);
    const projectPhoto = _.filter(_.mapValues(_.get(data, 'project.project_medias'), 'media'), ['type.code', 'PHOTO']);

    const handleUpdate = () => {
        history.push(`/product/primary/edit/${id}`);
    }

    const handleChangeApproveNote = (e) => {
        e.preventDefault();
        setApproveNote(e.target.value);
    }


    const handleApprove = () => {
        swal("Thông báo", "Chức năng này sẽ được hỗ trợ ở phiên bản kế tiếp!", "success");
        // try {

        //     updateApprove({
        //         variables: {
        //             data: {
        //                 request: request,
        //                 status: statusProject.status,
        //                 user: _.get(userInfo,'id'),
        //                 note: approveNote
        //             }
        //         }
        //     }).then(res => {
        //         swal("Thông báo", "Xét duyệt sản phẩm thành công!", "success").then(() => { history.push('/product/primary') });
        //     }, (error) => {
        //         swal("Vui lòng thử lại", "Có lỗi khi xét duyệt sản phẩm!", "error");
        //     })


        // } catch (error) {

        // }

    }

    return (
        <Fragment>
            <Grid container direction="column" className={classes.infoContainer}>
                <Grid container direction="row" justifyContent="space-between" className={classes.boxTitleTop}>
                    <Grid item>
                        <Typography>Mã sản phẩm:</Typography>
                        <Typography>{_.get(data, 'project.code')}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>Ngày tạo</Typography>
                        <Typography>
                            <Typography><Moment format="DD/MM/YYYY">{_.get(data, 'project.created')}</Moment></Typography>

                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Card className={classes.cardRootTop}>
                        <CardActionArea
                            className={classes.cardActionArea}
                            disableRipple
                            onClick={handleImageViewer}
                        >
                            <CardMedia
                                className={classes.media}
                                title={_.get(data, 'project.title')}
                                image={configValue.MEDIA_DOMAIN + _.get(_.head(_.get(data, 'project.project_medias')), 'media.url')}
                            >
                                <div className={classes.buttonBuy}>
                                    <Typography>{_.get(data, 'project.project_status.name')}</Typography>
                                </div>
                            </CardMedia>
                        </CardActionArea>
                        {isViewerOpen && (
                            <ImageViewer
                                src={_.map(projectPhoto, item => configValue.MEDIA_DOMAIN + _.replace(_.get(item, 'url'), "mqdefault", "maxresdefault"))}
                                onClose={handleCloseImageViewer}
                                backgroundStyle={{
                                    backgroundColor: "rgba(0,0,0,0.9)",
                                    zIndex: "9999"
                                }}
                            />
                        )}
                        <CardContent className={classes.cardContent}>
                            <Typography gutterBottom>{_.get(data, 'project.name')}</Typography>
                            <Typography>{addressFull}</Typography>
                            <Typography>{`từ ${_.get(data, 'project.price_from_milion') || 0} triệu / m2`}</Typography>
                        </CardContent>
                    </Card>
                </Grid>


                <Grid container direction="row">
                    <Card variant="outlined" className={classes.cardRoot}>
                        <Grid container direction="row" className={classes.cardContentContainer} alignItems="center">
                            <Grid item sm={6} xs={6} className={classes.boxService}>
                                <Grid item>
                                    <div className={classes.iconBox}><Home /></div>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.labelStyle}>Dự án</Typography>
                                    <Typography className={classes.valueStyle}>
                                        {_.join(_.map(_.get(data, 'project.project_types'), 'project_type.name'), ', ')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={6} className={classes.boxService}>
                                <Grid item>
                                    <div className={classes.iconBox}><Apartment /></div>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.labelStyle}>Số toà tháp</Typography>
                                    <Typography className={classes.valueStyle}>

                                        {`${_.get(data, 'project.total_block') || 0} blocks`}

                                        {/* {`${_.get(data, 'project.total_block')} blocks`} */}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={6} className={classes.boxService}>
                                <Grid item>
                                    <div className={classes.iconBox}><ViewList /></div>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.labelStyle}>
                                        {
                                            _.get(_.head(_.get(data, 'project.project_types')), 'project_type.code') === 'L'
                                                ? 'Số đất nền'
                                                : 'Số căn hộ'
                                        }
                                    </Typography>
                                    <Typography className={classes.valueStyle}>
                                        {
                                            _.get(_.head(_.get(data, 'project.project_types')), 'project_type.code') === 'L'
                                                ? `${_.get(data, 'project.total_ground') || 0} nền`
                                                : `${_.get(data, 'project.total_appartments') || 0} căn`
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={6} className={classes.boxService}>
                                <Grid item>
                                    <div className={classes.iconBox}><ViewList /></div>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.labelStyle}>Số tầng</Typography>
                                    <Typography className={classes.valueStyle}>
                                        {`${rangeFormat(_.get(data, 'project.floor_from'), _.get(data, 'project.floor_to')) || '0 '} tầng`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={6} className={classes.boxService}>
                                <Grid item>
                                    <div className={classes.iconBox}><DataUsage /></div>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.labelStyle}>Tổng diện tích</Typography>
                                    <Typography className={classes.valueStyle}>
                                        {`${_.get(data, 'project.total_area') || 0} m2`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={6} className={classes.boxService}>
                                <Grid item>
                                    <div className={classes.iconBox}><Straighten /></div>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.labelStyle}>diện tích căn hộ</Typography>
                                    <Typography className={classes.valueStyle}>
                                        {`${rangeFormat(_.get(data, 'project.area_from'), _.get(data, 'project.area_to')) || 0} m2`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={6} className={classes.boxService}>
                                <Grid item>
                                    <div className={classes.iconBox}><Build /></div>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.labelStyle}>mật độ xây dựng</Typography>
                                    <Typography className={classes.valueStyle}>
                                        {`${_.get(data, 'project.density_of_building') || 0} %`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={6} className={classes.boxService}>
                                <Grid item>
                                    <div className={classes.iconBox}><Nature /></div>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.labelStyle}>mật độ cây xanh</Typography>
                                    <Typography className={classes.valueStyle}>
                                        {`${_.get(data, 'project.density_of_trees') || 0} %`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid container direction="column">
                    <Grid container direction="row">
                        <Grid item style={{ display: 'flex', flexDirection: 'column', marginTop: 20, textAlign: 'center' }}>
                            <Typography style={{ fontWeight: 'bold' }}>Mô tả</Typography>
                            <span style={{ display: 'block', backgroundColor: '#DA6744', borderRadius: 10, width: 60, height: 3 }}></span>
                        </Grid>
                    </Grid>
                    <Grid container direction="column" style={{ marginTop: 20 }}>
                        <Grid item>
                            <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Thông tin mô tả</Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontSize: 14, width: matchesMD ? "600px" : undefined }}>
                                {_.get(data, 'project.description')}
                            </Typography>
                        </Grid>
                        {/* <Grid item>
                        <Typography style={{ fontWeight: 'bold', marginTop: 20 }}>Xem thêm</Typography>
                    </Grid> */}
                    </Grid>
                    <Grid container direction="column">
                        <Grid container direction="column" style={{ marginTop: 20 }}>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Chủ đầu tư</Typography>
                            </Grid>
                            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                {!_.isEmpty(_.get(data, 'project.project_investor.logo.url')) &&
                                    <Grid item>
                                        <img
                                            width={100}
                                            src={configValue.MEDIA_DOMAIN + _.get(data, 'project.project_investor.logo.url')}
                                        />
                                    </Grid>
                                }
                                <Grid item style={{ fontSize: 14, paddingLeft: '16px' }}>
                                    <Typography style={{ fontSize: 13 }}>
                                        {_.get(data, 'project.project_investor.name')}
                                    </Typography>
                                    <Typography style={{ color: '#00837B' }}>
                                        <em>{_.get(data, 'project.project_investor.website')}</em>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid container direction="column" style={{ marginTop: 20 }}>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Nhà thầu xây dựng</Typography>
                            </Grid>
                            <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                <Grid item style={{ fontSize: 14 }}>
                                    <Typography style={{ fontSize: 13 }}>
                                        {_.get(data, 'project.project_constructor.name')}
                                    </Typography>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid container direction="column" style={{ marginTop: 20 }}>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Đơn vị quản lý</Typography>
                            </Grid>
                            <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                <Grid item style={{ fontSize: 14 }}>
                                    <Typography style={{ fontSize: 13 }}>
                                        {_.get(data, 'project.project_management.name')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid container direction="column" style={{ marginTop: 20 }}>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Đơn vị thiết kế</Typography>
                            </Grid>
                            <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                <Grid item style={{ fontSize: 14 }}>
                                    <Typography style={{ fontSize: 13 }}>
                                        {_.get(data, 'project.project_design.name')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid container direction="column" style={{ marginTop: 20 }}>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Đơn vị hợp tác đầu tư</Typography>
                            </Grid>
                            <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                <Grid item style={{ fontSize: 14 }}>
                                    <Typography style={{ fontSize: 13 }}>
                                        {_.get(data, 'project.investment_cooperation.name')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid container direction="column" style={{ marginTop: 20 }}>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Đơn vị giám sát thi công</Typography>
                            </Grid>
                            <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                <Grid item style={{ fontSize: 14 }}>
                                    <Typography style={{ fontSize: 13 }}>
                                        {_.get(data, 'project.construction_supervision.name')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid container direction="column" style={{ marginTop: 20 }}>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Thời gian khởi công</Typography>
                            </Grid>
                            {
                                _.get(data, 'project.start_date') ? <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                    <Grid item style={{ fontSize: 14 }}>
                                        <Typography style={{ fontSize: 13 }}>
                                            {moment(_.get(data, 'project.start_date')).format('DD/MM/YYYY').toString()}
                                            {/* #UTC */}
                                        </Typography>
                                    </Grid>
                                </Grid> : <Typography style={{ fontSize: 13 }}>-</Typography>
                            }

                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid container direction="column" style={{ marginTop: 20 }}>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Thời gian bàn giao</Typography>
                            </Grid>
                            {
                                _.get(data, 'project.taking_over') ? <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                    <Grid item style={{ fontSize: 14 }}>

                                        <Typography style={{ fontSize: 13 }}>
                                            {moment(_.get(data, 'project.taking_over')).format('DD/MM/YYYY') === "Invalid date" ? "" : moment(_.get(data, 'project.taking_over')).format('DD/MM/YYYY').toString()}

                                            {/* #UTC */}
                                        </Typography>
                                    </Grid>
                                </Grid> : <Typography style={{ fontSize: 13 }}>-</Typography>
                            }

                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid container direction="column" style={{ marginTop: 20 }}>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Ngân hàng bảo lãnh</Typography>
                            </Grid>
                            <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                <Grid item style={{ fontSize: 14 }}>
                                    <Typography style={{ fontSize: 13 }}>
                                        {_.get(data, 'project.project_guaranty_banks.name')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid container direction="column" style={{ marginTop: 20 }}>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Ngân hàng hỗ trợ vay vốn</Typography>
                            </Grid>
                            <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                <Grid item style={{ fontSize: 14 }}>
                                    <Typography style={{ fontSize: 13 }}>
                                        {_.get(data, 'project.project_loan_banks.name')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid container direction="column" style={{ marginTop: 40 }}>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Video sản phẩm</Typography>
                            </Grid>
                            <Grid container direction="row" alignItems="center" style={{ marginTop: 10 }}>
                                <Grid container style={{ fontSize: 14 }}>
                                    {_.get(data, 'project.video_url', "") !== "" ? <ReactPlayer url={_.get(data, 'project.video_url', "")} width={540} /> : null}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid container direction="column" style={{ marginTop: 40 }}>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Không gian 3d sản phẩm</Typography>
                            </Grid>
                            <Grid container direction="row" alignItems="center" style={{ marginTop: 10 }}>
                                <Grid container style={{ fontSize: 14 }}>
                                    {_.get(data, 'project.view_3d_url', "") !== "" ? <iframe width="540" height="300" src={_.get(data, 'project.view_3d_url', "")} frameBorder="0" allowFullScreen allow="xr-spatial-tracking"></iframe> : null}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid container direction="column" style={{ marginTop: 40 }}>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Tài liệu chủ đầu tư</Typography>
                            </Grid>
                            <Grid container direction="row" alignItems="center" style={{ marginTop: 10 }}>
                                <Grid container className={classes.wrapperButton}>
                                    {_.map(projectDocuments, (item, key) => (
                                        <Button
                                            key={key}
                                            onClick={() => downloadImage(configValue.MEDIA_DOMAIN + _.get(item, 'url'))}
                                            className={classes.downloadDocument}
                                        >
                                            {_.get(item, 'name')}
                                        </Button>)
                                    )}

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid container direction="column" style={{ marginTop: 40, width: '40%' }}>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Ưu điểm nổi bật</Typography>
                            </Grid>
                            <Grid container direction="row" alignItems="center" style={{ marginTop: 10 }}>
                                {_.map(outstandingUtilities, (item, key) =>
                                    <Grid key={key} item xs={12} sm={12} md={6} className={classes.utilities}>
                                        <CheckCircle style={{ color: '#566F90', fontSize: 12, marginRight: 10 }} />
                                        <Typography style={{ fontSize: 12 }}>{_.get(item, 'name')}</Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid container direction="column" style={{ marginTop: 40, width: '40%' }}>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Tiện ích nội khu</Typography>
                            </Grid>
                            <Grid container direction="column" justifyContent="center" style={{ marginTop: 10 }}>
                                {_.map(internalUtilities, (item, key) =>
                                    <Grid key={key} item sm={12} className={classes.utilities}>
                                        <CheckCircle style={{ color: '#566F90', fontSize: 12, marginRight: 10 }} />
                                        <Typography style={{ fontSize: 12 }}>{_.get(item, 'name')}</Typography>

                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid container direction="row" style={{ marginTop: 40 }}>
                            <Grid item style={{ fontWeight: 'bold', width: 200 }}>Người gửi yêu cầu:</Grid>
                            <Grid
                                item
                                style={{ fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => history.push(window.open("/employee/detail/" + _.get(employee, 'info.id')))}
                            >
                                {_.get(employee, 'info.code')}
                            </Grid>
                        </Grid>
                        <Grid container direction="row" style={{ marginTop: 40 }}>
                            <Grid item style={{ fontWeight: 'bold', width: 200 }}>NV xét duyệt:</Grid>
                            <Grid
                                item
                                style={{ fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => history.push(window.open("/employee/detail/" + _.get(employee, 'info.id')))}
                            >
                                {_.get(employee, 'info.code')}
                            </Grid>
                        </Grid>
                        <Grid container direction="row" style={{ marginTop: 40, display: 'flex', alignItems: 'center' }}>
                            <Grid item style={{ fontWeight: 'bold', width: 200 }}>Trạng thái sản phẩm:</Grid>
                            <Grid item>
                                <Typography>{_.get(data, 'project.project_status.name')}</Typography>
                                {/* <InputDropdown
                                    discription='Trạng thái'
                                    fieldname='status'
                                    object={statusProject}
                                    setObject={setStatusProject}
                                    data={[{ id: 'APPROVE', name: 'Duyệt' }, { id: 'REJECT', name: 'Không duyệt' }]}
                                /> */}
                            </Grid>
                        </Grid>
                        {/* {!_.isEmpty(statusProject) &&
                            <Grid container direction="row" style={{ marginTop: 40, display: 'flex', alignItems: 'center' }}>
                                <Grid item style={{ fontWeight: 'bold', width: 200 }}>
                                    Ghi chú xét duyệt:
                                </Grid>
                                <Grid item>
                                    <Paper className={clsx(classes.note, matchesMD && 'sizeMD')}>
                                        <TextField
                                            className={classes.message}
                                            InputProps={{ disableUnderline: true }}
                                            multiline
                                            style={{ width: '30em', height: '100%' }}
                                            id="message"
                                            rows={5}
                                            inputProps={{ maxLength: 100 }}
                                            required
                                            onChange={handleChangeApproveNote}
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>
                        } */}
                        <Grid container direction="row" style={{ marginTop: 40, display: 'flex', alignItems: 'center' }}>
                            <Grid item style={{ fontWeight: 'bold', width: 200 }}>
                                Ghi chú tin đăng:
                            </Grid>
                            <Grid item>
                                <Paper className={clsx(classes.note, matchesMD && 'sizeMD')}>
                                    <TextField
                                        value={_.get(data, 'project.note')}
                                        className={classes.message}
                                        InputProps={{ disableUnderline: true }}
                                        multiline
                                        style={{ width: '30em', height: '100%' }}
                                        id="message"
                                        rows={5}
                                        inputProps={{ maxLength: 100 }}
                                        required
                                    />
                                </Paper>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <Divider className={classes.marginDivider} />
            <Grid container direction="row" className={classes.groupButtonFooter} justifyContent='flex-end'>
                <Button
                    color='primary'
                    startIcon={<Edit />}
                    variant="contained"
                    disabled={!checkedPermission(userInfo, accessObjectPermissions, UPDATE_PRIMARY)}
                    onClick={handleUpdate}
                >Chỉnh sửa</Button>

                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<Telegram />}
                    onClick={handleApprove}
                >
                    Xét duyệt
                </Button>
                <ButtonReturn link={slugs.productPrimary} />
            </Grid>
        </Fragment>
    )
}

export default InfoProductPrimary
