import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import CreateNotification from '../../../components/other/CreateNotification'
import NotificationHistory from '../../../components/other/NotificationHistory';

const SettingNotification = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            // width: '70%'
            marginTop: 20
        },
        margin: {
            marginTop: '40px'
        },
        button: {
            fontSize: '16px',
            color: '#ffffff',
            borderRadius: '0',
            height: '32px',
        },
        checkboxLabelForm: {
            width: 160,
            fontWeight: 'bold',
            marginRight: 30,
            fontSize: '22px',
            marginBottom: '5px'
        },
        textAreaContent: {
            width: '25em',
            height: '6em',
            backgroundColor: '#ffffff',
            border: '1px solid lightgray',
            marginTop: '50px',
        },
        input: {
            display: 'none',
        },
        backButton: {
            backgroundColor: 'black',
            color: '#ffffff',
            borderRadius: '0',
            padding: '10px 30px'
        },
        confirmButton: {
            backgroundColor: '#2c8a81',
            padding: '10px 30px',
            color: '#ffffff',
            borderRadius: 0,
            marginRight: '30px'
        }
    }))
    const classes = useStyles();
    const [openNotificationHistory, setOpenNotificationHistory] = useState(false);
    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item style={{ marginLeft: '160px' }}>
                <Grid item >
                    <ButtonGroup size="small" aria-label="small outlined button group">
                        <Button
                            onClick={() => setOpenNotificationHistory(false)}
                            className={classes.button}
                            style={{ backgroundColor: openNotificationHistory ? '#2c8a81' : '#E27F35', width: '210px', fontWeight: openNotificationHistory ? undefined : 'bold' }}>
                            THÔNG BÁO MỚI
                        </Button>
                        <Button
                            onClick={() => setOpenNotificationHistory(true)}
                            className={classes.button} style={{ backgroundColor: openNotificationHistory ? '#E27F35' : '#2c8a81', width: '250px', fontWeight: openNotificationHistory ? 'bold' : undefined }}>
                            THÔNG BÁO ĐÃ GỬI
                        </Button>
                    </ButtonGroup>
                </Grid>
                {openNotificationHistory ? (
                    <NotificationHistory />
                ) : (
                    <CreateNotification />
                )}
            </Grid>


        </Grid>
    )
}

export default SettingNotification
