import * as _ from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';
import { SelectAll } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    inputStyle: {
        width: '80%',
        backgroundColor: 'white',
        marginLeft: '0',
        '& > div:before': {
            content: 'unset',
        },
        '& > div:after': {
            content: 'unset',
        },
    },
}));


const SearchText = ({ text, setText }) => {
    const classes = useStyles();
    const [inputValue, setInputValue] = useState(text);

    const onBlurSetValue = (event) => {
        const value = _.trim(inputValue);
        if (text === value) return;
        if (_.isEmpty(value) && inputValue === undefined) return;

        if (_.isFunction(setText)) setText(value);
    };

    const onchangeAction = (e) => {
        setInputValue(_.get(e, 'target.value'));
    };

    const onKeyPress = (event) => {
        if (event.key === 'Enter' || event.charCode === 13) {
            onBlurSetValue(event);
        }
        return;
    }

    useEffect(() => {
        setInputValue(text || '');
    }, [text]);

    return (
        <TextField
            className={classes.inputStyle}
            placeholder='Tìm kiếm'
            value={inputValue}
            id={'input-search'}
            onBlur={onBlurSetValue}
            onChange={onchangeAction}
            onKeyPress={onKeyPress}
        />
    );
}

SearchText.propTypes = {
    text: PropTypes.string,
    setText: PropTypes.func,
};

export default SearchText;
