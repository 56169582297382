import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PersonIcon from "@material-ui/icons/Person";
import useTheme from "@material-ui/styles/useTheme";
import axios from "axios";
import clsx from "clsx";
import * as _ from "lodash";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import swal from "sweetalert";
import ButtonReturn from "../../components/ButtonReturn";
import CustomerProductView from "../../components/customer/CustomerProductView";
import ModalBlocked from "../../components/customer/ModalBlocked";
import ModalUnBlocked from "../../components/customer/ModalUnBlocked";
import InputDateAndTimePicker from "../../components/InputDateAndTimePicker";
import {
  GET_CUSTOMER,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_KYC,
} from "../../graphql/schemas/customer/customerQueries";
import SelectComponent from "./components/SelectComponent";
import NO_IMAGE from "../../assets/images/no-image.svg";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import { checkedPermission, formatPhone } from "../../helper";
import ImageViewer from "react-simple-image-viewer";
import AutoCompleteBank from "../../components/AutoCompleteBank";
import { ConfigContext } from "../../context/ConfigContext";
import { StoreContext } from "../../resources/store";
import { USER_ROLE_SUPPER_ADMIN } from "../../constants/defined";
import { UPDATE_CUSTOMER as UPDATE_CUSTOMER_TEXT } from '../../constants/defined';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "30px",
  },
  wapperLoading: {
    width: "100%",
    height: "calc(100vh - 113px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sectionStyle: {
    paddingBottom: "30px",
  },
  text: {
    fontSize: "14px",
  },
  card: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "20px",
    borderRadius: 0,
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  summary: {
    minHeight: "30px !important",
    height: "40px",
  },
  historyAdvisory: {
    fontSize: "14px",
  },

  message: {
    border: `2px solid lightgrey`,
    border: "none",
  },
  note: {
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "0",
    boxShadow: "1px 5px 7px lightgrey",
    padding: "8px",
    "&.matches-md": {
      width: "400px",
    },
  },
  buttonGroupInfo: {
    display: "flex",
    alignItems: "flex-end",
    marginLeft: "4em",
    "&.matches-md": {
      marginTop: "2em",
      marginLeft: "0",
    },
  },
  buttonInfo: {
    borderRadius: 0,
    margin: "0 12px",
    color: "#ffffff",
    backgroundColor: "#E27F35",
    "&.edit-mode": {
      backgroundColor: "#2C8A81",
    },
  },
  avatarFaceId: {
    marginLeft: "5em",
    "&.matches-md": {
      display: "none",
    },
  },
  boxAvatar: {
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    fontSize: 80,
    padding: 16,
    marginBottom: "20px",
  },
  avatar: {
    borderRadius: "50%",
    verticalAlign: "middle",
    width: "100px",
    height: "80px",
  },
  styleZoom: {
    cursor: "zoom-in",
  },
  boxFlexAvt: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "26px",
    '&:not(.disable)': {
      cursor: "pointer",
    }
  },
  boxTitle: {
    paddingBottom: "30px",
    paddingTop: "60px",
  },
  blokedBtn: {
    backgroundColor: "#E51515",
    fontSize: "bold",
    borderRadius: 0,
    color: "#ffffff",
    textTransform: "none",
    fontWeight: 700,
    marginLeft: "50px",
  },
  activedBtn: {
    backgroundColor: "#2C8A81",
    fontSize: "bold",
    borderRadius: 0,
    color: "#ffffff",
    textTransform: "none",
    fontWeight: 700,
    marginLeft: "50px",
  },
  idImage: {
    "& > img": {
      width: 300,
      height: "auto",
      objectFit: "contain",
    },
  },
  displayFlex: {
    display: "flex",
    marginLeft: "-14px",
    marginTop: "10px",
  },
  dottedBorder: {
    border: "1px dashed #B9B9B9",
    boxSizing: "border-box",
    borderRadius: "6px",
    padding: "12px 24px",
    margin: "24px 0",
  },
  bold: {
    fontWeight: 700,
  },
}));

const DetailCustomerPage = () => {
  const { id } = useParams();
  const { userInfo, accessObjectPermissions, accessPermissions } = useContext(StoreContext);
  const configValue = useContext(ConfigContext);

  const { loading, data, error, refetch } = useQuery(GET_CUSTOMER, {
    variables: {
      id: id,
    },
  });

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        variables: {
          id: id,
        },
      },
    ],
    onError: (err) => {
      return swal({
        title: `${err.message}`,
        icon: "error",
      });
    },
    onCompleted: () => {
      return swal({
        title: "Cập nhật khách hàng thành công",
        icon: "success",
      });
    },
  });

  const [updateKYC] = useMutation(UPDATE_CUSTOMER_KYC, {
    onCompleted: () => {
      return swal({
        title: "Cập nhật thông tin EKYC thành công",
        icon: "success",
      }).then(() => refetch(id));
    },
    onError: () => {
      return swal({
        title: "Cập nhật EKYC thất bại",
        icon: "error",
      });
    },
  });

  const [loadingUpload, setLoadingUpload] = useState(false);

  const theme = useTheme();

  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  const [openEdit, setOpenEdit] = useState(false);
  const [isEditKyc, setIsEditKyc] = useState(false);

  const history = useHistory();

  const [blocked, setBlocked] = useState(true);
  const [isViewer, setIsViewer] = useState({ view: false, key: 0 });

  const classes = useStyles();

  // import blocked
  const [openBlocked, setOpenBlocked] = useState(false);
  const [openUnBlocked, setOpenUnBlocked] = useState(false);

  // import state

  const [personalInfo, setPersonalInfo] = useState({
    fullName: "",
    phone: "",
    cusId: "",
    personalId: "",
    email: "",
    birthDay: moment(new Date()).format("YYYY/MM/DD"),
    gender: -1,
    faceId: null,
    accountId: "",
    refEcoeEmployeeId: "",
    consultEmployee: "",
  });

  const [bankInfo, setBankInfo] = useState({
    nameaccount: "",
    numberaccount: "",
    bankname: "",
    bankbranch: "",
    bankanother: "",
  });
  const [eKycObj, setEKycObj] = useState({});
  const [showBankName, setShowBankName] = useState(() => false);

  useEffect(() => {
    setShowBankName(_.get(data?.results, "customer_banks[0].bank_name_other"));
  }, [data]);

  useEffect(() => {
    if (isEditKyc) {
      setEKycObj({
        id: _.get(data.results, "info.id"),
        id_updated: "now",
        id_name: _.get(data.results, "info.id_name"),
        birthday: moment(_.get(data.results, "info.birthday")).format('YYYY-MM-DD'),
        personalid: _.get(data.results, "info.personalid"),
        id_issues_date: moment(_.get(data.results, "info.id_issues_date")).format('YYYY-MM-DD'),
        gender: _.get(data.results, "info.gender"),
      });
    } else {
      setEKycObj({});
    }
  }, [isEditKyc]);

  const handleOpenUnBlockedModal = () => {
    setOpenUnBlocked(true);
  };

  const handleOpenBlockedModal = () => {
    setOpenBlocked(true);
  };

  const handleUpdate = () => {
    updateCustomer({
      variables: {
        arg: {
          id: _.get(data.results, "id"),
          account: {
            id: personalInfo.accountId,
            full_name: personalInfo.fullName,
            gender: personalInfo.gender,
            personalid: personalInfo.personalId,
            birthday: personalInfo.birthDay,
            faceid: _.get(personalInfo.faceId, "id"),
          },
          customer_banks: {
            bank: bankInfo.bankname,
            bank_branch: bankInfo.bankbranch,
            bank_name_other: showBankName ? bankInfo.bankanother : "",
            account_name: bankInfo.nameaccount,
            account_number: bankInfo.numberaccount,
          },
        },
      },
    }).then(() => {
      setOpenEdit(false);
    });
  };

  const uploadHandler = async (e) => {
    setLoadingUpload(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("media_type_code", "AVATAR");
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        configValue.API_UPLOAD_FILE,
        formData,
        config
      );
      setLoadingUpload(false);
      setPersonalInfo((prev) => {
        return {
          ...prev,
          faceId: data[0],
        };
      });
    } catch (error) {
      setLoadingUpload(false);
      console.log(error);
    }
  };

  if (loading || !data)
    return (
      <div className={classes.wapperLoading}>
        <CircularProgress />
      </div>
    );

  const handleGender = (e) => {
    setPersonalInfo((prev) => {
      return {
        ...prev,
        gender: e.target.value,
      };
    });
  };

  const returnedGender = () => {
    if (_.get(data, "results.info.gender") === 0) return "Nữ";
    if (_.get(data, "results.info.gender") === 1) return "Nam";
    return undefined;
  };

  const returnedIdStatus = () => {
    if (_.get(data.results, "info.id_status") === "VERIFIED")
      return "Đã xác thực";
    if (_.get(data.results, "info.id_status") === "REJECT") return "Đã từ chối";
    return;
  };

  const handleConfirmKyc = () => {
    swal("Xác nhận thông tin eKYC", "", "warning", {
      buttons: {
        cancel: "Hủy",
        confirm: {
          text: "Chấp nhận",
          value: "confirm",
        },
        reject: {
          text: "Từ chối",
          value: "reject",
        },
      },
    }).then((value) => {
      switch (value) {
        case "reject":
          updateKYC({
            variables: {
              data: {
                id: _.get(data.results, "info.id"),
                id_status: "REJECT",
                id_updated: "now",
              },
            },
          });
          break;
        case "confirm":
          updateKYC({
            variables: {
              data: {
                id: _.get(data.results, "info.id"),
                id_status: "VERIFIED",
                id_updated: "now",
              },
            },
          });
          break;

        default:
      }
    });
  };
  const handleUpdateKycInfo = () => {
    updateKYC({
      refetchQueries: [
        {
          query: GET_CUSTOMER,
          variables: {
            id: id,
          },
        },
      ],
      variables: {
        data: eKycObj
      },
      onCompleted: () => {
        return swal({
          title: "Cập nhật thông tin eKYC thành công",
          icon: "success",
        }).then(() => { setIsEditKyc(false) })
      },
      onError: (error) => {
        return swal("Lỗi cập nhật", "Có lỗi khi cập nhật thông tin. Vui lòng thử lại!", "error");
      },
    });
  };

  const handleAnotherBank = (message) => {
    setShowBankName(message);
  };

  const isSuperAdmin = _.toUpper(_.get(userInfo, 'role')) !== USER_ROLE_SUPPER_ADMIN;
  const accessDeniedEdit = !checkedPermission(userInfo, accessObjectPermissions, UPDATE_CUSTOMER_TEXT);

  return (
    <Paper className={classes.root}>
      <ModalBlocked
        setOpenBlocked={setOpenBlocked}
        setBlocked={setBlocked}
        openBlocked={openBlocked}
        data={_.get(data, "results")}
      />
      <ModalUnBlocked
        setOpenUnBlocked={setOpenUnBlocked}
        setBlocked={setBlocked}
        openUnBlocked={openUnBlocked}
        data={_.get(data, "results")}
      />

      <Grid container direction="column" className={classes.sectionStyle}>
        <Grid
          container
          direction="row"
          className={classes.boxTitle}
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              Thông tin khách hàng
            </Typography>
          </Grid>
          <Grid item>
            {_.get(data.results, "info.active") === false ? (
              <Button variant="contained" className={classes.blokedBtn}>
                Đã khóa
              </Button>
            ) : (
              <Button variant="contained" className={classes.activedBtn}>
                Đang hoạt động
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          direction={matchesMD ? "column" : "row"}
          alignItems="flex-start"
          style={{ padding: "0 24px" }}
        >
          <Grid item style={{ paddingTop: "20px", paddingRight: "120px" }}>
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ marginBottom: "26px" }}
            >
              <Grid item style={{ width: "160px" }}>
                <Typography
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  Khách hàng:{" "}
                </Typography>
              </Grid>
              <Grid item className={classes.text}>
                {openEdit ? (
                  <TextField
                    style={{ backgroundColor: "#ffffff" }}
                    value={personalInfo.fullName}
                    onChange={(e) =>
                      setPersonalInfo((prev) => {
                        return {
                          ...prev,
                          fullName: e.target.value,
                        };
                      })
                    }
                    variant="outlined"
                    size="small"
                  />
                ) : (
                  _.get(data.results, "info.full_name")
                )}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ marginBottom: "26px" }}
            >
              <Grid item style={{ width: "160px" }}>
                <Typography
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  Số điện thoại:
                </Typography>
              </Grid>
              <Grid item className={classes.text}>
                {openEdit ? (
                  <TextField
                    style={{ backgroundColor: "#ffffff" }}
                    value={formatPhone({
                      phone: personalInfo.phone,
                      type: "first",
                    })}
                    disabled={true}
                    onChange={(e) =>
                      setPersonalInfo((prev) => {
                        return {
                          ...prev,
                          phone: e.target.value,
                        };
                      })
                    }
                    variant="outlined"
                    size="small"
                  />
                ) : (
                  formatPhone({
                    phone: _.get(data, "results.info.phone"),
                    type: "first",
                  })
                )}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ marginBottom: "26px" }}
            >
              <Grid item style={{ width: "160px" }}>
                <Typography
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  Ngày sinh:
                </Typography>
              </Grid>
              <Grid item className={classes.text}>
                {openEdit ? (
                  <InputDateAndTimePicker
                    defaultValue={personalInfo.birthDay}
                    width={229}
                    setDateTime={(birthday) =>
                      setPersonalInfo((prev) => {
                        return {
                          ...prev,
                          birthDay: birthday,
                        };
                      })
                    }
                    format="dd/MM/yyyy"
                  />
                ) : moment(_.get(data.results, "info.birthday")).format(
                  "DD/MM/YYYY"
                ) === "Invalid date" ? (
                  ""
                ) : (
                  moment(_.get(data.results, "info.birthday")).format(
                    "DD/MM/YYYY"
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: "20px" }}>
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ marginBottom: "24px" }}
            >
              <Grid item style={{ width: "160px" }}>
                <Typography
                  style={{ fontWeight: "bold" }}
                  className={classes.text}
                >
                  Mã khách hàng:
                </Typography>
              </Grid>
              <Grid item className={classes.text}>
                {openEdit ? (
                  <TextField
                    disabled={true}
                    style={{ backgroundColor: "#ffffff" }}
                    value={personalInfo.cusId}
                    onChange={(e) =>
                      setPersonalInfo((prev) => {
                        return {
                          ...prev,
                          cusId: e.target.value,
                        };
                      })
                    }
                    variant="outlined"
                    size="small"
                  />
                ) : (
                  _.get(data.results, "info.code")
                )}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ marginBottom: "24px" }}
            >
              <Grid item className={classes.text} style={{ width: "160px" }}>
                <Typography
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  CMND/CCCD:
                </Typography>
              </Grid>
              <Grid item className={classes.text}>
                {openEdit ? (
                  <TextField
                    style={{ backgroundColor: "#ffffff" }}
                    value={personalInfo.personalId}
                    onChange={(e) =>
                      setPersonalInfo((prev) => {
                        return {
                          ...prev,
                          personalId: e.target.value,
                        };
                      })
                    }
                    variant="outlined"
                    size="small"
                  />
                ) : (
                  _.get(data.results, "info.personalid")
                )}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ marginBottom: "24px" }}
            >
              <Grid item className={classes.text} style={{ width: "160px" }}>
                <Typography
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  Email:
                </Typography>
              </Grid>
              <Grid item className={classes.text}>
                {openEdit ? (
                  <TextField
                    disabled
                    style={{ backgroundColor: "#ffffff" }}
                    value={personalInfo.email}
                    onChange={(e) =>
                      setPersonalInfo((prev) => {
                        return {
                          ...prev,
                          email: e.target.value,
                        };
                      })
                    }
                    variant="outlined"
                    size="small"
                  />
                ) : (
                  _.get(data.results, "info.email")
                )}
              </Grid>
            </Grid>
            <Grid container alignItems="center" direction="row">
              <Grid item className={classes.text} style={{ width: "160px" }}>
                <Typography
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  Giới tính:
                </Typography>
              </Grid>
              <Grid item className={classes.text}>
                {openEdit ? (
                  <SelectComponent
                    listData={[
                      { id: 0, label: "Nữ" },
                      { id: 1, label: "Nam" },
                    ]}
                    fieldValue="id"
                    fieldTitle="label"
                    handleChange={handleGender}
                    defaultValue={-1}
                    disabledDefault
                    width={229}
                    defaultTitle="Chọn giới tính"
                    value={personalInfo.gender}
                  />
                ) : (
                  returnedGender()
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            className={clsx(classes.avatarFaceId, matchesMD && "matches-md")}
            style={{ marginTop: "20px" }}
          >
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ marginBottom: "24px" }}
            >
              <input
                accept="image/*"
                id="face-id-customer"
                multiple
                type="file"
                hidden
                onChange={uploadHandler}
              />
              <Grid item>
                {openEdit ? (
                  <label
                    className={classes.boxFlexAvt}
                    htmlFor="face-id-customer"
                  >
                    {_.get(personalInfo.faceId, "url") === undefined ? (
                      <PersonIcon className={classes.boxAvatar} />
                    ) : (
                      <img
                        src={
                          configValue.MEDIA_DOMAIN +
                          _.get(personalInfo.faceId, "url")
                        }
                        alt="Avatar"
                        className={classes.avatar}
                      />
                    )}
                    <Typography style={{ fontWeight: "bold" }}>
                      Face ID KH
                    </Typography>
                  </label>
                ) : (
                  <div className={clsx(classes.boxFlexAvt, !_.get(data, 'results.info.faceid') && 'disable')}>
                    {_.get(data, "results.info.faceid") === null ||
                      _.get(data, "results.info.faceid") === undefined ? (
                      <PersonIcon className={classes.boxAvatar} />
                    ) : (
                      <Fragment>
                        <img
                          src={
                            configValue.MEDIA_DOMAIN +
                            _.get(data, "results.info.faceid.url")
                          }
                          alt="Avatar"
                          className={clsx(classes.avatar, classes.styleZoom)}
                          onClick={(e) => setIsViewer({ view: true, key: 0 })}
                        />
                        {isViewer?.view && (
                          <ImageViewer
                            src={
                              _.includes(
                                _.split(
                                  _.get(data, "results.info.faceid.url"),
                                  "/"
                                ),
                                "avatar"
                              )
                                ? [
                                  configValue.MEDIA_DOMAIN +
                                  _.get(data, "results.info.faceid.url"),
                                ]
                                : [
                                  configValue.MEDIA_DOMAIN +
                                  _.replace(
                                    _.get(data, "results.info.faceid.url"),
                                    "maxresdefault.jpg",
                                    "default"
                                  ) +
                                  `.${_.last(
                                    _.split(
                                      _.get(
                                        data,
                                        "results.info.faceid.name"
                                      ),
                                      "."
                                    )
                                  )}`,
                                ]
                            }
                            onClose={() => setIsViewer({ view: false, key: 0 })}
                            backgroundStyle={{
                              backgroundColor: "rgba(0,0,0,0.9)",
                              zIndex: "9999",
                            }}
                            currentIndex={isViewer?.key}
                          />
                        )}
                      </Fragment>
                    )}
                    <Typography style={{ fontWeight: "bold" }}>
                      Face ID KH
                    </Typography>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction={matchesMD ? "column" : "row"}>
          <Grid item className={classes.dottedBorder} xs={7}>
            <Grid>
              <Typography
                variant="h6"
                gutterBottom
                component="span"
                style={{ fontWeight: 700, padding: "8px 0" }}
              >
                Thông tin tài khoản ngân hàng
              </Typography>
            </Grid>
            <Grid container>
              <Grid item style={{ paddingTop: "20px", paddingRight: "120px" }}>
                <Grid
                  container
                  alignItems="center"
                  direction="row"
                  style={{ marginBottom: "26px" }}
                >
                  <Grid item style={{ width: "160px" }}>
                    <Typography
                      className={classes.text}
                      style={{ fontWeight: "bold" }}
                    >
                      Ngân hàng
                    </Typography>
                  </Grid>
                  <Grid item className={classes.text}>
                    {openEdit ? (
                      <AutoCompleteBank
                        handleAnotherBank={handleAnotherBank}
                        editBankValue={bankInfo?.bankname}
                        onChange={(value) =>
                          setBankInfo((prev) => {
                            return {
                              ...prev,
                              bankname: value,
                            };
                          })
                        }
                      />
                    ) : (
                      _.get(data.results, "customer_banks[0].bank_info.name")
                    )}
                  </Grid>
                </Grid>

                {_.get(data.results, "customer_banks[0].bank_name_other") &&
                  !openEdit && (
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      style={{ marginBottom: "26px" }}
                    >
                      <Grid item style={{ width: "160px" }}>
                        <Typography
                          className={classes.text}
                          style={{ fontWeight: "bold" }}
                        ></Typography>
                      </Grid>
                      <Grid item className={classes.text}>
                        {_.get(
                          data.results,
                          "customer_banks[0].bank_name_other"
                        )}
                      </Grid>
                    </Grid>
                  )}
                {openEdit && showBankName && (
                  <Grid
                    container
                    alignItems="center"
                    direction="row"
                    style={{ marginBottom: "26px" }}
                  >
                    <Grid item style={{ width: "160px" }}>
                      <Typography
                        className={classes.text}
                        style={{ fontWeight: "bold" }}
                      ></Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                      <TextField
                        placeholder="Nhập tên ngân hàng"
                        autoFocus
                        style={{ backgroundColor: "#ffffff" }}
                        value={bankInfo.bankanother}
                        onChange={(e) =>
                          setBankInfo((prev) => {
                            return {
                              ...prev,
                              bankanother: e.target.value,
                            };
                          })
                        }
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  alignItems="center"
                  direction="row"
                  style={{ marginBottom: "26px" }}
                >
                  <Grid item style={{ width: "160px" }}>
                    <Typography
                      className={classes.text}
                      style={{ fontWeight: "bold" }}
                    >
                      Tên tài khoản
                    </Typography>
                  </Grid>
                  <Grid item className={classes.text}>
                    {openEdit ? (
                      <TextField
                        style={{ backgroundColor: "#ffffff" }}
                        value={bankInfo.nameaccount}
                        onChange={(e) =>
                          setBankInfo((prev) => {
                            return {
                              ...prev,
                              nameaccount: e.target.value,
                            };
                          })
                        }
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      _.get(data.results, "customer_banks[0].account_name")
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item style={{ marginTop: "20px" }}>
                <Grid
                  container
                  alignItems="center"
                  direction="row"
                  style={{ marginBottom: "24px" }}
                >
                  <Grid item style={{ width: "160px" }}>
                    <Typography
                      style={{ fontWeight: "bold" }}
                      className={classes.text}
                    >
                      Chi nhánh
                    </Typography>
                  </Grid>
                  <Grid item className={classes.text}>
                    {openEdit ? (
                      <TextField
                        style={{ backgroundColor: "#ffffff" }}
                        value={bankInfo.bankbranch}
                        onChange={(e) =>
                          setBankInfo((prev) => {
                            return {
                              ...prev,
                              bankbranch: e.target.value,
                            };
                          })
                        }
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      _.get(data.results, "customer_banks[0].bank_branch")
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  direction="row"
                  style={{ marginBottom: "24px" }}
                >
                  <Grid
                    item
                    className={classes.text}
                    style={{ width: "160px" }}
                  >
                    <Typography
                      className={classes.text}
                      style={{ fontWeight: "bold" }}
                    >
                      Số tài khoản
                    </Typography>
                  </Grid>
                  <Grid item className={classes.text}>
                    {openEdit ? (
                      <TextField
                        style={{ backgroundColor: "#ffffff" }}
                        value={bankInfo.numberaccount}
                        onChange={(e) =>
                          setBankInfo((prev) => {
                            return {
                              ...prev,
                              numberaccount: e.target.value,
                            };
                          })
                        }
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      _.get(data.results, "customer_banks[0].account_number")
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction={matchesMD ? "column" : undefined}
        className={classes.sectionStyle}
      >
        <Grid item>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "25px",
              marginBottom: "20px",
              marginTop: matchesMD ? "20px" : undefined,
            }}
          >
            Ghi chú:
          </Typography>
          <Paper className={clsx(classes.note, matchesMD && "matches-md")}>
            <TextField
              className={classes.message}
              InputProps={{ disableUnderline: true }}
              multiline
              style={{ width: "25em", height: "100%" }}
              id="message"
              rows={5}
            />
          </Paper>
        </Grid>
        <Grid
          item
          className={clsx(classes.buttonGroupInfo, matchesMD && "matches-md")}
        >
          {openEdit && (
            <Button
              className={clsx(classes.buttonInfo, openEdit && "edit-mode")}
              color="primary"
              variant="contained"
              onClick={handleUpdate}
              disabled={loadingUpload}
            >
              Xác nhận
            </Button>
          )}
          {!openEdit && (
            <Button
              onClick={() => {
                setOpenEdit(!openEdit);
                setPersonalInfo((prev) => {
                  return {
                    ...prev,
                    refEcoeEmployeeId: _.get(
                      data,
                      "results.ref_employee_info.id"
                    ),
                    fullName: _.get(data, "results.info.full_name"),
                    phone: _.get(data, "results.info.phone"),
                    personalId: _.get(data, "results.info.personalid"),
                    email: _.get(data, "results.info.email"),
                    cusId: _.get(data, "results.info.code"),
                    accountId: _.get(data, "results.info.id"),
                    consultEmployee: _.get(
                      data,
                      "results.consult_employee_info.id"
                    ),
                    faceId: _.get(data, "results.info.faceid"),
                    birthDay: _.get(data, "results.info.birthday"),
                    gender: _.parseInt(_.get(data, "results.info.gender")),
                  };
                });
                setBankInfo((prev) => {
                  return {
                    ...prev,
                    bankanother: _.get(
                      data.results,
                      "customer_banks[0].bank_name_other"
                    ),
                    bankbranch: _.get(
                      data.results,
                      "customer_banks[0].bank_branch"
                    ),
                    bankname: _.get(
                      data.results,
                      "customer_banks[0].bank_info.id"
                    ),
                    nameaccount: _.get(
                      data.results,
                      "customer_banks[0].account_name"
                    ),
                    numberaccount: _.get(
                      data.results,
                      "customer_banks[0].account_number"
                    ),
                  };
                });
              }}
              className={clsx(classes.buttonInfo, openEdit && "edit-mode")}
              variant="contained"
              disabled={accessDeniedEdit}
            >
              Sửa thông tin
            </Button>
          )}
          {_.get(data.results, "info.active") === true ? (
            <Button
              variant="contained"
              disabled={isSuperAdmin}
              className={classes.blokedBtn}
              onClick={handleOpenBlockedModal}
              disabled={accessDeniedEdit}
            >
              Khoá tài khoản
            </Button>
          ) : (
            <Button
              variant="contained"
              disabled={isSuperAdmin}
              className={classes.activedBtn}
              onClick={handleOpenUnBlockedModal}
              disabled={accessDeniedEdit}
            >
              Mở khóa tài khoản
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container direction="column" className={classes.sectionStyle}>
        <Grid container item className={classes.boxTitle} alignItems="center">
          <Grid item>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              Thông tin eKYC
            </Typography>
          </Grid>
          <Grid item>
            {_.get(data.results, "info.id_status") &&
              _.get(data.results, "info.id_status") !== "INITIAL" &&
              _.get(data.results, "info.id_status") !== "IDENTITY_UPLOADED" ? (
              <Button
                variant="contained"
                className={`${_.get(data.results, "info.id_status") === "VERIFIED"
                  ? classes.activedBtn
                  : classes.blokedBtn
                  } `}
              >
                {returnedIdStatus()}
              </Button>
            ) : null}
          </Grid>
          <Grid
            container
            direction={matchesMD ? "column" : "row"}
            alignItems="flex-start"
          >
            <Grid item style={{ paddingTop: "20px", paddingRight: "120px" }}>
              <Grid
                container
                alignItems="center"
                direction="row"
                style={{ marginBottom: "26px" }}
              >
                <Grid item style={{ width: "160px" }}>
                  <Typography
                    className={classes.text}
                    style={{ fontWeight: "bold" }}
                  >
                    Họ và tên{" "}
                  </Typography>
                </Grid>
                {isEditKyc ? (
                  <TextField
                    defaultValue={_.get(data.results, "info.id_name")}
                    style={{ backgroundColor: "#ffffff" }}
                    value={eKycObj.id_name}
                    onChange={(e) =>
                      setEKycObj({ ...eKycObj, id_name: e.target.value })
                    }
                    variant="outlined"
                    size="small"
                  />
                ) : (
                  <Grid item className={classes.text}>
                    {_.get(data.results, "info.id_name")}
                  </Grid>
                )}
              </Grid>

              <Grid
                container
                alignItems="center"
                direction="row"
                style={{ marginBottom: "26px" }}
              >
                <Grid item style={{ width: "160px" }}>
                  <Typography
                    className={classes.text}
                    style={{ fontWeight: "bold" }}
                  >
                    CMND/CCCD
                  </Typography>
                </Grid>
                {isEditKyc ? (
                  <TextField
                    defaultValue={_.get(data.results, "info.personalid")}
                    style={{ backgroundColor: "#ffffff" }}
                    value={eKycObj.persionalid}
                    onChange={(e) =>
                      setEKycObj({ ...eKycObj, persionalid: e.target.value })
                    }
                    variant="outlined"
                    size="small"
                  />
                ) : (
                  <Grid item className={classes.text}>
                    {_.get(data.results, "info.personalid")}
                  </Grid>
                )}
              </Grid>

              <Grid
                container
                alignItems="center"
                direction="row"
                style={{ marginBottom: "26px" }}
              >
                <Grid item style={{ width: "160px" }}>
                  <Typography
                    className={classes.text}
                    style={{ fontWeight: "bold" }}
                  >
                    Giới tính
                  </Typography>
                </Grid>
                <Grid item className={classes.text}>
                  {isEditKyc ? (
                    <SelectComponent
                      listData={[
                        { id: 0, label: "Nữ" },
                        { id: 1, label: "Nam" },
                      ]}
                      fieldValue="id"
                      fieldTitle="label"
                      handleChange={(e) =>
                        setEKycObj({ ...eKycObj, gender: e.target.value })
                      }
                      defaultValue={_.get(data.results, "info.gender")}
                      width={250}
                      disabledDefault
                      defaultTitle="Chọn giới tính"
                      value={eKycObj.gender}
                    />
                  ) : (
                    returnedGender()
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={{ paddingTop: "20px", paddingRight: "120px" }}>
              <Grid
                container
                alignItems="center"
                direction="row"
                style={{ marginBottom: "26px" }}
              >
                <Grid item style={{ width: "160px" }}>
                  <Typography
                    className={classes.text}
                    style={{ fontWeight: "bold" }}
                  >
                    Ngày sinh{" "}
                  </Typography>
                </Grid>
                {isEditKyc ? (
                  <InputDateAndTimePicker
                    defaultValue={_.get(data.results, "info.birthday")}
                    setDateTime={(date) =>
                      setEKycObj({ ...eKycObj, birthday: date })
                    }
                    format="dd/MM/yyyy"
                  />
                ) : (
                  <Grid item className={classes.text}>
                    {moment(_.get(data.results, "info.birthday")).format(
                      "DD/MM/YYYY"
                    ) === "Invalid date"
                      ? ""
                      : moment(_.get(data.results, "info.birthday")).format("DD/MM/YYYY")}
                  </Grid>
                )}
              </Grid>

              <Grid container style={{ marginBottom: "26px" }}>
                <Grid item style={{ width: "160px" }}>
                  <Typography
                    className={classes.text}
                    style={{ fontWeight: "bold" }}
                  >
                    Ngày cấp CMND/CCCD
                  </Typography>
                </Grid>
                {isEditKyc ? (
                  <InputDateAndTimePicker
                    defaultValue={_.get(data.results, "info.id_issues_date")}
                    setDateTime={(date) =>
                      setEKycObj({ ...eKycObj, id_issues_date: date })
                    }
                    format="dd/MM/yyyy"
                  />
                ) : (
                  <Grid item className={classes.text}>
                    {moment(_.get(data.results, "info.id_issues_date")).format(
                      "DD/MM/YYYY"
                    ) === "Invalid date"
                      ? ""
                      : moment(_.get(data.results, "info.id_issues_date")).format("DD/MM/YYYY")}
                  </Grid>
                )}
              </Grid>
            </Grid>
            {/* CMND Image */}
            <Grid container>
              <Grid item container direction="column" md={4} xs={12}>
                <Grid item style={{ width: "160px", marginBottom: "20px" }}>
                  <Typography
                    className={classes.text}
                    style={{ fontWeight: "bold" }}
                  >
                    Mặt trước CCCD
                  </Typography>
                </Grid>
                <Grid className={classes.idImage}>
                  {_.get(data.results, "info.front_media") ? (
                    <img
                      src={
                        configValue.MEDIA_DOMAIN +
                        _.get(data.results, "info.front_media.url")
                      }
                      alt="CMND"
                    />
                  ) : (
                    <img src={NO_IMAGE} alt="" />
                  )}
                </Grid>
              </Grid>
              <Grid item container direction="column" md={4} xs={12}>
                <Grid item style={{ width: "160px", marginBottom: "20px" }}>
                  <Typography
                    className={classes.text}
                    style={{ fontWeight: "bold" }}
                  >
                    Mặt sau CCCD
                  </Typography>
                </Grid>
                <Grid className={classes.idImage}>
                  {_.get(data.results, "info.back_media") ? (
                    <img
                      src={
                        configValue.MEDIA_DOMAIN +
                        _.get(data.results, "info.back_media.url")
                      }
                      alt="CMND"
                    />
                  ) : (
                    <img src={NO_IMAGE} alt="" />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.displayFlex}>
            {isEditKyc ? (
              <div>
                <Button
                  variant="contained"
                  startIcon={<CheckIcon />}
                  color="primary"
                  className={classes.buttonInfo}
                  onClick={() => handleUpdateKycInfo()}
                  disabled={accessDeniedEdit}
                >
                  Cập nhật
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  variant="contained"
                  startIcon={<EditIcon />}
                  className={classes.buttonInfo}
                  onClick={() => setIsEditKyc(true)}
                  disabled={accessDeniedEdit}
                >
                  Chỉnh sửa
                </Button>
              </div>
            )}

            <div>
              {_.get(data.results, "info.id_status") === "INITIAL" ||
                _.get(data.results, "info.id_status") === "IDENTITY_UPLOADED" ? (
                <Button
                  variant="contained"
                  className={classes.buttonInfo}
                  onClick={handleConfirmKyc}
                >
                  Xác nhận
                </Button>
              ) : null}
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container direction="column" className={classes.sectionStyle}>
        <CustomerProductView
          wallets={_.get(data, "results.info.wallets")}
          accountId={_.get(data.results, "info.id")}
        />
      </Grid>
      {/* <Grid container direction="column" className={classes.accordion} style={{ marginLeft: matchesSM ? '20px' : '150px'}}>
                <Grid item><Typography variant="h5" style={{fontWeight: 'bold', fontSize: '16px', marginBottom: '4px'}}>Lịch sử tư vấn</Typography></Grid>
                <Accordion />
            </Grid> */}
      <Divider style={{ margin: "30px 0" }} />
      <Grid container direction="row" justifyContent="flex-end">
        <ButtonReturn />
      </Grid>
    </Paper>
  );
};

export default DetailCustomerPage;
