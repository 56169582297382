import { Paper, Grid, CircularProgress } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ButtonExportExcel from "../../components/ButtonExportExcel.js";
import TableComponent from "../../components/TableComponent.js";
import PrimaryButton from "../../components/UI/PrimaryButton.js";
import { CREATE_PROMOTION, EXPORT_PROMOTION } from "../../constants/defined.js";
import { checkedPermission } from "../../helper/index.js";
import slugs from "../../resources/slugs.js";
import { StoreContext } from "../../resources/store.js";
import { useGetListEvent } from "./hooks";

const useStyles = makeStyles((theme) => ({
  wrapperTable: {
    width: '100%',
  },
  marginBottom: {
    marginBottom: 30,
  },
  wapperLoading: {
    width: '100%',
    height: 'calc(100vh - 113px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const headMenu = [
  { id: "code", numeric: false, disablePadding: false, label: "Mã CT" },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Tên chương trình",
  },
  { id: "start", numeric: false, disablePadding: false, label: "Ngày bắt đầu" },
  {
    id: "end",
    numeric: false,
    disablePadding: false,
    label: "Ngày kết thúc",
  },
  {
    id: "gameStatus",
    numeric: false,
    disablePadding: false,
    label: "Trạng thái",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Loại chương trình",
  },
];

const PromotionIndex = () => {
  const classes = useStyles();
  const history = useHistory();

  const { userInfo, accessObjectPermissions } = useContext(StoreContext);

  const [eventData, setEventData] = useState([]);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);

  const [idJackPlaying, setIdJackPlaying] = useState(null);
  const [idBingoPlaying, setIdBingoPlaying] = useState(null);

  const { loading, data } = useGetListEvent();

  useEffect(() => {
    if (_.size(data) > 0) {
      let tmp = _.map(data, (item) => {
        if (
          _.get(item, "code") === 'JACK'
          && !moment().isBefore(moment(_.get(item, "start")).format())
          && !moment().isAfter(moment(_.get(item, "end")).format())
        ) {
          setIdJackPlaying(_.get(item, "id"));
        } else if (
          _.get(item, "code") === 'BINGO'
          && !moment().isBefore(moment(_.get(item, "start")).format())
          && !moment().isAfter(moment(_.get(item, "end")).format())
        ) {
          setIdBingoPlaying(_.get(item, "id"));
        };

        return {
          id: _.get(item, "id"),
          code: _.get(item, "code") || _.get(item, "id"),
          name: _.get(item, "name"),
          start: _.get(item, "start") ? moment(_.get(item, "start")).format("DD/MM/YYYY") : '-',
          end: _.get(item, "end") ? moment(_.get(item, "end")).format("DD/MM/YYYY") : '-',
          gameStatus: moment().isBefore(moment(_.get(item, "start")).format())
            ? "Sắp diễn ra"
            : moment().isAfter(moment(_.get(item, "end")).format())
              ? "Kết thúc"
              : "Đang diễn ra",

          type: _.get(item, "type_info.name"),
          typeCode: _.get(item, "type_info.code"),
        }
      });
      setEventData(tmp);
    }
  }, [loading]);

  if (loading || _.isUndefined(data)) {
    return (
      <div className={classes.wapperLoading}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <Grid container direction="row">
      <Grid container direction="row" justifyContent="space-between" className={classes.marginBottom}>
        <Grid item xs={3}>
          <PrimaryButton
            labelButton="Tạo khuyến mãi mới"
            actionButton={() => history.push(slugs.promotionCreate)}
            iconButton={<Add />}
            disabled={!checkedPermission(userInfo, accessObjectPermissions, CREATE_PROMOTION)}
          />
        </Grid>
        <Grid item xs={3}>
          <ButtonExportExcel
            exportName="EXPORT_GAME"
            event={idJackPlaying}
            eventBingo={idBingoPlaying}
            disabled={!checkedPermission(userInfo, accessObjectPermissions, EXPORT_PROMOTION)}
          />
        </Grid>
      </Grid>

      <Paper className={classes.wrapperTable}>
        <TableComponent
          align="center"
          data={_.slice(eventData, skip, 10 * page)}
          headMenu={headMenu}
          rowPath="promotion"
          fieldValue='id'
          setSkip={(skip) => setSkip(skip)}
          page={page}
          setPage={setPage}
          count={eventData.length}
        />
      </Paper>
    </Grid>
  );
};

export default PromotionIndex;
