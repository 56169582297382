import _ from "lodash";
import React, {useEffect, useState} from "react";
import {
  makeStyles, 
  withStyles,
  Grid, Paper, TextField,
  RadioGroup, Radio, FormControlLabel, CircularProgress, IconButton, Button,
} from "@material-ui/core";
import { useInsertResultBingoRound } from "../../hooks";
import { useHistory } from "react-router";
import moment from "moment";
import { BINGO_INPUT_FINAL_RESULTS, BINGO_INPUT_WEEKLY_RESULTS } from "../../../../constants/defined";


const useStyles = makeStyles((theme) => ({
  wrapperPaper: {
    padding: "30px 15px",
    marginBottom: "30px",
  },
  heading: {
    fontWeight: "bold",
    fontSize: 24,
  },
  textCenter: {
    '& input': {
      textAlign: 'center',
    }    
  },
  wapperLoading: {
    width: '100%',
    height: 'calc(100vh - 113px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const CustomRadio = withStyles((theme) => ({
  root: {
    color: '#00837B',
    '&$checked': {
      color: '#00837B',
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);

const ITEM = {
  "code": "",
  "setting": null,
  "reward": null,
  "result_date": moment(new Date()).format("YYYY-MM-DD"),
  "channel": "HCM",
  "event": null,
  "index": null,
};

const SetBingoResultRound = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { data, round, eventData } = _.get(props,'location.state');
  const last_numbers_result = _.get(_.head(_.get(eventData, 'bingo_result_settings')), 'last_numbers') || 6;
  const [isSpecialResult, setIsSpecialResult] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [eventObj, setEventObj] = useState({});

  const { insertResultBingoRound, loading: loadingInsert, data: messageSuccess } = useInsertResultBingoRound(history, setSubmitDisabled, _.get(eventData, 'id'));

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitDisabled(true);
    let arg = [..._.filter(eventObj, o => !_.isEmpty(_.get(o,'code')) && !_.get(o,'notImport'))];

    insertResultBingoRound({
      variables: { arg },
    });

  }

  useEffect(()=>{
    let enteredBingoResults = JSON.parse(JSON.stringify(_.get(data, 'bingo_results')));
    if(_.size(enteredBingoResults) > 0) {
      setIsSpecialResult( _.get(_.head(enteredBingoResults), 'bingo_result_setting.special'));
    };
  },[data]);

  useEffect(()=>{
    const eventId = _.get(eventData, 'id');
    let enteredBingoResults = JSON.parse(JSON.stringify(_.get(data, 'bingo_results')));
    
    let tmp = _.map((isSpecialResult ? BINGO_INPUT_FINAL_RESULTS : BINGO_INPUT_WEEKLY_RESULTS), item => {
      const settingId = _.get(_.find(_.get(eventData, 'bingo_result_settings'), {'code': item, special: isSpecialResult}), 'id');
      let valEntered = _.find(enteredBingoResults, ['bingo_result_setting.code', item]);
      const getReward = _.get(_.find(_.get(eventData, 'bingo_result_settings'), {'code': item, special: isSpecialResult}), 'reward') 

      if(valEntered) {
        _.remove(enteredBingoResults, (n) => {
          return _.get(n, 'id') === _.get(valEntered, "id");
        })
        return {
          ...ITEM, event: eventId, setting: settingId, index: _.get(data, 'id'), code: valEntered?.code, reward: _.toInteger(getReward), notImport: true,
        }
      } else {
        return {
          ...ITEM, event: eventId, setting: settingId, index: _.get(data, 'id'), reward: _.toInteger(getReward),
        }
      }
    });

    setEventObj(tmp);
  },[isSpecialResult]);

  if (loadingInsert) return (
    <div className={classes.wapperLoading}>
        <CircularProgress />
    </div>
  )

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container className={classes.heading}>
        <Grid item>{`Số trúng thưởng ${round}`}</Grid>
      </Grid>

      <Paper className={classes.wrapperPaper}>
        <Grid direction="column" container spacing={3}>
          <Grid item xs={12} style={{color: "rgba(0, 0, 0, 0.54)", fontSize: "1.3rem", marginBottom: 0, fontWeight: 'bold'}}>
              Giải thưởng
          </Grid>

          <Grid item xs={12} style={{marginBottom: '15px'}}>
            <Grid direction="row" container spacing={3} alignItems="center">
              <Grid item style={{fontSize: "16px", fontWeight: 'bold'}}>
                Loại giải thưởng:
              </Grid>

              <Grid item>
                <RadioGroup
                  style={{flexDirection:'row'}}
                  aria-label="setting-result-round"
                  name="setting-result-round"
                  value={isSpecialResult}
                  onChange={(e) => setIsSpecialResult(_.get(e,'target.value')==='true') ? true : false}
                >
                  <FormControlLabel value={false} disabled={!_.isEmpty(_.get(data, 'bingo_results'))} control={<CustomRadio />} label="Giải thường" />
                  <FormControlLabel value={true} disabled={!_.isEmpty(_.get(data, 'bingo_results'))} control={<CustomRadio />} label="Giải chung cuộc" />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{height:'45px', flexBasis: 'unset', fontWeight: 'bold', opacity: '0.6', background: '#ccc'}}>
            <Grid direction="row" container spacing={3}>
              <Grid item xs={3} style={{textAlign:'center'}}>Hạng giải thưởng</Grid>
              <Grid item xs={3} style={{textAlign:'center'}}>Mã số giải thưởng</Grid>
              <Grid item xs={6} style={{textAlign:'center'}}>Mô tả</Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {
              _.map(eventObj, (item, key) => {
                let getSetting = _.find(_.get(eventData, 'bingo_result_settings'), ['id', _.get(item, 'setting')]);
                const disableItem =  _.get(item, 'notImport');

                return(
                  <Grid
                    key={key}
                    direction="row" container spacing={3} alignItems="center"
                    style={{borderBottom: '1px solid #ccc', marginBottom: '15px'}}
                  >
                    <Grid item xs={3} style={{textAlign:'center'}}>
                      {_.get(getSetting,'name')}
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        className={classes.textCenter}
                        placeholder="Nhập kết quả"
                        variant="outlined"
                        size="small"
                        style={{ backgroundColor: disableItem ? '#eee' : '#ffffff' }}
                        value={_.get(item, 'code')}
                        disabled={disableItem}
                        onChange={(e) => {
                          if(_.size(e.target.value) > last_numbers_result) return;
                          let tmp = _.cloneDeep(eventObj);
                          tmp[key]['code'] = e.target.value;
                          // tmp[key]['reward'] = _.toInteger(e.target.value);
                          setEventObj(tmp)
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} style={{textAlign:'center'}}>
                      <TextField
                        fullWidth
                        placeholder={`Mô tả ${_.toLower(_.get(getSetting,'name'))}`}
                        variant="outlined"
                        size="small"
                        style={{ backgroundColor: disableItem ? '#eee' : '#ffffff' }}
                        value={_.get(item, 'channel')}
                        disabled={disableItem}
                        onChange={(e) => {
                          let tmp = _.cloneDeep(eventObj);
                          tmp[key]['channel'] = e.target.value;
                          setEventObj(tmp)
                        }}
                      />
                    </Grid>

                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>
      </Paper>

      <Grid item container justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={submitDisabled}
        >
          Xác nhận
        </Button>
      </Grid>
    </Grid>
  );
};

export default SetBingoResultRound;
