import { Grid, IconButton, makeStyles, TextField } from "@material-ui/core";
import { AddCircleRounded, RemoveCircleOutline } from "@material-ui/icons";
import _ from "lodash";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(() => ({
  addBtn: {
    background: "#00837B",
    borderRadius: "6px",
    color: "#fff",
    fontSize: "16px",

    "& > span": {
      gap: "10px",
      display: "flex",
      alignItems: "center",
    },

    "&:hover": {
      background: "rgba(0,131,123,0.8)",
    },
  },
  textLabel: {
    fontWeight: "bold",
  },
  textTitle: {
    fontWeight: "bold",
    fontSize: "24px",
    borderBottom: "1px dashed #E2E1E1",
    color: "#00837B",

    "& > p": {
      margin: 0,
    },
  },
}));

const EventCalendars = ({ eventCalendars, setEventCalendars }) => {
  const [inputValues, setInputValues] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    let defautValue = _.map(eventCalendars, (item) => {
      return {
        start: _.get(item, "start"),
        end: _.get(item, "end"),
      };
    });
    setInputValues(defautValue);
  }, [eventCalendars]);

  const addEventCalendars = () => {
    setInputValues([...inputValues, { start: null, end: null }]);
  };

  const remmoveEventCalendars = (index) => {
    const fields = [...inputValues];
    fields.splice(index, 1);
    setInputValues(fields);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputValues];
    values[index][event.target.name] = event.target.value;
    setInputValues(values);
  };

  const onBlurInput = () => {
    setEventCalendars(inputValues);
  };

  return (
    <Grid container direction="column" spacing={4}>
      {_.map(inputValues, (item, key) => (
        <>
          <Grid item container alignItems="center" spacing={1}>
            <Grid item xs={1}>
              <p className={classes.textLabel}>{`Đợt ${key + 1}`}</p>
            </Grid>
            <Grid item xs={1} className={classes.textLabelForm}>
              <p className={classes.textLabel}>
                Thời gian bắt đầu<span style={{ color: "red" }}>*</span>
              </p>
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                variant="outlined"
                className={classes.input}
                value={item?.start}
                name="start"
                type="datetime-local"
                onChange={(e) => handleInputChange(key, e)}
                onBlur={onBlurInput}
              />
            </Grid>

            <Grid item xs={1} className={classes.textLabelForm}>
              <p className={classes.textLabel}>
                Thời gian kết thúc<span style={{ color: "red" }}>*</span>
              </p>
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                variant="outlined"
                className={classes.input}
                value={item?.end}
                name="end"
                type="datetime-local"
                onChange={(e) => handleInputChange(key, e)}
                onBlur={onBlurInput}
              />
            </Grid>
            <Grid item xs={1}>
              {inputValues.length > 1 && (
                <IconButton onClick={() => remmoveEventCalendars(key)}>
                  <RemoveCircleOutline style={{ fill: "red" }} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </>
      ))}
      <Grid item container>
        <IconButton
          onClick={() => addEventCalendars()}
          className={classes.addBtn}
        >
          <AddCircleRounded style={{ fill: "white" }} />{" "}
          <span>Tạo đợt mới </span>
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default EventCalendars;
