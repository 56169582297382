import React, { useEffect, useState, useContext } from "react";
import * as _ from "lodash";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { validEmail } from "../../components/customer/regex";

import axios from "axios";

import { useCreateCustomer } from "../Customer/hooks";

import { user } from "../../constants/user";
import AutoCompleteEmployee from "../../components/AutoCompleteEmployee";
import slugs from "../../resources/slugs";
import { ArrowBackOutlined, Telegram } from "@material-ui/icons";

import { validNumber, validPhone } from "./regex";
import InputDateAndTimePicker from "../../components/InputDateAndTimePicker";
import SelectComponent from "./components/SelectComponent";
import { Divider, Paper, Typography } from "@material-ui/core";
import AutoCompleteBank from "../../components/AutoCompleteBank";
import clsx from "clsx";
import { PrimaryButton } from "../../components/UI";

import { ConfigContext } from '../../context/ConfigContext';
import { StoreContext } from "../../resources/store";

const style = {
  borderRadius: 0,
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: "20px 0",
  },
  formContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  err: {
    color: "#db2828",
    paddingTop: "5px",
  },
  textLabelForm: {
    wordWrap: "break-word",
    width: 160,
    fontWeight: "bold",
    marginRight: 30,
  },
  input: {
    width: 250,
    backgroundColor: "white",
    marginLeft: "0",
    "& > .MuiFormHelperText-marginDense": {
      backgroundColor: "#FAFAFA",
      margin: 0,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none",
    display: "flex",
    maxWidth: "760px",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    color: "#fff",
    borderRadius: 0,
    textTransform: "uppercase",
  },
  buttonTitle: {
    backgroundColor: "#07807d",
    color: "white",
    borderRadius: 0,
    fontSize: "16px",
    width: "26em",
    fontWeight: "bold",
  },
  buttonCreateConfirm: {
    backgroundColor: "#07807d",
    color: "#fff",
    borderRadius: 0,
    textTransform: "uppercase",
  },
  dottedBorder: {
    border: "1px dashed #B9B9B9",
    boxSizing: "border-box",
    borderRadius: "6px",
    padding: "24px",
  },
  bold: {
    fontWeight: 700,
  },
  marginBottom: {
    marginBottom: "30px",
  },
  typeTab: {
    fontSize: "20px",
    color: "#B9B9B9",
    "&:not(.selected)": {
      cursor: "pointer",
    },
    "&.selected": {
      color: "#0A2240",
      position: "relative",
      "&:after": {
        content: `''`,
        width: "calc(100% - 30px)",
        height: "3px",
        background: "#DA6744",
        position: "absolute",
        bottom: "10px",
        left: "15px",
      },
    },
  },
  wrapperContent: {
    minHeight: "calc(100vh - 80px)",
    position: "relative",
    padding: "24px",
    margin: "30px auto",
  },
}));

const TAB_MENU = [
  { id: 1, code: "CREATE", name: "Tạo khách hàng mới" },
];

const CreateCustomerPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const { userInfo, accessPermissions } = useContext(StoreContext);
  const configValue = useContext(ConfigContext);

  const [faceId, setFaceId] = useState([]);
  const [personalInfo, setPersonalInfo] = useState({
    email: "",
    name: "",
    phone: "",
    personalId: "",
    gender: -1,
    birthDay: new Date(),
    consultEcoeId: "",
    refEcoeId: "",
  });
  const [bankInfo, setBankInfo] = useState({
    nameaccount: "",
    numberaccount: "",
    bankname: "",
    bankbranch: "",
    bankanother: "",
  });
  const [error, setError] = useState({
    nameError: false,
    phoneError: false,
    personalIdError: false,
    ecoeIdError: false,
    emailError: false,
  });
  const [showBankName, setShowBankName] = useState(false);
  const [open, setOpen] = useState(false);

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const { create: createCustomer } = useCreateCustomer(setOpen, history);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const { email, name, phone, personalId } = personalInfo;
    const validateCheck = [email, name, phone, personalId].every(
      (item) => item
    );
    validateCheck ? setSubmitDisabled(false) : setSubmitDisabled(true);
  }, [personalInfo]);

  useEffect(() => {
    validate();
  }, [personalInfo]);

  const validate = () => {
    // email regex
    if (
      _.isEmpty(personalInfo.email) === false &&
      !validEmail.test(personalInfo.email)
    ) {
      setError((prev) => ({
        ...prev,
        emailError: true,
      }));
    } else {
      setError((prev) => ({
        ...prev,
        emailError: false,
      }));
    }
    // phone regex
    if (
      _.isEmpty(personalInfo.phone) === false &&
      !validPhone.test(personalInfo.phone)
    ) {
      setError((prev) => ({
        ...prev,
        phoneError: true,
      }));
    } else {
      setError((prev) => ({
        ...prev,
        phoneError: false,
      }));
    }
    // personal id regex
    if (
      _.isEmpty(personalInfo.personalId) === false &&
      !validNumber.test(personalInfo.personalId)
    ) {
      setError((prev) => ({
        ...prev,
        personalIdError: true,
      }));
    } else {
      setError((prev) => ({
        ...prev,
        personalIdError: false,
      }));
    }
  };

  const uploadHandler = async (e) => {
    setLoadingUpload(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("media_type_code", "AVATAR");
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        configValue.API_UPLOAD_FILE,
        formData,
        config
      );
      setLoadingUpload(false);
      setFaceId(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = () => {
    const data = {
      ...bankInfo,
      ...personalInfo,
    };

    createCustomer({
      variables: {
        customer: {
          account: {
            username: _.get(data, "personalId"),
            full_name: _.get(data, "name"),
            email: _.get(data, "email"),
            faceid: faceId.length !== 0 ? _.get(faceId[0], "id") : undefined,
            gender: _.get(data, "gender"),
            birthday: _.get(data, "birthDay"),
            personalid: _.get(data, "personalId"),
            phone: _.get(data, "phone"),
            created_by: _.get(userInfo,"id"),
            roles: [{ code: "CUSTOMER" }],
            type: "CUSTOMER",
            source: "WEBADMIN",
          },
          ref_employee: _.get(data, "refEcoeId") || undefined,
          consult_employee: _.get(data, "consultEcoeId") || undefined,
          customer_banks: !_.get(data, "numberaccount") ? undefined : {
            bank: _.get(data, "bankname"),
            bank_branch: _.get(data, "bankbranch"),
            bank_name_other: _.get(data, "bankanother"),
            account_name: _.get(data, "nameaccount"),
            account_number: _.get(data, "numberaccount"),
          },
        },
      },
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleGender = (e) => {
    setPersonalInfo((prev) => {
      return {
        ...prev,
        gender: e.target.value,
      };
    });
  };

  const handleAnotherBank = (message) => {
    setShowBankName(message);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={3}
        justifyContent="space-between"
        className={classes.marginBottom}
      >
        <Grid item>
          <Grid container spacing={4}>
            <Grid item className={clsx(classes.typeTab, "selected")}>
              Tạo khách hàng mới
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Paper className={classes.wrapperContent}>
        <FormControl>
          <Grid
            container
            spacing={1}
            alignItems="center"
            style={{ marginLeft: 0 }}
          >
            <Grid item className={classes.textLabelForm}>
              <p>
                Tên <span style={{ color: "red" }}>*</span>
              </p>
            </Grid>
            <Grid item>
              <TextField
                style={style}
                size="small"
                id="input-with-icon-employee"
                variant="outlined"
                placeholder="Họ tên"
                className={classes.input}
                required
                value={personalInfo.name}
                onChange={(e) =>
                  setPersonalInfo((prev) => {
                    return {
                      ...prev,
                      name: e.target.value,
                    };
                  })
                }
                inputProps={{
                  minLength: 2,
                  maxLength: 100,
                }}
              />
            </Grid>
            <Grid
              item
              alignItems="center"
              style={{
                margin: matchesMD ? "40px 0px" : "0 30px 0 20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  wordWrap: "break-word",
                  width: matchesMD ? 150 : matchesSM ? 80 : 110,
                  fontWeight: "bold",
                  marginRight: matchesMD ? 38 : 30,
                }}
              >
                Điện thoại <span style={{ color: "red" }}>*</span>
              </span>
              <TextField
                style={style}
                size="small"
                placeholder="Điện thoại liên hệ"
                id="input-with-icon-employee"
                variant="outlined"
                helperText={
                  error.phoneError &&
                  personalInfo.phone !== "" && (
                    <div className={classes.err}>Số điện thoại không hợp lệ</div>
                  )
                }
                value={personalInfo.phone}
                onChange={(e) =>
                  setPersonalInfo((prev) => {
                    return {
                      ...prev,
                      phone: e.target.value,
                    };
                  })
                }
                className={classes.input}
                inputProps={{
                  maxLength: 10,
                }}
                required
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            alignItems="center"
            style={{ marginLeft: 0 }}
          >
            <Grid item className={classes.textLabelForm}>
              <p variant="h3">
                Email <span style={{ color: "red" }}>*</span>
              </p>
            </Grid>
            <Grid item>
              <TextField
                style={style}
                size="small"
                placeholder="Email"
                id="input-with-icon-employee"
                variant="outlined"
                helperText={
                  error.emailError &&
                  personalInfo.email !== "" && (
                    <div className={classes.err}>Email không hợp lệ</div>
                  )
                }
                value={personalInfo.email}
                onChange={(e) => {
                  setPersonalInfo((prev) => {
                    return {
                      ...prev,
                      email: e.target.value,
                    };
                  });
                  if (validEmail.test(personalInfo.email)) {
                    setError((prev) => ({
                      ...prev,
                      emailError: false,
                    }));
                  }
                }}
                className={classes.input}
                required
              />
            </Grid>
            <Grid
              item
              alignItems="center"
              style={{
                margin: matchesMD ? "40px 0px" : "0 30px 0 20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  wordWrap: "break-word",
                  width: matchesMD ? 150 : matchesSM ? 80 : 110,
                  fontWeight: "bold",
                  marginRight: matchesMD ? 38 : 30,
                }}
              >
                CMND/CCCD <span style={{ color: "red" }}>*</span>
              </span>
              <TextField
                style={style}
                size="small"
                placeholder="CMND/CCCD"
                id="input-with-icon-employee"
                variant="outlined"
                helperText={
                  error.personalIdError &&
                  personalInfo.personalId !== "" && (
                    <div className={classes.err}>
                      Căn cước công dân không hợp lệ
                    </div>
                  )
                }
                className={classes.input}
                value={personalInfo.personalId}
                onChange={(e) =>
                  setPersonalInfo((prev) => {
                    return {
                      ...prev,
                      personalId: e.target.value,
                    };
                  })
                }
                required
                inputProps={{
                  maxLength: 12,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            alignItems="center"
            className={classes.margin}
          >
            <Grid item className={classes.textLabelForm}>
              Upload hình ảnh Face ID
            </Grid>
            <Grid item>
              <input
                accept="image/*"
                id="face-id-customer"
                multiple
                type="file"
                hidden
                onChange={uploadHandler}
              />
              <label htmlFor="face-id-customer">
                {faceId.length === 0 ? (
                  <CloudUploadIcon style={{ fontSize: 110, cursor: "pointer" }} />
                ) : (
                  <img
                    style={{ width: 110, borderRadius: "50%" }}
                    src={configValue.MEDIA_DOMAIN + faceId[0].url}
                    alt="avatar"
                  />
                )}
              </label>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            alignItems="center"
            className={classes.margin}
          >
            <Grid item className={classes.textLabelForm}>
              Sinh nhật
            </Grid>
            <Grid item xs={3}>
              <InputDateAndTimePicker
                defaultValue={personalInfo.birthDay}
                setDateTime={(birthday) =>
                  setPersonalInfo((prev) => {
                    return {
                      ...prev,
                      birthDay: birthday,
                    };
                  })
                }
                format="dd/MM/yyyy"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            alignItems="center"
            className={classes.margin}
          >
            <Grid item className={classes.textLabelForm}>
              Giới tính
            </Grid>
            <Grid item xs={3}>
              <SelectComponent
                listData={[
                  { id: 0, label: "Nữ" },
                  { id: 1, label: "Nam" },
                ]}
                fieldValue="id"
                fieldTitle="label"
                handleChange={handleGender}
                width={250}
                defaultTitle="Chọn giới tính"
                disabledDefault={true}
                value={personalInfo.gender}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            alignItems="center"
            className={classes.margin}
          >
            <Grid item className={classes.textLabelForm}>
              Mã nhân viên ECOE giới thiệu
            </Grid>
            <Grid item xs={3}>
              <AutoCompleteEmployee
                onChange={(value) =>
                  setPersonalInfo((prev) => {
                    return {
                      ...prev,
                      refEcoeId: value,
                    };
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            alignItems="center"
            className={classes.margin}
          >
            <Grid item className={classes.textLabelForm}>
              <p>Nhân viên tư vấn </p>
            </Grid>
            <Grid item xs={3}>
              <AutoCompleteEmployee
                onChange={(value) =>
                  setPersonalInfo((prev) => {
                    return {
                      ...prev,
                      consultEcoeId: value,
                    };
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid className={classes.dottedBorder} item xs={12} py={4} px={2}>
            <Grid item container xs={12} spacing={1}>
              <Grid item container xs={12} alignItems="center" spacing={1} py={2}>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="span"
                  style={{ fontWeight: 700, padding: "8px 0" }}
                >
                  Thông tin tài khoản ngân hàng
                </Typography>
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                spacing={1}
                className={classes.margin}
              >
                <Grid item container xs={6} alignItems="center" px={4}>
                  <Grid item xs={4}>
                    <Typography variant="body3" className={classes.bold}>
                      Ngân hàng
                    </Typography>
                  </Grid>
                  <Grid item xs={8} paddingTop={3}>
                    <AutoCompleteBank
                      handleAnotherBank={handleAnotherBank}
                      onChange={(value) =>
                        setBankInfo((prev) => {
                          return {
                            ...prev,
                            bankname: value,
                          };
                        })
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={6} alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body3" className={classes.bold}>
                      Chi nhánh ngân hàng
                    </Typography>
                  </Grid>
                  <Grid item xs={8} paddingTop={3}>
                    <TextField
                      style={style}
                      size="small"
                      id="input-bank-account"
                      variant="outlined"
                      placeholder="Nhập chí nhánh ngân hàng"
                      className={classes.input}
                      value={bankInfo.bankbranch}
                      onChange={(e) =>
                        setBankInfo((prev) => {
                          return {
                            ...prev,
                            bankbranch: e.target.value,
                          };
                        })
                      }
                      inputProps={{
                        minLength: 0,
                        maxLength: 100,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {showBankName && (
              <Grid item container xs={12} spacing={1}>
                <Grid item container xs={6} alignItems="center" px={4}>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={8} paddingTop={3}>
                    <TextField
                      autoFocus
                      style={style}
                      size="small"
                      id="input-bank-name"
                      variant="outlined"
                      placeholder="Nhập tên ngân hàng"
                      className={classes.input}
                      value={bankInfo.bankanother}
                      onChange={(e) =>
                        setBankInfo((prev) => {
                          return {
                            ...prev,
                            bankanother: e.target.value,
                          };
                        })
                      }
                      inputProps={{
                        minLength: 0,
                        maxLength: 200,
                      }}
                    />
                    <Typography variant="body3" component="div">
                      Cú pháp đặt tên: “Tên viết tắt - Tên ngân hàng”. Ví dụ: Nam
                      A Bank - Ngân hàng TMCP Nam Á
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={6} alignItems="center"></Grid>
              </Grid>
            )}

            <Grid item container xs={12} spacing={1}>
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                spacing={1}
                className={classes.margin}
              >
                <Grid item container xs={6} alignItems="center" px={4}>
                  <Grid item xs={4}>
                    <Typography variant="body3" className={classes.bold}>
                      Tên tài khoản
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      style={style}
                      size="small"
                      id="input-bank-account"
                      variant="outlined"
                      placeholder="Nhập tên tài khoản"
                      className={classes.input}
                      value={bankInfo.nameaccount}
                      onChange={(e) =>
                        setBankInfo((prev) => {
                          return {
                            ...prev,
                            nameaccount: e.target.value,
                          };
                        })
                      }
                      inputProps={{
                        minLength: 0,
                        maxLength: 100,
                      }}
                    />
                    <Typography variant="body3">
                      Nhập tiếng việt không dấu
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={6} alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body3" className={classes.bold}>
                      Số tài khoản
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      style={style}
                      size="small"
                      id="input-bank-number"
                      variant="outlined"
                      placeholder="Nhập số tài khoản"
                      className={classes.input}
                      value={bankInfo.numberaccount}
                      onChange={(e) =>
                        setBankInfo((prev) => {
                          return {
                            ...prev,
                            numberaccount: e.target.value,
                          };
                        })
                      }
                      inputProps={{
                        minLength: 0,
                        maxLength: 100,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>


          {/* <Grid
            container
            spacing={1}
            alignItems="center"
            style={{ marginTop: "90px" }}
            justifyContent={matchesMD ? undefined : "flex-end"}
          >
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                startIcon={<Telegram />}
                style={{
                  padding: "6px 20px",
                  marginRight: "30px",
                }}
                disabled={
                  submitDisabled ||
                  error.ecoeRecommendError ||
                  error.ecoeIdError ||
                  error.emailError ||
                  loadingUpload ||
                  error.personalIdError ||
                  error.phoneError
                }
                onClick={handleSubmit}
              >
                Xác nhận
              </Button>

              <ButtonReturn link={slugs.customer} />
            </Grid>
          </Grid> */}

          <Divider style={{ margin: "30px auto" }} />

          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent={matchesMD ? undefined : "flex-end"}
          >
            <Grid item>
              <PrimaryButton
                labelButton="Quay lại"
                iconButton={<ArrowBackOutlined />}
                style='back'
                actionButton={() => history.push(slugs.customer)}
              />
            </Grid>

            <Grid item>
              <PrimaryButton
                labelButton="Xác nhận"
                disabled={
                  submitDisabled ||
                  error.ecoeRecommendError ||
                  error.ecoeIdError ||
                  error.emailError ||
                  loadingUpload ||
                  error.personalIdError ||
                  error.phoneError
                }
                actionButton={handleSubmit}
                iconButton={<Telegram />}
              />
            </Grid>

          </Grid>
        </FormControl>
      </Paper>
    </>
  );
};

export default CreateCustomerPage;
