import React from 'react';
import Modal from '@material-ui/core/Modal';
import { useHistory } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useCreate } from '../../../pages/Employee/hooks';
import _ from 'lodash';
import swal from "sweetalert";

const ModalCreateEmployee = ({ openModalCreateEmployee, setOpenModalCreateEmployee, employeeItem }) => {

    const history = useHistory();


    const { employeeCreate, loading } = useCreate(setOpenModalCreateEmployee, history);

    const handleClose = () => {
        setOpenModalCreateEmployee(false);
    };

    const handleClick = () => {
        employeeCreate({
            variables: employeeItem
        })
        
        
    }

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            outline: 'none'
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            outline: 'none',
            display: 'flex',
            width: '760px',
            flexDirection: 'column',
            alignItems: 'center',
        },
        buttonConfirm: {
            color: '#fff', 
            borderRadius: 0, 
            padding: '6px 36px',
            margin: '0 10px'
        },
    }));
    const classes = useStyles();

     
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModalCreateEmployee}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 1000,
            }}
    >
        <Fade in={openModalCreateEmployee}>
            <div className={classes.paper}>
                <p id="transition-modal-description" style={{fontWeight: 'bolder'}}>Bạn có muốn tạo tài khoản này?</p>
                <Grid container direction="row" justifyContent="center">
                    <Button disabled={loading} style={{ backgroundColor: '#07807D' }} className={classes.buttonConfirm} onClick={handleClick}> 
                        Xác nhận 
                    </Button>
                    <Button style={{ backgroundColor: 'black' }} className={classes.buttonConfirm} onClick={handleClose}>
                        Huỷ
                    </Button>
                </Grid>

            </div>
        </Fade>
    </Modal>
    )
}

export default ModalCreateEmployee
