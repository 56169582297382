import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/styles/useTheme';
import Grid from '@material-ui/core/Grid';
import CardBanner from '../../../components/CardBanner';
import { Fragment } from 'react';
import CardUploadBanner from '../../../components/CardUploadBanner';
import { useHistory } from 'react-router-dom';
import { useGetListBanner, useGetBannerType } from '../../../pages/Other/Banner/hooks';

import _ from 'lodash';
import ButtonReturn from '../../../components/ButtonReturn';
import slugs from '../../../resources/slugs';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import { ConfigContext } from '../../../context/ConfigContext';
import { checkedPermission } from '../../../helper';
import { VIEW_BANNER, CREATE_BANNER } from '../../../constants/defined';
import { StoreContext } from '../../../resources/store';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // justifyContent: 'space-around',
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        '& > ul': {
            width: '100%'
        }
    },
    textAddBanner: {
        marginTop: '100px',
        marginLeft: '15%',
        fontSize: '20px',
        fontWeight: 'bold',
        color: "#e27f35",
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    confirmButton: {
        backgroundColor: '#2c8a81',
        color: '#ffffff',
        padding: '10px 30px',
        marginRight: '30px',
        borderRadius: '0'
    },

    buttonSecondaryStyle: {
        backgroundColor: '#E27F35',
        '&:hover': {
            backgroundColor: '#c56e2d',
        }

    },
    groupButtonStyle: {
        marginTop: '50px',
        '&.RightSrtle': {
            justifyContent: 'flex-end',
        },
        '&.centerStyle': {
            justifyContent: 'center',
        },
        '& > button:not(:last-child)': {
            marginRight: '30px',
        },
    }
}))

export default function SettingBanner() {
    const [getListBanner, { dataBanner }] = useGetListBanner();

    const [bannerType, setBannerType] = useState("BANNER");

    const { dataBannerType } = useGetBannerType();

    const { userInfo, accessObjectPermissions } = useContext(StoreContext);

    const configValue = useContext(ConfigContext);

    const classes = useStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

    const [loading, setLoading] = useState(false);
    const [openUploadCard, setOpenUploadCard] = useState(false);


    const handleChange = (event, newValue) => {

        getListBanner({
            variables: {
                code: newValue
            }
        })
        setBannerType(newValue);
    };
    useEffect(() => {
        getListBanner({
            variables: {
                code: bannerType
            }
        });
    }, [loading])

    useEffect(() => {
        getListBanner({
            variables: {
                code: "BANNER"
            }
        })
    }, []);

    // if (!checkedPermission(userInfo, accessObjectPermissions, VIEW_BANNER)) {
    //     return <AccessDenied />
    // }

    return (
        <Fragment>
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={bannerType}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {
                            _.map(dataBannerType, (item, index) => {
                                return <Tab label={_.get(item, 'name')} {...a11yProps(_.get(item, 'id'))} key={index} value={_.get(item, 'code')} />
                            })
                        }

                        {/* <Tab label="Item One" {...a11yProps(0)} />
                        <Tab label="Item Two" {...a11yProps(1)} />
                        <Tab label="Item Three" {...a11yProps(2)} />
                        <Tab label="Item Four" {...a11yProps(3)} />
                        <Tab label="Item Five" {...a11yProps(4)} />
                        <Tab label="Item Six" {...a11yProps(5)} />
                        <Tab label="Item Seven" {...a11yProps(6)} /> */}
                    </Tabs>
                </AppBar>
                {
                    _.map(dataBannerType, (item, index) => {
                        return <TabPanel key={index} value={bannerType} index={_.get(item, 'code')}>
                            <Grid container direction="column" justifyContent="center" style={{ overFlow: 'hidden' }}>
                                <Grid container direction={matchesSM ? 'column' : 'row'}>
                                    {_.map(dataBanner, (item, key) => (
                                        <CardBanner
                                            key={_.get(item, 'id')}
                                            image={configValue.MEDIA_DOMAIN + _.get(item, 'url')} title={`Banner ${key}`}
                                            bannerId={_.get(item, 'id')}
                                            banners={item}
                                            setLoading={setLoading}
                                        />
                                    ))}

                                    {openUploadCard && <CardUploadBanner setLoading={setLoading} title="Banner 4" type={bannerType} />}
                                </Grid>
                            </Grid>
                            {
                                checkedPermission(userInfo, accessObjectPermissions, CREATE_BANNER) && (
                                    <Typography onClick={() => setOpenUploadCard(!openUploadCard)} className={classes.textAddBanner}>
                                        Thêm banner mới
                                    </Typography>
                                )
                            }


                            <Grid container direction="row" style={{ marginTop: '50px' }} justifyContent={matchesSM ? 'center' : 'flex-end'}>
                                {/* <Button variant="contained" className={classes.confirmButton}>XÁC NHẬN</Button> */}
                                <ButtonReturn link={slugs.setting} />
                            </Grid>

                        </TabPanel>
                    })
                }
            </div>

        </Fragment>


    );
}