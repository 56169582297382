import React from 'react';
import * as _ from "lodash";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    validate: {
        height: '0px',
        position:'relative',
        bottom: '0px',
        fontSize: '13px',
        color: '#f00',
    },
}));

const ValidationNote = ({Obj, fieldname}) => {
    const classes = useStyles();
    
    if(!_.get(Obj,fieldname)) return null;

    return (
        <Typography component='div' className={classes.validate}>
            Thông tin bắt buộc
        </Typography>
    )
};
export default ValidationNote;
