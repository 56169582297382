import React, { useEffect, useState, Fragment, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useStyles } from '../useStyles';
import { useMutation, useQuery } from '@apollo/client';

import {
    Button, Grid, MenuItem, Typography, CircularProgress,
    Divider,
    Paper, FormControl, Select,
} from '@material-ui/core';
import * as _ from 'lodash';
import swal from 'sweetalert';
import { Save } from '@material-ui/icons';

import clsx from 'clsx';
import { useCreateCart, useUpdateCart } from '../hooks';
import { useGetProjectCarts } from '../../../../graphql/schemas/hooks';
import AutoCompleteProject from '../components/AutoCompleteProject';
import AutoCompleteCart from '../components/AutoCompleteCart';
import ButtonReturn from '../../../../components/ButtonReturn'
import InputMinutes from '../../../../components/InputMinutes';
import InputDateAndTimePicker from '../../../../components/InputDateAndTimePicker';
import { user } from '../../../../constants/user';
import ValidationNote from '../../../../components/ValidationNote';
import { GET_DETAIL_CART_FOR_EDIT } from '../../../../graphql/schemas/cart/cartQueries';
import { CREATE_CART_PRODUCT, UPDATE_SETTING_CART } from '../../../../graphql/schemas/cart/mutations';
import * as FormatMoment from 'moment';
import InputCartSearch from '../components/InputCartSearch';
import moment from 'moment';


const REQUIRED_FIELD = [
    'booking_timeline', 'assembly_date', 'assembly_rate', 'priority_max', 'assembly_timeline',
    'sale_date', 'priority_timeline', 'deposit_timeline', 'assembly_start_countdown', 'sale_start_countdown'
];


function CreateCartPrimary() {
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams();
    const selectProject = JSON.parse(localStorage.getItem('selectProject'));

    const [projectId, setProjectId] = useState();

    const [draftObject, setDraftObject] = useState({});
    const [assemblyDate, setAssemblyDate] = useState(new Date());
    const [saleDate, setSaleDate] = useState(new Date());
    const [assemblyCountdownDate, setAssemblyCountdownDate] = useState(new Date());
    const [saleCountdownDate, setSaleCountdownDate] = useState(new Date());

    const [selectedCart, setSelectedCart] = useState(_.get(selectProject, 'indexCard.id') ? _.get(selectProject, 'indexCard') : null);
    const [errorValid, setErrorValid] = useState(null)
    const [validateDatePicker, setValidateDatePicker] = useState(false)

    const { data: cartList, loading } = useGetProjectCarts({ project: projectId || id });

    const { data: dataTmpCart, loadingTmpCart } = useQuery(GET_DETAIL_CART_FOR_EDIT, {
        variables: {
            id: _.get(selectedCart, 'id')
        },
        fetchPolicy: 'network-only',
        skip: !_.get(selectedCart,'id')
    });

    const [createCart, { loading: loadingCreateCart }] = useMutation(CREATE_CART_PRODUCT);
    const [updateSettingCart] = useMutation(UPDATE_SETTING_CART);

    const handleCreateCart = (event) => {
        event.preventDefault();
        
        let pushObject = draftObject;
        _.set(pushObject, 'assembly_date', moment(assemblyDate).format());
        _.set(pushObject, 'assembly_start_countdown', moment(assemblyCountdownDate).format());
        _.set(pushObject, 'sale_date', moment(saleDate).format());
        _.set(pushObject, 'sale_start_countdown', moment(saleCountdownDate).format());

        !pushObject?.booking_timeline && _.set(pushObject, 'booking_timeline', null);
        !pushObject?.assembly_rate && _.set(pushObject, 'assembly_rate', null);
        !pushObject?.priority_max && _.set(pushObject, 'priority_max', 1);
        !pushObject?.assembly_timeline && _.set(pushObject, 'assembly_timeline', null);
        !pushObject?.priority_timeline && _.set(pushObject, 'priority_timeline', null);
        !pushObject?.deposit_timeline && _.set(pushObject, 'deposit_timeline', null);

        !pushObject?.general_max_blocked && _.set(pushObject, 'general_max_blocked', 1);
        !pushObject?.sale_max_blocked && _.set(pushObject, 'sale_max_blocked', 1);

        delete pushObject.name;

        let tmpErr = {};

        _.map(pushObject, (value, key) => {
            if ((!value || value === -1 || value === 'Invalid date') && _.includes(REQUIRED_FIELD, key)) {
                _.set(tmpErr, key, true)
            }
        })

        if (
            !_.isEmpty(tmpErr) 
            || Date.parse(assemblyDate) < Date.parse(assemblyCountdownDate) + 60000 || Date.parse(saleDate) < Date.parse(saleCountdownDate) + 60000 || Date.parse(assemblyDate) > Date.parse(saleDate)
            || (Date.parse(assemblyDate) === Date.parse(assemblyCountdownDate) && Date.parse(assemblyCountdownDate) === Date.parse(saleDate) && Date.parse(saleDate) ===Date.parse(saleCountdownDate)) 
        ) {
            setErrorValid(tmpErr);
            setValidateDatePicker((Date.parse(assemblyDate) === Date.parse(assemblyCountdownDate) && Date.parse(assemblyCountdownDate) === Date.parse(saleDate) && Date.parse(saleDate) ===Date.parse(saleCountdownDate)));
            return;
        }

        if (selectedCart) {
            updateSettingCart({
                variables: {
                    data: pushObject
                }
            }).then(res => {
                swal("Thông báo", "Cập nhât thông tin giỏ hàng thành công!", "success");
                window.history.back();
            }, (error) => {
                swal("Cập nhât thông tin giỏ hàng thất bại", "Vui lòng thử lại!", "error");
            });

        } else {

            createCart({
                variables: {
                    data: {
                        project: projectId || id,
                        booking_timeline: _.get(pushObject, 'booking_timeline'),
                        assembly_date: _.get(pushObject, 'assembly_date'),
                        assembly_rate: _.get(pushObject, 'assembly_rate'),
                        priority_max: _.get(pushObject, 'priority_max'),
                        assembly_timeline: _.get(pushObject, 'assembly_timeline'),
                        sale_date: _.get(pushObject, 'sale_date'),
                        priority_timeline: _.get(pushObject, 'priority_timeline'),
                        sale_max_blocked: _.get(pushObject, 'sale_max_blocked'),
                        sale_blocked_timeline: _.get(pushObject, 'sale_blocked_timeline'),
                        general_max_blocked: _.get(pushObject, 'general_max_blocked'),
                        general_blocked_timeline: _.get(pushObject, 'general_blocked_timeline'),
                        deposit_timeline: _.get(pushObject, 'deposit_timeline'),
                        assembly_start_countdown: _.get(pushObject, 'assembly_start_countdown'),
                        sale_start_countdown: _.get(pushObject, 'sale_start_countdown'),
                        project_items: []
                    }
                }
            }).then(res => {
                swal("Thông báo", "Tạo giỏ hàng thành công!", "success");
                window.history.back();
            }, (error) => {
                swal("Tạo giỏ hàng thất bại", "Vui lòng thử lại!", "error");
            });

        }

    };

    
    useEffect(()=> {
        if(!id) setProjectId(id);
    },[])

    useEffect(()=>{
        if(!id) setSelectedCart(null);
    },[projectId]);
   
    useEffect(()=> {
        if(dataTmpCart) {
            let tmp = {..._.get(dataTmpCart,'project_cart')};
            delete tmp.__typename;
            if(_.get(dataTmpCart, 'project_cart.assembly_date')) {
                setAssemblyDate( _.get(dataTmpCart, 'project_cart.assembly_date') )
            }
            if(_.get(dataTmpCart, 'project_cart.assembly_start_countdown')) {
                setAssemblyCountdownDate( _.get(dataTmpCart, 'project_cart.assembly_start_countdown') )
            }
            if(_.get(dataTmpCart, 'project_cart.sale_date')) {
                setSaleDate( _.get(dataTmpCart, 'project_cart.sale_date') )
            }
            if(_.get(dataTmpCart, 'project_cart.sale_start_countdown')) {
                setSaleCountdownDate( _.get(dataTmpCart, 'project_cart.sale_start_countdown') )
            }
            setDraftObject(tmp);
        } else {
            setDraftObject({});
        }

    },[dataTmpCart])


    return (
        <Paper className={classes.root}>
            <Grid container direction="row" className={classes.headerStyle}>
                <Typography gutterBottom variant="h4" component='h2'>Cài đặt thông số giỏ hàng</Typography>
            </Grid>
            <Grid container direction="row" className={classes.mgBottom}>
                {_.isEmpty(id) ? (
                    <Fragment>
                        <Grid item xs={6} md={2}>
                            <Typography className={classes.label}>Dự án</Typography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <FormControl variant="outlined" className={clsx(classes.formControl, { 'fullwidth': true })}>
                                <AutoCompleteProject onChange={setProjectId} />
                            </FormControl>
                        </Grid>
                    </Fragment>
                )
                    :
                    (
                        <Fragment>
                            <Grid item xs={6} md={2}>
                                <Typography className={classes.label}>Mã dự án</Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography>{_.get(selectProject, 'code')}</Typography>
                            </Grid>
                        </Fragment>
                    )}
            </Grid>

            <Grid container direction="row" className={classes.mgBottom}>
                {loading ? (
                    <CircularProgress />
                )
                    :
                    !_.isEmpty(cartList) && (
                        <Fragment>
                            <Grid item xs={6} md={2}>
                                <Typography className={classes.label}>Đợt giỏ hàng</Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <InputCartSearch
                                    object={selectedCart}
                                    setObject={setSelectedCart}
                                    datalist={_.filter(_.get(cartList,'result'), (Obj) => { 
                                        return !_.includes(['ECOECART', 'AFTERSALE'], _.get(Obj,'project_cart_status.code')); 
                                    })}
                                    disabled={id && _.get(selectProject, 'indexCard.id')}
                                />
                            </Grid>
                        </Fragment>
                    )}
            </Grid>

            <Divider className={classes.marginDivider} />

            <Grid
                container direction="row"
                className={clsx(classes.mgBottom, _.includes(['ASSEMBLED', 'OPENSALE', 'ECOECART'], _.get(selectedCart, 'project_cart_status.code')) && 'disabled')}
            >
                <Grid item md={12}>
                    <Typography className={classes.statusCart}>Giữ chỗ</Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Typography className={clsx(classes.label, 'required')}>Thời gian Admin xác nhận chuyển tiền</Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                    <InputMinutes
                        fieldname='booking_timeline'
                        object={draftObject}
                        setObject={setDraftObject}
                        labelUnit='Phút'
                        defaultValue="BABABABAB"
                    />
                    {
                        !_.get(draftObject, 'booking_timeline') && <ValidationNote Obj={errorValid} fieldname='booking_timeline' />
                    }
                </Grid>
            </Grid>

            <Grid
                container direction="row"
                className={clsx(classes.mgBottom, _.includes(['OPENSALE', 'ECOECART'], _.get(selectedCart, 'project_cart_status.code')) && 'disabled')}
            >
                <Grid item md={12}>
                    <Typography className={classes.statusCart}>Ráp căn</Typography>
                </Grid>

                <Grid container direction="row" className={clsx(classes.mgBottom, 'midle-line')}>
                    <Grid item xs={6} md={3}>
                        <Typography className={clsx(classes.label, 'required')}>Ngày bắt đầu</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <InputDateAndTimePicker
                            setDateTime={setAssemblyDate}
                            defaultValue={_.get(draftObject,'assembly_date')}
                            fromDate={Date.parse(assemblyCountdownDate)}
                            validateFalse={(Date.parse(assemblyDate) > Date.parse(saleDate)) || validateDatePicker}
                        />
                        {
                            !_.isDate(assemblyDate) && <ValidationNote Obj={errorValid} fieldname='assembly_date' />
                        }
                    </Grid>
                </Grid>

                <Grid container direction="row" className={classes.mgBottom}>
                    <Grid item xs={6} md={3}>
                        <Typography className={clsx(classes.label, 'required')}>Tỉ lệ booking/ SP</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <InputMinutes
                            fieldname='assembly_rate'
                            object={draftObject}
                            setObject={setDraftObject}
                            labelUnit='/1'
                        />
                        {
                            !_.get(draftObject, 'assembly_rate') && <ValidationNote Obj={errorValid} fieldname='assembly_rate' />
                        }
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <Typography className={clsx(classes.label, 'required')}>Số lượt ưu tiên tối đa</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <FormControl size="small" variant="outlined" fullWidth >
                            <Select
                                className={classes.form}
                                id="maximun-priority"
                                value={_.get(draftObject, 'priority_max',1)}
                                //defaultValue={_.get(draftObject,'priority_max')}
                                onChange={
                                    (e) => {
                                        let tmp = { ...draftObject };
                                        _.set(tmp, 'priority_max', e.target.value);
                                        setDraftObject(tmp);
                                    }
                                }
                                style={{ maxWidth: "225px" }}
                            >
                                {_.map(([1, 2, 3]), (value, key) => <MenuItem key={key} value={value}>{value}</MenuItem>)}
                            </Select>
                        </FormControl>
                        {
                            !_.get(draftObject, 'priority_max') && <ValidationNote Obj={errorValid} fieldname='priority_max' />
                        }
                    </Grid>
                </Grid>

                <Grid container direction="row" className={clsx(classes.mgBottom, 'midle-line')}>
                    <Grid item xs={6} md={3}>
                        <Typography className={clsx(classes.label, 'required')}>Thời gian bắt đầu countdown</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <InputDateAndTimePicker
                            setDateTime={setAssemblyCountdownDate}
                            defaultValue={_.get(draftObject, 'assembly_start_countdown')}
                            toDate={Date.parse(assemblyDate)}
                            validateFalse={validateDatePicker}
                        />
                        {
                            !_.isDate(assemblyCountdownDate) && <ValidationNote Obj={errorValid} fieldname='assembly_start_countdown' />
                        }
                    </Grid>
                </Grid>

                <Grid container direction="row" className={classes.mgBottom}>
                    <Grid item xs={6} md={3}>
                        <Typography className={clsx(classes.label, 'required')}>Thời gian booking theo STT</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <InputMinutes
                            fieldname='assembly_timeline'
                            object={draftObject}
                            setObject={setDraftObject}
                            labelUnit='Phút'
                        />
                        {
                            !_.get(draftObject, 'assembly_timeline') && <ValidationNote Obj={errorValid} fieldname='assembly_timeline' />
                        }
                    </Grid>
                </Grid>

            </Grid>

            <Grid
                container direction="row"
                className={clsx(classes.mgBottom, _.includes(['ECOECART'], _.get(selectedCart, 'project_cart_status.code')) && 'disabled')}
            >
                <Grid item md={12}>
                    <Typography className={classes.statusCart}>Event mở bán</Typography>
                </Grid>

                <Grid container direction="row" className={clsx(classes.mgBottom, 'midle-line')}>
                    <Grid item xs={6} md={3}>
                        <Typography className={clsx(classes.label, 'required')}>Ngày bắt đầu Event</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <InputDateAndTimePicker
                            setDateTime={setSaleDate}
                            defaultValue={draftObject?.sale_date}
                            fromDate={Date.parse(saleCountdownDate)}
                            validateFalse={(Date.parse(assemblyDate) > Date.parse(saleDate)) || validateDatePicker}
                        />
                        {
                            !_.isDate(saleDate) && <ValidationNote Obj={errorValid} fieldname='sale_date' />
                        }
                    </Grid>
                </Grid>

                <Grid container direction="row" className={classes.mgBottom}>
                    <Grid item xs={6} md={3}>
                        <Typography className={clsx(classes.label, 'required')}>Thời gian từng UT</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <InputMinutes
                            fieldname='priority_timeline'
                            object={draftObject}
                            setObject={setDraftObject}
                            labelUnit='Phút'
                        />
                        {
                            !_.get(draftObject, 'priority_timeline') && <ValidationNote Obj={errorValid} fieldname='priority_timeline' />
                        }
                    </Grid>
                </Grid>

                <Grid container direction="row" className={classes.mgBottom}>
                    <Grid item xs={6} md={3}>
                        <Typography className={classes.label}>Thời gian Khoá căn</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <InputMinutes
                            fieldname='sale_blocked_timeline'
                            object={draftObject}
                            setObject={setDraftObject}
                            labelUnit='Phút'
                        />
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <Typography className={classes.label}>Số lượng căn tối đa Sale được khoá</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <FormControl size="small" variant="outlined" fullWidth >
                            <Select
                                className={classes.form}
                                id="number-lock-event"
                                value={_.get(draftObject, 'sale_max_blocked',1)}
                                //defaultValue={_.get(draftObject, 'sale_max_blocked')}
                                onChange={
                                    (e) => {
                                        let tmp = { ...draftObject };
                                        _.set(tmp, 'sale_max_blocked', e.target.value);
                                        setDraftObject(tmp);
                                    }
                                }
                                style={{ maxWidth: "225px" }}
                            >
                                {_.map(([1, 2, 3]), (value, key) => <MenuItem key={key} value={value}>{value}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>

                <Grid container direction="row" className={clsx(classes.mgBottom, 'midle-line')}>
                    <Grid item xs={6} md={3}>
                        <Typography className={clsx(classes.label, 'required')}>Thời gian bắt đầu countdown</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <InputDateAndTimePicker
                            setDateTime={setSaleCountdownDate}
                            defaultValue={draftObject?.sale_start_countdown}
                            toDate={Date.parse(saleDate)} 
                            validateFalse={validateDatePicker}
                        />
                        {
                            !_.isDate(saleCountdownDate) && <ValidationNote Obj={errorValid} fieldname='sale_start_countdown' />
                        }
                    </Grid>
                </Grid>

            </Grid>

            <Grid container direction="row" className={classes.mgBottom}>
                <Grid item md={12}>
                    <Typography className={classes.statusCart}>Giỏ hàng chung ECOE</Typography>
                </Grid>

                <Grid container direction="row" className={classes.mgBottom}>
                    <Grid item xs={6} md={3}>
                        <Typography className={classes.label}>Thời gian khoá căn</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <InputMinutes
                            fieldname='general_blocked_timeline'
                            object={draftObject}
                            setObject={setDraftObject}
                            labelUnit='Phút'
                        />
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <Typography className={classes.label}>Số lượng căn tối đa Sale được khoá</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <FormControl size="small" variant="outlined" fullWidth >
                            <Select
                                className={classes.form}
                                id="number-lock-ecoe-cart"
                                value={_.get(draftObject, 'general_max_blocked',1)}
                                //defaultValue={_.get(draftObject, 'general_max_blocked')}
                                onChange={
                                    (e) => {
                                        let tmp = { ...draftObject };
                                        _.set(tmp, 'general_max_blocked', e.target.value);
                                        setDraftObject(tmp);
                                    }
                                }
                                style={{ maxWidth: "225px" }}
                            >
                                {_.map(([1, 2, 3]), (value, key) => <MenuItem key={key} value={value}>{value}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container direction="row" className={classes.mgBottom}>
                    <Grid item xs={6} md={3}>
                        <Typography className={clsx(classes.label, 'required')}>Thời gian Admin xác nhận chuyển tiền</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <InputMinutes
                            fieldname='deposit_timeline'
                            object={draftObject}
                            setObject={setDraftObject}
                            labelUnit='Phút'
                        />
                        {
                            !_.get(draftObject, 'deposit_timeline') && <ValidationNote Obj={errorValid} fieldname='deposit_timeline' />
                        }
                    </Grid>
                </Grid>

            </Grid>

            {/* 
            <Input
                type="file"
                inputProps={{
                    "accept": ".csv,.xlsx,.xls"
                }}
                onChange={handleFileUpload}
            />

            {!_.isEmpty(data) && <DataTable
                pagination
                highlightOnHover
                columns={columns}
                data={data}
            />} 
            */}
            <Divider className={classes.marginDivider} />
            <Grid container direction="row" className={classes.footerStyle}>
                <Button
                    variant="contained"
                    color='primary'
                    disabled={loadingCreateCart}
                    onClick={handleCreateCart}
                    startIcon={<Save />}
                >Xác nhận</Button>
                <ButtonReturn />
            </Grid>
        </Paper>
    );
}
export default CreateCartPrimary;

