import { ApolloClient, InMemoryCache, ApolloLink, split, HttpLink, from } from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client';
import * as _ from "lodash";
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import buildHasuraProvider from "ra-data-hasura";
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';

import { refreshToken } from "../../firebase/services/token";

export const ssrMode = typeof window === 'undefined';

const getAuthorization = () => {
    let access_token = localStorage.getItem('access_token');
    if (access_token) {
        return {
            'authorization': `Bearer ${access_token}`
        }
    }

    return {};
};


// 1. create websocket link
const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_GRAPHQL_WS_ENDPOINT,
    options: {
        reconnect: true,
        connectionParams: {
            headers: {
                'hasura-client-name': process.env.REACT_APP_HASURA_CLIENT_NAME,
                ...getAuthorization(),
                //...headers, /** Please don't edit the order */
            }
        },
    }
});

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_HASURA_ENPOINT
});

const authLink = setContext(async (_, { headers }) => {
    return {
        headers: {
            'hasura-client-name': process.env.REACT_APP_HASURA_CLIENT_NAME,
            ...getAuthorization(),
            ...headers, /** Please don't edit the order */
        }
    }
});


const errorLink = onError(({ networkError, graphQLErrors, operation, forward }) => {
    const { cache } = operation.getContext();
    /** Handle reset token */
    try {
        _.map(graphQLErrors, ({ message, extensions }) => {
            if (extensions.code === 'invalid-jwt') {
                refreshToken();
            }
            console.log("[Graphql error]:", message);
        });
        if (networkError) {
            console.log(`[Network error]:`, networkError);
        }
    } catch (error) {
        console.log("Error Link: ", error);
    }
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink
);


export const client = new ApolloClient({
    ssrMode,
    //link: ApolloLink.from([authLink, errorLink, createUploadLink({ uri: url, credentials: 'same-origin' })]),
    link: from([errorLink, authLink, splitLink]),
    cache: new InMemoryCache()
});


export const buildDataProvider = async () => {
    const dataProvider = await buildHasuraProvider({
        client
    });

    return (() => dataProvider);
};
