import firebase from 'firebase/app';
import 'firebase/auth'; // If you need it
import 'firebase/firestore'; // If you need it
// import 'firebase/storage' // If you need it
// import 'firebase/analytics' // If you need it
// import 'firebase/performance' // If you need it
import 'firebase/messaging';
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID, //If you need it
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const messaging = firebase.messaging();

// messaging.onMessage(function (payload) {
//     try {  //try???
//         console.log('Message received. ', payload);

//         const noteTitle = payload.notification.title;
//         const noteOptions = {
//             body: payload.notification.body,
//             icon: "typewriter.jpg", //this is my image in my public folder
//         };
//         alert(payload)
//         console.log("title ", noteTitle, " ", payload.notification.body);
//         //var notification = //examples include this, seems not needed

//         // new Notification(noteTitle, noteOptions).onclick = function (event) {
//         //     // console.log(event);
//         //     // console.log(payload.notification.click_action);
//         //     if (payload && payload.notification && payload.notification.click_action && payload.notification.click_action.length > 0) {
//         //         window.open(payload.notification.click_action, '_blank');
//         //     }
//         //     this.close();
//         // };
//     }
//     catch (err) {
//         console.log('Caught error: ', err);
//     }
// });

// messaging.usePublicVapidKey(process.env.REACT_APP_FIREBASE_VAPID);
// export { messaging };

// self.addEventListener('notificationclick', function (event) {
//     // do what you want
//     // ...
// });
export const requestFirebaseNotificationPermission = () =>
    new Promise((resolve, reject) => {
        messaging
            .requestPermission()
            .then(() => messaging.getToken())
            .then((firebaseToken) => {
                resolve(firebaseToken);
            })
            .catch((err) => {
                reject(err);
            });
    });

// export const onMessageListener = () =>
//     messaging.onMessage((payload) => {
//         console.log("Payload:", payload);
//         return payload
//     })

export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            resolve(payload);
        });
    });

export default firebase;
