export const primary_product_template = {
    "name": null,
    "account": null,
    "province": null,
    "district": null,
    "ward": null,
    "street": null,
    "address": null,
    "type_list": [],
    "price_from": null,
    "price_to": null,
    "price_from_milion": null,
    "price_to_milion": null,
    "product_image_list": [],
    "video_url": null,
    "view_3d_url": null,
    "investor": null,
    "corporation": null,
    "investor_website": null,
    "corporation_company": null,
    "develop_company": null,
    "building_contractor": null,
    "design_company": null,
    "manager_company": null,
    "monitor_company": null,
    "bank": null,
    "support_bank": null,
    "total_investment": null,
    "total_area": null,
    "density_of_building": null,
    "density_of_tree": null,
    "start_date": new Date(),
    "taking_over": new Date(),
    "total_appartments": null,
    "total_officetel": null,
    "total_shophouse": null,
    "total_villa": null,
    "total_townhouse": null,
    "total_ground": null,
    "total_block": null,
    "total_parking": null,
    "floor_from": null,
    "floor_to": null,
    "area_from": null,
    "area_to": null,
    "management_fee": null,
    "motorbike_parking_fee": null,
    "car_parking_fee": null,
    "outstanding_advantages": [],
    "utilities": [],
    "investor_document_name": null,
    "other_utilities": [],
    "investor_document": [],
    "description": null,
    "note": null,
    "longitude" : null,
    "latitude" : null,
    "project_status": null,
};
