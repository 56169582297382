const data = [
    {
        idEmployee: "E00001",
        name: "Huy Nguyen",
        role: "Key",
        phone: "090336578",
        email: "huynguyen.dt@ecoe.vn",
        startDay: "16/06/2021"
    },
    {
        idEmployee: "E00002",
        name: "Long Tran",
        role: "Thành viên",
        phone: "090336578",
        email: "longtran@ecoe.vn",
        startDay: "16/06/2021"
    },
    {
        idEmployee: "E00001",
        name: "Long Nguyen",
        role: "Thành vien",
        phone: "090336578",
        email: "longnguyen@ecoe.vn",
        startDay: "16/06/2021"
    }
];

module.exports = data;