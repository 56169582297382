import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';

import moment from 'moment';

const ItemListReview = ({ circleColor = 'blue', borderRadius = "50%", circleWidth = 15, circleHeight = 15, gridItem = false, data = undefined, setFlag }) => {
    const useStyles = makeStyles((theme) => ({
        itemContainer: {
            marginTop: 20,
            '&:hover': {
                backgroundColor: '#C4C4C4'
            },
            cursor: 'pointer'
        },
        circle: {
            width: circleWidth,
            height: circleHeight,
            backgroundColor: circleColor,
            borderRadius,
            margin: 20
        },
        customerName: {
            textDecoration: 'underline'
        }
       
    }));

    const classes = useStyles();
    return (
        <Fragment>
            {gridItem ? (
                <Grid className={classes.itemContainer} container direction="row" alignItems="center" onClick={() => setFlag((prev) => ({ ...prev, isDetail: true }))}>
                    <Grid item>
                        <div className={classes.circle}></div>
                    </Grid>
                    <Grid item>
                        <PersonIcon style={{fontSize: 40 }} />
                    </Grid>
                    <Grid item style={{marginLeft: 20}}>
                        <span className={classes.customerName}>Khách hàng Huy Nguyễn (Mã KH: C000001)</span> <span>đã đánh giá sales Huy Vũ </span> 
                    </Grid>
                    <Grid item style={{ marginLeft: 'auto' }}>
                        {moment(new Date()).format('DD/MM/YYYY hh:mm:ss A')}
                    </Grid>
                </Grid>
               
            ) : (
                <Fragment>If not using grid item</Fragment> 
            )}

        </Fragment>
    )
}

export default ItemListReview
