import { gql } from '@apollo/client';

export const CREATE_EMPLOYEE = gql`
mutation createEmployee($employee: EmployeeInput!) {
  result: createEmployee(employee: $employee) {
    id
    info: accountByAccount {
      id
      code
      full_name
      email
      phone
      personalid
      roles: account_roles {
        role: roleByRole {
          id
          code
          name
        }
      }
      avatar: medium {
        id
        name
        url
        type
      }
      active
    }
    branch: branchByBranch {
      id
      code
      name
      address
      map
      active
      deleted
    }
    manager: employee {
      info: accountByAccount {
        id
        code
        full_name
        email
        phone
      }
    }
    departments: employee_departments {
      department: departmentByDepartment {
        id
        code
        name
      }
    }
  }
}

`

export const UPDATE_EMPLOYEE = gql`
mutation updateEmployee($employee: EmployeeUpdateInput!) {
  result: updateEmployee(employee: $employee) {
    id
    faceid: medium {
        id
        name
        ext
        mime
        size
        provider
        hash
        url
    }
    info: accountByAccount {
      id
      code
      full_name
      email
      phone
      personalid
      roles: account_roles {
        role: roleByRole {
          id
          code
          name
        }
      }
      avatar: medium {
        id
        name
        url
        type
      }
      active
    }
    branch: branchByBranch {
      id
      code
      name
      address
      map
      active
      deleted
    }
    manager: employee {
      info: accountByAccount {
        id
        code
        full_name
        email
        phone
      }
    }
    departments: employee_departments {
      department: departmentByDepartment {
        id
        code
        name
      }
    }
  }
}


`

export const DELETE_SCHEDULE = gql`
mutation DeleteProductRequestEvent($id: uuid!, $updated_by: bigint!) {
  update_product_request_event_by_pk(pk_columns: {id: $id},_set: {deleted: true, updated: "now()", updated_by: $updated_by}) {
    id
  }
}
`

export const CREATE_SCHEDULE = gql`
mutation CreateProductRequestEvent($data: EventInput!) {
  createEvent(args: $data){
    id
    title
    status
    start_time
    end_time
    type: product_request_event_type {
      id
      code
      name
    }
    customer_info: account {
      id
      code
      full_name
      avatar: medium {
        id
        url
      }
    }
    employee_info: accountByEmployee {
      id
      code
      full_name
      avatar: medium {
        id
        url
      }
    }
  }
}
`

export const UPDATE_SCHEDULE = gql`
mutation UpdateProductRequestEvent($id: uuid!, $data: product_request_event_set_input!) {
  result: update_product_request_event_by_pk(pk_columns: {id: $id},_set:$data) {
    id
    title
    status
    start_time
    end_time
    type: product_request_event_type {
      id
      code
      name
    }
    customer_info: account {
      id
      code
      full_name
      avatar: medium {
        id
        url
      }
    }
    employee_info: accountByEmployee {
      id
      code
      full_name
      avatar: medium {
        id
        url
      }
    }
  }
}
`

