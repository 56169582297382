import React from "react";
import { useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

import { useUpdateEmployee } from "../../../pages/Employee/hooks";
import swal from "sweetalert";
import moment from "moment";

import _ from "lodash";

const ModalEditEmployee = ({
  openModalEditEmployee,
  setOpenModalEditEmployee,
  code,
  username,
  full_name,
  email,
  personalId,
  phone,
  gender,
  birthday,
  createdBy,
  roles,
  faceid,
  branch,
  manager,
  position,
  employee,
  departments,
  id,
  accountid,
  setOpenEdit,
  distributed,
  distributedList
}) => {

  const { updateEmployee } = useUpdateEmployee(
    setOpenModalEditEmployee,
    setOpenEdit,
    accountid
  );

  const handleClose = () => {
    setOpenModalEditEmployee(false);
  };

  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      outline: "none",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      outline: "none",
      display: "flex",
      width: "760px",
      flexDirection: "column",
      alignItems: "center",
    },
    buttonConfirm: {
      color: "#fff",
      borderRadius: 0,
      padding: "6px 36px",
      margin: "0 10px",
    },
    reasonBlock: {
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderBottomLeftRadius: 20,
      borderTopRightRadius: 0,
      marginTop: "20px",
      padding: "10px",
      height: "5em",
      width: "70%",
    },
  }));

  const submitHandleUpdate = () => {
    const variables = {
      employee: {
        account: {
          id: accountid,
          full_name,
          personalid: personalId,
          gender: 1,
          phone,
          code,
          birthday: birthday ? moment(birthday).format("YYYY-MM-DD") : null,
          employeeType: distributed === "ALL" ? null : {code: distributed},
          products: _.isEmpty(distributedList) ? null : distributedList,
          roles: [{code: roles}],
          // updated_by: createdBy,
          // updated: "now()"
        },
        id: Number(id),
        faceid: _.get(faceid, "id"),
        branch,
        manager,
        position,
        departments,
      },
    };

    if (email !== _.get(employee, "info.email")) {
      variables.employee.account.email = email;
    }
    updateEmployee({
      variables,
    });
  };

  const classes = useStyles();
  {
    /* employee: {
            account: {
                code: _.get(employee, "info.code"),
                username: _.get(employee, "info.username"),
                full_name: _.get(employee, "info.full_name"),
                email: _.get(employee, "info.email"),
                personalid: _.get(employee, "info.personalid"),
                phone: _.get(employee, "info.phone"),
                gender: _.get(employee, "info.phone"),
                birthday: "07/07/1986",
                created_by: 1,
                roles: _.get(employee, "info.roles")
            },
            faceid: _.get(employee, "faceid"),
            branch: _.get(employee, "branch.id"),
            manager: _.get(employee, "manager"),
            position: _.get(employee, "position.id"),
            departments: _.map(_.get(employee, 'departments'), 'department.id')
        } */
  }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModalEditEmployee}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1000,
      }}
    >
      <Fade in={openModalEditEmployee}>
        <div className={classes.paper}>
          <p id="transition-modal-description" style={{ fontWeight: "bolder" }}>
            Bạn có muốn thay đổi thông tin tài khoản này? Vui lòng nhập lý do:
          </p>
          <Grid
            container
            direction="row"
            justifyContent="center"
            style={{ marginBottom: "1em" }}
          >
            <Paper className={classes.reasonBlock}>
              <TextField
                className={classes.message}
                InputProps={{ disableUnderline: true }}
                multiline
                style={{ width: "100%" }}
                id="message"
                rows={4}
              />
            </Paper>
          </Grid>
          <Grid container direction="row" justifyContent="center">
            <Button
              style={{ backgroundColor: "#07807D" }}
              className={classes.buttonConfirm}
              onClick={submitHandleUpdate}
            >
              Có
            </Button>
            <Button
              style={{ backgroundColor: "black" }}
              className={classes.buttonConfirm}
              onClick={handleClose}
            >
              Không
            </Button>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalEditEmployee;
