import { useMutation } from "@apollo/client";
import {
  Button, Grid, TextField, Typography, CircularProgress,
  RadioGroup, FormControlLabel, IconButton,
} from "@material-ui/core";
import { Cancel, Edit, RemoveCircleOutline, CloudUploadTwoTone } from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState, Fragment, useContext } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import InputDateAndTimePicker from "../../../../components/InputDateAndTimePicker";
import { INSERT_YOUTUBE_MEDIA } from "../../../../graphql/schemas/event/eventMutation";
import Upload from "../../components/Upload";
import EventGifts from "../../create/EventGifts";
import EventJackSchedules from "../../create/EventJackSchedules";
import { useUpdateEvent } from "../../hooks";
import EventGiftsTable from "../EventGiftsTable";
import { CustomRadio } from '../../../../components/UI';
import axios from "axios";

import { ConfigContext } from '../../../../context/ConfigContext';
import { StoreContext } from "../../../../resources/store";
import { checkedPermission } from "../../../../helper";
import { UPDATE_PROMOTION } from "../../../../constants/defined";
import AccessDenied from "../../../Permission/AccessDenied";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: "bold",
  },
  space: {
    width: 10,
    height: 50,
  },
  marginLeftSpace: {
    marginLeft: 20,
  },
  textUnderLine: {
    textDecoration: "underline",
  },
  flex: {
    display: "flex",
  },
  widthTitle: {
    width: 280,
  },
  wapperLoading: {
    width: '100%',
    height: 'calc(100vh - 113px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperTable: {
    '& > div:first-child': {
      background: '#eee'
    },
    '& > div:not(:last-child)': {
      marginBottom: '15px'
    },
    '& > div:not(:first-child)': {
      borderBottom: '1px #eee solid',
      borderLeft: '1px #eee solid',
      borderRight: '1px #eee solid',
    }
  },
  textLabelForm: {
    fontWeight: "bold",
  },
}));

const ShakeGameDetail = ({ data, createdBy, loading }) => {
  const classes = useStyles();
  const { id } = useParams();

  const [isEdit, setIsEdit] = useState(false);

  const [name, setName] = useState("");
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const [eventGifts, setEventGifts] = useState([]);

  const [ruleGameUpload, setRuleGameUpload] = useState([]);
  const [ruleGameUpdate, setRuleGameUpdate] = useState([]);

  const [guideGameUpload, setGuideGameUpload] = useState([]);
  const [guideGameUpdate, setGuideGameUpdate] = useState([]);

  const [videoUpload, setVideoUpload] = useState([]);
  const [videoUpdate, setVideoUpdate] = useState([]);
  const [typeVideoUpload, setTypeVideoUpload] = useState('LINK');
  const [errorUploadVideo, setErrorUploadVideo] = useState("");
  const [loadingVideoUpload, setLoadingVideoUpload] = useState(false);
  const [dataEventShakeSchedules, setDataEventShakeSchedules] = useState([]);

  const { updateEvent, data: updatedData } = useUpdateEvent(id, setIsEdit);

  const configValue = useContext(ConfigContext);
  const { userInfo, accessObjectPermissions } = useContext(StoreContext);

  const listVideoView = (videoUpload, videoUpdate) => {
    let arr = [];
    arr = _.unionBy(_.concat(videoUpload, videoUpdate), 'id');
    arr = _.filter(arr, item => (
      (_.includes(_.map(videoUpload, o => _.get(o, 'id')), _.get(item, 'id')) && !_.includes(_.map(videoUpdate, o => _.get(o, 'id')), _.get(item, 'id')))
      ||
      (!_.includes(_.map(videoUpload, o => _.get(o, 'id')), _.get(item, 'id')) && _.includes(_.map(videoUpdate, o => _.get(o, 'id')), _.get(item, 'id')))
    ))
    return arr;
  };

  const handleRemoveField = (index, item) => {
    let tmp = [...videoUpdate];

    if (_.find(videoUpload, { 'id': _.get(item, 'id') })) {
      tmp = [...videoUpdate, item];
    } else {
      tmp = _.remove(videoUpdate, o => o?.id !== item?.id);
    }
    setVideoUpdate(tmp);
  };

  const [insertLinkYoutubeMedia, { data: dataInsertMedia, loading: loadingInsertMedia, error: errorInsertMedia }] = useMutation(INSERT_YOUTUBE_MEDIA, {
    onCompleted: (data) => {
      setVideoUpdate(_.concat(videoUpdate, [_.get(data, 'media')]))
    },
    onError: (err) => {
      return swal({
        title: "Kiểm tra lại link video",
        icon: "error",
      })
    },
  });

  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];
    if (file?.size > 5e6) {
      setErrorUploadVideo("Dung lượng file tối đa cho phép là 20MB");
      return false;
    }
    if (_.size(listVideoView(videoUpload, videoUpdate)) === 5) {
      setErrorUploadVideo("Chỉ cho phép tối đa 5 file");
      return false;
    }
    setLoadingVideoUpload(true);
    setErrorUploadVideo("");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("media_type_code", "VIDEO");
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        configValue.API_UPLOAD_FILE,
        formData,
        config
      );
      setVideoUpdate([...videoUpdate, data[0]]);
      setLoadingVideoUpload(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = () => {
    let arg = {};
    arg.id = parseInt(id);
    arg.name = name;
    if (dateStart) {
      arg.start = dateStart;
    }
    if (dateEnd) {
      arg.end = dateEnd;
    }

    const media = [...ruleGameUpdate, ...videoUpdate, ...guideGameUpdate];
    const updatedMediaId = media.map((item) => item.id);
    const uniq = [...new Set(updatedMediaId)];
    const eventMedia = uniq.map((item) => ({ media: item }));
    const schedules = _.filter(dataEventShakeSchedules, (obj) => {
      return _.get(obj, 'start') && _.get(obj, 'end')
    });

    arg.event_medias_update = eventMedia;
    arg.event_gifts_update = eventGifts.map((item) => {
      if (item.id)
        return {
          id: item.id,
          name: item.name,
          title: item.title,
          description: item.description,
          start: moment(item.start).utc().format("YYYY-MM-DD"),
          end: moment(item.end).utc().format("YYYY-MM-DD"),
          redeem_ecoin: item.redeem_ecoin,
          max_redeem: item.max_redeem,
          expire_day: item.expire_day,
          image: item.image.id,
        };
      else
        return {
          name: item.name,
          title: item.title,
          description: item.description,
          start: moment(item.start).utc().format("YYYY-MM-DD"),
          end: moment(item.end).utc().format("YYYY-MM-DD"),
          redeem_ecoin: item.redeem_ecoin,
          max_redeem: item.max_redeem,
          expire_day: item.expire_day,
          image: item.image.id,
          type: item.type,
        };
    });
    arg.event_items_update = [];
    arg.event_jack_schedules = _.map(schedules, (item) => item)

    updateEvent({
      variables: {
        arg,
      },
    });
    setIsEdit(false);
  };

  useEffect(() => {
    dataInital();
  }, [data, isEdit]);

  const dataInital = () => {
    let eventGiftsObj = _.map(data.event_gifts, (item) => item.gifts);
    let rule = _.map(_.get(data, "event_medias"), (item) => item.media).filter(
      (item) => item.media_type.code === "RULE_GAME"
    );
    let guide = _.map(_.get(data, "event_medias"), (item) => item.media).filter(
      (item) => item.media_type.code === "GUIDE_GAME"
    );
    let video = _.map(_.get(data, "event_medias"), (item) => item.media).filter(
      (item) => _.includes(["YOUTUBE", "VIDEO"], _.get(item, "media_type.code"))
    );
    let eventJackSchedules = _.map(_.get(data, 'event_jack_schedules'), (item) => {
      let tmp = JSON.parse(JSON.stringify(item));
      delete tmp?.__typename;
      return tmp
    });

    setRuleGameUpload(rule);
    setGuideGameUpload(guide);
    setVideoUpload(video);
    setEventGifts(eventGiftsObj);
    setDataEventShakeSchedules(eventJackSchedules);
  };

  if (loading) return (
    <div className={classes.wapperLoading}>
      <CircularProgress />
    </div>
  )

  if (isEdit && !checkedPermission(userInfo, accessObjectPermissions, UPDATE_PROMOTION)) {
    return <AccessDenied />
  }

  return (
    <Grid container direction="column" style={{ marginTop: 40 }}>
      <Grid container direction="row">
        <Grid item xs={4}>
          <Grid className={classes.flex}>
            <Typography className={clsx(classes.bold, classes.widthTitle)}>
              Tên chương trình:
            </Typography>
            {isEdit ? (
              <TextField
                // disabled={true}
                style={{ backgroundColor: "#ffffff", width: 250 }}
                variant="outlined"
                value={name}
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
                size="small"
                required
              />
            ) : (
              <Typography className={clsx(classes.marginLeftSpace)}>
                {_.get(data, "name")}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid className={classes.flex}>
            <Typography
              className={clsx(
                classes.bold,
                classes.widthTitle,
                classes.marginLeftSpace
              )}
            >
              Mã chương trình:{" "}
            </Typography>
            <Typography className={clsx(classes.marginLeftSpace)}>
              {_.get(data, "code")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid className={classes.flex}>
            <Typography className={clsx(classes.bold, classes.widthTitle)}>
              Loại chương trình:{" "}
            </Typography>
            <Typography className={clsx(classes.marginLeftSpace)}>
              {_.get(data, "type_info.name")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.space}></Grid>
      <Grid container direction="row">
        <Grid item xs={4}>
          <Grid className={classes.flex}>
            <Typography className={clsx(classes.bold, classes.widthTitle)}>
              Tổng số coin được tạo:
            </Typography>
            <Typography className={clsx(classes.bold, classes.marginLeftSpace)}>
              {_.get(data, "total_ecoin.aggregate.sum.ecoin") +
                _.get(data, "total_ecoin.aggregate.sum.room_ecoin")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid className={classes.flex}>
            <Typography
              className={clsx(
                classes.bold,
                classes.marginLeftSpace,
                classes.widthTitle
              )}
            >
              Số phòng game đã tạo:{" "}
            </Typography>
            <Typography className={clsx(classes.bold, classes.marginLeftSpace)}>
              {_.get(data, "total_room.aggregate.count")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid className={classes.flex}>
            <Typography
              className={clsx(
                classes.bold,
                classes.marginLeftSpace,
                classes.widthTitle
              )}
            >
              Trạng thái
            </Typography>
            <Typography className={clsx(classes.bold, classes.marginLeftSpace)}>
              {moment().isBefore(moment(_.get(data, "start")).format())
                ? "Sắp diễn ra"
                : moment().isAfter(moment(_.get(data, "end")).format())
                  ? "Kết thúc"
                  : "Đang diễn ra"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.space}></Grid>
      <Grid container direction="row">
        <Grid item xs={4}>
          <Grid className={classes.flex}>
            <Typography className={clsx(classes.bold, classes.widthTitle)}>
              Số lượng giải thưởng đã trao:{" "}
            </Typography>
            <Typography className={clsx(classes.bold, classes.marginLeftSpace)}>
              {_.get(data, "total_change_gift.aggregate.count")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid className={classes.flex}>
            <Typography
              className={clsx(
                classes.bold,
                classes.marginLeftSpace,
                classes.widthTitle
              )}
            >
              Số lượng phòng game đang chơi:{" "}
            </Typography>
            <Typography className={clsx(classes.bold, classes.marginLeftSpace)}>
              {_.get(data, "rooms_running.aggregate.count")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.space}></Grid>
      <Grid container direction="row">
        <Grid item xs={4}>
          <Grid className={classes.flex}>
            <Typography className={clsx(classes.bold, classes.widthTitle)}>
              Ngày tạo:{" "}
            </Typography>
            <Typography className={clsx(classes.marginLeftSpace)}>
              {moment(_.get(data, "created")).format("DD/MM/yyyy")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid className={classes.flex}>
            <Typography
              className={clsx(
                classes.bold,
                classes.marginLeftSpace,
                classes.widthTitle
              )}
            >
              Người tạo:{" "}
            </Typography>
            <Link
              to={`/employee/detail/${_.get(createdBy, "id")}`}
              className={clsx(classes.marginLeftSpace, classes.textUnderLine)}
            >
              {_.get(createdBy, "full_name")}
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.space}></Grid>

      <Grid container direction="row" alignItems="center">
        <Grid item xs={6}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Typography className={clsx(classes.bold, classes.widthTitle)} style={{ paddingRight: '16px' }}>Thời gian bắt đầu:</Typography>
            </Grid>
            {
              (isEdit && moment(_.get(data, "start")).unix() > moment(new Date()).unix()) ? (
                <InputDateAndTimePicker setDateTime={setDateStart} defaultValue={_.get(data, "start")} />
              )
                :
                (
                  <Grid item>
                    <Typography
                      className={clsx(classes.marginLeftSpace)}
                      component="span"
                    >
                      {moment(_.get(data, "start")).format("DD/MM/yyyy HH:mm:ss A")}
                    </Typography>
                  </Grid>
                )
            }
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Typography className={clsx(classes.bold, classes.marginLeftSpace, classes.widthTitle)} style={{ paddingRight: '16px' }}>Thời gian kết thúc:</Typography>
            </Grid>
            {
              (isEdit && moment(_.get(data, "end")).unix() > moment(new Date()).unix()) ? (
                <InputDateAndTimePicker setDateTime={setDateEnd} defaultValue={_.get(data, "end")} />
              )
                :
                (
                  <Grid item>
                    <Typography
                      className={clsx(classes.marginLeftSpace)}
                      component="span"
                    >
                      {moment(_.get(data, "end")).format("DD/MM/yyyy HH:mm:ss A")}
                    </Typography>
                  </Grid>
                )
            }
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.space}></Grid>
      {/* Game rule */}
      <Grid container spacing={4}>
        <Grid item xs={2}>
          <Typography className={clsx(classes.bold, classes.widthTitle)}>
            Thể lệ
          </Typography>
        </Grid>
        {isEdit ? (
          <Upload
            type="RULE_GAME"
            uploadData={ruleGameUpload}
            setUploadData={setRuleGameUpload}
            limit={10}
            id={`upload-rule${id}`}
            fileChange={ruleGameUpdate}
            setFileChange={setRuleGameUpdate}
          />
        ) : (
          <Grid item container className={clsx(classes.marginLeftSpace)}>
            {_.map(_.get(data, "event_medias"), (item, key) => {
              if (item.media?.media_type.code === "RULE_GAME")
                return (
                  <Grid item xs={2} key={key}>
                    <a
                      href={
                        configValue.MEDIA_DOMAIN +
                        _.get(item, "media.url")
                      }
                      key={key}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {_.get(item, "media.name")}
                    </a>
                    <Grid className={classes.space}></Grid>
                  </Grid>
                );
            })}
          </Grid>
        )}
      </Grid>
      <Grid className={classes.space}></Grid>

      {/* Game guide */}
      <Grid container spacing={4}>
        <Grid item xs={2}>
          <Typography className={clsx(classes.bold, classes.widthTitle)}>
            Hướng dẫn
          </Typography>
        </Grid>
        {isEdit ? (
          <Upload
            type="GUIDE_GAME"
            uploadData={guideGameUpload}
            setUploadData={setGuideGameUpload}
            limit={10}
            id={`upload-guide${id}`}
            fileChange={guideGameUpdate}
            setFileChange={setGuideGameUpdate}
          />
        ) : (
          <Grid item xs={9} container className={clsx(classes.marginLeftSpace)}>
            {_.map(_.get(data, "event_medias"), (item, key) => {
              if (item.media?.media_type.code === "GUIDE_GAME")
                return (
                  <Grid item xs={2} key={key}>
                    <a
                      href={
                        configValue.MEDIA_DOMAIN +
                        _.get(item, "media.url")
                      }
                      key={key}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {_.get(item, "media.name")}
                    </a>
                    <Grid className={classes.space}></Grid>
                  </Grid>
                );
            })}
          </Grid>
        )}
      </Grid>

      <Grid className={classes.space}></Grid>

      <Grid container direction="row">
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item xs={2}>
              <Typography className={clsx(classes.bold, classes.widthTitle)}>Video:</Typography>
              {
                isEdit && <span style={{ color: 'grey' }}>Tối đa 5 file</span>
              }
            </Grid>

            <Grid item xs={10} style={{ paddingTop: 15 }}>
              {
                !isEdit ? (
                  <Grid container direction="row" spacing={3}>
                    {
                      _.map(_.get(data, "event_medias"), (item, key) => {
                        if (_.includes(['VIDEO', 'YOUTUBE'], _.get(item, 'media.media_type.code')))
                          return (
                            <Grid item xs={4} key={key}>
                              <ReactPlayer
                                url={configValue.MEDIA_DOMAIN + _.get(item, "media.url")}
                                width="100%"
                                controls={true}
                              />
                              <Grid className={classes.space}></Grid>
                            </Grid>
                          );
                      })
                    }
                  </Grid>
                )
                  :
                  (
                    <Grid direction="column" container>
                      <Grid item>
                        <RadioGroup
                          style={{ flexDirection: 'row' }}
                          aria-label="game-video"
                          name="game-video-option"
                          value={typeVideoUpload}
                          onChange={(e) => setTypeVideoUpload(e.target.value)}
                        >
                          <FormControlLabel value="LINK" control={<CustomRadio />} label="Link youtube" />
                          <FormControlLabel value="UPLOAD" control={<CustomRadio />} label="Upload video" />
                        </RadioGroup>
                      </Grid>
                      {
                        typeVideoUpload === "LINK" ? (
                          <Grid item>
                            {
                              _.map(listVideoView(videoUpload, videoUpdate), (item, key) => {
                                return (
                                  <Grid key={key} direction="row" container spacing={3}>
                                    <Grid item className={classes.textLabelForm} xs={1}>
                                      <p>{`Link ${key + 1}`}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        id={`add-link-video-${key}`}
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={_.get(item, 'provider') ? configValue.MEDIA_DOMAIN + _.get(item, "url") : _.get(item, 'url')}
                                      />
                                    </Grid>
                                    <Grid item xs={1}>
                                      <IconButton
                                        onClick={() => handleRemoveField(key, item)}
                                      >
                                        <RemoveCircleOutline style={{ fill: "red" }} />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              })
                            }

                            {
                              _.size(listVideoView(videoUpload, videoUpdate)) < 5 && (
                                loadingInsertMedia ? (
                                  <CircularProgress />
                                )
                                  :
                                  (
                                    <Grid direction="row" container spacing={3}>
                                      <Grid item className={classes.textLabelForm} xs={1}>
                                        <p>{`Link ${_.size(listVideoView(videoUpload, videoUpdate)) + 1}`}</p>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <TextField
                                          id="add-link-video"
                                          placeholder="link youtube"
                                          variant="outlined"
                                          fullWidth
                                          value={null}
                                          onBlur={
                                            (e) => {
                                              if (_.get(e, 'target.value')) {
                                                insertLinkYoutubeMedia({
                                                  variables: {
                                                    url: _.get(e, 'target.value')
                                                  }
                                                })
                                              }
                                            }
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  )
                              )
                            }
                          </Grid>
                        )
                          :
                          (
                            <Grid item xs={12}>
                              <Grid container direction="row" spacing={3}>
                                {_.map(listVideoView(videoUpload, videoUpdate), (item, key) => (
                                  <Grid item xs={4} key={key} style={{ position: 'relative' }}>
                                    <ReactPlayer
                                      url={configValue.MEDIA_DOMAIN + _.get(item, "url")}
                                      width="360"
                                      controls={true}
                                    />

                                    <IconButton
                                      onClick={() => handleRemoveField(key, item)}
                                      style={{ position: 'absolute', top: 10, right: 10 }}
                                    >
                                      <RemoveCircleOutline style={{ fill: "red" }} />
                                    </IconButton>
                                  </Grid>
                                ))}

                                {
                                  _.size(listVideoView(videoUpload, videoUpdate)) < 5 && (
                                    <Grid item xs={4}>
                                      <div>
                                        <input
                                          hidden
                                          accept="video/mp4,video/x-m4v,video/*"
                                          id="icon-video-file"
                                          type="file"
                                          onChange={handleVideoUpload}
                                          disabled={loadingVideoUpload}
                                        />
                                        <label htmlFor="icon-video-file">
                                          <IconButton aria-label="icon-video-file" component="span">
                                            {loadingVideoUpload ? (
                                              <CircularProgress />
                                            ) : (
                                              <CloudUploadTwoTone
                                                style={{ height: "auto", width: "100px" }}
                                              />
                                            )}
                                          </IconButton>
                                          <p style={{ color: "red", fontStyle: "italic" }}>
                                            {errorUploadVideo}
                                          </p>
                                        </label>
                                      </div>
                                    </Grid>
                                  )
                                }
                              </Grid>
                            </Grid>
                          )
                      }
                    </Grid>
                  )
              }
            </Grid>

          </Grid>
        </Grid>
      </Grid>





      <Grid className={classes.space}></Grid>

      {
        !isEdit ? (
          !_.isEmpty(dataEventShakeSchedules) && (
            <Fragment>
              <Grid container direction="column">
                <Grid container direction="row" alignItems="center" style={{ paddingBottom: '30px' }}>
                  <Typography className={clsx(classes.bold)}>Đợt quay</Typography>
                </Grid>

                <Grid container direction="row" xs={8} className={clsx(classes.textLabelForm, classes.wrapperTable)} alignItems="center">
                  <Grid container item alignItems="center" spacing={3}>
                    <Grid item className={classes.textLabelForm} md={4}>Đợt</Grid>
                    <Grid item className={classes.textLabelForm} md={4}>Thời gian bắt đầu</Grid>
                    <Grid item className={classes.textLabelForm} md={4}>Thời gian kết thúc</Grid>
                  </Grid>

                  {
                    _.map(dataEventShakeSchedules, (item, key) => {
                      return (
                        <Grid key={key} item container alignItems="center" spacing={3}>
                          <Grid item className={classes.textLabelForm} md={4}>{`Đợt ${key + 1}`}</Grid>

                          <Grid item className={classes.textLabelForm} md={4}>
                            <Typography className={clsx(classes.bold)}>
                              {moment(_.get(item, "start")).format("DD/MM/yyyy HH:mm A")}
                            </Typography>
                          </Grid>

                          <Grid item className={classes.textLabelForm} md={4}>
                            <Typography className={clsx(classes.bold)}>
                              {moment(_.get(item, "end")).format("DD/MM/yyyy HH:mm A")}
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Grid>
              <Grid className={classes.space}></Grid>
            </Fragment>
          )
        )
          :
          (
            <EventJackSchedules
              eventJackSchedules={dataEventShakeSchedules}
              setEventJackSchedules={setDataEventShakeSchedules}
              isEdit={true}
            />
          )
      }

      <Grid container direction="column">
        {isEdit ? (
          <Grid item>
            <EventGifts
              classes={classes}
              // eventGifts={_.map(data.event_gifts, (item) => item.gifts)}
              eventGifts={eventGifts}
              setEventGifts={setEventGifts}
            />
          </Grid>
        ) : (
          <Fragment>
            <Grid item xs={2} style={{ paddingBottom: '30px' }}>
              <Typography className={clsx(classes.bold)}>Giải thưởng</Typography>
            </Grid>
            <Grid item>
              <EventGiftsTable data={data} align="center" />
            </Grid>
          </Fragment>

        )}

      </Grid>
      <Grid container item justifyContent="flex-end" spacing={4}>
        {isEdit ? (
          <>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Check />}
                onClick={handleUpdate}
              >
                Xác nhận
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="default"
                startIcon={<Cancel />}
                onClick={() => {
                  setIsEdit(false);
                  dataInital();
                }}
              >
                Hủy bỏ
              </Button>
            </Grid>
          </>
        ) : (
          <Button
            color="primary"
            startIcon={<Edit />}
            variant="contained"
            disabled={!checkedPermission(userInfo, accessObjectPermissions, UPDATE_PROMOTION)}
            onClick={() => {
              setIsEdit(true);
              setName(_.get(data, "name"));
            }}
          >
            Cập nhật
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default ShakeGameDetail;
