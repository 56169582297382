import gql from "graphql-tag";

export const UPDATE_CART_STATUS = gql`
    mutation UpdateCartStatus($id: Int!,$status:Int!,$priority:Int) {
        updateCartStatus(id: $id,status:$status,priority:$priority) {
            id
            project
            status
        }
    }
`;

export const CREATE_CART_PRODUCT = gql`
    mutation CreateProjectCart($data: InsertCartInput!) {
        createCart(data: $data) {
            id
            project
            status
        }
    }
`;

export const UPDATE_CART_PRODUCT = gql`
    mutation UpdateProjectCart($data: UpdateCartInput!) {
        updateCart(data: $data) {
            id
            project
            status
        }
    }
`;

export const UPDATE_SETTING_CART = gql`
    mutation UpdateCartSetting($data: UpdateCartSettingInput!) {
        updateCartSetting(data: $data) {
            id
            name
        }
    }
`;
