import * as _ from "lodash";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_PROVINCES = gql`
  query GetProvinces($skip: Int!, $take: Int!) {
    results: province(offset: $skip, limit: $take) {
      id
      name
    }
  }
`;

const GET_DISTRICTS = gql`
  query GetDistricts($provinceId: Int!, $skip: Int!, $take: Int!) {
    results: district(
      offset: $skip
      limit: $take
      where: { province: { _eq: $provinceId } }
    ) {
      id
      name
      coordinates
    }
  }
`;

const GET_DISTRICTS_MULTIPLE = gql`
  query GetDistricts($provinceId: [Int!], $skip: Int!, $take: Int!) {
    results: district(
      offset: $skip
      limit: $take
      where: { province: { _in: $provinceId } }
    ) {
      id
      name
    }
  }
`;

export const GET_ALL_DISTRICTS = gql`
query GetDistricts($provinceId:Int!,$skip: Int!) {
  results: district(offset: $skip, where:{province:{_eq:$provinceId}}) {
    id
    name
  }
}
 
`

const GET_WARDS = gql`
  query GetWards($districtId: Int!, $skip: Int!, $take: Int!) {
    results: ward(
      offset: $skip
      limit: $take
      where: { district: { _eq: $districtId } }
    ) {
      id
      name
      coordinates
    }
  }
`;

const GET_WARDS_MULTIPLE = gql`
  query GetWards($districtId: [Int!], $skip: Int!, $take: Int!) {
    results: ward(
      offset: $skip
      limit: $take
      where: { district: { _in: $districtId } }
    ) {
      id
      name
    }
  }
`;

const GET_STREETS = gql`
  query GetStreets($districtId: Int!, $skip: Int!, $take: Int!) {
    results: street(
      offset: $skip
      limit: $take
      where: { district: { _eq: $districtId } }
    ) {
      id
      name
    }
  }
`;

export const GET_PROJECT_STATUS = gql`
  query GetProjectStatuses {
    results: project_status(where: { deleted: { _eq: false } }) {
      id
      code
      name
    }
  }
`;

export const useProvinces = () => {
  const { data } = useQuery(GET_PROVINCES, {
    variables: {
      skip: 0,
      take: 1e9,
    },
  });
  return _.get(data, "results");
};

export const useDistricts = ({ provinceId }) => {
  const { data } = useQuery(GET_DISTRICTS, {
    variables: {
      provinceId,
      skip: 0,
      take: 1e9,
    },
    skip: !provinceId,
  });
  return _.get(data, "results");
};

export const useWards = ({ districtId }) => {
  const { data } = useQuery(GET_WARDS, {
    variables: {
      districtId,
      skip: 0,
      take: 1e9,
    },
    skip: !districtId,
  });
  return _.get(data, "results");
};

export const useStreets = ({ districtId }) => {
  const { data } = useQuery(GET_STREETS, {
    variables: {
      districtId,
      skip: 0,
      take: 1e9,
    },
    skip: !districtId,
  });
  return _.get(data, "results");
};

export const useProjectStatus = () => {
  const { data } = useQuery(GET_PROJECT_STATUS);
  return _.get(data, "results");
};

export const useGetDistrictMultiple = ({ provinceIds }) => {
  const { data } = useQuery(GET_DISTRICTS_MULTIPLE, {
    variables: {
      provinceId: provinceIds,
      skip: 0,
      take: 100,
    },
    skip: !provinceIds,
  });
  return _.get(data, "results");
};

export const useWardsMultiple = ({ districtIds }) => {
  const { data } = useQuery(GET_WARDS_MULTIPLE, {
    variables: {
      districtId: districtIds,
      skip: 0,
      take: 1e9,
    },
    skip: !districtIds,
  });
  return _.get(data, "results");
};
