import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import {
  MenuItem,
  FormControl,
  Select,
  Typography,
} from "@material-ui/core";
import { useDistricts, useWards } from "../../../graphql/schemas/masterdata/address";

const SelectComponentNew = ({
  title,
  handleChange,
  value,
  defaultTitle,
  defaultValue,
  listData = [],
  fieldValue,
  fieldTitle,
  width = 120,
  multiple = false,
  widthTitle = undefined,
  direction = "row",
  alignItems = undefined,
  justifyContent = undefined,
  disabledDefault = false,
  margin = undefined,
  required = false,
  renderValue = false,
  onClose = undefined,
  province = undefined,
  district = undefined,
  itemIndex = undefined,
}) => {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      width: width,
      backgroundColor: "#fff",
    },
  }));

  const districtList = useDistricts({provinceId: province});
  const wardList = useWards({districtId: district});
  const [list, setList] = useState(listData);
  
  useEffect(()=>{
    if (province) {
      const dataFilter = _.filter(districtList, (Obj) => Obj.coordinates);
      setList(dataFilter);
    };
  },[province, districtList]);

  useEffect(()=>{
    if (district) {
      const dataFilter = _.filter(wardList, (Obj) => Obj.coordinates);
      setList(dataFilter);
    };
  },[district, wardList]);

  const handleOnClose = () => {
    if(district) onClose(wardList, itemIndex);
  }
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: direction,
        alignItems,
        justifyContent,
        margin: margin,
      }}
    >
      <Typography style={{ width: widthTitle, fontWeight: "bold" }}>
        {title} {required && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <FormControl
        size="small"
        variant="outlined"
        className={classes.formControl}
      >
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={value ? value : defaultValue}
          displayEmpty
          onChange={handleChange}
          multiple={multiple}
          onClose={handleOnClose}
          renderValue={
            renderValue
              ? (selected) => {
                  if (_.isEmpty(selected)) {
                    return defaultTitle;
                  }
                  return _.join(selected, ", ");
                }
              : undefined
          }
        >
          <MenuItem disabled={disabledDefault} value={defaultValue}>
            {defaultTitle}
          </MenuItem>
          {_.map(_.isEmpty(listData) ? list : listData, (item, key) => {
            return (
              <MenuItem key={_.get(item, "id")} value={_.get(item, fieldValue)}>
                {_.get(item, fieldTitle)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectComponentNew;
