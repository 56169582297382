import React, { useEffect, useContext } from "react";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {
  Home as HomeIcon,
  Apartment as ApartmentIcon,
  Person as PersonIcon,
  BusinessCenter as BusinessCenterIcon,
  Settings as SettingsIcon,
  ExitToApp as ExitToAppIcon,
  Redeem,
  Business as BusinessIcon,
} from "@material-ui/icons";
import swal from "sweetalert";
import { user } from "../../constants/user";
import { useMutation } from "@apollo/client";
import { DELETE_DEVICE_BY_ACCOUNT } from "../../graphql/schemas/notification/notifyMutation";
import { StoreContext } from "../../resources/store";
import _ from "lodash";
import { ALWAY_ALLOW_ACCESS, USER_ROLE_SUPPER_ADMIN } from '../../constants/defined';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#fff",
  },
  border: {
    borderBottom: "1px solid rgba(255, 255, 255, 0.05)",
    padding: "15px 30px",
    "& .MuiListItemText-root": {
      "& > .MuiTypography-body1": {
        color: "#ffffff",
      },
    },
  },
  backgroundColor: "#32373d",
}));

export const listStorage = ["activeMenuProduct", "activeMenuDetailEmployee", "typeListEmployee", "isActiveReviewMenu", "kindCartShow", "product_type", "headMenuBranchKey"];

const DrawerV2 = ({ setAuth }) => {
  const history = useHistory();
  const location = useLocation();

  const [deleteDevice] = useMutation(DELETE_DEVICE_BY_ACCOUNT);
  const { userInfo, setToken, accessPermissions, permissionList } = useContext(StoreContext);
  const classes = useStyles();

  const itemsList = [
    {
      code: "DASHBOARD",
      text: "Home",
      icon: <HomeIcon className={classes.icon} />,
      onClick: () => {
        _.forEach(listStorage, item => localStorage.removeItem(item));
        history.push("/");
      },
      style:
        matchPath(location.pathname, { path: "/dashboard" || "/" }) ||
          matchPath(location.pathname, { path: "/", exact: true })
          ? { backgroundColor: "#1068BF" }
          : undefined,
    },
    {
      code: "PRODUCT",
      text: "Quản Lý Sản phẩm",
      icon: <ApartmentIcon className={classes.icon} />,
      onClick: () => {
        _.forEach(listStorage, item => localStorage.removeItem(item));
        history.push("/product");
      },
      style: matchPath(location.pathname, { path: "/product" })
        ? { backgroundColor: "#1068BF" }
        : undefined,
    },
    {
      code: "CUSTOMER",
      text: "Quản Lý Khách hàng",
      icon: <BusinessCenterIcon className={classes.icon} />,
      onClick: () => {
        _.forEach(listStorage, item => localStorage.removeItem(item));
        history.push("/customer");
      },
      style:
        matchPath(location.pathname, { path: "/customer" }) ||
          matchPath(location.pathname, { path: "/customer/detail/:id" })
          ? { backgroundColor: "#1068BF" }
          : undefined,
    },
    {
      code: "EMPLOYEE",
      text: "Quản Lý Nhân viên",
      icon: <PersonIcon className={classes.icon} />,
      onClick: () => {
        _.forEach(listStorage, item => localStorage.removeItem(item));
        history.push("/employee");
      },
      style:
        matchPath(location.pathname, { path: "/employee" }) ||
          matchPath(location.pathname, { path: "/employee/detail/:id" })
          ? { backgroundColor: "#1068BF" }
          : undefined,
    },
    {
      code: "BRANCH",
      text: "Quản Lý Văn phòng",
      icon: <BusinessIcon className={classes.icon} />,
      onClick: () => {
        _.forEach(listStorage, item => localStorage.removeItem(item));
        history.push("/branches");
      },
      style:
        matchPath(location.pathname, { path: "/branches" }) ||
          matchPath(location.pathname, { path: "/branches/detail/:id" })
          ? { backgroundColor: "#1068BF" }
          : undefined,
    },
    {
      code: "OTHER",
      text: "Quản Lý Khác",
      icon: <SettingsIcon className={classes.icon} />,
      onClick: () => {
        _.forEach(listStorage, item => localStorage.removeItem(item));
        history.push("/setting");
      },
      style: matchPath(location.pathname, { path: "/setting" })
        ? { backgroundColor: "#1068BF" }
        : undefined,
    },
    {
      code: "PROMOTION",
      text: "Chương trình khuyến mãi",
      icon: <SettingsIcon className={classes.icon} />,
      icon: <Redeem className={classes.icon} />,
      onClick: () => {
        _.forEach(listStorage, item => localStorage.removeItem(item));
        history.push("/promotion");
      },
      style: matchPath(location.pathname, { path: "/promotion" })
        ? { backgroundColor: "#1068BF" }
        : undefined,
    },
    {
      text: "Đăng xuất",
      code: "LOGOUT",
      icon: <ExitToAppIcon className={classes.icon} />,
      onClick: () => {
        _.forEach(listStorage, item => localStorage.removeItem(item));
        swal({
          title: "Bạn có chắc muốn đăng xuất?",
          icon: "warning",
          buttons: ["Hủy", "Đăng xuất"],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            //Delete device by account
            let token = localStorage.getItem("notify_token");
            if (token) {
              deleteDevice({
                variables: {
                  device_token: token,
                },
              });
            }
            setAuth(false);
            user.reset();
            setToken("");
            history.push("/login");
          } else {
          }
        });
      },
    },
  ];

  let directoryAccess = [];
  _.forEach(permissionList, parent => {
    for (var i = 0; i <= _.size(accessPermissions); i++) {
      let check = _.find(parent?.childs, ['id', accessPermissions[i]?.id]);
      if (check) {
        directoryAccess.push(parent);
        break;
      }
    }
  })

  return (
    <List className={classes.bg}>
      {itemsList.map((item) => {        
        const { text, icon, onClick } = item;
        if (
          _.toUpper(_.get(userInfo, 'role')) === USER_ROLE_SUPPER_ADMIN
          || _.includes(ALWAY_ALLOW_ACCESS, _.toUpper(_.get(item,"code")))
          || _.find(directoryAccess, ["code", _.get(item,"code")])
          || (item?.code === "PRODUCT" && _.find(directoryAccess, (o) => _.includes(["PRIMARY", "SECONDARY"], _.get(o,'code'))))
        ) {
          return (
            <ListItem
              style={item.style}
              button
              key={text}
              onClick={onClick}
              className={classes.border}
            >
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={text} color="white" />
            </ListItem>
          );
        }
      })}
    </List>
  );
};

export default DrawerV2;
