import { gql } from "@apollo/client";
import { CORE_SCHEMA_FIELDS } from "../product/fragments";

export const GET_LIST_EMPLOYEE = gql`
query getEmployees($skip: Int!, $take: Int!) {
  results: employee(offset: $skip, limit: $take, order_by: {accountByAccount: {created: desc_nulls_last}}) {
    id
    faceid: medium {
      id
      name
      ext
      mime
      size
      provider
      hash
      url
    }
    info: accountByAccount {
      is_can_login_agent
      id
      code
      username
      full_name
      email
      phone
      gender
      personalid
      roles: account_roles {
        role: roleByRole {
          id
          code
          name
        }
      }
      avatar: medium {
        id
        name
        url
        type
      }
      active
      online
      last_activity
      consult_count: productRequestsByIncharge_aggregate(where: {workflow_type: {code: {_eq: "CONSULT"}}}) {
        aggregate {
          count
        }
      }
      consign_count: productRequestsByIncharge_aggregate(where: {workflow_type: {code: {_eq: "CONSIGN"}}}) {
        aggregate {
          count
        }
      }
    }
    branch: branchByBranch {
      id
      code
      name
      address
      map
      active
      deleted
    }
    position: positionByPosition {
      id
      code
      name
      deleted
    }
    manager: employee {
      info: accountByAccount {
        id
        code
        full_name
        email
        phone
      }
    }
    departments: employee_departments {
      department: departmentByDepartment {
        id
        code
        name
      }
    }
  }
  t: employee_aggregate {
    aggregate {
      count
    }
  }
}
`;
export const GET_DETAIL_EMPLOYEE = gql`
  query getEmployee($id: bigint!) {
    results: employee_by_pk(id: $id) {
      employee_id: id
      info: accountByAccount {
        id
        code
        full_name
        email
        phone
        personalid
        created
        roles: account_roles {
          role: roleByRole {
            id
            code
            name
          }
        }
        avatar: medium {
          id
          name
          url
          type
        }
        active
        online
        last_activity
      }
      faceid: medium {
        id
        name
        ext
        mime
        size
        provider
        hash
        url
      }
      branch: branchByBranch {
        id
        code
        name
        address
        map
        active
        deleted
      }
      manager: employee {
        id
        info: accountByAccount {
          id
          code
          full_name
          email
          phone
        }
      }
      position: positionByPosition {
        id
        code
        name
      }

      departments: employee_departments {
        department: departmentByDepartment {
          id
          code
          name
        }
      }
    }
  }
`;

// position: positionByPosition {
//   id
//   code
//   name
// }

export const GET_EMPLOYEE_BY_ROLE = gql`
  query GetEmployeesByCode(
    $skip: Int!
    $take: Int!
    $where: view_list_employee_bool_exp!
  ) {
    results: view_list_employee(
      offset: $skip
      limit: $take
      where: $where
      order_by: { accountByAccount: { created: desc_nulls_last } }
    ) {
      id
      consult_count
      consign_count
      info: accountByAccount {
        id
        code
        full_name
        email
        phone
        personalid
        roles: account_roles {
          role: roleByRole {
            id
            code
            name
          }
        }
        avatar: medium {
          id
          name
          url
          type
        }
        active
        online
        last_activity
      }
      start
      end
      faceid: medium {
        id
        name
        ext
        mime
        size
        provider
        hash
        url
      }
      branch: branchByBranch {
        id
        code
        name
        address
        map
        active
        deleted
      }
      position: positionByPosition {
        id
        code
        name
        deleted
      }
      manager: employee {
        id
        info: accountByAccount {
          id
          code
          full_name
          email
          phone
        }
      }
      departments: employee_departments {
        department: departmentByDepartment {
          id
          code
          name
        }
      }
    }
    t: view_list_employee_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_EMPLOYEE_BY_ROLE_SUMMARY = gql`
  query GetEmployeeByRoleSummary($condition: view_list_employee_bool_exp!) {
    online: view_list_employee_aggregate(
      where: { _and: [
        $condition,
        { accountByAccount: { deleted: {_eq: false}, account_roles: {roleByRole: {code: {_eq: "SALES"}}} status: {_in: ["ONLINE"]} } }
      ]}
    ){
      aggregate {
        count
      }
    }
    offline: view_list_employee_aggregate(
      where: { _and: [
        $condition,
        { accountByAccount: { deleted: {_eq: false}, account_roles: {roleByRole: {code: {_eq: "SALES"}}} status: {_in: ["OFFLINE"]} } }
      ]}
    ){
      aggregate {
        count
      }
    }
    task_blocked: view_list_employee_aggregate(
      where: { _and: [
        $condition,
        { accountByAccount: { deleted: {_eq: false}, account_roles: {roleByRole: {code: {_eq: "SALES"}}} status: {_in: ["TASK_BLOCKED"]} } }
      ]}
    ){
      aggregate {
        count
      }
    }
    blocked: view_list_employee_aggregate(
      where: { _and: [
        $condition,
        { accountByAccount: { deleted: {_eq: false}, account_roles: {roleByRole: {code: {_eq: "SALES"}}} status: {_in: ["BLOCKED"]} } }
      ]}
    ){
      aggregate {
        count
      }
    }    
  }
`;

export const GET_LIST_DEPARTMENTS = gql`
  query getDepartments {
    results: department(
      where: { active: { _eq: true }, deleted: { _eq: false } }
    ) {
      id
      code
      name
    }
  }
`;

export const GET_LIST_ROLES = gql`
  query GetRoles {
    results: role(where: { deleted: { _eq: false } }) {
      id
      code
      name
    }
  }
`;

export const GET_ALL_SCHEDULE = gql`
  query GetProductRequestEventByCustomer(
    $where: product_request_event_bool_exp!
    $take: Int!
    $skip: Int!
  ) {
    events: product_request_event(offset: $skip, limit: $take, where: $where) {
      id
      title
      status
      start_time
      end_time
      type: product_request_event_type {
        id
        code
        name
      }
      customer_info: account {
        id
        code
        full_name
        avatar: medium {
          id
          url
        }
      }
      employee_info: accountByEmployee {
        id
        code
        full_name
        avatar: medium {
          id
          url
        }
      }
    }
  }
`;

export const GET_DETAIL_SCHEDULE = gql`
  ${CORE_SCHEMA_FIELDS}
  query GetProductRequestEvent($uid: uuid!) {
    detail: product_request_event_by_pk(id: $uid) {
      id
      title
      status
      start_time
      end_time
      address
      remark
      type: product_request_event_type {
        id
        code
        name
      }
      customer_info: account {
        id
        code
        full_name
        avatar: medium {
          id
          url
        }
      }
      employee_info: accountByEmployee {
        id
        code
        full_name
        avatar: medium {
          id
          url
        }
      }
      product_info: productByProduct {
        id
        code
        title
        bed_room
        bath_room
        basement
        area
        area_used
        area_width
        area_height
        price_to_display
        price_unit: priceUnitByPriceUnit {
          id
          name
        }
        address: addressByAddress {
          ...addressData
        }
        avatar: productAvatarByMedia {
          id
          name
          url
        }
      }
    }
  }
`;

export const GET_PRODUCT_BY_EMPLOYEE = gql`
  ${CORE_SCHEMA_FIELDS}
  query GetProductsByCustomer(
    $skip: Int!
    $take: Int!
    $condition: product_bool_exp!
    $order_by: [product_order_by!] = {}
  ) {
    product_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
    products: product(
      offset: $skip
      limit: $take
      where: $condition
      order_by: $order_by
    ) {
      status
      title
      id
      active
      product_requests(
        limit: 1
        order_by: { updated_by: desc_nulls_first, created: desc }
      ) {
        workflow_type {
          code
        }
        id
        requests {
          id
          draft
          locked
          current_step
          type_workflow {
            type_step {
              id
              code
              name
              type_status {
                id
                code
                name
              }
            }
          }
        }
        type_workflow {
          typeByType {
            id
            code
            name
          }
          type_step {
            id
            code
            name
          }
        }
      }
      branchByBranch {
        id
        code
        name
      }
      id
      code
      title
      price_to_display
      price_unit: priceUnitByPriceUnit {
        id
        name
      }
      area
      area_used
      area_height
      area_width
      bath_room
      bed_room
      basement
      orientation: orientationByOrientation {
        id
        name
      }
      exclusive
      urgent
      publish
      address: addressByAddress {
        ...addressData
      }
      listing_type: listingTypeByListingType {
        id
        name
      }
      legal_type: legalTypeByLegalType {
        id
        name
      }
      property_type: propertyTypeByPropertyType {
        id
        name
      }
      avatar: productAvatarByMedia {
        id
        name
        url
      }
      created
      updated
      updated_by_role: updatedByAccount {
        account_roles {
          roleByRole {
            id
            code
            name
          }
        }
      }
    }
  }
`;
export const GET_EVENT_TYPES = gql`
  query GetProductRequestEventTypes {
    results: product_request_event_type(where: { deleted: { _eq: false } }) {
      id
      code
      name
    }
  }
`;

export const GET_EMPLOYEE_BY_CODE = gql`
  query GetEmployeesByCode(
    $skip: Int!
    $take: Int!
    $where: employee_bool_exp!
  ) {
    results: employee(offset: $skip, limit: $take, where: $where) {
      id
      info: accountByAccount {
        id
        code
      }
    }
  }
`;

export const GET_EMPLOYEE_BY_ROLE_SECONDARY = gql`
  query getEmployeesByRole($roleCodes: [String!]!) {
    results: employee(
      where: {
        accountByAccount: {
          account_roles: { roleByRole: { code: { _in: $roleCodes } } }
        }
      }
    ) {
      info: accountByAccount {
        id
        code
        full_name
        email
        phone
        personalid
      }
    }
  }
`;

export const GET_EMPLOYEE_BY_ACCOUNT = gql`
  query GetEmployeeByAccount($id: bigint!) {
    results: employee(where: { account: { _eq: $id } }) {
      info: accountByAccount {
        id
        code
        full_name
        email
        phone
        personalid
        avatar: medium {
          id
          name
          url
        }
        roles: account_roles {
          role: roleByRole {
            id
            code
            name
          }
        }
        active
      }
      branch: branchByBranch {
        id
        code
        name
      }
    }
  }
`;

export const GET_ACTIVITY_HISTORY_BY_EMPLOYEE = gql`
  query GetHistoryByEmployee($employee: bigint!, $type: String!) {
    request_workflow(
      where: {
        type_workflow: { typeByType: { code: { _eq: $type } } }
        requestByRequest: { assigned_user: { _eq: $employee } }
      }
    ) {
      type_workflow {
        typeByType {
          id
          code
          name
        }
      }
      id
      step
      type_workflow {
        type_step {
          id
          code
          name
        }
      }
      requestByRequest {
        account: customerByCustomer {
          id
          code
          full_name
          customers {
            id
          }
        }
        productByProduct {
          productByProduct {
            id
            code
          }
        }
      }
      created
      account {
        id
        code
        full_name
        employees {
          id
        }
      }
    }
  }
`;

export const GET_EMPLOYEE_BY_ID = gql`
  query GetEmployeesByCode(
    $skip: Int!
    $take: Int!
    $where: employee_bool_exp!
  ) {
    results: employee(offset: $skip, limit: $take, where: $where) {
      id
      info: accountByAccount {
        id
        code
        full_name
        email
        phone
        personalid
        birthday
        roles: account_roles {
          role: roleByRole {
            id
            code
            name
          }
        }
        employeeType: employeeTypeByEmployeeType {
          id
          code
          name
        }
        products: account_products {
          product: productByProduct {
            id
            title
          }
        }
        avatar: medium {
          id
          name
          url
          type
        }
        active
        online
        last_activity
      }
      faceid: medium {
        id
        name
        ext
        mime
        size
        provider
        hash
        url
      }
      branch: branchByBranch {
        id
        code
        name
        address
        map
        active
        deleted
      }
      position: positionByPosition {
        id
        code
        name
      }
      manager: employee {
        id
        info: accountByAccount {
          id
          code
          full_name
          email
          phone
        }
      }
      departments: employee_departments {
        department: departmentByDepartment {
          id
          code
          name
        }
      }
    }
  }
`;

export const CHECK_EMAIL_EXIST = gql`
  query CheckEmailExist($email: String) {
    result: account(where: { email: { _eq: $email } }) {
      id
      code
      full_name
      email
      phone
    }
  }
`;

export const CHECK_PHONE_EXIST = gql`
  query CheckPhoneExist($phone: String) {
    result: account(where: { phone: { _ilike: $phone } }) {
      id
      code
      full_name
      email
      phone
    }
  }
`;

export const CHECK_CODE_EXIST = gql`
  query CheckCodeExist($code: String) {
    result: account(where: { code: { _eq: $code } }) {
      id
      code
      full_name
      email
      phone
    }
  }
`;

export const GET_EMPLOYEE_TYPE = gql`
  query GetEmployeeType {
    result: employee_type {
      id
      code
      name
    }
  }
`;
