import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, TextField } from "@material-ui/core";
import { GET_BRANCH_BY } from "../graphql/schemas/masterdata/branches/index";
import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";

import * as _ from "lodash";
import { GET_LIST_BANK } from "../graphql/schemas/customer/customerQueries";

AutoCompleteBank.propTypes = {};
const useStyles = makeStyles((theme) => ({
  input: {
    width: 250,
    backgroundColor: "white",
    marginLeft: "0",
    "& > .MuiFormHelperText-marginDense": {
      backgroundColor: "#FAFAFA",
      margin: 0,
    },
  },
}));

function AutoCompleteBank(props) {
  const { handleAnotherBank, onChange, editBankValue } = props;
  const [banks, setBanks] = useState([]);
  const [value, setValue] = useState("");
  const classes = useStyles();

  const { loading, data, error } = useQuery(GET_LIST_BANK);

  useEffect(() => {
    setBanks(() => data?.result);
  }, [data]);

  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error loading banks</p>;
  }

  const handleSelect = (e, value) => {
    setValue(value.name);
    onChange(value.id);
    handleAnotherBank(value.id === banks[banks.length - 1].id);
  };
  return (
    <Autocomplete
      inputValue={value}
      fullWidth={true}
      options={banks}
      getOptionLabel={(option) => _.toString(option.name)}
      className={classes.input}
      onChange={handleSelect}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          fullWidth={true}
          value={value}
          label={
            editBankValue
              ? banks?.find((item) => item?.id === editBankValue)?.name
              : ""
          }
          style={{ backgroundColor: "#ffffff" }}
        />
      )}
    />
  );
}

export default AutoCompleteBank;
