import {
  Grid, Typography, Button, TextField, Paper,
  RadioGroup, Radio, FormControlLabel, CircularProgress, IconButton,
} from "@material-ui/core";
import { Cancel, Edit, Check, RemoveCircleOutline, CloudUploadTwoTone, AddCircleRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect, Fragment, useContext } from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import TableComponent from "../../../../components/TableComponent";
import BingoResultSettings from "../../create/BingoResultSettings";
import { useGetEventDetail, useGetJackpotType, useUpdateEvent, useUpdateRedeemEcoinGameSetting } from "../../hooks";
import BingoResults from "../../create/BingoResults";
import Upload from "../../components/Upload";
import InputDateAndTimePicker from "../../../../components/InputDateAndTimePicker";
import { withStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from "@apollo/client";
import swal from 'sweetalert';
import axios from "axios";
import { INSERT_YOUTUBE_MEDIA } from "../../../../graphql/schemas/event/eventMutation";
import EventCalendars from "../../create/EventCalendars";
import { BINGO_RESULT_SETTING_ITEM, BUY_TICKET, UPDATE_PROMOTION } from "../../../../constants/defined";
import { GET_PROFILE } from "../../../../graphql/schemas/account/accountQueries";
import { ConfigContext } from "../../../../context/ConfigContext";
import { checkedPermission } from "../../../../helper";
import { StoreContext } from "../../../../resources/store";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: "bold",
  },
  space: {
    width: 10,
    height: 50,
  },
  marginLeftSpace: {
    marginLeft: 20,
  },
  textUnderLine: {
    textDecoration: "underline",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  widthTitle: {
    width: 280,
  },
  wrapperPaper: {
    padding: "30px 15px",
    marginBottom: "30px",
  },
  linkStyle: {
    color: "#00837B",
    '&:hover': {
      fontWeight: 'bold'
    }
  },
  addBtn: {
    background: "#00837B",
    borderRadius: "6px",
    color: "#fff",
    fontSize: "16px",

    "& > span": {
      gap: "10px",
      display: "flex",
      alignItems: "center",
    },

    "&:hover": {
      background: "rgba(0,131,123,0.8)",
    },
  },
  wapperLoading: {
    width: '100%',
    height: 'calc(100vh - 113px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const headMenu = [
  {
    id: "order",
    numeric: false,
    disablePadding: false,
    label: "STT",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Tên đợt giải thưởng",
  },
  {
    id: "start",
    numeric: false,
    disablePadding: false,
    label: "Thời gian bắt đầu",
  },
  {
    id: "end",
    numeric: false,
    disablePadding: false,
    label: "Thời gian kết thúc",
  },
];

const CustomRadio = withStyles((theme) => ({
  root: {
    color: '#00837B',
    '&$checked': {
      color: '#00837B',
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);

const BingoGameDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState();
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [eventCalendars, setEventCalendars] = useState([]);
  const [typeBingoEvent, setTypeBingoEvent] = useState('TICKETS'); //CALENDARS
  const [eventTickets, setEventTickets] = useState([]);
  const [deleteTicketsItems, setDeleteTicketsItems] = useState([]);
  const [redeemEcoinEventSetting, setRedeemEcoinEventSetting] = useState();

  const configValue = useContext(ConfigContext);
  const { userInfo, accessObjectPermissions } = useContext(StoreContext);

  const { loading, data } = useGetEventDetail(id);
  const { updateRedeemEcoinGameSetting, data: dataRedeemEcoin, loading: loadingRedeemEcoin, error: errorRedeemEcoin } = useUpdateRedeemEcoinGameSetting()
  const { updateEvent, data: updatedData, loading: loadingUpdate } = useUpdateEvent(id, setIsEdit, setSubmitDisabled);
  const { data: dataBuyTicket, loading: loadingBuyTicket } = useGetJackpotType({ code: BUY_TICKET });
  const { loading: loadingGetProfile, data: dataGetProfile } = useQuery(GET_PROFILE, {
    variables: {
      id: _.get(data, "created_by") * 1,
    },
    skip: !_.get(data, "created_by"),
  });

  const [insertLinkYoutubeMedia, { data: dataInsertMedia, loading: loadingInsertMedia, error: errorInsertMedia }] = useMutation(INSERT_YOUTUBE_MEDIA, {
    onCompleted: (data) => {
      setVideoUpdate(_.concat(videoUpdate, [_.get(data, 'media')]))
    },
    onError: (err) => {
      return swal({
        title: "Kiểm tra lại link video",
        icon: "error",
      })
    },
  });

  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];
    if (file?.size > 5e6) {
      setErrorUploadVideo("Dung lượng file tối đa cho phép là 20MB");
      return false;
    }
    if (_.size(listVideoView(videoUpload, videoUpdate)) === 5) {
      setErrorUploadVideo("Chỉ cho phép tối đa 5 file");
      return false;
    }
    setLoadingVideoUpload(true);
    setErrorUploadVideo("");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("media_type_code", "VIDEO");
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        configValue.API_UPLOAD_FILE,
        formData,
        config
      );
      setVideoUpdate([...videoUpdate, data[0]]);
      setLoadingVideoUpload(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [videoUpload, setVideoUpload] = useState([]);
  const [videoUpdate, setVideoUpdate] = useState([]);
  const [typeVideoUpload, setTypeVideoUpload] = useState('LINK');
  const [errorUploadVideo, setErrorUploadVideo] = useState("");
  const [loadingVideoUpload, setLoadingVideoUpload] = useState(false);

  const [ruleGameUpload, setRuleGameUpload] = useState([]);
  const [ruleGameUpdate, setRuleGameUpdate] = useState([]);

  const [guideGameUpload, setGuideGameUpload] = useState([]);
  const [guideGameUpdate, setGuideGameUpdate] = useState([]);

  const [bingoResults, setBingoResults] = useState([
    {
      code: "",
      setting: null,
      reward: null,
      result_date: "",
      channel: "",
    },
  ]);

  const [bingoSettings, setBingoSettings] = useState([]);

  const [eventObj, setEventObj] = useState({
    name: null,
    start: null,
    end: null,
    type: null,
    event_medias: [],
  });

  const handleUpdate = () => {
    setSubmitDisabled(true);
    let arg = {};
    arg.id = parseInt(id);
    if (name) {
      arg.name = name;
    }
    if (dateStart) {
      arg.start = dateStart;
    }
    if (dateEnd) {
      arg.end = dateEnd;
    }
    const media = [...ruleGameUpdate, ...videoUpdate, ...guideGameUpdate];
    const updatedMediaId = media.map((item) => item.id);
    const uniq = [...new Set(updatedMediaId)];
    const eventMedia = uniq.map((item) => ({ media: item }));

    arg.event_medias_update = eventMedia;

    arg.bingo_result_daily = [];
    arg.event_gifts_update = [];
    arg.event_items_update = [];

    arg.bingo_result_settings_update = _.map(bingoSettings, (item) => {
      return item;
    });

    arg.bingo_result_daily = _.map(
      _.filter(bingoResults, "setting"),
      (item) => {
        return {
          ...item,
          result_date: moment(new Date(), "YYYY-MM-DD[T]HH-mm").format(
            "YYYY-MM-DD"
          ),
        };
      }
    );
    if (_.size(eventTickets) > 0 && typeBingoEvent === "TICKETS") {
      arg.event_tickets = _.map(_.filter(eventTickets, (o) => _.get(o, "ticket") > 0), (item) => {
        if (_.get(item, "id")) {
          return {
            id: _.get(item, "id") || null,
            ticket: _.get(item, "ticket") || 0
          }
        } else {
          return {
            ticket: _.get(item, "ticket") || 0
          }
        }
      });
    } else {
      arg.event_tickets = []
    }

    if (_.size(eventCalendars) > 0 && typeBingoEvent === "CALENDARS") {
      arg.event_calendars = _.map(eventCalendars, (item) => {
        return {
          start: moment(_.get(item, "start"))
            .subtract(7, "hours")
            .format("YYYY-MM-DD HH:mm"),
          end: moment(_.get(item, "end"))
            .subtract(7, "hours")
            .format("YYYY-MM-DD HH:mm"),
        };
      });
    }

    if(redeemEcoinEventSetting) {
      updateRedeemEcoinGameSetting({
        variables: {
          ecoin: redeemEcoinEventSetting*(-1),
          where: {
            code: {
              _eq: "BUY_TICKET"
            }
          }
        }  
      }).then((res) => {
        if(_.get(res, "data.result")) {
          updateEvent({
            variables: {
              arg,
            },
          });
        } else {
          setIsEdit(false);
          setSubmitDisabled(false)
        }        
      })
    } else {
      updateEvent({
        variables: {
          arg,
        },
      });
    }
  };

  const listVideoView = (videoUpload, videoUpdate) => {
    let arr = [];
    arr = _.unionBy(_.concat(videoUpload, videoUpdate), 'id');
    arr = _.filter(arr, item => (
      (_.includes(_.map(videoUpload, o => _.get(o, 'id')), _.get(item, 'id')) && !_.includes(_.map(videoUpdate, o => _.get(o, 'id')), _.get(item, 'id')))
      ||
      (!_.includes(_.map(videoUpload, o => _.get(o, 'id')), _.get(item, 'id')) && _.includes(_.map(videoUpdate, o => _.get(o, 'id')), _.get(item, 'id')))
    ))
    return arr;
  };

  const handleRemoveField = (index, item) => {
    let tmp = [...videoUpdate];

    if (_.find(videoUpload, { 'id': _.get(item, 'id') })) {
      tmp = [...videoUpdate, item];
    } else {
      tmp = _.remove(videoUpdate, o => o?.id !== item?.id);
    }
    setVideoUpdate(tmp);
  };

  const dataInital = () => {
    let tmpResults = _.map(
      JSON.parse(JSON.stringify(data?.bingo_result_settings || [])),
      (item) => {
        delete item.__typename;
        return item;
      }
    );

    if (_.size(tmpResults) > 0) {
      setBingoSettings(tmpResults);
    } else {
      setBingoSettings([{ ...BINGO_RESULT_SETTING_ITEM }]);
    }

    let tmpRes = _.map(
      JSON.parse(JSON.stringify(data?.bingo_results)),
      (item) => {
        delete item.__typename;
        return item;
      }
    );

    let tmpEventCalendar = _.map(data.event_calendars, (item, index) => {
      return {
        order: index + 1,
        name: `Đợt ${index + 1}`,
        start: moment(_.get(item, "start")).format("DD/MM/YYYY - HH:mm"),

        end: moment(_.get(item, "end")).format("DD/MM/YYYY - HH:mm"),
      };
    });

    let rule = _.map(_.get(data, "event_medias"), (item) => item?.media).filter(
      (item) => _.get(item, 'media_type.code') === "RULE_GAME"
    );
    let guide = _.map(_.get(data, "event_medias"), (item) => item?.media).filter(
      (item) => _.get(item, 'media_type.code') === "GUIDE_GAME"
    );
    let video = _.map(_.get(data, "event_medias"), (item) => item?.media).filter(
      (item) => _.includes(["YOUTUBE", "VIDEO"], _.get(item, "media_type.code"))
    );

    let getTicket = _.map(_.get(data, "event_tickets"), item => item) || { index: null, ticket: null };
    setRuleGameUpload(rule);
    setGuideGameUpload(guide);
    setVideoUpload(video);

    setEventCalendars(tmpEventCalendar);

    setBingoResults(tmpRes);
    setEventTickets(getTicket);
  }

  useEffect(() => {
    dataInital();
  }, [data, isEdit]);

  if (loading || loadingGetProfile || loadingBuyTicket || loadingUpdate || loadingRedeemEcoin) return (
    <div className={classes.wapperLoading}>
      <CircularProgress />
    </div>
  )

  return (
    <Grid container direction="column" style={{ marginTop: 40 }}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={4}>
          <Grid className={classes.flex}>
            <Typography className={clsx(classes.bold, classes.widthTitle)}>
              Tên chương trình
            </Typography>
            {isEdit ? (
              <TextField
                style={{ backgroundColor: "#ffffff", width: 250 }}
                size="small"
                variant="outlined"
                value={name}
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
              />
            ) : (
              <Typography className={clsx(classes.marginLeftSpace)}>
                {_.get(data, "name")}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid className={classes.flex}>
            <Typography
              className={clsx(
                classes.bold,
                classes.widthTitle,
                classes.marginLeftSpace
              )}
            >
              Mã chương trình:
            </Typography>
            <Typography className={clsx(classes.marginLeftSpace)}>
              {_.get(data, "code")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid className={classes.flex}>
            <Typography className={clsx(classes.bold, classes.widthTitle)}>
              Loại chương trình:
            </Typography>
            <Typography className={clsx(classes.marginLeftSpace)}>
              {_.get(data, "type_info.name")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.space}></Grid>
      <Grid container direction="row">
        <Grid item xs={4}>
          <Grid className={classes.flex}>
            <Typography className={clsx(classes.bold, classes.widthTitle)}>
              Tổng số Ecoin được tạo:
            </Typography>
            <Typography className={clsx(classes.bold, classes.marginLeftSpace)}>
              {_.get(data, "total_ecoin.aggregate.sum.ecoin") +
                _.get(data, "total_ecoin.aggregate.sum.room_ecoin")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid className={classes.flex}>
            <Typography
              className={clsx(
                classes.bold,
                classes.marginLeftSpace,
                classes.widthTitle
              )}
            >
              Số Ecoin/ vé
            </Typography>
            <Typography className={clsx(classes.bold, classes.marginLeftSpace)}>
              {Math.abs(_.get(_.head(dataBuyTicket), 'ecoin')) || '-'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.space}></Grid>
      <Grid container direction="row">
        <Grid item xs={4}>
          <Grid className={classes.flex}>
            <Typography className={clsx(classes.bold, classes.widthTitle)}>
              Ngày tạo:
            </Typography>
            <Typography className={clsx(classes.marginLeftSpace)}>
              {moment(_.get(data, "created")).format("DD/MM/yyyy")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid className={classes.flex}>
            <Typography
              className={clsx(
                classes.bold,
                classes.marginLeftSpace,
                classes.widthTitle
              )}
            >
              Người tạo:
            </Typography>
            <Link
              to={`/employee/detail/${_.get(dataGetProfile, "id")}`}
              className={clsx(classes.marginLeftSpace, classes.textUnderLine)}
            >
              {_.get(dataGetProfile, "full_name")}
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.space}></Grid>

      <Grid container direction="row" alignItems="center">

        <Grid item xs={6}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Typography className={clsx(classes.bold)} style={{ paddingRight: '16px' }}>Thời gian bắt đầu:</Typography>
            </Grid>
            {
              (isEdit && moment(_.get(data, "start")).unix() > moment(new Date()).unix()) ? (
                <InputDateAndTimePicker setDateTime={setDateStart} defaultValue={_.get(data, "start")} />
              )
                :
                (
                  <Grid item>
                    <Typography
                      className={clsx(classes.marginLeftSpace)}
                      component="span"
                    >
                      {moment(_.get(data, "start")).format("DD/MM/yyyy HH:mm:ss A")}
                    </Typography>
                  </Grid>
                )
            }
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Typography className={clsx(classes.bold, classes.marginLeftSpace)} style={{ paddingRight: '16px' }}>Thời gian kết thúc:</Typography>
            </Grid>
            {
              (isEdit && moment(_.get(data, "end")).unix() > moment(new Date()).unix()) ? (
                <InputDateAndTimePicker setDateTime={setDateEnd} defaultValue={_.get(data, "end")} />
              )
                :
                (
                  <Grid item>
                    <Typography
                      className={clsx(classes.marginLeftSpace)}
                      component="span"
                    >
                      {moment(_.get(data, "end")).format("DD/MM/yyyy HH:mm:ss A")}
                    </Typography>
                  </Grid>
                )
            }
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.space}></Grid>

      <Grid container direction="row" spacing={3}>
        <Grid item xs={6}>
          <Typography className={clsx(classes.bold)}>Thể lệ:</Typography>
          {isEdit ? (
            <Upload
              type="RULE_GAME"
              uploadData={ruleGameUpload}
              setUploadData={setRuleGameUpload}
              fileChange={ruleGameUpdate}
              setFileChange={setRuleGameUpdate}
              limit={1}
              id={`upload-rule${id}`}
            />
          ) : (
            <Typography className={clsx(classes.marginLeftSpace)}>
              {_.map(_.get(data, "event_medias"), (item, key) => {
                if (item.media?.media_type.code === "RULE_GAME")
                  return (
                    <Grid item key={key}>
                      <a
                        href={
                          configValue.MEDIA_DOMAIN +
                          _.get(item, "media.url")
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {_.get(item, "media.name")}
                      </a>
                      <Grid className={classes.space}></Grid>
                    </Grid>
                  );
              })}
            </Typography>
          )}
        </Grid>

        {isEdit ? (
          <Grid item>
            <Typography className={clsx(classes.bold)}>Hướng dẫn:</Typography>
            <Upload
              type="GUIDE_GAME"
              uploadData={guideGameUpload}
              setUploadData={setGuideGameUpload}
              limit={1}
              id={`upload-guide${id}`}
              fileChange={guideGameUpdate}
              setFileChange={setGuideGameUpdate}
            />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <Typography className={clsx(classes.bold)}>Hướng dẫn:</Typography>
            <Typography className={clsx(classes.marginLeftSpace)}>
              {_.map(_.get(data, "event_medias"), (item, key) => {
                if (item.media?.media_type.code === "GUIDE_GAME")
                  return (
                    <Grid item key={key}>
                      <a
                        href={
                          configValue.MEDIA_DOMAIN +
                          _.get(item, "media.url")
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {_.get(item, "media.name")}
                      </a>
                      <Grid className={classes.space}></Grid>
                    </Grid>
                  );
              })}
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid className={classes.space}></Grid>

      <Grid container direction="row">
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item xs={2}>
              <Typography className={clsx(classes.bold, classes.widthTitle)}>Video:</Typography>
              {
                isEdit && <span style={{ color: 'grey' }}>Tối đa 5 file</span>
              }
            </Grid>

            {/* {_.find(_.get(data, "event_medias"), [
              "media.media_type.code",
              "YOUTUBE",
            ]) && (
              <Grid item xs={6} className={classes.marginLeftSpace}>
                {_.map(_.get(data, "event_medias"), (item, key) => {
                  if (_.get(item, "meida.media_type.code") === "VIDEO")
                    return (
                      <>
                        <ReactPlayer
                          url={
                            configValue.MEDIA_DOMAIN +
                            _.get(item, "media.url")
                          }
                          width="360"
                          key={key}
                          controls={true}
                        />
                        <Grid className={classes.space}></Grid>
                      </>
                    );
                })}
              </Grid>
            )} */}

            {/* {isEdit && (
              <Grid item>
                <Upload
                  type="VIDEO"
                  uploadData={videoUpload}
                  setUploadData={setVideoUpload}
                  fileChange={videoUpdate}
                  setFileChange={setVideoUpdate}
                  limit={5}
                  id={`upload-video${id}`}
                />
              </Grid>
            )} */}


            <Grid item xs={10} style={{ paddingTop: 15 }}>
              {
                !isEdit ? (
                  <Grid container direction="row" spacing={3}>
                    {
                      _.map(_.get(data, "event_medias"), (item, key) => {
                        if (_.includes(['VIDEO', 'YOUTUBE'], _.get(item, 'media.media_type.code')))
                          return (
                            <Grid item xs={4} key={key}>
                              <ReactPlayer
                                url={configValue.MEDIA_DOMAIN + _.get(item, "media.url")}
                                width="auto"
                                controls={true}
                              />
                              <Grid className={classes.space}></Grid>
                            </Grid>
                          );
                      })
                    }
                  </Grid>
                )
                  :
                  (
                    <Grid direction="column" container>
                      <Grid item>
                        <RadioGroup
                          style={{ flexDirection: 'row' }}
                          aria-label="game-video"
                          name="game-video-option"
                          value={typeVideoUpload}
                          onChange={(e) => setTypeVideoUpload(e.target.value)}
                        >
                          <FormControlLabel value="LINK" control={<CustomRadio />} label="Link youtube" />
                          <FormControlLabel value="UPLOAD" control={<CustomRadio />} label="Upload video" />
                        </RadioGroup>
                      </Grid>
                      {
                        typeVideoUpload === "LINK" ? (
                          <Grid item>
                            {
                              _.map(listVideoView(videoUpload, videoUpdate), (item, key) => {
                                return (
                                  <Grid key={key} direction="row" container spacing={3}>
                                    <Grid item className={classes.textLabelForm} xs={1}>
                                      <p>{`Link ${key + 1}`}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        id={`add-link-video-${key}`}
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={_.get(item, 'provider') ? configValue.MEDIA_DOMAIN + _.get(item, "url") : _.get(item, 'url')}
                                      />
                                    </Grid>
                                    <Grid item xs={1}>
                                      <IconButton
                                        onClick={() => handleRemoveField(key, item)}
                                      >
                                        <RemoveCircleOutline style={{ fill: "red" }} />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              })
                            }

                            {
                              _.size(listVideoView(videoUpload, videoUpdate)) < 5 && (
                                loadingInsertMedia ? (
                                  <CircularProgress />
                                )
                                  :
                                  (
                                    <Grid direction="row" container spacing={3}>
                                      <Grid item className={classes.textLabelForm} xs={1}>
                                        <p>{`Link ${_.size(listVideoView(videoUpload, videoUpdate)) + 1}`}</p>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <TextField
                                          id="add-link-video"
                                          placeholder="link youtube"
                                          variant="outlined"
                                          fullWidth
                                          value={null}
                                          onBlur={
                                            (e) => {
                                              if (_.get(e, 'target.value')) {
                                                insertLinkYoutubeMedia({
                                                  variables: {
                                                    url: _.get(e, 'target.value')
                                                  }
                                                })
                                              }
                                            }
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  )
                              )
                            }
                          </Grid>
                        )
                          :
                          (
                            <Grid item xs={12}>
                              <Grid container direction="row" spacing={3}>
                                {_.map(listVideoView(videoUpload, videoUpdate), (item, key) => (
                                  <Grid item xs={4} key={key} style={{ position: 'relative' }}>
                                    <ReactPlayer
                                      url={configValue.MEDIA_DOMAIN + _.get(item, "url")}
                                      width="360"
                                      controls={true}
                                    />

                                    <IconButton
                                      onClick={() => handleRemoveField(key, item)}
                                      style={{ position: 'absolute', top: 10, right: 10 }}
                                    >
                                      <RemoveCircleOutline style={{ fill: "red" }} />
                                    </IconButton>
                                  </Grid>
                                ))}

                                {
                                  _.size(listVideoView(videoUpload, videoUpdate)) < 5 && (
                                    <Grid item xs={4}>
                                      <div>
                                        <input
                                          hidden
                                          accept="video/mp4,video/x-m4v,video/*"
                                          id="icon-video-file"
                                          type="file"
                                          onChange={handleVideoUpload}
                                          disabled={loadingVideoUpload}
                                        />
                                        <label htmlFor="icon-video-file">
                                          <IconButton aria-label="icon-video-file" component="span">
                                            {loadingVideoUpload ? (
                                              <CircularProgress />
                                            ) : (
                                              <CloudUploadTwoTone
                                                style={{ height: "auto", width: "100px" }}
                                              />
                                            )}
                                          </IconButton>
                                          <p style={{ color: "red", fontStyle: "italic" }}>
                                            {errorUploadVideo}
                                          </p>
                                        </label>
                                      </div>
                                    </Grid>
                                  )
                                }
                              </Grid>
                            </Grid>
                          )
                      }
                    </Grid>
                  )
              }
            </Grid>

          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.space}></Grid>

      <Grid container direction="row">
        <Grid container item direction="row">
          <p style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "1.3rem" }} className={classes.textLabelForm}>
            Danh sách đợt
          </p>
        </Grid>

        <Grid container item direction="row">
          <RadioGroup
            style={{ flexDirection: 'row' }}
            aria-label="game-bingo"
            name="game-bingo-event"
            value={typeBingoEvent}
            onChange={(e) => setTypeBingoEvent(e.target.value)}
          >
            <FormControlLabel value="TICKETS" control={<CustomRadio />} label="Theo số vé" />
            <FormControlLabel value="CALENDARS" disabled control={<CustomRadio />} label="Theo thời gian" />
          </RadioGroup>
        </Grid>

        <Grid container item direction="row">
          {
            isEdit ? (
              <Grid item xs={12}>
                {
                  typeBingoEvent === "TICKETS" ? (
                    <Grid direction="column" container xs={6} spacing={3}>
                      <Grid item xs={12}>
                        <Grid direction="row" container spacing={1}>
                          <Grid item xs={3} className={classes.textLabelForm}>Tên đợt</Grid>
                          <Grid item xs={8} className={classes.textLabelForm}>Số vé</Grid>
                        </Grid>
                      </Grid>

                      {
                        _.map(eventTickets, (item, key) => {
                          return (
                            <Grid item xs={12} key={key}>
                              <Grid direction="row" container spacing={1} alignItems="center">
                                <Grid item xs={3}>{`Đợt ${key + 1}`}</Grid>
                                <Grid item xs={8}>
                                  <TextField
                                    fullWidth
                                    defaultValue={_.get(item, 'ticket')}
                                    size="small" variant="outlined"
                                    onChange={(e) => {
                                      let tmp = _.cloneDeep(eventTickets);
                                      _.set(tmp[key], 'ticket', _.toInteger(e.target.value));
                                      _.set(tmp[key], 'index', key);
                                      setEventTickets(tmp);
                                    }}
                                  />
                                </Grid>

                                {/* {_.size(eventTickets) > 1 && (
                                  <Grid item xs={1}>
                                    <IconButton onClick={() => {
                                      const fields = _.cloneDeep(eventTickets);
                                      setDeleteTicketsItems(_.concat(deleteTicketsItems, [fields[key]?.id]));
                                      fields.splice(key, 1);
                                      setEventTickets(fields);
                                    }}>
                                      <RemoveCircleOutline style={{ fill: "red" }} />
                                    </IconButton>
                                  </Grid>
                                )} */}

                              </Grid>
                            </Grid>
                          )
                        })
                      }

                      <Grid item xs={12}>
                        <IconButton
                          className={classes.addBtn}
                          onClick={() => {
                            let tmp = [...eventTickets, { index: null, ticket: null }];
                            setEventTickets(tmp);
                          }}
                        >
                          <AddCircleRounded style={{ fill: "white" }} />{" "}
                          <span>Thêm đợt</span>
                        </IconButton>

                      </Grid>
                    </Grid>
                  )
                    :
                    (
                      <EventCalendars
                        eventCalendars={eventCalendars}
                        setEventCalendars={setEventCalendars}
                      />
                    )
                }
              </Grid>
            )
              :
              (
                <Fragment>
                  <Grid
                    direction="row"
                    container
                    style={{ height: '45px', alignItems: 'center', padding: '0 10px', flexBasis: 'unset', fontWeight: 'bold', opacity: '0.6', background: '#ccc' }}
                  >
                    <Grid item xs={1}>STT</Grid>
                    <Grid item xs={2}>Tên đợt</Grid>
                    <Grid item xs={3}>Số vé</Grid>
                    <Grid item xs={3}>Thời gian cập nhật</Grid>
                  </Grid>

                  {
                    _.map(eventTickets, (item, key) => {
                      return (
                        <Grid key={key}
                          direction="row" container spacing={3} alignItems="center"
                          style={{
                            borderBottom: '1px solid #ccc',
                            margin: 0
                          }}
                        >
                          <Grid item xs={1}>{key + 1}</Grid>
                          <Grid item xs={2}>{`Đợt ${key + 1}`}</Grid>
                          <Grid item xs={3}>
                            <Typography component="span">
                              {_.get(item, 'ticket')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography component="span">
                              {_.get(item, 'created') ? moment(_.get(item, 'created')).format("DD/MM/yyyy") : '-'}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} style={{ textAlign: 'center' }}>
                            {
                              (_.isEmpty(_.get(item, 'bingo_results')) || _.size(_.get(item, 'bingo_results')) < 14) ? (
                                <Link
                                  to={{
                                    pathname: `/bingo-result/setting/${_.get(item, "id")}`,
                                    state: { data: item, round: `Đợt ${key + 1}`, eventData: data }
                                  }}
                                  style={{ textDecoration: 'none' }}
                                >
                                  <span style={{ padding: '8px 16px' }} className={classes.addBtn}>
                                    Nhập mã số trúng thưởng
                                  </span>
                                </Link>
                              )
                                :
                                (
                                  <Link
                                    className={classes.linkStyle}
                                    to={{
                                      pathname: `/bingo-result/round/${_.get(item, "id")}`,
                                      state: { data: item, round: `Đợt ${key + 1}` }
                                    }}
                                  >Chi tiết giải</Link>
                                )
                            }
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Fragment>
              )
          }
        </Grid>
      </Grid>

      <Grid className={classes.space}></Grid>
      <Grid container direction="column">
        {/* <Grid item xs={2}>
          <Typography className={clsx(classes.bold)}>Giải thưởng</Typography>
        </Grid> */}
        <Grid item>
          {isEdit && (
            <Fragment>
              <Paper className={classes.wrapperPaper}>
                <BingoResultSettings
                  // bingoResults={bingoResults}
                  // setBingoResults={setBingoResults}
                  bingoSettings={bingoSettings}
                  setBingoSettings={setBingoSettings}
                  object={eventObj}
                  setObject={setEventObj}
                  classes={classes}
                  isEdit={true}
                  setRedeemEcoinEventSetting={setRedeemEcoinEventSetting}
                />
              </Paper>
              {/* <Paper className={classes.wrapperPaper}>
                <BingoResults
                  bingoSettings={bingoSettings}
                  bingoResults={bingoResults}
                  setBingoResults={setBingoResults}
                  classes={classes}
                  isEdit={true}
                />
              </Paper> */}
            </Fragment>
          )
            // : 
            // (
            //   <TableComponent
            //     align="center"
            //     data={eventCalendars}
            //     headMenu={headMenu}
            //     isPromotionRouter={true}
            //     // page={page}
            //     // setPage={setPage}
            //   />
            // )
          }
        </Grid>
      </Grid>

      <Grid container item justifyContent="flex-end" spacing={4}>
        {isEdit ? (
          <>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Check />}
                onClick={handleUpdate}
                disabled={submitDisabled}
              >
                Xác nhận
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="default"
                startIcon={<Cancel />}
                onClick={() => {
                  setIsEdit(false);
                  dataInital();
                }}
              >
                Hủy bỏ
              </Button>
            </Grid>
          </>
        ) : (
          <Button
            color="primary"
            startIcon={<Edit />}
            variant="contained"
            disabled={!checkedPermission(userInfo, accessObjectPermissions, UPDATE_PROMOTION)}
            onClick={() => {
              setIsEdit(true);
              setName(_.get(data, "name"));
            }}
          >
            Cập nhật
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default BingoGameDetail;
