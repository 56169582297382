import * as _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const COLOR = (type) => _.get(
    {
        waiting: '#0e7043',
        selling: '#0f295c',
        sold: '#cece06',
        hidden: '#f77b00',
        all: '#ff0000',
    }, type, '#ff0000');

const useStyles = makeStyles(theme => ({
    textStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '16px',
        color: '#0A2240',
        textAlign: 'center',
        justifyContent: 'flex-end',
        padding: '0 10px',
        cursor: 'pointer',
        '&.disable': {
            opacity: '0.5',
            cursor: 'unset',
        },
        '&:hover:not(.disable)': {
            opacity: '0.8',
            color: '#0f295c'
        },
        '& > .unavailable': {
            opacity: .3,
        }
    },
    buttonStyle: {
        height: '20px',
        width: '20px',
        padding: '10px',
        marginBottom: '20px',
        borderRadius: '50%',
        cursor: 'pointer',
        '&.online-type': {
            backgroundColor: COLOR('waiting')
        },
        '&.offline-type': {
            backgroundColor: COLOR('selling')
        },
        '&.task_blocked-type': {
            backgroundColor: COLOR('sold')
        },
        '&.blocked-type': {
            backgroundColor: COLOR('hidden')
        },
        '&.all-type': {
            backgroundColor: COLOR('all')
        },
    },
    inputStyle: {
        width: '80%',
        backgroundColor: 'white',
        marginLeft: '0',
        '& > div:before': {
            content: 'unset',
        },
        '& > div:after': {
            content: 'unset',
        },
    },
}));

const LABLE = (status) => _.get({
    'ONLINE': 'Online',
    'OFFLINE': 'Offline',
    'TASK_BLOCKED': 'Ngừng nhận nhiệm vụ',
    //'': 'Nghỉ phép',
    'BLOCKED': 'Khoá',
    'ALL': 'Xem tất cả',
}, status, '');

const FILTER = (code) => {
    return (
        _.get({
            'ONLINE': ['ONLINE'],
            'OFFLINE': ['OFFLINE'],
            'BLOCKED': ['BLOCKED'],
            'TASK_BLOCKED': ['TASK_BLOCKED'],
            'ALL': ['ONLINE','OFFLINE','BLOCKED','TASK_BLOCKED']        
        }, code, ['ONLINE','OFFLINE','BLOCKED','TASK_BLOCKED'])
    )
}

const ButtonStatusList = ({ handleClick, status, count = 0, selected=false }) => {
    const classes = useStyles();

    const handleClickFilter = () => {
        if (_.isFunction(handleClick)) handleClick(FILTER(status));
    }

    return (
        <div 
            className={clsx(classes.textStyle)}
            onClick={handleClickFilter}
        >
            <div className={clsx(classes.buttonStyle, _.toLower(status)+'-type')} />
            <div className={clsx(!selected && 'unavailable')}>{`${LABLE(status)} (${count})`}</div>
        </div>
    );
}

export default ButtonStatusList;
