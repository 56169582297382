import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';

// import table

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import data from './data/teamdata';

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650,
    },
    tableTh: {
        fontWeight: 'bold'
    },
    buttonContainer: {
        marginTop: 30,
        marginLeft: 'auto',
    },
    button: {
        borderRadius: 0,
        color: '#ffffff',
        padding: '5px 30px'
    }
}))

const EmployeeDetailTeam = () => {
    const theme = useTheme();
    const classes = useStyles();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const history = useHistory();
    const [openEdit, setOpenEdit] = useState(false);
    return (
        <Grid container direction="column" style={{paddingLeft: matchesSM ? '20px' : '15px', paddingTop: matchesSM ? '20px' : undefined}}>
            <Grid container direction={matchesMD ? 'column' : 'row'} alignItems="flex-start">
                <Grid item style={{paddingTop: '20px', paddingRight: '160px'}}>
                    <Grid container alignItems="center" direction="row" style={{marginBottom: '26px'}}>
                        <Grid item style={{width: '160px'}}>
                            <Typography className={classes.text} style={{fontWeight: 'bold'}}>Tên Nhóm: </Typography>
                        </Grid>
                        <Grid item className={classes.text}>
                            {openEdit ? (<TextField style={{backgroundColor: '#ffffff'}} value="Nhóm 1" variant="outlined" size="small" />) : 'Nhóm 1'}
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" direction="row" style={{marginBottom: '26px'}}>
                        <Grid item style={{width: '160px'}}>
                            <Typography className={classes.text} style={{fontWeight: 'bold'}}>Ngày tạo: </Typography>
                        </Grid>
                        <Grid item className={classes.text}>
                            {openEdit ? (<TextField style={{backgroundColor: '#ffffff'}} value="16/06/2021" variant="outlined" size="small" />) : '16/06/2021'}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{paddingTop: '20px'}}>
                    <Grid container alignItems="center" direction="row" style={{marginBottom: '26px'}}>
                        <Grid item style={{width: '160px'}}>
                            <Typography className={classes.text} style={{fontWeight: 'bold'}}>Mã Nhóm: </Typography>
                        </Grid>
                        <Grid item className={classes.text}>
                            {openEdit ? (<TextField style={{backgroundColor: '#ffffff'}} value="B000001-001" variant="outlined" size="small" />) : 'B000001-001'}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* Table */}
            <Grid item>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead style={{backgroundColor: '#FFEB9C'}}>
                            <TableRow>
                                <TableCell className={classes.tableTh} align="center">STT</TableCell>
                                <TableCell className={classes.tableTh} align="center">Mã NV</TableCell>
                                <TableCell className={classes.tableTh} align="center">Tên Nhân Viên</TableCell>
                                <TableCell className={classes.tableTh} align="center">Vai Trò</TableCell>
                                <TableCell className={classes.tableTh} align="center">Số Điện Thoại</TableCell>
                                <TableCell className={classes.tableTh} align="center">E-mail</TableCell>
                                <TableCell className={classes.tableTh} align="center">Ngày Tham Gia Nhóm</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={row.idEmployee}>
                                <TableCell align="center">{index}</TableCell>
                                <TableCell style={{textDecoration: 'underline'}} className={classes.tableTh} align="center">{row.idEmployee}</TableCell>
                                <TableCell align="center">{row.name}</TableCell>
                                <TableCell align="center">{row.role}</TableCell>
                                <TableCell align="center">{row.phone}</TableCell>
                                <TableCell align="center">{row.email}</TableCell>
                                <TableCell align="center">{row.startDay}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item className={classes.buttonContainer}>
                <Button style={{backgroundColor: '#2C8A81'}} className={classes.button} variant="contained">Thay Đổi</Button>
                <Button 
                    style={{backgroundColor: 'black', marginLeft: 20}} 
                    className={classes.button} 
                    onClick={() => history.push('/employee/detail')} 
                    variant="contained">
                        Quay lại
                </Button>
            </Grid>
        </Grid>
    )
}

export default EmployeeDetailTeam;
