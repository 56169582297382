import React, { Fragment, useState, useEffect, useContext } from 'react'
import {
    Grid, makeStyles, Typography, TextField, Button, IconButton,
    FormControl, FormGroup, FormControlLabel, Checkbox, CircularProgress
} from '@material-ui/core';
import { Check, Cancel, Edit, AddCircleOutline, Add } from "@material-ui/icons";
import clsx from 'clsx';
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import _ from 'lodash';
import { ReactComponent as AttachFile } from '../../../../assets/icons/game/attachFile.svg';
import { useGetListApplyType, useUpdateEvent } from "../../hooks";
import ReactPlayer from 'react-player';
import moment from "moment";
import { GET_PROFILE } from '../../../../graphql/schemas/account/accountQueries';
import axios from "axios";
import { red } from '@material-ui/core/colors';
import Upload from '../../components/Upload';
import { ConfigContext } from '../../../../context/ConfigContext';


const useStyles = makeStyles((theme) => ({
    wrapperDetail: {
        background: 'red',
        marginTop: '30px',
    },
    button: {
        color: '#fff',
    },
    bodyContainer: {
        backgroundColor: '#fff',
        borderRadius: 12,
        padding: 24,
        marginTop: 24
    },
    title: {
        fontWeight: 700,
        width: 200
    },
    space: {
        width: 10,
        height: 40
    },
    fileAttach: {
        width: 194,
        height: 40,
        backgroundColor: '#F5F5F5',
        color: "#00837B",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        borderRadius: '12px',
        cursor: 'pointer'
    },
    imgPicker: {
        display: 'inline-flex',
        alignItems: 'center',
        flexDirection: 'column-reverse',
        background: '#eee',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        cursor: 'pointer'
    },
    wapperLoading: {
        width: '100%',
        height: 'calc(100vh - 113px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const VoucherDetail = ({ data, loading }) => {
    const classes = useStyles();

    const { id } = useParams();
    const [isEdit, setIsEdit] = useState(false);
    const [name, setName] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [vouchers, setVouchers] = useState([]);
    const [documentUpload, setDocumentUpload] = useState([]);
    const [documentOriginal, setDocumentOriginal] = useState([]);
    const [documentRuleUpload, setDocumentRuleUpload] = useState([]);
    const [documentRuleOriginal, setDocumentRuleOriginal] = useState([]);
    const [videoUpload, setVideoUpload] = useState([]);
    const [videoOriginal, setVideoOriginal] = useState([]);
    const { data: listApplyType } = useGetListApplyType();
    const { updateEvent, data: updatedData } = useUpdateEvent(id, setIsEdit);
    const { loading: loadingGetProfile, data: createdBy } = useQuery(GET_PROFILE, {
        variables: { id: _.get(data, 'created_by') },
        skip: !_.get(data, 'created_by'),
    });

    const configValue = useContext(ConfigContext);

    const addVoucherField = () => {
        setVouchers([
            ...vouchers,
            {
                name: null,
                deadline_day: null,
                amount: null,
                start_time: null,
                end_time: null,
                prefix_code: null,
                postfix_code: null,
                quantity: null,
                apply_type: [..._.map(listApplyType, item => _.get(item, 'id'))],
                banner: null,
                description: null,
            },
        ]);
    };

    const handleChangeVoucherInput = async (index, event) => {
        const values = [...vouchers];

        if (event.target.name === "max_redeem") {
            values[index][event.target.name] = parseInt(event.target.value);
        }
        else if (event.target.name === "apply_type") {
            if (_.includes(values[index][event.target.name], Number(event.target.value))) {
                _.remove(values[index][event.target.name], (val) => val === Number(event.target.value))
            } else {
                values[index][event.target.name] = [...values[index][event.target.name], Number(event.target.value)];
            }
        }
        else if (event.target.name === "banner") {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append("file", file);
            formData.append("media_type_code", "PHOTO");
            try {
                const config = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                };
                const { data } = await axios.post(
                    configValue.API_UPLOAD_FILE,
                    formData,
                    config
                );
                values[index][event.target.name] = data[0];
                setVouchers(values);
            } catch (error) {
                console.log(error);
            }
        }
        else {
            values[index][event.target.name] = event.target.value;
        }
        setVouchers(values);
    };

    const handleUpdate = () => {
        let arg = {};
        arg.id = parseInt(id);
        if (!_.isEmpty(name)) arg.name = name;
        if (!_.isEmpty(endTime)) arg.end = endTime;

        arg.event_medias_update = [
            ..._.map(documentRuleUpload, item => ({ media: _.get(item, 'id') })),
            ..._.map(documentUpload, item => ({ media: _.get(item, 'id') })),
            ..._.map(videoUpload, item => ({ media: _.get(item, 'id') }))
        ];
        arg.bingo_result_daily = [];
        arg.bingo_result_settings_update = [];
        arg.event_gifts_update = [];
        arg.event_items_update = [];

        arg.event_vouchers_update = _.map(vouchers, (item) => ({
            name: _.get(item, 'name'),
            deadline_day: +_.get(item, 'deadline_day'),
            amount: +_.get(item, 'amount'),
            description: _.get(item, 'description') || null,
            start_time: moment(_.get(item, 'start_time')).utc().format("YYYY-MM-DD"),
            end_time: moment(_.get(item, 'end_time')).utc().format("YYYY-MM-DD"),
            prefix_code: _.get(item, 'prefix_code') || null,
            postfix_code: _.get(item, 'postfix_code') || null,
            quantity: +_.get(item, 'quantity'),
            apply_type: _.get(item, 'apply_type'),
            banner: _.get(item, 'banner.id') || null,
        }));

        updateEvent({
            variables: {
                arg,
            },
        });

        //window.location.reload();
    }

    useEffect(() => {
        let _documentOriginal = _.map(_.filter(_.get(data, 'event_medias'), ['media.media_type.code', 'DOCUMENT']), (item) => _.get(item, 'media'));
        let _documentRuleOriginal = _.map(_.filter(_.get(data, 'event_medias'), ['media.media_type.code', 'RULE_GAME']), (item) => _.get(item, 'media'));
        let _videoOriginal = _.map(_.filter(_.get(data, 'event_medias'), ['media.media_type.code', 'VIDEO']), (item) => _.get(item, 'media'));
        setDocumentOriginal(_documentOriginal);
        setDocumentRuleOriginal(_documentRuleOriginal);
        setVideoOriginal(_videoOriginal);

    }, []);

    if (loading) return (
        <div className={classes.wapperLoading}>
            <CircularProgress />
        </div>
    )

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            style={{ marginTop: 40 }}
        >
            <Grid container direction="row" spacing={2} >
                <Grid item xs={4} style={{ display: 'flex', width: '100%', alignItems: "center" }}>
                    <Typography className={clsx(classes.title)}>Tên chương trình: </Typography>
                    {isEdit ? (
                        <TextField
                            style={{ backgroundColor: "#ffffff", width: 250 }}
                            variant="outlined"
                            defaultValue={_.get(data, 'name')}
                            onChange={(e) => setName(e.target.value)}
                            size="small"
                            required
                        />
                    )
                        :
                        (
                            <Typography className={clsx(classes.marginLeftSpace)}>
                                {_.get(data, "name")}
                            </Typography>
                        )}
                </Grid>
                <Grid item xs={4} style={{ display: 'flex', width: '100%', alignItems: "center" }}>
                    <Typography className={clsx(classes.title)}>Mã chương trình: </Typography>
                    <Typography className={clsx(classes.marginLeftSpace)}>
                        {_.get(data, "code")}
                    </Typography>
                </Grid>
                <Grid item xs={4} style={{ display: 'flex', width: '100%', alignItems: "center" }}>
                    <Typography className={clsx(classes.title)}>Loại chương trình: </Typography>
                    <Typography className={clsx(classes.marginLeftSpace)}>
                        {_.get(data, "type_info.name")}
                    </Typography>
                </Grid>
            </Grid>

            {
                !isEdit && (
                    <Fragment>
                        <div className={clsx(classes.space)}></div>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={4}>
                                <div style={{ display: 'flex' }}>
                                    <Typography className={clsx(classes.title)}>Thời gian cập nhật: </Typography>
                                    <Typography className={clsx(classes.marginLeftSpace)}>
                                        {moment(_.get(data, "created")).format("DD/MM/yyyy")}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{ display: 'flex' }}>
                                    <Typography className={clsx(classes.title)}>Người tạo: </Typography>
                                    <Link
                                        to={`/employee/detail/${_.get(createdBy, "results.id")}`}
                                        className={clsx(classes.marginLeftSpace, classes.textUnderLine)}
                                    >
                                        {_.get(createdBy, "results.full_name")}
                                    </Link>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{ display: 'flex' }}>
                                    <Typography className={clsx(classes.title)}>Trạng thái: </Typography>
                                    <Typography className={clsx(classes.bold, classes.marginLeftSpace)}>
                                        {
                                            moment().isBefore(moment(_.get(data, "start")).format())
                                                ? "Sắp diễn ra"
                                                : moment().isAfter(moment(_.get(data, "end")).format())
                                                    ? "Kết thúc"
                                                    : "Đang diễn ra"
                                        }
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <div className={clsx(classes.space)}></div>
                        <Grid container direction="row">
                            <div style={{ display: 'flex' }}>
                                <Typography className={clsx(classes.title)}>Tổng số voucher đã tạo: </Typography>
                                <Typography>
                                    {_.get(data, "total_promotions.aggregate.count")}
                                </Typography>
                            </div>
                        </Grid>

                        <div className={clsx(classes.space)}></div>
                        <Grid container direction="row">
                            <Grid item xs={4}>
                                <div style={{ display: 'flex' }}>
                                    <Typography className={clsx(classes.title)}>Thời gian bắt đầu: </Typography>
                                    <Typography className={clsx(classes.marginLeftSpace)}>
                                        {moment(_.get(data, "start")).format("DD/MM/YYYY")}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{ display: 'flex' }}>
                                    <Typography className={clsx(classes.title)}>Thời gian kết thúc: </Typography>
                                    <Typography className={clsx(classes.marginLeftSpace)}>
                                        {moment(_.get(data, "end")).format("DD/MM/YYYY")}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Fragment>
                )
            }

            {
                isEdit && (
                    <Fragment>
                        <div className={clsx(classes.space)}></div>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={4} style={{ display: 'flex', width: '100%', alignItems: "center" }}>
                                <Typography className={clsx(classes.title)}>Thời gian bắt đầu: </Typography>
                                <Typography className={clsx(classes.marginLeftSpace)}>
                                    {moment(_.get(data, "start")).format("DD/MM/YYYY")}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} style={{ display: 'flex', width: '100%', alignItems: "center" }}>
                                <Typography className={clsx(classes.title)}>Thời gian kết thúc: </Typography>
                                <TextField
                                    style={{ backgroundColor: "#fff" }}
                                    size="small"
                                    variant="outlined"
                                    className={classes.input}
                                    defaultValue={moment(_.get(data, "end")).format("YYYY-MM-DD[T]HH:mm")}
                                    type="datetime-local"
                                    onChange={(e) => setEndTime(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={4} style={{ display: 'flex', width: '100%', alignItems: "center" }}>
                                <Typography className={clsx(classes.title)}>Trạng thái: </Typography>
                                <Typography className={clsx(classes.bold, classes.marginLeftSpace)}>
                                    {
                                        moment().isBefore(moment(_.get(data, "start")).format())
                                            ? "Sắp diễn ra"
                                            : moment().isAfter(moment(_.get(data, "end")).format())
                                                ? "Kết thúc"
                                                : "Đang diễn ra"
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                        <div className={clsx(classes.space)}></div>
                        <Grid container direction="row">
                            <Typography className={clsx(classes.title)}>Voucher</Typography>
                        </Grid>

                        <Grid container direction="row">
                            {
                                _.map(vouchers, (item, key) => (
                                    <Grid
                                        item
                                        container
                                        key={key}
                                        alignItems="center"
                                        spacing={2}
                                        className={classes.itemRow}
                                    >
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center">
                                                <p className={classes.textLabelForm}>{`Voucher ${key + 1}`}</p>
                                            </Grid>
                                        </Grid>

                                        <Grid item container xs={4} alignItems="center">
                                            <Grid item className={classes.textLabelForm} md={4}>
                                                <p>Tên voucher<span style={{ color: "red" }}>*</span></p>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    className={classes.input}
                                                    required
                                                    value={_.get(item, 'name')}
                                                    name="name"
                                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item container md={4} alignItems="center">
                                            <Grid item xs={4}>
                                                <p className={classes.textLabelForm}>Giá trị (VND)<span style={{ color: "red" }}>*</span>
                                                </p>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    type="number"
                                                    size="small"
                                                    variant="outlined"
                                                    className={classes.input}
                                                    required
                                                    value={_.get(item, 'amount')}
                                                    name="amount"
                                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item container md={4} alignItems="center">
                                            <Grid item className={classes.textLabelForm} xs={4}>
                                                <p>Hạn sử dụng<span style={{ color: "red" }}>*</span></p>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    size="small"
                                                    variant="outlined"
                                                    className={classes.input}
                                                    value={_.get(item, 'deadline_day')}
                                                    name="deadline_day"
                                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <p style={{ color: "#aaa", paddingLeft: '15px' }}>Ngày</p>
                                            </Grid>
                                        </Grid>

                                        <Grid item container md={4} alignItems="center">
                                            <Grid item className={classes.textLabelForm} xs={4}>
                                                <p>Tiền tố</p>
                                            </Grid>
                                            <Grid item xs={8} style={{ position: 'relative' }}>
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    className={classes.input}
                                                    value={_.get(item, 'prefix_code')}
                                                    name="prefix_code"
                                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                                />
                                                <p style={{ position: 'absolute', top: '30px', color: '#999', userSelect: 'none' }}>Ví dụ: EKM21001-XXXXX</p>
                                            </Grid>
                                        </Grid>

                                        <Grid item container md={4} alignItems="center">
                                            <Grid item className={classes.textLabelForm} xs={4}>
                                                <p>Hậu tố</p>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    className={classes.input}
                                                    value={_.get(item, 'postfix_code')}
                                                    name="postfix_code"
                                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item container md={4} alignItems="center">
                                            <Grid item className={classes.textLabelForm} xs={4}>
                                                <p>Số lượng<span style={{ color: "red" }}>*</span></p>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    type="number"
                                                    size="small"
                                                    variant="outlined"
                                                    className={classes.input}
                                                    value={_.get(item, 'quantity')}
                                                    name="quantity"
                                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item container md={12} alignItems="center">
                                            <Grid item className={classes.textLabelForm} xs={2}>
                                                <p>Sản phẩm áp dụng<span style={{ color: "red" }}>*</span></p>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <FormControl component="fieldset" className={classes.formControl}>
                                                    <FormGroup row >
                                                        {_.map(listApplyType, (item, index) => (
                                                            <FormControlLabel
                                                                key={index}
                                                                name="apply_type"
                                                                onChange={(e) => handleChangeVoucherInput(key, e)}
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        value={_.get(item, 'id')}
                                                                        checked={_.includes(_.get(vouchers[key], 'apply_type'), _.get(item, 'id'))}
                                                                    />
                                                                }
                                                                label={<span style={{ fontSize: '16px' }}>{_.get(item, 'name')}</span>}
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid item container xs={4} alignItems="center">
                                            <Grid item xs={4} className={classes.textLabelForm}>
                                                <p>
                                                    Thời gian bắt đầu<span style={{ color: "red" }}>*</span>
                                                </p>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    className={classes.input}
                                                    value={_.get(item, 'start_time')}
                                                    name="start_time"
                                                    type="datetime-local"
                                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item container alignItems="center" xs={4}>
                                            <Grid item xs={4} className={classes.textLabelForm}>
                                                <p>
                                                    Thời gian kết thúc<span style={{ color: "red" }}>*</span>
                                                </p>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    className={classes.input}
                                                    value={_.get(item, 'end_time')}
                                                    name="end_time"
                                                    type="datetime-local"
                                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item container md={4} alignItems="center">
                                            <Grid xs={4} item className={classes.textLabelForm}>
                                                <p>
                                                    Mô tả<span style={{ color: "red" }}>*</span>
                                                </p>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    name="description"
                                                    className={classes.input}
                                                    value={_.get(item, 'description')}
                                                    onChange={(e) => handleChangeVoucherInput(key, e)}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item container alignItems="center">
                                            <Grid item container xs={4} alignItems="center">
                                                <Grid item xs={4} className={classes.textLabelForm}>
                                                    <p>
                                                        Hình ảnh<span style={{ color: "red" }}>*</span>
                                                    </p>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {_.isEmpty(_.get(item, "banner.url")) ? (
                                                        <>
                                                            <input
                                                                id={`banner-voucher-${key}`}
                                                                hidden
                                                                type="file"
                                                                name="banner"
                                                                accept="image/*"
                                                                multiple
                                                                className={classes.inputStyle}
                                                                onChange={(e) => handleChangeVoucherInput(key, e)}
                                                            />
                                                            <label
                                                                htmlFor={`banner-voucher-${key}`}
                                                                className={classes.imgPicker}
                                                            >
                                                                Thêm ảnh <Add />
                                                            </label>{" "}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <input
                                                                id={`banner-voucher-${key}`}
                                                                hidden
                                                                type="file"
                                                                name="banner"
                                                                accept="image/*"
                                                                multiple
                                                                className={classes.inputStyle}
                                                                onChange={(e) => handleChangeVoucherInput(key, e)}
                                                            />
                                                            <label
                                                                htmlFor={`banner-voucher-${key}`}
                                                                className={classes.imgPicker}
                                                            >
                                                                <img
                                                                    style={{ height: 100, width: 100 }}
                                                                    alt="icon"
                                                                    src={configValue.MEDIA_DOMAIN + _.get(item, 'banner.url')}
                                                                />
                                                            </label>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>

                        <Grid item>
                            <IconButton onClick={addVoucherField}>
                                <AddCircleOutline style={{ fill: "blue" }} /><span style={{ paddingLeft: "10px" }}>Thêm mới Voucher</span>
                            </IconButton>
                        </Grid>
                    </Fragment>
                )
            }

            <div className={clsx(classes.space)}></div>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item style={{ width: 120 }}>
                            <Typography className={clsx(classes.title)}>Thể lệ:</Typography>
                            <Typography style={{ color: '#999' }}>Tối đa 1 file</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={clsx(classes.marginLeftSpace)}>
                                {
                                    !isEdit && _.map(_.get(data, "event_medias"), (item, key) => {
                                        if (item.media?.media_type.code === "RULE_GAME") {
                                            return (
                                                <Grid item key={key}>
                                                    <a
                                                        href={configValue.MEDIA_DOMAIN + _.get(item, "media.url")}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {_.get(item, "media.name")}
                                                    </a>
                                                </Grid>
                                            );
                                        }
                                    })
                                }

                                {isEdit && (
                                    <Grid item>
                                        <Upload
                                            type="RULE_GAME"
                                            uploadData={documentRuleOriginal}
                                            setUploadData={setDocumentRuleOriginal}
                                            limit={1}
                                            id={`upload-rule${id}`}
                                            fileChange={documentRuleUpload}
                                            setFileChange={setDocumentRuleUpload}
                                        />
                                    </Grid>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={4}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item style={{ width: 120 }}>
                            <Typography className={clsx(classes.title)}>Hướng dẫn:</Typography>
                            <Typography style={{ color: '#999' }}>Tối đa 1 file</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={clsx(classes.marginLeftSpace)}>
                                {
                                    !isEdit && _.map(_.get(data, "event_medias"), (item, key) => {
                                        if (item.media?.media_type.code === "DOCUMENT") {
                                            return (
                                                <Grid item key={key}>
                                                    <a
                                                        href={configValue.MEDIA_DOMAIN + _.get(item, "media.url")}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {_.get(item, "media.name")}
                                                    </a>
                                                </Grid>
                                            );
                                        }
                                    })
                                }

                                {isEdit && (
                                    <Grid item>
                                        <Upload
                                            type="DOCUMENT"
                                            uploadData={documentOriginal}
                                            setUploadData={setDocumentOriginal}
                                            limit={1}
                                            id={`upload-doc${id}`}
                                            fileChange={documentUpload}
                                            setFileChange={setDocumentUpload}
                                        />
                                    </Grid>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

            <div className={clsx(classes.space)}></div>
            <Grid container direction="row" spacing={2}>
                <Grid item style={{ width: 120 }}>
                    <Typography className={clsx(classes.title)}>Video:</Typography>
                    <Typography style={{ color: '#999' }}>Tối đa 5 file</Typography>
                </Grid>
                <Grid item>
                    <Typography className={clsx(classes.marginLeftSpace)}>
                        {
                            !isEdit && _.map(_.get(data, "event_medias"), (item, key) => {
                                if (item.media?.media_type.code === "VIDEO") {
                                    return (
                                        <Grid item key={key}>
                                            <a
                                                href={configValue.MEDIA_DOMAIN + _.get(item, "media.url")}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {_.get(item, "media.name")}
                                            </a>
                                        </Grid>
                                    );
                                }
                            })
                        }

                        {isEdit && (
                            <Grid item>
                                <Upload
                                    type="VIDEO"
                                    uploadData={videoOriginal}
                                    setUploadData={setVideoOriginal}
                                    limit={5}
                                    id={`upload-video${id}`}
                                    fileChange={videoUpload}
                                    setFileChange={setVideoUpload}
                                />
                            </Grid>
                        )}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container item justifyContent="flex-end" spacing={4}>
                {isEdit ? (
                    <>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Check />}
                                onClick={handleUpdate}
                            >
                                Xác nhận
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<Cancel />}
                                onClick={() => setIsEdit(false)}
                            >
                                Hủy bỏ
                            </Button>
                        </Grid>
                    </>
                ) : (
                    <Button
                        color="primary"
                        startIcon={<Edit />}
                        variant="contained"
                        onClick={() => setIsEdit(true)}
                    >
                        Cập nhật
                    </Button>
                )}
            </Grid>
        </Grid>
    )
}

export default VoucherDetail
