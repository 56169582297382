import {
  Button,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  ArrowDropDown,
  ArrowDropUp,
  FilterListOutlined,
} from "@material-ui/icons";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "../FilterStyles";

const HeaderFilter = ({ children, title, onSubmit }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(false);
    onSubmit();
  };

  return (
    <Grid item container className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      <Grid item className={classes.wrapper}>
        <List
          component="nav"
          className={clsx(classes.list, open && classes.shadow)}
          disablePadding
          dense
        >
          <ListItem
            button
            onClick={() => setOpen(!open)}
            className={clsx(
              classes.item,
              open ? classes.active : classes.nonActive
            )}
          >
            <FilterListOutlined />
            <ListItemText primary="Bộ lọc" style={{ paddingLeft: 11 }} />
            {open ? <ArrowDropUp /> : <ArrowDropDown />}
          </ListItem>
          <Collapse in={open} timeout="auto" className={classes.collapse}>
            <List component="div" disablePadding className={classes.content}>
              {children}
            </List>
            <ListItem>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={handleClick}
              >
                Áp dụng
              </Button>
            </ListItem>
          </Collapse>
        </List>
      </Grid>
    </Grid>
  );
};

export default HeaderFilter;
