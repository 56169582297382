import * as _ from 'lodash';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import ScrollableTabs from '../../../components/ScrollableTabs';
import InfoProductPrimary from './InfoProductPrimary'
import CartContent from './Cart/CartContent'
import ApprovalHistory from './AprovalHistory';
import TransactionHistory from './TransactionHistory';
import { PROJECT_GET_ONE } from '../../../graphql/schemas/product/projectQueries';
import { useQuery } from '@apollo/client';

import { StoreContext } from '../../../resources/store';
import { USER_ROLE_SUPPER_ADMIN } from '../../../constants/defined';
import { checkedPermission } from '../../../helper';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '30px',
        backgroundColor: '#FCFFFF',
        display: 'block',
        overflow: 'hidden'
    },
    actionStyle: {
        display: 'grid',
        gridTemplate: 'auto / auto auto',
        justifyContent: 'flex-end',
        textAlign: 'right',
        '& button': {
            margin: '0 15px',
        },
    },

}));

const ProductDetailPrimary = (props) => {
    const classes = useStyles();

    const { id } = useParams();

    const { accessObjectPermissions, userInfo } = useContext(StoreContext);

    const { loading, error, data } = useQuery(PROJECT_GET_ONE, {
        variables: {
            id
        },
        fetchPolicy: 'cache-and-network'
    });

    const LISTS = [
        { value: 0, navigationTitle: 'Thông tin sản phẩm', content: (props) => <InfoProductPrimary error={error} loading={loading} data={data} {...props} />, active: "detailProduct" },
        { value: 1, navigationTitle: 'Thông tin giỏ hàng', content: (props) => <CartContent {...props} />, active: "cartContent" },
        { value: 2, navigationTitle: 'Lịch sử cập nhật', content: (props) => <ApprovalHistory {...props} />, active: "approvalHistory" },
        { value: 3, navigationTitle: 'Lịch sử giao dịch', content: (props) => <TransactionHistory {...props} />, active: "transactionHistory" },
    ]

    return (
        <>
            <Paper className={classes.root}>
                <ScrollableTabs {...props} list={LISTS} />
            </Paper>

        </>

    )
}

export default ProductDetailPrimary;
