import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const datas = [
  {
    id: 1,
    data: 2150,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 0,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 2762,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 3850,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 2510,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 1575,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 1575,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 2150,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 0,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 2762,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 3850,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 2510,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 1575,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 1575,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 2150,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 0,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 2762,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 0,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 2510,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 1575,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 1575,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 2150,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 0,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 2762,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 3850,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 2510,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 1575,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 1575,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 2150,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 0,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 2762,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 3850,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 2510,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 1575,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 1575,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 2150,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 0,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 2762,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 3850,
    date: "1/1/2022",
  },
  {
    id: 1,
    data: 2510,
    date: "1/1/2022",
  },
];

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
    datalabels: {
      display: true,
      color: "#fff",
      font: {
        weight: "400",
      },
    },
  },
  scales: {
    x: {
      grid: {
        drawOnChartArea: false,
        display: true,
        borderWidth: 1,
        borderColor: "#000",
      },
      stacked: true,
    },
    y: {
      grid: {
        borderWidth: 1,
        drawBorder: false,
      },
      stacked: true,
    },
  },
  maintainAspectRatio: false,
};

export const data = {
  labels: datas.map((item) => item.date),
  datasets: [
    {
      label: "Từ chối kiểm duyệt",
      data: datas.map((item) => item.data),
      backgroundColor: "#0A2240",
    },
    {
      label: "Phê duyệt lại",
      data: datas.map((item) => item.data),
      backgroundColor: "#3EC9B9",
    },
    {
      label: "Timeout",
      data: datas.map((item) => item.data),
      backgroundColor: "#707070",
    },
   
  ],
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.background.paper,
  },
  bar: {
    margin: theme.spacing(3),
  },
}));

function QuantityChart() {
  const classes = useStyles();

  return (
    <Grid item container className={classes.wrapper}>
      <Bar height={500} options={options} data={data} className={classes.bar} />
    </Grid>
  );
}

export default QuantityChart;
