import { Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { ReactComponent as Checked } from "../../../assets/icons/checked.svg";
import { ReactComponent as UnCheck } from "../../../assets/icons/uncheck.svg";
import { useStyles } from "../FilterStyles";

const ListCheck = ({
  headerTitle,
  data,
  open,
  setOpen,
  field,
  checkAllTitle,
  setFilterType,
  setIsSelectAll = () => {},
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);

  useLayoutEffect(() => {
    setCheckedAll(checked.length === data?.length);
    setIsSelectAll((prev) => {
      return {
        ...prev,
        [field]: checked.length === data?.length,
      };
    });
  }, [checked, data]);

  useEffect(() => {
    setChecked(data);
    setFilterType((prev) => {
      return {
        ...prev,
        [field]: data.map((item) => item.id),
      };
    });
  }, [data]);

  const toggleAll = () => {
    setChecked(checkedAll ? [] : data);
    setCheckedAll(!checkedAll);
    setIsSelectAll((prev) => {
      return {
        ...prev,
        [field]: !checkedAll,
      };
    });
    setFilterType((prev) => {
      return {
        ...prev,
        [field]: checkedAll ? [] : data.map((item) => item.id),
      };
    });
  };
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    setFilterType((prev) => {
      return {
        ...prev,
        [field]: newChecked.map((item) => item.id),
      };
    });
  };

  return (
    <Fragment>
      <ListItem
        button
        onClick={() => {
          if (field) {
            setOpen((prev) => ({ ...prev, [field]: !open }));
            return;
          }
          setOpen(!open);
        }}
      >
        <ListItemText primary={headerTitle} className={classes.subHeader} />
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding className={classes.subList}>
          <ListItem button onClick={toggleAll}>
            <span className={classes.checkBtn}>
              {checkedAll ? <Checked /> : <UnCheck />}
            </span>
            <ListItemText primary={checkAllTitle} />
          </ListItem>
          {data?.map((value, index) => (
            <ListItem key={value.id} button onClick={handleToggle(value)}>
              <span className={classes.checkBtn}>
                {checked.indexOf(value) !== -1 ? <Checked /> : <UnCheck />}
              </span>
              <ListItemText id={index} primary={value.name} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Fragment>
  );
};

export default ListCheck;
