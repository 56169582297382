import { Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import SelectComponent from '../../pages/Customer/components/SelectComponent'
import TableComponent from '../TableComponent';

import { useGetGiftTypes, useGetListGiftByAccount, useGetListVoucher, useGetListVoucherByAccount } from '../../pages/Promotion/hooks';
import _ from 'lodash';

const RewardList = ({ accountId, wallets }) => {

    const headMenuEcoin = [
        { id: 'game', numeric: false, disablePadding: false, label: 'Game' },
        { id: 'rewardName', numeric: false, disablePadding: false, label: 'Tên giải thưởng' },
        { id: 'idRewardName', numeric: false, disablePadding: false, label: 'ID Loại giải thưởng' },
        { id: 'timeExchange', numeric: false, disablePadding: false, label: 'Thời gian giao dịch' },
        { id: 'HSD', numeric: false, disablePadding: false, label: 'HSD' },
        { id: 'dayChangeReWard', numeric: false, disablePadding: false, label: 'NGÀY ĐỔI QUÀ' },
    ];

    const headMenuVoucher = [
        { id: 'game', numeric: false, disablePadding: false, label: 'Game' },
        { id: 'idVoucher', numeric: false, disablePadding: false, label: 'ID Voucher' },
        { id: 'valueMoney', numeric: false, disablePadding: false, label: 'Giá trị (VND)' },
        { id: 'timeExchange', numeric: false, disablePadding: false, label: 'Thời gian giao dịch' },
        { id: 'HSD', numeric: false, disablePadding: false, label: 'HSD' },
        { id: 'dayChangeReWard', numeric: false, disablePadding: false, label: 'NGÀY ĐỔI QUÀ' },
        { id: 'personChange', numeric: false, disablePadding: false, label: 'NGƯỜI ĐỔI' },
        { id: 'applyProduct', numeric: false, disablePadding: false, label: 'SP ÁP DỤNG' },
    ]

    const { data: typesList } = useGetGiftTypes();
  

    const [typeCode, setTypeCode] = useState(window.localStorage.getItem('typeRewardCode') || "GIFT");

    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0);


    const { data: voucherList } = useGetListVoucherByAccount(accountId, skip, 10);

    const { data: giftList } = useGetListGiftByAccount(accountId, skip, 10);


    const handleSelectedChange = (code) => {
        window.localStorage.setItem('typeRewardCode', code)
        setTypeCode(code)
    }

    const voucherData = _.map(_.get(voucherList, 'list_voucher'), (obj) => {
        return {
            game: _.get(obj, 'event_info.eventByEvent.name'),
            idVoucher: _.get(obj, 'code'),
            valueMoney: _.get(obj, 'amount'),
            timeExchange: !_.isEmpty(_.get(obj, 'created')) ? moment(_.get(obj, 'created')).format('DD/MM/YYYY') : '-',
            HSD: !_.isEmpty(_.get(obj, 'end_time')) ? moment(_.get(obj, 'end_time')).format('DD/MM/YYYY') : '-',
            dayChangeReWard:  !_.isEmpty(_.get(obj, 'exchange_date')) ? moment(_.get(obj, 'exchange_date')).format('DD/MM/YYYY') : '-',
            personChange: _.get(obj, 'exchange_personalid') || '-',
            applyProduct: _.get(obj, 'exchange_product') || '-'
        }
    })
  
    const ecoinData = _.map(_.get(giftList, 'list_gift'), (obj) => {
        return {
            game: _.get(obj, 'accountGiftByAccountGift.eventByEvent.name'),
            rewardName: _.get(obj, 'accountGiftByAccountGift.giftByGift.name'),
            idRewardName: _.get(obj, 'accountGiftByAccountGift.giftByGift.code'),
            timeExchange: !_.isEmpty(_.get(obj, 'created')) ? moment(_.get(obj, 'created')).format('DD/MM/YYYY HH:mm:ss A') : '-',
            HSD: !_.isEmpty(_.get(obj, 'end_time')) ? moment(_.get(obj, 'end_time')).format('DD/MM/YYYY') : '-',
            dayChangeReWard: !_.isEmpty(_.get(obj, 'exchange_date')) ? moment(_.get(obj, 'exchange_date')).format('DD/MM/YYYY HH:mm:ss A') : '-',
        }
    });

    const countVoucher = _.get(voucherList, 'total_voucher.aggregate.count');
    const countGift = _.get(giftList, 't.aggregate.count');

    const [dataList, setDataList] = useState({
        headMenu: headMenuEcoin,
        dataList: ecoinData,
        count: countGift,
        sumCoin: _.get(giftList, 'total_ecoin.aggregate.sum.ecoin') + _.get(giftList, 'total_ecoin.aggregate.sum.room_ecoin'),
        totalExchange: _.get(giftList, 'total_exchange.aggregate.count'),
        totalFriend: _.get(giftList, 'account_info[0].total_friend.aggregate.count')
    });

    useEffect(() => {
        if(typeCode === "GIFT") {
            setDataList(
                (prev) => ({
                     ...prev, 
                    dataList: ecoinData, 
                    headMenu: headMenuEcoin, 
                    count: countGift, 
                    sumCoin: _.get(giftList, 'total_ecoin.aggregate.sum.ecoin') + _.get(giftList, 'total_ecoin.aggregate.sum.room_ecoin'),
                    totalExchange: _.get(giftList, 'total_exchange.aggregate.count'),
                    totalFriend: _.get(giftList, 'account_info[0].total_friend.aggregate.count')
                }))
        }

        if(typeCode === "VOUCHER") {
            setDataList(
                (prev) => ({ 
                    ...prev, 
                    dataList: voucherData, 
                    headMenu: headMenuVoucher, 
                    count: countVoucher, 
                    sumCoin: _.get(voucherList, 'total_ecoin.aggregate.sum.ecoin') + _.get(voucherList, 'total_ecoin.aggregate.sum.room_ecoin'),
                    totalExchange: _.get(voucherList, 'total_exchange.aggregate.count'),
                    totalFriend: _.get(voucherList, 'account_info[0].total_friend.aggregate.count')
                }))
        }
    }, [typeCode, voucherList, giftList])

    return (
        <Grid container direction='column'>
            <Grid container direction='row' style={{ marginTop: 20 }} alignItems="center">
                <Grid item xs={3}>
                    <SelectComponent
                        handleChange={(e) => handleSelectedChange(e.target.value)}
                        fieldTitle="name"
                        fieldValue="code"
                        widthTitle={140}
                        defaultTitle="Chọn giải thưởng"
                        defaultValue={0}
                        disabledDefault={true}
                        value={typeCode}
                        listData={typesList}
                        width={220}
                        title="Loại giải thưởng"
                        alignItems="center"
                        margin="0px 10px"
                    />
                </Grid>

                <Grid item xs={2}>
                    <Typography>Số coin đang có: <span>{_.get(dataList, 'sumCoin')}</span></Typography>
                </Grid>
               
                <Grid item xs={2}>
                    <Typography>Số coin còn lại: <span>{_.get(wallets[0], 'amount') || 0}</span></Typography>
                </Grid>

                <Grid item xs={2}>
                    <Typography>Số coin đã đổi: <span>{_.get(dataList, 'totalExchange')}</span></Typography>
                </Grid>

                <Grid item xs={2}>
                    <Typography>Số bạn bè đã mời: <span>{_.get(dataList, 'totalFriend')}</span></Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{ marginTop: 20 }}>
                <TableComponent 
                    isPromotionRouter={true} 
                    pagination={_.size(dataList.dataList) > 0 ? true : false} 
                    setPage={setPage} 
                    setSkip={(skip) => setSkip(skip)} 
                    count={dataList.count} 
                    data={dataList.dataList} 
                    headMenu={dataList.headMenu} 
                    page={page}
                />
            </Grid>
        </Grid>
    )
}

export default RewardList;
