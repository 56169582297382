import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import * as _ from "lodash";
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    plus: {
        paddingLeft: "10px",
        paddingTop: "10px",
        color: "green"
    },
    remove: {
        paddingLeft: "10px",
        paddingTop: "10px",
        color: "red"
    },
    mgBottom: {
        marginBottom: "20px",
        display: "flex"
    }
}));

InputField.propTypes = {

};

function InputField({ limit, object, fieldname, setObject }) {
    const classes = useStyles();
    const items = limit ? limit : 0;
    const [inputList, setInputList] = useState([{ id: 0 }]);

    const [inputValue, setInputValue] = useState(_.get(object, fieldname));

    const onBlurSetValue = (event, key) => {
        let oldValue = _.get(object, fieldname);
        const value = _.trim(_.get(event, 'target.value'));
        let tempObject = { ...object };
        if(oldValue !==null && oldValue.length >1 && key<=oldValue.length) {
            if(key === oldValue.length) {
                _.set(tempObject, fieldname, _.concat(oldValue, value));
                setObject(tempObject)
            } else {
                let update = _.map(oldValue, (item, index) => {
                    if(index === key) {
                        item = value;
                    }
                    return item;
                });
                _.set(tempObject, fieldname, update);
                setObject(tempObject) 
            }
            
        } else {
            if (_.isEmpty(value) || _.get(object, fieldname) === value) return;
            let newValue = (oldValue == null && key===0 || oldValue.length == 1 && key==0)? [value] : _.concat(oldValue, value);
            _.set(tempObject, fieldname, newValue);
            setObject(tempObject)
        }   
    };
    const onchangeAction = (e) => {
        setInputValue(_.get(e, 'target.value'));
    };

    useEffect(() => {
        setInputValue(_.get(object, fieldname));
    }, [object])

    return (
        <Grid container xs={12}>
            <Grid item xs={8}>
                {_.map(inputList, (item, key) =>
                    <div key={key} className={classes.mgBottom}>
                        <TextField variant="outlined" fullWidth id={`input-textfield-${fieldname}-${key}`}
                            onBlur={(e) => onBlurSetValue(e, key)}
                            onChange={onchangeAction} />                       
                    </div>
                )}

            </Grid>
            <Grid item xs={2}>
                {
                    inputList.length < items && (
                        <div
                            className={classes.plus}
                            onClick={() => {
                                let list = inputList;
                                list = _.concat(list, { id: _.random(0, 1000) });
                                setInputList(list);
                            }}
                        >
                            <AddCircleIcon fontSize='large' />
                        </div>
                    )
                }
               
            </Grid>
        </Grid>


    );
};

export default InputField;