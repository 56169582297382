import * as _ from 'lodash';
import { useQuery } from '@apollo/client';
import {
    GET_APP_CONFIG,
    GET_FURNITURES,
    GET_ORIENTATION,
    GET_PRODUCT_PLAN_STATUS,
    GET_PROPERTY_TYPES,
    GET_UTILITIES,
    GET_WORKFLOW_NEXT_STEP
} from './masterdata/masterDataQueries';

import {
    GET_ALL_SHORT_PROJECTS
} from './product/projectQueries';

import {
    GET_ALL_SHORT_CUSTOMER,
    GET_CUSTOMER_BY_CODE
} from './customer/customerQueries';

import {
    GET_EMPLOYEE_BY_ACCOUNT
} from './employee/employeeQueries';
import { GET_DETAIL_PRODUCT_FOR_EDIT, PRODUCT_FIND_ONE } from './product/productQueries';
import { GET_NEXT_CART_STATUS, GET_PROJECT_CARTS } from './cart/cartQueries';
import { GET_BOOKING_STATUS, GET_PRODUCTS_BY_CART } from './booking/bookingQueries';


export const usePropertyTypes = () => {
    const { data } = useQuery(GET_PROPERTY_TYPES, {});
    return _.get(data, 'results');
};

export const useUtilities = () => {
    const { data } = useQuery(GET_UTILITIES, {});
    return _.get(data, 'results');
};
export const useFurnitures = () => {
    const { data } = useQuery(GET_FURNITURES, {});
    return _.get(data, 'results');
};

export const useOrientation = () => {
    const { data } = useQuery(GET_ORIENTATION, {});
    return _.get(data, 'results');
};

export const usePrjects = () => {
    const { data, loading, error } = useQuery(GET_ALL_SHORT_PROJECTS, {
        variables: {
            skip: 0,
            take: 1e9
        }
    });
    return {
        data: _.get(data, 'results'),
        loading,
        error
    };
};

export const useGetCustomerByCode = (customerCode) => {
    const { data } = useQuery(GET_CUSTOMER_BY_CODE, {
        variables: {
            skip: 0,
            take: 10,
            where: {
                accountByAccount: {
                    code: {
                        _like: `%${_.upperCase(customerCode)}%`
                    }
                }
            }
        },
        skip: !customerCode,
    });
    return _.get(data, 'results');
};

export const useGetAllShortCustomer = () => {
    const { data, loading, error } = useQuery(GET_ALL_SHORT_CUSTOMER, {
        variables: {
            skip: 0,
            take: 1e9,
        }
    });
    return {
        data: _.get(data, 'results'),
        loading,
        error
    };
};

export const useGetEmployeeByAccount = (accountId) => {
    const { data, loading, error } = useQuery(GET_EMPLOYEE_BY_ACCOUNT, {
        variables: {
            id: accountId
        },
        skip: !accountId,
    });
    return {
        employee: _.head(_.get(data, 'results')),
        loading,
        error
    }
};

export const useGetProductForEdit = (id) => {
    const { data, loading } = useQuery(GET_DETAIL_PRODUCT_FOR_EDIT, {
        variables: {
            productId: id
        },
        skip: !id
    });

    return {
        data: { ..._.get(_.head(_.get(data, 'request')), 'product'), customer: _.get(_.head(_.get(data, 'request')), 'customer') },
        loading
    }
}

export const useGetProductDetail = ({ productId }) => {
    const { data, error, loading, refetch } = useQuery(PRODUCT_FIND_ONE, {
        variables: {
            productId
        },
        skip: !productId
    });
    const headRequest = _.head(_.get(data, 'request'));
    return {
        data: {
            ..._.get(headRequest, 'product'),
            customer: _.get(headRequest, 'customer'),
            requests: _.get(headRequest, 'requests'),
            employee: _.get(headRequest, 'employee'),
            product_media: _.get(headRequest, 'product_request_attachments')
        },
        loading, error, refetch
    }
}

export const useGetWorkflowNextStep = ({ id, isSkip = false }) => {
    const { data, loading, error } = useQuery(GET_WORKFLOW_NEXT_STEP, {
        variables: {
            id
        },
        skip: !id || isSkip
    });

    return { data, loading, error }
};

export const useGetProjectCarts = ({ project }) => {
    const { data, loading, error } = useQuery(GET_PROJECT_CARTS, {
        variables: {
            skip: 0,
            take: 1000,
            order_by: {
                created: 'desc'
            },
            project
        },
        fetchPolicy: 'cache-and-network',
        skip: !project
    });

    return { data, loading, error }
};

export const useGetBookingStatus = () => {
    const { data, loading, error } = useQuery(GET_BOOKING_STATUS, {});
    return { data, loading, error }
};

export const useGetProductsByCart = ({ cartId, searchText = "" }) => {
    const { data, loading, error } = useQuery(GET_PRODUCTS_BY_CART, {
        variables: {
            cartId,
            search: `%${searchText}%`
        },
        skip: !cartId
    });
    return { data, loading, error };
};

export const useGetNextCartStatus = ({ id }) => {
    const { data, loading, error, refetch } = useQuery(GET_NEXT_CART_STATUS, {
        variables: { id },
        skip: !id
    });
    return { data, loading, error, refetch };
};

export const useProductPlanMapStatus = () => {
    const { data, loading, error } = useQuery(GET_PRODUCT_PLAN_STATUS);
    return {
        loading, error, data: _.get(data, 'result')
    }
};

export const useGetAppConfig = () => {
    const { data } = useQuery(GET_APP_CONFIG);

    return _.get(data, 'appconfig')
}