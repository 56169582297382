import { useMutation, useQuery } from '@apollo/client';
import { Button, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Save, Update } from '@material-ui/icons';
import clsx from 'clsx';
import * as _ from 'lodash';
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import ButtonReturn from '../../../components/ButtonReturn';

import { usePrjects } from '../../../graphql/schemas/hooks';
import { useDistricts, useProjectStatus, useProvinces, useStreets, useWards } from '../../../graphql/schemas/masterdata/address';
import { GET_UTILITIES } from '../../../graphql/schemas/masterdata/masterDataQueries';
import { UPDATE_PRIMARY_PRODUCT } from '../../../graphql/schemas/product/mutations';
import { GET_PROJECT_TYPES, PROJECT_GET_ALL, PROJECT_GET_ONE } from '../../../graphql/schemas/product/projectQueries';
import { checkedPermission, jsUcfirst } from '../../../helper';
import slugs from '../../../resources/slugs';
import { StoreContext } from '../../../resources/store';
import ImagePicker from '../Secondary/components/ImagePicker';
import InputDropdown from '../Secondary/components/InputDropdown';
import InputTextField from '../Secondary/components/InputTextField';
import InputTextFieldSearch from '../Secondary/components/InputTextFieldSearch';
import SelectUtilities from '../Secondary/components/SelectUtilities';
import Investor from './Investor';
import { primary_product_template } from './primary-template';
import ProjectInfo from './ProjectInfo';
import { useStyles } from './useStyles';

import { USER_ROLE_SUPPER_ADMIN } from '../../../constants/defined';

PrimaryProductEdit.propTypes = {

};

const mappingAdvantages = (data) => {
    if (data) {
        if (_.size(_.get(data.project, 'project_utilities')) > 0) {
            const arr = _.filter(_.mapValues(_.get(data.project, 'project_utilities'), 'utility'), ['type', 1]);
            return _.map(arr, item => _.get(item, 'id'));
        }
    }
    return [];
}


const mappingUtilities = (data) => {
    if (data) {
        if (_.size(_.get(data.project, 'project_utilities')) > 0) {
            const arr = _.filter(_.mapValues(_.get(data.project, 'project_utilities'), 'utility'), ['type', 2]);
            return _.map(arr, item => _.get(item, 'id'));
        }
    }
    return [];
}

const mappingUploadProductImages = (data) => {
    if (data) {
        const arr = _.filter(_.mapValues(_.get(data.project, 'project_medias'), 'media'), ['type.code', 'PHOTO']);
        let result = _.map(arr, item => { return { 'id': _.get(item, 'id'), 'url': _.get(item, 'url') } });
        return result;
    }
    return [];
}

const mappingUploadDocument = (data) => {
    if (data) {
        const arr = _.filter(_.mapValues(_.get(data.project, 'project_medias'), 'media'), ['type.code', 'DOCUMENT']);
        let result = _.map(arr, item => { return { 'id': _.get(item, 'id'), 'url': _.get(item, 'url') } });
        return result;
    }
    return [];
}



function PrimaryProductEdit(props) {
    const { id } = useParams();
    const { loading, error, data, refetch } = useQuery(PROJECT_GET_ONE, {
        variables: {
            id
        }
    });
    const { userInfo, accessObjectPermissions } = useContext(StoreContext);
    const [disabled, setDisabled] = useState(true);
    const [draftObject, setDraftObject] = useState(_.cloneDeep(primary_product_template));
    const [isDraft, setIsDraft] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);


    //handle add data into template
    useMemo(() => {
        if (data) {
            let imgs = _.map(_.get(data.project, "project_medias"), item => {
                return item.media.id;
            });

            let advs = _.map(_.get(data.project, "project_utilities"), item => {
                if (item.utility.type === 1)
                    return item.utility.id;
            });

            advs = _.filter(advs, (item) => {
                return !_.isEmpty(item);
            })

            let uties = _.map(_.get(data.project, "project_utilities"), item => {
                if (item.utility.type === 2)
                    return item.utility.id;
            });

            uties = _.filter(uties, (item) => {
                return !_.isEmpty(item);
            })

            let projectType = _.map(_.get(data.project, "project_types"), item => {
                return item.project_type.id
            });

            let status = _.get(data.project, "project_status.id");


            let tmp = draftObject;
            _.set(tmp, 'name', _.get(data.project, "title"));
            _.set(tmp, 'account', _.get(userInfo, "id"));
            _.set(tmp, 'province', _.get(data.project, "address.province"));
            _.set(tmp, 'district', _.get(data.project, "address.district"));
            _.set(tmp, 'ward', _.get(data.project, "address.ward"));
            _.set(tmp, 'street', _.get(data.project, "address.street"));
            _.set(tmp, 'address', _.get(data.project, "address.short"));
            _.set(tmp, 'type_list', projectType);
            _.set(tmp, 'price_from', _.get(data.project, "price_from"));
            _.set(tmp, 'price_to', _.get(data.project, "price_to"));
            _.set(tmp, 'price_from_milion', _.get(data.project, "price_from_milion"));
            _.set(tmp, 'price_to_milion', _.get(data.project, "price_to_milion"));
            _.set(tmp, 'product_image_list', imgs);
            _.set(tmp, 'video_url', _.get(data.project, "video_url"));
            _.set(tmp, 'view_3d_url', _.get(data.project, "view_3d_url"));
            _.set(tmp, 'investor', _.get(data.project, "project_investor.name"));
            _.set(tmp, 'corporation', _.get(data.project, "project_investor.corporation.name"));
            _.set(tmp, 'investor_website', _.get(data.project, "project_investor.website"));
            _.set(tmp, 'corporation_company', _.get(data.project, "investment_cooperation.name"));
            _.set(tmp, 'develop_company', _.get(data.project, "develop_company.name"));
            _.set(tmp, 'building_contractor', _.get(data.project, "project_constructor.name"));
            _.set(tmp, 'design_company', _.get(data.project, "project_design.name"));
            _.set(tmp, 'manager_company', _.get(data.project, "project_management.name"));
            _.set(tmp, 'monitor_company', _.get(data.project, "construction_supervision.name"));
            _.set(tmp, 'bank', _.get(data.project, "project_guaranty_banks.name"));
            _.set(tmp, 'support_bank', _.get(data.project, "project_loan_banks.name"));
            _.set(tmp, 'total_investment', _.get(data.project, "investment"));
            _.set(tmp, 'total_area', _.get(data.project, "total_area"));
            _.set(tmp, 'density_of_building', _.get(data.project, "density_of_building"));
            _.set(tmp, 'density_of_tree', _.get(data.project, "density_of_trees"));
            _.set(tmp, 'start_date', _.get(data.project, "start_date"));
            _.set(tmp, 'taking_over', _.get(data.project, "taking_over"));
            _.set(tmp, 'total_appartments', _.get(data.project, "total_appartments"));
            _.set(tmp, 'total_officetel', _.get(data.project, "total_officetel"));
            _.set(tmp, 'total_shophouse', _.get(data.project, "total_shophouse"));
            _.set(tmp, 'total_villa', _.get(data.project, "total_villa"));
            _.set(tmp, 'total_townhouse', _.get(data.project, "total_townhouse"));
            _.set(tmp, 'total_ground', _.get(data.project, "total_ground"));
            _.set(tmp, 'total_block', _.get(data.project, "total_block"));
            _.set(tmp, 'total_parking', _.get(data.project, "total_parking"));
            _.set(tmp, 'floor_from', _.get(data.project, "floor_from"));
            _.set(tmp, 'floor_to', _.get(data.project, "floor_to"));
            _.set(tmp, 'area_from', _.get(data.project, "area_from"));
            _.set(tmp, 'area_to', _.get(data.project, "area_to"));
            _.set(tmp, 'management_fee', _.get(data.project, "management_fee"));
            _.set(tmp, 'motorbike_parking_fee', _.get(data.project, "motorbike_parking_fee"));
            _.set(tmp, 'car_parking_fee', _.get(data.project, "car_parking_fee"));
            _.set(tmp, 'outstanding_advantages', advs);
            _.set(tmp, 'utilities', uties);
            _.set(tmp, 'investor_document_name', _.get(data.project, "investor_document_name"));
            _.set(tmp, 'other_utilities', _.get(data.project, ""));
            _.set(tmp, 'investor_document', _.get(data.project, ""));
            _.set(tmp, 'description', _.get(data.project, "description"));
            _.set(tmp, 'note', _.get(data.project, "note"));
            _.set(tmp, 'longitude', _.get(data.project, "longitude"));
            _.set(tmp, 'latitude', _.get(data.project, "latitude"));
            _.set(tmp, 'project_status', status);


            setDraftObject(tmp);

            setSelectedStatus(draftObject.project_status)

            if (_.get(data.project, "requests[0].draft", null) === true) {
                setIsDraft(true);
            }
        }
    }, []);

    const [updatePrimaryProduct] = useMutation(UPDATE_PRIMARY_PRODUCT, {
        refetchQueries: [{
            query: PROJECT_GET_ALL, variables: {
                skip: 0,
                take: 10,
                order_by: { created: "desc" },
                where: {
                    deleted: { _eq: false },
                    propertyTypeByPropertyType: {
                        code: {
                            _eq: "P"
                        }
                    }
                }
            },
            query: PROJECT_GET_ONE, variables: {
                id
            }
        }],
        fetchPolicy: 'no-cache'
    });
    // get project list
    const { data: projectList, loading: projectLoading } = usePrjects();
    const [projectsAvailable, setProjectsAvailable] = useState(false);

    const projectStatus = useProjectStatus();


    const provinces = useProvinces();
    const districts = useDistricts({ provinceId: _.get(draftObject, 'province.id') });
    const wards = useWards({ districtId: _.get(draftObject, 'district.id') });
    const streets = useStreets({ districtId: _.get(draftObject, 'district.id') });
    const [uploadProductDocuments, setUploadProductDocuments] = useState(() => mappingUploadDocument(data));
    const [uploadProductImages, setUploadProductImages] = useState(() => mappingUploadProductImages(data));
    const [uploadAvatarImage, setUploadAvatarImage] = useState();

    const projectTypes = useQuery(GET_PROJECT_TYPES, {});
    const utilitiList = useQuery(GET_UTILITIES, {});;

    const [advantages, setAdvantages] = useState(() => mappingAdvantages(data));
    let advantageData = _.filter(_.get(utilitiList, 'data.results'), (item) => {
        return item.type === 1;
    });

    const [utilities, setUtilities] = useState(() => mappingUtilities(data));
    let utilitiesData = _.filter(_.get(utilitiList, 'data.results'), (item) => {
        return item.type === 2;
    });
    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        let productImgs = _.map(uploadProductImages, (item) => {
            return item.id;
        });
        let docImgs = _.map(uploadProductDocuments, (item) => {
            return item.id;
        });
        let product_image_list = _.concat(productImgs, docImgs);
        let tmp = { ...draftObject };
        _.set(tmp, 'product_image_list', product_image_list);
        if (uploadAvatarImage) {
            _.set(tmp, 'investor_image', uploadAvatarImage[0]);
        }

        setDraftObject(tmp);
    }, [uploadProductImages, uploadProductDocuments, uploadAvatarImage])

    //Set condition enable
    useEffect(() => {
        if (!_.isEmpty(uploadProductImages)
            && !_.isEmpty(_.get(draftObject, 'name'))
            && !_.isEmpty(_.get(draftObject, 'type_list'))
            && !_.isEmpty(_.get(draftObject, 'investor'))
            && !_.isUndefined(_.get(draftObject, 'longitude'))
            && !_.isUndefined(_.get(draftObject, 'latitude'))) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [uploadProductImages, draftObject]);

    const handleUpdateProject = (e) => {
        e.preventDefault();
        let pushObject = draftObject;
        let typeList = pushObject.type_list;
        _.isArray(typeList) ? _.set(pushObject, 'type_list', typeList) : _.set(pushObject, 'type_list', [typeList]);
        _.set(pushObject, 'account', _.get(userInfo, 'id'));

        if (isDraft) {
            _.set(pushObject, 'draft', true);
        }
        _.set(pushObject, 'id', id);
        if (!_.isEmpty(utilities)) {
            _.set(pushObject, 'utilities', utilities);
        }

        if (!_.isEmpty(advantages)) {
            _.set(pushObject, 'outstanding_advantages', advantages);
        }

        if (!_.isEmpty(_.get(pushObject, 'province'))) {
            _.set(pushObject, 'province', _.get(pushObject, 'province.id'));
        };

        if (!_.isEmpty(_.get(pushObject, 'district'))) {
            _.set(pushObject, 'district', _.get(pushObject, 'district.id'));
        }

        if (!_.isEmpty(_.get(pushObject, 'ward'))) {
            _.set(pushObject, 'ward', _.get(pushObject, 'ward.id'));
        }

        if (!_.isEmpty(_.get(pushObject, 'street'))) {
            _.set(pushObject, 'street', _.get(pushObject, 'street.id'));
        }

        if (selectedStatus) {
            _.set(pushObject, 'project_status', selectedStatus);
        }

        updatePrimaryProduct({
            variables: {
                data: pushObject
            }
        }).then(res => {
            swal({
                title: "Cập nhật sản phẩm thành công",
                icon: "success",
            }).then(() => {
                history.push(slugs.productPrimary)
            })
        }, (error) => {
            swal("Vui lòng thử lại", "Có lỗi khi cập nhật sản phẩm!", "error");
        })
    }

    const handleSaveProject = (e) => {
        e.preventDefault();
        let pushObject = draftObject;
        let typeList = pushObject.type_list;
        _.isArray(typeList) ? _.set(pushObject, 'type_list', typeList) : _.set(pushObject, 'type_list', [typeList]);
        _.set(pushObject, 'account', _.get(userInfo, 'id'));

        if (isDraft) {
            _.set(pushObject, 'draft', false);
        }
        _.set(pushObject, 'id', id);
        if (!_.isEmpty(utilities)) {
            _.set(pushObject, 'utilities', utilities);
        }

        if (!_.isEmpty(advantages)) {
            _.set(pushObject, 'outstanding_advantages', advantages);
        }

        if (!_.isEmpty(_.get(pushObject, 'province'))) {
            _.set(pushObject, 'province', _.get(pushObject, 'province.id'));
        };

        if (!_.isEmpty(_.get(pushObject, 'district'))) {
            _.set(pushObject, 'district', _.get(pushObject, 'district.id'));
        }

        if (!_.isEmpty(_.get(pushObject, 'ward'))) {
            _.set(pushObject, 'ward', _.get(pushObject, 'ward.id'));
        }

        if (!_.isEmpty(_.get(pushObject, 'street'))) {
            _.set(pushObject, 'street', _.get(pushObject, 'street.id'));
        }


        updatePrimaryProduct({
            variables: {
                data: pushObject
            }
        }).then(res => {
            swal({
                title: "Lưu sản phẩm thành công",
                icon: "success",
            }).then(() => {
                history.push(slugs.productPrimary)
            })
        }, (error) => {
            swal("Vui lòng thử lại", "Có lỗi khi lưu sản phẩm!", "error");
        })
    }

    const handleChangeProjectStatus = (e) => {
        setSelectedStatus(parseInt(e.target.value));
    }

    return (
        <>
            <Paper className={classes.root}>
                <div className={classes.headerStyle} >
                    <Typography gutterBottom variant="h4" component='h2'>Chỉnh sửa dự án</Typography>
                </div>

                <FormControl className={classes.formContainer}>
                    <Grid container className={classes.groupRow}>
                        <Grid item xs={12}>
                            <Typography component='div' className={classes.title}>Tên dự án</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            {_.get(draftObject, "name") === undefined ? <CircularProgress /> : <InputTextFieldSearch
                                {...props}
                                description='Nhập tên dự án'
                                fieldname='name'
                                inputType="project"
                                object={draftObject}
                                setObject={setDraftObject}
                                setProjectsAvailable={setProjectsAvailable}
                                datalist={projectList}
                                defaultValue={_.get(draftObject, "name")}
                            />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component='div' className={classes.title}>Vị trí</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <InputTextFieldSearch
                                {...props}
                                description='Chọn thành phố'
                                fieldname='province'
                                object={draftObject}
                                setObject={setDraftObject}
                                disabled={projectsAvailable}
                                datalist={provinces}
                                defaultValue={_.get(draftObject, "province.name")}
                            />
                        </Grid>
                        <Grid item container xs={5} justifyContent="space-around">
                            <Typography component='div' aria-required >Latitude <span style={{ color: 'red' }}>*</span></Typography>

                            <InputTextField
                                number
                                fieldname='latitude'
                                object={draftObject}
                                setObject={setDraftObject}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <InputTextFieldSearch
                                {...props}
                                description='Chọn quận'
                                fieldname='district'
                                object={draftObject}
                                setObject={setDraftObject}
                                disabled={projectsAvailable}
                                datalist={districts}
                                defaultValue={_.get(draftObject, "district.name")}
                            />
                        </Grid>
                        <Grid item container xs={5} justifyContent="space-around">
                            <Typography component='div' aria-required >Longitude <span style={{ color: 'red' }}>*</span></Typography>
                            <InputTextField
                                number
                                fieldname='longitude'
                                object={draftObject}
                                setObject={setDraftObject}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <InputTextFieldSearch
                                {...props}
                                description='Chọn phường'
                                fieldname='ward'
                                object={draftObject}
                                setObject={setDraftObject}
                                disabled={projectsAvailable}
                                datalist={wards}
                                defaultValue={_.get(draftObject, "ward.name")}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <InputTextFieldSearch
                                {...props}
                                description='Chọn tên đường'
                                fieldname='street'
                                object={draftObject}
                                setObject={setDraftObject}
                                disabled={projectsAvailable}
                                datalist={streets}
                                defaultValue={_.get(draftObject, "street.name")}
                            />
                        </Grid>

                        <Grid item xs={7}>
                            <InputTextField
                                fieldname='address'
                                description='Nhập số nhà'
                                fullwidth
                                object={draftObject}
                                setObject={setDraftObject}
                                defaultValue={_.get(draftObject, "address")}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.marginDivider} />
                    <Grid container className={classes.groupRow}>
                        <Grid item xs={2}>
                            <Typography component='div' className={clsx(classes.title, 'required')}>Loại hình</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            {_.get(draftObject, "type_list") === undefined ? <CircularProgress /> :
                                <InputDropdown
                                    fieldname='type_list'
                                    discription='Chọn loại hình'
                                    fullwidth
                                    object={draftObject}
                                    setObject={setDraftObject}
                                    data={_.filter(_.get(projectTypes, 'data.property_type'), (item, key) => item.code !== "P")}
                                    defaultValue={_.get(draftObject, "type_list")}
                                />
                            }

                        </Grid>
                    </Grid>
                    <div className={classes.marginDivider} />
                    <Grid container className={classes.groupRow}>
                        <Grid item xs={12}>
                            <Typography component='div' className={classes.title}>Giá</Typography>
                            <Typography component='div' className={clsx(classes.label, 'description')}>Điền một trong hai giá bên dưới</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component='div' className={classes.label}>Giá (VNĐ)</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <InputTextField
                                number
                                fieldname='price_from'
                                fullwidth
                                object={draftObject}
                                setObject={setDraftObject}
                                defaultValue={_.get(draftObject, "price_from")}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <p className={classes.to}>đến</p>
                        </Grid>
                        <Grid item xs={3}>
                            <InputTextField
                                number
                                fieldname='price_to'
                                fullwidth
                                object={draftObject}
                                setObject={setDraftObject}
                                defaultValue={_.get(draftObject, "price_to")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component='div' className={classes.label}>Giá (triệu/m2)</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <InputTextField
                                number
                                fieldname='price_from_milion'
                                fullwidth
                                object={draftObject}
                                setObject={setDraftObject}
                                defaultValue={_.get(draftObject, "price_from_milion")}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <p className={classes.to}>đến</p>
                        </Grid>
                        <Grid item xs={3}>
                            <InputTextField
                                number
                                fieldname='price_to_milion'
                                fullwidth
                                object={draftObject}
                                setObject={setDraftObject}
                                defaultValue={_.get(draftObject, "price_to_milion")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component='div' className={clsx(classes.title, 'required')}>Hình ảnh sản phẩm</Typography>
                            <Typography component='div' className={clsx(classes.label, 'description')}>Tối đa 10 hình, dung lượng 5MB/hình</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.gridRow}>
                            <ImagePicker
                                data={uploadProductImages}
                                setData={setUploadProductImages}
                                numberColumn={4}
                                buttonType='CARD'
                                type="PHOTO"
                            />
                        </Grid>

                    </Grid>
                    <div style={{ marginTop: "20px" }} />
                    <Grid container xs={12} className={classes.groupRow}>
                        <Grid item xs={1}>
                            <Typography component='div' className={classes.label}>Link Video</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <InputTextField
                                fieldname='video_url'
                                fullwidth
                                object={draftObject}
                                setObject={setDraftObject}
                            />
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: "20px" }} />
                    <Grid container xs={12} className={classes.groupRow}>
                        <Grid item xs={1}>
                            <Typography component='div' className={classes.label}>Link 3D</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <InputTextField
                                fieldname='view_3d_url'
                                fullwidth
                                object={draftObject}
                                setObject={setDraftObject}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.marginDivider} />

                    <Investor draftObject={draftObject} setDraftObject={setDraftObject} data={data ? data?.project?.project_investor?.logo : []} onChange={id => setUploadAvatarImage(id)} />

                    <div className={classes.marginDivider} />
                    <ProjectInfo draftObject={draftObject} setDraftObject={setDraftObject} modeEdit={true} />
                    <div className={classes.marginDivider} />
                    <Grid container className={classes.groupRow} xs={12}>
                        <SelectUtilities
                            title='Ưu điểm nổi bật'
                            data={advantageData}
                            object={advantages}
                            setObject={setAdvantages}

                        />
                    </Grid>
                    <div className={classes.marginDivider} />
                    {/* <Grid container xs={12}>
                        <Grid item xs={2}>
                            <Typography component='div' className={classes.label}>Nhập những ưu điểm khác</Typography>
                            <Typography component='div' className={clsx(classes.label, 'description')}>Tối đa 3 ưu điểm</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <InputField limit={4}
                                fieldname='investor.operation_unit'
                                object={draftObject}
                                setObject={setDraftObject} />
                        </Grid>
                    </Grid> */}

                    <div className={classes.marginDivider} />
                    <Grid container className={classes.groupRow} xs={12}>
                        <SelectUtilities
                            title='Tiện ích nội khu'
                            data={utilitiesData}
                            object={utilities}
                            setObject={setUtilities}
                        />
                    </Grid>
                    <div className={classes.marginDivider} />
                    {/* <Grid container xs={12}>
                        <Grid item xs={2}>
                            <Typography component='div' className={classes.label}>Tiện ích nội khu khác</Typography>
                            <Typography component='div' className={clsx(classes.label, 'description')}>Tối đa 3 tiện ích</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <InputField limit={3}
                                fieldname='other_utilities'
                                object={draftObject}
                                setObject={setDraftObject} />
                        </Grid>
                    </Grid> */}
                    {/* <div className={classes.marginDivider} />
                    <Grid container xs={12}>
                        <Grid item xs={2}>
                            <Typography component='div' className={classes.label}>Tên tài liệu</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <InputTextField
                                fieldname='investor_document_name'
                                fullwidth
                                object={draftObject}
                                setObject={setDraftObject}
                                defaultValue={_.get(draftObject, "investor_document_name")}
                            />
                        </Grid>
                    </Grid> */}
                    <div className={classes.marginDivider} />
                    <Grid container xs={12}>
                        <Grid item xs={2}>
                            <Typography component='div' className={classes.label}>Tài liệu từ chủ đầu tư</Typography>
                            <Typography component='div' className={clsx(classes.label, 'description')}>PDF - Kích thước tối đa 5MB</Typography>
                        </Grid>
                        <ImagePicker
                            data={uploadProductDocuments}
                            setData={setUploadProductDocuments}
                            numberColumn={4}
                            buttonType='CARD'
                            type="DOCUMENT"
                        />
                    </Grid>
                    <div className={classes.marginDivider} />
                    <Grid container >
                        <Grid item xs={2}>
                            <b className={classes.textLabelForm}>Trạng thái dự án <span style={{ color: 'red' }}>*</span></b>
                        </Grid>
                        <Grid>

                            <RadioGroup a aria-label="project_status" name="project_status" value={selectedStatus} onChange={handleChangeProjectStatus} row>
                                {_.map(projectStatus, (item) => (
                                    <FormControlLabel
                                        key={_.get(item, 'id')}
                                        style={{ width: '160px' }}
                                        control={<Radio size="small" value={_.get(item, 'id')} required defaultChecked={draftObject.project_status} />}
                                        label={<span style={{ fontSize: '16px' }}>{jsUcfirst(_.get(item, 'name'))}</span>}

                                    />
                                ))}
                            </RadioGroup>

                        </Grid>
                    </Grid>

                    <div className={classes.marginDivider} />
                    <Grid container xs={12}>
                        <Grid item xs={2}>
                            <Typography component='div' className={classes.label}>Mô tả</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <InputTextField
                                fieldname='description'
                                fullwidth
                                multiline={true}
                                maxLength={1000}
                                object={draftObject}
                                setObject={setDraftObject}
                                defaultValue={_.get(draftObject, "description")}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.marginDivider} />
                    <Grid container xs={12}>
                        <Grid item xs={2}>
                            <Typography component='div' className={classes.label}>Ghi chú tin đăng</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <InputTextField
                                fieldname='note'
                                fullwidth
                                multiline={true}
                                maxLength={500}
                                object={draftObject}
                                setObject={setDraftObject}
                                defaultValue={_.get(draftObject, "note")}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.footerStyle}>
                        <Button
                            variant="contained"
                            color='primary'
                            disabled={disabled}
                            className={classes.finishBtn}
                            onClick={handleUpdateProject}
                            startIcon={<Update />}
                        >Cập nhật</Button>
                        {
                            isDraft &&
                            <Button
                                variant="contained"
                                color='primary'
                                onClick={handleSaveProject}
                                disabled={disabled}
                                startIcon={<Save />}
                            >Hoàn thành</Button>
                        }

                        <ButtonReturn link={slugs.productPrimary} />
                    </div>
                </FormControl>
            </Paper >

        </>

    );
}

export default PrimaryProductEdit;