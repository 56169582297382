import * as _ from "lodash";
import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { GET_ALL_SHORT_PROJECTS } from "../graphql/schemas/product/projectQueries";
import { useQuery } from "@apollo/client";
import { CustomCheckbox } from "./UI";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 80px)",
    padding: "0 30px",
    position: "relative",
  },
  headerStyle: {
    background: "#F5F5F5",
    "& th > span": {
      fontWeight: "bold",
      color: "#000",
    },
    "& th > .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "#000",
      "& .MuiTableSortLabel-icon": {
        color: "#000 !important",
      },
    },
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  rowStyle: {
    position: "relative",
    zIndex: 5,
  },
  checkboxStyle: {
    background: "green",
    position: "relative",
    zIndex: 9,
  },
  nodataStyle: {
    color: "#E27F35",
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

const headMenu = [
  { id: "number", numeric: false, disablePadding: false, label: "STT" },
  { id: "name", numeric: false, disablePadding: false, label: "Tên dự án" },
  { id: "address", numeric: false, disablePadding: false, label: "Địa chỉ" }
];

const EnhancedTableHead = (props) => {
  const { classes, headCells, allSelected=true, setAllSelected, removeAll } = props;
  
  const handleAllCheckbox = () => {
    if(allSelected) {
      removeAll([]);
    };
    setAllSelected(!allSelected);
  }

  return (
    <TableHead className={classes.headerStyle}>
      <TableRow>
        <TableCell>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                onChange={handleAllCheckbox}
                control={
                  <CustomCheckbox
                    size="small"
                    checked={allSelected}
                  />
                }
              />
            </FormGroup>
          </FormControl>
        </TableCell>

        {_.map(headCells, (item, key) => (
          <TableCell
            key={key}
            align={_.get(item, 'center') ? 'center' : 'left'}
            padding={_.get(item, 'disablePadding') ? "none" : "normal"}
          >
            {_.get(item, 'label')}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TableComponentProjects = ({distributedList=[], setDistributedList}) => {
  let headCells = [];
  if (_.isEmpty(headMenu) && !_.isEmpty(data)) {
    headCells = _.map(_.keys(_.head(data)), (value, key) => {
      return { id: value, numeric: false, disablePadding: false, label: value };
    });
  } else {
    headCells = headMenu;
  }

  const classes = useStyles();
  const [projects, setProjects] = useState([]);
  const [count, setCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [allSelected, setAllSelected] = useState();

  const { loading, data } = useQuery(GET_ALL_SHORT_PROJECTS, {
    variables: { 
      skip: 0, take: 1e9,
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSkip(newPage * rowsPerPage - rowsPerPage);
  };

  const handleClickCheckbox = (e, row) => {
    if(allSelected) {
      setAllSelected(false);
    }
    if (e.target.checked) {
      setDistributedList([...distributedList, _.get(row, 'id')]);
    } else {
      setDistributedList(distributedList.filter((item) => item !== _.get(row, 'id')));
    }
  };

  useEffect(() => {
    if (data) {
      const tmp = _.map(_.get(data, 'results'), (item, key) => ({
        number: ++key + ((page - 1) * rowsPerPage),
        id: _.get(item, 'id'),
        name: _.get(item, 'name'),
        address: _.join(
          _.pull([
            _.get(item, 'address.short', ''),
            _.get(item, 'address.street.name', ''),
            _.get(item, 'address.ward.name', ''),
            _.get(item, 'address.district.name', ''),
            _.get(item, 'address.province.name', '')
          ], '', null), ', '
        )
      }))
      setProjects(tmp);
      setCount(_.size(_.get(data, 'results')));
    }
  }, [loading, data]);

  useEffect(() => {
    if (allSelected) {
      setDistributedList(_.map(_.get(data, 'results'), item => item?.id));
    }
  }, [allSelected, loading]);

  useEffect(() => {
    if (_.isEmpty(distributedList)) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, []);

  if (loading) {
    return (
      <CircularProgress />
    );
  }

  if (_.size(data) === 0) {
    return (
      <Typography className={classes.nodataStyle}>
        Không có dữ liệu thích hợp
      </Typography>
    );
  }

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              headCells={headCells}
              allSelected={allSelected}
              setAllSelected={setAllSelected}
              removeAll={setDistributedList}
            />
            <TableBody>
              {
                _.map(_.slice(projects, skip, rowsPerPage * page), (row) => {
                  return (
                    <TableRow key={_.get(row, "id")} role="checkbox">
                      <TableCell>
                        <FormControl component="fieldset">
                          <FormGroup>
                            <FormControlLabel
                              onChange={(e) => handleClickCheckbox(e, row)}
                              control={
                                <CustomCheckbox
                                  size="small"
                                  checked={_.includes(distributedList, _.get(row, 'id')) || allSelected}
                                />
                              }
                            />
                          </FormGroup>
                        </FormControl>
                      </TableCell>
                      {
                        _.map(headCells, (item, key) => {
                          return (
                            <TableCell key={key}>
                              {_.get(row, item.id)}
                            </TableCell>
                          )
                        })
                      }
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item style={{ display: 'inline-flex', justifyContent: 'center' }}>
        <Pagination
          count={Number(Math.ceil(count / rowsPerPage))}
          onChange={handleChangePage}
          page={page}
          variant="outlined"
          shape="rounded"
          showLastButton
          showFirstButton
          defaultPage={1}
        />
      </Grid>

    </Grid>
  );
};



export default TableComponentProjects;


