import * as _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    useMediaQuery,
    Grid, Button, Typography, FormControl, Select, MenuItem, Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/styles/useTheme';
import { useQuery, useLazyQuery } from '@apollo/client';
import Moment from 'react-moment';
import DescriptionIcon from '@material-ui/icons/Description';
import AccordionEmployee from '../Accordion';
import clsx from 'clsx';
import slugs from '../../resources/slugs';
import ButtonReturn from '../ButtonReturn';
import { GET_ACTIVITY_HISTORY_BY_EMPLOYEE } from '../../graphql/schemas/employee/employeeQueries';

import { checkedPermission, handleExportFile } from '../../helper/index'
import { UPDATE_EMPLOYEE } from '../../constants/defined';
import { StoreContext } from '../../resources/store';



const useStyles = makeStyles((theme) => ({
    accordion: {
        width: '100%',
        maxWidth: '900px',
        marginTop: '30px',
        marginLeft: '0',
        '&.matches-sm': {
            width: '100%',
        }
    },
    text: {
        fontSize: '16px',
    },
    labelStyle: {
        fontWeight: 'bold',

    },
    buttonExport: {
        borderRadius: 0,
        color: '#ffffff',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: '#82868B',
        textTransform: 'none',
        width: 130,

        '&.matches-md ': {
            marginTop: '20px',
        }
    },

    marginDivider: {
        width: '100%',
        margin: '30px 0',
    },
    footerStyle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',

        '& button': {
            borderRadius: 0,
            padding: '5px 30px',
        },
        '& button:not(:last-child)': {
            marginRight: '30px',
        },

    },
    export: {
        marginLeft: "30px"
    }
}));

const HistoryActivityEmployee = ({ employee, refetch }) => {
    const { id } = useParams();
    //const id = _.get(employee, 'employee_id');
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

    const { userInfo, accessObjectPermissions } = useContext(StoreContext);

    // const [value, setValue] = useState(1);
    const [typeProduct, setTypeProduct] = useState('CONSULT');//CONSULT - CONSIGN setTypeProduct

    useEffect(() => {
        refetch()
    }, [typeProduct])


    const { data: dataActivyHistory, loading: loadingActivyHistory } = useQuery(GET_ACTIVITY_HISTORY_BY_EMPLOYEE, {
        variables: {
            type: typeProduct,
            employee: Number(id)
        }
    });

    const dataNotChecking = _.filter(_.get(dataActivyHistory, 'request_workflow'), ['type_workflow.type_step.code', 'INPROCESS']);
    const dataHouseView = _.filter(_.get(dataActivyHistory, 'request_workflow'), ['type_workflow.type_step.code', 'HOUSEVIEW']);
    const dataDeposit = _.filter(_.get(dataActivyHistory, 'request_workflow'), ['type_workflow.type_step.code', 'DEPOSIT']);
    const dataContract = _.filter(_.get(dataActivyHistory, 'request_workflow'), ['type_workflow.type_step.code', 'CONTRACT']);

    return (
        <Grid container direction="column" style={{ paddingTop: matchesSM ? '20px' : undefined }}>
            <Grid container direction={matchesMD ? 'column' : 'row'} alignItems="flex-start">
                <Grid item style={{ paddingTop: '20px', width: '400px' }}>
                    <Grid container alignItems="center" direction="row" style={{ marginBottom: '26px' }}>
                        <Grid item style={{ width: '160px' }}>
                            <Typography className={classes.labelStyle} >Tên nhân viên:</Typography>
                        </Grid>
                        <Grid item className={classes.text}>
                            <span>{_.get(employee, 'info.full_name')}</span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{ paddingTop: '20px' }}>
                    <Grid container alignItems="center" direction="row" style={{ marginBottom: '26px' }}>
                        <Grid item style={{ width: '160px' }}>
                            <Typography className={classes.labelStyle}>Mã nhân viên: </Typography>
                        </Grid>
                        <Grid item className={classes.text}>
                            <span>{_.get(employee, 'info.code')}</span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* <Grid container direction={matchesMD ? 'column' : 'row'} alignItems="flex-start">
                <Grid item style={{ paddingTop: '20px', width: '400px' }}>
                    <Grid container alignItems="center" direction="row" style={{ marginBottom: '26px' }}>
                        <Grid item style={{ width: '160px' }}>
                            <Typography className={classes.labelStyle}>Toạ độ X: </Typography>
                        </Grid>
                        <Grid item className={classes.text}>
                            <span>{_.last(_.words(_.get(employee, 'branch.map'), /[^,"() ]+/g))}</span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{ paddingTop: '20px', width: '400px' }}>
                    <Grid container alignItems="center" direction="row" style={{ marginBottom: '26px' }}>
                        <Grid item style={{ width: '160px' }}>
                            <Typography className={classes.labelStyle}>Toạ độ Y: </Typography>
                        </Grid>
                        <Grid item className={classes.text}>
                            <span>{_.head(_.words(_.get(employee, 'branch.map'), /[^,"() ]+/g))}</span>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid> */}

            <Grid container direction={matchesMD ? 'column' : 'row'} alignItems="flex-start">
                <Grid item style={{ paddingTop: '20px' }}>
                    <Grid container alignItems="center" direction="row" style={{ marginBottom: '26px' }}>
                        <Grid item style={{ width: '250px' }}>
                            <Typography className={classes.labelStyle}>Lần truy cập gần nhất: </Typography>
                        </Grid>
                        <Grid item className={classes.text}>
                            <span>
                                <Moment format="DD/MM/YYYY hh:mm">{_.get(employee, 'info.last_activity')}</Moment>
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container direction={matchesMD ? 'column' : 'row'}>
                <Grid item style={{ width: '160px', paddingBottom: '30px' }}>
                    <Typography className={classes.labelStyle}>Loại hoạt động: </Typography>
                </Grid>
                <Grid item>
                    <FormControl variant="outlined" size="small" style={{ backgroundColor: '#ffffff', width: 260 }}>
                        <Select
                            labelId="list-acitivity"
                            id="list-acitivity"
                            value={typeProduct}
                            onChange={(e) => setTypeProduct(e.target.value)}
                        >
                            <MenuItem value={'CONSULT'}>Lịch sử kiểm duyệt</MenuItem>
                            <MenuItem value={'CONSIGN'}>Lịch sử tư vấn</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item={true} className={classes.export}>
                    <Button disabled={!checkedPermission(userInfo, accessObjectPermissions, UPDATE_EMPLOYEE)} variant="contained" className={clsx(classes.buttonExport, matchesMD && 'matches-md')} onClick={() => handleExportFile(dataNotChecking, dataHouseView, dataDeposit, dataContract)}>
                        <DescriptionIcon style={{ fontSize: 14 }} />
                        <span style={{ fontSize: 13 }}>Xuất dữ liệu</span>
                    </Button>
                </Grid>
            </Grid>

            <Grid container direction="column" className={clsx(classes.accordion, matchesSM && 'matches-sm')}>
                <AccordionEmployee data={dataActivyHistory} loading={loadingActivyHistory} />
            </Grid>

            <Divider className={classes.marginDivider} />
            <Grid container direction="row" justifyContent={matchesMD ? "flex-start" : "flex-end"} className={classes.footerStyle} >
                <ButtonReturn />
            </Grid>
        </Grid>
    )
}

export default HistoryActivityEmployee;
