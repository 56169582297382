import { gql } from '@apollo/client';

export const GET_LIST_BANNER = gql`
query GetBanners($code: String!) {
  results: media(order_by: {created: desc}, where: {media_type: {code: {_eq: $code}}, deleted: {_eq: false}}) {
    id
    name
    url
  }
}
`

export const DELETE_BANNER = gql`
    mutation DeleteBanner($id: uuid!,$account: bigint!) {
  update_media(_set: {deleted: true, updated: "now()", updated_by: $account},where: {id: {_eq: $id}}) {
    affected_rows
    returning {
      id
    }
  }
}

`

export const BANNER_TYPES = gql`
query GetBannerTypes {
  results: media_type(order_by: {created: desc}, where: {code: {_like: "BANNER%"}, deleted: {_eq: false}}) {
    id
    code
    name
  }
}

`