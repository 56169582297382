import * as _ from "lodash";
import React, { Fragment, useEffect, useState, useContext } from "react";
import {
  Paper,
  Typography,
  FormControl,
  Button,
  IconButton,
  InputBase,
  Grid,
  Divider,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";

import {
  PersonAdd,
  PostAdd,
  Search,
  Settings as SettingsIcon,
} from "@material-ui/icons";

import { makeStyles } from "@material-ui/styles";
import TableComponent from "./components/TableComponent";
import {
  GET_CUSTOMER_BY_CODE_FILTER,
  GET_CUSTOMER_BY_STATUS_SUMMARY,
} from "../../graphql/schemas/customer/customerQueries";
import { Link, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";

import VisibilityIcon from "@material-ui/icons/Visibility";
import { useBranches } from "../../graphql/schemas/masterdata/branches";
import MultiSelectionObject from "../../components/MultiSelectionObject";
import AutoCompleteEmployee from "../../components/AutoCompleteEmployee";
import moment from "moment";
import ButtonStatusList from "./components/ButtonStatusList";
import ButtonExportExcel from "../../components/ButtonExportExcel";
import clsx from "clsx";
import slugs from "../../resources/slugs";
import { PrimaryButton } from "../../components/UI";
import { StoreContext } from "../../resources/store";
import { checkedPermission } from "../../helper";
import { CREATE_CUSTOMER, EXPORT_CUSTOMER } from "../../constants/defined";

const headMenu = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Tên khách hàng",
  },
  {
    id: "code",
    numeric: false,
    disablePadding: false,
    label: "Mã khách hàng",
    center: true,
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "Điện thoại",
    center: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    center: true,
  }, //
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Trạng thái",
    center: true,
  },
  // { id: 'eKYC', numeric: false, disablePadding: false, label: 'Trạng thái eKYC' },
  {
    id: "lastActivity",
    numeric: false,
    disablePadding: false,
    label: "Truy cập gần nhất",
  }, //

  // { id: 'office', numeric: false, disablePadding: false, label: 'Văn phòng' },
  // { id: 'staff', numeric: false, disablePadding: false, label: 'Mã số nhân viên phụ trách' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "calc(100vh - 200px)",
    padding: "30px 30px",
    position: "relative",
  },

  labelStyle: {
    fontWeight: "bold",
  },
  groupFilterStyle: {
    marginTop: "30px",
    "& > div:not(:last-child)": {
      paddingRight: "30px",
    },
  },
  buttonStyle: {
    backgroundColor: "#3f51b5",
    color: "#ffffff",
    borderRadius: 0,
    paddingLeft: "30px",
    paddingRight: "30px",
    margin: 10,
    "&:hover": {
      backgroundColor: "#303f9f",
    },
  },
  rootSearch: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    borderRadius: 20,
  },
  columnStyle: {
    "&:not(:first-child)": {
      paddingLeft: "30px",
    },
    "&.right": {
      textAlign: "right",
    },
  },
  wrapperButtonFilter: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  wapperLoading: {
    width: "100%",
    height: "calc(100vh - 200px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  marginBottom: {
    marginBottom: "30px",
  },

  styleButton: {
    textTransform: "unset",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#ffffff",
    borderRadius: "6px",
    padding: "4px 20px",
  },

  groupButton: {
    "& >:not(:first-child)": {
      marginLeft: "24px",
    },
    "& > a": {
      backgroundColor: "#00837B",

      "&:hover": {
        backgroundColor: "#00837B",
        opacity: 0.7,
      },
    },
  },
}));

const CustomerListPage = (props) => {
  const history = useHistory();
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const { userInfo, accessObjectPermissions } = useContext(StoreContext);
  let [searchText, setSearchText] = useState("");
  let [searchTextValue] = useDebounce(searchText, 500);

  // const { data: brancheList } = useBranches();
  const [branches, setBranches] = useState([]);

  const [employeeId, setEmployeeId] = useState();
  const [typeStatusCode, setTypeStatusCode] = useState(["ALL"]);
  const [strFilter, setStrFilter] = useState({});
  const [lockFilter, setLockFilter] = useState(false);

  const id = employeeId
    ? { employees: { id: { _eq: employeeId } } }
    : undefined;
  const branch =
    branches.length > 0
      ? {
          id: {
            _in: branches,
          },
        }
      : undefined;

  const employee =
    !_.isEmpty(branch) || !_.isEmpty(id)
      ? {
          accountByAccount: id,
          _or: [
            {
              branchByBranch: branch,
            },
            //   {
            //     "branchByBranch": {
            //       "name": {
            //         "_ilike": "%%"
            //       }
            //     }
            //   }
          ],
        }
      : undefined;

  const variables = {
    skip,
    take: 10,
    where: {
      employee,
      accountByAccount: {
        deleted: {
          _eq: false,
        },
        type: {
          _eq: "CUSTOMER",
        },
        active: lockFilter ? { _eq: false } : undefined,
        id_status: _.isEmpty(strFilter) ? undefined : strFilter,
        _or: searchTextValue
          ? [
              {
                phone: {
                  _ilike: `%${
                    searchTextValue[0] === "0"
                      ? searchTextValue.slice(1)
                      : searchTextValue
                  }%`,
                },
              },
              {
                full_name: {
                  _ilike: `%${searchTextValue}%`,
                },
              },
              {
                code: {
                  _ilike: `%${searchTextValue}%`,
                },
              },
            ]
          : undefined,
      },
    },
  };

  const { loading: loadingSummary, data: dataSummary } = useQuery(
    GET_CUSTOMER_BY_STATUS_SUMMARY,
    {}
  );

  const {
    loading,
    data,
    error,
    refetch: refetchCustomers,
  } = useQuery(
    GET_CUSTOMER_BY_CODE_FILTER,
    {
      variables,
    },
    { keepPreviousData: true }
  );
  const [object, setObject] = useState({ menu: headMenu });

  const customerData = _.map(_.get(data, "results"), (item, key) => {
    return {
      id: _.get(item, "id"),
      code: _.get(item, "info.code"),
      name:
        _.get(item, "info.id_status") === "VERIFIED"
          ? _.get(item, "info.id_name")
          : _.get(item, "info.full_name"),
      phone: _.get(item, "info.phone"),
      email: _.get(item, "info.email"),
      status: _.get(item, "info.active"),
      // eKYC: _.get(item, 'info.id_status'),
      lastActivity: _.get(item, "info.last_activity")
        ? moment(_.get(item, "info.last_activity")).format("DD/MM/YYYY HH:mm A")
        : "-",
      // office: _.map(_.get(item, 'departments')).length !== 0 ? _.map(_.get(item, 'departments'), (item, key) => item.department.name).join(', ') : "NULL",
      // staff: _.get(item, 'consult_employee_info.info.code')
    };
  });
  const classes = useStyles();

  useEffect(() => {
    setSkip(0);
    setPage(1);
    if (_.head(typeStatusCode) === "ALL") {
      setStrFilter({});
    } else if (_.isEmpty(_.head(typeStatusCode))) {
      setStrFilter({ _is_null: true });
    } else {
      setStrFilter({ _in: typeStatusCode });
    }
  }, [typeStatusCode]);

  const count = _.get(data, "t.aggregate.count");
  const accessDeniedCreate = !checkedPermission(
    userInfo,
    accessObjectPermissions,
    CREATE_CUSTOMER
  );
  const accessDeniedExport = !checkedPermission(
    userInfo,
    accessObjectPermissions,
    EXPORT_CUSTOMER
  );

  if (loadingSummary) {
    return (
      <div className={classes.wapperLoading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Fragment>
      <Grid container direction="row" className={classes.marginBottom}>
        <Paper className={classes.rootSearch}>
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
          >
            <Search />
          </IconButton>
          <TextField
            size="small"
            InputProps={{ disableUnderline: true }}
            style={{ backgroundColor: "#ffffff", border: 0, width: 330 }}
            placeholder="Tìm kiếm..."
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              setPage(1);
              setSkip(0);
            }}
          />
        </Paper>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={clsx(classes.marginBottom)}
      >
        <Grid item>
          <Grid
            item
            container
            direction="row"
            className={clsx(classes.groupButton)}
          >
            <Button
              className={classes.styleButton}
              style={{ backgroundColor: accessDeniedCreate && "lightgray" }}
              disabled={accessDeniedCreate}
              component={Link}
              to={slugs.customerCreate}
              startIcon={<PersonAdd />}
            >
              Tạo Khách hàng
            </Button>

            <Grid item>
              <ButtonExportExcel
                exportName="EXPORT_CUSTOMER"
                disabled={accessDeniedExport}
                account_type="CUSTOMER"
                id_status={
                  _.head(typeStatusCode) === "ALL"
                    ? ""
                    : _.isEmpty(_.head(typeStatusCode))
                    ? "NONE"
                    : _.join(typeStatusCode, ",")
                }
                active={!lockFilter ? null : false}
              />
            </Grid>

            {/* 
                        <Grid item>
                            <PrimaryButton
                                labelButton="Quản lý đánh giá KH"
                                actionButton={() => history.push(slugs.customerReview)}
                                iconButton={<VisibilityIcon />}
                            />                            
                        </Grid> 
                        */}
          </Grid>
        </Grid>

        <Grid item>
          <FormControl component="fieldset">
            <FormControlLabel
              control={<Checkbox color="primary" checked={lockFilter} />}
              label="Đã khóa"
              labelPlacement="end"
              onChange={(e) => {
                setLockFilter(e?.target?.checked);
                setSkip(0);
                setPage(1);
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Paper className={classes.root}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className={clsx(classes.marginBottom, classes.wrapperButtonFilter)}
        >
          <ButtonStatusList
            status="DEFAULT"
            handleClick={setTypeStatusCode}
            selected={_.isEmpty(typeStatusCode)}
            count={_.get(dataSummary, "default.aggregate.count")}
          />
          <ButtonStatusList
            status="WAITING"
            handleClick={setTypeStatusCode}
            selected={_.first(typeStatusCode) === "INITIAL"}
            count={_.get(dataSummary, "initital.aggregate.count")}
          />
          <ButtonStatusList
            status="VERIFIED"
            handleClick={setTypeStatusCode}
            selected={_.first(typeStatusCode) === "VERIFIED"}
            count={_.get(dataSummary, "verified.aggregate.count")}
          />
          <ButtonStatusList
            status="REJECT"
            handleClick={setTypeStatusCode}
            selected={_.first(typeStatusCode) === "REJECT"}
            count={_.get(dataSummary, "reject.aggregate.count")}
          />
          <ButtonStatusList
            status="ALL"
            handleClick={setTypeStatusCode}
            selected={_.first(typeStatusCode) === "ALL"}
            count={_.sum([
              _.get(dataSummary, "default.aggregate.count"),
              _.get(dataSummary, "initital.aggregate.count"),
              _.get(dataSummary, "verified.aggregate.count"),
              _.get(dataSummary, "reject.aggregate.count"),
            ])}
          />
        </Grid>

        {/* 
                <Grid container direction="row" alignItems="center">
                    <div style={{ width: 10, height: 20 }}></div>
                    <Grid container direction="row">
                        <Grid item xs={12} md={4} className={classes.columnStyle}>
                            <MultiSelectionObject
                                fullwidth
                                title='Văn phòng'
                                list={brancheList}
                                setValue={setBranches}
                                selected={branches}
                            />
                        </Grid>

                        <Grid item xs={12} md={4} className={classes.columnStyle}>
                            <Typography gutterBottom component='h4'>Nhân viên phụ trách</Typography>
                            <AutoCompleteEmployee diffId={true} width={505} onChange={(id) => setEmployeeId(id)} />
                        </Grid>
                    </Grid>

                </Grid> 
                */}

        <Grid
          container
          direction="row"
          spacing={4}
          style={{
            marginTop: "30px",
            marginLeft: "-30px",
            width: "calc(100% + 60px)",
          }}
        >
          {!customerData ? (
            <div
              style={{
                minHeight: "300px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableComponent
              searchText={searchText}
              count={count}
              data={customerData}
              headMenu={_.get(object, "menu")}
              setSkip={(skip) => setSkip(skip)}
              loading={loading}
              page={page}
              setPage={setPage}
            />
          )}
        </Grid>
      </Paper>
    </Fragment>
  );
};
export default CustomerListPage;
