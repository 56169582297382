import React, { useEffect, useState, useContext } from "react";
import * as _ from "lodash";
import { useStyles } from "./useStyles";
import {
  Paper,
  Typography,
  FormControl,
  Grid,
  Button,
  Divider,
  FormGroup,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import clsx from "clsx";
import InputTextField from "../Secondary/components/InputTextField";
import { primary_product_template } from "./primary-template";
import { useMutation, useQuery } from "@apollo/client";
import InputDropdown from "../Secondary/components/InputDropdown";

import Investor from "./Investor";
import ProjectInfo from "./ProjectInfo";
import SelectUtilities from "../Secondary/components/SelectUtilities";
import { useHistory } from "react-router-dom";
import slugs from "../../../resources/slugs";
import InputTextFieldSearch from "../Secondary/components/InputTextFieldSearch";

import ImagePicker from "../Secondary/components/ImagePicker";
import {
  useProvinces,
  useDistricts,
  useWards,
  useStreets,
  useProjectStatus,
} from "../../../graphql/schemas/masterdata/address";
import { GET_UTILITIES } from "../../../graphql/schemas/masterdata/masterDataQueries";
import { usePrjects, useUtilities } from "../../../graphql/schemas/hooks";
import {
  GET_PROJECT_TYPES,
  PROJECT_GET_ALL,
} from "../../../graphql/schemas/product/projectQueries";
import { INSERT_PRIMARY_PRODUCT } from "../../../graphql/schemas/product/mutations";
import swal from "sweetalert";
import { Save, CastConnected } from "@material-ui/icons";
import ButtonReturn from "../../../components/ButtonReturn";
import { checkedPermission, jsUcfirst } from "../../../helper";
import { StoreContext } from "../../../resources/store";

import { USER_ROLE_SUPPER_ADMIN } from '../../../constants/defined';

function PrimaryProductCreate(props) {
  const { userInfo, accessPermissions, accessObjectPermissions } = useContext(StoreContext);
  const [disabled, setDisabled] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [draftObject, setDraftObject] = useState(
    _.cloneDeep(primary_product_template)
  );
  const [insertPrimaryProduct] = useMutation(INSERT_PRIMARY_PRODUCT, {
    refetchQueries: [
      {
        query: PROJECT_GET_ALL,
        variables: {
          skip: 0,
          take: 10,
          order_by: { created: "desc" },
          where: {
            deleted: { _eq: false },
            propertyTypeByPropertyType: {
              code: {
                _eq: "P",
              },
            },
          },
        },
      },
    ],
  });

  // get project list
  const { data: projectList, loading: projectLoading } = usePrjects();
  const [projectsAvailable, setProjectsAvailable] = useState(false);

  const projectStatus = useProjectStatus();

  const provinces = useProvinces();
  const districts = useDistricts({
    provinceId: _.get(draftObject, "province.id"),
  });
  const wards = useWards({ districtId: _.get(draftObject, "district.id") });
  const streets = useStreets({ districtId: _.get(draftObject, "district.id") });
  const [uploadProductDocuments, setUploadProductDocuments] = useState([]);
  const [uploadProductImages, setUploadProductImages] = useState([]);
  const [uploadAvatarImage, setUploadAvatarImage] = useState();

  const projectTypes = useQuery(GET_PROJECT_TYPES, {});
  const utilitiList = useQuery(GET_UTILITIES, {});

  const [advantages, setAdvantages] = useState([]);
  let advantageData = _.filter(_.get(utilitiList, "data.results"), (item) => {
    return item.type === 1;
  });

  const [utilities, setUtilities] = useState([]);
  let utilitiesData = _.filter(_.get(utilitiList, "data.results"), (item) => {
    return item.type === 2;
  });
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    let productImgs = _.map(uploadProductImages, (item) => {
      return item.id;
    });
    let docImgs = _.map(uploadProductDocuments, (item) => {
      return item.id;
    });
    let product_image_list = _.concat(productImgs, docImgs);

    let tmp = { ...draftObject };
    _.set(tmp, "product_image_list", product_image_list);
    if (uploadAvatarImage) {
      _.set(tmp, "investor_image", uploadAvatarImage[0]);
    }

    setDraftObject(tmp);
  }, [uploadProductImages, uploadProductDocuments, uploadAvatarImage]);

  //Set condition enable
  useEffect(() => {
    if (
      !_.isEmpty(uploadProductImages) &&
      _.get(draftObject, "name") !== null &&
      _.get(draftObject, "type_list") !== null &&
      _.get(draftObject, "investor") !== null &&
      _.get(draftObject, "longitude") !== null &&
      _.get(draftObject, "latitude") !== null
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [uploadProductImages, draftObject]);

  const saveDraft = (e) => {
    e.preventDefault();
    let pushObject = draftObject;
    _.set(pushObject, "draft", true);
    let typeList = pushObject.type_list;
    _.isArray(typeList)
      ? _.set(pushObject, "type_list", typeList)
      : _.set(pushObject, "type_list", [typeList]);
    _.set(pushObject, "account", _.get(userInfo, "id"));

    if (!_.isEmpty(utilities)) {
      _.set(pushObject, "utilities", utilities);
    }

    if (!_.isEmpty(advantages)) {
      _.set(pushObject, "outstanding_advantages", advantages);
    }

    if (!_.isEmpty(_.get(pushObject, "province"))) {
      _.set(pushObject, "province", _.get(pushObject, "province.id"));
    }

    if (!_.isEmpty(_.get(pushObject, "district"))) {
      _.set(pushObject, "district", _.get(pushObject, "district.id"));
    }

    if (!_.isEmpty(_.get(pushObject, "ward"))) {
      _.set(pushObject, "ward", _.get(pushObject, "ward.id"));
    }

    if (!_.isEmpty(_.get(pushObject, "street"))) {
      _.set(pushObject, "street", _.get(pushObject, "street.id"));
    }

    if (selectedStatus) {
      _.set(pushObject, "project_status", selectedStatus);
    }

    insertPrimaryProduct({
      variables: {
        data: pushObject,
      },
    }).then(
      (res) => {
        swal({
          title: "Lưu nháp sản phẩm thành công",
          icon: "success",
        }).then(() => {
          history.push(slugs.productPrimary);
        });
      },
      (error) => {
        swal("Vui lòng thử lại", "Có lỗi khi lưu nháp sản phẩm!", "error");
      }
    );
  };

  const handleSaveProject = (e) => {
    e.preventDefault();
    let pushObject = draftObject;
    let typeList = pushObject.type_list;
    _.isArray(typeList)
      ? _.set(pushObject, "type_list", typeList)
      : _.set(pushObject, "type_list", [typeList]);
    _.set(pushObject, "account", _.get(userInfo, "id"));

    if (!_.isEmpty(utilities)) {
      _.set(pushObject, "utilities", utilities);
    }

    if (!_.isEmpty(advantages)) {
      _.set(pushObject, "outstanding_advantages", advantages);
    }

    if (!_.isEmpty(_.get(pushObject, "province"))) {
      _.set(pushObject, "province", _.get(pushObject, "province.id"));
    }

    if (!_.isEmpty(_.get(pushObject, "district"))) {
      _.set(pushObject, "district", _.get(pushObject, "district.id"));
    }

    if (!_.isEmpty(_.get(pushObject, "ward"))) {
      _.set(pushObject, "ward", _.get(pushObject, "ward.id"));
    }

    if (!_.isEmpty(_.get(pushObject, "street"))) {
      _.set(pushObject, "street", _.get(pushObject, "street.id"));
    }

    if (selectedStatus) {
      _.set(pushObject, "project_status", selectedStatus);
    }

    insertPrimaryProduct({
      variables: {
        data: pushObject,
      },
    }).then(
      (res) => {
        swal({
          title: "Đăng sản phẩm thành công",
          icon: "success",
        }).then(() => {
          history.push(slugs.productPrimary);
        });
      },
      (error) => {
        swal("Vui lòng thử lại", "Có lỗi khi đăng sản phẩm!", "error");
      }
    );
  };

  const handleChangeProjectStatus = (e) => {
    setSelectedStatus(parseInt(e.target.value));
  };



  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.headerStyle}>
          <Typography gutterBottom variant="h4" component="h2">
            Tạo dự án mới
          </Typography>
        </div>

        <FormControl className={classes.formContainer}>
          <Grid container className={classes.groupRow}>
            <Grid item xs={12}>
              <Typography component="div" className={classes.title}>
                Tên dự án{" "}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <InputTextFieldSearch
                {...props}
                description="Nhập tên dự án"
                fieldname="name"
                inputType="project"
                object={draftObject}
                setObject={setDraftObject}
                setProjectsAvailable={setProjectsAvailable}
                datalist={projectList}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography component="div" className={classes.title}>
                Vị trí
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <InputTextFieldSearch
                {...props}
                description="Chọn thành phố"
                fieldname="province"
                object={draftObject}
                setObject={setDraftObject}
                disabled={projectsAvailable}
                datalist={provinces}
              />
            </Grid>

            <Grid item container xs={5} justifyContent="space-around">
              <Typography component="div" aria-required>
                Latitude <span style={{ color: "red" }}>*</span>
              </Typography>

              <InputTextField
                number
                fieldname="latitude"
                object={draftObject}
                setObject={setDraftObject}
              />
            </Grid>

            <Grid item xs={7}>
              <InputTextFieldSearch
                {...props}
                description="Chọn quận"
                fieldname="district"
                object={draftObject}
                setObject={setDraftObject}
                disabled={projectsAvailable}
                datalist={districts}
              />
            </Grid>
            <Grid item container xs={5} justifyContent="space-around">
              <Typography component="div" aria-required>
                Longitude <span style={{ color: "red" }}>*</span>
              </Typography>
              <InputTextField
                number
                fieldname="longitude"
                object={draftObject}
                setObject={setDraftObject}
              />
            </Grid>
            <Grid item xs={7}>
              <InputTextFieldSearch
                {...props}
                description="Chọn phường"
                fieldname="ward"
                object={draftObject}
                setObject={setDraftObject}
                disabled={projectsAvailable}
                datalist={wards}
              />
            </Grid>
            <Grid item xs={7}>
              <InputTextFieldSearch
                {...props}
                description="Chọn tên đường"
                fieldname="street"
                object={draftObject}
                setObject={setDraftObject}
                disabled={projectsAvailable}
                datalist={streets}
              />
            </Grid>
            <Grid item xs={7}>
              <InputTextField
                fieldname="address"
                description="Nhập số nhà"
                fullwidth
                object={draftObject}
                setObject={setDraftObject}
              />
            </Grid>
          </Grid>
          <div className={classes.marginDivider} />
          <Grid container className={classes.groupRow}>
            <Grid item xs={2}>
              <Typography
                component="div"
                className={clsx(classes.title, "required")}
              >
                Loại hình
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <InputDropdown
                fieldname="type_list"
                discription="Chọn loại hình"
                fullwidth
                object={draftObject}
                setObject={setDraftObject}
                data={_.filter(
                  _.get(projectTypes, "data.property_type"),
                  (item, key) => item.code !== "P"
                )}
              />
            </Grid>
          </Grid>
          <div className={classes.marginDivider} />
          <Grid container className={classes.groupRow}>
            <Grid item xs={12}>
              <Typography component="div" className={classes.title}>
                Giá
              </Typography>
              <Typography
                component="div"
                className={clsx(classes.label, "description")}
              >
                Điền một trong hai giá bên dưới
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="div" className={classes.label}>
                Giá (VNĐ)
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <InputTextField
                number
                fieldname="price_from"
                fullwidth
                object={draftObject}
                setObject={setDraftObject}
              />
            </Grid>
            <Grid item xs={1}>
              <p className={classes.to}>đến</p>
            </Grid>
            <Grid item xs={3}>
              <InputTextField
                number
                fieldname="price_to"
                fullwidth
                object={draftObject}
                setObject={setDraftObject}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography component="div" className={classes.label}>
                Giá (triệu/m2)
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <InputTextField
                number
                fieldname="price_from_milion"
                fullwidth
                object={draftObject}
                setObject={setDraftObject}
              />
            </Grid>
            <Grid item xs={1}>
              <p className={classes.to}>đến</p>
            </Grid>
            <Grid item xs={3}>
              <InputTextField
                number
                fieldname="price_to_milion"
                fullwidth
                object={draftObject}
                setObject={setDraftObject}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="div"
                className={clsx(classes.title, "required")}
              >
                Hình ảnh sản phẩm
              </Typography>
              <Typography
                component="div"
                className={clsx(classes.label, "description")}
              >
                Tối đa 10 hình, dung lượng 5MB/hình
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridRow}>
              <ImagePicker
                data={uploadProductImages}
                setData={setUploadProductImages}
                numberColumn={4}
                buttonType="CARD"
                type="PHOTO"
              />
            </Grid>
          </Grid>
          <div style={{ marginTop: "20px" }} />
          <Grid container className={classes.groupRow}>
            <Grid item xs={2}>
              <Typography component="div" className={classes.label}>
                Link Video
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <InputTextField
                fieldname="video_url"
                fullwidth
                object={draftObject}
                setObject={setDraftObject}
              />
            </Grid>
          </Grid>
          <div style={{ marginTop: "20px" }} />
          <Grid container className={classes.groupRow}>
            <Grid item xs={2}>
              <Typography component="div" className={classes.label}>
                Link 3D
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <InputTextField
                fieldname="view_3d_url"
                fullwidth
                object={draftObject}
                setObject={setDraftObject}
              />
            </Grid>
          </Grid>
          <div className={classes.marginDivider} />
          <Investor
            draftObject={draftObject}
            setDraftObject={setDraftObject}
            onChange={(id) => setUploadAvatarImage(id)}
          />
          <div className={classes.marginDivider} />
          <ProjectInfo
            draftObject={draftObject}
            setDraftObject={setDraftObject}
          />
          <div className={classes.marginDivider} />
          <Grid container className={classes.groupRow}>
            <SelectUtilities
              title="Ưu điểm nổi bật"
              data={advantageData}
              object={advantages}
              setObject={setAdvantages}
            />
          </Grid>
          <div className={classes.marginDivider} />
          {/* <Grid container>
                        <Grid item xs={2}>
                            <Typography component='div' className={classes.label}>Nhập những ưu điểm khác</Typography>
                            <Typography component='div' className={clsx(classes.label, 'description')}>Tối đa 3 ưu điểm</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <InputField limit={4}
                                fieldname='investor.operation_unit'
                                object={draftObject}
                                setObject={setDraftObject} />
                        </Grid>
                    </Grid> */}

          <div className={classes.marginDivider} />
          <Grid container className={classes.groupRow}>
            <SelectUtilities
              title="Tiện ích nội khu"
              data={utilitiesData}
              object={utilities}
              setObject={setUtilities}
            />
          </Grid>
          <div className={classes.marginDivider} />
          {/* <Grid container>
                        <Grid item xs={2}>
                            <Typography component='div' className={classes.label}>Tiện ích nội khu khác</Typography>
                            <Typography component='div' className={clsx(classes.label, 'description')}>Tối đa 3 tiện ích</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <InputField limit={3}
                                fieldname='other_utilities'
                                object={draftObject}
                                setObject={setDraftObject} />
                        </Grid>
                    </Grid> */}
          {/* <div className={classes.marginDivider} />
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography component='div' className={classes.label}>Tên tài liệu</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <InputTextField
                                fieldname='investor_document_name'
                                fullwidth
                                object={draftObject}
                                setObject={setDraftObject}
                            />
                        </Grid>
                    </Grid> */}
          <div className={classes.marginDivider} />
          <Grid container>
            <Grid item xs={2}>
              <Typography component="div" className={classes.label}>
                Tài liệu từ chủ đầu tư
              </Typography>
              <Typography
                component="div"
                className={clsx(classes.label, "description")}
              >
                PDF - Kích thước tối đa 5MB
              </Typography>
            </Grid>
            <ImagePicker
              data={uploadProductDocuments}
              setData={setUploadProductDocuments}
              numberColumn={4}
              buttonType="CARD"
              type="DOCUMENT"
            />
          </Grid>

          <div className={classes.marginDivider} />

          <Grid container>
            <Grid item xs={2}>
              <b className={classes.textLabelForm}>
                Trạng thái dự án <span style={{ color: "red" }}>*</span>
              </b>
            </Grid>
            <Grid>
              <RadioGroup
                a
                aria-label="project_status"
                name="project_status"
                value={selectedStatus}
                onChange={handleChangeProjectStatus}
                row
              >
                {_.map(projectStatus, (item) => (
                  <FormControlLabel
                    key={_.get(item, "id")}
                    style={{ width: "160px" }}
                    control={
                      <Radio
                        size="small"
                        value={_.get(item, "id")}
                        required
                        defaultChecked={selectedStatus}
                      />
                    }
                    label={
                      <span style={{ fontSize: "16px" }}>
                        {jsUcfirst(_.get(item, "name"))}
                      </span>
                    }
                  />
                ))}
              </RadioGroup>
            </Grid>
          </Grid>
          <div className={classes.marginDivider} />

          <Grid container>
            <Grid item xs={2}>
              <Typography component="div" className={classes.label}>
                Mô tả
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <InputTextField
                fieldname="description"
                fullwidth
                multiline={true}
                object={draftObject}
                setObject={setDraftObject}
                maxLength={1000}
              />
            </Grid>
          </Grid>
          <div className={classes.marginDivider} />
          <Grid container>
            <Grid item xs={2}>
              <Typography component="div" className={classes.label}>
                Ghi chú tin đăng
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <InputTextField
                fieldname="note"
                fullwidth
                multiline={true}
                object={draftObject}
                setObject={setDraftObject}
                maxLength={500}
              />
            </Grid>
          </Grid>
          <div className={classes.marginDivider} />
        </FormControl>

        <div className={classes.footerStyle}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CastConnected />}
            onClick={saveDraft}
          >
            Lưu nháp
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveProject}
            disabled={disabled}
            startIcon={<Save />}
          >
            Hoàn thành
          </Button>

          <ButtonReturn link={slugs.productPrimary} />
        </div>
      </Paper>
    </>

  );
}

export default PrimaryProductCreate;
