import React from "react";
import { Grid, CircularProgress, makeStyles } from "@material-ui/core";
import ItemHistory from "./ItemHistory";
import _ from "lodash";
import { useGetUpdateHistory } from "../hooks";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  wapperLoading: {
    width: '100%',
    height: 'calc(100vh - 113px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const UpdateHistory = () => {
  const { id } = useParams();
  const classes = useStyles();
  const { loading, data } = useGetUpdateHistory(id);

  if(loading) {
    return (
      <div className={classes.wapperLoading}>
          <CircularProgress />
      </div>
    )
  };

  return (
    <Grid container direction="column">
      {_.map(data, (item) => (
        <ItemHistory gridItem={true} data={item} />
      ))}
    </Grid>
  );
};

export default UpdateHistory;
