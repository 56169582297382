import { Grid } from '@material-ui/core';
import moment from 'moment';
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import iconEcoe from '../../../assets/images/banner/logoEcoe.png'
const useStyles = makeStyles(theme => ({
    wrapperItem: {
        display: 'grid',
        gridTemplate: '72px / 100px auto 150px',
        alignItems: 'center',
        fontSize: '16px',

        '& .right': {
            textAlign: 'right'
        },

        '& img': {
            width: '48px',
            height: '48px',

        }
    }
}))

const BranchActivityHistory = () => {
    const classes = useStyles();
    return <div className={classes.wrapperItem}>Coming soon</div>
    return (
        <Fragment>
            {
                _.map([1,2,3,4], (item, key) => (
                    <div key={key} className={classes.wrapperItem}>
                        <div>
                            <img src={iconEcoe} alt='testing' />
                        </div>
                        <div>{item}. Content Lịch sử hoạt động</div>
                        <div className={'right'}>{moment(new Date()).format('DD/MM/YYYY h:mm A')}</div>
                    </div>
                ))
            }
        </Fragment>
    )
}

export default BranchActivityHistory;
