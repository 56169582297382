import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";

const InputDateAndTimePicker = ({
  setDateTime,
  defaultValue,
  fromDate,
  toDate,
  validateFalse,
  format = "hh:mm a - dd/MM/yyyy",
  width = 250,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#ffffff",
      width,
      border: "1px solid lightgray",
      padding: "5px 0px 5px 15px",
      borderRadius: "5px",
      margin: "0px",
      
      "& > p": {
        margin: 0,
        position: 'absolute',
        bottom: '-24px',
        left: '0px',
      },

      "& > div > div:last-child": {
        marginLeft: 0,

        "& > button": {
          padding: "8px",
        },
      },
    },
    note: {
      color: "#f00",
      
    },
  }));

  const classes = useStyles();
  const now = new Date();
  const [selectedDate, setSelectedDate] = useState(() => {
    return now
  });
  const [isValidate, setIsValidate] = useState(true);

  useEffect(() => {
    setSelectedDate(defaultValue)
  },[defaultValue])

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDateTime(date);
    if (
      (fromDate > 0 || toDate > 0) &&
      (Date.parse(date) < Date.parse(now) + 6000 ||
        Date.parse(date) < fromDate + 60000 ||
        Date.parse(date) > toDate - 60000)
    ) {
      setIsValidate(false);
    } else {
      setIsValidate(true);
    }
  };


  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        className={classes.root}
        margin="normal"
        variant="inline"
        format={format}
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
        InputProps={{ disableUnderline: true }}
        invalidDateMessage={<span className={classes.note}>Dữ liệu không hợp lệ</span>}
      />
      {(!isValidate || validateFalse) && (
        <span className={classes.note}>Dữ liệu không hợp lệ</span>
      )}
    </MuiPickersUtilsProvider>
  );
};

export default InputDateAndTimePicker;
