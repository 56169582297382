import * as _ from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
    MenuItem, FormControl, Select
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        '&:not(.fullwidth)': {
            width: 250,
        },
        '&.fullwidth': {
            width: '100%',
        },
    },
    selectEmpty: {
        color: 'rgba(0, 0, 0, 0.38)'
    },
    defaultPadding: {
        '&>div': {
            padding: '10.5px 14px',
        },
    },

}));

const statusSold = (extendedSold) => {
    return {
            "type_workflow_next_step": {
                "id": extendedSold,
                "type_step": {
                    "id": 1000,
                    "code": "Sold",
                    "name": "Đã bán"
                }
            }
        }
    
};

const InputNextStepDropdown = ({ discription = "Chọn", object, setObject, data, required, fullwidth, disabled=false, extendedSold=undefined }) => {
    const classes = useStyles();
    
    const [selectValue, setSelectValue] = useState("");
    const [listStatus, setListStatus] = useState();

    const handleChange = (e) => {
        e.preventDefault();
        const value = _.get(e, 'target.value');
        if ((object) === value) return;
        setSelectValue(value);
        setObject(value);
    };

    useEffect(()=>{
        let tmp = [];
        if(_.size(data) > 0) {
            tmp = _.map(JSON.parse(JSON.stringify(data)), obj => {
                delete obj.__typename;
                delete obj.type_workflow_next_step.__typename;
                delete obj.type_workflow_next_step.type_step.__typename;
                return obj
            });
        }
        if(extendedSold) {
            tmp = [...tmp, statusSold(extendedSold)];
        }
        setListStatus(tmp);
    }, [data, extendedSold]);

    return (
        <Fragment>
            <FormControl variant="outlined" disabled={disabled} className={clsx(classes.formControl, { 'fullwidth': fullwidth })}>
                <Select
                    className={clsx(classes.defaultPadding, selectValue === "" && classes.selectEmpty)}
                    id={`input-dropdown-nextstep`}
                    value={selectValue}
                    onChange={handleChange}
                    displayEmpty
                >
                    <MenuItem value="" disabled>{discription}</MenuItem>
                    {
                        _.map(listStatus, (item, key) => {
                            return (
                                <MenuItem 
                                    key={key}
                                    value={_.get(item, 'type_workflow_next_step.id')}
                                >
                                    {_.get(item, 'type_workflow_next_step.type_step.name')}
                                </MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        </Fragment>
    );
};

InputNextStepDropdown.propTypes = {
    fieldname: PropTypes.string,
    object: PropTypes.object,
    setObject: PropTypes.func,
    discription: PropTypes.string,
    data: PropTypes.array,
};

export default InputNextStepDropdown;
