import * as _ from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Typography, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { validPhone } from '../../../Customer/regex';

const useStyles = makeStyles(theme => ({

    labelStyle: {
        fontWeight: 'bold',
        paddingRight: '30px',
        position: 'relative',

        '&.required:after': {
            content: '"*"',
            fontSize: '18px',
            lineHeight: 1,
            position: 'absolute',
            color: 'red',
            paddingLeft: '5px',
        },
        '&.unit > span': {
            position: 'absolute',
            right: 0,
            bottom: 0,
            fontWeight: 'normal',
            opacity: '.5',
        },
        '&.description': {
            paddingTop: '10px',
            fontStyle: 'italic',
            fontWeight: 'normal',
        },
    },

    inputStyle: {
        '&:not(.fullwidth)': {
            width: 250,
        },
        '&.fullwidth': {
            width: '100%',
        },
        backgroundColor: 'white',
        marginLeft: '0',
        '&.disabled': {
            background: '#f7f7f7',
        }
    },

}));

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator

        isNumericString
      />
    );
  });

const InputTextField = ({ label, setMoney, fieldname, description, required, object, setObject, number, fullwidth, multiline, disabled, defaultValue, maxLength, modeEdit, setDisabled}) => {

    const classes = useStyles();
    const [inputValue, setInputValue] = useState(_.get(object, fieldname));
    const [error,setError] = useState({
        phoneError: false
    })

    const onBlurSetValue = (event) => {
        const value = _.trim(inputValue);//_.trim(_.get(event, 'target.value'));
        if (_.get(object, fieldname) === value) return;
        if (_.isEmpty(value) && inputValue === undefined) return;
        let tempObject = { ...object };
        if ((_.isEmpty(value) && inputValue !== undefined) || value === "") {
            if(modeEdit){
                _.set(tempObject, fieldname, number ? 0 : "");
            } else {
                tempObject = { ..._.omit(object, fieldname) };
            }            
        }
        if (!_.isEmpty(value)) {
            _.set(tempObject, fieldname, number ? value * 1 : value);
        }

        //condition to edit
        if (_.isEmpty(value) && !_.isEmpty(defaultValue)) {
            _.set(tempObject, fieldname, null);
        }

        if (_.isFunction(setObject)) setObject(tempObject);
    };

    const onchangeAction = (e) => {
        let value = _.get(e, 'target.value');
        if (fieldname === "price") {
            setMoney(e.target.value);
        }
        if (number) {
            if (value < 0) {
                return setInputValue(null);
            }
        }
        if(fieldname === "phone" && !validPhone.test(_.get(e, 'target.value'))){
            setError((prev) => {
                return {
                    ...prev,
                    phoneError: true
                }
            })
            setDisabled(true)
        }else{
            setError((prev) => {
                return {
                    ...prev,
                    phoneError: false
                }
            })
            fieldname === "phone" && setDisabled(false)
        }
        setInputValue(_.get(e, 'target.value'));
        // if(fieldname === "phone" && !validPhone.test(inputValue)){
        //     setError((prev) => {
        //         return {
        //             ...prev,
        //             phoneError: true
        //         }
        //     })
        //     setDisabled(error.phoneError)
        // }
        // console.log(error.phoneError)
    };


    useEffect(() => {
        setInputValue(_.get(object, fieldname) || '');
    }, [object])

    return (
        <Fragment>
            {!_.isEmpty(label) && <Typography component='span' className={clsx(classes.labelStyle, { 'required': required })}>{label}</Typography>}

            <TextField
                size="small" variant="outlined"
                placeholder={_.isEmpty(inputValue) && description}
                value={inputValue}
                disabled={disabled}
                className={clsx(classes.inputStyle, { 'fullwidth': (fullwidth || multiline) }, { 'disabled': disabled })} required
                multiline={multiline}
                rows={multiline ? 5 : 1}
                rowsMax={multiline ? 5 : 1}
                id={`input-textfield-${fieldname}`}
                onBlur={onBlurSetValue}
                onChange={onchangeAction}
                autoComplete='off'
                inputProps={maxLength && { maxLength }}
                // type={number ? "number" : "text"}
                InputProps={{
                    inputComponent: number ? NumberFormatCustom : undefined,
                }}
                helperText={
                    error.phoneError &&
                    (
                      <div style={{color:'red'}}>Số điện thoại không hợp lệ</div>
                    )
                  }
                defaultValue={defaultValue}
            />
        </Fragment >
    );
}

InputTextField.propTypes = {
    label: PropTypes.string,
    fieldname: PropTypes.string,
    description: PropTypes.string,
    required: PropTypes.bool,
    number: PropTypes.bool,
    object: PropTypes.object,
    setObject: PropTypes.func,
    fullwidth: PropTypes.bool,
    multiline: PropTypes.bool,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
    modeEdit: PropTypes.bool,
};

InputTextField.defaultProps = {
    required: false,
    number: false,
    fullwidth: false,
    multiline: false,
    disabled: false,
    modeEdit: false,
};

export default InputTextField;
