import React, { Fragment, useContext, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import { Warning } from '@material-ui/icons';
import moment from "moment";
import Carousel from 'react-material-ui-carousel';
import ImageViewer from "react-simple-image-viewer";
import { ConfigContext } from '../../context/ConfigContext';


const useStyles = makeStyles((theme) => ({
    titleContent: {
        fontWeight: 'bold',
        fontSize: '20px',
    },
    noteContent: {
        color: '#DA6744'
    },
    labelContent: {
        fontWeight: 'bold'
    },
    wapperInfo: {
        color: '#fff',
        borderRadius: '6px',

        position: 'relative',
        padding: '30px 8px !important',

        '&:after': {
            content: `'1'`,
            position: 'absolute',
            top: '0px',
        }
    },
    usingInfo: {
        background: '#7A43B7'
    },
    trafficInfo: {
        background: '#B79843'
    },
    wrapperCarousel: {
        '& > div': {
            height: '200px',
            maxWidth: '300px',

            // '& .CarouselItem': {
            //     width: '320px',
            // },

            '& img.thumb': {
                height: '200px',
                width: '100%',
                objectFit: 'cover',
                background: '#ccc',
            }
        }
    },
}));



const PlanningInformation = ({ data, photos }) => {

    const classes = useStyles();
    const [isViewerImagePersonalId, setIsViewerImagePersonalId] = useState({ view: false, key: 0 });
    const configValue = useContext(ConfigContext);

    return (
        <Fragment>
            {isViewerImagePersonalId?.view && (
                <ImageViewer
                    src={
                        _.map(photos,
                            (element) => configValue.MEDIA_DOMAIN + _.replace(_.get(element, 'media_detail.url'), "maxresdefault.jpg", "default") + `.${_.last(_.split(_.get(element, 'media_detail.name'), '.'))}`
                        )
                    }
                    onClose={() => setIsViewerImagePersonalId({ view: false, key: 0 })}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)",
                        zIndex: "9999"
                    }}
                    currentIndex={isViewerImagePersonalId?.key}
                />
            )}

            {
                _.size(photos) > 0 && (
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}><Typography style={{ fontWeight: 'bold' }}>Hình ảnh sổ/HĐMB từ áp KH</Typography></Grid>
                            <Grid item xs={12} className={classes.wrapperCarousel}>
                                <Carousel
                                    navButtonsAlwaysVisible={true}
                                    navButtonsAlwaysInvisible={_.size(photos) < 2}
                                    indicators={false}
                                    animation='slide'
                                    duration={1500}
                                >
                                    {
                                        _.map(photos, (item, i) =>
                                            <img
                                                key={i}
                                                className={'thumb'}
                                                src={configValue.MEDIA_DOMAIN + _.get(item, 'media_detail.url')}
                                                onClick={(e) => setIsViewerImagePersonalId({ view: true, key: i })}
                                            />
                                        )
                                    }
                                </Carousel>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }

            <Grid container spacing={2} alignItems='stretch'>
                {/* <Grid item xs={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className={classes.titleContent}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '550px', background: '#ccc', borderRadius: '6px' }}>Bản đồ quy hoạch</div>
                        </Grid>
                    </Grid>
                </Grid> */}

                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className={classes.titleContent} style={{ marginTop: '30px' }}>Thông tin quy hoạch</Grid>

                        <Grid item xs={12} className={classes.noteContent}><Warning fontSize='small' />Thông tin chỉ mang tính chất tham khảo</Grid>

                        <Grid item xs={5}>Tỉnh/ Thành phố</Grid>
                        <Grid item xs={7} className={classes.labelContent}>{_.get(data, 'address.province.name') || '-'}</Grid>

                        <Grid item xs={5}>Quận/Huyện</Grid>
                        <Grid item xs={7} className={classes.labelContent}>{_.get(data, 'address.district.name') || '-'}</Grid>

                        <Grid item xs={5}>Phường/Xã</Grid>
                        <Grid item xs={7} className={classes.labelContent}>{_.get(data, 'address.ward.name') || '-'}</Grid>

                        <Grid item xs={5}>Số tờ</Grid>
                        <Grid item xs={7} className={classes.labelContent}>{_.get(data, 'map_sheet') || '-'}</Grid>

                        <Grid item xs={5}>Số thửa</Grid>
                        <Grid item xs={7} className={classes.labelContent}>{_.get(data, 'land_plot') || '-'}</Grid>

                        <Grid item xs={5}>Tổng diện tích</Grid>
                        <Grid item xs={7} className={classes.labelContent}>{_.get(data, 'area') || '-'}</Grid>

                        <Grid item xs={5}>Ngày cấp sổ</Grid>
                        <Grid item xs={7} className={classes.labelContent}>{_.get(data, 'issue_date') ? moment(_.get(data, "issue_date")).format("DD/MM/yyyy") : '-'}</Grid>

                        <Grid item xs={12} className={classes.titleContent}>Chi tiết quy hoạch sử dụng đất</Grid>

                        <Grid item xs={5}>Đồ án</Grid>
                        <Grid item xs={7} className={classes.labelContent}>{_.get(data, 'remark') || '-'}</Grid>

                        {/* 
                        <Grid item xs={12} className={`${classes.wapperInfo} ${classes.usingInfo}`}>
                            <Grid container spacing={1}>
                                <Grid item xs={5}>Mã ô chức năng</Grid>
                                <Grid item xs={7} className={classes.labelContent}>A19</Grid>
                                <Grid item xs={5}>Diện tích</Grid>
                                <Grid item xs={7} className={classes.labelContent}>83.62 m2</Grid>
                                <Grid item xs={5}>Chức năng SDĐ</Grid>
                                <Grid item xs={7} className={classes.labelContent}>Đất nhóm nhà ở hiện hữu cải tạo</Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.titleContent}>Chi tiết giao thông</Grid>

                        <Grid item xs={12} className={`${classes.wapperInfo} ${classes.trafficInfo}`}>
                            <Grid container spacing={1}>
                                <Grid item xs={5}>Tên đường</Grid>
                                <Grid item xs={7} className={classes.labelContent}>Đường Bạch Đằng</Grid>
                                <Grid item xs={5}>Lộ giới</Grid>
                                <Grid item xs={7} className={classes.labelContent}>20 m</Grid>
                                <Grid item xs={5}>Diện tích</Grid>
                                <Grid item xs={7} className={classes.labelContent}>124.81 m2</Grid>
                                <Grid item xs={5}>Hướng so với thửa đất</Grid>
                                <Grid item xs={7} className={classes.labelContent}>Hướng Bắc</Grid>
                            </Grid>
                        </Grid>
                        */}

                        <Grid item xs={12} className={classes.titleContent}>Bảng tọa độ góc ranh</Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={2} className={classes.labelContent}>TT</Grid>
                                <Grid item xs={5} className={classes.labelContent}>X(m)</Grid>
                                <Grid item xs={5} className={classes.labelContent}>Y(m)</Grid>
                            </Grid>
                            {
                                _.map(_.get(data, 'points'), (item, key) => (
                                    <Grid container spacing={2} key={key}>
                                        <Grid item xs={2}>{key + 1}</Grid>
                                        <Grid item xs={5}>{_.get(item, 'x')}</Grid>
                                        <Grid item xs={5}>{_.get(item, 'y')}</Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default PlanningInformation;
