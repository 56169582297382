import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, TextField } from "@material-ui/core";
import { GET_BRANCH_BY } from "../graphql/schemas/masterdata/branches/index";
import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";

import * as _ from "lodash";

AutoCompleteBranch.propTypes = {};
const useStyles = makeStyles((theme) => ({
  input: {
    width: 250,
    backgroundColor: "white",
    marginLeft: "0",
    "& > .MuiFormHelperText-marginDense": {
      backgroundColor: "#FAFAFA",
      margin: 0,
    },
  },
}));

function AutoCompleteBranch({
  type,
  onChange,
  value,
  defaultValue = undefined,
  width = undefined,
  placeholder,
}) {
  const [searchText, setSearchText] = useState("");
  const classes = useStyles();

  const handleOnFocus = () => {
    setSearchText("");
  };

  var listOption = [];
  const variables = {
    where: {
      _or: [
        { name: searchText === "" ? undefined : { _ilike: `%${searchText}%` } },
        { code: searchText === "" ? undefined : { _ilike: `%${searchText}%` } },
      ],
      deleted: { _eq: false },
      active: { _eq: true }
    },
  };

  const { loading, data } = useQuery(GET_BRANCH_BY, {
    variables: variables,
    fetchPolicy: "no-cache"
  });

  if (!_.isEmpty(_.get(data, "results"))) {
    listOption = _.map(_.get(data, "results"), (item) => {
      return {
        id: _.get(item, "id"),
        code: _.get(item, "code"),
        name: _.get(item, "name"),
      };
    });
  }

  const handleSelect = (e, value) => {
    try {
      if (value) {
        onChange(value.id);
      } else {
        onChange(null);
      }
    } catch (error) {
      console.log("auto complete customer error", error);
    }
  };
  if (loading) return <CircularProgress />;

  return (
    <Autocomplete
      id={`combo-box-${type}`}
      fullWidth={true}
      options={listOption}
      getOptionLabel={(option) => {
        if(option?.code || option?.name)
          return (`${_.toString(option?.code)} - ${_.toString(option?.name)}`)
        else
          return null
      }}
      onChange={handleSelect}
      defaultValue={{
        code: _.get(_.find(listOption, ["id", defaultValue ? defaultValue : value]), "code"),
        name: _.get(_.find(listOption, ["id", defaultValue ? defaultValue : value]), "name"),
      }}
      className={classes.input}
      loading={loading}
      renderInput={(params) => {
        return(
          <TextField
            {...params}
            variant="outlined"
            size="small"
            placeholder={placeholder}
            value={value}
            fullWidth={true}
            style={{ backgroundColor: "#ffffff", width }}
            // onChange={(e) => setSearchText(e.target.value)}
            onFocus={handleOnFocus}
          />
        )
      }}
    />
  );
}

export default AutoCompleteBranch;
