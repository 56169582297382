import * as _ from "lodash";
import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import {
  Paper,
  Typography,
  Button,
  Grid,
  Select,
  FormControl,
  MenuItem,
  TextField,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import TableComponent from "../../components/TableComponent";
import {
  useBranches,
  useDepartments,
  useEmployeeByRole,
  usePositions,
} from "./hooks";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import slug from "../../resources/slugs";
import {
  PersonAdd,
  Search,
  Settings as SettingsIcon,
  Star as StarIcon,
  Description as DescriptionIcon,
  CloudUpload,
  CloudDownloadOutlined,
  CloudUploadOutlined,
} from "@material-ui/icons";
import Moment from "react-moment";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
  GET_EMPLOYEE_BY_ROLE,
  GET_EMPLOYEE_BY_ROLE_SUMMARY,
  GET_LIST_EMPLOYEE,
} from "../../graphql/schemas/employee/employeeQueries";
import swal from "sweetalert";
import { useDebounce } from "use-debounce";
import moment from "moment";
import * as XLSX from "xlsx";
import ButtonStatusList from "./components/ButtonStatusList";
import clsx from "clsx";
import { useGetListAccountType } from "../Permission/hooks";
import { validPhone } from "../Customer/regex";
import { validEmail } from "../../components/customer/regex";
import { GET_BRANCHES } from "../../graphql/schemas/masterdata/branches";
import ButtonExportExcel from "../../components/ButtonExportExcel";
import { StoreContext } from "../../resources/store";
import axios from "axios";
import {
  CREATE_EMPLOYEE,
  EXPORT_EMPLOYEE,
  USER_ROLE_ADMINISTRATOR,
  USER_ROLE_SALES,
  USER_ROLE_SUPPER_ADMIN,
} from "../../constants/defined";
import { checkedPermission } from "../../helper";

const headMenu = [
  { id: "code", numeric: false, disablePadding: false, label: "Mã nhân viên" },
  { id: "name", numeric: false, disablePadding: false, label: "Tên nhân viên" },
  {
    id: "officeCode",
    numeric: false,
    disablePadding: false,
    label: "Văn phòng",
  },
  { id: "position", numeric: false, disablePadding: false, label: "Chức vụ" },
  {
    id: "department",
    numeric: false,
    disablePadding: false,
    label: "Phòng ban",
  },
  //{ id: 'aspect', numeric: false, disablePadding: false, label: 'Tình trạng' },
];

const headMenuSales = [
  // {
  //   id: "priorityDeposit",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Lượt ưu tiên ký gửi",
  //   center: true,
  // },
  // {
  //   id: "priorityMatching",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Lượt ưu tiên matching KH",
  //   center: true,
  // },
  {
    id: "employeeCode",
    numeric: false,
    disablePadding: false,
    label: "Mã nhân viên",
  },
  {
    id: "employeeName",
    numeric: false,
    disablePadding: false,
    label: "Tên nhân viên",
  },
  { id: "office", numeric: false, disablePadding: false, label: "Văn phòng" },
  // { id: 'teamCode', numeric: false, disablePadding: false, label: 'Mã nhóm' },
  { id: "position", numeric: false, disablePadding: false, label: "Chức vụ" },
  //{ id: 'aspect', numeric: false, disablePadding: false, label: 'Trạng thái' },
  {
    id: "mostRecentVisit",
    numeric: false,
    disablePadding: false,
    label: "Lượt truy cập gần nhất",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "calc(100vh - 80px)",
    position: "relative",
    padding: "24px",
    margin: "30px auto",
  },
  formControl: {
    display: "grid",
    gridTemplate: "auto / 150px 300px",
    alignItems: "center",
  },
  selectEmpty: {},
  labelStyle: {
    fontWeight: "bold",
  },
  groupFilterStyle: {
    marginTop: "30px",
    "& > div:not(:last-child)": {
      paddingRight: "30px",
    },
  },
  wrapperButtonFilter: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  buttonTop: {
    borderRadius: 0,
    color: "#ffffff",
    backgroundColor: "#E27F35",
    padding: "5px 40px",
    textTransform: "none",
    letterSpacing: 0.4,
    marginTop: 30,
  },
  buttonType: {
    color: "#ffffff",
    borderRadius: 0,
    padding: "5px 50px",
    marginTop: 40,
  },
  export: {
    backgroundColor: "#00837B",
    textTransform: "unset",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#ffffff",
    borderRadius: "6px",
    padding: "4px 20px",
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#00837B",
      opacity: 0.7,
    },
  },
  rootSearch: {
    padding: "2px 4px",
    display: "flex",
    width: 400,
    borderRadius: 20,
    alignItems: "center",
  },
  wapperLoading: {
    width: "100%",
    height: "calc(100vh - 113px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  marginBottom: {
    marginBottom: "30px",
  },
  styleButton: {
    textTransform: "unset",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#ffffff",
    borderRadius: "6px",
    padding: "4px 20px",
  },
  groupButton: {
    "& > a:not(:first-child)": {
      marginLeft: "24px",
    },
    "& > a": {
      backgroundColor: "#00837B",

      "&:hover": {
        backgroundColor: "#00837B",
        opacity: 0.7,
      },
    },
  },
  buttonPermission: {
    background: "#E07E37",
    "&:hover": {
      background: "#E07E37",
      opacity: 0.7,
    },
  },
  typeAccount: {
    fontSize: "20px",
    color: "#B9B9B9",
    "&:not(.selected)": {
      cursor: "pointer",
    },
    "&.selected": {
      color: "#0A2240",
      position: "relative",
      "&:after": {
        content: `''`,
        width: "calc(100% - 30px)",
        height: "3px",
        background: "#DA6744",
        position: "absolute",
        bottom: "10px",
        left: "15px",
      },
    },
  },
  nonButton: {
    backgroundColor: "#FAFAFA !important",
    color: "#00837B !important",
    fontWeight: 700,
    fontSize: "18px",
    marginLeft: "24px",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    textTransform: "unset",

    "&.disabled": {
      color: "lightgrey !important",
      userSelect: "none",
      "&:hover": {
        opacity: "unset",
      },
    },

    "&:hover": {
      opacity: "0.7",
    },
  },
}));

const ACOUNT_TYPE = [
  { id: 1, code: USER_ROLE_SALES, name: "Danh sách tài khoản NVKD" },
  { id: 2, code: USER_ROLE_ADMINISTRATOR, name: "Danh sách tài khoản Admin" },
];

const ExportToExcel = ({ data, type }) => {
  const ExcelJSWorkbook = new ExcelJS.Workbook();
  const worksheet = ExcelJSWorkbook.addWorksheet("ExcelJS sheet");
  const header = [
    "STT",
    "Mã nhân viên",
    "Họ & tên",
    "Số điện thoại",
    "Email",
    "Thời gian gần nhất",
  ];

  const dataToExport = [];

  if (_.size(data) > 0) {
    let i = 1;
    data.forEach((value) => {
      dataToExport.push([
        i,
        _.get(value, "info.code"),
        _.get(value, "info.full_name"),
        _.get(value, "info.phone"),
        _.get(value, "info.email"),
        _.get(value, "info.last_activity")
          ? moment(_.get(value, "info.last_activity")).format(
              "DD/MM/YYYY HH:mm A"
            )
          : "-",
      ]);
      i++;
    });
  } else {
    swal({
      title: "Thông báo",
      text: "Không có dữ liệu!",
      icon: "warning",
    });
    return;
  }

  const customCell = worksheet.getCell("A1");
  customCell.alignment = { vertical: "middle", horizontal: "center" };
  customCell.font = {
    name: "Century",
    family: 4,
    bold: true,
  };

  worksheet.addRow();
  const headerRow = worksheet.addRow(header);
  headerRow.font = {
    name: "Century",
    family: 4,
    bold: true,
  };

  let columnSTT = 1;
  worksheet.columns.forEach((column) => {
    let dataMax = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      dataMax = cell.value ? cell.value.toString().length : 0;
    });
    if (columnSTT === 1) {
      column.width = 5;
      columnSTT++;
    } else if (dataMax <= 2) {
      column.width = 10;
    } else {
      column.width = dataMax <= 15 ? 20 : dataMax;
    }
  });

  if (dataToExport.length > 0) {
    dataToExport.forEach((value) => {
      worksheet.addRow(value);
    });
  }

  customCell.value = "Danh sách nhân viên";
  worksheet.mergeCells("A1:E1");

  ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `Danh_sach_nhan_vien_${
        _.toUpper(type) === USER_ROLE_SALES ? "sale" : "admin"
      }.xlsx`
    );
  });

  return;
};

const EmployeeList = (props) => {
  const { userInfo, accessObjectPermissions } = useContext(StoreContext);
  const classes = useStyles();

  const [typeList, setTypeList] = useState(
    window.localStorage.getItem("typeListEmployee") || USER_ROLE_SALES
  );
  const [userId, setUserId] = useState(_.get(userInfo, "id"));
  const [object, setObject] = useState({ data: [], menu: [] });
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [aspect, setAspect] = useState([]);
  const [status, setStatus] = useState([]);
  const [office, setOffice] = useState(0);
  const [position, setPosition] = useState(0);
  const [departmentSelected, setDepartmentSelected] = useState(0);
  const [accountTypeSelected, setAccountTypeSelected] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [searchTextValue] = useDebounce(searchText, 500);

  const isSuperAdmin =
    _.toUpper(_.get(userInfo, "role")) === USER_ROLE_SUPPER_ADMIN;
  const accessDeniedExport = !checkedPermission(
    userInfo,
    accessObjectPermissions,
    EXPORT_EMPLOYEE
  );
  const accessDeniedCreate = !checkedPermission(
    userInfo,
    accessObjectPermissions,
    CREATE_EMPLOYEE
  );

  const errorMessage = useRef({
    invalid: "",
  });

  const conditionFilterSummary = {
    branchByBranch:
      office === 0
        ? undefined
        : {
            id: { _eq: office },
          },
    positionByPosition:
      position === 0
        ? undefined
        : {
            id: { _eq: position },
          },
    employee_departments:
      departmentSelected === 0
        ? undefined
        : {
            departmentByDepartment: {
              id: { _eq: departmentSelected },
            },
          },
  };

  const variables = {
    skip: 0,
    take: 1e9,
    where: {
      accountByAccount: {
        deleted: { _eq: false },
        status: {
          _in: _.isEmpty(status)
            ? ["ONLINE", "OFFLINE", "BLOCKED", "TASK_BLOCKED"]
            : status,
        },
        account_roles: {
          roleByRole: {
            code: { _eq: _.toUpper(typeList) },
          },
        },
        _or: searchTextValue
          ? [
              {
                full_name: { _ilike: `%${searchTextValue}%` },
              },
              {
                code: { _ilike: `%${searchTextValue}%` },
              },
              {
                phone: {
                  _ilike: `%${
                    searchTextValue[0] === "0"
                      ? searchTextValue.slice(1)
                      : searchTextValue
                  }%`,
                },
              },
              {
                email: { _ilike: `%${searchTextValue}%` },
              },
            ]
          : undefined,
      },
      branchByBranch:
        office === 0
          ? undefined
          : {
              id: { _eq: office },
            },
      positionByPosition:
        position === 0
          ? undefined
          : {
              id: { _eq: position },
            },
      employee_departments:
        departmentSelected === 0
          ? undefined
          : {
              departmentByDepartment: {
                id: { _eq: departmentSelected },
              },
            },
    },
  };

  const { loading: loadingSummary, data: dataSummary } = useQuery(
    GET_EMPLOYEE_BY_ROLE_SUMMARY,
    {
      variables: {
        condition: conditionFilterSummary,
      },
    }
  );
  const { data: dataEmployees } = useQuery(GET_LIST_EMPLOYEE, {
    variables: {
      skip: 0,
      take: null,
    },
  });
  const [
    exportSales,
    { called: calledSales, loading: loadingSales, data: dataSales },
  ] = useLazyQuery(GET_EMPLOYEE_BY_ROLE, {
    variables,
  });

  const allAreTruthy = (arr) => {
    const res = arr.filter(
      (item) => !Object.values(item).every((value) => !value)
    );
    return res;
  };

  const handleExport = (role) => {
    const getData = new Promise((resolve, reject) => {
      exportSales()
        .then((value) => resolve(value))
        .catch((error) => reject(error));
    });

    getData.then(
      (value) => {
        ExportToExcel({ data: _.get(value, "data.results"), type: role });
      },

      (error) => {
        setLoadingProgress(false);
        swal({
          title: "Thông báo",
          text: "Có lỗi khi xuất dữ liệu!",
          icon: "error",
        });
      }
    );
  };

  const {
    data: employeeList,
    loading: loadingEmployeeList,
    count,
    refetch: refetchEmployeeList,
  } = useEmployeeByRole({ ...variables, skip: skip, take: 10 });

  const {
    data: listBranches,
    loading: loadingBranches,
    refetch: refetchBranches,
  } = useBranches();
  const {
    data: listPositions,
    loading: loadingPositions,
    refetch: refetchPositions,
  } = usePositions(_.toUpper(typeList) !== USER_ROLE_SALES);
  const { data: departments, loading: loadingDepartments } = useDepartments(
    !_.includes(
      [USER_ROLE_SUPPER_ADMIN, USER_ROLE_ADMINISTRATOR],
      _.toUpper(typeList)
    )
  );
  const { data: accountTypeList, loading: loadingAccountTypes } =
    useGetListAccountType(
      !_.includes(
        [USER_ROLE_SUPPER_ADMIN, USER_ROLE_ADMINISTRATOR],
        _.toUpper(typeList)
      )
    );

  const handleTabSelect = (type) => {
    setTypeList(_.toUpper(type));
    window.localStorage.setItem("typeListEmployee", _.toUpper(type));
    setPage(1);
    setSkip(0);
  };

  useEffect(() => {
    if (
      _.includes(
        [USER_ROLE_SUPPER_ADMIN, USER_ROLE_ADMINISTRATOR],
        _.toUpper(typeList)
      )
    ) {
      setStatus([]);
    }
  }, [typeList]);

  useEffect(() => {
    if (_.size(employeeList) >= 0) {
      setObject({
        menu:
          _.toUpper(typeList) === USER_ROLE_SALES ? headMenuSales : headMenu,
        data:
          _.toUpper(typeList) === USER_ROLE_SALES
            ? _.map(employeeList, (item) => {
                return {
                  id: _.get(item, "info.id"),
                  priorityDeposit: _.get(item, "consult_count", 0),
                  priorityMatching: _.get(item, "consign_count", 0),
                  employeeCode: _.get(item, "info.code"),
                  employeeName: _.get(item, "info.full_name"),
                  office: _.get(item, "branch.name", "-"),
                  position: _.get(item, "position.name"),
                  mostRecentVisit: _.get(item, "info.last_activity") ? (
                    <Moment format="DD/MM/YYYY - h:mm A">
                      {_.get(item, "info.last_activity")}
                    </Moment>
                  ) : (
                    "-"
                  ),
                };
              })
            : _.map(employeeList, (item) => {
                return {
                  id: _.get(item, "info.id"),
                  code: _.get(item, "info.code"),
                  name: _.get(item, "info.full_name"),
                  officeCode: _.get(item, "branch.name"),
                  position: _.get(item, "position.name"),
                  department:
                    _.map(_.get(item, "departments")).length !== 0
                      ? _.map(
                          _.get(item, "departments"),
                          (Obj) => Obj.department.name
                        ).join(", ")
                      : "-",
                };
              }),
      });
    }
  }, [employeeList]);

  useEffect(() => {
    setPage(1);
    setSkip(0);
  }, [status]);

  const handleChangeBranches = (e) => {
    setOffice(e.target.value);
  };

  const handleChangePosition = (e) => {
    setPosition(e.target.value);
  };

  const handleFileUpload = async (e, role) => {
    if (role) {
      setLoadingProgress(true);
      //Generate UI
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 2 });
        processData(data, file);
      };
      reader.readAsBinaryString(file);
      //Handle data
      e.target.value = null;
      try {
      } catch (e) {
        console.log(e.message);
      }
    } else {
      swal({
        title: "Error!",
        text: `Not authentication`,
        icon: "error",
        button: "OK!",
      });
    }
  };
  const processData = async (dataString, file) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
    const list = [];
    for (let i = 1; i < dataStringLines.length - 1; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      list.push({
        CODE: row[0],
        NAME: row[1],
        BIRTHDAY: row[2],
        GENDER: row[3],
        POSITION: row[4],
        PHONE: row[5],
        EMAIL: row[6],
        CODE_MANAGE: row[7],
        CODE_BRANCH: row[8],
        CODE_DEPARTMENT: row[9],
      });
    }
    // prepare columns list from headers
    const columns = headers.map((c) => ({
      name: c,
      selector: c,
    }));

    const employeeList = allAreTruthy(list);
    //CHECK INVALID
    checkInvalid(employeeList, "PHONE");
    checkInvalid(employeeList, "EMAIL");

    if (errorMessage.current.invalid) {
      setLoadingProgress(false);
      swal({
        title: "Error!",
        text: `${errorMessage.current.invalid}`,
        icon: "error",
        button: "OK!",
      });
    } else {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("import_name", "employees");
      formData.append("account", userId);
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            api_key: process.env.REACT_APP_API_KEY,
          },
        };
        await axios
          .post(process.env.REACT_APP_BASEURL_UPLOAD_EMPLOYEE, formData, config)
          .then((res) => {
            if (res) {
              console.log("res : ", res)
              setLoadingProgress(false);
              return swal({
                title: "Thêm nhân viên thành công",
                icon: "success",
              });
            }
          })
          .then(() => setLoadingProgress)
          .catch((err) => {
            if (err) {
              setLoadingProgress(false);
              swal({
                title: "Lỗi!",
                text: err.response.data.errors.message,
                icon: "error",
                button: "Đóng",
              });
            }
          });
      } catch (errors) {
        console.log(errors);
      }
    }
    errorMessage.current.invalid = "";
  };

  const checkInvalid = (arr, comp) => {
    arr
      .map((e) => e[comp])
      .map((e, i, final) => {
        if (comp === "PHONE") {
          if (!validPhone.test(e)) {
            setLoadingProgress(false);
            errorMessage.current.invalid = errorMessage.current.invalid.concat(
              ` Row: ${i + 2} - lỗi: phone ${e} không hợp lệ \n`
            );
          }
        }
        if (comp === "EMAIL") {
          if (!validEmail.test(e)) {
            setLoadingProgress(false);
            errorMessage.current.invalid = errorMessage.current.invalid.concat(
              ` Row: ${i + 2} - lỗi: email ${e} không hợp lệ \n`
            );
          }
        }
      });
  };

  if (loadingSales || loadingSummary) {
    return (
      <div className={classes.wapperLoading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Fragment>
      <Grid container direction="row" className={classes.marginBottom}>
        <Paper className={classes.rootSearch}>
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
          >
            <Search />
          </IconButton>
          <TextField
            size="small"
            InputProps={{ disableUnderline: true }}
            style={{ backgroundColor: "#ffffff", border: 0, width: 330 }}
            placeholder="Tìm kiếm..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Paper>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={clsx(classes.marginBottom)}
      >
        <Grid item>
          <Grid
            item
            container
            direction="row"
            className={clsx(classes.groupButton)}
          >
            <Button
              className={classes.styleButton}
              style={{ backgroundColor: accessDeniedCreate && "lightgray" }}
              component={Link}
              disabled={accessDeniedCreate}
              to={slug.employeeCreate}
              startIcon={<PersonAdd />}
            >
              Tạo Tài khoản mới
            </Button>

            <label htmlFor="upload-photo">
              {!accessDeniedCreate && (
                <input
                  disabled={loadingProgress}
                  style={{ display: "none" }}
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                  onChange={(e) =>
                    handleFileUpload(
                      e,
                      dataEmployees?.results?.find(
                        (item) => item.info.id === userId
                      ).info.is_can_login_agent
                    )
                  }
                />
              )}

              <Button
                disabled={accessDeniedCreate}
                className={clsx(
                  classes.nonButton,
                  accessDeniedCreate && "disabled"
                )}
                component="span"
              >
                {!loadingProgress ? (
                  <CloudUploadOutlined style={{ marginRight: "8px" }} />
                ) : (
                  <CircularProgress
                    color={"#00837B"}
                    style={{ marginRight: "8px" }}
                  />
                )}
                Tải lên danh sách
              </Button>
            </label>

            <a
              href={
                !accessDeniedCreate &&
                process.env.REACT_APP_BASEURL_DOWNLOAD_TEMPLATE
              }
              target="_blank"
              rel="noreferrer"
              className={clsx(
                classes.nonButton,
                accessDeniedCreate && "disabled"
              )}
            >
              <CloudDownloadOutlined style={{ marginRight: "8px" }} />
              Tải xuống bản mẫu
            </a>
          </Grid>
        </Grid>

        <Grid item>
          <Button
            disabled={!isSuperAdmin}
            className={clsx(classes.styleButton, classes.buttonPermission)}
            style={{ backgroundColor: !isSuperAdmin && "lightgrey" }}
            component={Link}
            to={slug.permission}
            startIcon={<SettingsIcon />}
          >
            Phân quyền tài khoản
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        spacing={3}
        justifyContent="space-between"
        className={classes.marginBottom}
      >
        <Grid item>
          <Grid container spacing={4}>
            {_.map(ACOUNT_TYPE, (item, key) => {
              const selected = _.toUpper(typeList) === _.get(item, "code");
              return (
                <Grid
                  key={key}
                  item
                  className={clsx(classes.typeAccount, selected && "selected")}
                  onClick={() =>
                    !selected && handleTabSelect(_.get(item, "code"))
                  }
                >
                  {_.get(item, "name")}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>

      <Paper className={classes.root}>
        {_.toUpper(typeList) === USER_ROLE_SALES && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            className={clsx(classes.marginBottom, classes.wrapperButtonFilter)}
          >
            <ButtonStatusList
              status="ONLINE"
              handleClick={setStatus}
              selected={_.first(status) === "ONLINE" && _.size(status) === 1}
              count={_.get(dataSummary, "online.aggregate.count")}
            />
            <ButtonStatusList
              status="OFFLINE"
              handleClick={setStatus}
              selected={_.first(status) === "OFFLINE" && _.size(status) === 1}
              count={_.get(dataSummary, "offline.aggregate.count")}
            />
            <ButtonStatusList
              status="TASK_BLOCKED"
              handleClick={setStatus}
              selected={
                _.first(status) === "TASK_BLOCKED" && _.size(status) === 1
              }
              count={_.get(dataSummary, "task_blocked.aggregate.count")}
            />
            <ButtonStatusList
              status="BLOCKED"
              handleClick={setStatus}
              selected={_.first(status) === "BLOCKED" && _.size(status) === 1}
              count={_.get(dataSummary, "blocked.aggregate.count")}
            />
            <ButtonStatusList
              status="ALL"
              handleClick={setStatus}
              selected={_.isEmpty(status) || _.size(status) > 1}
              count={_.sum([
                _.get(dataSummary, "online.aggregate.count"),
                _.get(dataSummary, "offline.aggregate.count"),
                _.get(dataSummary, "task_blocked.aggregate.count"),
                _.get(dataSummary, "blocked.aggregate.count"),
              ])}
            />
          </Grid>
        )}

        <Grid
          container
          direction="row"
          spacing={3}
          alignItems="flex-end"
          className={classes.marginBottom}
        >
          <Grid item xs={3}>
            <Typography>Văn phòng</Typography>
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: "100%" }}
            >
              <Select onChange={handleChangeBranches} value={office}>
                <MenuItem value={0}>Tất cả</MenuItem>
                {loadingBranches ? (
                  <CircularProgress />
                ) : (
                  _.map(listBranches, (item, key) => (
                    <MenuItem
                      key={_.get(item, "id") ? _.get(item, "id") : key}
                      value={Number(_.get(item, "id"))}
                    >
                      {_.get(item, "name")}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>

          {_.toUpper(typeList) === USER_ROLE_SALES && (
            <Grid item xs={3}>
              <Typography>Chức vụ</Typography>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
              >
                <Select onChange={handleChangePosition} value={position}>
                  <MenuItem value={0}>Tất cả</MenuItem>
                  {loadingPositions ? (
                    <CircularProgress />
                  ) : (
                    _.map(listPositions, (item, key) => (
                      <MenuItem
                        key={_.get(item, "id") ? _.get(item, "id") : key}
                        value={Number(_.get(item, "id"))}
                      >
                        {_.get(item, "name")}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Grid>
          )}

          {_.includes(
            [USER_ROLE_SUPPER_ADMIN, USER_ROLE_ADMINISTRATOR],
            _.toUpper(typeList)
          ) && (
            <Fragment>
              <Grid item xs={3}>
                <Typography>Phòng ban</Typography>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Select
                    onChange={(e) =>
                      setDepartmentSelected(_.get(e, "target.value"))
                    }
                    value={departmentSelected}
                  >
                    <MenuItem value={0}>Tất cả</MenuItem>
                    {loadingDepartments ? (
                      <CircularProgress />
                    ) : (
                      _.map(departments, (item, key) => {
                        return (
                          <MenuItem
                            key={_.get(item, "id") ? _.get(item, "id") : key}
                            value={Number(_.get(item, "id"))}
                          >
                            {_.get(item, "name")}
                          </MenuItem>
                        );
                      })
                    )}
                  </Select>
                </FormControl>
              </Grid>

              {/* <Grid item xs={3}>
                <Typography>Loại tài khoản</Typography>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                  disabled
                >
                  <Select
                    onChange={(e) =>
                      setAccountTypeSelected(_.get(e, "target.value"))
                    }
                    value={accountTypeSelected}
                  >
                    <MenuItem value={0}>Tất cả</MenuItem>
                    {loadingAccountTypes ? (
                      <CircularProgress />
                    ) : (
                      _.map(accountTypeList, (item, key) => (
                        <MenuItem
                          key={_.get(item, "id") ? _.get(item, "id") : key}
                          value={Number(_.get(item, "id"))}
                        >
                          {_.get(item, "name")}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid> */}
            </Fragment>
          )}

          {/* 
          <Grid item xs={3}>
            <Button
              className={classes.export}
              startIcon={<DescriptionIcon />}
              title="Xuất file excel"
              onClick={() => handleExport(typeList)}
            >
              Xuất dữ liệu
            </Button>
          </Grid> 
          */}

          <Grid item xs={3}>
            <ButtonExportExcel
              disabled={accessDeniedExport}
              exportName="EXPORT_EMPLOYEE"
              position={position}
              branch={office}
              status={
                _.isEmpty(status)
                  ? ["ONLINE", "OFFLINE", "BLOCKED", "TASK_BLOCKED"]
                  : status
              }
              role={typeList}
            />
          </Grid>

          {_.toUpper(typeList) === USER_ROLE_SALES && (
            <Grid item xs={3}>
              <ButtonExportExcel
                disabled={accessDeniedExport}
                buttonLabel="Xuất báo cáo thống kê"
                exportName="EXPORT_PRODUCT_CONSULT"
                withRange={true}
                multiReport={true}
              />
            </Grid>
          )}
        </Grid>

        <Grid
          container
          direction="row"
          spacing={6}
          style={{ marginTop: "30px" }}
        >
          {!employeeList ? (
            <div
              style={{
                minHeight: "300px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableComponent
              loadingEmployeeList={loadingEmployeeList}
              data={_.get(object, "data")}
              headMenu={_.get(object, "menu")}
              setSkip={(skip) => setSkip(skip)}
              count={count}
              page={page}
              setPage={setPage}
            />
          )}
        </Grid>
      </Paper>
      <Backdrop
        style={{ color: "#fff", zIndex: 200000 }}
        open={loadingProgress}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};
export default EmployeeList;
