import gql from "graphql-tag";
import { CORE_SCHEMA_FIELDS } from "./fragments";

export const PRODUCT_FIND_ALL_SECONDARY = gql`
  ${CORE_SCHEMA_FIELDS}
  query GetProducts(
    $skip: Int!
    $take: Int!
    $condition: product_bool_exp!
    $order_by: [product_order_by!] = {}
  ) {
    products: product(
      offset: $skip
      limit: $take
      where: $condition
      order_by: $order_by
    ) {
      id
      code
      title
      price_to_display
      price_unit: priceUnitByPriceUnit {
        id
        name
      }
      area
      area_used
      area_height
      area_width
      bath_room
      bed_room
      basement
      orientation: orientationByOrientation {
        id
        name
      }
      exclusive
      urgent
      publish
      address: addressByAddress {
        ...addressData
      }
      listing_type: listingTypeByListingType {
        id
        name
      }
      legal_type: legalTypeByLegalType {
        id
        name
      }
      property_type: propertyTypeByPropertyType {
        id
        name
      }
      avatar: productAvatarByMedia {
        id
        name
        url
      }
    }
  }
`;

export const PRODUCT_FIND_ALL = gql`
  ${CORE_SCHEMA_FIELDS}

  query GetProducts(
    $skip: Int!
    $take: Int!
    $condition: product_bool_exp!
    $order_by: [product_order_by!] = {}
  ) {
    product_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
    products: product(
      offset: $skip
      limit: $take
      where: $condition
      order_by: $order_by
    ) {
      branchByBranch {
        id
        code
        name
      }
      updated_by
      updated
      updated_by_role: updatedByAccount {
        account_roles {
          roleByRole {
            id
            code
            name
          }
        }
      }
      created
      deleted
      active
      status
      id
      code
      title
      price
      price_to_display
      price_unit: priceUnitByPriceUnit {
        id
        name
      }
      area
      area_used
      area_height
      area_width
      bath_room
      bed_room
      basement
      orientation: orientationByOrientation {
        id
        name
      }
      exclusive
      urgent
      publish
      address: addressByAddress {
        ...addressData
      }
      listing_type: listingTypeByListingType {
        id
        name
      }
      legal_type: legalTypeByLegalType {
        id
        name
      }
      property_type: propertyTypeByPropertyType {
        id
        name
      }
      avatar: productAvatarByMedia {
        id
        name
        url
      }
      plan_status: productPlanStatusByProductPlanStatus {
        code
        id
        name
      }
    }
  }
`;

export const PRODUCT_FIND_ONE = gql`
  ${CORE_SCHEMA_FIELDS}

  query GetProduct($productId: uuid!) {
    request: product_request(
      limit: 1
      order_by: { created: desc }
      where: {
        product: { _eq: $productId }
        workflow_type: { code: { _in: ["CONSIGN", "POST"] } }
      }
    ) {
      requests {
        created_by: accountByCreatedBy {
          id
          code
          full_name
        }
        id
        draft
        locked
        request_workflows {
          id
          status
          approved
          created
        }
        current_step
        type_workflow {
          id
          type
          type_step {
            id
            code
            name
            type_status {
              id
              code
              name
            }
          }
        }
      }

      customer: account {
        id
        code
        full_name
        phone
        customers {
          id
          name
        }
      }
      employee: accountByIncharge {
        id
        code
        full_name
      }
      product: productByProduct {
        id
        code
        title
        product_requests(
          where: {
            type_workflow: {
              typeByType: {
                code: { _eq: "CONSULT" }
                type_steps: { code: { _eq: "CONTRACT" } }
              }
            }
          }
        ) {
          consult_contracts: product_request_attachments(
            where: { medium: { media_type: { code: { _eq: "CONTRACT" } } } }
          ) {
            media_detail: medium {
              media_type {
                id
                code
                name
              }
            }
          }
        }

        branch: branchByBranch {
          id
          code
          name
        }
        productStatusByProductStatus {
          id
          code
          name
        }
        active
        deleted
        status
        price
        price_to_display
        price_unit: priceUnitByPriceUnit {
          id
          name
        }
        longitude
        latitude
        created
        area
        area_used
        area_height
        area_width
        bath_room
        bed_room
        basement
        floor
        area_foor
        area_construction
        commission
        entrance_dist
        frontways
        description
        remark
        address: addressByAddress {
          ...addressData
        }
        orientation: orientationByOrientation {
          id
          name
        }
        exclusive
        urgent
        publish
        listing_type: listingTypeByListingType {
          id
          code
          name
        }
        legal_type: legalTypeByLegalType {
          id
          name
        }
        property_type: propertyTypeByPropertyType {
          id
          name
        }
        product_detail: productDetailByProductDetail {
          description
          plan_info
          outstanding_advantages
          furniture_info
          street_view
          view_3d_url
          video_url
        }
        project: projectByProject {
          id
          code
          name
          investment
          investment_unit: priceUnitByPriceUnit {
            id
            name
          }
          start_date
          taking_over
          total_appartments
          total_blocks
          project_investor: investorByInvestor {
            id
            name
            website
            logo: investorLogoByMedia {
              id
              url
            }
          }
          project_constructor: constructorByConstructor {
            id
            name
          }
          project_detail: projectDetailByProjectDetail {
            project_design: projectDesignByProjectDesign {
              id
              name
            }
            project_management: projectManagementByProjectManagement {
              id
              name
            }
            investment_cooperation: investmentCooperationByInvestmentCooperation {
              id
              name
            }
          }
        }
        product_utilities {
          utility {
            id
            name
            type
            utilities_type {
              id
              name
              code
            }
          }
        }
        furnitures: product_furnitures {
          furniture: furnitureByFurniture {
            id
            name
            room_type: roomTypeByRoomType {
              id
              code
              name
            }
          }
        }
        product_tags: product_tags {
          tag: tagByTag {
            id
            value
          }
        }
        product_medias: property_media {
          media: propertyMediaDetail {
            id
            name
            url
            media_type {
              code
              name
            }
          }
        }
        avatar: productAvatarByMedia {
          id
          name
          url
        }
        product_plan: productPlanByProductPlan {
          address: addressByAddress {
            ...addressData
          }
          land_plot
          map_sheet
          area
          issue_date
          remark
          photos: product_plan_media {
            medium {
              id
              name
              url
            }
          }
          points: product_plan_points {
            x
            y
          }
        }
        plan_status: productPlanStatusByProductPlanStatus {
          code
          id
          name
        }
      }
      product_request_attachments {
        media_detail: medium {
          url
          name
          media_type {
            code
            name
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_BY_CODE = gql`
  query GetProductsByCode(
    $skip: Int!
    $take: Int!
    $condition: product_bool_exp!
  ) {
    products: product(
      offset: $skip
      limit: $take
      where: $condition
      order_by: { code: asc }
    ) {
      id
      code
    }
  }
`;

export const GET_PRODUCT_STATUS_BY_CODE = gql`
  query getProductStatusByCode($code: String!) {
    result: product_status(where: { code: { _eq: $code } }) {
      id
      code
    }
  }
`;

export const GET_DETAIL_PRODUCT_FOR_EDIT = gql`
  ${CORE_SCHEMA_FIELDS}
  query GetProduct($productId: uuid!) {
    request: product_request(where: { product: { _eq: $productId } }) {
      customer
      product: productByProduct {
        id
        code
        title
        map
        branch: branchByBranch {
          id
          code
          name
        }
        price_to_display
        price_unit: priceUnitByPriceUnit {
          id
          name
        }
        area
        area_used
        area_height
        area_width
        bath_room
        bed_room
        basement
        entrance_dist
        frontways
        description
        remark
        address: addressByAddress {
          ...addressData
        }
        orientation: orientationByOrientation {
          id
          name
        }
        exclusive
        urgent
        publish
        listing_type: listingTypeByListingType {
          id
          name
        }
        legal_type: legalTypeByLegalType {
          id
          name
        }
        property_type: propertyTypeByPropertyType {
          id
          name
        }
        product_detail: productDetailByProductDetail {
          description
          plan_info
          outstanding_advantages
          furniture_info
          street_view
          view_3d_url
          video_url
        }
        project: projectByProject {
          id
          code
          name
          investment
          investment_unit: priceUnitByPriceUnit {
            id
            name
          }
          start_date
          taking_over
          total_appartments
          total_blocks
          project_investor: investorByInvestor {
            id
            name
            website
            logo: investorLogoByMedia {
              id
              url
            }
          }
          project_constructor: constructorByConstructor {
            id
            name
          }
          project_detail: projectDetailByProjectDetail {
            project_design: projectDesignByProjectDesign {
              id
              name
            }
            project_management: projectManagementByProjectManagement {
              id
              name
            }
            investment_cooperation: investmentCooperationByInvestmentCooperation {
              id
              name
            }
          }
        }
        product_utilities {
          utility {
            id
            name
            type
            utilities_type {
              id
              name
              code
            }
          }
        }
        furnitures: product_furnitures {
          furniture: furnitureByFurniture {
            id
            name
            room_type: roomTypeByRoomType {
              id
              code
              name
            }
          }
        }
        product_tags: product_tags {
          tag: tagByTag {
            id
            value
          }
        }
        product_medias: property_media {
          media: propertyMediaDetail {
            id
            name
            url
            media_type {
              code
              name
            }
          }
        }
        avatar: productAvatarByMedia {
          id
          name
          url
        }
      }
    }
  }
`;

export const GET_PRODUCT_CONSIGN_SUMMARY = gql`
  query GetProductConsignSummary($condition: product_bool_exp!) {
    all: product_aggregate(
      where: {
        _and: [
          $condition
          {
            product_requests: { workflow_type: { code: { _eq: "CONSIGN" } } }
            status: {
              _in: [
                "HIDDEN"
                "SOLD"
                "SOLD_OTHER"
                "DEPOSIT"
                "SELLING"
                "WAITING"
                "BLOCKED"
              ]
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    waiting: product_aggregate(
      where: {
        _and: [
          $condition
          {
            product_requests: { workflow_type: { code: { _eq: "CONSIGN" } } }
            status: { _eq: "WAITING" }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    selling: product_aggregate(
      where: {
        _and: [
          $condition
          {
            product_requests: { workflow_type: { code: { _eq: "CONSIGN" } } }
            status: { _eq: "SELLING" }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    sold: product_aggregate(
      where: {
        _and: [
          $condition
          {
            product_requests: { workflow_type: { code: { _eq: "CONSIGN" } } }
            status: { _in: ["SOLD", "SOLD_OTHER", "DEPOSIT"] }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    hidden: product_aggregate(
      where: {
        _and: [
          $condition
          {
            product_requests: { workflow_type: { code: { _eq: "CONSIGN" } } }
            status: { _eq: "HIDDEN" }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    locked: product_aggregate(
      where: {
        _and: [
          $condition
          {
            product_requests: { workflow_type: { code: { _eq: "CONSIGN" } } }
            active: { _eq: false }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PRODUCT_POST_SUMMARY = gql`
  query GetProductPostSummary($condition: product_bool_exp!) {
    all: product_aggregate(
      where: {
        _and: [
          $condition
          {
            product_requests: { workflow_type: { code: { _eq: "POST" } } }
            status: {
              _in: [
                "HIDDEN"
                "SOLD"
                "SOLD_OTHER"
                "DEPOSIT"
                "SELLING"
                "WAITING"
                "BLOCKED"
              ]
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    waiting: product_aggregate(
      where: {
        _and: [
          $condition
          {
            product_requests: { workflow_type: { code: { _eq: "POST" } } }
            status: { _eq: "WAITING" }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    selling: product_aggregate(
      where: {
        _and: [
          $condition
          {
            product_requests: { workflow_type: { code: { _eq: "POST" } } }
            status: { _eq: "SELLING" }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    sold: product_aggregate(
      where: {
        _and: [
          $condition
          {
            product_requests: { workflow_type: { code: { _eq: "POST" } } }
            status: { _in: ["SOLD", "SOLD_OTHER", "DEPOSIT"] }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    hidden: product_aggregate(
      where: {
        _and: [
          $condition
          {
            product_requests: { workflow_type: { code: { _eq: "POST" } } }
            status: { _eq: "HIDDEN" }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    locked: product_aggregate(
      where: {
        _and: [
          $condition
          {
            product_requests: { workflow_type: { code: { _eq: "POST" } } }
            active: { _eq: false }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PRODUCT_ACTIVITY_HISTORY = gql`
  query GetProductActivityHistory($where: request_workflow_bool_exp = {}) {
    request_workflow(order_by: { created: desc }, where: $where) {
      created
      requestByRequest {
        customer_info: customerByCustomer {
          id
          code
          full_name
        }
        employee_info: account {
          id
          code
          full_name
        }
      }
      type_workflow {
        type_step {
          code
          name
        }
      }
    }
  }
`;

export const GET_PRODUCT_STATUS_ALL = gql`
query GetProductConsignSummary{
  # Tất cả
    all: product_aggregate(where: {product_requests: {workflow_type: {code: {_eq: "CONSIGN"}}}, status: {_in: ["HIDDEN","SOLD","SOLD_OTHER","DEPOSIT","SELLING","WAITING","LOCKED"]}}) {
      aggregate {
        count
      }
    }
  #Chờ duyệt
    waiting: product_aggregate(where: {product_requests: {workflow_type: {code: {_eq: "CONSIGN"}}}, status: {_eq: "WAITING"}}) {
      aggregate {
        count
      }
    }
  #Đang bán
    selling: product_aggregate(where: {product_requests: {workflow_type: {code: {_eq: "CONSIGN"}}}, status: {_eq: "SELLING"}}) {
      aggregate {
        count
      }
    }
  #Đã bán
    sold: product_aggregate(where: {product_requests: {workflow_type: {code: {_eq: "CONSIGN"}}}, status: {_in: ["SOLD", "SOLD_OTHER", "DEPOSIT"]}}) {
      aggregate {
        count
      }
    }
  #Ẩn
    hidden: product_aggregate(where: {product_requests: {workflow_type: {code: {_eq: "CONSIGN"}}}, status: {_eq: "HIDDEN"}}) {
      aggregate {
        count,
      }
    }
    blocked: product_aggregate(where: {product_requests: {workflow_type: {code: {_eq: "CONSIGN"}}}, status: {_eq: "LOCKED"}}) {
      aggregate {
        count,
      }
    }
  }
`;


export const GET_PROPERTY_TYPE = gql`
  query PropertyTypeQuery {
  results: property_type(order_by: {order: asc}, where: {deleted: {_eq: false}}) {
    id
    code
    name
  }
}
`;

export const GET_PROPERTY_TYPE_SUB_PROJECT = gql`
  query PropertyTypeQuery {
    results: property_type(
      order_by: { order: asc }
      where: { deleted: { _eq: false }, code: { _neq: "P" } }
    ) {
      id
      code
      name
    }
  }
`;

export const GET_SUMMARY_PRODUCT_OVERVIEW = gql`
  query GetProductsOverview($listing_type_code: String = "CONSIGN", $incharge_userids: String = "", $branchids: String = "", $updated_by_role: String = "", $plan_code: String = "", $provinceids: String = "") {
    result: product_overview_fn(args: {listing_type_code: $listing_type_code, incharge_userids: $incharge_userids, branchids: $branchids, updated_by_role: $updated_by_role, plan_code: $plan_code, provinceids: $provinceids}) {
      total
      total_waiting
      total_selling
      total_sold
      total_hidden
      total_locked
    }
  }
`;
