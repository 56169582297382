import _ from 'lodash';
import React, { Fragment, useContext, useState } from 'react';
import { makeStyles, Paper, Grid } from '@material-ui/core';

import ModalCreateEmployee from '../../components/employee/modal/ModalCreateEmployee';
import ModalCreateEmployeeTeam from '../../components/employee/modal/ModalCreateEmployeeTeam';
import EmployeeCreateNew from '../../components/employee/EmployeeCreateNew';
import EmployeeCreateTeam from '../../components/employee/EmployeeCreateTeam';
import moment from 'moment';
import clsx from 'clsx';
import swal from 'sweetalert';
import { COMING_SOON } from '../../constants/defined';
import { StoreContext } from '../../resources/store';

import { USER_ROLE_SUPPER_ADMIN } from '../../constants/defined';
import { checkedPermission } from '../../helper';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: "calc(100vh - 200px)",
		position: "relative",
	},
	wrapperContent: {
		minHeight: "calc(100vh - 200px)",
		position: "relative",
		padding: "24px",
		margin: "0 auto",
	},
	margin: {
		margin: '20px 0',
	},
	formContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	textLabelForm: {
		wordWrap: 'break-word',
		width: 200,
		fontWeight: 'bold',
		marginRight: 30
	},
	input: {
		width: 250,
		backgroundColor: 'white',
		marginLeft: '0'
	},
	button: {
		color: '#fff',
		borderRadius: 0,
		textTransform: 'uppercase'
	},
	buttonTitle: {
		color: "white",
		borderRadius: 0,
		fontSize: '16px',
		width: '26em',
		fontWeight: 'bold'
	},
	typeTab: {
		fontSize: "20px",
		color: "#B9B9B9",
		"&:not(.selected)": {
			cursor: "pointer",
		},
		"&.selected": {
			color: "#0A2240",
			position: "relative",
			"&:after": {
				content: `''`,
				width: "calc(100% - 30px)",
				height: "3px",
				background: "#DA6744",
				position: "absolute",
				bottom: "10px",
				left: "15px",
			},
		},
	},
}));

const TAB_MENU = [
	{ id: 1, code: "CREATEEMPLOYEE", name: "Tạo nhân viên mới" },
	{ id: 2, code: "CREATETEAM", name: "Tạo nhóm mới" },
];

const EmployeeCreate = () => {
	const classes = useStyles();

	const { userInfo, accessObjectPermissions } = useContext(StoreContext);

	const [kindCreate, setKindCreate] = useState("createemployee");
	const [employeeItem, setEmployeeItem] = useState({
		employee: {
			account: {
				code: null,
				username: null,
				full_name: null,
				email: null,
				personalid: null,
				phone: null,
				gender: null,
				birthday: null,
				created_by: null,
				roles: null
			},
			faceid: null,
			branch: null,
			manager: null,
			position: null,
			departments: null
		}
	});
	const [openModalCreateEmployee, setOpenModalCreateEmployee] = useState(false);
	const [openModalCreateEmployeeTeam, setOpenModalCreateEmployeeTeam] = useState(false);

	const handleOpenCreateEmployee = (birthday, position, department, email, roles, faceid, name, phone, personalid, idEmployeeManage, branchDebource, idTeam, idEmployee, createdBy, distributed, distributedList) => {
		setOpenModalCreateEmployee(true);
		setEmployeeItem((prev) => ({
			...prev,
			employee: {
				account: {
					code: idEmployee,
					username: personalid,
					full_name: name,
					email,
					personalid: personalid,
					phone,
					gender: 1,
					birthday: birthday ? moment(birthday).format("YYYY-MM-DD") : null,
					created_by: createdBy,
					roles: [{ code: roles }],
					employeeType: distributed === "ALL" ? null : { code: distributed },
					products: _.isEmpty(distributedList) ? null : distributedList,
				},
				faceid: faceid[0].id,
				branch: Number(branchDebource),
				manager: idEmployeeManage,
				position,
				departments: department
			}
		}))
	};

	const handleOpenCreateEmployeeTeam = () => {
		setOpenModalCreateEmployeeTeam(true);
	};

	const handleTabSelect = (type) => {
		if (_.toLower(type) === 'createteam') {
			return swal({
				title: _.get(COMING_SOON, 'title'),
				text: _.get(COMING_SOON, 'text'),
				icon: "info"
			})
		}
		setKindCreate(_.toLower(type));
	};

	return (
		<Fragment>
			<Grid
				container
				direction="row"
				spacing={3}
				justifyContent="space-between"
				className={classes.root}
			>
				<Grid item>
					<Grid container spacing={4}>
						{_.map(TAB_MENU, (item, key) => {
							const selected = _.includes(_.toUpper(kindCreate), _.get(item, "code"));
							return (
								<Grid
									key={key}
									item
									className={clsx(classes.typeTab, selected && "selected")}
									onClick={() =>
										!selected && handleTabSelect(_.get(item, "code"))
									}
								>
									{_.get(item, "name")}
								</Grid>
							);
						})}
					</Grid>
				</Grid>

				<ModalCreateEmployee
					openModalCreateEmployee={openModalCreateEmployee}
					setOpenModalCreateEmployee={setOpenModalCreateEmployee}
					employeeItem={employeeItem}
				/>
				<ModalCreateEmployeeTeam
					openModalCreateEmployeeTeam={openModalCreateEmployeeTeam}
					setOpenModalCreateEmployeeTeam={setOpenModalCreateEmployeeTeam}
				/>

				<Grid item xs={12}>
					<Paper className={classes.wrapperContent}>
						{kindCreate === "createemployee" && <EmployeeCreateNew handleOpenCreateEmployee={handleOpenCreateEmployee} />}
						{kindCreate === "createteam" && <EmployeeCreateTeam handleOpenCreateEmployeeTeam={handleOpenCreateEmployeeTeam} />}
					</Paper>
				</Grid>
			</Grid>

		</Fragment>
	)
}

export default EmployeeCreate;
