import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Cancel, Check, Edit, AddCircle } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import swal from "sweetalert";
import { formatMoney, formatPhone } from "../../../../helper";
import slugs from "../../../../resources/slugs";
import { useGetJackVoucherDetail, useUpdateVoucherGame } from "../../hooks";
import AutoCompleteCustomerScrollLoad from '../../../../components/AutoCompleteCustomerScrollLoad';
import { ConfigContext } from "../../../../context/ConfigContext";

const useStyles = makeStyles((theme) => ({
  button: {
    color: "#fff",
  },
  bodyContainer: {
    backgroundColor: "#fff",
    borderRadius: 12,
    padding: 24,
    marginTop: 24,
  },
  title: {
    fontWeight: 700,
  },
  titleGrey: {
    color: '#999',
    userSelect: 'none',
    paddingBottom: '15px',
  },
  titleLabel: {
    width: '160px',
  },
  updateButton: {
    color: "#fff",
    backgroundColor: "#00837B",
    textTransform: "none",
    marginTop: 20,
    width: 150,
    height: 38,
  },
  inputDisable: {
    background: '#eee',
  },
}));

const JackVoucherDetail = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [customer, setCustomer] = useState({});

  const { data, loading } = useGetJackVoucherDetail(id);

  const configValue = useContext(ConfigContext);

  const { updateVoucherGame, loading: updatingAction } = useUpdateVoucherGame(setIsEdit, id);

  const handleChangeInput = (event) => {
    const values = inputValues;
    values[event.target.name] = event.target.value;
    setInputValues(values);
  };

  useEffect(() => {
    if (_.get(customer, "customer") === "Invalid") {
      swal({
        title: "Khách hàng chưa tồn tại!",
        text: "Bạn có muốn tạo khách hàng mới.",
        icon: "warning",
        buttons: {
          confirm: "Chấp nhận",
          cancel: "Bỏ qua",
        },
      }).then((flag) => {
        if (flag) {
          history.push("/customer/create");
        }
      });
    }

    let tmp = { ...inputValues };
    tmp["exchange_code"] = _.get(customer, "code") || "";
    tmp["exchange_phone"] = _.get(customer, "phone") || "";
    tmp["exchange_email"] = _.get(customer, "email") || "";
    tmp["exchange_personalid"] = _.get(customer, "personalid") || "";
    tmp["exchange_full_name"] = _.get(customer, "name") || "";

    setInputValues(tmp);
  }, [customer]);

  const handleUpdate = () => {
    let arg = {};
    arg.id = _.get(data, "id");
    arg.exchange_code = _.get(inputValues, "exchange_code") || "";
    arg.exchange_date = _.get(inputValues, "exchange_date")
      ? moment(_.get(inputValues, "exchange_date")).utc().format()
      : moment(new Date()).utc().format();
    arg.exchange_product = _.get(inputValues, "exchange_product") || "";
    arg.exchange_personalid = _.get(inputValues, "exchange_personalid") || "";
    arg.exchange_phone = _.get(inputValues, "exchange_phone") || "";
    arg.exchange_email = _.get(inputValues, "exchange_email") || "";
    arg.exchange_full_name = _.get(inputValues, "exchange_full_name") || "";

    updateVoucherGame({
      variables: {
        arg,
      },
    });
  };

  if (loading) return <CircularProgress />;

  return (
    <Fragment>
      <Grid container direction="row" spacing={2}>
        <Typography variant="h4" className={clsx(classes.title)}>
          Thông tin giải thưởng{" "}
        </Typography>
      </Grid>

      <Grid container direction="column" spacing={2} style={{ marginTop: 40 }}>
        <Paper style={{ padding: 30 }}>

          <Grid item container direction="row" spacing={2}>
            <Grid item>
              <Typography variant="h5" className={clsx(classes.title, classes.titleGrey)}>Thông tin voucher</Typography>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={2}>
            <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={clsx(classes.title, classes.titleLabel)}>Tên chương trình:</Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.marginLeftSpace)}>
                    {_.get(data, "gift_info.eventByEvent.name")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={clsx(classes.title, classes.titleLabel)}>Mã chương trình:</Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.marginLeftSpace)}>
                    {_.get(data, "gift_info.eventByEvent.code")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={clsx(classes.title, classes.titleLabel)}>Loại chương trình:</Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.marginLeftSpace)}>
                    {_.get(data, "gift_info.eventByEvent.event_type.name")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={clsx(classes.title, classes.titleLabel)}>Mã voucher:</Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.marginLeftSpace)}>
                    {_.get(data, "code")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={clsx(classes.title, classes.titleLabel)}>Giá trị (VND):</Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.marginLeftSpace)}>
                    {formatMoney(_.get(data, "gift_info.giftByGift.reward"))}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={clsx(classes.title, classes.titleLabel)}>Sản phẩm áp dụng:</Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.marginLeftSpace)}>
                    {_.map(_.get(data, "promotionByPromotion.promotion_applies"), (item, index) =>
                      `${_.get(item, "promotion_apply_type.name")}${index + 1 <
                        _.size(
                          _.get(data, "promotionByPromotion.promotion_applies")
                        )
                        ? ", "
                        : ""
                      }`
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={clsx(classes.title, classes.titleLabel)}>Ngày tạo:</Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.marginLeftSpace)}>
                    {moment(_.get(data, "created")).format("DD/MM/yyyy")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={clsx(classes.title, classes.titleLabel)}>Người tạo:</Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.marginLeftSpace)}>
                    {_.get(data, "created_by")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={clsx(classes.title, classes.titleLabel)}>Trạng thái:</Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.marginLeftSpace)}>
                    {_.get(data, "voucher_status.name")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={clsx(classes.title, classes.titleLabel)}>Thời gian bắt đầu:</Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.marginLeftSpace)}>
                    {moment(_.get(data, "start_time")).format("DD/MM/yyyy")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={clsx(classes.title, classes.titleLabel)}>Thời gian kết thúc:</Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.marginLeftSpace)}>
                    {moment(_.get(data, "end_time")).format("DD/MM/yyyy")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={clsx(classes.title, classes.titleLabel)}>Số ngày hết hạn:</Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.marginLeftSpace)}>
                    {_.get(data, "gift_info.giftByGift.expire_day")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={clsx(classes.title, classes.titleLabel)}>Mã khách hàng:</Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.marginLeftSpace)}>
                    {_.get(data, "account_info.code")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={clsx(classes.title, classes.titleLabel)}>Tên khách hàng:</Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.marginLeftSpace)}>
                    {_.get(data, "account_info.full_name")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={clsx(classes.title, classes.titleLabel)}>Số CMND/CCCD:</Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.marginLeftSpace)}>
                    {_.get(data, "account_info.personalid")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "flex-start" }}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={clsx(classes.title, classes.titleLabel)}>Hình ảnh:</Typography>
                </Grid>
                <Grid item>
                  <img
                    style={{ height: 100, width: 100 }}
                    alt="icon"
                    src={
                      configValue.MEDIA_DOMAIN + _.get(data, "image.url")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={8} style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={clsx(classes.title, classes.titleLabel)}>Mô tả:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography className={clsx(classes.marginLeftSpace)}>
                    {_.get(data, "gift_info.giftByGift.description")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

          </Grid>

          <Divider style={{ margin: "30px 0" }} />

          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Typography variant="h5" className={clsx(classes.title, classes.titleGrey)}>Thông tin giao dịch</Typography>
            </Grid>
          </Grid>

          {isEdit ? (
            <Grid container direction="row" spacing={2}>
              <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
                <Grid item container spacing={2}>
                  <Grid item>
                    <Typography className={clsx(classes.title, classes.titleLabel)}>Tên khách hàng:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      className={clsx(classes.input, classes.inputDisable)}
                      value={_.get(inputValues, "exchange_full_name")}
                      name="exchange_full_name"
                      onChange={(e) => handleChangeInput(e)}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
                <Grid item container spacing={2}>
                  <Grid item>
                    <Typography className={clsx(classes.title, classes.titleLabel)}>Mã KH đổi thưởng<span style={{ color: "red" }}>*</span>:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <AutoCompleteCustomerScrollLoad
                      setObject={setCustomer}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
                <Button
                  color="primary"
                  startIcon={<AddCircle />}
                  variant="contained"
                  onClick={() => history.push(slugs.customerCreate)}
                >
                  Tạo mới
                </Button>
              </Grid>

              <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
                <Grid item container spacing={2}>
                  <Grid item>
                    <Typography className={clsx(classes.title, classes.titleLabel)}>E-mail:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      className={clsx(classes.input, classes.inputDisable)}
                      value={_.get(inputValues, "exchange_email")}
                      name="exchange_email"
                      onChange={(e) => handleChangeInput(e)}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
                <Grid item container spacing={2}>
                  <Grid item>
                    <Typography className={clsx(classes.title, classes.titleLabel)}>CMND/CCCD:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      className={clsx(classes.input, classes.inputDisable)}
                      value={_.get(inputValues, "exchange_personalid")}
                      name="exchange_personalid"
                      onChange={(e) => handleChangeInput(e)}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
                <Grid item container spacing={2}>
                  <Grid item>
                    <Typography className={clsx(classes.title, classes.titleLabel)}>Số điện thoại<span style={{ color: "red" }}>*</span>:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      className={clsx(classes.input, classes.inputDisable)}
                      //value={_.get(inputValues, "exchange_phone")}
                      value={formatPhone({ phone: _.get(inputValues, "exchange_phone"), type: 'first' })}
                      name="exchange_phone"
                      onChange={(e) => handleChangeInput(e)}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
                <Grid item container spacing={2}>
                  <Grid item>
                    <Typography className={clsx(classes.title, classes.titleLabel)}>Ngày giao dịch:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      className={classes.input}
                      value={_.get(inputValues, "exchange_date")}
                      name="exchange_date"
                      type="datetime-local"
                      onChange={(e) => handleChangeInput(e)}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
                <Grid item container spacing={2}>
                  <Grid item>
                    <Typography className={clsx(classes.title, classes.titleLabel)}>Mã SP áp dụng:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      className={classes.input}
                      value={_.get(inputValues, "exchange_product")}
                      name="exchange_product"
                      onChange={(e) => handleChangeInput(e)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
            :
            (
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  <Grid item container spacing={2}>
                    <Grid item>
                      <Typography className={clsx(classes.title, classes.titleLabel)}>Tên khách hàng:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={clsx(classes.marginLeftSpace)}>
                        {_.get(data, "exchange_full_name") || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  <Grid item container spacing={2}>
                    <Grid item>
                      <Typography className={clsx(classes.title, classes.titleLabel)}>Mã KH đổi thưởng:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={clsx(classes.marginLeftSpace)}>
                        {_.get(data, "exchange_code") || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  <Grid item container spacing={2}>
                    <Grid item>
                      <Typography className={clsx(classes.title, classes.titleLabel)}>Số điện thoại:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={clsx(classes.marginLeftSpace)}>
                        {_.get(data, "exchange_phone") ? formatPhone({ phone: _.get(data, "exchange_phone"), type: 'first' }) : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  <Grid item container spacing={2}>
                    <Grid item>
                      <Typography className={clsx(classes.title, classes.titleLabel)}>E-mail:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={clsx(classes.marginLeftSpace)}>
                        {_.get(data, "exchange_email") || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={8} style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  <Grid item container spacing={2}>
                    <Grid item>
                      <Typography className={clsx(classes.title, classes.titleLabel)}>Mã CMND/CCCD:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={clsx(classes.marginLeftSpace)}>
                        {_.get(data, "exchange_personalid") || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  <Grid item container spacing={2}>
                    <Grid item>
                      <Typography className={clsx(classes.title, classes.titleLabel)}>Ngày giao dịch:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={clsx(classes.marginLeftSpace)}>
                        {
                          _.get(data, "exchange_date") ? moment(_.get(data, "exchange_date")).format("DD/MM/yyyy") : "-"
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={8} style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  <Grid item container spacing={2}>
                    <Grid item>
                      <Typography className={clsx(classes.title, classes.titleLabel)}>Sản phẩm áp dụng:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={clsx(classes.marginLeftSpace)}>
                        {_.get(data, "exchange_product") || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={4} style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  <Grid item container spacing={2}>
                    <Grid item>
                      <Typography className={clsx(classes.title, classes.titleLabel)}>Người cập nhật:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={clsx(classes.marginLeftSpace)}>
                        {_.get(data, "created_by") || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={8} style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  <Grid item container spacing={2}>
                    <Grid item>
                      <Typography className={clsx(classes.title, classes.titleLabel)}>Thời gian cập nhật:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={clsx(classes.marginLeftSpace)}>
                        {
                          _.get(data, "exchange_date") ? moment(_.get(data, "exchange_date")).format("DD/MM/yyyy") : "-"
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
        </Paper>
      </Grid>

      <Grid
        container
        justifyContent="flex-end"
        spacing={2}
        style={{ marginTop: 50 }}
      >
        {_.includes(["INITIAL", "ACTIVATED"], _.get(data, "voucher_status.code")) ? (
          isEdit ? (
            <>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Check />}
                  onClick={handleUpdate}
                  disabled={_.get(customer, "customer") === "Invalid" || _.get(inputValues, "exchange_code") === "" || updatingAction}
                >
                  Xác nhận
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<Cancel />}
                  onClick={() => {
                    setInputValues({});
                    setCustomer({});
                    setIsEdit(false);
                  }}
                >
                  Hủy bỏ
                </Button>
              </Grid>
            </>
          ) : (
            <Button
              color="primary"
              startIcon={<Edit />}
              variant="contained"
              onClick={() => setIsEdit(true)}
            >
              Cập nhật
            </Button>
          )
        ) : null}
      </Grid>
    </Fragment>
  );
};

export default JackVoucherDetail;
