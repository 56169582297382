import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import ecoeImage from "../../../assets/images/banner/logoEcoe.png";

import moment from "moment";
import _ from "lodash";

const ItemHistory = ({ gridItem = false, data = undefined, setFlag }) => {
  const useStyles = makeStyles((theme) => ({
    itemContainer: {
      marginTop: 20,
      "&:hover": {
        backgroundColor: "#C4C4C4",
      },
      cursor: "pointer",
    },
    customerName: {
      textDecoration: "underline",
    },
  }));

  const classes = useStyles();
  return (
    <Fragment>
      {gridItem ? (
        <Grid
          className={classes.itemContainer}
          container
          direction="row"
          alignItems="center"
          //   onClick={() => setFlag((prev) => ({ ...prev, isDetail: true }))}
        >
          <Grid item>
            <img src={ecoeImage} alt="LogoEcoe" style={{ width: 40 }} />
          </Grid>
          <Grid item style={{ marginLeft: 20 }}>
            <span>
              Ngày {moment(_.get(data, "created")).format("DD/MM/YYYY HH:mm")}
            </span>{" "}
            <span>
              cập nhật chương trình khuyến mãi bởi{" "}
              <strong>{_.get(data, "accountByAccount.code")}</strong>
            </span>
          </Grid>
        </Grid>
      ) : (
        <Fragment>If not using grid item</Fragment>
      )}
    </Fragment>
  );
};

export default ItemHistory;
