import * as _ from 'lodash';
import React from 'react';
import {
    Paper, Divider, Button,
    Typography,
    TextField,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';


const DATA_TEST = {
    key: 0,
    cusId: 'E2100001', cusName: 'Huy Nguyễn', cusPhone: '0901234567', cusAvatar: '', cusMessage: 'Tư vấn không thành thật',
    empId: 'E0046', empName: 'Huy Vũ', createdDate: 1623661896066
}

const CustomerReviewEdit = (props) => {

    const useStyles = makeStyles(theme => ({
        root: {
            height: 'calc(100vh - 80px)',
            paddingLeft: '30px',
            position: 'relative',
        },
        headerStyle: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '30px',
            '& button': {
                color: '#fff',
                background: '#2c8a81',
                borderRadius: 0,
                height: '50px',

                '&.active': {
                    fontWeight: 'bold',
                    background: '#f9ab2c',
                }
            }
        },
        contentStyle: {
            maxHeight: 'calc(100% - 200px)',
            overflow: 'hidden',
            overflowY: 'auto',

            width: '100%',
            display: 'flex',
            flexDirection: 'column',

        },
        rowStyle: {
            display: 'inline-flex',
            paddingBottom: '30px',
            '& div': {
                minWidth: '120px',
                paddingRight: '30px',
            },
            '& div:nth-child(2n+1)': {
                fontWeight: 'bold',
            }
        },
        row500Style: {
            maxWidth: '500px',
            paddingBottom: '30px',
        },
        footerStyle: {
            '& button': {
                backgroundColor: 'black',
                color: '#ffffff',
                borderRadius: 0,
                padding: '10px 40px',
                position: 'absolute',
                right: 30,
                bottom: 30,
            },
        },
    }))
    const classes = useStyles();
    const history = useHistory();

    return (
        <Paper className={classes.root}>
            <div className={classes.headerStyle} ></div>

            <div className={classes.contentStyle}>
                <Typography gutterBottom variant="h4" color="textSecondary" component="h4">Thông tin khách hàng</Typography>
                <div className={classes.rowStyle}>
                    <div>Khách hàng:</div>
                    <div>{_.get(DATA_TEST, 'cusName')}</div>
                    <div>Mã khách hàng:</div>
                    <div>{_.get(DATA_TEST, 'cusId')}</div>
                </div>
                <div className={classes.rowStyle}>
                    <div>Số điện thoại:</div>
                    <div>{_.get(DATA_TEST, 'cusPhone')}</div>
                </div>
                <div className={classes.rowStyle}>
                    <div>Lý do:</div>
                    <div>{_.get(DATA_TEST, 'cusMessage')}</div>
                </div>

                <div className={classes.row500Style}>
                    <Typography gutterBottom variant="h4" color="textSecondary" component="h4">Ghi chú của khách hàng</Typography>
                    <TextField
                        id="customer-message-id"
                        defaultValue={_.get(DATA_TEST, 'cusMessage', 'Nhập lý do báo xấu')}
                        fullWidth
                        multiline
                        rows={3}
                        rowsMax={3}
                        disabled
                        variant="outlined"
                    />
                </div>

                <div className={classes.row500Style}>
                    <Typography gutterBottom variant="h4" color="textSecondary" component="h4">Ghi chú của ECOE</Typography>
                    <TextField
                        id="ecoe-message-id"
                        placeholder='Nhập thông tin'
                        fullWidth
                        multiline
                        rows={3}
                        rowsMax={3}
                        variant="outlined"
                    />
                </div>
            </div>

            <Divider style={{ margin: '30px 0' }} />

            <div className={classes.footerStyle}>
                <Button variant="contained" onClick={() => history.push('/setting')}>Quay lại</Button>
            </div>

        </Paper>
    )
};
export default CustomerReviewEdit;
