export default {
  dashboard: "/",
  dashboardv2: "/dashboard",

  signIn: "/login",
  // signUp: '/register',
  // resetPassword: '/reset-password',

  product: "/product",
  productPrimary: "/product/primary",
  productPrimaryCreateBooking: "/product/primary/booking/create/:id",
  productPrimaryDetailBooking: "/product/primary/booking/:id",
  productCreatePrimary: "/product/primary/create",
  productEditPrimary: "/product/primary/edit/:id",
  productDetailsPrimary: "/product/primary/details/:id",
  productCartPrimary: "/product/primary/cart",
  productCartPrimaryCreate: "/product/primary/cart/create",
  productCartPrimaryUpdate: "/product/primary/cart/create/:id",
  productSecondary: "/product/secondary",
  productDetailSecondary: "/product/secondary/detail/:id",
  productCreateSecondary: "/product/secondary/create",
  productEditSecondary: "/product/secondary/edit/:id",

  customer: "/customer",
  customerCreate: "/customer/create",
  customerDetail: "/customer/detail/:id",
  customerReview: "/customer/review",
  customerReviewEdit: "/customer/review/edit/:id",
  customerReviewCreate: "/customer/review/create",

  employee: "/employee",
  employeeCreate: "/employee/create",
  employeePriority: "/employee/priority",
  employeeLocate: "/employee/locate",
  employeeDetail: "/employee/detail/:id",
  employeeCreateAppointment: "/employee/create/appointment",

  branchList: "/branches",
  branchCreate: "/branches/create",
  branchDetail: "/branches/detail/:id",
  branchDetailEdit: "/branches/edit/:id",

  permission: "/employee/permission",
  permissionsAccountCreate: "/employee/permission/account/create",

  officeCreate: "/office/create",

  setting: "/setting",
  settingNotification: "/setting/notification",
  settingBanner: "/setting/banner",

  notify: "/notify",

  promotion: "/promotion",
  promotionCreate: "/promotion/create",
  promotionDetail: "/promotion/detail/:id",

  promotionCreateVoucher: "/promotion/voucher/create",
  promotionDetailVoucher: "/promotion/voucher/detail/:id",
  promotionDetailRewardVoucher: "/promotion/voucher/reward",

  promotionJackGameVoucherDetail: "/jack-voucher/detail/:id",



  bingoResultDetail: "/bingo-result/detail/:id",
  bingoRoundResultDetail: "/bingo-result/round/:id",
  setBingoRoundResultCode: "/bingo-result/setting/:id",

  pageNotFound: "/not-found-404",
};
