import { Grid } from "@material-ui/core";
import React from "react";
import ChartCategory from "../components/Chart/ChartCategory";
import ChartCategoryAndLand from "../components/Chart/ChartCategoryAndLand";
import ChartProductStatus from "../components/Chart/ChartProductStatus";
import ChartQuantity from "../components/Chart/ChartQuantity";
import ChartGrowSecondary from "../components/Chart/ChartGrowSecondary";
import { Chart as ChartJS, registerables } from "chart.js";
import { useQuery } from "@apollo/client";
import { GET_BRANCHES } from "../graphql/schemas/masterdata/branches";
import {
  GET_PRODUCT_STATUS_ALL,
  GET_PROPERTY_TYPE,
  GET_PROPERTY_TYPE_SUB_PROJECT,
} from "../graphql/schemas/product/productQueries";
import { GET_ALL_DISTRICTS } from "../graphql/schemas/masterdata/address";
import _ from "lodash";

ChartJS.register(...registerables);

const Dashboard = (props) => {
  const {
    loading: loadingBranches,
    data: dataBranches,
    error: errorBranches,
  } = useQuery(GET_BRANCHES);
  const {
    loading: loadingStatus,
    data: dataStatus,
    error: errorStatus,
  } = useQuery(GET_PRODUCT_STATUS_ALL);
  const {
    loading: loadingProperty,
    data: dataProperty,
    error: errorProperty,
  } = useQuery(GET_PROPERTY_TYPE_SUB_PROJECT);
  const {
    loading: loadingDistricts,
    data: dataDistricts,
    error: errorDistricts,
  } = useQuery(GET_ALL_DISTRICTS, {
    variables: {
      provinceId: 2,
      skip: 0,
    },
  });

  if (loadingBranches || loadingStatus || loadingProperty || loadingDistricts) {
    return <p>Loading...</p>;
  }

  if (errorBranches || errorStatus || errorProperty || errorDistricts) {
    return <p>Error...</p>;
  }

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Welcome to Dashboard</h1>
      <Grid container spacing={8} direction="column">
        <ChartCategory branches={dataBranches.results} status={dataStatus} />
        <ChartCategoryAndLand districts={dataDistricts.results} />
        <ChartGrowSecondary
          branches={dataBranches.results}
          properties={dataProperty.results}
          status={dataStatus}
        />
        <ChartProductStatus
          branches={dataBranches.results}
          properties={dataProperty.results}
        />
        {/* <ChartQuantity /> */}
      </Grid>
    </div>
  );
};

export default Dashboard;
