import React, { useState, useContext } from 'react';
import { Grid, ButtonGroup, Button, Paper, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SearchText from '../Product/Secondary/components/SearchText';
import { Search } from '@material-ui/icons';
import ItemListNotify from '../../components/Notify/ItemListNotify';
import { useQuery } from '@apollo/client';
import { GET_NEW_NOTIFICATIONS, GET_OLD_NOTIFICATIONS } from '../../graphql/schemas/notification';
import _ from 'lodash';
import { StoreContext } from '../../resources/store';

const useStyles = makeStyles(theme => ({
    buttonGroup: {
        width: '700px'
    },
    button: {
        width: '50%',
        color: 'white'
    },
    rootSearch: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        borderRadius: 10,
        marginTop: 40
    },
}));

const NotifyPage = () => {
    const classes = useStyles();
    const { userInfo, accessPermissions } = useContext(StoreContext);
    const account = _.get(userInfo,"id");
    const [isReaded, setIsReaded] = useState(false);
    const [searchText, setSearchText] = useState('');

    const { data: newData, loading: newLoading } = useQuery(GET_NEW_NOTIFICATIONS, {
        variables: {
            account: account
        }
    });

    const { data: oldData, loading: oldLoading } = useQuery(GET_OLD_NOTIFICATIONS, {
        variables: {
            account: account,
            skip: 0,
            take: 10
        }
    });

    return (
        <Grid container direction="column">
            <Grid container direction="row">
                <ButtonGroup className={classes.buttonGroup}>
                    <Button
                        className={classes.button}
                        style={{ backgroundColor: isReaded ? '#388A81' : '#E27F35' }}
                        onClick={() => setIsReaded(false)}>
                        Thông báo mới {_.get(newData, 'results') ? '(' + _.size(_.get(newData, 'results')) + ')' : null}
                    </Button>
                    <Button
                        className={classes.button}
                        style={{ backgroundColor: isReaded ? '#E27F35' : '#388A81' }}
                        onClick={() => setIsReaded(true)}>
                        Thông báo đã đọc
                    </Button>
                </ButtonGroup>
            </Grid>
            <Grid container direction="row">
                <Grid item>
                    <Paper className={classes.rootSearch}>
                        <IconButton className={classes.iconButton} aria-label="search">
                            <Search />
                        </IconButton>
                        <SearchText
                            text={searchText}
                            setText={setSearchText}
                        />
                    </Paper>
                </Grid>
            </Grid>
            {
                !isReaded ? _.size(_.get(newData, 'results')) > 0 ? <Grid container direction="row">
                    {_.map(_.get(newData, 'results'), (item, index) => {
                        return <ItemListNotify key={index} data={item} isNew={true}></ItemListNotify>
                    })
                    }

                </Grid> : <div style={{ marginTop: "20px" }}>Không có thông báo mới</div> : <Grid container direction="row">
                    {_.map(_.get(oldData, 'results'), (item, index) => {
                        return <ItemListNotify key={index} data={item} isNew={false}></ItemListNotify>
                    })
                    }
                </Grid>
            }

        </Grid>
    )
}

export default NotifyPage
