import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import clsx from 'clsx';
import { makeStyles, TextField } from '@material-ui/core';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const ModalConfirm = ({ open, handleClose, title, description, submit, isNote = false, onChangeNote = undefined, note }) => {
    const useStyles = makeStyles(theme => ({
        title: {
            color: '#00837B',
            fontSize: 24,
            fontWeight: 600,

        },
        description: {
            fontSize: 16,
            fontWeight: 'normal',
            color: '#0A2240',
            margin: '24px 0px'
        },
        back: {
            backgroundColor: '#E2E1E1',
            color: '#0A2240',
            width: 150,
            height: 48
        },
        confirm: {
            backgroundColor: '#00837B',
            color: '#fff',
            width: 150,
            height: 48,
            marginLeft: 12
        }
    }));
    const classes = useStyles();
    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>
                <Typography className={clsx(classes.title)} variant="h6" component="h2">
                    {title}
                </Typography>
                <Typography className={clsx(classes.description)} sx={{ mt: 2 }}>
                    {description}
                </Typography>
                {isNote && (
                    <Grid container direction="row">
                        <TextField value={note} onChange={onChangeNote} multiline variant="outlined" minRows={4} style={{ width: '100%', margin: '12px 0px' }} />
                    </Grid>
                )}
                <Grid container direction="row" justifyContent="flex-end" marginTop="30px">
                    <Button size="small" onClick={handleClose} className={clsx(classes.back)}>Quay lại</Button>
                    <Button size="small" onClick={submit} className={clsx(classes.confirm)}>Xác nhận</Button>
                </Grid>

            </Box>


        </Modal>
    )
}

export default ModalConfirm
