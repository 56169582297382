import React, { Fragment, useState } from 'react';
import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import ReactPlayer from 'react-player';

import BathtubIcon from '@material-ui/icons/Bathtub';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Moment from 'react-moment';

const Desc = ({ product }) => {
    const useStyles = makeStyles((theme) => ({
        text: {
            fontSize: 12
        },
        descContainer: {
            width: 800,
            marginTop: 40,
            marginLeft: '80px'
        },
        cardRoot: {
            width: '54%',
            marginTop: 50,
            borderRadius: 20,
            marginLeft: 80
        },
        title: {
            fontSize: 16,
            fontWeight: 'bold',
            color: '#0A2240'
        },
        utilities: {
            display: 'flex',
            alignItems: 'center',
            margin: '5px 0px'
        },
        furnitues: {
            width: 460,
            // '& .MuiGrid-item:nth-child(4n)': {
            //     borderRight: 'none'
            // },
            '& .MuiGrid-item:last-child': {
                borderRight: 'none',
            },
            '& .MuiGrid-item': {
                width: 'auto',
                padding: '0 10px'
            },
            '& .MuiGrid-item:nth-child(4n + 1)': {
                justifyContent: 'center',
                marginLeft: 10
            },
        },
        furnituesItem: {
            borderRight: '2px solid lightgray',
            display: 'flex',
            width: 20,
            marginTop: 16,
            marginBottom: 16,
            width: 'auto',
            '& p': {
                color: '#0A2240',
                fontSize: 13,
            }
        }
    }))
    const classes = useStyles();

    const furnitues = _.chain(product.furnitures).groupBy("furniture.room_type.name").map((value, key) => ({ key: key, data: value })).value();
    return (
        <Fragment>
            <Grid item className={classes.descContainer}>
                <Typography className={classes.text}>
                    {_.get(product, "product_detail.description")}
                </Typography>
            </Grid>
            <Grid item>
                <Card className={classes.cardRoot} variant="outlined">
                    <CardContent>
                        <Grid item style={{ marginLeft: 30 }}>
                            <Grid item style={{ marginBottom: 10 }}>
                                <Typography className={classes.title} style={{ color: '#384B64', marginBottom: 10 }}>
                                    Hướng
                                </Typography>
                                <Typography style={{ color: '#384B64' }} className={classes.text}>{_.get(product, 'orientation.name') ? _.get(product, 'orientation.name') : ""}</Typography>
                            </Grid>
                            <Grid item style={{ marginBottom: 10 }}>
                                <Typography className={classes.title} style={{ color: '#384B64', marginBottom: 10 }}>
                                    Giấy tờ
                                </Typography>
                                <Typography style={{ color: '#384B64' }} className={classes.text}>{_.get(product, 'legal_type.name') ? _.get(product, 'legal_type.name') : ""}</Typography>
                            </Grid>
                            <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography className={classes.title} style={{ color: '#384B64' }}>
                                    Video sản phẩm
                                </Typography>

                                <ReactPlayer url={_.get(product, 'product_detail.video_url')} width="120" />
                            </Grid>
                            <Grid item style={{ marginBottom: 10, marginTop: 10 }}>
                                <Typography className={classes.title} style={{ color: '#384B64', marginBottom: 10 }}>
                                    Không gian 3D sản phẩm
                                </Typography>
                                <iframe width="765" height="300" src={_.get(product, 'product_detail.view_3d_url')} frameBorder="0" allowFullScreen allow="xr-spatial-tracking"></iframe>
                            </Grid>
                            <Grid container direction="row" className={classes.utilitiesContainer}>
                                <Grid item sm={12} style={{ marginTop: 10, marginBottom: 24 }}>
                                    <Typography className={classes.title}>Tiện tích</Typography>
                                </Grid>
                                {_.map(product.product_utilities, (item, key) => (
                                    <Grid item sm={6} key={item.utility.id} className={classes.utilities}>
                                        <CheckCircleIcon style={{ color: '#566F90', fontSize: 12, marginRight: 10 }} /> <Typography style={{ fontSize: 12 }}>{item.utility.name}</Typography>
                                    </Grid>
                                ))}
                            </Grid>
                            {/* Nội thất */}
                            <Grid container direction="row">
                                <Grid item sm={12} style={{ marginTop: 10, marginBottom: 24 }}>
                                    <Typography className={classes.title}>Nội thất</Typography>
                                </Grid>

                                <Grid container direction="row">
                                    {_.map(furnitues, (item, index) => (
                                        <Fragment key={item.key}>
                                            <Grid item sm={12} key={item.key} style={{ display: 'flex', alignItems: 'center' }}>
                                                <BathtubIcon style={{ fontSize: 17, marginRight: 10, color: '#B9B9B9' }} />
                                                <Typography style={{ color: '#0A2240', fontSize: 14, fontWeight: 'bold' }}>
                                                    {item.key}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" className={classes.furnitues}>
                                                {_.map(item.data, (item, index) => (
                                                    <Grid item key={item.furniture.id} className={classes.furnituesItem}>
                                                        <Typography>{item.furniture.name}</Typography>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Fragment>

                                    ))}

                                </Grid>
                            </Grid>
                            {/* Footer Detail */}
                            <Grid container direction="column" style={{ marginTop: 30 }}>
                                <Grid container direction="row" justifyContent="space-between">
                                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Mã sản phẩm: </Typography>
                                        <Typography style={{ fontSize: 13, marginLeft: 10 }}>{_.get(product, 'code') ? _.get(product, 'code') : ""}</Typography>
                                    </Grid>
                                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography style={{ fontWeight: 'bold', color: '#0A2240' }}>Ngày tạo: </Typography>
                                        <Typography style={{ fontSize: 13, marginLeft: 10 }}><Moment format="DD/MM/YYYY">{_.get(product, 'created')}</Moment></Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Fragment>

    )
}

export default Desc;
