import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/styles/useTheme';
import _ from 'lodash';

import EmployeeInfoView from '../../components/employee/EmployeeInfoView';
import ManageProduct from '../../components/employee/ManageProduct';
import HistoryActivityEmployee from '../../components/employee/HistoryActivityEmployee';
import Calendar from '../../components/calendar/Calendar';
import EmployeeCreateTime from '../../components/employee/EmployeeCreateTime';
import { SCHEDULE, SCHEDULE_CREATE, SCHEDULE_DETAIL, SCHEDULE_EDIT } from './constant';
import EmployeeEditTime from '../../components/employee/EmployeeEditTime';
import EmployeeDetailTime from '../../components/employee/EmployeeDetailTime';
import { useEmployee } from './hooks'
import { useQuery } from '@apollo/client';
import { GET_ALL_SCHEDULE, GET_EMPLOYEE_BY_ID } from '../../graphql/schemas/employee/employeeQueries';
import {
	CircularProgress, useMediaQuery,
	Paper, Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import { UPDATE_EMPLOYEE, USER_ROLE_ADMINISTRATOR, USER_ROLE_SUPPER_ADMIN, VIEW_EMPLOYEE } from '../../constants/defined';
import { StoreContext } from '../../resources/store';

import { checkedPermission } from '../../helper';
import AccessDenied from '../Permission/AccessDenied';

const useStyles = makeStyles(theme => ({
	root: {
		minHeight: "calc(100vh - 200px)",
		position: "relative",
	},
	typeTab: {
		fontSize: "20px",
		color: "#B9B9B9",
		"&:not(.selected)": {
			cursor: "pointer",
		},
		"&.selected": {
			color: "#0A2240",
			position: "relative",
			"&:after": {
				content: `''`,
				width: "calc(100% - 30px)",
				height: "3px",
				background: "#DA6744",
				position: "absolute",
				bottom: "10px",
				left: "15px",
			},
		},
	},
	wrapperContent: {
		minHeight: "calc(100vh - 200px)",
		position: "relative",
		padding: "24px",
		margin: "0 auto",
	},
	wapperLoading: {
		width: '100%',
		height: 'calc(100vh - 200px)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

const TAB_MENU = [
	{ id: 1, code: "INFOEMPLOYEE", name: "Thông tin nhân viên" },
	{ id: 2, code: "MANAGEPRODUCT", name: "Quản lý sản phẩm" },
	{ id: 3, code: "HISTORYACTIVITY", name: "Lịch sử hoạt động" },
	{ id: 4, code: "MANAGETIME", name: "Quản lý lịch hẹn" },
];

const EmployeeDetail = (props) => {
	const theme = useTheme();
	const classes = useStyles();
	const { id } = useParams();
	const [idSchedule, setIdSchedule] = useState();
	const [kindMenu, setKindMenu] = useState(window.localStorage.getItem('activeMenuDetailEmployee') || "infoemployee");
	const [showDetailTeam, setShowDetailTeam] = useState(false);

	const { loading: loadingEmployee, data, refetch } = useQuery(GET_EMPLOYEE_BY_ID, {
		variables: {
			skip: 0,
			take: 10,
			where: {
				accountByAccount: {
					deleted: { _eq: false },
					id: {
						_eq: id
					}
				}
			}
		}
	})

	const employee = _.get(data, 'results[0]')

	const { loading: loadingEvents, error: errorEvents, data: dataEvents } = useQuery(GET_ALL_SCHEDULE, {
		variables: {
			skip: 0,
			take: 10,
			where: {
				deleted: { _eq: false },
				employee: { _eq: Number(id) },
			}
		},
	});

	const [showSchedule, setShowSchedule] = useState(SCHEDULE);

	const returnRoleAdmin = () => {
		const roles = _.get(employee, 'info.roles');
		if (roles) {
			for (let i = 0; i < roles.length; i++) {
				if (_.includes([USER_ROLE_SUPPER_ADMIN, USER_ROLE_ADMINISTRATOR], _.toUpper(_.get(roles[i], 'role.code')))) {
					return true;
				} else {
					return false;
				}
			}
		}
	}

	const handleTabSelect = (type) => {
		setKindMenu(type);
		window.localStorage.setItem('activeMenuDetailEmployee', type);
	};

	const { accessObjectPermissions, userInfo } = useContext(StoreContext);

	if (loadingEmployee || loadingEvents) {
		return (
			<div className={classes.wapperLoading}>
				<CircularProgress />
			</div>
		)
	};

	return (
		<>
			<Grid
				container
				direction="row"
				spacing={3}
				justifyContent="space-between"
				className={classes.root}
			>
				<Grid item>
					<Grid container spacing={4}>
						{_.map(TAB_MENU, (item, key) => {
							const selected = _.includes(_.toUpper(kindMenu), _.get(item, "code"));
							if (returnRoleAdmin() && _.includes(["MANAGEPRODUCT", "MANAGETIME"], _.get(item, "code"))) return null;
							return (
								<Grid
									key={key}
									item
									className={clsx(classes.typeTab, selected && "selected")}
									onClick={() =>
										!selected && handleTabSelect(_.toLower(_.get(item, "code")))
									}
								>
									{_.get(item, "name")}
								</Grid>
							);
						})}
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Paper className={classes.wrapperContent}>
						{
							kindMenu === "infoemployee" && (
								<EmployeeInfoView
									showDetailTeam={showDetailTeam}
									setShowDetailTeam={setShowDetailTeam}
									employee={employee}
								/>
							)
						}

						{
							kindMenu === "manageproduct" && (
								<ManageProduct
									employee={employee}
								/>
							)
						}

						{
							kindMenu === "historyactivity" && (
								<HistoryActivityEmployee
									employee={employee}
									refetch={refetch}
								/>
							)
						}

						{
							(kindMenu === "managetime" && showSchedule === SCHEDULE) && (
								<Calendar
									onCreate={() => setShowSchedule(SCHEDULE_CREATE)}
									onDetail={(id) => { setShowSchedule(SCHEDULE_DETAIL); setIdSchedule(id) }}
									data={dataEvents}
								/>
							)
						}

						{
							(kindMenu === "managetime" && checkedPermission(userInfo, accessObjectPermissions, UPDATE_EMPLOYEE) && showSchedule === SCHEDULE_CREATE) && (
								<EmployeeCreateTime
									id={id}
									onBack={() => setShowSchedule(SCHEDULE)}
								/>
							)
						}

						{
							(kindMenu === "managetime" && !checkedPermission(userInfo, accessObjectPermissions, UPDATE_EMPLOYEE) && showSchedule === SCHEDULE_CREATE) && (
								<AccessDenied />
							)
						}

						{
							(kindMenu === "managetime" && showSchedule === SCHEDULE_EDIT) && checkedPermission(userInfo, accessObjectPermissions, UPDATE_EMPLOYEE) && (
								<EmployeeEditTime
									idSchedule={idSchedule}
									onBack={() => setShowSchedule(SCHEDULE)}
								/>
							)
						}

						{
							(kindMenu === "managetime" && showSchedule === SCHEDULE_EDIT) && !checkedPermission(userInfo, accessObjectPermissions, UPDATE_EMPLOYEE) && (
								<AccessDenied />
							)
						}

						{
							(kindMenu === "managetime" && showSchedule === SCHEDULE_DETAIL) && checkedPermission(userInfo, accessObjectPermissions, VIEW_EMPLOYEE) && (
								<EmployeeDetailTime
									idSchedule={idSchedule}
									onBack={() => setShowSchedule(SCHEDULE)}
									onEdit={() => setShowSchedule(SCHEDULE_EDIT)}
								/>
							)
						}

						{
							(kindMenu === "managetime" && showSchedule === SCHEDULE_DETAIL) && !checkedPermission(userInfo, accessObjectPermissions, VIEW_EMPLOYEE) && (
								<AccessDenied />
							)
						}
					</Paper>
				</Grid>
			</Grid>

		</>

	)
}

export default EmployeeDetail;
