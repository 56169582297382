//content tab - ApprovalHistory
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Typography, Avatar, Grid, useMediaQuery, Button } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import ecoeIcon from '../../../assets/icons/logo-ecoe-no-char.svg';
import Moment from 'react-moment';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_ACTIVITY_HISTORY } from '../../../graphql/schemas/product/productQueries';
import { CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import moment from 'moment';


const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'none',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);




const ApprovalHistory = props => {

    const useStyles = makeStyles((theme) => ({
        circle: {
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            marginRight: '32px'
        },
        message: {
            fontSize: '18px',
            marginLeft: '40px',
            marginRight: '160px',
            wordWrap: 'break-word'
        },
        buttonBack: {
            backgroundColor: 'black',
            color: '#ffffff',
            borderRadius: 0,
            marginTop: '40px',
            padding: '10px 40px'
        }
    }));
    const { id } = useParams();
    const { loading, data } = useQuery(GET_PRODUCT_ACTIVITY_HISTORY, {
        variables: {
            where: {
                requestByRequest: {
                    typeByType: {
                        code: {
                            _eq: "CONSIGN"
                        }
                    },
                    productByProduct: {
                        product: {
                            _eq: id
                        }
                    }
                }
            }
        }
    })
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const history = useHistory();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        if (!expanded) setExpanded('panel1');
    });

    const returnedItems = (item) => {
        if (_.isNull(_.get(item, 'requestByRequest.employee_info'))) {
            return (
                <Grid
                    item
                    className={classes.message}
                    style={{ width: matchesSM ? "600px" : '800px' }}
                >
                    Hệ thống tự động cập nhật
                </Grid>
            )
        } else {
            return (
                <Grid
                    item
                    className={classes.message}
                    style={{ width: matchesSM ? "600px" : '800px' }}
                >
                    Nhân viên {_.get(item, 'requestByRequest.employee_info.full_name')} (Mã NV: {_.get(item, 'requestByRequest.employee_info.code')}): {_.get(item, 'type_workflow.type_step.name').toLowerCase()}
                </Grid>
            )
        }
    }

    if (loading || !data.request_workflow) return <CircularProgress />
    return (
        <div className={classes.root}>

            <Grid container direction="column">

                {
                    _.map(data.request_workflow, (item, index) => <Grid container direction="row" style={{ marginTop: '60px', flexWrap: 'wrap' }} alignItems="center" key={index}>
                        <Grid item className={classes.circle} style={{ backgroundColor: '#dfe235' }} ></Grid>
                        <Grid item><img src={ecoeIcon} alt="logo" width={40} /></Grid>
                        {returnedItems(item)}
                        <Grid item style={{ fontSize: '18px' }}>  {moment(item.created).format('DD/MM/YYYY hh:mm:ss A')}</Grid>
                    </Grid>)
                }
            </Grid>
        </div>
    );
}
export default ApprovalHistory;
