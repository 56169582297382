import * as _ from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    label: {
        fontWeight: 'bold',
        paddingRight: '30px',
        position: 'relative',
    },
    groupUtility: {
        display: 'flex',
        marginTop: '-30px',
        '& > div': {
            display: 'flex',
            background: '#fff',
            padding: '15px 80px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            cursor: 'pointer',
            marginTop: '30px',
            '&:not(:last-child)': { marginRight: '30px', },
            '&.selected': { background: '#f0f0f0', },
            '&:hover': { background: "#f0f0f0", },
        },
    },
    paddingTop: {
        paddingTop: '30px',
        '& > div:first-child': {
            textAlign: 'center',
        },
        '& > div:last-child': {
            color: '#ccc',
        },
    },
}));


const SelectUtilities = ({ data, title, icon, object, setObject }) => {
    const classes = useStyles();

    const handleClick = (item) => {
        let list = object;
        if (_.indexOf(list, _.get(item, 'id')) >= 0) {
            list = _.difference(list, [_.get(item, 'id')]);
        } else {
            list = _.concat(list, _.get(item, 'id'));
        }
        setObject(list);
    };

    if (_.isEmpty(data)) return null;

    return (
        <Fragment>
            {_.isEmpty(icon) ? (
                <Typography component='div' className={clsx(classes.label)}>{title}</Typography>
            ) : (
                <Grid container xs={12} className={classes.paddingTop}>
                    <Grid xs={2}><img src={icon} /></Grid>
                    <Grid xs={3}><Typography component='div' className={clsx(classes.label)}>{title}</Typography></Grid>
                </Grid>
            )}

            <Grid container xs={12} className={classes.groupUtility}>
                {_.map(data, (item, key) =>
                    <div
                        key={key}
                        className={clsx({ selected: _.indexOf(object, _.get(item, 'id')) >= 0 })}
                        onClick={() => handleClick(item)}
                    >
                        {_.get(item, 'name')}
                    </div>
                )}
            </Grid>
        </Fragment>
    );

}

SelectUtilities.propTypes = {
    title: PropTypes.string,
    data: PropTypes.array,
    icon: PropTypes.any,
    label: PropTypes.string,
    object: PropTypes.array,
    setObject: PropTypes.func,
};

export default SelectUtilities;
