import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AddressAutocomplete({
  dataList,
  objectLabel = "label",
  objectValue = "code",
  multiple = true,
  setValue,
  placeholder = "placeholder",
  size = undefined
}) {
  return (
    <Autocomplete
      multiple={multiple}
      limitTags={2}
      options={dataList}
      onChange={(e, newValue) => setValue(newValue)}
      disableCloseOnSelect
      getOptionLabel={(option) => option[objectLabel]}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            value={selected}
          />
          {option[objectLabel]}
        </React.Fragment>
      )}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" placeholder={placeholder} size={size} />
      )}
    />
  );
}
