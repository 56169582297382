import * as _ from 'lodash';
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Divider, Button } from '@material-ui/core';
import { ArrowBack, LibraryAddCheck } from '@material-ui/icons';
import ScrollableTabs from '../../../components/ScrollableTabs';
import InfoProduct from './InfoProduct';
import ApprovalHistory from './ApprovalHistory';
import DealHistory from './DealHistory';
import ApproveButton from '../../../components/ApproveButton';
import { StoreContext } from '../../../resources/store';

import { USER_ROLE_SUPPER_ADMIN } from '../../../constants/defined';
import { checkedPermission } from '../../../helper';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '30px',
    },
    actionStyle: {
        display: 'grid',
        gridTemplate: 'auto / auto auto',
        justifyContent: 'flex-end',
        textAlign: 'right',
        '& button': {
            margin: '0 15px',
        },
    },
}));

const ProductDetail = (props) => {
    const classes = useStyles();

    const { accessObjectPermissions, userInfo } = useContext(StoreContext);

    const LISTS = [
        { value: 0, navigationTitle: 'Thông tin sản phẩm', content: (props) => <InfoProduct {...props} />, active: "detailProduct" },
        { value: 1, navigationTitle: 'Lịch sử cập nhật', content: (props) => <ApprovalHistory {...props} />, active: "censorshipHistory" },
        { value: 2, navigationTitle: 'Lịch sử giao dịch', content: (props) => <DealHistory {...props} />, active: "transactionHistory" },
    ]

    return (
        <>
            <Paper className={classes.root}>
                <ScrollableTabs {...props} list={LISTS} />
            </Paper>

        </>

    );
}
export default ProductDetail;
