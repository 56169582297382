import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    root: {
        padding: '30px',
    },
    wapperLoading: {
        width: '100%',
        height: 'calc(100vh - 113px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerStyle: {
        height: '80px',
    },
    marginDivider: {
        margin: '30px 0',
    },
    formContainer: {
        display: 'block',
    },
    groupRow: {
        '& > div:not(:last-child)': {
            paddingBottom: '30px',
        }
    },
    gridRow: {
        alignItems: 'center',

        '& > .grid-image': {
            display: 'grid',
            gridGap: '30px',
        },
        '& > .column-3': {
            gridTemplate: 'auto / calc((100% - 60px)/3) calc((100% - 60px)/3) calc((100% - 60px)/3)',
        },
        '& > .column-4': {
            gridTemplate: 'auto / calc((100% - 60px)/4) calc((100% - 60px)/4) calc((100% - 60px)/4) calc((100% - 60px)/4)',
        },
    },
    vertical: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontWeight: 'bold',
        paddingBottom: '30px',
    },
    label: {
        fontWeight: 'bold',
        paddingRight: '30px',
        position: 'relative',

        '&.required:after': {
            content: '"*"',
            fontSize: '18px',
            lineHeight: 1,
            position: 'absolute',
            color: 'red',
            paddingLeft: '5px',
        },
        '&.unit > span': {
            position: 'absolute',
            right: 0,
            bottom: 0,
            fontWeight: 'normal',
            opacity: '.5',
        },
        '&.description': {
            paddingTop: '10px',
            fontStyle: 'italic',
            fontWeight: 'normal',
        },
    },
    button: {
        color: '#fff',
        borderRadius: 0,
        textTransform: 'uppercase'
    },
    secondaryButton: {
        borderBottom: '1px solid',
        display: 'inline-block',
        cursor: 'pointer',
        color: '#0037ff',
        fontWeight: 'bold',
    },
    imgWrapper: {
        position: 'relative',
        minHeight: '150px',
        borderRadius: '8px',
        overflow: 'hidden',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        '&.add-image-product': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#777',
            background: 'rgb(204 204 204 / 30%)',
            boxSizing: 'border-box',
            cursor: 'pointer',
            '& > div': {
                marginTop: '15px',
            },
            '&:hover': {
                border: '1px solid #777',
                background: 'rgb(204 204 204 / 100%)',
            },
        },
    },
    deleteImage: {
        fill: '#000',
        background: 'rgb(255 255 255 / 50%)',
        borderRadius: '50%',
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '8px',
        cursor: 'pointer',
        '&:hover': {
            fill: '#566F90',
            background: 'rgb(255 255 255 / 100%)',
        },
    },
    uploadButton: {
        width: '100%',
        border: '1px solid #0A2240',
        borderRadius: '12px',
        padding: '15px',
        marginTop: '30px',
        background: '#fff',
        cursor: 'pointer',
        '&:hover': {
            opacity: '.5',
        },
    },
    groupOrientation: {
        display: 'grid',
        gridTemplate: '150px 150px / 150px 150px 150px 150px',
        gridGap: '30px',
        '& > div': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #ccc',
            borderRadius: '8px',
            cursor: 'pointer',
            '&.selected': {
                background: '#f0f0f0',
                boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            },
            '&:hover': {
                background: "#f0f0f0",
                boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            },
        },
    },


    footerStyle: {
        height: '80px',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',

        '& button': {
            borderRadius: 0,
            padding: '5px 30px',
        },
        '& button:not(:last-child)': {
            marginRight: '30px',
        },

    },
}));