import { gql } from '@apollo/client';

export const CORE_SCHEMA_FIELDS = gql`

    fragment addressData on address {
        short
        street: streetByStreet {
            id
            name
        }
        ward: wardByWard {
            id
            name
        }
        district: districtByDistrict {
            id
            name
        }
        province: provinceByProvince {
            id
            name
        }
    }




`;
