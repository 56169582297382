import * as _ from 'lodash';
import React, { useState, } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, MenuItem, FormControl, Select } from '@material-ui/core';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
    formControl: {
        '&:not(.fullwidth)': {
            width: 300,
        },
        '&.fullwidth': {
            width: '100%',
        },
    },
    inputStyle: {
        '&>div': {
            padding: '10.5px 14px',
        },
        '& > .MuiSelect-select:focus': {
            background: 'none'
        },
        '&.MuiInput-underline:before': {
            height: '100%',
            border: '1px solid #ccc !important',
        },
        '&.MuiInput-underline:after': {
            borderBottom: 0,
        },
    },
    contentStyle: {
        paddingLeft: '8px',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, filters, theme) {
    return {
        fontWeight:
            filters.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const MultiSelectionObject = ({ title, list = [], setValue, selected, fullwidth }) => {
    const [filters, setFilters] = useState(() => {
        if (_.size(selected) > 0) {
            return _.map(selected, value => _.get(_.find(list, ['id', value]), 'name'));
        }
        return [];
    });
    const classes = useStyles();
    const theme = useTheme();

    const handleChange = (event) => {
        setFilters(event.target.value);
    };

    const handleSetValue = (e) => {
        if (_.isFunction(setValue)) {
            let arr = _.map(filters, value => _.get(_.find(list, ['name', value]), 'id'))
            setValue(arr)
        }
    };

    return (
        <FormControl className={clsx(classes.formControl, fullwidth && 'fullwidth')}>
            <Typography gutterBottom component='h4'>{title}</Typography>
            <Select
                className={classes.inputStyle}
                multiple
                displayEmpty
                value={filters}
                onChange={handleChange}
                onClose={handleSetValue}
                renderValue={
                    (selected) => <span className={classes.contentStyle}>{selected.length === 0 ? 'Tất cả' : _.join(selected, ', ')}</span>
                }
                MenuProps={MenuProps}
            >
                <MenuItem disabled value="">{`Chọn ${title}`}</MenuItem>
                {_.map(list, (item, key) => (
                    <MenuItem key={key} value={_.get(item, 'name')} style={getStyles(_.get(item, 'id'), filters, theme)}>
                        {_.get(item, 'name')}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
export default MultiSelectionObject;

MultiSelectionObject.propTypes = {
    title: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    setValue: PropTypes.func,
    fullwidth: PropTypes.bool,
    selected: PropTypes.array
};
