import React from "react";
import { withStyles, Radio } from "@material-ui/core";

const CustomRadio = withStyles((theme) => ({
	root: {
		color: '#B9B9B9',
		'&$checked': {
			color: '#00837B',
		},
	},
	checked: {},
}))((props) => <Radio color="default" {...props} />);

export default CustomRadio;
