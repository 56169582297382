import gql from "graphql-tag";

export const UPDATE_WORKFLOW_PRODUCT = gql`
    mutation RequestUpdateWorkflow($data: RequestUpdateWorkflowInput!) {
        result: requestUpdateWorkflow(args: $data) {
            affected_rows
        }
    }
`;

export const UPDATE_SOLD_STATUS_PRODUCT = gql`
    mutation updateProductById($id: uuid!, $product_status: smallint!, $remark: String!, $updated_by: bigint!) {
        result: update_product_by_pk(pk_columns: {id: $id}, _set: {product_status: $product_status, remark: $remark, updated_by: $updated_by, updated: "now"}) {
            id
        }
    }
`;

export const UPDATE_HIDDEN_PRODUCT = gql`
    mutation updateProductById($id: uuid!, $deleted: Boolean!, $remark: String!, $updated_by: bigint!) {
        result: update_product_by_pk(pk_columns: {id: $id}, _set: {deleted: $deleted, remark: $remark, updated_by: $updated_by, updated: "now"}) {
            id
        }
    }
`;

export const UPDATE_LOCK_PRODUCT = gql`
    mutation updateProductById($id: uuid!, $active: Boolean!, $deleted: Boolean!, $remark: String!, $updated_by: bigint!) {
        result: update_product_by_pk(pk_columns: {id: $id}, _set: {active: $active, deleted: $deleted, remark: $remark, updated_by: $updated_by, updated: "now"}) {
            id
        }
    }
`;
