import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

const RewardDetail = () => {
    const useStyles = makeStyles((theme) => ({
        titleContainer: {
            fontWeight: 'bold',
            fontSize: 24
        },
        bodyContainer: {
            backgroundColor: '#fff',
            borderRadius: 12,
            padding: 20
        },
        titleField: {
            width: 170,
            fontWeight: 'bold'
        },
        space: {
            width: 10,
            height: 30
        }
    }));
    const classes = useStyles();
    return (
        <Grid container direction='column'>
            <Grid container direction='row'>
                <Typography className={clsx(classes.titleContainer)}>Thông tin giải thưởng</Typography>
            </Grid>
            <Grid className={clsx(classes.bodyContainer)} container direction="row">
                <Grid container direction="row">
                    <Typography>Thông tin voucher</Typography>
                </Grid>
                <div className={clsx(classes.space)}></div>
                <Grid container direction="row">
                    <Grid xs={4} item style={{ display: 'flex' }}>
                        <Typography className={clsx(classes.titleField)}>Tên chương trình</Typography>
                        <Typography>Game Quay 2021</Typography>
                    </Grid>
                    <Grid xs={4} item style={{ display: 'flex' }}>
                        <Typography className={clsx(classes.titleField)}>Mã chương trình</Typography>
                        <Typography>KM202235</Typography>
                    </Grid>
                    <Grid xs={4} item style={{ display: 'flex' }}>
                        <Typography className={clsx(classes.titleField)}>Loại chương trình</Typography>
                        <Typography>Game Quay</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default RewardDetail
