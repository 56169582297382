import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { GET_EMPLOYEE_BY_CODE } from '../graphql/schemas/employee/employeeQueries';
import { useQuery } from '@apollo/client';
import * as _ from "lodash";

import { makeStyles } from '@material-ui/core/styles';

AutoCompleteEmployee.propTypes = {};

function AutoCompleteEmployee({ type, onChange, value, defaultValue, isSchedule, width = 250, placeholder }) {
    const [optList, setOptList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const useStyles = makeStyles(theme => ({
        input: {
            maxWidth: 250,
            backgroundColor: 'white',
            marginLeft: '0',
            '& > .MuiFormHelperText-marginDense': {
                backgroundColor: '#FAFAFA',
                margin: 0
            }
        }
    }));
    const classes = useStyles();
    var listOption = [];
    const variables = {
        skip: 0,
        take: 10,
        where: {
            accountByAccount: {
                code: {
                    _ilike: `%${searchText}%`
                }
            }
        }
    }

    const { loading, data } = useQuery(GET_EMPLOYEE_BY_CODE, { variables: variables });

    if (!_.isEmpty(_.get(data, 'results'))) {
        if (isSchedule) {
            listOption = _.map(_.get(data, 'results'), (item) => {
                return {
                    id: _.get(item, "info.id"),
                    code: _.get(item, "info.code")
                }
            });
        } else {
            listOption = _.map(_.get(data, 'results'), (item) => {
                return {
                    id: _.get(item, "id"),
                    code: _.get(item, "info.code")
                }
            });
        }
    }

    const handleSelect = (e, value) => {
        try {
            if (value) {
                onChange(value.id);
            } else {
                onChange(null);
            }
        } catch (error) {
            console.log("auto complete employee error", error);
        }
    }

    return (
        <Autocomplete
            id={`combo-box-${type}`}
            options={listOption}
            getOptionLabel={(option) => _.toString(option.code)}
            onChange={handleSelect}
            defaultValue={{ code: defaultValue ? defaultValue : value }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    placeholder={placeholder}
                    variant="outlined"
                    size="small"
                    value={value}
                    style={{ backgroundColor: '#ffffff', width: width }}
                    onChange={(e) => setSearchText(e.target.value)} />}
        />
    );
}

export default AutoCompleteEmployee;