import { Paper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Save } from '@material-ui/icons';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import ButtonReturn from '../../components/ButtonReturn';
import { useDistricts, useProvinces, useWards } from '../../graphql/schemas/masterdata/address';
import slugs from '../../resources/slugs';
import InputTextField from '../Product/Secondary/components/InputTextField';
import InputTextFieldSearch from '../Product/Secondary/components/InputTextFieldSearch';




const useStyles = makeStyles((theme) => ({
    root: {
        padding: 30,
    },
    formContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    err: {
        color: "#db2828",
        paddingTop: "5px"
    },
    textLabelForm: {
        wordWrap: 'break-word',
        width: 160,
        fontWeight: 'bold',
        marginRight: 30
    },
    input: {
        width: 250,
        backgroundColor: 'white',
        marginLeft: '0',
        '& > .MuiFormHelperText-marginDense': {
            backgroundColor: '#FAFAFA',
            margin: 0
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none'
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        outline: 'none',
        display: 'flex',
        maxWidth: '760px',
        flexDirection: 'column',
        alignItems: 'center',
    },
    button: {
        color: '#fff',
        borderRadius: 0,
        textTransform: 'uppercase'
    },
    buttonTitle: {
        backgroundColor: '#07807d',
        color: "white",
        borderRadius: 0,
        fontSize: '16px',
        width: '26em',
        fontWeight: 'bold'
    },
    buttonCreateConfirm: {
        backgroundColor: "#07807d",
        color: '#fff',
        borderRadius: 0,
        textTransform: 'uppercase'
    }
}));



const OfficeCreate = (props) => {

    const [office, setOffice] = useState({
        officeName: null,
        province: null,
        district: null,
        ward: null,
        address: null,
        street: null,
        longitude: null,
        latitude: null,
        officeCode: null,
        managerCode: null,
    });
    const [employeeListFile, setEmployeeListFile] = useState(null);
    const [employeeUploadStatus, setEmployeeUploadStatus] = useState(true)
    const [areaFile, setAreaFile] = useState(null);
    const [areaUploadStatus, setAreaUploadStatus] = useState(true)

    const [disabled, setDisabled] = useState(true);

    const classes = useStyles();


    const provinces = useProvinces();


    const districts = useDistricts({ provinceId: _.get(office, 'province.id') });
    const wards = useWards({ districtId: _.get(office, 'district.id') });

    let allowedExtensions = /(\.xls|\.xlsx)$/i;


    useEffect(() => {
        if (areaFile
            && !_.isEmpty(_.get(office, 'officeName'))
            && !_.isEmpty(_.get(office, 'street'))
            && !_.isEmpty(_.get(office, 'province'))
            && !_.isEmpty(_.get(office, 'district'))
            && !_.isEmpty(_.get(office, 'ward'))
            && _.isNumber(_.get(office, 'longitude'))
            && _.isNumber(_.get(office, 'latitude'))
            && !_.isEmpty(_.get(office, 'officeCode'))
            && !_.isEmpty(_.get(office, 'managerCode'))
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [areaFile, office]);


    const handleEmployeeFileUpload = (e) => {

        const file = e.target.files[0];

        if (allowedExtensions.exec(file?.name)) {
            setEmployeeListFile(file);
            setEmployeeUploadStatus(true)
        } else {
            setEmployeeUploadStatus(false);
            setEmployeeListFile(null);
        }
    }

    const handleAreaFileUpload = (e) => {
        const file = e.target.files[0];

        if (allowedExtensions.exec(file?.name)) {
            setAreaFile(file);
            setAreaUploadStatus(true)
        } else {

            setAreaUploadStatus(false)
            setAreaFile(null);
        }
    }

    const handleCreateOffice = (e) => {
        e.preventDefault();
        // const fd = new FormData();
        // fd.append('file', employeeListFile)
        // fd.append('file', areaFile)
    }


    return (
        <Paper className={classes.root}>

            <FormControl >
                <Grid container style={{ marginBottom: '3em' }} justifyContent="center">
                    <Typography gutterBottom variant="h4" component='h2'>TẠO VĂN PHÒNG MỚI</Typography>

                </Grid>
                <Grid container direction="column" spacing={2}>
                    <Grid container item alignItems="center" spacing={2} >
                        <Grid item xs={2} style={{ fontWeight: 'bold' }}>
                            <p>Tên văn phòng <span style={{ color: 'red' }}>*</span></p>
                        </Grid>
                        <Grid item md={6}>
                            <InputTextField
                                className={classes.input}
                                fieldname="officeName"
                                object={office}
                                setObject={setOffice}
                                required
                            />

                        </Grid>
                    </Grid>

                    <Grid container item spacing={2} alignItems="center" >
                        <Grid item container xs={12} spacing={2}>
                            <Grid item md={2} sm={4} style={{ fontWeight: 'bold' }}>
                                <p>Vị trí văn phòng <span style={{ color: 'red' }}>*</span></p>
                            </Grid>
                            <Grid item md={4} sm={10}>
                                <InputTextField
                                    description="Số nhà"
                                    fieldname='address'
                                    object={office}
                                    setObject={setOffice}
                                />
                            </Grid>

                            <Grid item md={4} sm={10} >
                                <InputTextField
                                    description="Tên đường"
                                    fieldname='street'
                                    object={office}
                                    setObject={setOffice}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container md={12} spacing={2}>
                            <Grid item container md={12} spacing={2}>
                                <Grid md={2}><div></div></Grid>
                                <Grid item md={8}  >
                                    <InputTextFieldSearch
                                        {...props
                                        }
                                        description='Chọn thành phố'
                                        fieldname='province'
                                        object={office}
                                        setObject={setOffice}
                                        // disabled={projectsAvailable}
                                        datalist={provinces}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item container md={12} spacing={2}>
                            <Grid item container md={12} spacing={2}>
                                <Grid md={2}><div></div></Grid>
                                <Grid item md={8}  >
                                    <InputTextFieldSearch
                                        {...props}
                                        description='Chọn quận'
                                        fieldname='district'
                                        object={office}
                                        setObject={setOffice}
                                        // disabled={projectsAvailable}
                                        datalist={districts}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container md={12} spacing={2}>
                            <Grid item container md={12} spacing={2}>
                                <Grid md={2}><div></div></Grid>
                                <Grid item md={8}  >
                                    <InputTextFieldSearch
                                        {...props}
                                        description='Chọn phường'
                                        fieldname='ward'
                                        object={office}
                                        setObject={setOffice}
                                        // disabled={projectsAvailable}
                                        datalist={wards}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                    <hr />


                    <Grid item container >
                        <Grid container item alignItems="center" spacing={2} md={6} sm={12}>
                            <Grid item sm={2} md={4} style={{ fontWeight: 'bold' }}>
                                <p>Longitude <span style={{ color: 'red' }}>*</span></p>
                            </Grid>
                            <Grid item >
                                <InputTextField
                                    number
                                    fieldname='longitude'
                                    object={office}
                                    setObject={setOffice}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item alignItems="center" spacing={2} md={6} sm={12}>
                            <Grid item md={3} sm={2} style={{ fontWeight: 'bold' }}>
                                <p>Latitude <span style={{ color: 'red' }}>*</span></p>
                            </Grid>
                            <Grid >
                                <InputTextField
                                    number
                                    fieldname='latitude'
                                    object={office}
                                    setObject={setOffice}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container>
                        <Grid container item alignItems="center" xs={12} >
                            <Grid item xs={2} style={{ fontWeight: 'bold' }}>
                                <p>Mã văn phòng quản lý <span style={{ color: 'red' }}>*</span></p>
                            </Grid>
                            <InputTextField
                                fieldname='officeCode'
                                object={office}
                                setObject={setOffice}

                            />
                        </Grid>

                        <Grid container item alignItems="center" xs={12} spacing={2}>
                            <Grid item xs={2} style={{ fontWeight: 'bold' }}>
                                <p>Mã nhân viên ECOE quản lý cao nhất <span style={{ color: 'red' }}>*</span></p>
                            </Grid>
                            <Grid item >
                                <InputTextField
                                    fieldname='managerCode'
                                    object={office}
                                    setObject={setOffice}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <hr />
                <Grid container>
                    <Grid item className={classes.textLabelForm}>
                        <p>Danh sách nhân viên ECOE </p>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            component="label"
                            color="primary"
                        >
                            Upload File
                            <input
                                onChange={handleEmployeeFileUpload}
                                type="file"
                                hidden
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
                        </Button>
                        {employeeListFile ? <p>{employeeListFile?.name}</p> : null}
                        {employeeUploadStatus ? null :
                            <p style={{ color: 'red'}}>Vui lòng chọn file excel</p>}
                    </Grid >
                </Grid>

                <Grid container>
                    <Grid item className={classes.textLabelForm}>
                        <p>Khu vực quản lý <span style={{ color: 'red' }}>*</span></p>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            component="label"
                            color="primary"
                        >
                            Upload File
                            <input
                                onChange={handleAreaFileUpload}
                                type="file"
                                hidden
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
                        </Button>
                        {(areaFile) ? <p>{areaFile?.name}</p> : null}
                        {areaUploadStatus ? null :
                            <p style={{ color: 'red' }}>Vui lòng chọn file excel</p>}
                    </Grid >
                </Grid>
                <hr />
                <Grid container justifyContent="flex-end" >
                    <Button
                        variant="contained"
                        color='primary'
                        onClick={handleCreateOffice}
                        disabled={disabled}
                        startIcon={<Save />}
                        style={{ marginRight: '15px' }}
                    >
                        Hoàn thành
                    </Button>
                    <ButtonReturn link={slugs.employee} />
                </Grid>
            </FormControl>

        </Paper >

    )
}

export default OfficeCreate;
