import { CircularProgress, Grid, Typography } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AutoCompleteBranch from "../../../components/AutoCompleteOffice";
import TableComponent from "../../../components/TableComponent";
import {
  useDistricts,
  useProvinces,
  useWardsMultiple,
} from "../../../graphql/schemas/masterdata/address";
import slugs from "../../../resources/slugs";
import AddressAutocomplete from "../../Employee/components/AddressAutocomplete";
import { useGetBranchList } from "../hooks";

const headMenu = [
  {
    id: "province",
    numeric: false,
    disablePadding: false,
    label: "Tỉnh/ Thành phố",
  },
  {
    id: "district",
    numeric: false,
    disablePadding: false,
    label: "Quận/ Huyện",
  },
  {
    id: "ward",
    numeric: false,
    disablePadding: false,
    label: "Phường/ Xã",
  },
  {
    id: "code",
    numeric: false,
    disablePadding: false,
    label: "Mã văn phòng",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Tên văn phòng",
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Ngày tạo",
  },
  {
    id: "employee",
    numeric: false,
    disablePadding: false,
    label: "Mã nhân viên quản lý",
  },
];

const BranchesByArea = ({data,loading}) => {
  const [tableData, setTableData] = useState([]);

  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [variables, setVariables] = useState({});
  const [branch, setBranch] = useState(null);
  const [selectedProvince, setSelectedProvince] = useState();
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [selectedWards, setSelectedWards] = useState([]);

  const provinceList = useProvinces();

  const districts = useDistricts({
    provinceId: _.get(selectedProvince, "id"),
  });

  const wards = useWardsMultiple({
    districtIds: _.map(selectedDistricts, (district) => _.get(district, "id")),
  });

  // const { data, loading } = useGetBranchList(variables);

  useEffect(() => {
    let tmp = {
      addressByAddress: {
        province: {
          _eq: _.get(selectedProvince, "id")
            ? _.get(selectedProvince, "id")
            : undefined,
        },
        district: {
          _in: !_.isEmpty(selectedDistricts)
            ? _.map(selectedDistricts, (district) => _.get(district, "id"))
            : undefined,
        },
        ward: {
          _in: !_.isEmpty(selectedWards)
            ? _.map(setSelectedWards, (ward) => _.get(ward, "id"))
            : undefined,
        },
      },
    };
    setVariables(tmp);
  }, [selectedProvince, selectedDistricts, selectedWards]);

  useEffect(() => {
    let filterData = data;

    if(branch) {
      filterData = _.filter(filterData, {'id': branch});
    };

    if(!_.isEmpty(selectedWards)) {
      filterData = _.filter(filterData, (o) => _.includes(_.map(selectedWards, item => _.get(item, 'id')), _.get(o,'address.ward.id')));
    } else if(!_.isEmpty(selectedDistricts)) {
      filterData = _.filter(filterData, (o) => _.includes(_.map(selectedDistricts, item => _.get(item, 'id')), _.get(o,'address.district.id')));
    } else if(!_.isEmpty(selectedProvince)) {
      filterData = _.filter(filterData, (o) => _.get(o, 'address.province.id') === _.get(selectedProvince, 'id'));
    }

    let tmp = _.map(filterData, (item) => ({
        id: _.get(item, "id"),
        province: _.get(item, "address.province.name"),
        district: _.get(item, "address.district.name"),
        ward: _.get(item, "address.ward.name"),
        name: _.get(item, "name"),
        created: moment(_.get(item, "created")).format("DD/MM/YYYY"),
        code: _.get(item, "code"),
        employee: _.get(item, "employee_manager.info.code"),
      })
    );

    setTableData(tmp);
  }, [data, selectedProvince, selectedDistricts, selectedWards, branch]);

  return (
    <Grid
      container
      spacing={4}
      direction="column"
      style={{ marginTop: "16px" }}
    >
      <Grid item container spacing={4}>
        <Grid item container direction="column" xs={2}>
          <Typography>Thành phố</Typography>
          <AddressAutocomplete
            dataList={provinceList}
            objectLabel="name"
            objectValue="id"
            multiple={false}
            setValue={setSelectedProvince}
            placeholder="Thành phố"
          />
        </Grid>
        <Grid item container direction="column" xs={3}>
          <Typography>Quận/ Huyện</Typography>
          <AddressAutocomplete
            dataList={districts ? districts : []}
            objectLabel="name"
            objectValue="id"
            setValue={setSelectedDistricts}
            placeholder="Quận/ huyện"
          />
        </Grid>
        <Grid item container direction="column" xs={3}>
          <Typography>Phường/ xã</Typography>
          <AddressAutocomplete
            dataList={wards ? wards : []}
            objectLabel="name"
            objectValue="id"
            setValue={setSelectedWards}
            placeholder="Phường/ xã"
          />
        </Grid>
        <Grid item container direction="column" xs={3}>
          <Typography>Văn phòng</Typography>
          <AutoCompleteBranch
            width={250}
            onChange={(id) => setBranch(id)}
          />
        </Grid>
      </Grid>

      <Grid item container>
        {loading ? (
          <CircularProgress />
        ) : (
          <TableComponent
            align="center"
            rowPath={slugs.branchList}
            fieldValue='id'
            headMenu={headMenu}
            data={tableData}
            setSkip={(skip) => setSkip(skip)}
            page={page}
            setPage={setPage}
            count={data?.length}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default BranchesByArea;
