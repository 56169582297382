import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import ButtonReturn from '../ButtonReturn';

// checkbox import
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

//icons
import BackupIcon from '@material-ui/icons/Backup';
import Telegram from '@material-ui/icons/Telegram';
import IconButton from '@material-ui/core/IconButton';

import _ from 'lodash';

import XLSX from 'xlsx';

import swal from 'sweetalert';

import { useCreateNotification } from '../../pages/Other/Notification/hooks';
import slugs from '../../resources/slugs';
import { GET_LIST_NOTIFICATION } from '../../graphql/schemas/notification';

const CreateNotification = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            marginTop: '160px',
            marginLeft: '160px',
            width: '100%'
        },
        margin: {
            marginTop: '40px'
        },
        checkboxLabelForm: {
            width: 160,
            fontWeight: 'bold',
            marginRight: 30,
            fontSize: '22px',
            marginBottom: '5px'
        },
        textAreaContent: {
            width: '25em',
            height: '6em',
            backgroundColor: '#ffffff',
            border: '1px solid lightgray',

        },
        input: {
            display: 'none',
        },

        confirmButton: {
            backgroundColor: '#2c8a81',
            color: '#ffffff',
            marginRight: '30px'
        }
    }))
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const [fileName, setFileName] = useState("");
    const [title, setTitle] = useState();
    const [body, setBody] = useState();
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState();

    const { createNotification } = useCreateNotification();

    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }

        // prepare columns list from headers
        const columns = headers.map(c => ({
            name: c,
            selector: c,
        }));

        let result = [];

        for (let i = 0; i <= list.length; i++) {
            result.push(_.get(list[i], "Mã nhân viên"));
            result.push(_.get(list[i], "Mã khách hàng"));
            result.push(_.get(list[i], "MÃ£ nhÃ¢n viÃªn"));
            result.push(_.get(list[i], "MÃ£ khÃ¡ch hÃ ng"));
        }
        setData(result.filter(Boolean));
        setColumns(columns);
    }


    // handle file upload
    const handleFileUpload = e => {
        const file = e.target.files[0];
        if (!file) return;
        setFileName(file.name);
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        reader.readAsBinaryString(file);
    }
    const handleSubmit = () => {
        createNotification({
            variables: {
                typeCode: "ADMIN",
                notification: {
                    type: 0,
                    deep_link: "",
                    url_image: "/logo.png",
                    title,
                    body
                },
                accounts: [],
                employeeCodes: _.filter(data, item => _.includes(item, 'E')),
                customerCodes: _.filter(data, item => _.includes(item, 'C'))
            },
            refetchQueries: [{
                query: GET_LIST_NOTIFICATION,
                variables: {
                    skip: 0,
                    take: 5,
                    type: "ADMIN"
                }
            }]
        }).then((data) => {
            swal({
                title: "Tạo thông báo thành công",
                icon: "success",
            })
        })
    }

    useEffect(() => {
        const checkValidate = Object.values({
            title,
            body,
            data
        }).every((item) => Boolean(item));
        checkValidate ? setSubmitDisabled(false) : setSubmitDisabled(true)
    }, [title, body, data])


    return (
        <Grid container direction="column" spacing={1} alignItems="center" className={classes.margin}>
            {/* Fields */}
            <Grid container direction="row">
                <Grid item style={{ display: 'flex', alignItems: matchesMD ? undefined : 'center', flexDirection: matchesMD ? "column" : "row" }}>
                    <span className={classes.checkboxLabelForm}>Hình thức</span>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox size="medium" required name="gilad" />}
                                label={<span style={{ fontSize: '20px' }}>In-App</span>}
                            />
                            {/* <FormControlLabel
                                control={<Checkbox size="medium" required name="gilad" />}
                                label={<span style={{ fontSize: '20px' }}>Push Notification</span>}
                            /> */}
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
            {/* Fields */}
            <Grid container direction="row">
                <Grid
                    item
                    style={{
                        display: 'flex',
                        alignItems: matchesMD ? undefined : 'center',
                        flexDirection: matchesMD ? "column" : "row",
                        marginTop: matchesMD ? "20px" : '50px'
                    }}>
                    <span className={classes.checkboxLabelForm}>Tiêu đề</span>
                    <TextField
                        required
                        value={title}
                        style={{
                            marginTop: matchesMD ? "20px" : "0px",
                            backgroundColor: '#ffffff',
                            border: '1px solid lightgray'
                        }}
                        onChange={(e) => setTitle(e.target.value)}
                        InputProps={{ disableUnderline: true }}
                    />
                </Grid>
            </Grid>
            {/* Fields */}
            <Grid container direction="row">
                <Grid item style={{ display: 'flex', alignItems: matchesMD ? undefined : 'center', flexDirection: matchesMD ? "column" : "row", marginTop: matchesMD ? "20px" : undefined }}>
                    <span className={classes.checkboxLabelForm}>Nội dung</span>
                    <TextField
                        required
                        multiline
                        className={classes.textAreaContent}
                        style={{ marginTop: matchesMD ? "20px" : "50px" }}
                        rows={10}
                        value={body}
                        InputProps={{ disableUnderline: true }}
                        onChange={(e) => setBody(e.target.value)}
                    />
                </Grid>
            </Grid>
            {/* Fields */}
            <Grid container direction="row">
                <span className={classes.checkboxLabelForm}></span>
                <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
                    <input onChange={handleFileUpload} accept=".csv,.xlsx,.xls" className={classes.input} id="icon-button-file" type="file" />
                    <label htmlFor="icon-button-file" style={{ fontSize: 26, display: 'flex', alignItems: 'center', fontSize: '25px' }}>
                        <IconButton color="primary" aria-label="upload picture" component="span">
                            <BackupIcon style={{ fontSize: 60, color: 'black' }} />
                        </IconButton>
                        {fileName ? fileName : "Cập nhật bằng file csv"}
                    </label>
                </Grid>
            </Grid>
            {/* Fields */}
            <Grid container direction="row" justifyContent="center">
                <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
                    <Button startIcon={<Telegram />} variant="contained" disabled={submitDisabled} className={classes.confirmButton} color="primary" onClick={handleSubmit}>Xác nhận</Button>
                    <ButtonReturn link={slugs.setting} />
                </Grid>
            </Grid>
        </Grid>

    )
}

export default CreateNotification;
