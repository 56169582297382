import React, { Fragment, useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useParams } from "react-router-dom";
import { CircularProgress, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import useTheme from "@material-ui/styles/useTheme";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import StarRatings from "react-star-ratings";
import Box from "@material-ui/core/Box";

// import component
import ModalEditEmployee from "./modal/ModalEditEmployee";

//import checkbox
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Checkbox from "@material-ui/core/Checkbox";

// import time
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";

import _ from "lodash";
import avatar from "./data/avatar.jpg";

// import useQuery
import {
  usePositions,
  useDepartments,
  useRoles,
  useGetEmployeeType,
} from "../../pages/Employee/hooks";

import { checkedPermission, jsUcfirst } from "../../helper/index";

import { user } from "../../constants/user";

import axios from "axios";

import PersonIcon from "@material-ui/icons/Person";

// import time diff
import AutoCompleteBranch from "../AutoCompleteOffice";
import { ArrowBackOutlined, Edit, Save } from "@material-ui/icons";
import slugs from "../../resources/slugs";

import moment from "moment";

import ModalBlocked from "./modal/ModalBlocked";
import ModalUnBlocked from "./modal/ModalUnblocked";
import InputDateAndTimePicker from "../InputDateAndTimePicker";
import { useDebounce } from "use-debounce";
import {
  usePhoneExist,
  useEmailExist,
  useCodeExist,
} from "../../pages/Employee/hooks";
import { validEcoeId, validEmail, validOffice, validPhone } from "./regex";
import AutoCompleteEmployeeScrollLoad from "../AutoCompleteEmployeeScrollLoad";
import ImageViewer from "react-simple-image-viewer";
import clsx from "clsx";
import { CustomCheckbox, CustomRadio, PrimaryButton } from "../UI";
import TableComponentProjects from "../TableComponentProjects";
import { ConfigContext } from "../../context/ConfigContext";
import { StoreContext } from "../../resources/store";
import { UPDATE_EMPLOYEE, USER_ROLE_SUPPER_ADMIN } from "../../constants/defined";

const EmployeeInfo = ({ setShowDetailTeam, employee }) => {
  const { id: accountId } = useParams();
  const { userInfo, accessObjectPermissions } = useContext(StoreContext);
  const id = _.get(employee, "id", null);

  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  const { data: dataPosition, loading: loadingPosition } = usePositions();
  const { data: dataDepartments, loading: loadingDepartment } = useDepartments();

  const { data: dataRoles, loading: loadingRoles } = useRoles();
  const { data: dataEmployeeTypes, loading: loadingEmployeeTypes } = useGetEmployeeType();

  const [rating, setRating] = useState(1);

  const [openModalEditEmployee, setOpenModalEditEmployee] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);

  const history = useHistory();
  const changeRating = (newRating, name) => {
    setRating(newRating);
  };
  const [blocked, setBlocked] = useState(true);
  const [isViewer, setIsViewer] = useState({ view: false, key: 0 });

  const useStyles = makeStyles((theme) => ({
    text: {
      fontSize: "14px",
    },
    blokedBtn: {
      backgroundColor: "#E51515",
      fontSize: "bold",
      borderRadius: 0,
      color: "#ffffff",
      textTransform: "none",
      fontWeight: 700,
      marginRight: 20,
    },
    activedBtn: {
      backgroundColor: "#2C8A81",
      fontSize: "bold",
      borderRadius: 0,
      color: "#ffffff",
      textTransform: "none",
      fontWeight: 700,
      marginRight: 20,
    },
    buttonBack: {
      backgroundColor: "black",
      color: "#ffffff",
      padding: "6px 36px",
      marginTop: "60px",
      marginRight: "30px",
      borderRadius: "0",
      marginBottom: "30px",
    },
    message: {
      border: `2px solid lightgrey`,
      border: "none",
    },
    note: {
      borderBottomLeftRadius: "10px",
      borderTopLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      borderTopRightRadius: "0",
      boxShadow: "1px 5px 7px lightgrey",
      padding: "8px",
      width: matchesMD ? "400px" : undefined,
    },
    avatarFaceId: {
      marginLeft: "5em",
      display: matchesMD ? "none" : undefined,
    },
    boxAvatar: {
      backgroundColor: "#ffffff",
      borderRadius: "50%",
      fontSize: 80,
      padding: 16,
      marginBottom: "20px",
    },
    boxFlexAvt: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: "26px",
      '&:not(.disable)': {
        cursor: "pointer",
      }
    },
    buttonActivity: {
      backgroundColor: blocked ? "red" : "#2C8A81",
      color: "#ffffff",
      borderRadius: 0,
      padding: "5px 36px",
      textTransform: "none",
      marginRight: 26,
    },
    buttonEdit: {
      backgroundColor: openEdit ? "#2c8a81" : "#E27F35",
      color: "#ffffff",
      padding: "6px 36px",
      marginTop: "60px",
      marginRight: "30px",
      borderRadius: "0",
      marginBottom: "30px",
    },
    keyboardDate: {
      marginTop: 0,
      "& .MuiInputBase-adornedEnd": {
        "& > input": {
          padding: "8px 14px",
        },
      },
    },
    avatar: {
      borderRadius: "50%",
      verticalAlign: "middle",
      width: "100px",
      height: "80px",
      cursor: "pointer",
    },
    styleZoom: {
      cursor: 'zoom-in',
    },
    marginDivider: {
      margin: "30px 0",
    },
    footerStyle: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",

      "& button": {
        borderRadius: 0,
        padding: "5px 30px",
      },
      "& button:not(:last-child)": {
        marginRight: "30px",
      },
    },
    wapperLoading: {
      width: '100%',
      height: 'calc(100vh - 200px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }));

  const [status, setStatus] = useState(1);

  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [code, setCode] = useState();
  const [username, setUsername] = useState();
  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState(null);
  const [personalId, setPersonalId] = useState();
  const [gender, setGender] = useState(1);
  const [birthday, setBirthday] = useState();
  const [createdBy, setCreatedBy] = useState(1);
  const [faceid, setFaceid] = useState();
  const [branch, setBranch] = useState();
  const [manager, setManager] = useState();
  const [position, setPosition] = useState();
  const [departments, setDepartments] = useState();
  const [roles, setRoles] = useState();
  const [distributed, setDistributed] = useState("ALL");
  const [distributedList, setDistributedList] = useState([]);

  //   debound for checking exist with server
  const [phoneValue] = useDebounce(phone, 300);
  const [emailValue] = useDebounce(email, 300);
  const [codeEmployeeValue] = useDebounce(code, 300);

  //   error state
  const [availableRegex, setAvailableRegex] = useState({
    emailAvailable: { value: true, message: "" },
    idEcoeIdAvailable: { value: true, message: "" },
    phoneAvailable: { value: true, message: "" },
  });
  const isError = () => {
    return (
      _.get(availableRegex, "emailAvailable.value") &&
      _.get(availableRegex, "idEcoeIdAvailable.value") &&
      _.get(availableRegex, "phoneAvailable.value")
    );
  };

  // check exist api call
  const { data: phoneExist, loading: phoneExistLoading } = usePhoneExist(
    phoneValue !== null && `%${phoneValue.slice(-9).trim()}`
  );

  const { data: emailExist, loading: emailExistLoading } = useEmailExist(emailValue);
  const { data: codeExist, loading: codeExistLoading } = useCodeExist(codeEmployeeValue);

  const [loadingUpload, setLoadingUpload] = useState(false);

  const handleChangeDepartment = (e) => {
    if (e.target.checked) {
      setDepartments([...departments, Number(e.target.value)]);
    } else {
      setDepartments(
        departments.filter((item) => item !== Number(e.target.value))
      );
    }
  };

  const handleOpenUnBlockedModal = () => {
    setOpenUnBlocked(true);
  };

  const handleOpenBlockedModal = () => {
    setOpenBlocked(true);
  };

  const handleChangeRole = (e) => {
    setRoles(e.target.value);
  };

  const handleSelectEmployeeType = (e) => {
    setDistributed(_.get(e, 'target.value'))
  };

  const validate = () => {
    // email
    if (email !== _.get(employee, "info.email") && !_.isEmpty(email)) {
      if (!validEmail.test(email) && _.isEmpty(email) === false) {
        setAvailableRegex((prev) => ({
          ...prev,
          emailAvailable: { value: false, message: "Email không hợp lệ" },
        }));
      } else if (!_.isEmpty(emailExist) && !_.isEmpty(phone)) {
        setAvailableRegex((prev) => ({
          ...prev,
          emailAvailable: { value: false, message: "Email đã tồn tại" },
        }));
      } else {
        setAvailableRegex((prev) => ({
          ...prev,
          emailAvailable: { value: true, message: "" },
        }));
      }
    }
    // code
    if (code !== _.get(employee, "info.code")) {
      if (_.isEmpty(code)) {
        setAvailableRegex((prev) => ({
          ...prev,
          idEcoeIdAvailable: {
            value: false,
            message: "Vui lòng nhập mã nhân viên",
          },
        }));
      } else if (!_.isEmpty(codeExist) && !_.isEmpty(code)) {
        setAvailableRegex((prev) => ({
          ...prev,
          idEcoeIdAvailable: {
            value: false,
            message: "Mã nhân viên đã tồn tại",
          },
        }));
      } else if (!validEcoeId.test(code) && !_.isEmpty(code)) {
        setAvailableRegex((prev) => ({
          ...prev,
          idEcoeIdAvailable: {
            value: false,
            message: "Mã nhân viên không hợp lệ",
          },
        }));
      } else {
        setAvailableRegex((prev) => ({
          ...prev,
          idEcoeIdAvailable: { value: true, message: "" },
        }));
      }
    }

    // Phone
    if (phone === null || phone.trim() === "") {
      setAvailableRegex((prev) => {
        return {
          ...prev,
          phoneAvailable: {
            value: false,
            message: "Số điện thoại không được trống",
          },
        };
      });
    } else {
      if (phone !== _.get(employee, "info.phone")) {
        if (!validPhone.test(phone) && _.isEmpty(phone) === false) {
          setAvailableRegex((prev) => {
            return {
              ...prev,
              phoneAvailable: {
                value: false,
                message: "Số điện thoại không hợp lệ",
              },
            };
          });
        } else if (!_.isEmpty(phoneExist) && !_.isEmpty(phone)) {
          setAvailableRegex((prev) => {
            return {
              ...prev,
              phoneAvailable: {
                value: false,
                message: "Số điện thoại đã tồn tại",
              },
            };
          });
        } else {
          setAvailableRegex((prev) => {
            return {
              ...prev,
              phoneAvailable: {
                value: true,
                message: "",
              },
            };
          });
        }
      }
    }
  };

  const configValue = useContext(ConfigContext);
  // import blocked
  const [openBlocked, setOpenBlocked] = useState(false);
  const [openUnBlocked, setOpenUnBlocked] = useState(false);

  const uploadHandler = async (e) => {
    setLoadingUpload(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("media_type_code", "AVATAR");
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        configValue.API_UPLOAD_FILE,
        formData,
        config
      );
      setLoadingUpload(false);
      setFaceid(data[0]);
    } catch (error) {
      setLoadingUpload(false);
      console.log(error);
    }
  };
  const department = _.map(_.get(employee, "departments"), (item, key) => {
    return item.department.name;
  });

  const listRoles = _.map(_.get(employee, "info.roles"), (item, key) => {
    return item.role.code;
  });

  const imageAvatar = _.get(employee, "info.avatar.url")
    ? configValue.MEDIA_DOMAIN + _.get(employee, "info.avatar.url")
    : avatar;

  // const avatarImage = _.get(singleData, 'faceid') ? process.env.REACT_APP_S3_GATEWAY + _.get(singleData, 'faceid.url') : avatar
  useEffect(() => {
    let checking = phoneExistLoading || emailExistLoading || codeExistLoading;
    if (!checking) {
      validate();
    }
  }, [phoneExist, emailExist, codeExist]);

  useEffect(() => {
    setDistributedList([]);
  }, [distributed]);

  useEffect(() => {
    if (openEdit) {
      setAvailableRegex({
        emailAvailable: { value: true, message: "" },
        idEcoeIdAvailable: { value: true, message: "" },
        phoneAvailable: { value: true, message: "" },
      })
    }
  }, [openEdit]);

  useEffect(() => {
    if (employee) {
      setDistributed(_.get(employee, "info.employeeType.code") || "ALL");
    }
  }, []);

  useEffect(() => {
    if (employee && distributed === "PRIMARY") {
      const tmp = _.map(_.get(employee, "info.products"), (item) => _.get(item, "product.id"));
      setDistributedList(tmp);
    }
  }, [distributed]);

  if (loadingEmployeeTypes || loadingDepartment || loadingPosition || loadingRoles) {
    return (
      <div className={classes.wapperLoading}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid
        container
        direction="column"
        style={{ paddingTop: matchesSM ? "20px" : undefined }}
      >
        <ModalBlocked
          id={accountId}
          setOpenBlocked={setOpenBlocked}
          setBlocked={setBlocked}
          openBlocked={openBlocked}
          data={employee}
        />
        <ModalUnBlocked
          id={accountId}
          setOpenUnBlocked={setOpenUnBlocked}
          setBlocked={setBlocked}
          openUnBlocked={openUnBlocked}
          data={employee}
        />
        <ModalEditEmployee
          openModalEditEmployee={openModalEditEmployee}
          setOpenModalEditEmployee={setOpenModalEditEmployee}
          code={code}
          username={username}
          full_name={fullName}
          email={email}
          personalId={personalId}
          phone={phone}
          gender={gender}
          birthday={birthday}
          createdBy={createdBy}
          roles={roles}
          faceid={faceid}
          branch={branch}
          manager={manager}
          position={position}
          departments={departments}
          distributed={distributed}
          distributedList={distributedList}
          accountid={_.get(employee, "info.id")}
          employee={employee}
          id={id}
          setOpenEdit={setOpenEdit}
        />
        <Grid
          container
          direction={matchesMD ? "column" : "row"}
          alignItems="flex-start"
        >
          <Grid item style={{ paddingTop: "20px" }}>
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ marginBottom: "26px" }}
            >
              <Grid item style={{ width: "160px" }}>
                <Typography
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  Tên Nhân Viên:
                </Typography>
              </Grid>
              <Grid item className={classes.text}>
                {openEdit ? (
                  <TextField
                    style={{ backgroundColor: "#ffffff" }}
                    onChange={(e) => setFullName(e.target.value)}
                    value={fullName}
                    variant="outlined"
                    size="small"
                    required
                  />
                ) : (
                  _.get(employee, "info.full_name", " ")
                )}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ marginBottom: "26px" }}
            >
              <Grid item style={{ width: "160px" }}>
                <Typography
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  Số điện thoại:
                </Typography>
              </Grid>
              <Grid item className={classes.text}>
                {openEdit ? (
                  <TextField
                    style={{ backgroundColor: "#ffffff" }}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    variant="outlined"
                    size="small"
                    onBlur={(e) => {
                      if (e.target.value.trim() === "") {
                        setAvailableRegex({
                          ...availableRegex,
                          phoneAvailable: {
                            value: false,
                            message: "Số điện thoại không được bỏ trống",
                          },
                        });
                      }
                    }}
                    // disabled={true}
                    required
                    helperText={
                      !availableRegex.phoneAvailable.value &&
                      phone !== "" && (
                        <span style={{ color: "red" }}>
                          {_.get(availableRegex, "phoneAvailable.message")}
                        </span>
                      )
                    }
                  />
                ) : (
                  _.get(employee, "info.phone", "")
                )}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ marginBottom: "26px" }}
            >
              <Grid item style={{ width: "160px" }}>
                <Typography
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  CMND/CCCD:
                </Typography>
              </Grid>
              <Grid item className={classes.text}>
                {openEdit ? (
                  <TextField
                    style={{ backgroundColor: "#ffffff" }}
                    value={personalId}
                    variant="outlined"
                    onChange={(e) => setPersonalId(e.target.value)}
                    size="small"
                    required
                  />
                ) : (
                  _.get(employee, "info.personalid", "")
                )}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ marginBottom: "26px" }}
            >
              <Grid item style={{ width: "160px" }}>
                <Typography
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  Sinh nhật:
                </Typography>
              </Grid>
              <Grid item className={classes.text}>
                {openEdit ? (
                  <InputDateAndTimePicker
                    defaultValue={birthday}
                    setDateTime={setBirthday}
                    format="dd/MM/yyyy"
                  />
                ) : _.get(employee, "info.birthday") !== null ? (
                  moment(_.get(employee, "info.birthday")).format("DD-MM-YYYY")
                ) : (
                  "-"
                )}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ marginBottom: "26px" }}
            >
              <Grid item style={{ width: "160px" }}>
                <Typography
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  Email:
                </Typography>
              </Grid>
              <Grid item className={classes.text}>
                {openEdit ? (
                  <TextField
                    style={{ backgroundColor: "#ffffff" }}
                    value={email}
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                    size="small"
                    required
                    helperText={
                      !availableRegex.emailAvailable.value &&
                      email !== "" && (
                        <span style={{ color: "red" }}>
                          {_.get(availableRegex, "emailAvailable.message")}
                        </span>
                      )
                    }
                  />
                ) : (
                  _.get(employee, "info.email", "")
                )}
              </Grid>
            </Grid>
            {/* {openEdit && (
                            <Grid container alignItems="center" direction="row" style={{ marginBottom: '26px' }}>
                                <Grid item style={{ width: '160px' }}>
                                    <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Mã nhóm:</Typography>
                                </Grid>
                                <Grid item className={classes.text}>
                                    <TextField style={{ backgroundColor: '#ffffff' }} value="B0001 - 0001" variant="outlined" size="small" required />
                                </Grid>
                            </Grid>
                        )} */}
            {/* {!openEdit && (
                            <Grid container alignItems="center" direction="row" style={{ marginBottom: '26px' }}>
                                <Grid item style={{ width: '160px' }}>
                                    <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Tên Nhóm:</Typography>
                                </Grid>
                                <Grid item className={classes.text}>
                                    <span>Oh My God</span>
                                </Grid>
                            </Grid>
                        )} */}
          </Grid>
          <Grid
            item
            style={{
              marginTop: "20px",
              marginLeft: matchesMD ? undefined : "100px",
            }}
          >
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ marginBottom: "24px" }}
            >
              <Grid item style={{ width: "160px" }}>
                <Typography
                  style={{ fontWeight: "bold" }}
                  className={classes.text}
                >
                  Mã Nhân Viên:
                </Typography>
              </Grid>
              <Grid item className={classes.text}>
                {openEdit ? (
                  <TextField
                    // disabled={true}
                    style={{ backgroundColor: "#ffffff", width: 250 }}
                    variant="outlined"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    size="small"
                    required
                    helperText={
                      !availableRegex.idEcoeIdAvailable.value && (
                        <span style={{ color: "red" }}>
                          {_.get(availableRegex, "idEcoeIdAvailable.message")}
                        </span>
                      )
                    }
                  />
                ) : (
                  _.get(employee, "info.code", "")
                )}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ marginBottom: "24px" }}
            >
              <Grid item className={classes.text} style={{ width: "160px" }}>
                <Typography
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  Mã Quản Lý:
                </Typography>
              </Grid>
              <Grid item className={classes.text}>
                {openEdit ? (
                  <AutoCompleteEmployeeScrollLoad
                    defaultValue={{ id: _.get(employee, "manager.id"), code: _.get(employee, "manager.info.code") }}
                    setObject={setManager}
                  />
                ) : (
                  _.get(employee, "manager.info.code", "-")
                )}
              </Grid>
            </Grid>

            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ marginBottom: "24px" }}
            >
              <Grid item className={classes.text} style={{ width: "160px" }}>
                <Typography
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  Mã Văn Phòng:
                </Typography>
              </Grid>
              <Grid item className={classes.text}>
                {openEdit ? (
                  <AutoCompleteBranch
                    // defaultValue={_.get(employee, "branch.code")}
                    onChange={(id) => setBranch(id)}
                    value={_.get(employee, "branch.id")}
                  />
                ) : (
                  _.get(employee, "branch.name")
                )}
              </Grid>
            </Grid>
            {/* {openEdit && (
                            <Grid container alignItems="center" direction="row" style={{ marginBottom: '24px' }}>
                                <Grid item className={classes.text} style={{ width: '180px' }}>
                                    <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Reset mật khẩu mặc định:</Typography>
                                </Grid>
                                <Grid item className={classes.text}>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <FormGroup row>
                                            <FormControlLabel
                                                style={{ width: '160px', marginLeft: '20px' }}
                                                control={<Checkbox size="small" required name="gilad" />}
                                            />

                                        </FormGroup>

                                    </FormControl>
                                </Grid>
                            </Grid>
                        )} */}
            {/* {!openEdit && (
                            <Grid container alignItems="center" direction="row">
                                <Grid item className={classes.text} style={{ width: '160px' }}>
                                    <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Mã Nhóm:</Typography>
                                </Grid>
                                <Grid item className={classes.text}>
                                    <span style={{ textDecoration: 'underline', fontWeight: 'bolder', cursor: 'pointer' }}>B0001 - 0001</span>
                                </Grid>
                            </Grid>
                        )} */}
          </Grid>
          <Grid
            item
            className={classes.avatarFaceId}
            style={{ marginTop: "20px" }}
          >
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ marginBottom: "24px" }}
            >
              <input
                accept="image/*"
                id="face-id-customer"
                multiple
                type="file"
                hidden
                onChange={uploadHandler}
              />
              <Grid item>
                {openEdit ? (
                  <label
                    className={classes.boxFlexAvt}
                    htmlFor="face-id-customer"
                  >
                    {_.get(faceid, "url") === null ||
                      _.get(faceid, "url") === undefined ? (
                      <PersonIcon className={classes.boxAvatar} />
                    ) : (
                      <img
                        src={
                          configValue.MEDIA_DOMAIN +
                          _.get(faceid, "url")
                        }
                        alt="Avatar"
                        style={{ cursor: "pointer" }}
                        className={classes.avatar}
                      />
                    )}
                    <Typography style={{ fontWeight: "bold" }}>
                      Face ID
                    </Typography>
                  </label>
                ) : (
                  <div className={clsx(classes.boxFlexAvt, !_.get(employee, "faceid") && 'disable')}>
                    {_.get(employee, "faceid") === null ? (
                      <PersonIcon className={classes.boxAvatar} />
                    ) : (
                      <Fragment>
                        <img
                          src={configValue.MEDIA_DOMAIN + _.get(employee, "faceid.url")}
                          alt="Avatar"
                          className={clsx(classes.avatar, classes.styleZoom)}
                          onClick={(e) => setIsViewer({ view: true, key: 0 })}
                        />
                        {isViewer?.view && (
                          <ImageViewer
                            src={
                              _.includes(_.split(_.get(employee, "faceid.url"), '/'), 'avatar') ?
                                [
                                  configValue.MEDIA_DOMAIN
                                  + _.get(employee, "faceid.url")
                                ]
                                :
                                [
                                  configValue.MEDIA_DOMAIN
                                  + _.replace(_.get(employee, "faceid.url"), "maxresdefault.jpg", "default")
                                  + `.${_.last(_.split(_.get(employee, 'faceid.name'), '.'))}`
                                ]
                            }
                            onClose={() => setIsViewer({ view: false, key: 0 })}
                            backgroundStyle={{
                              backgroundColor: "rgba(0,0,0,0.9)",
                              zIndex: "9999"
                            }}
                            currentIndex={isViewer?.key}
                          />
                        )}
                      </Fragment>
                    )}
                    <Typography style={{ fontWeight: "bold" }}>
                      Face ID
                    </Typography>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction={matchesMD ? "column" : undefined}
        style={{
          paddingTop: matchesSM ? "20px" : undefined,
          marginTop: matchesMD ? 26 : undefined,
        }}
      >
        <Grid
          container
          alignItems="center"
          direction="row"
          style={{ marginBottom: "26px" }}
        >
          <Grid item style={{ width: "160px" }}>
            <Typography className={classes.text} style={{ fontWeight: "bold" }}>
              Chức vụ:
            </Typography>
          </Grid>
          <Grid item className={classes.text}>
            {openEdit ? (
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup row>
                  <RadioGroup
                    aria-label="level"
                    name="level1"
                    value={position}
                    onChange={(e) => {
                      setPosition(Number(e.target.value));
                    }}
                    row
                  >
                    {_.map(dataPosition, (item, key) => (
                      <FormControlLabel
                        key={item.id}
                        style={{ width: "160px" }}
                        control={
                          <CustomRadio size="small" value={item.id} required />
                        }
                        label={
                          <span style={{ fontSize: "16px" }}>{item.name}</span>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormGroup>
              </FormControl>
            ) : (
              _.get(employee, "position.name")
            )}
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          direction="row"
          style={{ marginBottom: "26px" }}
        >
          <Grid item style={{ width: "160px" }}>
            <Typography className={classes.text} style={{ fontWeight: "bold" }}>
              Loại tài khoản:
            </Typography>
          </Grid>
          <Grid item className={classes.text}>
            {openEdit ? (
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup row>
                  <RadioGroup
                    aria-label="roles"
                    name="roles"
                    value={roles}
                    onChange={handleChangeRole}
                    row
                  >
                    {_.map(dataRoles, (item, key) => (
                      <FormControlLabel
                        key={_.get(item, "id")}
                        style={{ width: "160px" }}
                        control={
                          <CustomRadio
                            size="small"
                            value={_.get(item, "code")}
                            required
                            defaultChecked={roles}
                          />
                        }
                        label={
                          <span style={{ fontSize: "16px" }}>
                            {jsUcfirst(_.get(item, "name"))}
                          </span>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormGroup>
              </FormControl>
            ) : listRoles ? (
              listRoles.join(", ")
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          direction="row"
          style={{ marginBottom: "26px" }}
        >
          <Grid item style={{ width: "160px" }}>
            <Typography className={classes.text} style={{ fontWeight: "bold" }}>
              Phòng ban:
            </Typography>
          </Grid>
          <Grid item className={classes.text}>
            {openEdit ? (
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup row>
                  {_.map(dataDepartments, (item, key) => (
                    <FormControlLabel
                      key={_.get(item, "id")}
                      value={_.get(item, "id")}
                      checked={departments.find(
                        (department) => Number(department) === Number(item.id)
                      )}
                      style={{ width: "160px" }}
                      control={
                        <CustomCheckbox
                          size="small"
                          onChange={handleChangeDepartment}
                          value={_.get(item, "id")}
                          required
                        />
                      }
                      label={
                        <span style={{ fontSize: "16px" }}>
                          {_.get(item, "name")}
                        </span>
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            ) : department.length !== 0 ? (
              department.join(", ")
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          direction="row"
          style={{ marginBottom: "26px", alignItems: `${openEdit ? 'center' : 'flex-start'}` }}
        >
          <Grid item style={{ width: "160px" }}>
            <Typography className={classes.text} style={{ fontWeight: "bold" }}>
              Phân bố sản phẩm:
            </Typography>
          </Grid>
          <Grid item className={classes.text}>
            {
              openEdit ? (
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormGroup row>
                    <RadioGroup
                      aria-label="distributed"
                      name="distributed"
                      value={distributed}
                      onChange={handleSelectEmployeeType}
                      row
                    >
                      {_.map([{ id: 100, code: "ALL", name: "Tất cả" }, ...dataEmployeeTypes], (item, key) => (
                        <FormControlLabel
                          key={_.get(item, "id")}
                          style={{ width: "160px" }}
                          control={
                            <CustomRadio
                              size="small"
                              value={_.get(item, "code")}
                              required
                            />
                          }
                          label={
                            <span style={{ fontSize: "16px" }}>
                              {jsUcfirst(_.get(item, "name"))}
                            </span>
                          }
                        />
                      ))}
                    </RadioGroup>
                  </FormGroup>
                </FormControl>
              )
                :
                (
                  <Grid item container direction="column" spacing={1}>
                    <Grid item>
                      {_.get(employee, "info.employeeType") ? _.get(employee, "info.employeeType.name") : "Tất cả sản phẩm"}
                    </Grid>

                    <Grid item>
                      {_.get(employee, "info.employeeType.code") === "PRIMARY" && (
                        _.isEmpty(_.get(employee, "info.products")) ? (
                          <Typography className={classes.text}>(Tất cả dự án)</Typography>
                        )
                          :
                          (
                            _.map(_.get(employee, "info.products"), (item, key) => (
                              <Typography key={key} className={classes.text}>{`- ${_.get(item, 'product.title')}`}</Typography>
                            ))
                          )
                      )}
                    </Grid>
                  </Grid>
                )
            }
          </Grid>
        </Grid>

        {
          openEdit && distributed === "PRIMARY" && (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: matchesMD ? undefined : "center",
                flexDirection: matchesMD ? "column" : "row",
                marginBottom: '30px'
              }}
            >
              <TableComponentProjects
                distributedList={distributedList}
                setDistributedList={setDistributedList}
              />
            </Grid>
          )
        }

        {!openEdit && (
          <Grid
            container
            alignItems="center"
            direction="row"
            style={{ marginBottom: "26px" }}
          >
            <Grid item style={{ width: "160px" }}>
              <Typography
                className={classes.text}
                style={{ fontWeight: "bold" }}
              >
                Trạng thái:
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.text}
              style={{ display: "flex", alignItems: "center" }}
            >
              {_.get(employee, "info.active") === false ? (
                <Button variant="contained" className={classes.blokedBtn}>
                  Đã khóa
                </Button>
              ) : (
                <Button variant="contained" className={classes.activedBtn}>
                  Đang hoạt động
                </Button>
              )}
              {_.get(employee, "info.active") === true ? (
                <Button
                  variant="contained"
                  disabled={
                    _.toUpper(_.get(userInfo, "role")) !== USER_ROLE_SUPPER_ADMIN
                  }
                  className={classes.blokedBtn}
                  onClick={handleOpenBlockedModal}
                >
                  Khoá tài khoản
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled={
                    _.toUpper(_.get(userInfo, "role")) !== USER_ROLE_SUPPER_ADMIN
                  }
                  className={classes.activedBtn}
                  onClick={handleOpenUnBlockedModal}
                >
                  Mở khóa tài khoản
                </Button>
              )}
            </Grid>
            <Grid
              item
              className={classes.text}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography>
                Lần truy cập gần nhất:
                {_.get(employee, "info.last_activity")
                  ? moment(_.get(employee, "info.last_activity")).format(
                    "DD/MM/YYYY hh:mm:ss"
                  )
                  : "--"}
              </Typography>
            </Grid>
          </Grid>
        )}
        {/* <div>Handle after 20/7</div>
                <Grid container alignItems={matchesMD ? undefined : 'center'} direction={matchesSM ? "column" : "row"} style={{ marginBottom: '26px' }}>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item style={{ width: '160px' }}>-
                            <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Tình trạng:</Typography>
                        </Grid>
                        <Grid item className={classes.text}>
                            {openEdit ? (
                                <FormControl variant="outlined" size="small" style={{ backgroundColor: '#ffffff', width: 260 }}>
                                    <Select
                                        labelId="status"
                                        id="status"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                        <MenuItem value={1}>Đang hoạt động</MenuItem>
                                        <MenuItem value={2}>Nghỉ phép</MenuItem>
                                        <MenuItem value={3}>Khoá</MenuItem>
                                    </Select>
                                </FormControl>
                            ) : blocked ? <span style={{ color: 'red' }}>Đã khoá</span> : 'Đang hoạt động'}
                        </Grid>
                    </Grid>
                    {openEdit ? (
                        <Fragment>
                            <Grid item style={{ display: 'flex', alignItems: 'center', marginLeft: matchesMD ? undefined : '60px', marginTop: '8px' }}>
                                <Grid item className={classes.text} style={{ width: '160px' }}>
                                    <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Ngày bắt đầu <span style={{ color: 'red' }}>*</span>:</Typography>
                                </Grid>
                                <Grid item className={classes.text}>
                                    <KeyboardDateTimePicker
                                        className={classes.keyboardDate}
                                        style={{
                                            backgroundColor: '#ffffff',
                                            width: '223px',
                                            border: '1px solid lightgray',
                                            padding: '5px 0',
                                            borderRadius: '5px'
                                        }}
                                        margin="normal"
                                        variant="inline"
                                        id="time-picker"
                                        format="MM/dd/yyyy HH:mm"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                        InputProps={{ disableUnderline: true }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item style={{ display: 'flex', alignItems: 'center', marginLeft: matchesMD ? undefined : '60px' }}>
                                <Grid item className={classes.text} style={{ width: '160px' }}>
                                    <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Ngày kết thúc <span style={{ color: 'red' }}>*</span>:</Typography>
                                </Grid>
                                <Grid item className={classes.text}>
                                    <KeyboardDateTimePicker
                                        className={classes.keyboardDate}
                                        style={{
                                            backgroundColor: '#ffffff',
                                            width: '223px',
                                            border: '1px solid lightgray',
                                            padding: '5px 0',
                                            borderRadius: '5px'
                                        }}
                                        margin="normal"
                                        variant="inline"
                                        id="time-picker"
                                        format="MM/dd/yyyy HH:mm"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                        InputProps={{ disableUnderline: true }}
                                    />
                                </Grid>
                            </Grid>
                        </Fragment>

                    ) : ""}

                </Grid> */}

        {/* <Grid container alignItems="center" direction="row" style={{ marginBottom: '26px' }}>
                    <Grid item style={{ width: '160px' }}>
                        <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Điểm đánh gia của KH:</Typography>
                    </Grid>
                    <Grid item className={classes.text}>
                        <Box component="fieldset" borderColor="transparent" style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ fontSize: '16px', marginRight: '10px' }}>4/5</span>
                            <StarRatings
                                style={classes.rating}
                                rating={4.5}
                                starDimension="26px"
                                changeRating={changeRating}
                                starRatedColor="black"
                                starHoverColor="gray"
                                name="rating"
                                starSpacing={3}
                            />
                        </Box>
                    </Grid>
                </Grid> */}
        <Grid item>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "25px",
              marginBottom: "20px",
              marginTop: matchesMD ? "20px" : undefined,
            }}
          >
            Ghi chú:
          </Typography>
          <Paper className={classes.note}>
            <TextField
              className={classes.message}
              InputProps={{ disableUnderline: true }}
              multiline
              style={{ width: "30em", height: "100%" }}
              id="message"
              rows={5}
              required
            />
          </Paper>
        </Grid>
      </Grid>

      <Divider className={classes.marginDivider} />

      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent={matchesMD ? undefined : "flex-end"}
      >
        <Grid item>
          <PrimaryButton
            labelButton="Quay lại"
            iconButton={<ArrowBackOutlined />}
            style='back'
            actionButton={() => history.push(slugs.employee)}
          />
        </Grid>

        <Grid item>
          {
            openEdit ? (
              <PrimaryButton
                labelButton="Hoàn thành"
                iconButton={<Save />}
                disabled={loadingUpload || !isError()}
                actionButton={() => setOpenModalEditEmployee(true)}
              />
            )
              :
              (
                <PrimaryButton
                  disabled={!checkedPermission(userInfo, accessObjectPermissions, UPDATE_EMPLOYEE)}
                  labelButton="Sửa thông tin"
                  iconButton={<Edit />}
                  actionButton={() => {
                    setOpenEdit(true);
                    setCode(_.get(employee, "info.code"));
                    setFullName(_.get(employee, "info.full_name"));
                    setPhone(_.get(employee, "info.phone"));
                    setPosition(_.get(employee, "position.id"));
                    setEmail(_.get(employee, "info.email"));
                    setManager(_.get(employee, "manager.id"));
                    setDepartments(
                      _.map(_.get(employee, "departments"), (item, key) =>
                        _.get(item, "department.id")
                      )
                    );
                    setBranch(_.get(employee, "branch.id"));
                    setPersonalId(_.get(employee, "info.personalid"));
                    setCreatedBy(1);
                    setBirthday(_.get(employee, "info.birthday"));
                    setFaceid(_.get(employee, "faceid"));
                    setGender(1);
                    setUsername("info.username");
                    setRoles(_.get(employee, "info.roles[0].role.code"));
                  }}
                />
              )
          }
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default EmployeeInfo;
