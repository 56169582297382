import _ from "lodash";
import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, CircularProgress, TextField, Typography } from "@material-ui/core";
import { Waypoint } from 'react-waypoint';
import { GET_EMPLOYEE_BY_CODE } from "../graphql/schemas/employee/employeeQueries";
import { useDebounce } from "use-debounce";

const PER_PAGE = 10;

const useStyles = makeStyles((theme) => ({
    wrapperInput: {
        minWidth: '250px',
        backgroundColor: '#fff',
        '& > div': {
            padding: '10.5px 14px !important'
        },
        '& input': {
            padding: '0 !important'
        },      
    },
}));

const DataEmpty = () => {
    return (
        <Typography>
            Không có dữ liệu thích hợp
        </Typography>
    )
};

const AutoCompleteEmployeeScrollLoad = ({defaultValue, setObject, isSchedule}) => {
    const classes = useStyles();
    const [value, setValue] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [searchTextValue] = useDebounce(searchText, 500);

    let listEmployee = [];
    const variables = {
        skip: 0,
        take: PER_PAGE,
        where: {
            _or: searchTextValue ? [
                {  
                    accountByAccount: {
                        code: {
                            _ilike: `%${searchTextValue}%`
                        }
                    }
                }
            ] : undefined
        }
    };

    const { loading, data, fetchMore } = useQuery(GET_EMPLOYEE_BY_CODE, { variables: variables });

    const loadMore = () => {
        fetchMore({
            variables: {
                skip: (_.size(listEmployee) / PER_PAGE) * PER_PAGE,
                take: PER_PAGE
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!_.get(fetchMoreResult, 'results')) {
                    return;
                };
                return {
                    results: [
                        ...previousResult?.results,
                        ...fetchMoreResult?.results
                    ],
                }
            }
        })
    }

    if (!_.isEmpty(_.get(data, 'results'))) {
        listEmployee = _.map(_.get(data, 'results'), (item) => {
            return {
                id: isSchedule ? _.get(item, "info.id") : _.get(item, "id"),
                code: _.get(item, "info.code")
            }
        });
    };

    const handleSelect = (e, value) => {
        setSearchText(null);
        try {
            if (value) {
                setValue(value);
                _.isFunction(setObject) && setObject(_.get(value,'id'));
            } 
            else {
                setValue(null);
                _.isFunction(setObject) && setObject(null);
            }
        } catch (error) {
            console.log("auto complete employee error", error);
        }
    }

    const handleOnBlur = () => {
        if(!loading && _.size(listEmployee) === 0) {
            _.isFunction(setObject) && setObject(null);
        }
    }

    useEffect(()=>{
        if(defaultValue){
            setValue(defaultValue);
        }
    }, []);

    return (
        <Autocomplete
            disablePortal
            id='auto-complete-employee'
            options={listEmployee}
            loading={loading}
            clearOnBlur={false}
            loadingText={<CircularProgress />}
            noOptionsText={<DataEmpty />}
            getOptionLabel={(option) => _.get(option, "code")}
            renderOption={
                (option, state, props) => {
                    return(
                        <Fragment>
                            {_.get(option, "code")}
                            {
                                (
                                    _.get(option,'code') === _.get(listEmployee[(_.size(listEmployee)-(_.floor(PER_PAGE/2)))],'code'))
                                    && (_.size(listEmployee) % PER_PAGE === 0
                                ) && (
                                    <Waypoint onEnter={loadMore} />
                                )
                            }
                        </Fragment>
                    )
                }
            }
            sx={{ width: '100%' }}
            defaultValue={defaultValue}
            onChange={handleSelect}
            onBlur={handleOnBlur}
            renderInput={(params) => 
                <TextField
                    {...params}
                    className={classes.wrapperInput}
                    variant="outlined"
                    placeholder="Chọn mã nhân viên"
                    onChange={(e) => {
                        let str = _.trim(_.get(e,'target.value'));
                        if (str) {
                            setSearchText(str)
                        }
                    }}
                />
            }
        />
    );
};

export default AutoCompleteEmployeeScrollLoad;

AutoCompleteEmployeeScrollLoad.propTypes = {
    setObject: PropTypes.func,
    defaultValue: PropTypes.any,
    isSchedule: PropTypes.bool,
};

AutoCompleteEmployeeScrollLoad.defaultProps = {
    isSchedule: false,
    defaultValue: null,
};
