import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import _ from "lodash";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export const options = {
  responsive: true,
  elements: {
    line: {
      tension: 0.2,
    },
    point: {
      radius: 2,
    },
  },

  scales: {
    x: {
      min: 0,
      max: 20,
      grid: {
        drawOnChartArea: false,
        display: true,
        borderWidth: 1,
        borderColor: "#000",
        // drawBorder: false,
      },
    },
    y: {
      grace: "5%",
      grid: {
        borderWidth: 1,
        drawBorder: false,
        borderColor: "#000",
      },
      type: "linear",
      display: true,
      position: "left",
    },
    y1: {
      grace: "5%",
      beginAtZero: true,
      type: "linear",
      position: "right",
      grid: {
        drawOnChartArea: false,
        display: false,
        drawBorder: false,
      },
    },
  },
  plugins: {
    legend: {
      position: "bottom",
    },

    tooltip: {
      enabled: true,
      position: "nearest",
    },
    datalabels: {
      display: true,
      font: {
        weight: "400",
      },
      color: "#000",
      anchor: "end",
      align: "top",
    },
    zoom: {
      pan: {
        enabled: true,
        mode: "x",
      },
      limits: {
        y: { min: "original", max: "original" },
      },
    },
  },
  maintainAspectRatio: false,
};

const groupData = (arr, arg) => {
  let rs = [];
  arr?.forEach((item) => {
    const index = rs
      .map((i) => _.get(i, arg))
      .findIndex((index) => index === _.get(item, arg));
    if (index !== -1) {
      rs = rs.map((e, i) =>
        i === index ? { ...e, count: e.count + item.count } : e
      );
    } else {
      rs.push(item);
    }
  });
  return rs;
};

const GrowSecondaryChart = (props) => {
  const { dataChart, chartRef, date } = props;
  const [dataType, setDataType] = useState();
  useEffect(() => {
    const tmpLine = groupData(
      _.get(dataChart, "view_statistics_product_type_aggregate.nodes"),
      "date"
    );

    const indexBegin = tmpLine.findIndex(
      (item) =>
        moment(item.date, "YYYY/MM/DD").toDate() >=
        moment(date.dateBegin, "YYYY/MM/DD").toDate()
    );

    let total = 0;
    if (indexBegin !== -1) {
      total = tmpLine
        .slice(0, indexBegin)
        .reduce((rs, item) => item.count + rs, 0);
    }

    const arr = groupData(_.get(dataChart, "result"), "date");

    const main = arr?.map((item) => {
      total += item.count;
      return { ...item, total };
    });

    setDataType(main);
  }, [dataChart]);

  const useStyles = makeStyles((theme) => ({
    wrapper: {
      backgroundColor: theme.palette.background.paper,
    },
    bar: {
      margin: theme.spacing(3),
    },
  }));
  const classes = useStyles();

  return (
    <Grid container className={clsx(classes.wrapper)}>
      <Grid container className={clsx(classes.wrapper)}>
        <Chart
          ref={chartRef}
          height={500}
          className={classes.bar}
          data={{
            labels: dataType?.map((item) =>
              moment(_.get(item, "date")).format("DD/MM/YYYY")
            ),
            datasets: [
              {
                type: "line",
                label: "Tổng",
                datalabels: {
                  display: false,
                },
                data: dataType?.map((item) => _.get(item, "total")),
                backgroundColor: "#E07E37",
                borderColor: "#E07E37",
                yAxisID: "y1",
              },
              {
                type: "bar",
                label: "Sản phẩm",
                barThickness: dataType?.length <= 15 ? 80 : 60,
                data: dataType?.map((item) => _.get(item, "count")),
                backgroundColor: "#566F90",
                yAxisID: "y",
              },
            ],
          }}
          options={options}
        />
      </Grid>
    </Grid>
  );
};

export default GrowSecondaryChart;
