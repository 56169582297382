import * as _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const COLOR = (type) => _.get(
    {
        default: '#f4c867',
        waiting: '#e07e37',
        verified: '#00837b',
        reject: '#c7431b',
        all: '#54a545',
    }, type, '#ff0000');

const useStyles = makeStyles(theme => ({
    textStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '16px',
        textAlign: 'center',
        justifyContent: 'flex-start',
        padding: '0 10px',
        userSelect: 'none',
        cursor: 'pointer',
        '&.disable': {
            opacity: '0.5',
            cursor: 'unset',
        },
        '&:hover:not(.disable)': {
            opacity: '0.8',
            color: '#00837b'
        },
        '& > .unavailable': {
            opacity: .3,
        }
    },
    buttonStyle: {
        height: '20px',
        width: '20px',
        margin: '10px 0',
        borderRadius: '50%',
        '&.default-type': {
            backgroundColor: COLOR('default')
        },
        '&.waiting-type': {
            backgroundColor: COLOR('waiting')
        },
        '&.verified-type': {
            backgroundColor: COLOR('verified')
        },
        '&.reject-type': {
            backgroundColor: COLOR('reject')
        },
        '&.all-type': {
            backgroundColor: COLOR('all')
        },
    },
    inputStyle: {
        width: '80%',
        backgroundColor: 'white',
        marginLeft: '0',
        '& > div:before': {
            content: 'unset',
        },
        '& > div:after': {
            content: 'unset',
        },
    },
    styleCount: {
        opacity: .7
    }
}));

const LABLE = (status) => _.get({
    'WAITING': 'Chưa xác thực',
    'VERIFIED': 'Đã xác thực',
    'REJECT': 'Đã từ chối',
    'ALL': 'Xem tất cả',
}, status, 'Chưa cập nhật eKYC');

const FILTER = (code) => {
    return (
        _.get({
            'WAITING': ["INITIAL", "IDENTITY_UPLOADED"],
            'VERIFIED': ['VERIFIED'],
            'REJECT': ['REJECT'],
            'ALL': ['ALL'],
        }, code, [])
    )
}

const ButtonStatusList = ({ handleClick, status, selected=false, count = 0 }) => {
    const classes = useStyles();

    const handleClickFilter = () => {
        if (_.isFunction(handleClick)) handleClick(FILTER(status));
    }

    return (
        <div 
            className={clsx(classes.textStyle)}
            onClick={handleClickFilter}
        >
            <div className={clsx(classes.buttonStyle, _.toLower(status)+'-type')} />
            <div className={clsx(!selected && 'unavailable')}>{`${LABLE(status)} (${count})`}</div>
        </div>
    );
}

export default ButtonStatusList;
