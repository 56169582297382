import React, { useState, Fragment } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useHistory, useParams } from 'react-router-dom';
import {
    useMediaQuery,
    Grid, FormControl, Select, MenuItem, Button, Typography, Divider
} from '@material-ui/core';
import { user } from '../../constants/user';
import { useGetProductByEmployee } from '../../pages/Employee/hooks';
import { Waypoint } from 'react-waypoint';
import _ from 'lodash';
import SecondaryCard from '../SecondaryCard';
import ButtonReturn from '../ButtonReturn';
import slugs from '../../resources/slugs';

const PER_PAGE = 10;

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '30px',
    },
    buttonBack: {
        backgroundColor: "black",
        color: '#ffffff',
        padding: '6px 36px',
        marginTop: '60px',
        marginRight: '30px',
        borderRadius: '0',
        marginBottom: '30px'
    },
    nodataStyle: {
        color: "#E27F35",
        fontSize: '16px',
        fontWeight: 'bold'
    },
    marginDivider: {
        width: '100%',
        margin: '30px 0',
    },
    footerStyle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',

        '& button': {
            borderRadius: 0,
            padding: '5px 30px',
        },
        '& button:not(:last-child)': {
            marginRight: '30px',
        },

    },
}));

const ManageProduct = ({ employee }) => {
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

    const { id } = useParams();
    
    const [typeProduct, setTypeProduct] = useState("CONSIGN");
    const [orderType, setOrderType] = useState({ type: 'desc' });
    
    const { data, fetchMore } = useGetProductByEmployee({
        skip: 0,
        take: PER_PAGE,
        order_by: {
            created: 'desc'
        },
        condition: {
            product_requests: {
                incharge: {
                    _eq: id,
                },
                workflow_type: {
                    code: {
                        _in: [typeProduct]
                    }
                }
            },
          
        }
    });
    

    const count = _.get(data, 'product_aggregate.aggregate.count');
    
    return (
        <Grid container direction="column" className={classes.root} >
            <Grid container direction="column" alignItems="flex-start">
                <Grid item style={{ paddingTop: '20px', marginBottom: 60 }}>
                    <FormControl variant="outlined" size="small" style={{ backgroundColor: '#ffffff', width: 260 }}>
                        <Select
                            labelId="manage-product"
                            id="manage-product"
                            value={typeProduct}
                            onChange={(e) => setTypeProduct(e.target.value)}
                        >
                            <MenuItem value="CONSIGN">SP sales Kiểm Duyệt</MenuItem>
                            <MenuItem value="CONSULT">SP sales đang tư vấn</MenuItem>
                            {/* <MenuItem value="">SP Sales giới thiệu</MenuItem> */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container direction="row">
                    {data && count === 0 ? (
                        <Typography className={classes.nodataStyle}>
                            Không có dữ liệu thích hợp
                        </Typography>
                    ) 
                    : 
                    (
                        _.map(_.get(data, 'products'), (item, index) => {
                            return (
                                <Fragment key={index}>
                                    {/* <CardProduct data={item} key={item.id} /> */}
                                    <SecondaryCard  data={item} /> 
                                    {index === _.size(data.products) - 1 && (
                                        <Waypoint
                                            onEnter={() =>
                                                fetchMore({
                                                    variables: {
                                                        skip: (_.size(data.products) / PER_PAGE) * PER_PAGE,
                                                        take: PER_PAGE
                                                    },
                                                    updateQuery: (previousResult, { fetchMoreResult }) => {
                                                        if (!_.get(fetchMoreResult, 'products')) {
                                                            return previousResult
                                                        };
                                                        return {
                                                            products: [
                                                                ...previousResult.products,
                                                                ...fetchMoreResult.products
                                                            ],
                                                        }
                                                    }
                                                })
                                            }
                                        />
                                    )}
                                </Fragment>
                            )
                        })
                    )} 

                </Grid>
                <Divider className={classes.marginDivider} />
                <Grid container direction="row" justifyContent={matchesMD ? "flex-start" : "flex-end"} className={classes.footerStyle} >
                    <ButtonReturn  />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ManageProduct
