import gql from "graphql-tag";

export const GET_BOOKING_STATUS = gql`
  query GetBookingStatus {
    result: booking_status(
      where: {
        code: {
          _in: [
            "BOOKING"
            "DEPOSIT"
            "CANCEL"
            "BOOKING_DONE"
            "CHECKING_DEPOSIT"
            "DONE"
            "INITIAL"
            "SKIP"
            "SELECTED"
          ]
        }
      }
    ) {
      id
      code
      name
      name2
    }
  }
`;

export const GET_PRODUCTS_BY_CART = gql`
  query GetProjectItemsByCart($cartId: Int!, $search: String = "") {
    result: project_item(
      where: {
        code: { _ilike: $search }
        cart: { _eq: $cartId }
        project_item_status: { code: { _eq: "INITIAL" } }
      }
    ) {
      id
      code
      ecoe_code
    }
  }
`;

export const GET_BOOKINGS_BY_CART_STATUS = gql`
  query GetBookingsByCartStatus(
    $skip: Int!
    $take: Int!
    $order_by: [booking_order_by!] = {}
    $where: booking_bool_exp!
  ) {
    result: booking(
      offset: $skip
      limit: $take
      order_by: $order_by
      where: $where
    ) {
      id
      code
      booking_investor_code
      index
      priority
      description
      created
      timeline_countdown
      payments {
        id
        amount
        receipt_date
        description
        receipt_media
        status: payment_status {
          id
          code
          name
        }
        receipt_media_detail: medium {
          name
          url
          media_type {
            id
            code
            name
          }
        }
      }
      product: project_item {
        id
        code
        block_code
        apartment_no
        ecoe_code
        orientation
        floor
        bed_room
        bath_room
        carpet_area
        built_up_area
        commission
        price
        price_include_vat
        project_item_status {
          id
          code
          name
        }
      }
      status: booking_status {
        id
        code
        name
      }
      cart: project_cart {
        name
        status: project_cart_status {
          id
          code
          name
        }
      }
      customer: account {
        id
        code
        full_name
        phone
        personalid
      }
      employee: accountByEmployee {
        id
        code
        full_name
        employees {
          manager: employee {
            account_info: accountByAccount {
              id
              code
              full_name
            }
          }
        }
      }
    }
  }
`;

export const GET_BOOKING_DETAIL = gql`
  query GetBookingDetail($id: uuid!) {
    result: booking_by_pk(id: $id) {
      id
      product: project_item {
        id
        code
        product {
          title
          code
        }
        ecoe_code
      }
      status: booking_status {
        id
        code
        name
        name2
      }
      cart: project_cart {
        id
        name
        status: project_cart_status {
          id
          code
          name
        }
        projectByProject {
          id
          code
          title
        }
      }
      customer: account {
        id
        code
        full_name
        phone
        personalid
      }
      accountByCreatedBy{
        id
        code
        full_name
    }
      employee: accountByEmployee {
        id
        code
        full_name
        employees {
          branch: branchByBranch {
            id
            code
            name
          }
          manager: employee {
            id
            accountByAccount {
              id
              code
              full_name
            }
          }
        }
      }
      created
      code
      booking_investor_code
      index
      priority
      payments {
        amount
        receipt_date
        description
        receipt_media
        status: payment_status {
          id
          code
          name
        }
        media: medium {
          name
          url
          media_type {
            id
            code
            name
          }
        }
      }
      description
    }
  }
`;

export const GET_STATUS_BOOKING = gql`
  query GetProjectItemStatus {
    result: project_item_status {
      id
      code
      name
    }
  }
`;
export const GET_PROJECT_ITEM = gql`
  query GetProjectItemsByCart(
    $skip: Int!
    $take: Int!
    $cartId: Int!
    $search: String = ""
  ) {
    result: project_item(
      offset: $skip
      limit: $take
      where: {
        code: { _ilike: $search }
        cart: { _eq: $cartId }
        project_item_status: { code: { _in: ["INITIAL"] } }
      }
    ) {
      id
      code
      ecoe_code
    }
  }
`;

export const GET_PROJECT_CART_STATUS_HISTORY = gql`
  query GetProjectCartStatusHistory(
    $order_by_project_cart_status_record: [project_cart_status_record_order_by!] = {

    }
    $project: uuid!
  ) {
    project_cart_status_record(
      order_by: $order_by_project_cart_status_record
      where: { projectCartByProjectCart: { project: { _eq: $project } } }
    ) {
      id
      created
      status
      status_priority
      project_cart_status {
        id
        code
        name
      }
      project_cart: projectCartByProjectCart {
        id
        project
        name
        index
      }
      account {
        id
        code
        full_name
      }
    }
  }
`;

export const UPDATE_BOOKING = gql`
  mutation UpdateBooking($data: UpdateBookingInput!) {
    updateBooking(data: $data) {
      id
    }
  }
`;

export const GET_BOOKING_HISTORY = gql`
  query GetBookingHistory($projectId: uuid!) {
    booking_status_record(
      order_by: { created: desc }
      where: {
        bookingByBooking: { project_cart: { project: { _eq: $projectId } } }
      }
    ) {
      created
      booking_status {
        id
        name
      }
      booking_info: bookingByBooking {
        code
        customer_info: account {
          id
          full_name
          code
        }
        employee_info: accountByEmployee {
          id
          full_name
          code
        }
      }
      priority
      product_selected: project_item {
        id
        code
      }
    }
  }
`;

export const CHECK_EXIST_PROJECT_NAME = gql`
query CheckExitsProjectByName($where: product_bool_exp = {}) {
  product(where: $where){
    id
    title
    propertyTypeByPropertyType{
      id
      code
      name
    }
    deleted
  }
}

`

export const GET_BOOKING_HISTORY_POPUP = gql`
query GetBookingHistories($bookingId: uuid) {
  booking_status_record(where: {booking: {_eq: $bookingId}}) {
    id
    created
    account {
      id
      code
      full_name
    }
    booking_status {
      id
      code
      name
    }
    priority
    project_item {
      id
      code
    }
  }
  payment(where: {booking: {_eq: $bookingId}}) {
    id
    amount
    created
    accountByCreatedBy {
      id
      code
      full_name
    }
  }
}
`