import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { GET_LIST_BANNER, DELETE_BANNER, BANNER_TYPES } from '../../../graphql/schemas/banner/index';
import swal from 'sweetalert';

export const useGetListBanner = () => {
    const [getListBanner, { data, loading }] = useLazyQuery(GET_LIST_BANNER, { fetchPolicy: 'cache-and-network' });
    return [
        getListBanner,
        {
            dataBanner: _.get(data, 'results'),
            loadingBanner: loading,
        }
    ]

}

export const useDeleteBanner = () => {
    const [deleteBanner] = useMutation(DELETE_BANNER, {
        onCompleted: () => {
            return swal({
                title: "Xoá banner thành công",
                icon: "success",
            })
        },
        onError: (err) => {
            return swal({
                title: `${err.message}`,
                icon: "error",
            })
        }
    });
    return {
        deleteBanner
    }
}


export const useGetBannerType = () => {
    const { data, loading } = useQuery(BANNER_TYPES);
    return {
        dataBannerType: _.get(data, 'results'),
        loadingBannerType: loading,
    }
}