import { Button, CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import { Description } from "@material-ui/icons";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import swal from "sweetalert";
import InputDateAndTimePicker from "../../../../components/InputDateAndTimePicker";
import TableComponent from "../../../../components/TableComponent";
import { useGetBingoResultByEvent, useGetCountBingoResultByEvent } from "../../hooks";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import GroupSelectResultComponent from "../../components/GroupSelectResultComponent";
import { EXPORT_PROMOTION, PER_PAGE } from "../../../../constants/defined";
import { GET_BINGO_RESULT_BY_EVENT } from "../../../../graphql/schemas/event/eventQueries";
import { useLazyQuery } from "@apollo/client";
import { checkedPermission } from "../../../../helper";
import { StoreContext } from "../../../../resources/store";

const headMenu = [
  {
    id: "order",
    numeric: false,
    disablePadding: false,
    label: "STT",
    center: true,
  },
  // {
  //   id: "event_code",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "ID giải thưởng",
  // },
  {
    id: "prize_type",
    numeric: false,
    disablePadding: false,
    label: "Loại giải thưởng",
  },
  {
    id: "prize_name",
    numeric: false,
    disablePadding: false,
    label: "Hạng giải thưởng",
  },

  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Ngày tạo",
    center: true,
  },
  {
    id: "reward",
    numeric: false,
    disablePadding: false,
    label: "Trị giá",
  },
  {
    id: "code",
    numeric: false,
    disablePadding: false,
    label: "Mã số trúng thưởng",
    center: true,
  },
  {
    id: "bingo_count",
    numeric: false,
    disablePadding: false,
    label: "Số người trúng",
    center: true,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 30px",
  },
  textLabelForm: {
    fontWeight: "bold",
  },
  textTitle: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "40px",
    "&> p": {
      fontSize: 32,
      fontWeight: "bold",
      padding: 12,
      color: "#FFF",
      background: "#26893d",
      borderRadius: "12px",
    },
  },
  wapperLoading: {
    width: '100%',
    height: 'calc(100vh - 113px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const mapData = ({ data = [], page = 1 }) => _.map(data, (item, index) => {
  return {
    id: _.get(item, "id"),
    event_id: _.get(item, "event_info.id"),
    order: ((page - 1) * PER_PAGE) + index + 1,
    // event_code: _.get(item, "event_code"),
    prize_type: _.get(item, "bingo_result_setting.special")
      ? "Giải đặc biệt"
      : "Giải thường",
    prize_name: _.get(item, "bingo_result_setting.name"),
    created: _.get(item, "result_date") ? moment(_.get(item, "result_date")).format("DD/MM/YYYY") : "-",
    reward: _.get(item, "reward"),
    code: _.get(item, "code"),
    bingo_count: _.get(item, "bingo_count"),
  };
});

const ResultTab = ({ id, startDate, endDate, bingoResultSettings }) => {
  const [tableData, setTableData] = useState([]);
  const [fromDate, setFromDate] = useState(startDate);
  const [toDate, setToDate] = useState(endDate);
  const [filterResult, setFilterResult] = useState(0);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);

  const { data: dataCountResult, loading: loadingCountResult } = useGetCountBingoResultByEvent({
    id,
    fromDate: fromDate ? moment(fromDate).format("YYYY/MM/DD") : null,
    toDate: toDate ? moment(toDate).format("YYYY/MM/DD") : null,
    filterResult,
  });
  const { data, loading } = useGetBingoResultByEvent({
    id,
    fromDate: fromDate ? moment(fromDate).format("YYYY/MM/DD") : null,
    toDate: toDate ? moment(toDate).format("YYYY/MM/DD") : null,
    filterResult,
    skip: (page - 1) * PER_PAGE,
    take: PER_PAGE,
  });

  const { userInfo, accessObjectPermissions } = useContext(StoreContext);

  const [reportResultBingo, { loading: loadingReport }] = useLazyQuery(GET_BINGO_RESULT_BY_EVENT);

  const classes = useStyles();

  const handleChangePrizeType = (e) => {
    setFilterResult(_.get(e, 'target.value'));
  };

  const handleExport = async () => {
    let tableData = [];
    const { data: dataReport, error: errorReport } = await reportResultBingo({
      variables: {
        where: {
          event: {
            _eq: parseInt(id),
          },
          result_date: fromDate && toDate ? {
            _gte: fromDate,
            _lte: toDate,
          } : undefined,
          bingo_result_setting: filterResult > 0 ? { id: { _eq: filterResult } } : undefined
        }
      },
    });

    if (errorReport) {
      return swal({
        title: "Có lỗi khi xuất báo cáo",
        text: "VUi lòng thao tác lại!",
        icon: "error"
      })
    };

    if (_.size(_.get(dataReport, 'result')) === 0) {
      return swal({
        title: "Không thể xuất báo cáo.",
        text: "Không tìm thấy dữ liệu. Vui lòng thao tác lại sau!",
        icon: "warning"
      })
    };

    tableData = mapData({ data: _.get(dataReport, 'result') });

    const ExcelJSWorkbook = new ExcelJS.Workbook();
    const worksheet = ExcelJSWorkbook.addWorksheet("ExcelJS sheet");
    const header = [
      "STT",
      // "ID giải thưởng",
      "Loại giải thưởng",
      "Hạng giải thưởng",
      "Ngày tạo",
      "Trị giá",
      "Mã số trúng thưởng",
      "Số người trúng",
    ];

    const dataToExport = [];

    if (tableData !== undefined && tableData.length > 0) {
      let i = 1;
      tableData.forEach((value) => {
        dataToExport.push([
          _.get(value, "order"),
          // _.get(value, "event_code"),
          _.get(value, "prize_type"),
          _.get(value, "prize_name"),
          _.get(value, "created"),
          _.get(value, "reward"),
          _.get(value, "code"),
          _.get(value, "bingo_count"),
        ]);
        i++;
      });
    }

    const customCell = worksheet.getCell("A1");
    customCell.alignment = { vertical: "middle", horizontal: "center" };
    customCell.font = {
      name: "Century",
      family: 4,
      bold: true,
    };

    worksheet.addRow();
    const headerRow = worksheet.addRow(header);
    headerRow.font = {
      name: "Century",
      family: 4,
      bold: true,
    };

    let columnSTT = 1;
    worksheet.columns.forEach((column) => {
      let dataMax = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        dataMax = cell.value ? cell.value.toString().length : 0;
      });
      if (columnSTT === 1) {
        column.width = 5;
        columnSTT++;
      } else if (dataMax <= 2) {
        column.width = 10;
      } else {
        column.width = dataMax <= 15 ? 20 : dataMax;
      }
    });

    if (dataToExport.length > 0) {
      dataToExport.forEach((value) => {
        worksheet.addRow(value);
      });
    }

    customCell.value = "Danh sách trúng thưởng";
    worksheet.mergeCells(`A1:${String.fromCharCode(64 + _.size(header))}1`);

    ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `danh_sach_trung_thuong_game_xo_so.xlsx`
      );
    });
  };

  useEffect(() => {
    if (!loading) {
      let tmp = mapData({ data: data, page: page });
      setTableData(tmp);
    }
  }, [loading, data]);

  useEffect(() => {
    setPage(1);
    setSkip(0);
  }, [fromDate, toDate, filterResult]);

  if (loading || loadingCountResult || loadingReport) return (
    <div className={classes.wapperLoading}>
      <CircularProgress />
    </div>
  )

  return (
    <>
      <Grid container direction="column" spacing={4}>
        <Grid container item spacing={4} alignItems="center">
          <Grid item className={classes.textLabelForm} xs={2} style={{ paddingRight: "30px" }}>
            <Typography>Giải thưởng</Typography>
            <GroupSelectResultComponent
              width="100%"
              listData={bingoResultSettings}
              fieldValue="id"
              fieldTitle="name"
              handleChange={handleChangePrizeType}
              defaultValue={filterResult}
              defaultTitle="Tất cả"
            />
          </Grid>

          <Grid item className={classes.textLabelForm} xs={3}>
            <Typography>Thời gian bắt đầu</Typography>
            <InputDateAndTimePicker
              defaultValue={fromDate || null}
              setDateTime={setFromDate}
              format="dd/MM/yyyy"
            />
          </Grid>

          <Grid item className={classes.textLabelForm} xs={3}>
            <Typography>Thời gian kết thúc</Typography>
            <InputDateAndTimePicker
              defaultValue={toDate || null}
              setDateTime={setToDate}
              format="dd/MM/yyyy"
            />
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              className={classes.buttonExport}
              onClick={() => handleExport()}
              disabled={!checkedPermission(userInfo, accessObjectPermissions, EXPORT_PROMOTION)}
            >
              <Description style={{ fontSize: 14 }} />
              <span style={{ fontSize: 13 }}>Xuất dữ liệu</span>
            </Button>
          </Grid>
        </Grid>
        <Grid container item>
          <TableComponent
            headMenu={headMenu}
            data={tableData}//{_.slice(tableData, skip, page * PER_PAGE)}
            rowPath="bingo-result"
            fieldValue="id"
            // setSkip={() => setSkip()}
            numRowsPerPage={PER_PAGE}
            page={page}
            setPage={setPage}
            count={dataCountResult}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ResultTab;
