import _ from "lodash";
import React, { useState, useEffect, Fragment } from "react";
import {
    Grid,
    IconButton,
    makeStyles,
    Divider,
    CircularProgress,
} from "@material-ui/core";
import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import clsx from "clsx";
import InputDateAndTimePicker from "../../../components/InputDateAndTimePicker";


const useStyles = makeStyles((theme) => ({
    styleOneDivider: {
      margin: "30px 0",
    },
    wrapperTable: {
        '& > div:not(:last-child)': {
            marginBottom: '15px'
        }
    },
    textLabelForm: {
      fontWeight: "bold",
    },
  
    itemRow: {
      marginBottom: "15px",
      paddingBottom: "15px",
    },
}));

const ItemTimer = {
    'start': null,
    'end': null
};

const EventJackSchedules = ({eventJackSchedules=[], setEventJackSchedules, isEdit = false}) => {
    const classes = useStyles();
    const [inputValues, setInputValues] = useState();

    useEffect(() => {
        let tmp = []
        if(isEdit) {
            tmp = _.cloneDeep(eventJackSchedules)
        } else {
            tmp = [ItemTimer]
        }
        setInputValues(tmp);
    }, []);
    
    const handleChangeTime = (index, name, value) => {
        const values = _.cloneDeep(inputValues);
        values[index][name] = value;
        setInputValues(values);
        setEventJackSchedules(values)
    };

    const addEventJackSchedules = () => {
        let tmp = _.cloneDeep(inputValues);
        tmp[_.size(inputValues)] = ItemTimer;
        setInputValues(tmp);
    };

    const handleRemoveSchedule = (index) => {
        const tmp = _.cloneDeep(inputValues);
        if(tmp[index].id) {
            tmp[index]["deleted"] = true;
        } else {
            tmp.splice(index, 1);
        }
        setInputValues(tmp);
        setEventJackSchedules(tmp);
    };

    if(!inputValues) {
        return <CircularProgress />
    }

    return (
        <Fragment>
            <Grid container item alignItems="center">
                <Grid item className={classes.textLabelForm} xs={12}>
                    <p style={{ fontSize: "24px", marginTop: 0 }}>Đợt quay</p>
                </Grid>
                <Grid item className={clsx(classes.textLabelForm, classes.wrapperTable)} xs={8} >
                    <Grid container item alignItems="center" style={{ background: '#ccc' }} spacing={3}>
                        <Grid item className={classes.textLabelForm} md={3}>Đợt</Grid>
                        <Grid item className={classes.textLabelForm} md={4}>Thời gian bắt đầu</Grid>
                        <Grid item className={classes.textLabelForm} md={4}>Thời gian kết thúc</Grid>
                    </Grid>
                    {
                        _.map(inputValues, (item, key) => {
                            
                            if(_.get(item,'deleted')) return null;

                            return (
                                <Grid key={key} container item alignItems="center" spacing={3}>
                                    <Grid item className={classes.textLabelForm} md={3}>{`Đợt ${key + 1}`}</Grid>

                                    <Grid item className={classes.textLabelForm} md={4}>
                                        <InputDateAndTimePicker
                                            setDateTime={(e)=>handleChangeTime(key, 'start', e)}
                                            defaultValue={_.get(item, "start")}
                                        />
                                    </Grid>

                                    <Grid item className={classes.textLabelForm} md={4}>
                                        <InputDateAndTimePicker
                                            setDateTime={(e)=>handleChangeTime(key, 'end', e)}
                                            defaultValue={_.get(item, "end")}
                                        />
                                    </Grid>

                                    <Grid item className={classes.textLabelForm} md={1}>
                                        <IconButton
                                            onClick={() => handleRemoveSchedule(key)}
                                        >
                                            <RemoveCircleOutline style={{ fill: "red" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    
                    <Grid item>
                        <IconButton onClick={() => addEventJackSchedules()}>
                            <AddCircleOutline style={{ fill: "blue" }} />
                            <span style={{ paddingLeft: "10px" }}>Thêm đợt</span>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Divider className={classes.styleOneDivider} />
        </Fragment>
    )
};

export default EventJackSchedules;
