import _ from "lodash";
import { useState } from "react";
import HeaderFilter from "../components/HeaderFilter";
import ListCheck from "../components/ListCheck";
import ListDate from "../components/ListDate";

const CategoryAndLandFilter = (props) => {
  const { districts, setFilterType, setDate, onSubmit, date, setIsSelectAll } =
    props;
  const [showDate, setShowDate] = useState(false);
  const [showFilter, setShowFilter] = useState({
    areaType: false,
  });

  return (
    <HeaderFilter onSubmit={onSubmit} title="Thống kê theo loại và khu vực">
      <ListDate
        open={showDate}
        date={date}
        setOpen={setShowDate}
        setDate={setDate}
      />
      <ListCheck
        headerTitle="Khu vực"
        data={districts}
        open={showFilter.areaType}
        setOpen={setShowFilter}
        field="areaType"
        setFilterType={setFilterType}
        setIsSelectAll={setIsSelectAll}
        checkAllTitle="Chọn tất cả khu vực"
      />
    </HeaderFilter>
  );
};

export default CategoryAndLandFilter;
