import React from "react";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import {
  MenuItem,
  FormControl,
  Select,
  Typography,
  InputLabel,
  ListSubheader,
} from "@material-ui/core";

const GroupSelectResultComponent = ({
  title,
  handleChange,
  value,
  defaultTitle,
  defaultValue,
  listData = [],
  fieldValue,
  fieldTitle,
  width = 120,
  multiple = false,
  widthTitle = undefined,
  direction = "row",
  alignItems = undefined,
  justifyContent = undefined,
  disabledDefault = false,
  margin = undefined,
  required = false,
  renderValue = false,
  onClose = undefined,
}) => {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      width: width,
      backgroundColor: "#fff",
    },
  }));

  const classes = useStyles();

  return (
    <FormControl 
      className={classes.formControl}
      size="small"
      variant="outlined"
    >
      <Select 
        id="bingo-result-settings-select"
        defaultValue={defaultValue}
        onChange={handleChange}
      >
        <MenuItem value={0}>Tất cả</MenuItem>

        <ListSubheader>Giải chung cuộc</ListSubheader>
        {
          _.map(_.orderBy(_.filter(listData, ['special', true]), 'id', 'asc'), (item,key) => <MenuItem key={key} value={_.get(item,fieldValue)}>{_.capitalize(_.get(item,fieldTitle))}</MenuItem>)
        }
        
        <ListSubheader>Giải thường</ListSubheader>
        {
          _.map(_.orderBy(_.filter(listData, ['special', false]), 'id', 'asc'), (item,key) => <MenuItem key={key} value={_.get(item,fieldValue)}>{_.capitalize(_.get(item,fieldTitle))}</MenuItem>)
        }
      </Select>
    </FormControl>
  )

  return (
    <div
      style={{
        display: "flex",
        flexDirection: direction,
        alignItems,
        justifyContent,
        margin: margin,
      }}
    >
      <Typography style={{ width: widthTitle, fontWeight: "bold" }}>
        {title} {required && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <FormControl
        size="small"
        variant="outlined"
        className={classes.formControl}
      >
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={value}
          displayEmpty
          onChange={handleChange}
          multiple={multiple}
          onClose={onClose}
          defaultValue={defaultValue}
          renderValue={
            renderValue
              ? (selected) => {
                  if (_.isEmpty(selected)) {
                    return defaultTitle;
                  }
                  return _.join(selected, ", ");
                }
              : undefined
          }
        >
          <MenuItem disabled={disabledDefault} value={defaultValue}>
            {defaultTitle}
          </MenuItem>
          {_.map(listData, (item, key) => {
            return (
              <MenuItem key={_.get(item, "id")} value={_.get(item, fieldValue)}>
                {_.get(item, fieldTitle)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default GroupSelectResultComponent;
