import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Grid, Typography } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useStyles } from './useStyles';
import clsx from 'clsx';
const IMAGE_TEST = 'https://s3gateway.ecodev.xyz/ecoeadmintesting/product/photos/c75479cd5909a457fd18.jpg';
const IMAGE_TEST2 = 'https://s3gateway.ecodev.xyz/ecoeadmintesting/product/photos/PI%20CITY_POOL%20VIEW_FINAL.jpg';

UploadImage.propTypes = {

};

function UploadImage({ limit }) {
    const items = limit ? limit : 10;
    const classes = useStyles();
    const [uploadProductImages, setUploadProductImages] = useState([{ 'id': 1, 'urlImage': IMAGE_TEST }, { id: 2, urlImage: IMAGE_TEST2 }, { id: 3, urlImage: IMAGE_TEST2 }]);
    return (
        <Grid container className={classes.gridRow} xs={12} >
            <Grid item xs={8} className="grid-image column-4">
                {_.map(uploadProductImages, (item, key) =>
                    <div key={key} className={classes.imgWrapper} >
                        <img alt="image text" src={_.get(item, 'urlImage')} />
                        <DeleteForeverIcon
                            className={classes.deleteImage}
                            onClick={() => {
                                let list = uploadProductImages;
                                _.remove(list, { 'id': _.get(item, 'id') });
                                setUploadProductImages([...list]);
                            }}
                        />
                    </div>
                )}
                {
                    uploadProductImages.length < items && (
                        <div
                            className={clsx(classes.imgWrapper, 'add-image-product')}
                            onClick={() => {
                                let list = uploadProductImages;
                                list = _.concat(list, { id: _.random(1000, 2000), urlImage: IMAGE_TEST });
                                setUploadProductImages(list);
                            }}
                        >
                            <AddCircleIcon fontSize='large' />
                            <div>Hình ảnh chính</div>
                        </div>
                    )
                }
            </Grid>
        </ Grid>
    );
}

export default UploadImage;