import gql from "graphql-tag";
export const INSERT_PRIMARY_PRODUCT = gql`
mutation projectCreate($data: ProjectCreateInput!) {
    result: projectCreate(args: $data) {
      id
    }
  }
`

export const UPDATE_PRIMARY_PRODUCT = gql`
mutation projectUpdate($data: ProjectCreateInput!) {
  result: projectUpdate(args: $data) {
    affected_rows
  }
}
`

export const APPROVE_PRODUCT = gql`
mutation projectUpdate($data: TypeSetApproveStatusInput!) {
  result: typeSetApproveStatus(args: $data) {
    affected_rows
  }
}
`


export const GET_DETAIL_CART = gql`
query GetProjectCartDetail($condition: project_cart_bool_exp!) {
  result: project_cart(where: $condition) {
    id
    name
    project
    assembly_date
    sale_date
    status
    project_items(where: {deleted: {_eq: false}}) {
      id
      index
      code
      block_code
      price
      floor
      status: project_item_status {
        id
        code
        name
      }
      bookings {
        id
        code
        priority
        booking_status {
          id
          code
          name
        }
      }
    }
  }
}
`