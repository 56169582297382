import { gql } from '@apollo/client';

export const GET_LIST_PERMISSION = gql`
  query GetListPermission($where: permission_bool_exp = {}, $whereAddin: position_permission_bool_exp = {department: {}}) {
    result: permission(where: $where) {
      id
      code
      name
      childs: permissions(where: { deleted: { _eq: false } }) {
        id
        code
        name
        position_permissions(where: $whereAddin) {
          positionByPosition {
            id
            name
          }
          departmentByDepartment {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_LIST_ACCOUNT_TYPE = gql`
  query GetListAccountType($where: account_type_bool_exp = {}) {
    account_type(where: $where) {
      id
      code
      name
    }
  }
`;

export const UPDATE_POSITION_PERMISSION = gql`
  mutation UpdatePositionPermission($arg: [updatePositionPermissionInput]!){
    updatePositionPermission(arg:$arg){
        success
        affected_rows
    }
  }
`;

export const GET_PERMISSION_WITH_ACCOUNT = gql`
  subscription GetPermisstionWithAccount($id: bigint = "") {
    result: account(where: {deleted: {_eq: false}, id: {_eq: $id}}) {
      employees {
        permissions: positionByPosition {
          position_permissions(where: {permissionByPermission: {code: {_is_null: false}}}, distinct_on: permission) {
            permission: permissionByPermission {
              id
              code
              name
            }
          }
        }
      }
    }
  }
`;
