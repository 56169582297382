import {
  Paper,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  Radio, RadioGroup, FormControlLabel, TextField,
} from "@material-ui/core";
import {
  AddCircleRounded,
  CloudUploadTwoTone,
  Description,
  HighlightOff,
  HighlightOffOutlined,
  RemoveCircleOutline
} from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useHistory } from "react-router";
import { ReactComponent as UploadFile } from "../../../assets/images/game/uploadFile.svg";
import InputDateAndTimePicker from "../../../components/InputDateAndTimePicker";
import SelectComponent from "../../Customer/components/SelectComponent";
import InputTextField from "../../Product/Secondary/components/InputTextField";
import Upload from "../components/Upload";
import {
  useCreateEvent,
  useGetEventTypes,
  useGetGiftTypes,
  useGetListApplyType,
  useGetSourceType,
  useUpdateRedeemEcoinGameSetting,
} from "../hooks";
import BingoResultSettings from "./BingoResultSettings";
import EventCalendars from "./EventCalendars";
import EventGifts from "./EventGifts";
import EventGiftsVouchers from "./EventGiftsVouchers";
import EventItems from "./EventItems";
import PromotionCreateVoucher from "./PromotionCreateVoucher";
import { useMutation } from "@apollo/client";
import { INSERT_YOUTUBE_MEDIA } from "../../../graphql/schemas/event/eventMutation";
import swal from "sweetalert";
import EventJackSchedules from "./EventJackSchedules";
import { ConfigContext } from "../../../context/ConfigContext";


const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "0 30px",
  },
  wrapperPaper: {
    padding: "30px 15px",
    marginBottom: "30px",
  },
  textLabelForm: {
    fontWeight: "bold",
    minWidth: "150px",
  },
  rowItem: {
    padding: "15px 0 0",
  },
  textTitle: {
    display: "flex",

    "&> p": {
      fontSize: 32,
      fontWeight: "bold",
      padding: "0",
      width: "100%",
      borderRadius: "12px",
    },
  },
  section: {
    background: "#e5e2e2",
    padding: "20px",
    paddingBottom: 0,
    borderRadius: "20px",
  },
  imgPicker: {
    display: "flex !important",
    background: "#F5F5F5",
    width: "100px",
    height: "100px",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    fontWeight: "bold",
    cursor: "pointer",
    border: "1px solid #B9B9B9",
    borderRadius: "6px",
  },
  wapperItem: {
    marginBottom: "30px",
  },
  addBtn: {
    background: "#00837B",
    borderRadius: "6px",
    color: "#fff",
    fontSize: "16px",

    "& > span": {
      gap: "10px",
      display: "flex",
      alignItems: "center",
    },

    "&:hover": {
      background: "rgba(0,131,123,0.8)",
    },
  },
}));

const CustomRadio = withStyles((theme) => ({
  root: {
    color: '#00837B',
    '&$checked': {
      color: '#00837B',
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);

const GameCreate = () => {
  const [eventObj, setEventObj] = useState({
    name: null,
    start: null,
    end: null,
    type: null,
    event_medias: [],
  });

  const configValue = useContext(ConfigContext);

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [typeBingoEvent, setTypeBingoEvent] = useState('TICKETS'); //CALENDARS

  const [eventType, setEventType] = useState("SHAKE");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [typeVideoUpload, setTypeVideoUpload] = useState('LINK');
  const [loadingVideoUpload, setLoadingVideoUpload] = useState(false);
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [errorUploadVideo, setErrorUploadVideo] = useState("");

  const [loadingGuideUpload, setLoadingGuideUpload] = useState(false);
  const [uploadedGuide, setUploadedGuide] = useState([]);
  const [updateGuide, setUpdateGuide] = useState([]);
  const [errorUploadGuide, setErrorUploadGuide] = useState("");

  const [loadingRuleUpload, setLoadingRuleUpload] = useState(false);
  const [uploadedRule, setUploadedRule] = useState([]);
  const [updateRule, setUpdateRule] = useState([]);
  const [errorUploadRule, setErrorUploadRule] = useState("");
  const [redeemEcoinEventSetting, setRedeemEcoinEventSetting] = useState();

  const { data: listEvent, loading: loadingEvents } = useGetEventTypes();
  const { data: listGiftType } = useGetGiftTypes();
  const { data: listApplyType } = useGetListApplyType();

  const history = useHistory();

  const { loading: loadingSourceType, error: errorSourceType, data: dataSourceType } = useGetSourceType();
  const { updateRedeemEcoinGameSetting, data: dataRedeemEcoin, loading: loadingRedeemEcoin, error: errorRedeemEcoin } = useUpdateRedeemEcoinGameSetting()
  const { eventCreate, loading } = useCreateEvent(history, setSubmitDisabled);

  const classes = useStyles();

  const [insertLinkYoutubeMedia, { data: dataInsertMedia, loading: loadingInsertMedia, error: errorInsertMedia }] = useMutation(INSERT_YOUTUBE_MEDIA, {
    onCompleted: (data) => {
      setUploadedVideos(_.concat(uploadedVideos, [_.get(data, 'media')]))
    },
    onError: (err) => {
      return swal({
        title: "Kiểm tra lại link video",
        icon: "error",
      })
    },
  });

  const [eventGifts, setEventGifts] = useState([
    {
      name: "",
      title: "",
      description: "",
      expire_day: null,
      redeem_ecoin: null,
      start: null,
      end: null,
      max_redeem: null,
      image: "",
    },
  ]);

  const [eventGiftsVouchers, setEventGiftsVouchers] = useState({
    vouchers: [
      {
        name: "",
        title: "",
        start: "",
        end: "",
        prefix: "",
        postfix: "",
        max_redeem: 0,
        banner: null,
        expire_day: 0,
        description: "",
        type: 2,
        image: "",
      },
    ],
    eventGifts: [
      {
        name: "",
        title: "",
        description: "",
        image: "",
        start: "",
        end: "",
        expire_day: 0,
        max_redeem: 0,
        redeem_ecoin: 0,
        reward: 0,
        type: 1,
      },
    ],
  });

  const [vouchers, setVouchers] = useState([
    {
      name: "",
      amount: null,
      description: "",
      deadline_day: null,
      prefix_code: null,
      postfix_code: null,
      quantity: null,
      start_time: null,
      end_time: null,
      banner: "",
      apply_type: [..._.map(listApplyType, (item) => _.get(item, "id"))],
      type: null,
      //max_redeem: null,
      //redeem_ecoin: null,
    },
  ]);

  const [eventItems, setEventItems] = useState([
    {
      name: "",
      icon: "",
      spin_rate: null,
      spin_ecoin: null,
      // iconUrl: "",
    },
  ]);

  const [bingoSettings, setBingoSettings] = useState([]);

  const [eventCalendars, setEventCalendars] = useState([
    { start: null, end: null },
  ]);

  const [eventTickets, setEventTickets] = useState([
    { index: null, ticket: null },
  ]);

  const [dataEventJackSchedules, setDataEventJackSchedules] = useState([]);

  const handleChangeEventType = (e) => {
    setEventType(e.target.value);
  };

  useEffect(() => {
    const validateCheck = Object.values({
      loadingGuideUpload: !loadingVideoUpload,
      loadingVideoUpload: !loadingVideoUpload,
      startDate,
      endDate,
      uploadedVideos: uploadedVideos[0]?.url,
      uploadedGuide: uploadedGuide[0]?.url,
    }).every((item) => Boolean(item));
    validateCheck ? setSubmitDisabled(false) : setSubmitDisabled(true);

  }, [
    loadingVideoUpload,
    loadingGuideUpload,
    endDate,
    startDate,
    eventObj,
    uploadedVideos,
    uploadedGuide,
  ]);

  // TODO: Upload video
  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];
    if (file?.size > 5e6) {
      setErrorUploadVideo("Dung lượng file tối đa cho phép là 20MB");
      return false;
    }
    if (uploadedVideos.length === 5) {
      setErrorUploadVideo("Chỉ cho phép tối đa 5 file");
      return false;
    }
    setLoadingVideoUpload(true);
    setErrorUploadVideo("");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("media_type_code", "VIDEO");
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        configValue.API_UPLOAD_FILE,
        formData,
        config
      );
      setUploadedVideos([...uploadedVideos, data[0]]);
      setLoadingVideoUpload(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveField = (index) => {
    const tmp = [...uploadedVideos];
    tmp.splice(index, 1);
    setUploadedVideos(tmp);
  };

  // TODO: Upload Guide game
  const handleUploadGuideGame = async (e) => {
    const file = e.target.files[0];

    if (uploadedGuide.length === 1) {
      setErrorUploadGuide("Chỉ cho phép tối đa 1 file");
      return false;
    }

    setLoadingGuideUpload(true);
    setErrorUploadGuide("");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("media_type_code", "GUIDE_GAME");
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        configValue.API_UPLOAD_FILE,
        formData,
        config
      );
      setUploadedGuide([...uploadedGuide, data[0]]);
      setLoadingGuideUpload(false);
    } catch (error) {
      console.log(error);
    }
  };

  // TODO: Upload Rule game
  const handleUploadRuleGame = async (e) => {
    const file = e.target.files[0];
    if (uploadedRule.length === 1) {
      setErrorUploadRule("Chỉ cho phép tối đa 1 file");
      return false;
    }

    setLoadingRuleUpload(true);
    setErrorUploadRule("");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("media_type_code", "RULE_GAME");
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        configValue.API_UPLOAD_FILE,
        formData,
        config
      );
      setUploadedRule([...uploadedRule, data[0]]);
      setLoadingRuleUpload(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitDisabled(true);
    let arg = eventObj;

    if (eventType === "SHAKE") {
      arg.event_gifts = eventGifts.map((item) => {
        return {
          giftByGift: {
            name: item?.name,
            title: item?.title,
            description: item?.description,
            expire_day: item?.expire_day,
            redeem_ecoin: item?.redeem_ecoin,
            start: moment(item?.start, "YYYY-MM-DD[T]HH-mm").format(
              "YYYY-MM-DD"
            ),
            end: moment(item?.end, "YYYY-MM-DD[T]HH-mm").format("YYYY-MM-DD"),
            max_redeem: item?.max_redeem,
            image: item?.image?.id,
            type: _.get(_.find(listGiftType, ["code", "GIFT"]), "id"),
          },
        };
      });
      const schedules = _.filter(dataEventJackSchedules, (obj) => {
        return _.get(obj, 'start') && _.get(obj, 'end')
      });
      arg.event_jack_schedules = _.map(schedules, (item) => item)
    }

    if (eventType === "JACK") {
      arg.event_gifts = [
        ..._.map(_.filter(eventGiftsVouchers?.eventGifts, (o) => _.get(o, 'name')), item => {
          return {
            giftByGift: {
              name: item?.name,
              title: item?.title,
              description: item?.description,
              image: item?.image?.id,
              start: moment(item?.start, "YYYY-MM-DD[T]HH-mm").format(
                "YYYY-MM-DD"
              ),
              end: moment(item?.end, "YYYY-MM-DD[T]HH-mm").format("YYYY-MM-DD"),
              expire_day: item?.expire_day,
              max_redeem: item?.max_redeem,
              redeem_ecoin: +item?.redeem_ecoin,
              type: _.get(_.find(listGiftType, ["code", "GIFT"]), "id"),
            },
          };
        }),

        ..._.map(_.filter(eventGiftsVouchers?.vouchers, (o) => _.get(o, 'name')), item => {
          return {
            giftByGift: {
              name: item?.name,
              title: item?.title,
              description: item?.description,
              image: item?.image?.id,
              start: moment(item?.start, "YYYY-MM-DD[T]HH-mm").format("YYYY-MM-DD"),
              end: moment(item?.end, "YYYY-MM-DD[T]HH-mm").format("YYYY-MM-DD"),
              expire_day: item?.expire_day,
              max_redeem: item?.max_redeem,
              prefix: item?.prefix,
              postfix: item?.postfix,
              reward: item?.reward,
              type: _.get(_.find(listGiftType, ["code", "VOUCHER"]), "id"),
            },
          };
        }),
      ];

      arg.event_items = eventItems.map((item) => {
        return {
          itemByItem: {
            name: item?.name,
            icon: item?.icon?.id,
            spin_rate: +item?.spin_rate,
            spin_ecoin: item?.spin_ecoin,
            source_type: item?.source_type || null,
            max_day: item?.max_day || 0,
            active: item?.active || true,
            calendars: _.map(item?.calendars, (o) => {
              return {
                start_time: moment(_.get(o, 'start_time')).utc().format(),
                end_time: moment(_.get(o, 'end_time')).utc().format(),
                start: _.get(o, 'start'),
                end: _.get(o, 'end'),
              }
            }),
          },
        };
      });

      const schedules = _.filter(dataEventJackSchedules, (obj) => {
        return _.get(obj, 'start') && _.get(obj, 'end')
      });
      arg.event_jack_schedules = _.map(schedules, (item) => item)
    }

    if (eventType === "BINGO") {
      arg.bingo_result_settings = _.map(bingoSettings, (item) => {
        return {
          name: item?.name,
          last_numbers: item?.last_numbers,
          reward: item?.reward,
          remark: item?.remark,
          special: item?.special,
          code: item?.code,
        };
      });
      if (_.size(eventTickets) > 0 && typeBingoEvent === "TICKETS") {
        arg.event_tickets = _.map(_.filter(eventTickets, (o) => _.get(o, "ticket") > 0), (item) => {
          return {
            ticket: _.get(item, "ticket")
          };
        });
      } else if (_.size(eventCalendars) > 0 && typeBingoEvent === "CALENDARS") {
        arg.event_calendars = _.map(eventCalendars, (item) => {
          return {
            start: moment(_.get(item, "start"))
              .subtract(7, "hours")
              .format("YYYY-MM-DD HH:mm"),
            end: moment(_.get(item, "end"))
              .subtract(7, "hours")
              .format("YYYY-MM-DD HH:mm"),
          };
        });
      } else {
        arg.event_tickets = [];
      }
      arg.event_gifts = [];
      arg.event_items = [];
      arg.vouchers = [];
    }
    if (eventType === "VOUCHER") {
      arg.bingo_result_settings = [];
      arg.event_gifts = [];
      arg.event_items = [];
      arg.vouchers = _.map(vouchers, (item) => {
        return {
          name: _.get(item, "name"),
          amount: +_.get(item, "amount"),
          description: _.get(item, "description"),
          deadline_day: +_.get(item, "deadline_day"),
          prefix: _.get(item, "prefix"),
          postfix: _.get(item, "postfix"),
          start_time: moment(
            _.get(item, "start_time"),
            "YYYY-MM-DD[T]HH-mm"
          ).format("YYYY-MM-DD"),
          end_time: moment(
            _.get(item, "end_time"),
            "YYYY-MM-DD[T]HH-mm"
          ).format("YYYY-MM-DD"),
          banner: _.get(item, "banner.id"),
          quantity: +_.get(item, "quantity"),
          apply_type: _.get(item, "apply_type"),
          type: _.get(_.find(listGiftType, ["code", "VOUCHER"]), "id") || 4,
          background_color: item?.background_color || 'none',
          title_color: item?.title_color || '#000000',
        };
      });
    }

    arg.start = moment(startDate).format("YYYY-MM-DD HH:mm");
    arg.end = moment(endDate).format("YYYY-MM-DD HH:mm");

    listEvent.forEach((element) => {
      if (element?.code === eventType) arg.type = element?.id;
    });

    // const video = uploadedVideos.map((item) => ({ media: item.id }));

    const media = [...updateGuide, ...updateRule, ...uploadedVideos];
    const updatedMediaId = media.map((item) => item.id);
    const uniq = [...new Set(updatedMediaId)];
    const eventMedia = uniq.map((item) => ({ media: item }));

    arg.event_medias = eventMedia;

    if(redeemEcoinEventSetting) {
      updateRedeemEcoinGameSetting({
        variables: {
          ecoin: redeemEcoinEventSetting*(-1),
          where: {
            code: {
              _eq: "BUY_TICKET"
            }
          }
        }  
      }).then((res) => {
        if(_.get(res, "data.result")) {
          eventCreate({
            variables: { arg },
          });
        } else {
          setSubmitDisabled(false)
        }        
      })
    } else {
      eventCreate({
        variables: { arg },
      });
    }    
  };

  return (
    <>
      <Grid container direction="column" spacing={2} className={classes.root}>
        <Grid item container className={classes.textTitle}>
          <Typography> Tạo khuyến mãi mới</Typography>
        </Grid>
        <Paper className={classes.wrapperPaper}>
          <Grid item container spacing={4}>
            <Grid item className={classes.textLabelForm} xs={2}>
              <p>
                Tên chương trình<span style={{ color: "red" }}>*</span>
              </p>
            </Grid>
            <Grid item>
              <InputTextField
                fieldname="name"
                object={eventObj}
                setObject={setEventObj}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={4}>
            <Grid item className={classes.textLabelForm} xs={2}>
              <p>
                Ngày bắt đầu<span style={{ color: "red" }}>*</span>
              </p>
            </Grid>
            <Grid item xs={3}>
              <InputDateAndTimePicker
                defaultValue={startDate}
                setDateTime={setStartDate}
              />
            </Grid>
            <Grid item className={classes.textLabelForm}>
              <p>
                Ngày kết thúc<span style={{ color: "red" }}>*</span>
              </p>
            </Grid>
            <Grid item xs={3}>
              <InputDateAndTimePicker
                defaultValue={endDate}
                setDateTime={setEndDate}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={4}>
            <Grid item className={classes.textLabelForm} xs={2}>
              <p>Loại chương trình khuyến mãi</p>
            </Grid>
            <Grid item xs={2}>
              <SelectComponent
                listData={listEvent}
                loadingList={loadingEvents}
                fieldValue="code"
                fieldTitle="name"
                handleChange={handleChangeEventType}
                width={250}
                defaultTitle={'Chọn chương trình'}
                disabledDefault={true}
                // defaultValue={null}
                value={eventType}
              />
            </Grid>
          </Grid>
        </Paper>

        <Paper className={classes.wrapperPaper}>
          {eventType === "SHAKE" && (
            <EventGifts
              classes={classes}
              eventGifts={eventGifts}
              setEventGifts={setEventGifts}
            >
              <EventJackSchedules
                eventJackSchedules={dataEventJackSchedules}
                setEventJackSchedules={setDataEventJackSchedules}
              />
            </EventGifts>
          )}

          {eventType === "JACK" && (
            <EventGiftsVouchers
              classes={classes}
              eventGiftsVouchers={eventGiftsVouchers}
              setEventGiftsVouchers={setEventGiftsVouchers}
            >
              <EventJackSchedules
                eventJackSchedules={dataEventJackSchedules}
                setEventJackSchedules={setDataEventJackSchedules}
              />
            </EventGiftsVouchers>
          )}

          {eventType === "VOUCHER" && (
            <PromotionCreateVoucher
              classes={classes}
              vouchers={vouchers}
              setVouchers={setVouchers}
            />
          )}

          {/* Game quay */}
          {eventType === "JACK" && (
            <>
              <EventItems
                eventItems={eventItems}
                setEventItems={setEventItems}
                classes={classes}
                dataSourceType={dataSourceType}
              />
            </>
          )}
          {eventType === "BINGO" && (
            <>
              <BingoResultSettings
                bingoSettings={bingoSettings}
                setBingoSettings={setBingoSettings}
                object={eventObj}
                setObject={setEventObj}
                classes={classes}
                setRedeemEcoinEventSetting={setRedeemEcoinEventSetting}
              />
            </>
          )}
        </Paper>

        {eventType === "BINGO" && (
          <Paper className={classes.wrapperPaper}>
            <Grid direction="column" container spacing={3}>
              <Grid item xs={12}>
                <p style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "1.3rem", marginBottom: 0 }} className={classes.textLabelForm}>
                  Tạo đợt giải thưởng
                </p>
              </Grid>

              <Grid item>
                <RadioGroup
                  style={{ flexDirection: 'row' }}
                  aria-label="game-bingo"
                  name="game-bingo-event"
                  value={typeBingoEvent}
                  onChange={(e) => setTypeBingoEvent(e.target.value)}
                >
                  <FormControlLabel value="TICKETS" control={<CustomRadio />} label="Theo số vé" />
                  <FormControlLabel value="CALENDARS" disabled control={<CustomRadio />} label="Theo thời gian" />
                </RadioGroup>
              </Grid>

              <Grid item>
                {
                  typeBingoEvent === "TICKETS" ? (
                    <Grid direction="column" container xs={6} spacing={3}>
                      <Grid item xs={12}>
                        <Grid direction="row" container spacing={1}>
                          <Grid item xs={3} className={classes.textLabelForm}>Tên đợt</Grid>
                          <Grid item xs={8} className={classes.textLabelForm}>Số vé</Grid>
                        </Grid>
                      </Grid>

                      {
                        _.map(eventTickets, (item, key) => {
                          return (
                            <Grid item xs={12} key={key}>
                              <Grid direction="row" container spacing={1} alignItems="center">
                                <Grid item xs={3}>{`Đợt ${key + 1}`}</Grid>
                                <Grid item xs={8}>
                                  <TextField
                                    fullWidth
                                    value={_.get(item, 'ticket')}
                                    size="small" variant="outlined"
                                    onChange={(e) => {
                                      let tmp = [...eventTickets];
                                      _.set(tmp[key], 'ticket', _.toInteger(e.target.value));
                                      _.set(tmp[key], 'index', key);
                                      setEventTickets(tmp);
                                    }}
                                  />
                                </Grid>

                                {_.size(eventTickets) > 1 && (
                                  <Grid item xs={1}>
                                    <IconButton onClick={() => {
                                      const fields = [...eventTickets];
                                      // setDeleteItems(_.concat(deleteItems, [fields[key]?.id]));
                                      fields.splice(key, 1);
                                      setEventTickets(fields);
                                    }}>
                                      <RemoveCircleOutline style={{ fill: "red" }} />
                                    </IconButton>
                                  </Grid>
                                )}

                              </Grid>
                            </Grid>
                          )
                        })
                      }

                      <Grid item xs={12}>
                        <IconButton
                          className={classes.addBtn}
                          onClick={() => {
                            let tmp = [...eventTickets, { index: null, ticket: null }];
                            setEventTickets(tmp);
                          }}
                        >
                          <AddCircleRounded style={{ fill: "white" }} />{" "}
                          <span>Thêm đợt</span>
                        </IconButton>

                      </Grid>
                    </Grid>
                  )
                    :
                    (
                      <EventCalendars
                        eventCalendars={eventCalendars}
                        setEventCalendars={setEventCalendars}
                      />
                    )
                }
              </Grid>
            </Grid>
          </Paper>
        )}

        {/* Media */}

        <Paper className={classes.wrapperPaper}>
          {/* TODO: Rule gamge */}
          <Grid container item spacing={4}>
            <Grid item xs={1} className={classes.textLabelForm}>
              <p>
                Thể lệ: <span style={{ color: "red" }}>*</span>
              </p>
            </Grid>
            <Grid item xs={2}>
              <Upload
                uploadData={uploadedRule}
                setUploadData={setUploadedRule}
                fileChange={updateRule}
                setFileChange={setUpdateRule}
                id="rulegame"
                type="RULE_GAME"
                limit={1}
              />
            </Grid>

            {/* TODO: Guide game */}

            <Grid item xs={1} className={classes.textLabelForm}>
              <p>
                Hướng dẫn: <span style={{ color: "red" }}>*</span>
              </p>
            </Grid>

            <Grid item xs={2}>
              <Upload
                uploadData={uploadedGuide}
                setUploadData={setUploadedGuide}
                fileChange={updateGuide}
                setFileChange={setUpdateGuide}
                id="guidegame"
                type="GUIDE_GAME"
                limit={1}
              />
            </Grid>

            {/* TODO: Video */}
            <Grid item container>
              <Grid item xs={1} className={classes.textLabelForm}>
                <p>Video:<span style={{ color: "red" }}>*</span></p>
                <p style={{ color: 'grey' }}>Tối đa 5 file</p>
              </Grid>

              <Grid item xs={10}>
                <Grid direction="column" container>
                  <Grid item>
                    <RadioGroup
                      style={{ flexDirection: 'row' }}
                      aria-label="game-video"
                      name="game-video-option"
                      value={typeVideoUpload}
                      onChange={(e) => setTypeVideoUpload(e.target.value)}
                    >
                      <FormControlLabel value="LINK" control={<CustomRadio />} label="Link youtube" />
                      <FormControlLabel value="UPLOAD" control={<CustomRadio />} label="Upload video" />
                    </RadioGroup>
                  </Grid>
                  {
                    typeVideoUpload === "LINK" ? (
                      <Grid item>
                        {
                          _.map(uploadedVideos, (item, key) => {

                            return (
                              <Grid key={key} direction="row" container spacing={3}>
                                <Grid item className={classes.textLabelForm} xs={1}>
                                  <p>{`Link ${key + 1}`}</p>
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    id={`add-link-video-${key}`}
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    value={_.get(item, 'provider') ? configValue.MEDIA_DOMAIN + _.get(item, "url") : _.get(item, 'url')}
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <IconButton
                                    onClick={() => handleRemoveField(key)}
                                  >
                                    <RemoveCircleOutline style={{ fill: "red" }} />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            )
                          })
                        }

                        {
                          _.size(uploadedVideos) < 5 && (
                            loadingInsertMedia ? (
                              <CircularProgress />
                            )
                              :
                              (
                                <Grid direction="row" container spacing={3}>
                                  <Grid item className={classes.textLabelForm} xs={1}>
                                    <p>{`Link ${_.size(uploadedVideos) + 1}`}</p>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      id="add-link-video"
                                      placeholder="link youtube"
                                      variant="outlined"
                                      fullWidth
                                      value={null}
                                      onBlur={
                                        (e) => {
                                          if (_.get(e, 'target.value')) {
                                            insertLinkYoutubeMedia({
                                              variables: {
                                                url: _.get(e, 'target.value')
                                              }
                                            })
                                          }
                                        }
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              )
                          )
                        }
                      </Grid>
                    )
                      :
                      (
                        <Grid item>
                          <Grid container direction="row" spacing={3}>
                            {_.map(uploadedVideos, (item, key) => (
                              <Grid item key={key} style={{ position: 'relative' }}>
                                <ReactPlayer
                                  url={configValue.MEDIA_DOMAIN + _.get(item, "url")}
                                  width="360"
                                  controls={true}
                                />

                                <IconButton onClick={() => handleRemoveField(key)} style={{ position: 'absolute', top: 10, right: 10 }}>
                                  <RemoveCircleOutline style={{ fill: "red" }} />
                                </IconButton>
                              </Grid>
                            ))}

                            {/* TODO add */}
                            {
                              _.size(uploadedVideos) < 5 && (
                                <Grid item xs={4}>
                                  <div>
                                    <input
                                      hidden
                                      accept="video/mp4,video/x-m4v,video/*"
                                      id="icon-video-file"
                                      type="file"
                                      onChange={handleVideoUpload}
                                      disabled={loadingVideoUpload}
                                    />
                                    <label htmlFor="icon-video-file">
                                      <IconButton aria-label="icon-video-file" component="span">
                                        {loadingVideoUpload ? (
                                          <CircularProgress />
                                        ) : (
                                          <CloudUploadTwoTone
                                            style={{ height: "auto", width: "100px" }}
                                          />
                                        )}
                                      </IconButton>
                                      <p style={{ color: "red", fontStyle: "italic" }}>
                                        {errorUploadVideo}
                                      </p>
                                    </label>
                                  </div>
                                </Grid>
                              )
                            }
                          </Grid>
                        </Grid>
                      )
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Grid item container justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={submitDisabled}
          >
            Xác nhận
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default GameCreate;
