import { gql } from "@apollo/client";
import { CORE_SCHEMA_FIELDS } from "../product/fragments";

export const GET_BRANCH_LIST = gql`
  query GetBranches($where: branch_bool_exp! = {}) {
    branch(order_by: { code: asc }, where: $where) {
      id
      code
      phone
      name
      latitude
      longitude
      employee {
        id
        account_info: accountByAccount {
          id
          full_name
          code
        }
      }
      active
      addressByAddress {
        ...addressData
      }
      created
    }
  }

  fragment addressData on address {
    short
    street: streetByStreet {
      id
      name
    }
    ward: wardByWard {
      id
      name
    }
    district: districtByDistrict {
      id
      name
    }
    province: provinceByProvince {
      id
      name
    }
  }
`;
