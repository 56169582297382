import _ from 'lodash';
import React, { Component, createContext, useState, useEffect, useCallback, useMemo } from 'react';
import { useGetListPermission, useGetPermissionWithAccount } from '../pages/Permission/hooks';
import jwt_decode from "jwt-decode";
import { CircularProgress } from '@material-ui/core';

export const StoreContext = createContext();

export const StoreContextProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('access_token'));
    const [userInfo, setUserInfo] = useState()

    const { data: dataPermissions, loading: loadingPermissions } = useGetListPermission({ isskip: !token });
    const [permissionList, setPermissionList] = useState();
    const { data: accessPermissions, loading: loadingAccessPermission } = useGetPermissionWithAccount(_.get(userInfo, 'id'));

    useEffect(() => {
        if (token) {
            const decodedToken = jwt_decode(token);
            const hasuraUser = _.get(decodedToken, 'https://hasura.io/jwt/claims');
            setUserInfo({
                id: Number(_.get(hasuraUser, "x-hasura-account-id")),
                role: _.get(hasuraUser, "x-hasura-business-roles"),
            })
        } else {
            setUserInfo(null);
        }
    }, [token]);

    const tempObjectAccessPermission = {};
    _.forEach(accessPermissions, item => _.set(tempObjectAccessPermission, item.code, true));

    useEffect(() => {
        if (dataPermissions) {
            setPermissionList(dataPermissions);
        }
    }, [loadingPermissions, dataPermissions]);

    if (loadingAccessPermission || loadingPermissions) {
        return (
            <div style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <CircularProgress />
            </div>
        )
    };

    return (
        <StoreContext.Provider
            value={{
                token, setToken,
                accessPermissions,
                accessObjectPermissions: tempObjectAccessPermission,
                userInfo, setUserInfo,
                permissionList, setPermissionList,
            }}
        >
            {children}
        </StoreContext.Provider>
    )
};

// export class StoreContextProvider extends Component {
//     state = {
//         test: 1,
//         get: key => {
//             return this.state[key]
//         },
//         set: (key, value) => {
//             const state = this.state;
//             state[key] = value;
//             this.setState(state)
//         },
//         remove: key => {
//             const state = this.state;
//             delete state[key];
//             this.setState(state)
//         },
//         getAll: () => {
//             let result = {};
//             let keys = Object.keys(this.state);
//             _.forEach(keys, (key) => {
//                 let item = _.get(this.state, key);
//                 if (!_.isFunction(item)) {
//                     _.set(result, key, item);
//                 }
//             });
//             return result;
//         }
//     }

//     render() {
//         return (
//             <StoreContext.Provider value={this.state}>
//                 {this.props.children}
//             </StoreContext.Provider>
//         )
//     }

// };

// export const withStore = WrappedComponent => {
//     return class extends Component {
//         render() {

//             return (
//                 <StoreContext.Consumer>
//                     {context => {
//                         return <WrappedComponent store={context} {...this.props} />
//                     }}
//                 </StoreContext.Consumer>
//             )
//         }
//     }
// };

