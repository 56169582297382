import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { makeStyles, createStyles } from "@material-ui/core";
import { SketchPicker } from 'react-color';

const ColorPicker = ({inputValues, setInputValues, index, isBackgroundColor, isVouchers, isEventVouchers, onBlurInput}) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState(_.get(inputValues, `${isVouchers ? 'vouchers' : 'eventGifts'}[${index}].${isBackgroundColor ? 'background_color' : 'title_color'}`) || 'unset');

    const useStyles = makeStyles(() => ({
        color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${color}`,
        },
        swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
        },
        popover: {
            position: 'absolute',
            zIndex: '2',
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
    }));

    const classes = useStyles();

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
        _.isFunction(onBlurInput) && onBlurInput();
    };

    const handleChange = (color) => {
        let tmp = [];
        if (isEventVouchers) {
            tmp = [...inputValues];
        }else if (isVouchers) {
            tmp = [...inputValues?.vouchers]
        } else {
            tmp = [...inputValues?.eventGifts]
        }

        tmp[index]["isUpdate"] = true;

        if (isBackgroundColor) {
            tmp[index]["background_color"] = _.toUpper(_.get(color,'hex')) === '#FFFFFF' ? 'unset' : _.get(color,'hex');
        } else {
            tmp[index]["title_color"] = _.toUpper(_.get(color,'hex')) === '#FFFFFF' ? 'unset' : _.get(color,'hex');
        }

        setColor(_.get(color,'hex') === '#FFFFFF' ? 'unset' : _.get(color,'hex'));
        
        if (isEventVouchers) {
            _.isFunction(setInputValues) && setInputValues(tmp);
        } else if (isVouchers) {
            _.isFunction(setInputValues) && setInputValues({ ...inputValues, vouchers: tmp });
        } else {
            _.isFunction(setInputValues) && setInputValues({ ...inputValues, eventGifts: tmp });
        }
    };

    return (
        <div>
            <div className={classes.swatch} onClick={handleClick}>
                <div className={classes.color} />
            </div>
            {displayColorPicker ? <div className={classes.popover}>
                <div className={classes.cover} onClick={handleClose} />
                <SketchPicker color={color} onChange={handleChange} />
            </div> : null}
        </div>
    );
};

export default ColorPicker;
