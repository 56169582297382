import * as _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { FormControl } from '@material-ui/core';
import swal from 'sweetalert';
import { useExistProjectName } from '../hooks';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%',
        padding: '10.5px 14px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        '&.disabled': {
            background: '#f7f7f7',
        }
    },
    inputStyle: {
        width: '100%',
        fontSize: 'unset',
        border: '0',
        background: 'none',
        '&:focus-visible': {
            outline: '0',
        },
        '&:disabled': {

        },
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    defaultPadding: {
        '&>div': {
            padding: '10.5px 14px',
        },
    },
    dropdownList: {
        '> *': {
            background: 'green',
        }
    }
}));



const InputTextFieldSearch = (props) => {

    const { fieldname, description, object, setObject, setProjectsAvailable, disabled, datalist, defaultValue, inputType } = props;
    const [inputValue, setInputValue] = useState(!_.isEmpty(defaultValue) ? defaultValue : _.get(object, fieldname));
    const [searchName, setSearchName] = useState();

    const { data } = useExistProjectName(searchName);

    useEffect(() => {
        if (data && data.length > 0 && inputType === 'project') {
            swal({
                title: "Dự án đã tồn tại",
                icon: "error",
            }).then(() => {
                setInputValue('');
                let tempObject = { ...object };
                _.set(tempObject, 'name', null);
                setObject(tempObject);
            })
        
        }

    }, [searchName, data])

    const classes = useStyles();
    const onBlurSetValue = (event) => {
        const value = _.trim(_.get(event, 'target.value'));

        if(inputType === 'project') {
            setSearchName(value);
        }
        

        if (_.isEmpty(value) && fieldname === 'project' && !_.isEmpty(_.get(tempObject, 'project.id'))) {

            delete tempObject.project.id;
        }

        if(value===inputValue) return;

        if ((_.isEmpty(value) && inputValue === undefined) || _.get(object, fieldname) === value) return;

        const selectedProject = fieldname === 'project' && _.find(datalist, { name: value });
        let tempObject = { ...object };

        if (fieldname === 'project') {
            _.set(tempObject, 'project.name', value);
        }

        //Long added for primary product
        if (fieldname === 'name') {
            _.set(tempObject, 'name', value);
        }


        if (selectedProject) {
            _.set(tempObject, 'project.id', _.get(selectedProject, 'id'));
            _.set(tempObject, 'province', { 'id': _.get(selectedProject, 'address.province.id', null), 'name': _.get(selectedProject, 'address.province.name', null) });
            _.set(tempObject, 'district', { 'id': _.get(selectedProject, 'address.district.id', null), 'name': _.get(selectedProject, 'address.district.name', null) });
            _.set(tempObject, 'ward', { 'id': _.get(selectedProject, 'address.ward.id', null), 'name': _.get(selectedProject, 'address.ward.name', null) });
            _.set(tempObject, 'street', { 'id': _.get(selectedProject, 'address.street.id', null), 'name': _.get(selectedProject, 'address.street.name', null) });
            _.set(tempObject, 'address_number', _.get(selectedProject, 'address.short', null));
            if (_.isFunction(setProjectsAvailable)) setProjectsAvailable(true);

        }
        // else {
        //     //_.set(tempObject, 'project.id', null);
        //     delete tempObject.project.id;

        // }

        if (_.includes(['province', 'district', 'ward', 'street'], fieldname)) {
            _.set(tempObject, fieldname, { 'id': _.get(_.find(datalist, { name: value }), 'id'), 'name': value });
            if (fieldname === 'province') {
                if (_.get(tempObject, 'district')) delete tempObject.district;
                if (_.get(tempObject, 'ward')) delete tempObject.ward;
                if (_.get(tempObject, 'street')) delete tempObject.street;
            }
            if (fieldname === 'district') {
                if (_.get(tempObject, 'ward')) delete tempObject.ward;
                if (_.get(tempObject, 'street')) delete tempObject.street;
            }
            if (fieldname === 'ward') {
                if (_.get(tempObject, 'street')) delete tempObject.street;
            }
        }

        if (_.isEmpty(value) && !_.isEmpty(defaultValue)) {
            _.set(tempObject, fieldname, null);
        }


        setObject(tempObject);

        return;
    };

    const onKeyPress = (event) => {
        if (event.key === 'Enter' || event.charCode === 13) {
            onBlurSetValue(event);
        }
        return;
    }

    const onchangeAction = (e) => {
        const value = _.get(e, 'target.value');
        setInputValue(value);
        onBlurSetValue(e);
    }

    useEffect(() => {
        if (_.includes(['province', 'district', 'ward', 'street'], fieldname)) {
            setInputValue(_.get(object, `${fieldname}.name`) || '');
        }
        //set condition for edit primary Product
        if (fieldname === 'name') {
            setInputValue(object.name)
        }
    }, [object])

    let change_id = _.random(5000);

    return (
        <Fragment>

            <div style={{ display: 'flex' }}>
                <FormControl variant="outlined" className={clsx(classes.formControl, { 'disabled': disabled })}>
                    <input
                        disabled={disabled}
                        className={clsx(classes.inputStyle)}
                        placeholder={_.isEmpty(inputValue) && description}
                        value={inputValue}
                        list={`list-${fieldname}-${change_id}`}
                        id={`input-textfield-${fieldname}`}
                        onBlur={(e) => onBlurSetValue(e)}
                        onKeyPress={onKeyPress}
                        onChange={onchangeAction}
                        autocomplete="off"
                        defaultValue={defaultValue}
                    />
                    <datalist id={`list-${fieldname}-${change_id}`} className={clsx(classes.dropdownList)}>
                        {datalist && _.map(datalist, (item, key) => {
                            return <option key={key} value={item?.name} />
                        })}
                    </datalist>

                </FormControl>
            </div >

        </Fragment >
    );
};

InputTextFieldSearch.propTypes = {

};

export default InputTextFieldSearch;
