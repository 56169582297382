import * as _ from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Typography, TextField } from '@material-ui/core';
import { readMillions } from '../helper/currency';

const useStyles = makeStyles(theme => ({
    
    labelStyle: {
        fontWeight: 'bold',
        paddingRight: '30px',
        position: 'relative',

        '&.required:after': {
            content: '"*"',
            fontSize: '18px',
            lineHeight: 1,
            position: 'absolute',
            color: 'red',
            paddingLeft: '5px',
        },
        '&.unit > span': {
            position: 'absolute',
            right: 0,
            bottom: 0,
            fontWeight: 'normal',
            opacity: '.5',
        },
        '&.description': {
            position: 'absolute',
            paddingTop: '10px',
            fontWeight: 'normal',
            
        },
    },

    inputStyle: {
        '&:not(.fullwidth)': {
            width: 250,
        },
        '&.fullwidth': {
            width: '100%',
        },
        backgroundColor: 'white',
        marginLeft: '0',
        '&.disabled': {
            background: '#f7f7f7',
        }
    },

}));


const InputTextFieldCurrency = ({ isCurrency, label, fieldname,itemKey, number, description, required, arrObject, setArrObject, fullwidth, maxLength, disabled }) => {

    const classes = useStyles();
    const [inputValue, setInputValue] = useState(_.get(arrObject[itemKey], fieldname));
    const [moneyStr, setMoneyStr] = useState("");
    const [money, setMoney] = useState(0);

    const onBlurSetValue = (event) => {
        const value = _.trim(inputValue);
        if (_.get(arrObject[itemKey], fieldname) === value) return;
        if (_.isEmpty(value) && inputValue === undefined) return;
        let tempArrObject = arrObject;

        if (!_.isEmpty(value)) {
            _.set(tempArrObject[itemKey], fieldname, number ? value * 1 : value);
        }
        if (_.isFunction(setArrObject)) setArrObject([...tempArrObject]);
    };

    const onchangeAction = (e) => {
        let value = _.get(e, 'target.value');
        setMoney(e.target.value);
        if (number) {
            if (value < 0) {
                return setInputValue(null);
            }
        }
        setInputValue(_.get(e, 'target.value'));
    };

    useEffect(() => {
        setInputValue(_.get(arrObject[itemKey], fieldname) || '');
    }, [arrObject]);
    useEffect(() => {
        let result = readMillions(money, false);
        setMoneyStr(result);
    }, [money])

    return (
        <Fragment>
            {!_.isEmpty(label) && (
                <Typography component='span' className={clsx(classes.labelStyle, { 'required': required })}>
                    {label}
                </Typography>
            )}
            <TextField
                size="small" variant="outlined"
                placeholder={_.isEmpty(inputValue) && description}
                value={inputValue}
                disabled={disabled}
                className={clsx(classes.inputStyle, { 'fullwidth': (fullwidth) }, { 'disabled': disabled })} required
                id={`input-textfield-${fieldname}`}
                onBlur={onBlurSetValue}
                onChange={onchangeAction}
                autoComplete='off'
                nputProps={maxLength && { maxLength }}
                type={number ? "number" : "text"}
            />
            {
                isCurrency && (
                    <Typography component='div' className={clsx(classes.labelStyle, 'description')}>
                        bằng chữ: {moneyStr || "không"} đồng
                    </Typography>
                )
            }
            
        </Fragment >
    );
}

InputTextFieldCurrency.propTypes = {
    label: PropTypes.string,
    fieldname: PropTypes.string,
    description: PropTypes.string,
    required: PropTypes.bool,
    number: PropTypes.bool,
    object: PropTypes.object,
    setArrObject: PropTypes.func,
    fullwidth: PropTypes.bool,
    disabled: PropTypes.bool,
};

InputTextFieldCurrency.defaultProps = {
    required: false,
    number: false,
    fullwidth: false,
    multiline: false,
    disabled: false,
    modeEdit: false,
};

export default InputTextFieldCurrency;
