import * as _ from 'lodash';
import React from "react";
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, Grid } from '@material-ui/core';
import slugs from "../../resources/slugs";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: 300
    },
    item: {
        maxWidth: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        '&:hover': {
            color: '#00837b',
            background: 'none',
            opacity: '.7',
        }
    },
    iconStyle: {
        width: '100%',
    },
    text: {
        paddingTop: 20,
        fontSize: 20,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        textAlign: 'center',
    }
});

const Settings = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container direction="row" style={{width: 600}} alignItems="center">
                <ListItem className={classes.item} button component={Link} to={_.get(slugs, 'settingNotification')} >
                    <NotificationsNoneIcon style={{fontSize: 100}} />
                    <div className={classes.text}>QUẢN LÝ THÔNG BÁO</div>
                </ListItem>
                <ListItem className={classes.item} button component={Link} to={_.get(slugs, 'settingBanner')} >
                    <ImportantDevicesIcon style={{fontSize: 100}} />
                    <div className={classes.text}>QUẢN LÝ BANNER ECOE</div>
                </ListItem>
            </Grid>
           
        </div>
    )
};

export default Settings;
