import _ from 'lodash';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
	GET_LIST_ACCOUNT_TYPE,
	GET_LIST_PERMISSION,
	GET_PERMISSION_WITH_ACCOUNT,
	UPDATE_POSITION_PERMISSION
} from '../../graphql/schemas/permission';
import swal from 'sweetalert';

export const useGetListPermission = ({ department = [], position = [], isskip = false }) => {
	const { data, loading, refetch } = useQuery(GET_LIST_PERMISSION, {
		variables: {
			where: {
				parent: { _is_null: true },
				deleted: { _eq: false }
			},
			whereAddin: (_.isEmpty(position) && _.isEmpty(department)) ? undefined : {
				position: _.isEmpty(position) ? undefined : {
					_in: position
				},
				department: _.isEmpty(department) ? undefined : {
					_in: department
				}
			}
		},
		skip: isskip || !localStorage.getItem('access_token')
	});

	return {
		data: _.get(data, 'result'),
		loading,
		refetch
	}
};

export const useGetListAccountType = (isSkip = false) => {
	const { data, loading } = useQuery(GET_LIST_ACCOUNT_TYPE, {
		variables: {
			where: {
				deleted: {
					_eq: false
				}
			}
		},
		skip: isSkip
	});

	return {
		data: _.get(data, 'account_type'),
		loading
	}
};

export const useUpdatePositionPermisson = (setDisabledUpdate, refetchGetPermissions) => {
	const [actionUpdate, { loading, error }] = useMutation(UPDATE_POSITION_PERMISSION, {
		onError: (err) => {
			return swal({
				title: "Có lỗi khi cập nhật",
				text: `${err.message}`,
				icon: "error",
			}).then(() => setDisabledUpdate(false))
		},
		onCompleted: () => {
			return swal({
				title: "Cập nhật thành công",
				icon: "success",
			}).then(() => refetchGetPermissions())
		}
	});

	return {
		actionUpdate, loading, error
	}
};

export const useGetPermissionWithAccount = (id) => {
	const { data, loading, error } = useSubscription(GET_PERMISSION_WITH_ACCOUNT, {
		variables: {
			id
		},
		fetchPolicy: 'no-cache',
		skip: !id
	});
	const permissions = _.map(_.get(_.head(_.get(_.head(_.get(data, 'result')), 'employees')), 'permissions.position_permissions'), (item) => {
		let permission = JSON.parse(JSON.stringify(_.get(item, 'permission')));
		delete permission.__typename;
		return permission;
	});

	return {
		data: permissions,
		loading,
		error
	}
};
