import _ from 'lodash';
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
	Select, FormControl, MenuItem,
	Grid,
	CircularProgress,
	Button,
	makeStyles,
	TableContainer,
	Typography,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody
} from '@material-ui/core';
import {
	Backup as BackupIcon,
	AddCircle as AddCircleIcon,
} from '@material-ui/icons';
import PermissionRow from './components/PermissionRow';
import { useGetListPermission, useUpdatePositionPermisson } from './hooks';
import { useDepartments, usePositions } from '../Employee/hooks';
import slugs from '../../resources/slugs';
import clsx from 'clsx';
import { COMING_SOON, USER_ROLE_SUPPER_ADMIN } from '../../constants/defined';
import swal from 'sweetalert';
import { StoreContext } from '../../resources/store';

import AccessDenied from '../Permission/AccessDenied'

const useStyles = makeStyles(theme => ({
	root: {
		'& > div:not(:last-child)': {
			marginBottom: '15px'
		},
	},
	wrapperContent: {
		width: '100%',
		minHeight: '200px',
		position: 'relative',
		padding: '24px 0',
		'& > div:not(:last-child)': {
			marginBottom: '30px'
		},
	},
	wapperLoading: {
		width: '100%',
		height: 'calc(100vh - 113px)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	groupButton: {
		'& > .update': {
			backgroundColor: '#F4C867',
		},

		'& > .create': {
			backgroundColor: '#00837B',
		},

		'& > .disabled': {
			color: '#b9b9b9',
			backgroundColor: '#e2e1e1',
		},

		'& > a': {
			marginLeft: '24px',
		},

		'& > button, > a': {
			textTransform: 'unset',
			fontSize: '18px',
			color: '#ffffff',
			borderRadius: '6px',
			padding: '4px 20px',

			'&:hover': {
				opacity: .7,
			},

		},
	},
	button: {
		padding: '6px 30px',
		color: '#fff'
	},
	buttonUpdate: {
		backgroundColor: '#E07E37'
	},
	buttonCreateTypeAccount: {
		backgroundColor: '#00837B'
	},
	bold: {
		fontWeight: 500
	},
	titlePage: {
		fontSize: 30
	},
	tabContainer: {
		'& > span': {
			backgroundColor: 'black'
		}
	},
	textBold: {
		'& > th': {
			fontSize: '14px',
			fontWeight: 'bold',
		}
	},
	indicator: {
		backgroundColor: '#DA6744'
	},
	typeTab: {
		fontSize: '20px',
		color: '#B9B9B9',
		'&:not(.selected)': {
			cursor: 'pointer',
		},
		'&.selected': {
			color: '#0A2240',
			position: 'relative',
			'&:after': {
				content: `''`,
				width: 'calc(100% - 30px)',
				height: '3px',
				background: '#DA6744',
				position: 'absolute',
				bottom: '10px',
				left: '15px',
			}
		}
	},
	showAllSelect: {
		position: "absolute",
		height: "100%",
		width: "100%",
		display: "flex",
		alignItems: "center",
		paddingLeft: theme.spacing(2),
	}
}));

const TAB_MENU = [
	{ id: 'CONFIG', name: 'Web Admin' },
	{ id: 'APPLY', name: 'Ứng dụng cho nhân viên tư vấn' }
]

const PermissionIndex = () => {
	const classes = useStyles();

	const { userInfo } = useContext(StoreContext);

	const [indexTab, setIndexTab] = useState('CONFIG');
	const [selectedDepartment, setSelectedDepartment] = useState([]);
	const [headerList, setHeaderList] = useState([]);
	const [disabledUpdate, setDisabledUpdate] = useState();
	const [permissionUpdate, setPermissionUpdate] = useState();

	const { data: departments, loading: loadingDepartments } = useDepartments();
	const { data: dataPositions, loading: loadingPositions } = usePositions();
	const { data: permissionList, loading: loadingPermissions } = useGetListPermission({ isskip: _.toUpper(_.get(userInfo, 'role')) !== USER_ROLE_SUPPER_ADMIN });
	const { data: valuePermissions, loading: loadingValuePermissions, refetch: refetchGetPermissions } = useGetListPermission({
		department: _.isEmpty(selectedDepartment) ? _.map(departments, item => _.get(item, 'id')) : _.map(selectedDepartment, item => _.get(item, 'id')),
		position: _.isEmpty(headerList) ? _.map(dataPositions, item => _.get(item, 'id')) : _.map(headerList, item => _.get(item, 'id')),
		isskip: loadingDepartments || loadingPositions,
	});

	const { actionUpdate, loading: loadingUpdate } = useUpdatePositionPermisson(setDisabledUpdate, refetchGetPermissions);

	const returnedHeader = (list) => {
		let lstCell = [];
		if (_.size(list) > 0) {
			for (let i = 0; i < _.size(list); i++) {
				lstCell.push(<TableCell align="center" key={i}>{list[i].name}</TableCell>)
			}
		}
		return lstCell;
	};

	const handleUpdate = () => {
		setDisabledUpdate(true);
		actionUpdate({
			variables: {
				arg: permissionUpdate
			},
		});
	};

	const handleTabSelect = (type) => {
		if (type === 'APPLY') {
			return swal({
				title: _.get(COMING_SOON, 'title'),
				text: _.get(COMING_SOON, 'text'),
				icon: "info"
			})
		}
		setIndexTab(type);
	};

	useEffect(() => {
		setDisabledUpdate(true);
		setPermissionUpdate([]);
	}, []);

	useEffect(() => {
		if (valuePermissions) {
			let tmp = [];
			_.map(valuePermissions, (item) => {
				_.map(_.get(item, 'childs'), (obj) => {
					const positions = _.union(_.map(_.get(obj, 'position_permissions'), i => _.get(i, 'positionByPosition.id')));
					_.map(positions, (position) => {
						tmp.push({
							"position": position,
							"permission": _.get(obj, 'id'),
							"departments": _.isEmpty(selectedDepartment) ? _.map(departments, item => _.get(item, 'id')) : _.map(selectedDepartment, item => _.get(item, 'id'))
						})
					})
				})
			});

			setPermissionUpdate(tmp);
		}
	}, [loadingValuePermissions, valuePermissions]);



	if (
		loadingPermissions
		|| loadingDepartments
		|| loadingPositions
		|| loadingValuePermissions
		|| loadingUpdate
	) {
		return (
			<div className={classes.wapperLoading}>
				<CircularProgress />
			</div>
		)
	};

	return (
		<>
			{_.toUpper(_.get(userInfo, 'role')) !== USER_ROLE_SUPPER_ADMIN ? (
				<AccessDenied />
			) : (
				<Grid container direction="row" className={classes.root}>
					<Grid container direction="row">
						<Typography className={clsx(classes.bold, classes.titlePage)}>Phân quyền tài khoản</Typography>
					</Grid>
					<Grid container direction="row" spacing={4}>
						{
							_.map(TAB_MENU, (item, key) => {
								const selected = indexTab === _.get(item, 'id');
								return (
									<Grid
										key={key} item
										className={clsx(classes.typeTab, selected && 'selected')}
										onClick={() => !selected && handleTabSelect(_.get(item, 'id'))}
									>
										{_.get(item, 'name')}
									</Grid>
								)
							})
						}
					</Grid>

					<Paper className={classes.wrapperContent} elevation={0}>
						<Grid container direction="row" justifyContent="space-between" alignItems="flex-end" style={{ padding: '0 24px' }}>
							<Grid item xs={6}>
								<Grid container direction="row" spacing={3}>
									{indexTab === 'CONFIG' && (
										<Grid item xs={5}>
											<Typography>Phòng ban</Typography>
											<FormControl variant="outlined" size="small" style={{ width: '100%' }}>
												{
													_.isEmpty(selectedDepartment) && (
														<Typography className={classes.showAllSelect}>Tất cả</Typography>
													)
												}
												<Select onChange={(e) => setSelectedDepartment(_.get(e, 'target.value'))} value={selectedDepartment} multiple={true} >
													<MenuItem value={null} disabled>Chọn phòng ban</MenuItem>
													{
														_.map(departments, (item, key) => (
															<MenuItem key={key} value={item}>
																{_.get(item, 'name')}
															</MenuItem>
														))
													}

												</Select>
											</FormControl>
										</Grid>
									)}

									<Grid item xs={5}>
										<Typography>Chức vụ</Typography>
										<FormControl variant="outlined" size="small" style={{ width: '100%' }}>
											{
												_.isEmpty(headerList) && (
													<Typography className={classes.showAllSelect}>Tất cả</Typography>
												)
											}
											<Select
												value={headerList}
												onChange={(e) => setHeaderList(_.get(e, 'target.value'))}
												multiple={true}
												displayEmpty
												placeholder="Chọn chức vụ"
											>
												<MenuItem value={null} disabled>Chọn chức vụ</MenuItem>
												{
													_.map(dataPositions, (item, key) => (
														<MenuItem key={key} value={item}>
															{_.get(item, 'name')}
														</MenuItem>
													))
												}
											</Select>

										</FormControl>
									</Grid>
								</Grid>
							</Grid>

							<Grid item>
								<Grid container direction="row" className={classes.groupButton}>
									<Button
										className={clsx('update', disabledUpdate && 'disabled')}
										startIcon={<BackupIcon />}
										disabled={disabledUpdate}
										onClick={handleUpdate}
									>
										Cập nhật
									</Button>

									{/* <Button
										className='create'
										startIcon={<AddCircleIcon />}
										component={Link}
										to={slugs.employeeCreate}
									>
										Tạo loại tài khoản
									</Button> */}
								</Grid>
							</Grid>
						</Grid>

						{indexTab === 'CONFIG' ? (
							<Grid container direction="row">
								<TableContainer component={Paper}>
									<Table stickyHeader>
										<TableHead>
											<TableRow className={classes.textBold}>
												<TableCell>Tài khoản</TableCell>
												{returnedHeader(_.size(headerList) > 0 ? headerList : dataPositions)}
											</TableRow>
										</TableHead>
										<TableBody>
											{
												_.map(permissionList, (element, index) => {
													return (
														<PermissionRow
															key={index}
															headerList={_.size(headerList) > 0 ? headerList : dataPositions}
															row={element}
															disabledUpdate={disabledUpdate}
															setDisabledUpdate={setDisabledUpdate}
															permissionUpdate={permissionUpdate}
															setPermissionUpdate={setPermissionUpdate}
															selectedDepartment={_.size(selectedDepartment) > 0 ? selectedDepartment : departments}
														/>
													)
												})
											}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
						)
							:
							(
								<Grid container direction="row">
									<TableContainer component={Paper}>
										<Table stickyHeader>
											<TableHead>
												<TableRow>
													<TableCell>Tài khoản</TableCell>
													{returnedHeader(_.size(headerList) > 0 ? headerList : dataPositions)}
												</TableRow>
											</TableHead>
											<TableBody>
												Coming soon
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							)}
					</Paper>
				</Grid>
			)
			}

		</>

	)
}

export default PermissionIndex;
