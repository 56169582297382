import _ from "lodash";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { emphasize, makeStyles, withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Box,
  InputBase,
  Paper,
  IconButton,
  Typography,
  FormControl,
  TextField,
  Tabs,
  Tab,
} from "@material-ui/core";
import { ArrowBack, Search, FilterList } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import { useQuery } from "@apollo/client";
import SecondaryCard from "../../../components/SecondaryCard";
import { Select, MenuItem } from "@material-ui/core";
import PostAddIcon from "@material-ui/icons/PostAdd";
import { useHistory } from "react-router-dom";
import slugs from "../../../resources/slugs";
import { Waypoint } from "react-waypoint";

import { Link } from "react-router-dom";
import {
  PRODUCT_FIND_ALL,
  // GET_PRODUCT_CONSIGN_SUMMARY,
  // GET_PRODUCT_POST_SUMMARY,
  GET_SUMMARY_PRODUCT_OVERVIEW,
} from "../../../graphql/schemas/product/productQueries";
import InputDropdown from "./components/InputDropdown";
import clsx from "clsx";
import { useBranches } from "../../../graphql/schemas/masterdata/branches";
import MultiSelectionObject from "../../../components/MultiSelectionObject";
import { useGetEmployeeByRole } from "./hooks";
import ButtonReturn from "../../../components/ButtonReturn";
import SearchText from "./components/SearchText";
import ButtonStatusList from "./components/ButtonStatusList";
import { useProvinces } from "../../../graphql/schemas/masterdata/address";
import { useProductPlanMapStatus } from "../../../graphql/schemas/hooks";
import ButtonExportExcel from "../../../components/ButtonExportExcel";
import {
  ALL_SECONDARY_PRODUCT_STATUS,
  EXPORT_SECONDARY,
  USER_ROLE_ADMINISTRATOR,
  USER_ROLE_SALES,
  USER_ROLE_SUPPER_ADMIN,
} from "../../../constants/defined";

import { StoreContext } from "../../../resources/store";
import { checkedPermission } from "../../../helper";

const useStyles = makeStyles((theme) => ({
  wrapperList: {
    minHeight: "350px",
    margin: "30px 0",
  },
  wapperLoading: {
    width: "100%",
    height: "calc(100vh - 113px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  typeProduct: {
    fontSize: "20px",
    color: "#B9B9B9",
    "&:not(.selected)": {
      cursor: "pointer",
    },
    "&.selected": {
      color: "#0A2240",
      position: "relative",
      "&:after": {
        content: `''`,
        width: "calc(100% - 30px)",
        height: "3px",
        background: "#DA6744",
        position: "absolute",
        bottom: 0,
        left: "15px",
      },
    },
  },
  nodataStyle: {
    color: "#E27F35",
    fontSize: "16px",
    fontWeight: "bold",
  },
  formControl: {
    display: "grid",
    gridTemplate: "auto / 150px 300px",
    alignItems: "center",
    marginTop: 30,
  },
  groupFilterStyle: {
    marginTop: "30px",
    "& > div:not(:last-child)": {
      paddingRight: "30px",
    },
  },
  button: {
    backgroundColor: "#E27F35",
    color: "#ffffff",
    borderRadius: 0,
    marginTop: 10,
    "&:nth-child(2)": {
      marginLeft: 20,
    },
  },
  rootSearch: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    maxWidth: 400,
    borderRadius: 20,
  },
  columnStyle: {
    // '&:not(:first-child)': {
    //     paddingLeft: '30px',
    // },
    "&.right": {
      textAlign: "right",
    },
  },
  plus: {
    fontSize: "38px",
    color: "#00837b",
    cursor: "pointer",
  },
  marginBottom: {
    marginBottom: "15px",
  },
  buttonSecondaryStyle: {
    backgroundColor: "#E27F35",
    "&:hover": {
      backgroundColor: "#c56e2d",
    },
  },
  wrapperButtonFilter: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
  },
  contentStyle: {
    paddingLeft: "8px",
  },
}));

const PRODUCT_TYPE = [
  { id: "CONSIGN", name: "Sản phẩm ký gửi" },
  { id: "POST", name: "Sản phẩm đăng tin" },
];

const USER_TYPE_UPDATED = [
  { id: 1, code: USER_ROLE_SALES, name: "Agent" },
  { id: 2, code: USER_ROLE_ADMINISTRATOR, name: "Admin" },
];

const Secondary = ({ openMenu }) => {
  const history = useHistory();
  const classes = useStyles();
  const [provinces, setProvinces] = useState([]);
  const [productType, setProductType] = useState({
    id: window.localStorage.getItem("product_type") || "CONSIGN",
  });
  const [orderBy, setOrderBy] = useState("created_desc");
  const [role, setRole] = useState([USER_ROLE_SALES]);
  const [employeeId, setEmployeeId] = useState([]);
  const [typeStatusCode, setTypeStatusCode] = useState();
  const [titleSearchText, setTitleSearchText] = useState("");
  const [filterEmployee, setFilterEmployee] = useState([]);
  const [employeeUpdate, setEmployeeUpdate] = useState("ALL");
  const [branchs, setBranchs] = useState([]);
  const [filterPlanMap, setFilterPlanMap] = useState("ALL");

  const { data: brancheList } = useBranches();
  const { data: dataEmployees } = useGetEmployeeByRole(role);
  const { data: dataPlanMapStatus } = useProductPlanMapStatus();

  const { userInfo, accessObjectPermissions } = useContext(StoreContext);

  const provinceList = useProvinces();

  const PER_PAGE = 30;

  // const conditionFilterSummary = {
  //   updatedByAccount:
  //     employeeUpdate === "ALL"
  //       ? undefined
  //       : {
  //         account_roles: {
  //           roleByRole: {
  //             code: {
  //               _eq: employeeUpdate,
  //             },
  //           },
  //         },
  //       },
  //     product_requests: {
  //       incharge:
  //         _.size(employeeId) > 0 && productType.id === "CONSIGN"
  //           ? {
  //             _in: employeeId,
  //           }
  //           : undefined,
  //       workflow_type: { code: { _eq: _.get(productType, "id") } },
  //     },
  //   addressByAddress:
  //     _.size(provinces) > 0 && productType.id === "POST"
  //       ? {
  //         province: { _in: provinces },
  //       }
  //       : undefined,
  //   branch:
  //     _.size(branchs) > 0 && productType.id === "CONSIGN"
  //       ? { _in: branchs }
  //       : undefined,
  //   _and:
  //     filterPlanMap === "ALL" && !titleSearchText
  //       ? undefined
  //       : [
  //         {
  //           _or:
  //             filterPlanMap === "ALL"
  //               ? undefined
  //               : filterPlanMap === "INITIAL"
  //                 ? [
  //                   {
  //                     productPlanStatusByProductPlanStatus: {
  //                       code: { _eq: filterPlanMap },
  //                     },
  //                   },
  //                   { product_plan_status: { _is_null: true } },
  //                 ]
  //                 : [
  //                   {
  //                     productPlanStatusByProductPlanStatus: {
  //                       code: { _eq: filterPlanMap },
  //                     },
  //                   },
  //                 ],
  //         },
  //         {
  //           _or: titleSearchText
  //             ? [
  //               { title: { _ilike: `%${titleSearchText}%` } },
  //               { code: { _ilike: `%${titleSearchText}%` } },
  //               {
  //                 addressByAddress: {
  //                   _or: [
  //                     {
  //                       wardByWard: {
  //                         name: { _ilike: `%${titleSearchText}%` },
  //                       },
  //                     },
  //                     {
  //                       districtByDistrict: {
  //                         name: { _ilike: `%${titleSearchText}%` },
  //                       },
  //                     },
  //                     {
  //                       provinceByProvince: {
  //                         name: { _ilike: `%${titleSearchText}%` },
  //                       },
  //                     },
  //                     {
  //                       streetByStreet: {
  //                         name: { _ilike: `%${titleSearchText}%` },
  //                       },
  //                     },
  //                   ],
  //                 },
  //               },
  //             ]
  //             : undefined,
  //         },
  //       ],
  // };

  const conditionFilter = {
    active: _.includes(typeStatusCode, "LOCKED") ? { _eq: false } : undefined,
    status: !_.includes(typeStatusCode, "LOCKED")
      ? { _in: typeStatusCode }
      : undefined,
    updatedByAccount:
      employeeUpdate === "ALL"
        ? undefined
        : {
          account_roles: {
            roleByRole: {
              code: employeeUpdate === USER_ROLE_ADMINISTRATOR ? {
                _in: [USER_ROLE_SUPPER_ADMIN, USER_ROLE_ADMINISTRATOR]
              } : {
                _eq: employeeUpdate,
              },
            },
          },
        },
    product_requests: {
      incharge:
        _.size(employeeId) > 0 && productType.id === "CONSIGN"
          ? {
            _in: employeeId,
          }
          : undefined,
      workflow_type: { code: { _eq: _.get(productType, "id") } },
    },
    addressByAddress:
      _.size(provinces) > 0 && productType.id === "POST"
        ? {
          province: { _in: provinces },
        }
        : undefined,
    branch:
      _.size(branchs) > 0 && productType.id === "CONSIGN"
        ? { _in: branchs }
        : undefined,
    _and:
      filterPlanMap === "ALL" && !titleSearchText
        ? undefined
        : [
          {
            _or:
              filterPlanMap === "ALL"
                ? undefined
                : filterPlanMap === "INITIAL"
                  ? [
                    {
                      productPlanStatusByProductPlanStatus: {
                        code: { _eq: filterPlanMap },
                      },
                    },
                    { product_plan_status: { _is_null: true } },
                  ]
                  : [
                    {
                      productPlanStatusByProductPlanStatus: {
                        code: { _eq: filterPlanMap },
                      },
                    },
                  ],
          },
          {
            _or: titleSearchText
              ? [
                { title: { _ilike: `%${titleSearchText}%` } },
                { code: { _ilike: `%${titleSearchText}%` } },
                {
                  addressByAddress: {
                    _or: [
                      {
                        wardByWard: {
                          name: { _ilike: `%${titleSearchText}%` },
                        },
                      },
                      {
                        districtByDistrict: {
                          name: { _ilike: `%${titleSearchText}%` },
                        },
                      },
                      {
                        provinceByProvince: {
                          name: { _ilike: `%${titleSearchText}%` },
                        },
                      },
                      {
                        streetByStreet: {
                          name: { _ilike: `%${titleSearchText}%` },
                        },
                      },
                    ],
                  },
                },
              ]
              : undefined,
          },
        ],
  };

  // const { data: dataCountStatus, loading: loadingConsignSummary } = useQuery(
  //   GET_PRODUCT_CONSIGN_SUMMARY,
  //   {
  //     variables: {
  //       condition: conditionFilterSummary,
  //     },
  //     skip: productType.id !== "CONSIGN",
  //   }
  // );

  // const { data: dataCountPost, loading: loadingPostSummary } = useQuery(
  //   GET_PRODUCT_POST_SUMMARY,
  //   {
  //     variables: {
  //       condition: conditionFilterSummary,
  //     },
  //     skip: productType.id !== "POST",
  //   }
  // );

  const { data: dataCountStatusNew, loading: loadingConsignSummaryNew } = useQuery(GET_SUMMARY_PRODUCT_OVERVIEW,{
    variables: {
      "listing_type_code": productType?.id,
      "incharge_userids": productType?.id !== "CONSIGN" || _.isEmpty(employeeId) ? null : _.join(employeeId,","),
      "branchids": productType?.id !== "CONSIGN" || _.isEmpty(branchs) ? null : _.join(branchs,","),
      "updated_by_role": employeeUpdate==="ALL" ? null : employeeUpdate === USER_ROLE_ADMINISTRATOR ? _.join([USER_ROLE_SUPPER_ADMIN, USER_ROLE_ADMINISTRATOR],",") : employeeUpdate,
      "plan_code": filterPlanMap==="ALL" ? null : filterPlanMap,
      "provinceids": productType?.id !== "POST" || _.isEmpty(provinces) ? null : _.join(provinces,","),
    }
  });

  const handleParamSort = () => {
    if (orderBy === "price_desc") {
      return {
        price: "desc",
      };
    }
    if (orderBy === "price_asc") {
      return {
        price: "asc",
      };
    }
    if (orderBy === "created_asc") {
      return {
        created: "asc",
      };
    }
    if (orderBy === "created_desc") {
      return {
        created: "desc",
      };
    }
    return {
      created: "desc",
    };
  };

  const { loading, data, fetchMore } = useQuery(PRODUCT_FIND_ALL, {
    variables: {
      skip: 0,
      take: PER_PAGE,
      order_by: handleParamSort(),
      condition: conditionFilter,
    },
    skip: !typeStatusCode
  });

  const count = _.get(data, "product_aggregate.aggregate.count");

  const handleSetValue = () => {
    setEmployeeId(filterEmployee);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    setTypeStatusCode(ALL_SECONDARY_PRODUCT_STATUS);
  }, [productType]);

  if (
    loading
    || loadingConsignSummaryNew
    //|| loadingConsignSummary || loadingPostSummary    
  ) {
    return (
      <div className={classes.wapperLoading}>
        <CircularProgress />
      </div>
    );
  }    

  return (
    <Fragment>
      <Grid
        container
        direction="row"
        className={classes.marginBottom}
        spacing={4}
      >
        <Grid item xs={12} md={6} className={classes.columnStyle}>
          <Paper className={classes.rootSearch}>
            <IconButton className={classes.iconButton} aria-label="search">
              <Search />
            </IconButton>
            <SearchText text={titleSearchText} setText={setTitleSearchText} />
          </Paper>
        </Grid>
        {/* <Grid item xs={12} md={6} className={clsx(classes.columnStyle, 'right')}>
                <Link to={slugs.productCreateSecondary}><PostAddIcon className={classes.plus} /></Link>
            </Grid> */}
      </Grid>

      <Grid
        container
        direction="row"
        spacing={3}
        justifyContent="space-between"
        className={classes.marginBottom}
      >
        <Grid item>
          <Grid container spacing={4}>
            {_.map(PRODUCT_TYPE, (item, key) => {
              const selected = _.get(productType, "id") === _.get(item, "id");
              return (
                <Grid
                  key={key}
                  item
                  className={clsx(classes.typeProduct, selected && "selected")}
                  onClick={() => !selected && setProductType(item)}
                >
                  {_.get(item, "name")}
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            style={{ height: "100%", alignItems: "center" }}
          >
            <Grid item>
              <FilterList />
            </Grid>
            <Grid item>
              <Typography gutterBottom component="h4">
                Săp xếp:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl size="small" variant="outlined" fullWidth>
                <Select
                  value={orderBy}
                  onChange={(e) => setOrderBy(e.target.value)}
                >
                  <MenuItem value="created_desc">Mới nhất</MenuItem>
                  <MenuItem value="created_asc">Cũ nhất</MenuItem>
                  <MenuItem value="price_desc">Giá cao nhất</MenuItem>
                  <MenuItem value="price_asc">Giá thấp nhất</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        className={classes.marginBottom}
        spacing={4}
      >
        <Grid item style={{ flexGrow: 10 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} className={classes.columnStyle}>
              <FormControl variant="outlined" size="small" fullWidth>
                <Typography gutterBottom component="h4">
                  Quy hoạch
                </Typography>
                <Select
                  defaultValue={filterPlanMap}
                  onChange={(e) => setFilterPlanMap(e.target.value)}
                >
                  <MenuItem value="ALL">{`Tất cả`}</MenuItem>
                  {_.map(dataPlanMapStatus, (item, key) => (
                    <MenuItem
                      key={_.get(item, "id") || key}
                      value={_.get(item, "code")}
                    >
                      {_.get(item, "name")}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3} className={classes.columnStyle}>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
              >
                <Typography gutterBottom component="h4">
                  Cập nhật bởi
                </Typography>
                <Select
                  defaultValue={employeeUpdate}
                  onChange={(e) => setEmployeeUpdate(e.target.value)}
                >
                  <MenuItem value="ALL">{`Tất cả`}</MenuItem>
                  {_.map(USER_TYPE_UPDATED, (item, key) => (
                    <MenuItem
                      key={_.get(item, "id") || key}
                      value={_.get(item, "code")}
                    >
                      {_.get(item, "name")}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {productType.id === "CONSIGN" && (
              <Grid item xs={12} md={3} className={classes.columnStyle}>
                <MultiSelectionObject
                  fullwidth
                  title="Văn phòng"
                  list={brancheList}
                  setValue={setBranchs}
                  selected={branchs}
                />
              </Grid>
            )}

            {productType.id === "POST" && (
              <Grid item xs={12} md={3} className={classes.columnStyle}>
                <MultiSelectionObject
                  fullwidth
                  title="Khu vực"
                  list={provinceList}
                  setValue={setProvinces}
                  selected={provinces}
                />
              </Grid>
            )}

            {productType.id === "CONSIGN" && (
              <Grid item xs={12} md={3} className={classes.columnStyle}>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Typography gutterBottom component="h4">
                    Nhân viên kiểm duyệt
                  </Typography>
                  <Select
                    multiple
                    MenuProps={MenuProps}
                    displayEmpty
                    value={filterEmployee}
                    onChange={(e) => setFilterEmployee(e.target.value)}
                    onClose={handleSetValue}
                    renderValue={(selected) => {
                      let arr = _.map(selected, (value) =>
                        _.get(_.find(dataEmployees, ["info.id", value]), "info.full_name")
                      );
                      if (_.size(arr) === 0) {
                        return <span>Tất cả</span>;
                      }
                      return arr.join(", ");
                    }}
                  >
                    <MenuItem disabled value="">{`Chọn nhân viên`}</MenuItem>
                    {_.map(dataEmployees, (item, key) => (
                      <MenuItem
                        key={_.get(item, "info.id") || key}
                        value={_.get(item, "info.id")}
                      >
                        {_.get(item, "info.full_name")}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <ButtonExportExcel
            disabled={
              !checkedPermission(
                userInfo,
                accessObjectPermissions,
                EXPORT_SECONDARY
              )
            }
            exportName="EXPORT_PRODUCT"
            workflow_type={_.get(productType, "id")}
            status={_.size(typeStatusCode) > 3 ? "" : _.join(typeStatusCode,",")}
            branch={
              productType.id === "CONSIGN" && _.size(branchs) > 0
                ? branchs
                : null
            }
            province={
              productType.id === "POST" && _.size(provinces) > 0
                ? provinces
                : null
            }
            price={
              orderBy === "price_desc"
                ? "desc"
                : orderBy === "price_asc"
                  ? "asc"
                  : null
            }
            plan_status={filterPlanMap === "ALL" ? null : filterPlanMap}
            updated_by_role={employeeUpdate === "ALL" ? null : employeeUpdate}
            created={
              orderBy === "created_desc"
                ? "desc"
                : orderBy === "created_asc"
                  ? "asc"
                  : null
            }
            incharge={
              _.get(productType, "id") === "CONSIGN" ? employeeId : null
            }
            withRange={true}
          />
        </Grid>
      </Grid>

      <Paper elevation={0} style={{ padding: "24px", margin: "30px auto" }}>
        {/* <Grid container direction="row">
          <div className={classes.wrapperButtonFilter}>
            <ButtonStatusList
              status="WAITING"
              count={
                productType.id === "CONSIGN"
                  ? _.get(dataCountStatus, "waiting.aggregate.count")
                  : _.get(dataCountPost, "waiting.aggregate.count")
              }
              handleClick={setTypeStatusCode}
              selected={
                _.first(typeStatusCode) === "WAITING" &&
                _.size(typeStatusCode) === 1
              }
            />
            <ButtonStatusList
              status="SELLING"
              count={
                productType.id === "CONSIGN"
                  ? _.get(dataCountStatus, "selling.aggregate.count")
                  : _.get(dataCountPost, "selling.aggregate.count")
              }
              handleClick={setTypeStatusCode}
              selected={_.first(typeStatusCode) === "SELLING"}
            />
            <ButtonStatusList
              status="SOLD"
              count={
                productType.id === "CONSIGN"
                  ? _.get(dataCountStatus, "sold.aggregate.count")
                  : _.get(dataCountPost, "sold.aggregate.count")
              }
              handleClick={setTypeStatusCode}
              selected={_.first(typeStatusCode) === "SOLD"}
            />
            <ButtonStatusList
              status="HIDDEN"
              count={
                productType.id === "CONSIGN"
                  ? _.get(dataCountStatus, "hidden.aggregate.count")
                  : _.get(dataCountPost, "hidden.aggregate.count")
              }
              handleClick={setTypeStatusCode}
              selected={_.first(typeStatusCode) === "HIDDEN"}
            />
            <ButtonStatusList
              status="LOCKED"
              count={
                productType.id === "CONSIGN"
                  ? _.get(dataCountStatus, "locked.aggregate.count")
                  : _.get(dataCountPost, "locked.aggregate.count")
              }
              handleClick={setTypeStatusCode}
              selected={_.first(typeStatusCode) === "LOCKED"}
            />
            <ButtonStatusList
              status="ALL"
              count={
                productType.id === "CONSIGN"
                  ? _.get(dataCountStatus, "all.aggregate.count")
                  : _.get(dataCountPost, "all.aggregate.count")
              }
              handleClick={setTypeStatusCode}
              selected={
                _.first(typeStatusCode) === "WAITING" &&
                _.size(typeStatusCode) > 1
              }
            />
          </div>
        </Grid> */}

        <Grid container direction="row">
          <div className={classes.wrapperButtonFilter}>
            <ButtonStatusList
              status="WAITING"
              count={_.get(dataCountStatusNew,"result[0].total_waiting") || 0}
              handleClick={setTypeStatusCode}
              selected={
                _.first(typeStatusCode) === "WAITING" &&
                _.size(typeStatusCode) === 1
              }
            />
            <ButtonStatusList
              status="SELLING"
              count={_.get(dataCountStatusNew,"result[0].total_selling") || 0}
              handleClick={setTypeStatusCode}
              selected={_.first(typeStatusCode) === "SELLING"}
            />
            <ButtonStatusList
              status="SOLD"
              count={_.get(dataCountStatusNew,"result[0].total_sold") || 0}
              handleClick={setTypeStatusCode}
              selected={_.first(typeStatusCode) === "SOLD"}
            />
            <ButtonStatusList
              status="HIDDEN"
              count={_.get(dataCountStatusNew,"result[0].total_hidden") || 0}
              handleClick={setTypeStatusCode}
              selected={_.first(typeStatusCode) === "HIDDEN"}
            />
            <ButtonStatusList
              status="LOCKED"
              count={_.get(dataCountStatusNew,"result[0].total_locked") || 0}
              handleClick={setTypeStatusCode}
              selected={_.first(typeStatusCode) === "LOCKED"}
            />
            <ButtonStatusList
              status="ALL"
              count={_.get(dataCountStatusNew,"result[0].total") || 0}
              handleClick={setTypeStatusCode}
              selected={
                _.first(typeStatusCode) === "WAITING" &&
                _.size(typeStatusCode) > 1
              }
            />
          </div>
        </Grid>

        {loading ? (
          <CircularProgress />
        ) : (
          <Box display="flex" className={classes.wrapperList}>
            <Grid container direction="row" alignItems="flex-start">
              <Grid container direction="row" spacing={3}>
                {_.size(_.get(data, "products")) > 0 ? (
                  _.map(data.products, (item, index) => {
                    return (
                      <Fragment key={index}>
                        <SecondaryCard openMenu={openMenu} data={item} />

                        {index === _.size(data.products) - 10 &&
                          count > _.size(data.products) && (
                            <Waypoint
                              onEnter={() =>
                                fetchMore({
                                  variables: {
                                    skip:
                                      (_.size(data.products) / PER_PAGE) *
                                      PER_PAGE,
                                    take: PER_PAGE,
                                  },
                                  updateQuery: (
                                    previousResult,
                                    { fetchMoreResult }
                                  ) => {
                                    if (!_.get(fetchMoreResult, "products")) {
                                      return previousResult;
                                    }
                                    return {
                                      products: [
                                        ...previousResult.products,
                                        ...fetchMoreResult.products,
                                      ],
                                    };
                                  },
                                })
                              }
                            />
                          )}
                      </Fragment>
                    );
                  })
                ) : (
                  <Typography className={classes.nodataStyle}>
                    Không có dữ liệu thích hợp
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>

      <Grid container direction="row" justifyContent="flex-end">
        <ButtonReturn link={slugs.product} />
      </Grid>
    </Fragment>
  );
};
export default Secondary;
