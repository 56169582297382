import React, { Fragment, useContext } from 'react'
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import * as _ from 'lodash';
import Moment from 'react-moment';
import { useMutation } from '@apollo/client';
import { UPDATE_NOTIFICATION_ACCOUNT_SEEN } from '../../graphql/schemas/notification/notifyMutation';
import { GET_COUNT_HOME_PAGE, GET_NEW_NOTIFICATIONS } from '../../graphql/schemas/notification';
import { useHistory } from 'react-router';
import { StoreContext } from '../../resources/store';

const ItemListNotify = ({ borderRadius = "50%", circleWidth = 15, circleHeight = 15, gridItem = false, data, isNew }) => {
    const history = useHistory();
    const { userInfo, accessPermissions } = useContext(StoreContext);
    const account = _.get(userInfo,'id');
    const [updateNotification, { data: dataUpdated }] = useMutation(UPDATE_NOTIFICATION_ACCOUNT_SEEN, {
        refetchQueries: [{
            query: GET_NEW_NOTIFICATIONS, variables: {
                account: account
            }
        },
        {
            query: GET_COUNT_HOME_PAGE, variables: {
                account: account
            }
        }
        ]
    });
    const useStyles = makeStyles((theme) => ({
        itemContainer: {
            marginTop: 20,
            '&:hover': {
                backgroundColor: '#C4C4C4'
            },
            cursor: 'pointer'
        },
        customerName: {
            textDecoration: 'underline'
        }

    }));

    const handleReadMessage = () => {
        const type = _.get(data, 'notification.notification_type.code');
        if (isNew) {
            //Call API to update read message
            updateNotification({
                variables: {
                    notification: _.get(data, 'notification.id'),
                    account: account
                }
            })
            if (type === 'PRODUCT' || type === 'TASK' || type === 'POST') {
                history.push('/product/secondary/detail/' + _.get(data, 'notification.deep_link'))
            }
        } else {
            if (type === 'PRODUCT' || type === 'TASK' || type === 'POST') {
                history.push('/product/secondary/detail/' + _.get(data, 'notification.deep_link'))
            }
        }
    }
    const classes = useStyles();
    return (
        <Fragment>
            {
                <Grid className={classes.itemContainer} container direction="row" alignItems="center" onClick={() => { }}>
                    <Grid item>
                        <PersonIcon style={{ fontSize: 40 }} />
                    </Grid>
                    <Grid item style={{ marginLeft: 20 }} onClick={handleReadMessage}>
                        <span>{_.get(data, 'notification.body')}</span>
                        {/* <span className={classes.customerName}>Nhân viên Huy Nguyen (mã nhân viên: xxxx) </span> <span>vừa giao dịch thành công căn SP ECOE123456 </span> */}
                    </Grid>
                    <Grid item style={{ marginLeft: 'auto' }}>
                        <Moment format="DD/MM/YYYY hh:mm:ss">{_.get(data, 'notification.created')}</Moment>
                    </Grid>
                </Grid>

            }

        </Fragment>
    )
}

export default ItemListNotify
