import React, { Fragment, useContext, useEffect, useState } from 'react';
import * as _ from "lodash";
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory, Redirect, useParams } from 'react-router-dom';
import {
    Paper, Grid, Typography, TextField, MenuItem, Select, FormControl, InputLabel, CircularProgress, Button
} from '@material-ui/core';
import slugs from '../../../resources/slugs';
import axios from 'axios';
import ICON_UPLOAD from '../../../assets/icons/up-load-photo.svg';
import InputTextField from '../Secondary/components/InputTextField';
import {
    useGetAllShortCustomer, useGetBookingStatus, useGetProductsByCart, useGetProjectCarts
} from '../../../graphql/schemas/hooks';
import InputCustomerSearch from '../Secondary/components/InputCustomerSearch';
import clsx from 'clsx';
import ButtonReturn from '../../../components/ButtonReturn';
import { Add, Save, CloudUpload, Delete } from '@material-ui/icons';
import InputTextFieldCurrency from '../../../components/InputTextFieldCurrency';
import AutoCompleteEmployee from '../../../components/AutoCompleteEmployee';
import AutoCompleteCodeProduct from '../../../components/AutoCompleteCodeProduct';
import { INSERT_BOOKING } from '../../../graphql/schemas/booking/mutations';
import { useMutation } from '@apollo/client';
import swal from 'sweetalert';
import InputDateAndTimePicker from '../../../components/InputDateAndTimePicker';

import { ConfigContext } from '../../../context/ConfigContext';


const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: '30px',
    },
    headerStyle: {
        fontSize: '40px',
        color: "#00837b",
        textTransform: "uppercase",
        fontWeight: "bold",
    },
    margin: {
        margin: '20px 0',
    },
    err: {
        color: "#db2828",
        paddingTop: "5px"
    },
    textLabelForm: {
        wordWrap: 'break-word',
        width: 160,
        fontWeight: 'bold',
        marginRight: 30
    },
    input: {
        width: 250,
        backgroundColor: 'white',
        marginLeft: '0',
        '& > .MuiFormHelperText-marginDense': {
            backgroundColor: '#FAFAFA',
            margin: 0
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none'
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        outline: 'none',
        display: 'flex',
        maxWidth: '760px',
        flexDirection: 'column',
        alignItems: 'center',
    },
    button: {
        color: '#fff',
        borderRadius: 0,
        textTransform: 'uppercase'
    },
    buttonTitle: {
        backgroundColor: '#07807d',
        color: "white",
        borderRadius: 0,
        fontSize: '16px',
        width: '26em',
        fontWeight: 'bold'
    },
    buttonCreateConfirm: {
        backgroundColor: "#07807d",
        color: '#fff',
        borderRadius: 0,
        textTransform: 'uppercase'
    },
    form: {
        width: "250px !important"
    },
    groupRow: {
        '& > div:not(:last-child)': {
            paddingBottom: '30px',
        }
    },
    gridRow: {
        alignItems: 'center',

        '& > .grid-image': {
            display: 'grid',
            gridGap: '30px',
        },
        '& > .column-3': {
            gridTemplate: 'auto / calc((100% - 60px)/3) calc((100% - 60px)/3) calc((100% - 60px)/3)',
        },
        '& > .column-4': {
            gridTemplate: 'auto / calc((100% - 60px)/4) calc((100% - 60px)/4) calc((100% - 60px)/4) calc((100% - 60px)/4)',
        },
        '&.image-view': {
            alignItems: 'start',
            paddingTop: '30px'
        }
    },
    invoiceImageStyle: {
        width: '100%',
        maxWidth: '360px',
        borderRadius: '5px',
        paddingRight: '5px',
    },
    vertical: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        fontWeight: 'bold',
        paddingRight: '30px',
        position: 'relative',

        '&.required:after': {
            content: '"*"',
            fontSize: '18px',
            lineHeight: 1,
            position: 'absolute',
            color: 'red',
            paddingLeft: '5px',
        },
        '&.unit > span': {
            position: 'absolute',
            right: 0,
            bottom: 0,
            fontWeight: 'normal',
            opacity: '.5',
        },
        '&.description': {
            paddingTop: '10px',
            fontWeight: 'normal',
            color: '#999',
        },
    },
    secondaryButton: {
        borderBottom: '1px solid',
        display: 'inline-block',
        cursor: 'pointer',
        color: '#0037ff',
        fontWeight: 'bold',
        marginLeft: '15px',
    },
    footerStyle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',

        '& button': {
            borderRadius: 0,
            padding: '5px 30px',
        },
        '& button:not(:last-child)': {
            marginRight: '30px',
        },
    },
    validate: {
        height: '0px',
        position: 'relative',
        bottom: '0px',
        fontSize: '13px',
        color: '#f00',
    },
    wapperLoading: {
        width: '100%',
        height: 'calc(100vh - 113px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

const MAX_SIZE = 5;
const DEPOSIT_STATUS_NUMB = 11;
const MASTER_CART = ['AFTERSALE', 'ECOECART'];
const REQUIRED_FIELD = [
    'cart', 'customer', 'personalid', 'employee', 'booking_investor_code',
    //'status', 
    //'product', 
];

const ValidationNote = ({ fieldname, Obj }) => {
    const classes = useStyles();

    if (_.get(Obj, fieldname)) {
        return (
            <Typography component='span' className={clsx(classes.validate)}>
                Thông tin bắt buộc
            </Typography>
        )
    };
    return null;
}

const CreateBookingPage = () => {
    let INDEX_CART = {};
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const { id } = useParams();
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const selectProject = JSON.parse(localStorage.getItem('selectProject'));

    const configValue = useContext(ConfigContext)

    const { data: dataCarts, loading: loadingCarts, error: errorCarts } = useGetProjectCarts({ project: id, });
    // const { data: dataBookingStatus, loading: loadingBookingStatus, error: errorBookingStatus } = useGetBookingStatus();
    const { data: customerList } = useGetAllShortCustomer();
    const [insertBooking, { loading: loadingInsert }] = useMutation(INSERT_BOOKING);

    const [progress, setProgress] = useState(false);
    const [draftObject, setDraftObject] = useState({ project: selectProject?.id, booking_investor_code: null, });
    const [customer, setCustomer] = useState(null);
    const [isAvailable, setIsAvailable] = useState(false);
    const [selectCart, setSelectCart] = useState(_.get(selectProject, 'indexCard.id') || -1);
    // const [statusBooking, setStatusBooking] = useState(-1);
    const [refEmployeeCode, setRefEmployeeCode] = useState(null);
    const [invoiceObject, setInvoiceObject] = useState([]);
    const [productBooked, setProductBooked] = useState(null);
    const [errorValid, setErrorValid] = useState(null)

    const handlerUpImage = async (e, itemKey) => {
        setProgress(true);
        let tempArrObject = invoiceObject;
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("media_type_code", "RECEIPT")
        try {
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
            const { data } = await axios.post(configValue.API_UPLOAD_FILE, formData, config);
            _.set(tempArrObject[itemKey], 'invoiceImage', _.head(data));
            setInvoiceObject(tempArrObject)
        } catch (error) {
            console.log(error);
        }
        setProgress(false);
    }

    const setDateTime = (value, index) => {
        let tmp = invoiceObject;
        _.set(tmp[index], 'receipt_date', value);
        setInvoiceObject(tmp);
    }

    useEffect(() => {
        if (_.isEmpty(invoiceObject)) {
            let tmp = invoiceObject;
            tmp = _.concat(tmp, { 'amount': '', 'invoiceImage': {}, 'invoiceCode': "" })
            setInvoiceObject(tmp);
        }
    }, []);

    const addInvoiceHandle = () => {
        let tmp = invoiceObject;
        tmp = _.concat(tmp, { 'amount': '', 'invoiceImage': {}, 'invoiceCode': "" })
        setInvoiceObject([...tmp]);
    };

    const deleteInvoiceHandle = (itemKey) => {
        let tmp = invoiceObject;
        _.pullAt(tmp, [itemKey]);
        setInvoiceObject([...tmp]);
    };

    useEffect(() => {
        if (customer?.customer === "Invalid") {
            swal("Khách hàng chưa tồn tại", "Vui lòng tạo mới hoặc chọn lại!", "warning");
        }
    }, [customer]);

    INDEX_CART = _.find(dataCarts?.result, ['id', selectCart])

    const handleCreateBooking = (event) => {

        event.preventDefault();
        let pushObject = draftObject;

        _.set(pushObject, 'cart', selectCart);
        _.set(pushObject, 'customer', customer?.id || null);
        _.set(pushObject, 'personalid', _.toString(customer?.personalid) || null);
        _.set(pushObject, 'employee', refEmployeeCode);
        // _.set( pushObject, 'status', statusBooking );
        _.set(pushObject, 'product', productBooked?.id || null);

        if (_.get(_.head(invoiceObject), 'amount')) {
            _.set(pushObject, 'payment',

                _.map(invoiceObject, (item) => ({
                    amount: _.get(item, 'amount'),
                    description: _.get(item, 'invoiceCode'),
                    receipt_media: _.get(item, 'invoiceImage.id'),
                    receipt_date: _.get(item, 'invoiceImage.receipt_date'),
                }))
            );
        }

        let tmpErr = {};
        _.map(pushObject, (value, key) => {
            if ((!value || value === -1) && _.includes(REQUIRED_FIELD, key)) {
                _.set(tmpErr, key, true)
            }
        })

        if (
            // statusBooking===DEPOSIT_STATUS_NUMB &&
            _.includes(MASTER_CART, _.get(INDEX_CART, 'project_cart_status.code')) &&
            _.isEmpty(productBooked?.id)
        ) {
            _.set(tmpErr, 'product', true)
        }

        if (!_.isEmpty(tmpErr)) {
            setErrorValid(tmpErr)
            return;
        }

        insertBooking({
            variables: {
                data: pushObject
            }
        }).then(res => {
            history.push(`/product/primary/details/${id}`)
            swal("Thông báo", "Đã đặt chỗ thành công!", "success");
        }, (error) => {
            swal("Đặt chỗ thất bại", "Vui lòng thử lại!", "error");
        })
    };

    if (errorCarts) {
        return <Redirect to="/" />
    }

    if (loadingCarts || loadingInsert) {
        return (
            <div className={classes.wapperLoading}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <Paper className={classes.formContainer}>
            <FormControl>
                <Grid container direction="row" style={{ marginBottom: '3em' }}>
                    <Typography className={classes.headerStyle}>Tạo booking mới</Typography>
                </Grid>

                <Grid container className={classes.groupRow} spacing={1} alignItems="center" style={{ marginLeft: 0 }}>
                    <Grid container direction="row" className={classes.gridRow}>
                        <Grid item xs={6} md={3}>
                            <Typography component='span' className={clsx(classes.label)}>
                                {_.get(selectProject, 'title') ? `Dự án ${_.get(selectProject, 'title')}` : ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography component='span' className={clsx(classes.label)}>
                                Mã sản phẩm <span>{_.get(selectProject, 'code', '')}</span>
                            </Typography>
                        </Grid>

                        <Grid item xs={4} md={2}>
                            <Typography component='span' className={clsx(classes.label)}>
                                Đợt giỏ hàng <span style={{ color: 'red' }}>*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={8} md={3}>
                            <FormControl size="small" variant="outlined" fullWidth >
                                <Select
                                    className={classes.form}
                                    id="selectCartId"
                                    value={selectCart}
                                    onChange={(e) => setSelectCart(e.target.value)}
                                    style={{ width: "225px" }}
                                >
                                    <MenuItem value={-1} disabled>Chọn giỏ hàng</MenuItem>
                                    {
                                        _.map(_.get(dataCarts, 'result'), (item, key) => {
                                            if (_.get(item, 'name'))
                                                return (
                                                    <MenuItem key={key} value={_.get(item, 'id')}>{_.get(item, 'name')}</MenuItem>
                                                )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>

                    <Grid container direction="row" className={classes.gridRow} >
                        <Grid item xs={6} md={2}>
                            <Typography component='span' className={clsx(classes.label, 'required')}>Mã khách hàng</Typography>
                        </Grid>
                        <Grid item xs={6} md={4} className={classes.vertical}>
                            <InputCustomerSearch
                                fieldname='customerCode'
                                object={customer}
                                setObject={setCustomer}
                                datalist={customerList}
                                setIsAvailable={setIsAvailable}
                            />
                            {
                                _.isEmpty(customer) && <ValidationNote Obj={errorValid} fieldname='customer' />
                            }

                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Typography
                                className={classes.secondaryButton}
                                onClick={() => history.push(slugs.customerCreate)}
                            >Tạo khách hàng mới</Typography>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" className={classes.gridRow} >
                        <Grid item xs={6} md={2}>
                            <Typography component='span' className={clsx(classes.label)}>Tên khách hàng</Typography>
                        </Grid>
                        <Grid xs={6} md={4} className={classes.vertical}>
                            <InputTextField
                                disabled={!_.isEmpty(customer)}
                                fieldname='name'
                                object={customer}
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography component='span' className={clsx(classes.label)}>Số điện thoại</Typography>
                        </Grid>
                        <Grid item xs={6} md={4} className={classes.vertical}>
                            <InputTextField
                                disabled={!_.isEmpty(customer)}
                                fieldname='phone'
                                object={customer}
                            />
                        </Grid>
                    </Grid>

                    <Grid container direction="row" className={classes.gridRow} >
                        <Grid item xs={6} md={2}>
                            <Typography component='span' className={clsx(classes.label, 'required')}>CMND/CCCD</Typography>
                        </Grid>
                        <Grid xs={6} md={4} className={classes.vertical}>
                            <InputTextField
                                description='CMND/CCCD'
                                disabled={!_.isEmpty(customer) && _.get(customer, 'personalid') && isAvailable}
                                fieldname='personalid'
                                object={customer}
                                setObject={setCustomer}
                                number
                                maxLength={13}
                            />
                            {
                                _.isEmpty(customer?.personalid) &&
                                <ValidationNote Obj={errorValid} fieldname='personalid' />
                            }

                        </Grid>

                        {/* <Grid xs={6} md={2}>
                            <Typography component='span' className={clsx(classes.label, 'required')}>Trạng thái booking</Typography>
                        </Grid>
                        <Grid xs={6} md={4}>
                            <FormControl size="small" variant="outlined" fullWidth >
                                <Select
                                    className={classes.form}
                                    id="statusBookingId"
                                    value={statusBooking}
                                    onChange={(e) => setStatusBooking(e.target.value)}
                                    style={{ width: "225px" }}
                                >
                                    <MenuItem value={-1} disabled>Chọn trạng thái</MenuItem>
                                    {
                                        _.map(_.get(dataBookingStatus, 'result'), (item, key) => 
                                            <MenuItem key={key} value={_.get(item, 'id')}>{_.get(item, 'name')}</MenuItem>    
                                        )
                                    }
                                </Select>
                                
                                {
                                    statusBooking < 0 &&
                                        <ValidationNote Obj={errorValid} fieldname='status' />
                                }
                            </FormControl>
                        </Grid> */}
                    </Grid>

                    {
                        _.map(invoiceObject, (itemInvoice, index) => {
                            const itemKey = index
                            return (
                                <Fragment key={index}>
                                    <Grid container direction="row" className={classes.gridRow} >
                                        <Grid item xs={6} md={2}>
                                            <Typography component='span' className={clsx(classes.label)}>
                                                Số tiền đặt chỗ/ đặt cọc
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4} className={clsx(classes.currencyField)}>
                                            <InputTextFieldCurrency
                                                fieldname='amount'
                                                setArrObject={setInvoiceObject}
                                                arrObject={invoiceObject}
                                                itemKey={itemKey}
                                                isCurrency
                                                number
                                            />
                                        </Grid>
                                        <Grid>
                                            <Typography component='span' className={clsx(classes.label, 'description')}>
                                                đơn vị: VNĐ
                                            </Typography>
                                            {
                                                (!_.isEmpty(_.toString(_.get(itemInvoice, 'amount'))) && _.size(invoiceObject) === itemKey + 1 && itemKey < MAX_SIZE - 1) && (
                                                    <Button
                                                        variant="contained"
                                                        color='primary'
                                                        startIcon={<Add />}
                                                        onClick={addInvoiceHandle}
                                                    >Thêm</Button>
                                                )
                                                // :(
                                                //     <Button 
                                                //         variant="contained"
                                                //         color='secondary'
                                                //         startIcon={<Delete />}
                                                //         onClick={()=>deleteInvoiceHandle(itemKey)}
                                                //     >Xoá</Button>
                                                // )
                                            }

                                        </Grid>
                                    </Grid>

                                    <Grid container direction="row" className={clsx(classes.gridRow, 'image-view')} >
                                        <Grid item xs={6} md={2}>
                                            <Typography component='span' className={clsx(classes.label)}>
                                                Upload ủy nhiệm chi
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4}>
                                            <input
                                                accept="image/*"
                                                id={`invoice-image-${itemKey}`}
                                                type="file"
                                                hidden
                                                onChange={(e) =>
                                                    handlerUpImage(e, itemKey)
                                                }
                                            />
                                            <label htmlFor={`invoice-image-${itemKey}`}>
                                                {!_.get(itemInvoice, 'invoiceImage.url') ? (
                                                    <img src={ICON_UPLOAD} style={{ fontSize: 110, cursor: 'pointer' }} />
                                                )
                                                    : progress ? (
                                                        <CircularProgress />
                                                    )
                                                        :
                                                        (
                                                            <img className={classes.invoiceImageStyle} src={configValue.API_UPLOAD_FILE + _.get(itemInvoice, 'invoiceImage.url')} alt="hoá đơn thanh toán" />
                                                        )}
                                            </label>
                                        </Grid>

                                        <Grid item xs={6} md={2}>
                                            <Typography component='span' className={clsx(classes.label)}>
                                                Thông tin phiếu UNC
                                            </Typography>
                                        </Grid>
                                        <Grid xs={6} md={4} className={classes.vertical}>
                                            <InputTextFieldCurrency
                                                fieldname='invoiceCode'
                                                arrObject={invoiceObject}
                                                setArrObject={setInvoiceObject}
                                                itemKey={itemKey}
                                                maxLength={100}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" className={clsx(classes.gridRow, 'image-view')} >
                                        <Grid item xs={6} md={2}>
                                            <Typography component='span' className={clsx(classes.label)}>
                                                Ngày chuyển tiền
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4}>
                                            <InputDateAndTimePicker setDateTime={(value) => setDateTime(value, index)} />
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )
                        })

                    }


                    <Grid container direction="row" className={classes.gridRow}>
                        <Grid item xs={6} md={2}>
                            <Typography component='span' className={clsx(classes.label, 'required')}>
                                Mã nhân viên ECOE tư vấn
                            </Typography>
                        </Grid>
                        <Grid xs={6} md={4} className={classes.vertical}>
                            <AutoCompleteEmployee isSchedule onChange={setRefEmployeeCode} />
                            {
                                !refEmployeeCode && <ValidationNote Obj={errorValid} fieldname='employee' />
                            }

                        </Grid>

                        <Grid item xs={6} md={2}>
                            <Typography component='span' className={clsx(classes.label, 'required')}>
                                Mã số đặt chỗ từ CĐT
                            </Typography>
                        </Grid>
                        <Grid xs={6} md={4} className={classes.vertical}>
                            <InputTextField
                                fieldname='booking_investor_code'
                                object={draftObject}
                                setObject={setDraftObject}
                                maxLength={100}
                            />
                            {
                                _.isEmpty(draftObject?.booking_investor_code) &&
                                <ValidationNote Obj={errorValid} fieldname='booking_investor_code' />
                            }

                        </Grid>
                    </Grid>

                    <Grid container direction="row" className={classes.gridRow}>
                        <Grid item xs={6} md={2}>
                            <Typography component='span' className={clsx(classes.label, _.includes(MASTER_CART, _.get(INDEX_CART, 'project_cart_status.code')) && 'required')}>
                                Mã SP đặt chỗ/ đặt cọc
                            </Typography>
                        </Grid>
                        <Grid xs={6} md={4} className={classes.vertical}>
                            <AutoCompleteCodeProduct
                                disabled={!_.includes(MASTER_CART, _.get(INDEX_CART, 'project_cart_status.code'))}
                                selectCart={selectCart}
                                onChange={setProductBooked}
                            />
                            {
                                (_.isEmpty(productBooked?.code) && _.includes(MASTER_CART, _.get(INDEX_CART, 'project_cart_status.code'))) &&
                                <ValidationNote Obj={errorValid} fieldname='product' />
                            }
                        </Grid>

                        <Grid item xs={6} md={2}>
                            <Typography component='span' className={clsx(classes.label, _.includes(MASTER_CART, _.get(INDEX_CART, 'project_cart_status.code')) && 'required')}>
                                Mã SP trong ECOE
                            </Typography>
                        </Grid>
                        <Grid xs={6} md={4} className={classes.vertical}>
                            <InputTextField
                                disabled={!_.isEmpty(productBooked) || !_.includes(MASTER_CART, _.get(INDEX_CART, 'project_cart_status.code'))}
                                description={productBooked?.ecoe_code}
                                object={productBooked}
                            />
                            {
                                (_.isEmpty(productBooked?.ecoe_code) && _.includes(MASTER_CART, _.get(INDEX_CART, 'project_cart_status.code'))) &&
                                <ValidationNote Obj={errorValid} fieldname='product' />
                            }
                        </Grid>
                    </Grid>

                    <Grid container direction="row" className={classes.gridRow}>
                        <Grid xs={6} md={2}>
                            <Typography component='div' className={clsx(classes.label)}>Ghi chú</Typography>
                        </Grid>
                        <Grid xs={6} md={6}>
                            <InputTextField
                                description='Ghi chú'
                                fieldname='description'
                                object={draftObject}
                                setObject={setDraftObject}
                                multiline
                                maxLength={100}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={1} alignItems="center" style={{ marginTop: '90px' }} justifyContent={matchesMD ? undefined : 'flex-end'}>
                    <Grid item className={classes.footerStyle}>
                        <Button
                            variant="contained"
                            color='primary'
                            startIcon={<Save />}
                            onClick={handleCreateBooking}
                        >
                            Xác nhận
                        </Button>
                        <ButtonReturn />
                    </Grid>
                </Grid>
            </FormControl>
        </Paper>
    )
}

export default CreateBookingPage;

