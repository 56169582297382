import {
  Grid, IconButton, makeStyles, TextField,
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography,
  Select, MenuItem,
  Checkbox,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { Add, AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../../context/ConfigContext";

const useStyles = makeStyles((theme) => ({
  textLabelForm: {
    fontWeight: "bold",
    paddingRight: "20px",
    '& > p': {
      fontSize: '14px'
    },
  },
  section: {
    background: "#efefef",
    padding: 20,
  },
  styleEventItem: {
    paddingBottom: '30px',
  },
  imgPicker: {
    display: "flex !important",
    background: "#F5F5F5",
    width: "100px",
    height: "100px",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    fontWeight: "bold",
    cursor: "pointer",
    border: "1px solid #B9B9B9",
    borderRadius: "6px",
  },
  styleFormControl: {
    alignItems: 'flex-start'
  },
  styleDropdown: {
    minWidth: '220px',

    '& .MuiSelect-outlined.MuiSelect-outlined': {
      paddingRight: '50px',

    },

    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px'
    },
  },
}));

const CustomRadio = withStyles((theme) => ({
  root: {
    color: '#00837B',
    '&$checked': {
      color: '#00837B',
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);

const CustomCheckbox = withStyles((theme) => ({
  root: {
    color: '#00837B',
    '&$checked': {
      color: '#00837B',
    },
  },
  checked: {},
}))((props) => <Checkbox {...props} />);

const EventItems = ({ eventItems, setEventItems, giftList = [], deleteItems, setDeleteItems, dataSourceType = [] }) => {
  const [inputValues, setInputValues] = useState(eventItems);
  const classes = useStyles();

  const configValue = useContext(ConfigContext);

  const handleAddField = () => {
    setInputValues([
      ...inputValues,
      {
        checkUpdate: true,
        active: true,
        name: "",
        icon: "",
        spin_rate: null,
        spin_ecoin: 0,
        iconUrl: "",
        gift: null,
      },
    ]);
  };

  const handleAddTimer = (index) => {
    let tmp = _.cloneDeep(inputValues);
    if (!_.get(tmp[index], 'calendars')) {
      tmp[index]['calendars'] = [];
    }
    tmp[index]['checkUpdate'] = true;
    tmp[index]['calendars'][_.size(_.get(tmp[index], 'calendars'))] = {};

    setInputValues(tmp);
    setEventItems(tmp);
  };

  const handleRemoveItemTimer = (indexItem, index) => {
    let tmp = _.map(inputValues, (item, key) => {
      if (key === indexItem) {
        return {
          ...item,
          checkUpdate: true,
          calendars: _.remove(_.get(item, 'calendars'), (o, i) => { return i !== index }),
        }
      } else {
        return item;
      }

    })
    setInputValues(tmp);
    setEventItems(tmp);
  };

  useEffect(() => {
    let defaultValue = _.map([...eventItems], (item, key) => {
      return {
        checkUpdate: _.get(item, "checkUpdate"),
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        icon: _.get(item, "icon"),
        spin_rate: _.get(item, "spin_rate"),
        spin_ecoin: _.get(item, "spin_ecoin"),
        gift_type: (_.get(item, 'source_type') > 0) ? ("VOUCHER-PINNOW") : ((_.get(item, "gift") > 0) ? ("GIFT") : ("COIN")),
        gift: _.get(item, "gift"),
        source_type: _.get(item, 'source_type'),
        max_day: _.get(item, 'max_day'),
        active: _.get(item, 'active'),
        calendars: _.get(item, 'calendars') || [],
      };
    });
    setInputValues(defaultValue);
  }, []);

  const handleRemoveField = (index) => {
    if (inputValues.length <= 1) return;
    const fields = [...inputValues];
    setDeleteItems(_.concat(deleteItems, [fields[index]?.id]));
    fields.splice(index, 1);
    setInputValues(fields);
  };

  const handleChangeItemTimer = (indexItem, index, event) => {
    let tmp = _.cloneDeep(inputValues);

    if (_.includes(['start_time', 'end_time'], _.get(event, 'target.name'))) {
      tmp[indexItem]['calendars'][index][_.get(event, 'target.name')] = "2000-01-01 " + _.get(event, 'target.value');
    }
    else {
      tmp[indexItem]['calendars'][index][_.get(event, 'target.name')] = _.get(event, 'target.value');
    }

    tmp[indexItem]['checkUpdate'] = true;

    setInputValues(tmp);
    setEventItems(tmp);
  };

  const handleChangeInput = async (index, event) => {
    const values = [...inputValues];
    if (_.includes(["spin_ecoin", "gift", "max_day"], _.get(event, 'target.name'))) {
      values[index][_.get(event, 'target.name')] = parseInt(_.get(event, 'target.value'));
    }
    // else if (event.target.name === "spin_rate") {
    //   values[index][event.target.name] = event.target.value;
    // }
    else if (_.get(event, 'target.name') === "icon") {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("media_type_code", "ICON");
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const { data } = await axios.post(
          configValue.API_UPLOAD_FILE,
          formData,
          config
        );

        // values[index][event.target.name] = data[0].id;
        // inputValues[index]["iconUrl"] = data[0].url;
        values[index][_.get(event, 'target.name')] = data[0];
        values[index]['checkUpdate'] = true;
        setInputValues(values);
        setEventItems(values);
      } catch (error) {
        console.log(error);
      }
    } else values[index][_.get(event, 'target.name')] = _.get(event, 'target.value');

    values[index]['checkUpdate'] = true;

    if (_.includes(["gift", "source_type"], _.get(event, 'target.name'))) {
      setEventItems(values);
    }

    setInputValues(values);
  };

  const onBlurInput = () => {
    //set value on pCpn
    setEventItems([...inputValues]);
  };

  const handleChangeGiftType = (index, event) => {
    const values = [...inputValues];
    values[index]['gift_type'] = event.target.value;
    values[index]['spin_ecoin'] = 0;
    values[index]['gift'] = null;
    values[index]['source_type'] = null;
    values[index]['checkUpdate'] = true;
    setInputValues(values);
    setEventItems(values);
  };

  const handleChangeActive = (index, event) => {
    let tmp = _.cloneDeep(inputValues);
    tmp[index]['checkUpdate'] = true;
    tmp[index][_.get(event, 'target.name')] = !_.get(event, 'target.checked');
    setInputValues(tmp);
    setEventItems(tmp);
  };

  return (
    <div>
      {_.map(inputValues, (item, key) => {
        const keyItem = key;
        return (
          <Grid container key={key} alignItems="center" spacing={3} className={classes.styleEventItem}>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Grid item container>
                  <Grid item className={classes.textLabelForm}>
                    <p style={{ fontSize: "14px" }}>{`Hình ảnh ${key + 1}`}</p>
                  </Grid>
                  <Grid item>
                    {inputValues.length > 1 && (
                      <IconButton onClick={() => handleRemoveField(key)}>
                        <RemoveCircleOutline style={{ fill: "red" }} />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item className={classes.textLabelForm}>
                <Grid item container>
                  <CustomCheckbox
                    checked={!_.get(item, 'active')}
                    name="active"
                    onChange={(e) => handleChangeActive(key, e)}
                  />
                  <p style={{ fontSize: "14px" }}>Tạm dừng phần thưởng</p>
                </Grid>
              </Grid>
            </Grid>

            <Grid item container>
              <Grid item xs={2} className={classes.textLabelForm}>
                <p>
                  Tên hình ảnh<span style={{ color: "red" }}>*</span>
                </p>

                <TextField
                  size="small"
                  variant="outlined"
                  className={classes.input}
                  required
                  value={_.get(item, 'name')}
                  name="name"
                  onChange={(e) => handleChangeInput(key, e)}
                  onBlur={onBlurInput}
                  disabled={!_.get(item, 'active')}
                />
              </Grid>

              <Grid item container xs={10}>
                <Grid item className={classes.textLabelForm}>
                  <p>Loại quà<span style={{ color: "red" }}>*</span></p>
                </Grid>

                <Grid item>
                  <FormControl component="fieldset" disabled={!_.get(item, 'active')}>
                    <RadioGroup aria-label="gender" name={`gift_${key}`} value={_.toString(_.get(item, 'gift_type'))} onChange={(e) => handleChangeGiftType(key, e)}
                      style={{ flexDirection: 'row' }}
                    >
                      <FormControlLabel
                        className={classes.styleFormControl}
                        value="COIN"
                        control={<CustomRadio />}
                        label={
                          <div>
                            <Grid item className={classes.textLabelForm}>
                              <p>Số Ecoin quy đổi</p>
                            </Grid>
                            <Grid item>
                              <TextField
                                disabled={_.toString(_.get(item, 'gift_type')) !== 'COIN' || !_.get(item, 'active')}
                                type="number"
                                size="small"
                                variant="outlined"
                                className={classes.input}
                                name="spin_ecoin"
                                value={_.get(item, 'spin_ecoin')}
                                onChange={(e) => handleChangeInput(key, e)}
                                onBlur={onBlurInput}
                              />
                            </Grid>
                          </div>
                        }
                      />
                      <FormControlLabel
                        className={classes.styleFormControl}
                        value="GIFT"
                        control={<CustomRadio />}
                        label={
                          <div>
                            <Grid item className={classes.textLabelForm}>
                              <p>Loại giải thưởng</p>
                            </Grid>
                            <Grid item>
                              <FormControl
                                className={classes.styleDropdown}
                                variant="outlined"
                                disabled={_.toString(_.get(item, 'gift_type')) !== 'GIFT' || !_.get(item, 'active')}
                              >
                                <Select
                                  id={`input-dropdown-${key}`}
                                  value={_.get(item, 'gift')}
                                  name="gift"
                                  onChange={(e) => handleChangeInput(key, e)}
                                >
                                  <MenuItem value="" disabled>Chọn voucher</MenuItem>
                                  {
                                    _.map(giftList, (item, key) => {
                                      return <MenuItem key={key} value={_.get(item, 'id')}>{_.get(item, 'name')}</MenuItem>
                                    })
                                  }
                                </Select>
                              </FormControl>
                            </Grid>

                          </div>
                        }
                      />

                      <FormControlLabel
                        className={classes.styleFormControl}
                        value="VOUCHER-PINNOW"
                        control={<CustomRadio />}
                        label={
                          <div>
                            <Grid item className={classes.textLabelForm}>
                              <p>Voucher Pinnow</p>
                            </Grid>
                            <Grid item>
                              <FormControl
                                className={classes.styleDropdown}
                                variant="outlined"
                                disabled={_.toString(_.get(item, 'gift_type')) !== 'VOUCHER-PINNOW' || !_.get(item, 'active')}
                              >
                                <Select
                                  id={`input-pinnow-dropdown-${key}`}
                                  value={_.get(item, 'source_type')}
                                  name="source_type"
                                  onChange={(e) => handleChangeInput(key, e)}
                                >
                                  <MenuItem value="" disabled>Chọn voucher</MenuItem>
                                  {
                                    _.map(dataSourceType, (item, key) => {
                                      return <MenuItem key={key} value={_.get(item, 'id')}>{_.get(item, 'name')}</MenuItem>
                                    })
                                  }
                                </Select>
                              </FormControl>
                            </Grid>

                          </div>
                        }
                      />

                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

            </Grid>

            <Grid item container>
              <Grid item xs={2} className={classes.textLabelForm}>
                <p>
                  Hình ảnh<span style={{ color: "red" }}>*</span>
                </p>

                {_.isEmpty(_.get(item, "icon.url")) ? (
                  <>
                    <input
                      id={`icon-item-${key}`}
                      hidden
                      type="file"
                      name="icon"
                      accept="image/*"
                      multiple
                      className={classes.inputStyle}
                      onChange={(e) => handleChangeInput(key, e)}
                      disabled={!_.get(item, 'active')}
                    />
                    <label
                      htmlFor={`icon-item-${key}`}
                      className={classes.imgPicker}
                    >
                      Thêm ảnh <Add />
                    </label>{" "}
                  </>
                ) : (
                  <>
                    <input
                      id={`icon-item-${key}`}
                      hidden
                      type="file"
                      name="icon"
                      accept="image/*"
                      multiple
                      className={classes.inputStyle}
                      onChange={(e) => handleChangeInput(key, e)}
                      disabled={!_.get(item, 'active')}
                    />
                    <label
                      htmlFor={`icon-item-${key}`}
                      className={classes.imgPicker}
                    >
                      <img
                        style={{ height: 100, width: 100 }}
                        alt="icon"
                        src={configValue.MEDIA_DOMAIN + _.get(item, 'icon.url')}
                      />
                    </label>
                  </>
                )}
              </Grid>

              <Grid item container xs={10} spacing={2}>
                <Grid item className={classes.textLabelForm}>
                  <p>Tỷ lệ trúng</p>
                </Grid>
                <Grid item md={2}>
                  <TextField
                    type="number"
                    size="small"
                    variant="outlined"
                    className={classes.input}
                    name="spin_rate"
                    value={_.get(item, 'spin_rate')}
                    onChange={(e) => handleChangeInput(key, e)}
                    onBlur={onBlurInput}
                    disabled={!_.get(item, 'active')}
                  />
                </Grid>

                <Grid item className={classes.textLabelForm}>
                  <p>SL trúng tối đa/ngày</p>
                </Grid>
                <Grid item md={2}>
                  <TextField
                    type="number"
                    size="small"
                    variant="outlined"
                    className={classes.input}
                    name="max_day"
                    value={_.get(item, 'max_day')}
                    onChange={(e) => handleChangeInput(key, e)}
                    onBlur={onBlurInput}
                    disabled={!_.get(item, 'active')}
                  />
                </Grid>

                <Grid item container xs={12} spacing={2}>
                  <Grid item className={classes.textLabelForm}>
                    <p>Thời gian</p>
                  </Grid>

                  <Grid item>
                    {
                      _.map(_.get(item, 'calendars'), (item, key) => {
                        return (
                          <Grid key={key} item container spacing={2} alignItems="center">
                            <Grid item style={{ margin: '14px 0' }}>từ</Grid>
                            <Grid item>
                              <TextField
                                id={`start_time-calendars-item-${key}`}
                                type="time"
                                size="small"
                                variant="outlined"
                                value={_.get(item, 'start_time') ? moment(_.get(item, 'start_time')).format("HH:mm") : undefined}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: 300,
                                }}
                                name="start_time"
                                onChange={(e) => handleChangeItemTimer(keyItem, key, e)}
                                disabled={!_.get(inputValues[keyItem], 'active')}
                              />
                            </Grid>

                            <Grid item style={{ margin: '14px 0' }}>đến</Grid>
                            <Grid item>
                              <TextField
                                id={`end_time-calendars-item-${key}`}
                                type="time"
                                size="small"
                                variant="outlined"
                                value={_.get(item, 'end_time') ? moment(_.get(item, 'end_time')).format("HH:mm") : undefined}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: 300,
                                }}
                                name="end_time"
                                onChange={(e) => handleChangeItemTimer(keyItem, key, e)}
                                disabled={!_.get(inputValues[keyItem], 'active')}
                              />
                            </Grid>

                            <Grid item>
                              <TextField
                                id={`start-calendars-item-${key}`}
                                type="date"
                                size="small"
                                variant="outlined"
                                // value={moment(_.get(item, 'start') ? _.get(item, 'start') : new Date(), "YYYY-MM-DD").format('YYYY-MM-DD')}
                                value={_.get(item, 'start') ? moment(_.get(item, 'start'), "YYYY-MM-DD").format('YYYY-MM-DD') : undefined}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                name="start"
                                onChange={(e) => handleChangeItemTimer(keyItem, key, e)}
                                disabled={!_.get(inputValues[keyItem], 'active')}
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                id={`end-calendars-item-${key}`}
                                type="date"
                                size="small"
                                variant="outlined"
                                // value={moment(_.get(item, 'end') ? _.get(item, 'end') : new Date(), "YYYY-MM-DD").format('YYYY-MM-DD')}
                                value={_.get(item, 'end') ? moment(_.get(item, 'end'), "YYYY-MM-DD").format('YYYY-MM-DD') : undefined}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                name="end"
                                onChange={(e) => handleChangeItemTimer(keyItem, key, e)}
                                disabled={!_.get(inputValues[keyItem], 'active')}
                              />
                            </Grid>

                            <Grid item>
                              <IconButton onClick={() => handleRemoveItemTimer(keyItem, key)} disabled={!_.get(inputValues[keyItem], 'active')}>
                                <RemoveCircleOutline style={{ fill: "red" }} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )
                      })
                    }
                  </Grid>

                  <Grid item>
                    <Grid item container spacing={2} alignItems="center">
                      <Grid item>
                        <IconButton onClick={() => handleAddTimer(key)} disabled={!_.get(item, 'active')}>
                          <AddCircleOutline style={{ fill: "blue" }} /> <span style={{ paddingLeft: '12px' }}>Thêm mới</span>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        )
      })}

      <Grid container direction="column" spacing={6}>
        <Grid item xs={12}>
          <IconButton onClick={() => handleAddField()}>
            <AddCircleOutline style={{ fill: "blue" }} /> <span style={{ paddingLeft: '12px' }}>Thêm mới</span>
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default EventItems;
