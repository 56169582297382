import {
  Grid, Typography, Button, TextField,
  RadioGroup, Radio, FormControlLabel, CircularProgress, IconButton,
} from "@material-ui/core";
import { Edit, Cancel, Check, RemoveCircleOutline, CloudUploadTwoTone } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import EventGiftsTable from "../EventGiftsTable";
import { useParams } from "react-router";
import { useGetGiftTypes, useGetSourceType, useUpdateEvent } from "../../hooks";
import EventItems from "../../create/EventItems";
import EventGiftsVouchers from "../../create/EventGiftsVouchers";
import Upload from "../../components/Upload";
import InputDateAndTimePicker from "../../../../components/InputDateAndTimePicker";
import InputTextField from "../../../Product/Secondary/components/InputTextField";
import { INSERT_YOUTUBE_MEDIA } from "../../../../graphql/schemas/event/eventMutation";
import { useMutation } from "@apollo/client";
import swal from 'sweetalert';
import axios from "axios";
import EventJackSchedules from "../../create/EventJackSchedules";
import { ConfigContext } from "../../../../context/ConfigContext";
import { UPDATE_PROMOTION, USER_ROLE_SUPPER_ADMIN } from "../../../../constants/defined";

import AccessDenied from '../../../../pages/Permission/AccessDenied';
import { StoreContext } from "../../../../resources/store";
import { checkedPermission } from "../../../../helper";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: "bold",
  },
  space: {
    width: 10,
    height: 50,
  },
  marginLeftSpace: {
    marginLeft: 20,
  },
  textUnderLine: {
    textDecoration: "underline",
  },
  flex: {
    display: "flex",
  },
  widthTitle: {
    width: 280,
  },
  icon: {
    width: 100,
    height: "auto",
    objectFit: "contain",
  },
  wapperLoading: {
    width: '100%',
    height: 'calc(100vh - 113px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabledStyle: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(254, 254, 254, 0.8)',
    border: '1px solid #ccc',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    "& > h2": {
      fontSize: '2rem',
      color: '#00837B',
    },
  },
  wrapperTable: {
    '& > div:first-child': {
      background: '#eee'
    },
    '& > div:not(:last-child)': {
      marginBottom: '15px'
    },
    '& > div:not(:first-child)': {
      borderBottom: '1px #eee solid',
      borderLeft: '1px #eee solid',
      borderRight: '1px #eee solid',
    }
  },
  textLabelForm: {
    fontWeight: "bold",
  },
}));

const CustomRadio = withStyles((theme) => ({
  root: {
    color: '#00837B',
    '&$checked': {
      color: '#00837B',
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);

const JackGameDetail = ({ data, loading }) => {
  const classes = useStyles();

  const { id } = useParams();

  const { loading: loadingSourceType, error: errorSourceType, data: dataSourceType } = useGetSourceType();
  const [isEdit, setIsEdit] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [name, setName] = useState("");
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const [eventGiftsVouchers, setEventGiftsVouchers] = useState({
    vouchers: [],
    eventGifts: [],
  });

  const [eventItems, setEventItems] = useState([]);
  const [deleteItems, setDeleteItems] = useState([]);

  const [ruleGameUpload, setRuleGameUpload] = useState([]);
  const [ruleGameUpdate, setRuleGameUpdate] = useState([]);

  const [guideGameUpload, setGuideGameUpload] = useState([]);
  const [guideGameUpdate, setGuideGameUpdate] = useState([]);

  const [typeVideoUpload, setTypeVideoUpload] = useState('LINK');
  const [videoUpload, setVideoUpload] = useState([]);
  const [videoUpdate, setVideoUpdate] = useState([]);
  const [errorUploadVideo, setErrorUploadVideo] = useState("");
  const [loadingVideoUpload, setLoadingVideoUpload] = useState(false);
  const [dataEventJackSchedules, setDataEventJackSchedules] = useState([]);

  const configValue = useContext(ConfigContext);
  const { userInfo, accessObjectPermissions } = useContext(StoreContext);

  const { updateEvent, data: updatedData, loading: loadingUpdate } = useUpdateEvent(id, setIsEdit, setDisableButton);
  const { data: listGiftType } = useGetGiftTypes();

  const [insertLinkYoutubeMedia, { data: dataInsertMedia, loading: loadingInsertMedia, error: errorInsertMedia }] = useMutation(INSERT_YOUTUBE_MEDIA, {
    onCompleted: (data) => {
      setVideoUpdate(_.concat(videoUpdate, [_.get(data, 'media')]))
    },
    onError: (err) => {
      return swal({
        title: "Kiểm tra lại link video",
        icon: "error",
      })
    },
  });

  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];
    if (file?.size > 5e6) {
      setErrorUploadVideo("Dung lượng file tối đa cho phép là 20MB");
      return false;
    }
    if (_.size(listVideoView(videoUpload, videoUpdate)) === 5) {
      setErrorUploadVideo("Chỉ cho phép tối đa 5 file");
      return false;
    }
    setLoadingVideoUpload(true);
    setErrorUploadVideo("");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("media_type_code", "VIDEO");
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        configValue.API_UPLOAD_FILE,
        formData,
        config
      );
      setVideoUpdate([...videoUpdate, data[0]]);
      setLoadingVideoUpload(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveField = (index, item) => {
    let tmp = [...videoUpdate];

    if (_.find(videoUpload, { 'id': _.get(item, 'id') })) {
      tmp = [...videoUpdate, item];
    } else {
      tmp = _.remove(videoUpdate, o => o?.id !== item?.id);
    }
    setVideoUpdate(tmp);
  };

  const handleUpdate = () => {
    setDisableButton(true);
    let arg = {};

    arg.id = parseInt(id);

    arg.name = name;
    if (dateStart) {
      arg.start = dateStart;
    }
    if (dateEnd) {
      arg.end = dateEnd;
    }

    const media = [...ruleGameUpdate, ...videoUpdate, ...guideGameUpdate];
    const updatedMediaId = media.map((item) => item.id);
    const uniq = [...new Set(updatedMediaId)];
    const eventMedia = uniq.map((item) => ({ media: item }));
    const schedules = _.filter(dataEventJackSchedules, (obj) => {
      return _.get(obj, 'start') && _.get(obj, 'end')
    });

    setRuleGameUpdate([]);
    setGuideGameUpdate([]);
    setVideoUpdate([]);

    arg.event_medias_update = eventMedia;
    arg.event_items_update = [
      ..._.map(_.filter(eventItems, o => _.get(o, 'checkUpdate') === true && !_.includes(deleteItems, _.get(o, 'id'))), (item) => {
        if (item?.id)
          return {
            id: item?.id,
            name: item?.name,
            icon: _.get(item, 'icon.id'),
            spin_rate: (item?.spin_rate) * 1,
            spin_ecoin: item?.spin_ecoin || 0,
            gift: item?.gift || null,
            source_type: item?.source_type || null,
            max_day: item?.max_day || 0,
            active: item?.active === false ? false : true,
            calendars: _.map(item?.calendars, (o) => {
              return {
                start_time: moment(_.get(o, 'start_time')).utc().format(),
                end_time: moment(_.get(o, 'end_time')).utc().format(),
                start: _.get(o, 'start'),
                end: _.get(o, 'end'),
              }
            }),
          };
        else
          return {
            name: item?.name,
            icon: _.get(item, 'icon.id'),
            spin_rate: (item?.spin_rate) * 1,
            spin_ecoin: item?.spin_ecoin || 0,
            gift: item?.gift || null,
            source_type: item?.source_type || null,
            max_day: item?.max_day || 0,
            active: item?.active === false ? false : true,
            calendars: _.map(item?.calendars, (o) => {
              return {
                start_time: moment(_.get(o, 'start_time')).utc().format(),
                end_time: moment(_.get(o, 'end_time')).utc().format(),
                start: _.get(o, 'start'),
                end: _.get(o, 'end'),
              }
            }),
          };
      }),
      ..._.map(deleteItems, item => {
        return {
          id: item,
          deleted: true,
        }
      })
    ];

    arg.event_gifts_update = [
      ..._.map(_.filter(_.get(eventGiftsVouchers, 'eventGifts'), ['isUpdate', true]), (item) => {
        if (item?.id)
          return {
            id: item?.id,
            name: item?.name,
            title: item?.title,
            description: item?.description,
            image: item?.image?.id,
            start: moment(item?.start, "YYYY-MM-DD[T]HH-mm").format(
              "YYYY-MM-DD"
            ),
            end: moment(item?.end, "YYYY-MM-DD[T]HH-mm").format("YYYY-MM-DD"),
            expire_day: item?.expire_day,
            max_redeem: item?.max_redeem,
            redeem_ecoin: +item?.redeem_ecoin,
            type: _.get(_.find(listGiftType, ["code", "GIFT"]), "id"),
            background_color: item?.background_color || 'none',
            title_color: item?.title_color || '#000',
          };
        else
          return {
            name: item?.name,
            title: item?.title,
            description: item?.description,
            image: _.get(item, 'image.id'),
            start: moment(item?.start, "YYYY-MM-DD[T]HH-mm").format(
              "YYYY-MM-DD"
            ),
            end: moment(item?.end, "YYYY-MM-DD[T]HH-mm").format("YYYY-MM-DD"),
            expire_day: item?.expire_day,
            max_redeem: item?.max_redeem,
            redeem_ecoin: +item?.redeem_ecoin,
            type: _.get(_.find(listGiftType, ["code", "GIFT"]), "id"),
            background_color: item?.background_color || 'none',
            title_color: item?.title_color || '#000',
          };
      }),

      ..._.map(_.filter(_.get(eventGiftsVouchers, 'vouchers'), ['isUpdate', true]), (item) => {
        if (item?.id)
          return {
            id: item?.id,
            name: item?.name,
            title: item?.title,
            description: item?.description,
            image: _.get(item, 'image.id'),

            start: moment(item?.start, "YYYY-MM-DD[T]HH-mm").format("YYYY-MM-DD"),
            end: moment(item?.end, "YYYY-MM-DD[T]HH-mm").format("YYYY-MM-DD"),
            expire_day: item?.expire_day,
            max_redeem: item?.max_redeem,

            prefix: item?.prefix,
            postfix: item?.postfix,
            reward: item?.reward,

            type: _.get(_.find(listGiftType, ["code", "VOUCHER"]), "id"),
            background_color: item?.background_color || 'none',
            title_color: item?.title_color || '#000000',
          };
        else
          return {
            name: item?.name,
            title: item?.title,
            description: item?.description,
            image: _.get(item, 'image.id'),

            start: moment(item?.start, "YYYY-MM-DD[T]HH-mm").format("YYYY-MM-DD"),
            end: moment(item?.end, "YYYY-MM-DD[T]HH-mm").format("YYYY-MM-DD"),
            expire_day: item?.expire_day,
            max_redeem: item?.max_redeem,

            prefix: item?.prefix,
            postfix: item?.postfix,
            reward: item?.reward,

            type: _.get(_.find(listGiftType, ["code", "VOUCHER"]), "id"),
            background_color: item?.background_color || 'none',
            title_color: item?.title_color || '#000000',
          };
      }),
    ];

    arg.event_jack_schedules = _.map(schedules, (item) => item)

    updateEvent({
      variables: {
        arg,
      },
    });
  };

  useEffect(() => {
    dataInital();
  }, [data]);

  const listVideoView = (videoUpload, videoUpdate) => {
    let arr = [];
    arr = _.unionBy(_.concat(videoUpload, videoUpdate), 'id');
    arr = _.filter(arr, item => (
      (_.includes(_.map(videoUpload, o => _.get(o, 'id')), _.get(item, 'id')) && !_.includes(_.map(videoUpdate, o => _.get(o, 'id')), _.get(item, 'id')))
      ||
      (!_.includes(_.map(videoUpload, o => _.get(o, 'id')), _.get(item, 'id')) && _.includes(_.map(videoUpdate, o => _.get(o, 'id')), _.get(item, 'id')))
    ))
    return arr;
  };

  const dataInital = () => {
    let vouchersObj = _.map(data?.event_gifts, (item) => ({ ...item?.gifts, 'isUpdate': false })).filter(
      (item) => _.get(item, 'gift_type.code') === "VOUCHER"
    );
    let giftsObj = _.map(data?.event_gifts, (item) => ({ ...item?.gifts, 'isUpdate': false })).filter(
      (item) => _.get(item, 'gift_type.code') === "GIFT"
    );
    // not allow to update voucher => vouchers = empty
    setEventGiftsVouchers({ eventGifts: giftsObj, vouchers: vouchersObj });

    //let eventItemsObj = _.map(data?.event_items, (item) => item.items);
    let eventItemsObj = _.map(data?.event_items, (item) => {
      let tmp = JSON.parse(JSON.stringify(item?.items));
      delete tmp?.__typename;
      return { ...tmp, checkUpdate: false }
    });

    let rule = _.map(_.get(data, "event_medias"), (item) => item?.media).filter(
      (item) => _.get(item, 'media_type.code') === "RULE_GAME"
    );
    let guide = _.map(_.get(data, "event_medias"), (item) => item?.media).filter(
      (item) => _.get(item, 'media_type.code') === "GUIDE_GAME"
    );
    let video = _.map(_.get(data, "event_medias"), (item) => item?.media).filter(
      (item) => _.includes(["YOUTUBE", "VIDEO"], _.get(item, "media_type.code"))
    );

    let eventJackSchedules = _.map(_.get(data, 'event_jack_schedules'), (item) => {
      let tmp = JSON.parse(JSON.stringify(item));
      delete tmp?.__typename;
      return tmp
    });

    setEventItems(eventItemsObj);
    setRuleGameUpload(rule);
    setGuideGameUpload(guide);
    setVideoUpload(video);
    setDataEventJackSchedules(eventJackSchedules);
  };



  if (loading || loadingUpdate) return (
    <div className={classes.wapperLoading}>
      <CircularProgress />
    </div>
  )

  if (isEdit && !checkedPermission(userInfo, accessObjectPermissions, UPDATE_PROMOTION)) {
    return <AccessDenied />
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      style={{ marginTop: 40 }}
    >

      <Grid container direction="row">
        <Grid item xs={4}>
          <Typography className={clsx(classes.bold, classes.widthTitle)}>
            Tên chương trình: {JSON.stringify(_.get(accessObjectPermissions, 'UPDATE_PROMOTION'))}
            {isEdit ? (
              <TextField
                // disabled={true}
                style={{ backgroundColor: "#ffffff", width: 250 }}
                variant="outlined"
                value={name}
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
                size="small"
                required
              />
            ) : (
              <Typography
                className={clsx(classes.marginLeftSpace)}
                component="span"
              >
                {_.get(data, "name")}
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            className={clsx(
              classes.bold,
              classes.widthTitle,
              classes.marginLeftSpace
            )}
          >
            Mã chương trình:
            <Typography
              className={clsx(classes.marginLeftSpace)}
              component="span"
            >
              {_.get(data, "code")}
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={clsx(classes.bold, classes.widthTitle)}>
            Loại chương trình:
            <Typography
              className={clsx(classes.marginLeftSpace)}
              component="span"
            >
              {_.get(data, "type_info.name")}
            </Typography>
          </Typography>
        </Grid>
      </Grid>

      <Grid className={classes.space}></Grid>
      <Grid container item direction="row" alignItems="center">
        <Grid item xs={4}>
          <Typography className={clsx(classes.bold, classes.widthTitle)}>
            Tổng số coin được tạo:
            <Typography
              className={clsx(classes.bold, classes.marginLeftSpace)}
              component="span"
            >
              {_.get(data, "total_ecoin.aggregate.sum.ecoin") +
                _.get(data, "total_ecoin.aggregate.sum.room_ecoin")}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            className={clsx(
              classes.bold,
              classes.marginLeftSpace,
              classes.widthTitle
            )}
          >
            Số phòng game đã tạo:
            <Typography
              className={clsx(classes.bold, classes.marginLeftSpace)}
              component="span"
            >
              {_.get(data, "total_room.aggregate.count")}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.space}></Grid>
      <Grid container direction="row">
        <Grid item xs={4}>
          <Typography className={clsx(classes.bold, classes.widthTitle)}>
            Số phòng game đang chơi:{" "}
            <Typography
              className={clsx(classes.bold, classes.marginLeftSpace)}
              component="span"
            >
              {_.get(data, "rooms_running.aggregate.count")}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={clsx(classes.bold, classes.marginLeftSpace)}>
            Số lượng giải thưởng đã trao:{" "}
            <Typography
              className={clsx(classes.bold, classes.marginLeftSpace)}
              component="span"
            >
              {_.get(data, "total_change_gift.aggregate.count")}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.space}></Grid>
      <Grid container direction="row">
        <Grid item xs={4}>
          <Typography className={clsx(classes.bold, classes.widthTitle)}>
            Ngày tạo:{" "}
            <Typography
              className={clsx(classes.marginLeftSpace)}
              component="span"
            >
              {moment(_.get(data, "created")).format("DD/MM/yyyy")}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            className={clsx(
              classes.bold,
              classes.marginLeftSpace,
              classes.widthTitle
            )}
          >
            Người tạo:{" "}
            <Link
              className={clsx(classes.marginLeftSpace, classes.textUnderLine)}
              to={`/employee/detail/${_.get(data, "created_by")}`}
            >
              E0001
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.space}></Grid>
      <Grid item container direction="row" alignItems="center">
        <Grid item xs={6}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Typography className={clsx(classes.bold)} style={{ paddingRight: '16px' }}>Thời gian bắt đầu:</Typography>
            </Grid>
            {
              (isEdit && moment(_.get(data, "start")).unix() > moment(new Date()).unix()) ? (
                <InputDateAndTimePicker setDateTime={setDateStart} defaultValue={_.get(data, "start")} />
              )
                :
                (
                  <Grid item>
                    <Typography
                      className={clsx(classes.marginLeftSpace)}
                      component="span"
                    >
                      {moment(_.get(data, "start")).format("DD/MM/yyyy HH:mm:ss A")}
                    </Typography>
                  </Grid>
                )
            }
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Typography className={clsx(classes.bold)} style={{ paddingRight: '16px' }}>Thời gian kết thúc:</Typography>
            </Grid>
            {
              (isEdit && moment(_.get(data, "end")).unix() > moment(new Date()).unix()) ? (
                <InputDateAndTimePicker setDateTime={setDateEnd} defaultValue={_.get(data, "end")} />
              )
                :
                (
                  <Grid item>
                    <Typography
                      className={clsx(classes.marginLeftSpace)}
                      component="span"
                    >
                      {moment(_.get(data, "end")).format("DD/MM/yyyy HH:mm:ss A")}
                    </Typography>
                  </Grid>
                )
            }
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.space}></Grid>

      <Grid container direction="row" spacing={3}>
        <Grid item xs={6}>
          <Typography className={clsx(classes.bold)} style={{ paddingBottom: '1rem' }}>Thể lệ:</Typography>
          {isEdit ? (
            <Upload
              type="RULE_GAME"
              uploadData={ruleGameUpload}
              setUploadData={setRuleGameUpload}
              fileChange={ruleGameUpdate}
              setFileChange={setRuleGameUpdate}
              limit={1}
              id={`upload-rule${id}`}
            />
          )
            :
            (
              <Grid item xs={12}>
                {_.map(_.get(data, "event_medias"), (item, key) => {
                  if (item.media?.media_type.code === "RULE_GAME")
                    return (
                      <Typography className={clsx(classes.marginLeftSpace)}>
                        <a
                          href={
                            configValue.MEDIA_DOMAIN +
                            _.get(item, "media.url")
                          }
                          key={key}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {_.get(item, "media.name")}
                        </a>
                      </Typography>
                    );
                })}
              </Grid>
            )}
        </Grid>

        <Grid item xs={6}>
          <Typography className={clsx(classes.bold)} style={{ paddingBottom: '1rem' }}>Hướng dẫn:</Typography>

          {isEdit ? (
            <Grid item>
              <Upload
                type="GUIDE_GAME"
                uploadData={guideGameUpload}
                setUploadData={setGuideGameUpload}
                limit={5}
                id={`upload-guide${id}`}
                fileChange={guideGameUpdate}
                setFileChange={setGuideGameUpdate}
              />
            </Grid>
          )
            :
            (
              <Grid item xs={12}>
                {_.map(_.get(data, "event_medias"), (item, key) => {
                  if (item.media?.media_type.code === "GUIDE_GAME")
                    return (
                      <Typography className={clsx(classes.marginLeftSpace)}>
                        <a
                          href={
                            configValue.MEDIA_DOMAIN +
                            _.get(item, "media.url")
                          }
                          key={key}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {_.get(item, "media.name")}
                        </a>
                      </Typography>
                    );
                })}
              </Grid>
            )}
        </Grid>
        <Grid className={classes.space}></Grid>
      </Grid>

      <Grid item xs={8}>
        <Grid container direction="row">
          <Grid item>
            <Typography className={clsx(classes.bold)}>Video:</Typography>
            {
              isEdit && <span style={{ color: 'grey' }}>Tối đa 5 file</span>
            }
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 15 }}>
            {
              !isEdit ? (
                <Grid container direction="row" spacing={3}>
                  {
                    _.map(_.get(data, "event_medias"), (item, key) => {
                      if (item?.media?.media_type.code === "VIDEO" || item?.media?.media_type.code === "YOUTUBE")
                        return (
                          <Grid item xs={4}>
                            <ReactPlayer
                              url={
                                configValue.MEDIA_DOMAIN +
                                _.get(item, "media.url")
                              }
                              width="auto"
                              key={key}
                              controls={true}
                            />
                            <Grid className={classes.space}></Grid>
                          </Grid>
                        );
                    })
                  }
                </Grid>
              )
                :
                (
                  <Grid direction="column" container>
                    <Grid item>
                      <RadioGroup
                        style={{ flexDirection: 'row' }}
                        aria-label="game-video"
                        name="game-video-option"
                        value={typeVideoUpload}
                        onChange={(e) => setTypeVideoUpload(e.target.value)}
                      >
                        <FormControlLabel value="LINK" control={<CustomRadio />} label="Link youtube" />
                        <FormControlLabel value="UPLOAD" control={<CustomRadio />} label="Upload video" />
                      </RadioGroup>
                    </Grid>
                    {
                      typeVideoUpload === "LINK" ? (
                        <Grid item>
                          {
                            _.map(listVideoView(videoUpload, videoUpdate), (item, key) => {
                              return (
                                <Grid key={key} direction="row" container spacing={3}>
                                  <Grid item className={classes.textLabelForm} xs={1}>
                                    <p>{`Link ${key + 1}`}</p>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      id={`add-link-video-${key}`}
                                      variant="outlined"
                                      fullWidth
                                      disabled
                                      value={_.get(item, 'provider') ? configValue.MEDIA_DOMAIN + _.get(item, "url") : _.get(item, 'url')}
                                    />
                                  </Grid>
                                  <Grid item xs={1}>
                                    <IconButton
                                      onClick={() => handleRemoveField(key, item)}
                                    >
                                      <RemoveCircleOutline style={{ fill: "red" }} />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              )
                            })
                          }

                          {
                            _.size(listVideoView(videoUpload, videoUpdate)) < 5 && (
                              loadingInsertMedia ? (
                                <CircularProgress />
                              )
                                :
                                (
                                  <Grid direction="row" container spacing={3}>
                                    <Grid item className={classes.textLabelForm} xs={1}>
                                      <p>{`Link ${_.size(listVideoView(videoUpload, videoUpdate)) + 1}`}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        id="add-link-video"
                                        placeholder="link youtube"
                                        variant="outlined"
                                        fullWidth
                                        value={null}
                                        onBlur={
                                          (e) => {
                                            if (_.get(e, 'target.value')) {
                                              insertLinkYoutubeMedia({
                                                variables: {
                                                  url: _.get(e, 'target.value')
                                                }
                                              })
                                            }
                                          }
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                )
                            )
                          }
                        </Grid>
                      )
                        :
                        (
                          <Grid item xs={12}>
                            <Grid container direction="row" spacing={3}>
                              {_.map(listVideoView(videoUpload, videoUpdate), (item, key) => (
                                <Grid item xs={4} key={key} style={{ position: 'relative' }}>
                                  <ReactPlayer
                                    url={configValue.MEDIA_DOMAIN + _.get(item, "url")}
                                    width="360"
                                    controls={true}
                                  />

                                  <IconButton
                                    onClick={() => handleRemoveField(key, item)}
                                    style={{ position: 'absolute', top: 10, right: 10 }}
                                  >
                                    <RemoveCircleOutline style={{ fill: "red" }} />
                                  </IconButton>
                                </Grid>
                              ))}

                              {
                                _.size(listVideoView(videoUpload, videoUpdate)) < 5 && (
                                  <Grid item xs={4}>
                                    <div>
                                      <input
                                        hidden
                                        accept="video/mp4,video/x-m4v,video/*"
                                        id="icon-video-file"
                                        type="file"
                                        onChange={handleVideoUpload}
                                        disabled={loadingVideoUpload}
                                      />
                                      <label htmlFor="icon-video-file">
                                        <IconButton aria-label="icon-video-file" component="span">
                                          {loadingVideoUpload ? (
                                            <CircularProgress />
                                          ) : (
                                            <CloudUploadTwoTone
                                              style={{ height: "auto", width: "100px" }}
                                            />
                                          )}
                                        </IconButton>
                                        <p style={{ color: "red", fontStyle: "italic" }}>
                                          {errorUploadVideo}
                                        </p>
                                      </label>
                                    </div>
                                  </Grid>
                                )
                              }
                            </Grid>
                          </Grid>
                        )
                    }
                  </Grid>
                )
            }
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.space}></Grid>

      {
        !isEdit ? (
          !_.isEmpty(dataEventJackSchedules) && (
            <Fragment>

              <Grid container direction="column">
                <Grid container direction="row" alignItems="center" style={{ paddingBottom: '30px' }}>
                  <Typography className={clsx(classes.bold)}>Đợt quay</Typography>
                </Grid>

                <Grid container direction="row" xs={8} className={clsx(classes.textLabelForm, classes.wrapperTable)} alignItems="center">
                  <Grid container item alignItems="center" spacing={3}>
                    <Grid item className={classes.textLabelForm} md={4}>Đợt</Grid>
                    <Grid item className={classes.textLabelForm} md={4}>Thời gian bắt đầu</Grid>
                    <Grid item className={classes.textLabelForm} md={4}>Thời gian kết thúc</Grid>
                  </Grid>

                  {
                    _.map(dataEventJackSchedules, (item, key) => {
                      return (
                        <Grid key={key} item container alignItems="center" spacing={3}>
                          <Grid item className={classes.textLabelForm} md={4}>{`Đợt ${key + 1}`}</Grid>

                          <Grid item className={classes.textLabelForm} md={4}>
                            <Typography className={clsx(classes.bold)}>
                              {moment(_.get(item, "start")).format("DD/MM/yyyy HH:mm A")}
                            </Typography>
                          </Grid>

                          <Grid item className={classes.textLabelForm} md={4}>
                            <Typography className={clsx(classes.bold)}>
                              {moment(_.get(item, "end")).format("DD/MM/yyyy HH:mm A")}
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Grid>
              <Grid className={classes.space}></Grid>
            </Fragment>
          )
        )
          :
          (
            <EventJackSchedules
              eventJackSchedules={dataEventJackSchedules}
              setEventJackSchedules={setDataEventJackSchedules}
              isEdit={true}
            />
          )
      }

      <Grid container direction="column">
        <Grid container direction="row" alignItems="center" style={{ paddingBottom: '30px' }}>
          <Typography className={clsx(classes.bold)}>Hình ảnh</Typography>
        </Grid>

        {!isEdit ? (
          _.map(eventItems, (item, key) => {
            return (
              <Fragment>
                <Grid container direction="row" alignItems="center" style={!_.get(item, 'active') ? { position: 'relative' } : undefined}>
                  {
                    !_.get(item, 'active') && (
                      <div className={classes.disabledStyle}>
                        <Typography component="h2">Tạm ẩn</Typography>
                      </div>
                    )
                  }
                  <Grid item xs={2}>
                    <Grid container direction="column" alignItems="center">
                      <Typography className={clsx(classes.bold)} style={{ paddingBottom: '20px' }}>
                        {`${key + 1}. ${_.get(item, 'name')}`}
                      </Typography>
                      <img
                        className={clsx(classes.icon)}
                        src={
                          configValue.MEDIA_DOMAIN +
                          _.get(item, "icon.url")
                        }
                        alt={`hình ảnh giải thưởng ${_.get(item, 'name')}`}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={2}>
                    <Grid container direction="column" alignItems="center">
                      <Typography className={clsx(classes.bold)}>
                        Loại giải thưởng
                      </Typography>
                      <Typography>
                        {_.get(item, "source_type") > 0 ? "Voucher Pinnow" : _.get(item, "gift_detail.gift_type.name") || '-'}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={2}>
                    <Grid container direction="column" alignItems="center">
                      <Typography className={clsx(classes.bold)}>
                        Tên giải thưởng
                      </Typography>
                      <Typography>
                        {_.get(item, "source_type") > 0 ? "Voucher Pinnow" : _.get(item, "gift_detail.name") || '-'}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={2}>
                    <Grid container direction="column" alignItems="center">
                      <Typography className={clsx(classes.bold)}>
                        Số Ecoin quy đổi
                      </Typography>
                      <Typography>
                        {_.get(item, "spin_ecoin") > 0 ? _.get(item, "spin_ecoin") : '-'}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={2}>
                    <Grid container direction="column" alignItems="center">
                      <Typography className={clsx(classes.bold)}>
                        Tỉ lệ trúng
                      </Typography>
                      <Typography>
                        {_.get(item, "spin_rate")}
                      </Typography>
                    </Grid>

                    <Grid container direction="column" alignItems="center">
                      <Typography className={clsx(classes.bold)}>
                        SL trúng tối đa/ngày
                      </Typography>
                      <Typography>
                        {_.get(item, "max_day") || '-'}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={2}>
                    <Grid container direction="column" alignItems="center">
                      <Typography className={clsx(classes.bold)}>
                        Ngày cập nhật
                      </Typography>
                      <Typography>
                        {_.get(item, "updated") ? moment(_.get(item, "updated")).format("DD/MM/yyyy") : '-'}
                      </Typography>
                    </Grid>
                  </Grid>

                </Grid>
                <Grid className={classes.space}></Grid>
              </Fragment>
            );
          })
        ) : (
          <EventItems
            eventItems={eventItems}
            setEventItems={setEventItems}
            deleteItems={deleteItems}
            setDeleteItems={setDeleteItems}
            giftList={
              _.map(_.get(data, 'event_gifts'), (item) => _.get(item, 'gifts')).filter(
                (item) => _.get(item, 'gift_type.code') === "GIFT" || _.get(item, 'gift_type.code') === "VOUCHER"
              )
            }
            dataSourceType={dataSourceType}
          />
        )}
      </Grid>

      {/* <Grid className={classes.space}></Grid>
      <Grid container direction="column">
        <Grid item xs={2}>
          <Typography className={clsx(classes.bold)}>Giải thưởng</Typography>
        </Grid>
        <Grid item>
          {isEdit ? (
            <EventGiftsVouchers
              eventGiftsVouchers={eventGiftsVouchers}
              setEventGiftsVouchers={setEventGiftsVouchers}
            />
          ) : (
            <EventGiftsTable data={data} align="center" />
          )}
        </Grid>
      </Grid> */}

      {isEdit && (
        <Fragment>
          <Grid className={classes.space}></Grid>
          <Grid container direction="column">
            <Grid item>
              <EventGiftsVouchers
                eventGiftsVouchers={eventGiftsVouchers}
                setEventGiftsVouchers={setEventGiftsVouchers}
              />
            </Grid>
          </Grid>
        </Fragment>
      )}

      <Grid container item justifyContent="flex-end" spacing={4}>
        {isEdit ? (
          <>
            <Grid item>
              <Button
                disabled={disableButton}
                variant="contained"
                color="primary"
                startIcon={<Check />}
                onClick={handleUpdate}
              >
                Xác nhận
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="default"
                startIcon={<Cancel />}
                onClick={() => {
                  setIsEdit(false);
                  dataInital();
                }}
              >
                Hủy bỏ
              </Button>
            </Grid>
          </>
        ) : (
          <Button
            color="primary"
            startIcon={<Edit />}
            variant="contained"
            disabled={!checkedPermission(userInfo, accessObjectPermissions, UPDATE_PROMOTION)}
            onClick={() => {
              setIsEdit(true);
              setName(_.get(data, "name"));
            }}
          >
            Cập nhật
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default JackGameDetail;
