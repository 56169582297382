const arrNum = ["không", "một", "hai", "ba", "bốn", "năm", "sáu", "bảy", "tám", "chín"];

export const readMillions = (number, isFull) => {
    let result = "";
    let billion = Math.floor(number / 1000000000);
    number = number % 1000000000;
    if (billion > 0) {
        result += readHundred(billion, isFull) + " tỷ ";
        isFull = true;
    }
    let million = Math.floor(number / 1000000);
    number = number % 1000000;
    if (million > 0) {
        result += readHundred(million, isFull) + " triệu ";
        isFull = true;
    }
    let thousand = Math.floor(number / 1000);
    number = number % 1000;
    if (thousand > 0) {
        result += readHundred(thousand, isFull) + " ngàn ";
        isFull = true
    }
    if (number > 0) {
        result += readHundred(number, isFull);
    }
    return result;
}

const readHundred = (number, isFull) => {
    let result = "";
    let hundred = Math.floor(number / 100);
    number = number % 100;
    if (isFull || hundred > 0) {
        result += arrNum[hundred] + " trăm ";
        result += readTen(number, true);
    } else {
        result += readTen(number, false);
    }
    return result;
}

const readTen = (number, isFull) => {
    let result = "";
    let ten = Math.floor(number / 10);
    let unit = number % 10;
    if (ten > 1) {
        result = " " + arrNum[ten] + " mươi ";
        if (unit === 1) {
            result += " mốt";
        }
    } else if (ten === 1) {
        result = " mười ";
        if (unit === 1) {
            result += " một";
        }
    } else if (isFull && unit > 0) {
        result = " lẻ ";
    }
    if (unit === 5 && ten >= 1) {
        result += " lăm ";
    } else if (unit > 1 || (ten === 0 && unit === 1)) {
        result += " " + arrNum[unit];
    }
    return result;
}
