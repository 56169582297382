import { Grid, makeStyles, Paper, Typography, TextField, FormControl, RadioGroup, FormControlLabel, Radio, MenuItem, Select, FormHelperText, Button } from '@material-ui/core'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useDepartments } from '../../Employee/hooks';
import { Controller, useForm } from 'react-hook-form';
import clsx from 'clsx';
import ModalConfirm from '../../../components/ModalConfirm';
import slugs from '../../../resources/slugs';
import swal from 'sweetalert';

const AccountCreate = () => {
    const [openModal, setOpenModal] = useState(false);
    const useStyles = makeStyles((theme) => ({
        buttonConfirm: {
            backgroundColor: '#00837B',
            color: '#fff',
            height: 48,
            width: '200px',
            marginTop: '24px',
            '&:hover': {
                backgroundColor: '#00615a'
            }
        },
        container: {
            padding: "32px 24px"
        },
        title: {
            marginLeft: '10px',
            fontSize: 20
        },
        bodyContainer: {
            backgroundColor: '#fff',
            borderRadius: '12px',
            padding: '32px',
            height: '80vh',
            width: '100%',
            marginLeft: '10px',
            marginTop: '24px'
        },
        fieldInputContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        fieldContainer: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '40px'
        },
        titleFieldInput: {
            width: '200px'
        },
        bottomContainer: {
            marginTop: "24px",
            marginLeft: '12px',
            justifyContent: 'flex-end'
        }
    }));

    const classes = useStyles();
    const history = useHistory();

    const departmentList = useDepartments();

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            name: "",
            department: 0,
            source: null
        },
    });

    const onSubmit = (values) => { }

    return (
        <Grid
            direction="column"
            container
            spacing={2}
        >
            <ModalConfirm
                handleClose={() => setOpenModal(false)}
                open={openModal}
                submit={handleSubmit(onSubmit)}
                description="Bạn có muốn tạo loại tài khoản mới?"
                title={"Tạo loại tài khoản mới"}
                isNote={false}
            />
            <Grid container direction="row" className={classes.title}>
                <Typography fontSize={20}>Tạo loại tài khoản mới </Typography>
            </Grid>
            <Grid container direction='column' className={classes.bodyContainer}>
                <Grid item className={classes.fieldInputContainer}>
                    <Typography className={classes.titleFieldInput} variant='caption' component='span'>Tên loại tài khoản <span style={{ color: 'red' }}>*</span></Typography>
                    <Controller
                        name="name"
                        rules={{
                            required: "Tên loại tài khoản không được để trống",
                        }}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={errors.name}
                                helperText={errors.name?.message}
                                variant="outlined"
                                style={{ width: 360 }}
                                size="small"
                            />
                        )}
                    />
                </Grid>
                <Grid item className={classes.fieldContainer}>
                    <Typography className={classes.titleFieldInput} variant='caption' component='span'>Nền tảng ECOE <span style={{ color: 'red' }}>*</span></Typography>
                    <Controller
                        name="source"
                        rules={{
                            required: "Nền tảng ECOE không được để trống",
                        }}
                        control={control}
                        render={({ field }) => (
                            <FormControl  {...field}>
                                <RadioGroup
                                    aria-label="application"
                                >
                                    <div style={{ display: 'flex' }}>
                                        <FormControlLabel value={"AGENTAPP"} style={{ marginRight: 100 }} control={<Radio />} label="Agent App" />
                                        <FormControlLabel value={"WEBADMIN"} control={<Radio />} label="Web Admin" />
                                    </div>
                                </RadioGroup>
                                <FormHelperText error>{errors.source?.message}</FormHelperText>
                            </FormControl>
                        )}
                    />

                </Grid>
                <Grid item className={classes.fieldContainer}>
                    <Typography variant='caption' component='span' className={classes.titleFieldInput}>Phòng ban</Typography>
                    <Controller
                        name="department"
                        control={control}
                        render={({ field }) => (
                            <FormControl>
                                <Select  {...field} style={{ width: 360 }} variant="outlined">
                                    <MenuItem value={0}>Chọn phòng ban</MenuItem>
                                    {_.map(_.get(departmentList, 'data'), (element, index) => (
                                        <MenuItem key={_.get(element, 'id')} value={_.get(element, 'id')}>{_.get(element, 'name')}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />

                </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-end'>
                <Button onClick={() => setOpenModal(true)} size="small" className={clsx(classes.buttonConfirm)}>Xác nhận </Button>
            </Grid>
        </Grid>


    )
}

export default AccountCreate