import { useState } from "react";
import HeaderFilter from "../components/HeaderFilter";
import ListCheck from "../components/ListCheck";
import ListDate from "../components/ListDate";

const ProductStatusFilter = (props) => {
  const { branches, properties, setFilterType, onSubmit, setIsSelectAll } =
    props;
  const [showFilter, setShowFilter] = useState({
    property: false,
    branch: false,
  });

  return (
    <HeaderFilter onSubmit={onSubmit} title="Thống kê theo trạng thái sản phẩm">
      <ListCheck
        headerTitle="Loại hình"
        data={properties}
        open={showFilter.property}
        setOpen={setShowFilter}
        field="property"
        setFilterType={setFilterType}
        setIsSelectAll={setIsSelectAll}
        checkAllTitle="Chọn tất cả loại hình"
      />
      <ListCheck
        headerTitle="Chi nhánh"
        data={branches}
        open={showFilter.branch}
        setOpen={setShowFilter}
        field="branch"
        setFilterType={setFilterType}
        setIsSelectAll={setIsSelectAll}
        checkAllTitle="Chọn tất cả chi nhánh"
      />
    </HeaderFilter>
  );
};

export default ProductStatusFilter;
