import React, { useEffect, useState } from 'react';
import * as _ from "lodash";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

import InputBase from '@material-ui/core/InputBase';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_SCHEDULE, GET_EVENT_TYPES } from '../../graphql/schemas/employee/employeeQueries';
import { CREATE_SCHEDULE } from '../../graphql/schemas/employee/employeeMutation';
import swal from 'sweetalert';
import AutoCompleteProduct from '../AutoCompleteProduct';
import AutoCompleteCustomer from '../AutoCompleteCustomer';
import AutoCompleteEmployee from '../AutoCompleteEmployee';
import moment from 'moment'

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const EmployeeCreateTime = ({ onBack, id }) => {
    const { loading, data, error } = useQuery(GET_EVENT_TYPES);
    const [createSchedule] = useMutation(CREATE_SCHEDULE, {
        refetchQueries: [{
            query: GET_ALL_SCHEDULE, variables: {
                skip: 0,
                take: 10,
                where: {
                    deleted: { _eq: false },
                    employee: { _eq: id },
                }
            }
        }],
    })
    const useStyles = makeStyles((theme) => ({
        message: {
            border: `2px solid lightgrey`,
            border: 'none'
        },
        err: {
            color: "#db2828",
            paddingTop: "5px"
        },
        note: {
            borderBottomLeftRadius: '10px',
            borderTopLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            borderTopRightRadius: '0',
            boxShadow: '1px 5px 7px lightgrey',
            padding: '8px',
            width: matchesMD ? '400px' : undefined,
        },
        buttonBack: {
            backgroundColor: "black",
            color: '#ffffff',
            padding: '6px 36px',
            marginTop: '60px',
            marginRight: '30px',
            borderRadius: '0',
            marginBottom: '30px'
        },
        buttonCreate: {
            backgroundColor: "#2C8A81",
            color: '#ffffff',
            padding: '6px 36px',
            marginTop: '60px',
            marginRight: '30px',
            borderRadius: '0',
            marginBottom: '30px',
            textTransform: 'none'
        },
        keyboardDate: {
            marginTop: 0,
            '& .MuiInputBase-adornedEnd': {
                '& > input': {
                    padding: '8px 14px'
                }
            }
        },
        selectWidth: {
            width: "225px"
        }
    }))

    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();

    const [typeSchedule, setTypeSchedule] = useState("");
    const [selectedDateStart, setSelectedDateStart] = React.useState(new Date().toISOString());
    const [selectedDateEnd, setSelectedDateEnd] = React.useState(new Date().toISOString());

    const [isDisabled, setDisaled] = useState(false);

    const [title, setTitle] = useState("");
    const [address, setAddress] = useState("");
    const [remark, setRemark] = useState("");
    const [ecoeId, setEcoeId] = useState();
    const [customerFullName, setCustomerFullName] = useState("");
    const [ecoeIdErr, setEcoeIdErr] = useState(false);
    const [customerId, setCustomerId] = useState();
    const [customerIdErr, setCustomerIdErr] = useState(false);
    const [productId, setProductId] = useState();
    const [productIdErr, setProductIdErr] = useState(false);


    useEffect(() => {
        const checkValidate = Object.values({
            typeSchedule, selectedDateStart
        }).every((item) => Boolean(item));

        checkValidate ? setDisaled(false) : setDisaled(true);

    }, [typeSchedule, selectedDateStart]);


    // useEffect(() => {
    //     validate();
    // }, [ecoeId, customerId, productId]);

    // const validate = () => {
    //     if (!validEcoeId.test(ecoeId) && _.isEmpty(ecoeId) === false) {
    //         setEcoeIdErr(true);
    //     } else {
    //         setEcoeIdErr(false);
    //     }
    //     if (!validCustomerId.test(customerId) && _.isEmpty(customerId) === false) {
    //         setCustomerIdErr(true);
    //     } else {
    //         setCustomerIdErr(false);
    //     }
    //     if (!validProductId.test(productId) && _.isEmpty(productId) === false) {
    //         setProductIdErr(true);
    //     } else {
    //         setProductIdErr(false);
    //     }
    // };

    const handleDateStartChange = (date) => {
        setSelectedDateStart(moment(date).format());
    };

    const handleDateEndChange = (date) => {
        setSelectedDateEnd(moment(date).format());
    };
    const handleCreateSchedule = () => {
        try {
            createSchedule({
                variables: {
                    data: {
                        title: title,
                        address: address,
                        remark: remark,
                        start_time: selectedDateStart,
                        end_time: selectedDateEnd,
                        type: Number(typeSchedule),
                        customer: customerId,
                        employee: id * 1,
                        product: productId,
                    }
                }
            }).then((data) => {
                swal("Tạo mới lịch hẹn thành công", "", "success").then(() => {
                    onBack();
                });
            });
        } catch (error) {
            swal("Tạo mới lịch hẹn thất bại", error, "error");
        }
    }


    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} moment={moment}>
            <Grid container direction="column" style={{ paddingLeft: matchesSM ? '20px' : '150px', paddingTop: matchesSM ? '20px' : undefined }}>
                <Grid container direction={matchesMD ? 'column' : 'row'} alignItems="flex-start">
                    <Grid item style={{ paddingTop: '20px', paddingRight: '40px' }}>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Loại lịch hẹn: <span style={{ color: 'red' }}>*</span></Typography>
                            </Grid>
                            <Grid item className={classes.text}>
                                <FormControl className={classes.selectWidth}>
                                    <NativeSelect
                                        id="demo-customized-select-native"
                                        value={typeSchedule}
                                        onChange={(e) => setTypeSchedule(e.target.value)}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        {data && _.map(data.results, (item, key) => {
                                            return <option value={item.id} key={key}>{item.name}</option>
                                        })}
                                    </NativeSelect>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Ngày bắt đầu: <span style={{ color: 'red' }}>*</span></Typography>
                            </Grid>
                            <Grid item className={classes.text}>
                                <KeyboardDateTimePicker
                                    className={classes.keyboardDate}
                                    style={{
                                        backgroundColor: '#ffffff',
                                        width: '223px',
                                        border: '1px solid lightgray',
                                        padding: '5px 0',
                                        borderRadius: '5px'
                                    }}
                                    margin="normal"
                                    variant="inline"
                                    id="time-picker-start"
                                    format="dd/MM/yyyy HH:mm"
                                    value={selectedDateStart}
                                    onChange={handleDateStartChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                />
                                {/* <div className={classes.err}>Thời gian không hợp lệ</div> */}
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Mã sản phẩm: </Typography>
                            </Grid>
                            <Grid item className={classes.text} xs={7}>
                                {/* <TextField style={{ backgroundColor: '#ffffff' }} variant="outlined" size="small" required onChange={(e) => setProductId(e.target.value)} /> */}
                                <AutoCompleteProduct onChange={(id) => setProductId(id)} />
                                {productIdErr && <div className={classes.err}>Mã sản phẩm không hợp lệ</div>}
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Mã khách hàng: </Typography>
                            </Grid>
                            <Grid item className={classes.text} xs={7}>
                                {/* <TextField style={{ backgroundColor: '#ffffff' }} variant="outlined" size="small" required onChange={(e) => setCustomerId(e.target.value)} /> */}
                                <AutoCompleteCustomer onChange={(id) => setCustomerId(id)} />
                                {customerIdErr && <div className={classes.err}>Mã khách hàng không hợp lệ</div>}
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Mã NV đi cùng: </Typography>
                            </Grid>
                            <Grid item className={classes.text} xs={7}>
                                {/* <TextField style={{ backgroundColor: '#ffffff' }} variant="outlined" size="small" required onChange={(e) => setEcoeId(e.target.value)} /> */}
                                <AutoCompleteEmployee onChange={(id) => setEcoeId(id)} isSchedule="true" />
                                {ecoeIdErr && <div className={classes.err}>Mã nhân viên không hợp lệ</div>}
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="column" style={{ marginBottom: '66px' }}>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', fontSize: '25px', marginBottom: '20px', marginTop: matchesMD ? '20px' : undefined }}>Ghi chú:</Typography>
                                <Paper className={classes.note}>
                                    <TextField
                                        className={classes.message}
                                        InputProps={{ disableUnderline: true }}
                                        multiline
                                        style={{ width: '25em', height: '100%' }}
                                        id="message"
                                        rows={5}
                                        onChange={(e) => setRemark(e.target.value)}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{ paddingTop: '20px' }}>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Tiêu đề: </Typography>
                            </Grid>
                            <Grid item className={classes.text}>
                                <TextField style={{ backgroundColor: '#ffffff' }} variant="outlined" size="small" onChange={(e) => setTitle(e.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Ngày kết thúc: </Typography>
                            </Grid>
                            <Grid item className={classes.text}>
                                <KeyboardDateTimePicker
                                    className={classes.keyboardDate}
                                    style={{
                                        backgroundColor: '#ffffff',
                                        width: '223px',
                                        border: '1px solid lightgray',
                                        padding: '5px 0',
                                        borderRadius: '5px'
                                    }}
                                    margin="normal"
                                    variant="inline"
                                    id="time-picker-end"
                                    format="dd/MM/yyyy HH:mm"
                                    value={selectedDateEnd}
                                    onChange={handleDateEndChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Vị trí: </Typography>
                            </Grid>
                            <Grid item className={classes.text}>
                                <TextField style={{ backgroundColor: '#ffffff' }} variant="outlined" size="small" required onChange={(e) => setAddress(e.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="row" style={{ marginBottom: '66px' }}>
                            <Grid item style={{ width: '160px' }}>
                                <Typography className={classes.text} style={{ fontWeight: 'bold' }}>Tên khách hàng: </Typography>
                            </Grid>
                            <Grid item className={classes.text}>
                                <TextField style={{ backgroundColor: '#ffffff' }} variant="outlined" size="small" onChange={(e) => setCustomerFullName(e.target.value)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                        <Button className={classes.buttonCreate} variant="contained" disabled={isDisabled} onClick={handleCreateSchedule}>Tạo mới</Button>
                        <Button onClick={() => { onBack() }} className={classes.buttonBack} variant="contained">Quay lại</Button>
                    </Grid>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider >
    )
}

export default EmployeeCreateTime;
