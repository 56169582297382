import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import * as _ from "lodash";
import InputTextField from '../Secondary/components/InputTextField';
import { useStyles } from './useStyles';
import { primary_product_template } from './primary-template';
import clsx from 'clsx';
import InputField from './InputField';
import UploadImage from './UploadImage';
import ImagePicker from '../Secondary/components/ImagePicker';

Investor.propTypes = {

};

const mappingUploadAvatarImages = (data) => {

    if (!_.isEmpty(data)) {
        let result = {
            'id': data.id,
            'url': data.url
        }
        return [result];
    }
    return [];
}

function Investor({ draftObject, setDraftObject, data, onChange }) {
    const classes = useStyles();
    const [uploadImageInvestor, setUploadImageInvestor] = useState(() => mappingUploadAvatarImages(data));

    useEffect(() => {
        let imgs = _.map(uploadImageInvestor, (item) => {
            return item.id;
        });
        onChange(imgs)
    }, [uploadImageInvestor]);


    return (
        <div>
            <Grid container xs={12} className={classes.groupRow}>
                <Grid item xs={12}>
                    <Typography component='div' className={classes.title}>Thông tin chủ đầu tư</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography component='div' className={clsx(classes.label, 'required')}>Tên chủ đầu tư</Typography>
                </Grid>
                <Grid item xs={5}>
                    <InputTextField
                        fieldname='investor'
                        fullwidth
                        object={draftObject}
                        setObject={setDraftObject}
                        defaultValue={_.get(draftObject, "investor")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography component='div' className={classes.label}>Hình ảnh</Typography>
                    <Typography component='div' className={clsx(classes.label, 'description')}>Tối đa 1 hình, dung lượng 5MB/hình</Typography>
                </Grid>
                <Grid item xs={12} className={classes.gridRow}>
                    <ImagePicker
                        data={uploadImageInvestor}
                        setData={setUploadImageInvestor}
                        numberColumn={4}
                        buttonType='CARD'
                        type="AVATAR"
                        limit={1}
                    />
                </Grid>
            </Grid>
            <div className={classes.mgBottom} />
            <Grid container xs={12} className={classes.groupRow}>
                <Grid item xs={2}>
                    <Typography component='div' className={classes.label}>Tên tập đoàn</Typography>
                </Grid>
                <Grid item xs={4}>
                    <InputTextField
                        fieldname='corporation'
                        fullwidth
                        description="Tên tập đoàn"
                        object={draftObject}
                        setObject={setDraftObject}
                        defaultValue={_.get(draftObject, "corporation")}
                    />
                </Grid>
            </Grid>
            <div className={classes.mgBottom} />
            <Grid container xs={12} className={classes.groupRow}>
                <Grid item xs={2}>
                    <Typography component='div' className={classes.label}>Link website chủ đầu tư</Typography>
                </Grid>
                <Grid item xs={4}>
                    <InputTextField
                        fieldname='investor_website'
                        fullwidth
                        object={draftObject}
                        setObject={setDraftObject}
                        defaultValue={_.get(draftObject, "investor_website")}
                    />
                </Grid>
            </Grid>
            <div className={classes.marginDivider} />
            <Grid container xs={12} className={classes.groupRow}>
                <Grid item xs={2}>
                    <Typography component='div' className={classes.label}>Công ty hợp tác đầu tư</Typography>
                </Grid>
                <Grid item xs={4}>
                    {/* <InputField limit={3}
                        fullwidth
                        fieldname='investor.cooperation_company'
                        object={draftObject}
                        setObject={setDraftObject} /> */}
                    <InputTextField
                        fieldname='corporation_company'
                        fullwidth
                        object={draftObject}
                        setObject={setDraftObject}
                        defaultValue={_.get(draftObject, "corporation_company")}
                    />
                </Grid>
            </Grid>
            <div className={classes.mgBottom} />
            <Grid container xs={12} className={classes.groupRow}>
                <Grid item xs={2}>
                    <Typography component='div' className={classes.label}>Nhà phát triển dự án (F0)</Typography>
                </Grid>
                <Grid item xs={4}>
                    {/* <InputField
                        fieldname='investor.project_developer'
                        object={draftObject}
                        setObject={setDraftObject}
                    /> */}
                    <InputTextField
                        fieldname='develop_company'
                        fullwidth
                        object={draftObject}
                        setObject={setDraftObject}
                        defaultValue={_.get(draftObject, "develop_company")}
                    />
                </Grid>
            </Grid>
            <div className={classes.mgBottom} />
            <Grid container xs={12} className={classes.groupRow}>
                <Grid item xs={2}>
                    <Typography component='div' className={classes.label}>Nhà thầu xây dựng</Typography>
                </Grid>
                <Grid item xs={4}>
                    {/* <InputField limit={4}
                        fieldname='investor.contractor'
                        object={draftObject}
                        setObject={setDraftObject}
                    /> */}
                    <InputTextField
                        fieldname='building_contractor'
                        fullwidth
                        object={draftObject}
                        setObject={setDraftObject}
                        defaultValue={_.get(draftObject, "building_contractor")}
                    />
                </Grid>
            </Grid>
            <div className={classes.mgBottom} />
            <Grid container xs={12} className={classes.groupRow}>
                <Grid item xs={2}>
                    <Typography component='div' className={classes.label}>Đơn vị thiết kế</Typography>
                </Grid>
                <Grid item xs={4}>
                    {/* <InputField limit={3}
                        fieldname='investor.design_unit'
                        object={draftObject}
                        setObject={setDraftObject}
                    /> */}
                    <InputTextField
                        fieldname='design_company'
                        fullwidth
                        object={draftObject}
                        setObject={setDraftObject}
                        defaultValue={_.get(draftObject, "design_company")}
                    />
                </Grid>
            </Grid>
            <div className={classes.mgBottom} />
            <Grid container xs={12} className={classes.groupRow}>
                <Grid item xs={2}>
                    <Typography component='div' className={classes.label}>Đơn vị quản lý vận hành</Typography>
                </Grid>
                <Grid item xs={4}>
                    {/* <InputField limit={3}
                        fieldname='investor.operation_unit'
                        object={draftObject}
                        setObject={setDraftObject}
                    /> */}
                    <InputTextField
                        fieldname='manager_company'
                        fullwidth
                        object={draftObject}
                        setObject={setDraftObject}
                        defaultValue={_.get(draftObject, "manager_company")}
                    />
                </Grid>
            </Grid>
            <div className={classes.mgBottom} />
            <Grid container xs={12} className={classes.groupRow}>
                <Grid item xs={2}>
                    <Typography component='div' className={classes.label}>Đơn vị giám sát thi công</Typography>
                </Grid>
                <Grid item xs={4}>
                    {/* <InputField limit={3}
                        fieldname='investor.monitor_unit'
                        object={draftObject}
                        setObject={setDraftObject}
                    /> */}
                    <InputTextField
                        fieldname='monitor_company'
                        fullwidth
                        object={draftObject}
                        setObject={setDraftObject}
                        defaultValue={_.get(draftObject, "monitor_company")}
                    />
                </Grid>
            </Grid>
            <div className={classes.mgBottom} />
            <Grid container xs={12} className={classes.groupRow}>
                <Grid item xs={2}>
                    <Typography component='div' className={classes.label}>Ngân hàng bảo lãnh</Typography>
                </Grid>
                <Grid item xs={4}>
                    {/* <InputField limit={3}
                        fieldname='investor.guagrantee_bank'
                        object={draftObject}
                        setObject={setDraftObject}
                    /> */}
                    <InputTextField
                        fieldname='bank'
                        fullwidth
                        object={draftObject}
                        setObject={setDraftObject}
                        defaultValue={_.get(draftObject, "bank")}
                    />
                </Grid>
            </Grid>
            <div className={classes.mgBottom} />
            <Grid container xs={12} className={classes.groupRow}>
                <Grid item xs={2}>
                    <Typography component='div' className={classes.label}>Ngân hàng hỗ trợ vay vốn</Typography>
                </Grid>
                <Grid item xs={4}>
                    {/* <InputField limit={3}
                        fieldname='investor.loans_bank'
                        object={draftObject}
                        setObject={setDraftObject}
                    /> */}
                    <InputTextField
                        fieldname='support_bank'
                        fullwidth
                        object={draftObject}
                        setObject={setDraftObject}
                        defaultValue={_.get(draftObject, "support_bank")}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default Investor;