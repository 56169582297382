import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SelectComponent from "../../Customer/components/SelectComponent";
import {
  useProvinces,
  useWards,
} from "../../../graphql/schemas/masterdata/address";
import {
  useDistricts,
  useStreets,
} from "../../../graphql/schemas/masterdata/address/index";
import _ from "lodash";
import clsx from "clsx";
import { checkedPermission, checkedValidate } from "../../../helper/index";
import InputTextField from "../../Product/Secondary/components/InputTextField";
import { useCreateBranch } from "../../../graphql/schemas/masterdata/branches/index";
import slugs from "../../../resources/slugs";
import swal from "sweetalert";
import AutoCompleteBranch from "../../../components/AutoCompleteOffice";
import AutoCompleteEmployee from "../../../components/AutoCompleteEmployee";
import AddressAutocomplete from "../components/AddressAutocomplete";
import ButtonReturn from "../../../components/ButtonReturn";
import { StoreContext } from "../../../resources/store";
import { USER_ROLE_SUPPER_ADMIN } from "../../../constants/defined";

const useStyles = makeStyles((theme) => ({
  fieldTitle: {
    fontWeight: "bold",
  },
  branchContainer: {
    marginTop: 24,
  },
  cancel: {
    backgroundColor: "#E27F35",
    color: "#fff",
    marginRight: 20,
  },
  confirm: {
    backgroundColor: "#00837B",
    color: "#fff",
  },
  radio: {
    display: "flex",
  },
}));

const BranchCreate = () => {
  const [wards, setWards] = useState([]);
  // const [street, setStreet] = useState();

  const history = useHistory();

  const { userInfo, accessObjectPermissions } = useContext(StoreContext);

  const [address, setAddress] = useState({
    wardIdsManage: [],
    districtIdManage: 0,
    provinceIdManage: 0,
  });

  const [object, setObject] = useState({
    name: "",
    phone: "",
    address_number: "",
    street_name: "",
    ward: 0,
    district: 0,
    province: 0,
    longitude: null,
    latitude: null,
    employee_manager: null,
    branch_manager: null,
    is_head: true,
    is_physical: false,
  });

  const createBranch = useCreateBranch();

  const [incharges, setIncharges] = useState([]);

  const [disabled, setDisabled] = useState(false);

  const classes = useStyles();

  const provinceList = useProvinces();

  const districtList = useDistricts({
    provinceId: object.province,
  });

  const wardList = useWards({
    districtId: object.district,
  });

  const districtListManage = useDistricts({
    provinceId: address.provinceIdManage,
  });

  const wardListManage = useWards({
    districtId: address.districtIdManage,
  });

  // const streetList = useStreets({ districtId: object.district })

  const onClose = () => {
    let arr = _.map(wards, (value) =>
      _.get(_.find(wardListManage, ["name", value]), "id")
    );

    let variablesWard = _.map(arr, (id) => {
      return {
        ward: id,
        province: address.provinceIdManage,
        district: address.districtIdManage,
      };
    });

    setIncharges(variablesWard);
  };

  useEffect(() => {
    let checked = checkedValidate(
      Object.values({
        name: object.name,
        province: object.province,
        ward: object.ward,
        district: object.district,
        street_name: object.street_name,
        longitude: object.longitude,
        latitude: object.latitude,
        employee_manager: object.employee_manager,
      })
    );
    setDisabled(!checked);
  }, [object]);

  const handleCreate = () => {
    let temp = _.cloneDeep(object);
    temp.longitude = Number(temp.longitude);
    temp.latitude = Number(temp.latitude);

    createBranch({
      variables: {
        data: {
          ...temp,
          incharges,
        },
      },
    }).then(
      (res) => {
        swal({
          title: "Tạo văn phòng thành công",
          icon: "success",
        }).then(() => {
          history.push(slugs.branchList);
        });
      },
      (error) => {
        swal("Vui lòng thử lại", "Có lỗi khi tạo văn phòng", "error");
      }
    );
  };

  return (
    <>
      <Grid container direction="column">
        <Grid container direction="row">
          <Typography variant="h4">Tạo mới văn phòng</Typography>
        </Grid>
        <Grid
          container
          direction="column"
          className={classes.branchContainer}
          spacing={2}
        >
          <Grid item container direction="row">
            <Grid item container xs={6} alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>
                  Tên văn phòng <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <InputTextField
                  fieldname="name"
                  description="Tên văn phòng"
                  object={object}
                  setObject={setObject}
                  required
                />
              </Grid>
            </Grid>
            <Grid item container xs={6} alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>
                  Số điện thoại
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <InputTextField
                  fieldname="phone"
                  description="Số điện thoại"
                  object={object}
                  setObject={setObject}
                  maxLength={10}
                  setDisabled={(value) => {
                    setDisabled(value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item container direction="row">
            <Grid item xs={6} container alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>
                  Tỉnh/ thành phố<span style={{ color: "red" }}>*</span>{" "}
                </Typography>
              </Grid>
              <div style={{ width: 250 }}>
                <SelectComponent
                  disabledDefault={true}
                  defaultValue={0}
                  width={"100%"}
                  defaultTitle="Chọn tỉnh/thành phố"
                  fieldTitle="name"
                  fieldValue="id"
                  value={object.province}
                  handleChange={(e) =>
                    setObject((prev) => ({
                      ...prev,
                      province: e.target.value,
                      district: 0,
                    }))
                  }
                  listData={provinceList}
                  alignItems="center"
                />
              </div>
            </Grid>
            <Grid item xs={6} container alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>
                  Quận/ Huyện<span style={{ color: "red" }}>*</span>{" "}
                </Typography>
              </Grid>
              <Grid>
                <div style={{ width: 250 }}>
                  <SelectComponent
                    fieldTitle="name"
                    fieldValue="id"
                    handleChange={(e) =>
                      setObject((prev) => ({
                        ...prev,
                        district: e.target.value,
                        ward: 0,
                      }))
                    }
                    listData={districtList}
                    width={250}
                    value={object.district}
                    defaultValue={0}
                    defaultTitle="Chọn quận/huyện"
                    alignItems="center"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6} container alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>
                  Phường/xã<span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <div style={{ width: 250 }}>
                <SelectComponent
                  fieldTitle="name"
                  fieldValue="id"
                  width="100%"
                  handleChange={(e) =>
                    setObject((prev) => ({ ...prev, ward: e.target.value }))
                  }
                  listData={wardList}
                  value={object.ward}
                  defaultValue={0}
                  defaultTitle="Phường/xã"
                  alignItems="center"
                />
              </div>
            </Grid>
          </Grid>

          <Grid item container direction="row">
            <Grid item xs={6} container alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>Số nhà</Typography>
              </Grid>
              <Grid item xs={4}>
                <InputTextField
                  fieldname="address_number"
                  description="Số nhà"
                  object={object}
                  setObject={setObject}
                  required
                />
              </Grid>
            </Grid>
            <Grid item xs={6} container alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>
                  Tên đường <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
              </Grid>
              <div style={{ width: 250 }}>
                {/* <AddressAutocomplete
                  dataList={streetList ? streetList : []}
                  objectLabel="name"
                  objectValue="id"
                  setValue={setStreet}
                  placeholder="Tên đường"
                  size='small'
                  multiple={false}
              /> */}
                <InputTextField
                  fieldname="street_name"
                  description="Chọn tên đường"
                  object={object}
                  setObject={setObject}
                  required
                />
              </div>
            </Grid>
          </Grid>

          <Grid item container direction="row">
            <Grid item xs={6} container>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={3}>
                  <Typography className={classes.fieldTitle}>
                    Tọa độ <span style={{ color: "red" }}>*</span>{" "}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <InputTextField
                    fullwidth
                    fieldname="longitude"
                    description="Toạ độ X"
                    object={object}
                    setObject={setObject}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputTextField
                    fullwidth
                    fieldname="latitude"
                    description="Toạ độ Y"
                    object={object}
                    setObject={setObject}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6} container>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={3}>
                  <Typography className={classes.fieldTitle}>
                    Phân loại <span style={{ color: "red" }}>*</span>{" "}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Radio
                    style={{ color: "#00837B" }}
                    checked={object.is_head}
                    onChange={() =>
                      setObject((prev) => {
                        return {
                          ...prev,
                          is_head: !object.is_head,
                        };
                      })
                    }
                  />
                  Hội sở
                  <Radio
                    style={{ color: "#00837B" }}
                    checked={!object.is_head}
                    onChange={() =>
                      setObject((prev) => {
                        return {
                          ...prev,
                          is_head: !object.is_head,
                        };
                      })
                    }
                  />
                  Chi nhánh
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={6}>
                    <Typography className={classes.fieldTitle}>
                      Là địa chỉ vật lý
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{ color: "#00837B" }}
                          checked={object.is_physical}
                          onClick={() =>
                            setObject((prev) => ({
                              ...prev,
                              is_physical: !object.is_physical,
                            }))
                          }
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container direction="row">
            <Grid item xs={6} container alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>
                  Mã văn phòng quản lý
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <AutoCompleteBranch
                  placeholder="Chọn văn phòng"
                  value={object.branch_manager}
                  onChange={(id) =>
                    setObject((prev) => ({ ...prev, branch_manager: id }))
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={6} container spacing={2}>
              <Grid item xs={3}>
                <Typography className={classes.fieldTitle}>
                  Mã nhân viên ECOE quản lý cao nhất{" "}
                  <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <AutoCompleteEmployee
                  placeholder="Chọn nhân viên"
                  value={object.employee_manager}
                  onChange={(id) =>
                    setObject((prev) => ({ ...prev, employee_manager: id }))
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{ padding: 8, marginTop: 4, marginLeft: 0 }}
            xs={12}
            alignItems="center"
            container
            spacing={2}
          >
            <Grid container spacing={4} alignItems="center">
              <Grid item style={{ width: 200 }}>
                <Typography className={classes.fieldTitle}>
                  Khu vực quản lý
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <SelectComponent
                  width="100%"
                  disabledDefault={true}
                  defaultValue={0}
                  defaultTitle="Chọn tỉnh/thành phố"
                  fieldTitle="name"
                  fieldValue="id"
                  value={address.provinceIdManage}
                  handleChange={(e) =>
                    setAddress((prev) => ({
                      ...prev,
                      provinceIdManage: e.target.value,
                      districtIdManage: 0,
                    }))
                  }
                  listData={provinceList}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectComponent
                  width="100%"
                  fieldTitle="name"
                  fieldValue="id"
                  handleChange={(e) =>
                    setAddress((prev) => ({
                      ...prev,
                      districtIdManage: e.target.value,
                      wardIdsManage: [],
                    }))
                  }
                  listData={districtListManage}
                  value={address.districtIdManage}
                  defaultValue={0}
                  defaultTitle="Chọn quận/huyện"
                />
              </Grid>
              <Grid item xs={3}>
                <SelectComponent
                  width="100%"
                  fieldTitle="name"
                  fieldValue="name"
                  multiple={true}
                  handleChange={(e) => setWards(e.target.value)}
                  listData={wardListManage}
                  value={wards}
                  defaultTitle="Chọn phường xã"
                  renderValue={true}
                  onClose={onClose}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: "50px" }}
          justifyContent="flex-end"
          container
          direction="row"
        >
          <div style={{ marginRight: "20px" }}>
            <ButtonReturn link={slugs.branchList} label="Hủy" />
          </div>
          <Button
            disabled={disabled}
            className={clsx(classes.confirm)}
            variant="contained"
            onClick={handleCreate}
          >
            Xác nhận
          </Button>
        </Grid>
      </Grid>

    </>
  );
};

export default BranchCreate;
