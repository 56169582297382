import _ from "lodash";
import React, { useState, Fragment } from 'react';
import { useQuery } from '@apollo/client';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, CircularProgress, TextField, Typography } from "@material-ui/core";
import { Waypoint } from 'react-waypoint';
import { GET_CUSTOMER_BY_CODE_FILTER_FOR_AUTOCOMPLETE } from "../graphql/schemas/customer/customerQueries";
import { useDebounce } from "use-debounce";

const PER_PAGE = 10;

const useStyles = makeStyles((theme) => ({
    wrapperInput: {
        '& > div': {
            padding: '10.5px 14px !important'
        },
        '& input': {
            padding: '0 !important'
        },      
    },
}));

const DataEmpty = () => {
    return (
        <Typography>
            Không có dữ liệu thích hợp
        </Typography>
    )
};

const AutoCompleteCustomerScrollLoad = ({defaultValue, setObject}) => {
    const classes = useStyles();
    const [value, setValue] = useState(defaultValue);
    const [searchText, setSearchText] = useState('');
    const [searchTextValue] = useDebounce(searchText, 500);
    let listCustomer = [];
    const variables = {
        skip: 0,
        take: PER_PAGE,
        where: {
            _or: searchTextValue ? [
                {  
                    accountByAccount: {
                        code: {
                            _ilike: `%${searchTextValue}%`
                        }
                    }
                }
            ] : undefined
        }
    };

    const { loading, data, fetchMore } = useQuery(GET_CUSTOMER_BY_CODE_FILTER_FOR_AUTOCOMPLETE, { variables: variables });

    const loadMore = () => {
        fetchMore({
            variables: {
                skip: (_.size(listCustomer) / PER_PAGE) * PER_PAGE,
                take: PER_PAGE
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!_.get(fetchMoreResult, 'results')) {
                    return;
                };
                return {
                    results: [
                        ...previousResult?.results,
                        ...fetchMoreResult?.results
                    ],
                }
            }
        })
    }

    if (!_.isEmpty(_.get(data, 'results'))) {
        listCustomer = _.map(_.get(data, 'results'), (item) => {
            return {
                id: _.get(item, "info.id"),
                code: _.get(item, "info.code"),
                name: _.get(item, "info.full_name"),
                email: _.get(item, "info.email"),
                personalid: _.get(item, "info.personalid"),
                phone: _.get(item, "info.phone")
            }
        });
    };

    const handleSelect = (e, value) => {
        setSearchText('');
        try {
            if (value) {
                setValue(value);
                _.isFunction(setObject) && setObject(value);
            } 
            else {
                setValue(null);
                _.isFunction(setObject) && setObject(null);
            }
        } catch (error) {
            console.log("auto complete employee error", error);
        }
    }

    const handleOnBlur = (e, value) => {
        if(!loading && _.size(listCustomer) === 0) {
            _.isFunction(setObject) && setObject({customer:'Invalid'});
        }
    }

    return (
        <Autocomplete
            disablePortal
            id='auto-complete-customer'
            options={listCustomer}
            loading={loading}
            clearOnBlur={false}
            loadingText={<CircularProgress />}
            noOptionsText={<DataEmpty />}
            getOptionLabel={(option) => _.get(option, "code")}
            renderOption={
                (option, state, props) => {
                    return(
                        <Fragment>
                            {_.get(option, "code")}
                            {
                                (
                                    _.get(option,'code') === _.get(listCustomer[(_.size(listCustomer)-(_.floor(PER_PAGE/2)))],'code'))
                                    && (_.size(listCustomer) % PER_PAGE === 0
                                ) && (
                                    <Waypoint onEnter={loadMore} />
                                )
                            }
                        </Fragment>
                    )
                }
            }
            sx={{ width: '100%' }}
            value={value}
            onChange={handleSelect}
            onBlur={handleOnBlur}
            renderInput={(params) => 
                <TextField
                    {...params}
                    className={classes.wrapperInput}
                    variant="outlined"
                    onChange={(e) => setSearchText(e.target.value)}
                />
            }
        />
    );
};

export default AutoCompleteCustomerScrollLoad;
