import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';

import NO_IMAGE from '../../assets/images/no-image.svg';
import clsx from 'clsx';
import { LocationOn, Home, Apartment, Map } from '@material-ui/icons';
import {
    Grid, Typography, Button,
    Card, CardActionArea, CardActions, CardContent, CardMedia,
} from '@material-ui/core';
import { ConfigContext } from '../../context/ConfigContext';


function PrimaryCard({ data }) {
    const censorship = _.get(data, "project_status.code");
    const projectInfo = {
        name: _.get(data, 'name'),
        code: _.get(data, 'code')
    }
    const id = _.get(data, 'id');

    const handleCensorShip = (censorship) => {
        switch (censorship) {
            case 'CUFS':
                let object = {
                    processName: 'Sắp mở bán',
                    color: "22px solid #2C8A81"
                }
                return object;
            case 'OS': {
                let object = {
                    processName: 'Đang mở bán',
                    color: "22px solid #0A2240"
                }
                return object;
            }
            case 'HO': {
                let object = {
                    processName: 'Đã bàn giao',
                    color: "22px solid #E27F35"
                }
                return object;
            }
            case 'BLOCK': {
                let object = {
                    processName: 'Khóa',
                    color: "22px solid #FF0000"
                }
                return object;
            }
            default:
                return "";
        }
    }

    const useStyles = makeStyles({
        root: {
            width: 360,
            borderRadius: 10,
            maxHeight: '440px',
            marginLeft: '6px',
            marginTop: '6px',
        },
        card: {
            display: 'flex',
            direction: 'column',
            paddingRight: '6px',
            paddingTop: '6px'
        },
        media: {
            height: 230,
        },
        rowStyle: {
            display: 'flex',
            alignItems: 'center',
        },
        kindHome: {
            color: "#e27f35",
        },
        titleStyle: {
            color: '#0A2240',
            fontSize: '16px',
            fontWeight: 'bold',
            width: '100%',
        },
        addressStyle: {
            color: '#B9B9B9',
            width: '100%',
            fontSize: '14px',
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '-webkitBoxOrient': 'vertical',
            '-webkitLineClamp': 1,
        },
        inlineStyle: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        button: {
            backgroundColor: '#2c8a81',
            color: "#ffffff",
            borderRadius: 0,
            fontSize: '14px',
            padding: '4px 24px',
            textTransform: 'none',
        },
        boxCardBottom: {
            backgroundColor: '#2c8a81',
            width: '30px',
            height: '10px'
        },
        codeMediaTop: {
            marginLeft: 'auto',
            marginTop: '12px',
            color: '#ffffff',
            marginRight: '16px',
        },
        triangle: {
            display: "block",
            position: 'absolute',
            width: 0,
            height: 0,
            borderTop: "12px solid transparent",
            borderLeft: censorship && handleCensorShip(censorship).color,
            borderBottom: "12px solid transparent",
            zIndex: 19,
            marginLeft: 126,
        },
        boxProcess: {
            width: '126px',
            height: '22px',
            backgroundColor: censorship && handleCensorShip(censorship).color.split(" ")[2],
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#ffffff',
            fontSize: '14px',
            zIndex: 2,
            borderRight: censorship ? '1px solid lightgray' : undefined
        },
        triangle: {
            display: "block",
            position: 'absolute',
            width: 0,
            height: 0,
            borderTop: "11px solid transparent",
            borderLeft: censorship && handleCensorShip(censorship).color,
            borderBottom: "11px solid transparent",
            zIndex: 19,
            marginLeft: 125,
        },
        price: {
            color: '#00837B',
            fontWeight: 600,
            marginBottom: '2px'
        },
        shortInfo: {
            paddingTop: '8px',
            '& > svg': {
                fontSize: '16px',
                color: '#B9B9B9',
                marginRight: '8px',
                '&:not(:first-child)': {
                    marginLeft: '15px',
                },
            },
            '& > span': {
                fontSize: '16px',
                color: '#0A2240',
            }

        },
    });
    const classes = useStyles();

    const configValue = useContext(ConfigContext);

    if (!data) {
        return null;
    }

    const addressFull = _.join(_.pull([_.get(data, 'address.short', ''), _.get(data, 'address.street.name', ''), _.get(data, 'address.ward.name', ''), _.get(data, 'address.district.name', ''), _.get(data, 'address.province.name', '')], '', null), ', ');
    const arr = _.filter(_.mapValues(_.get(data, 'project_medias'), 'media'), ['type.code', 'PHOTO']);
    let avatar = _.get(data, 'avatar');//_.map(arr, item => { return { 'id': _.get(item, 'id'), 'url': _.get(item, 'url') } });

    return (
        <Fragment>
            <Grid item className={classes.card}>
                <Card className={classes.root}>
                    <CardActionArea
                        disableRipple
                        style={{ maxHeight: 350 }}
                        to={`/product/primary/details/${id}`}
                        component={Link}
                    >
                        <CardMedia
                            className={classes.media}
                            image={!_.isEmpty(avatar) ? configValue.MEDIA_DOMAIN + avatar.url : NO_IMAGE}
                            title={_.get(data, 'title') || "Đang cập nhật..."}
                        >
                            <Grid container direction="row">
                                <Grid container style={{ marginTop: '10px' }}>
                                    <Grid item className={classes.boxProcess}>{handleCensorShip(_.get(data, "project_status.code")).processName}</Grid>
                                    <Grid item className={classes.triangle}></Grid>
                                </Grid>
                            </Grid>
                        </CardMedia>

                        <CardContent>
                            <Grid container direction="column">
                                <Grid container direction="column">

                                    <Grid item className={clsx(classes.kindHome, classes.rowStyle)}>
                                        <LocationOn style={{ marginRight: '8px', fontSize: '18px' }} />
                                        <span>Dự án</span>
                                    </Grid>
                                    <Grid item className={classes.titleStyle}>
                                        <div className={classes.inlineStyle}>{_.get(data, 'title')}</div>
                                    </Grid>
                                    <Grid item className={classes.addressStyle} >
                                        <div className={classes.inlineStyle}>{addressFull}</div>
                                    </Grid>

                                    <Grid item className={clsx(classes.rowStyle, classes.shortInfo)}>
                                        <Apartment />
                                        <span>{_.get(data, 'total_block') || '0'}&nbsp;block</span>

                                        {
                                            _.get(_.head(_.get(data, 'project_types')), 'project_type.code') === 'L' ? (
                                                <Fragment>
                                                    <Map />
                                                    <span>{_.get(data, 'total_ground') || '0'}&nbsp;nền</span>
                                                </Fragment>
                                            )
                                                :
                                                (
                                                    <Fragment>
                                                        <Home />
                                                        <span>{_.get(data, 'total_appartments') || '0'}&nbsp;căn</span>
                                                    </Fragment>

                                                )
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Grid style={{ marginLeft: '8px' }} container direction="row" alignItems="flex-end">
                            <Grid item>
                                <Typography className={classes.price}>{_.get(data, "price_from_milion")}-{_.get(data, "price_to_milion")} triệu/m<sup>2</sup></Typography>
                            </Grid>
                            {/* <Grid item style={{ marginLeft: 'auto' }}>
                                <CardActions>
                                    <Button variant="contained" className={classes.button}>Lịch hẹn</Button>
                                </CardActions>
                            </Grid> */}
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>

        </Fragment>

    );
}

export default PrimaryCard;