import * as _ from 'lodash';
import { gql, useQuery } from '@apollo/client';
import { CORE_SCHEMA_FIELDS } from '../../product/fragments';
import { useMutation } from '@apollo/client';
import swal from "sweetalert";

export const GET_BRANCHES = gql`
query GetBranches {
  results: branch(where: {active:{_eq: true},deleted: {_eq: false}}) {
    id
    code
    name
    phone
    head
    longitude
    latitude
    address: addressByAddress {
      ...addressData
    }
  }
}
fragment addressData on address {
    id
    short
    street: streetByStreet {
      id
      name
    }
    ward: wardByWard {
      id
      name
    }
    district: districtByDistrict {
        id
        name
    }
    province: provinceByProvince {
        id
        name
    }
}

`;

export const GET_BRANCH = gql`
  query getBranch($id: Int!) {
  results: branch(where: {id: {_eq: $id}})
    {
        id
        code
        name
        address
        
    longitude
    latitude
        active
        deleted
    }
}
`;

export const GET_BRANCH_BY_CODE = gql`
query getBranchByCode($code: String!) {
  results: branch(where: {code: {_eq: $code}})
    {
        id
        code
        name
        address
        
    longitude
    latitude
        active
        deleted
    }
}
`;

export const GET_BRANCH_NEW = gql`
 ${CORE_SCHEMA_FIELDS}
 query GetBranch($where: branch_bool_exp!) {
  branch(where: $where) {
    branch_incharges {
      wardByWard {
        id 
        name
      }
      districtByDistrict {
        id 
        name
      }
      provinceByProvince {
        id 
        name
      }
    }
    branch_incharges_aggregate {
      aggregate{
        count
      }
    }
    branch_departments {
      department
      branch
      departmentByDepartment {
        active
        code
        created
        created_by
        id
        name
        manager
      }
    }
    branches(where: {active:{_eq: true}, deleted: {_eq: false}}) {
      id
      name
      code
      employee_manager: employee {
        info: accountByAccount {
          id
          code
          full_name
        }
      }
      created
      branch_head: branch {
        code
      }
      address: addressByAddress {
        ...addressData
      }
    }
    emp_count: employees_aggregate(where: {accountByAccount: {account_roles: {roleByRole: {code: {_eq: "SALES"}}}}}) {
      aggregate {
        count
      }
    }
    admin_count: employees_aggregate(where: {accountByAccount: {account_roles: {roleByRole: {code: {_like: "%ADMIN%"}}}}}) {
      aggregate {
        count
      }
    }
    product_count: products_aggregate(where: {deleted: {_eq: false}}) {
      aggregate {
        count
      }
    }
    active
    address: addressByAddress {
      ...addressData
    }
    id
    code
    name
    branch {
      id 
      code 
      name
    }
    manager: employee {
      info: accountByAccount {
        id
        code
        full_name
      }
    }
    latitude
    longitude
    location
    created
    created_by
    head
    is_physical
    phone
  }
  t: branch_aggregate(where:$where) {
    	aggregate{
        count
      }
  }
}

`

export const GET_BRANCH_BY = gql`
  ${CORE_SCHEMA_FIELDS}
  query getBranchesBy($where: branch_bool_exp!, $skip: Int, $take: Int) {
  results: branch(where: $where, offset: $skip, limit: $take, order_by: { created: desc }) {
    id
    code
    name
    manager
    head
    is_physical
    phone
    employee_manager: employee {
      info: accountByAccount {
        id
        code
        full_name
      }
    }
    head_branch: branch {
      id
      code
      name
    }
    address: addressByAddress {
      ...addressData
    }
    longitude
    latitude
    emp_count: employees_aggregate(where: {accountByAccount: {account_roles: {roleByRole: {code: {_eq: "SALES"}}}}}) {
      aggregate {
        count
      }
    }
    admin_count: employees_aggregate(where: {accountByAccount: {account_roles: {roleByRole: {code: {_like: "%ADMIN%"}}}}}) {
      aggregate {
        count
      }
    }
    product_count:products_aggregate(where: { deleted: { _eq: false } }) {
      aggregate {
        count
      }
    }
    
    active
    deleted
    created
    manager
    branch {
      id 
      name
    }
    branch_incharges {
      wardByWard {
        id 
        code 
        name
      }
      districtByDistrict {
          id
          code
          name
      }
      provinceByProvince {
        id  
        code  
        name
      }
    }
  }
  t: branch_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}

`;

export const BLOCK_BRANCH = gql`
mutation block_branch($id: Int!, $active: Boolean!, $reason: String!) {
  update_branch(_set: {active: $active, reason: $reason}, where: { id: { _eq: $id } }) {
    affected_rows
    returning {
      active
      address
      code
      created
      created_by
      deleted
      id
      latitude
      branch_departments {
        departmentByDepartment {
          id
          manager
          name
          updated
          updated_by
        }
      }
    }
  }
}
`;

export const CREATE_BRANCH = gql`
  mutation CreateBranch($data: CreateBranchInput!) {
    createBranch(data: $data) {
      success
      branch
    }
  }
`;

export const UPDATE_BRANCH = gql`
  mutation UpdateBranch($data: UpdateBranchInput!) {
    updateBranch(data: $data) {
      success
      branch
    }
  }
`;

export const useBranches = () => {
  const { data, loading, error, refetch, fetchMore } = useQuery(GET_BRANCHES, {
    variables: {
      skip: 0,
      take: 1e9
    }
  });
  return {
    loading, error, refetch, fetchMore, data: _.get(data, 'results')
  }
};

export const useBlockBranch = () => {
  const [blockedBranch] = useMutation(BLOCK_BRANCH, {
    onError: (err) => {
        return swal({
            title: "Cập nhật thất bại",
            icon: "error",
        })
    },
    onCompleted: () => {
        return swal({
            title: "Cập nhật thành công",
            icon: "success",
        })
    }
  });

  return blockedBranch;
}

export const useCreateBranch = () => {
  const [createBranch] = useMutation(CREATE_BRANCH);

  return createBranch;
}

export const useGetBranch = (id) => {
  const { data, loading, error } = useQuery(GET_BRANCH_BY, { 
    variables: {
      where: {
        id: { _eq: id }
      }
    },
    skip: !id
   });

   return {
     data: _.get(data, 'results[0]'), loading, error
   }
}

export const useUpdateBranch = (id) => {
  const [updateBranch, { loading }] = useMutation(UPDATE_BRANCH, {
    refetchQueries: [
      {
        query: GET_BRANCH_NEW,
        variables: {
          where: {
            id: { _eq: id },
          },
        },
      },
    ],
  });

  return {
    updateBranch,
    loading
  };
}