import * as _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { ALL_SECONDARY_PRODUCT_STATUS } from '../../../../constants/defined';

const COLOR = (type) => _.get(
    {
        waiting: '#0e7043',
        selling: '#0f295c',
        sold: '#cece06',
        hidden: '#f77b00',
        all: '#000',
        locked: '#ff0000'
    }, type, '#000');

const useStyles = makeStyles(theme => ({
    textStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '16px',
        textAlign: 'center',
        justifyContent: 'flex-start',
        padding: '0 10px',
        cursor: 'pointer',
        '&.disable': {
            opacity: '0.5',
            cursor: 'unset',
        },
        '&:hover:not(.disable)': {
            opacity: '0.8',
            color: '#0f295c'
        },
        '& > .unavailable': {
            opacity: .3,
        }
    },
    buttonStyle: {
        height: '20px',
        width: '20px',
        margin: '10px 0',
        borderRadius: '50%',
        '&.waiting-type': {
            backgroundColor: COLOR('waiting')
        },
        '&.selling-type': {
            backgroundColor: COLOR('selling')
        },
        '&.sold-type': {
            backgroundColor: COLOR('sold')
        },
        '&.hidden-type': {
            backgroundColor: COLOR('hidden')
        },
        '&.locked-type': {
            backgroundColor: COLOR('locked')
        },
        '&.all-type': {
            backgroundColor: COLOR('all')
        },
    },
    inputStyle: {
        width: '80%',
        backgroundColor: 'white',
        marginLeft: '0',
        '& > div:before': {
            content: 'unset',
        },
        '& > div:after': {
            content: 'unset',
        },
    },
}));

const LABLE = (status) => _.get({
    'WAITING': 'Chờ duyệt',
    'SELLING': 'Đang bán',
    'SOLD': 'Đã bán',
    'HIDDEN': 'Đã ẩn',
    'LOCKED': 'Đã khóa',
    'ALL': 'Xem tất cả',
}, status, '');

const FILTER = (code) => {
    return (
        _.get({
            'WAITING': ['WAITING'],
            'SELLING': ['SELLING'],
            'SOLD': ['SOLD',"SOLD_OTHER",'DEPOSIT'],
            'HIDDEN': ['HIDDEN'],
            'LOCKED': ['LOCKED'],
            'ALL': ALL_SECONDARY_PRODUCT_STATUS        
        }, code, ALL_SECONDARY_PRODUCT_STATUS)
    )
}

const ButtonStatusList = ({ handleClick, status, count = 0, selected=false }) => {
    const classes = useStyles();

    const handleClickFilter = () => {
        if (_.isFunction(handleClick) && count >= 0) handleClick(FILTER(status));
    }

    return (
        <div 
            className={clsx(classes.textStyle, !(count >= 0) && 'disable')}
            onClick={handleClickFilter}
        >
            <div className={clsx(classes.buttonStyle, _.toLower(status)+'-type')} />
            <div className={clsx(!selected && 'unavailable')}>{`${LABLE(status)} (${count})`}</div>
            
        </div>
    );
}

export default ButtonStatusList;




    
