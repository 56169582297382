import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import * as _ from "lodash";
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_EMPLOYEE_BY_ID } from '../../../graphql/schemas/employee/employeeQueries';
import { ACTIVE_ACCOUNT } from '../../../graphql/schemas/customer/customerMutation';

const ModalBlocked = ({ setOpenBlocked, openBlocked, setBlocked, data, id }) => {
    const [reason, setReason] = useState("");
    const [blokedAccount, { loading, error },] = useMutation(ACTIVE_ACCOUNT, {
        refetchQueries: [{
            query: GET_EMPLOYEE_BY_ID,
            variables: {
                skip: 0,
                take: 10,
                where: {
                    accountByAccount: {
                        deleted: { _eq: false },
                        id: {
                            _eq: id
                        }
                    }
                }
            }
        }],
        awaitRefetchQueries: true,
    });
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        buttonConfirm: {
            backgroundColor: '#2C8A81',
            color: '#ffffff',
            borderRadius: 0,
            margin: '0 8px'
        },
        buttonModalBack: {
            backgroundColor: '#0B213F',
            color: '#ffffff',
            borderRadius: 0,
            margin: '0 8px'
        },
        reasonBlock: {
            borderTopLeftRadius: 20,
            borderBottomRightRadius: 20,
            borderBottomLeftRadius: 20,
            borderTopRightRadius: 0,
            marginTop: '20px',
            padding: '10px'
        }
    }));
    const classes = useStyles();


    const handleClose = () => {
        setOpenBlocked(false);
    };

    const handleBlocked = () => {
        blokedAccount({
            variables: {
                account: _.get(data, 'info.id'),
                active: false
            }
        });
        setOpenBlocked(false);
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openBlocked}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openBlocked}>
                <div className={classes.paper}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item>
                            <Typography style={{ fontWeight: 'bold' }}>Bạn có muốn khoá tài khoản {_.get(data, 'code')} - {_.get(data, 'full_name')}? Vui lòng nhập lý do bên dưới và xác nhận: </Typography>
                        </Grid>
                        <Grid item style={{ width: '60%' }}>
                            <Paper className={classes.reasonBlock}>
                                <TextField
                                    className={classes.message}
                                    InputProps={{ disableUnderline: true }}
                                    multiline
                                    style={{ width: '100%', height: '6em' }}
                                    id="message"
                                    rows={10}
                                    onBlur={(e) => setReason(e.target.value)}
                                />
                            </Paper>
                        </Grid>
                        <Grid item style={{ marginTop: '20px' }}>
                            <Button variant="contained" onClick={handleBlocked} className={classes.buttonConfirm}>Xác nhận</Button>
                            <Button onClick={handleClose} variant="contained" className={classes.buttonModalBack}>Quay lại</Button>
                        </Grid>
                    </Grid>

                </div>
            </Fade>
        </Modal>
    )
}

export default ModalBlocked
